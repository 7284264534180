import {
  useQuestionnaireResultQuery,
  useQuestionnaireResultTextQuery,
} from '@src/graphql/generated/graphql'
import { useQuestionnaire } from '@src/models/Questionnaire'
import { useNavigate, useParams } from 'react-router-dom'

export const useQuestionnaireResultHooks = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()

  const { data: questionnaireData, loading: questionnaireLoading } =
    useQuestionnaire({
      variables: {
        uuid: id ?? '',
      },
    })

  const { data: choiceData, loading: choiceLoading } =
    useQuestionnaireResultQuery({
      variables: {
        questionnaireUuid: id ?? '',
      },
    })

  const { data: textData, loading: textLoading } =
    useQuestionnaireResultTextQuery({
      variables: {
        questionnaireUuid: id ?? '',
      },
    })

  const loading = questionnaireLoading || choiceLoading || textLoading

  return {
    choiceData,
    id,
    loading,
    navigate,
    questionnaireData,
    textData,
  }
}

export type useQuestionnaireResultHooksResult = ReturnType<
  typeof useQuestionnaireResultHooks
>
