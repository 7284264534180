import { QuestionnaireResult } from '@src/components/pages/questionnaires/[id]/result'
import { useQuestionnaireResultHooks } from '@src/components/pages/questionnaires/[id]/result/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const QuestionnaireResultPage = memo(() => {
  const questionnaireResultHooks = useQuestionnaireResultHooks()
  return (
    <Authorization>
      <QuestionnaireResult {...questionnaireResultHooks} />
    </Authorization>
  )
})
