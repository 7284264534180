import { SettingsIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  AspectRatio,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  ModalCloseButton,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import {
  CsbDeleteButton,
  CsbDeleteModal,
  CsbFormControl,
  CsbFormModal,
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
  CsbPagination,
  CsbSearchInput,
} from '@src/components'
import { CsbEditButton } from '@src/components/Buttons/CsbEditButton'
import { CsbPlayButton } from '@src/components/Buttons/CsbPlayButton'
import { CsbTable } from '@src/components/CsbTable'
import { SelectCompaniesModal } from '@src/components/Modal/SelectCompanyModal/SelectCompaniesModal'
import { useMoviesHooksResult } from '@src/components/pages/movies/hooks'
import { Base } from '@src/layouts'
import { findError } from '@src/utils/findError'
import Vimeo from '@u-wave/react-vimeo'
import { memo, useState } from 'react'

//動画一覧ページのcomponent
export const Movies = memo(
  ({
    current,
    currentMovie,
    data,
    deleteModal,
    destroyMovieMutation,
    formModal,
    isCreating,
    isOpen,
    isUpdating,
    isUpdatingMovieCompanies,
    isWriteLearningMovie,
    onChangePage,
    onClose,
    onOpen,
    onResetSearch,
    onSearch,
    onSubmit,
    onUpdate,
    onUpdateSelectCompanies,
    refetch,
    searchForm,
    setMovie,
  }: useMoviesHooksResult) => {
    const [vimeoState, setVimeoState] = useState({
      error: false,
      loading: false,
    })

    const selectCompanyModal = useDisclosure()
    const [companyUuids, setCompanyUuids] = useState<string[]>([])
    const [uuid, setUuid] = useState<string>('')

    const modalClose = () => {
      onClose()
      setVimeoState({
        error: false,
        loading: true,
      })
    }

    return (
      <Base
        left={<Heading>動画管理</Heading>}
        right={
          isWriteLearningMovie && (
            <Button w={210} onClick={formModal.onCreate}>
              新しい動画の追加
            </Button>
          )
        }
      >
        <Box maxW={518} mt={12} w={'full'}>
          <CsbSearchInput
            placeholder={'動画名を入力'}
            {...searchForm.register('searchWord')}
            onReset={onResetSearch}
            onSearch={onSearch}
          />
        </Box>
        <CsbTable
          labels={['動画名', '動画URL', '対象企業', '']}
          wrapperProps={{ mt: 35 }}
        >
          {data?.movies.collection.map((item) => (
            <CsbTable.TableRow key={item.uuid}>
              <CsbTable.TableCell w={300}>
                <Tooltip aria-label="A tooltip" label={item.name}>
                  <Text isTruncated fontWeight={'bold'} w={300}>
                    {item.name}
                  </Text>
                </Tooltip>
              </CsbTable.TableCell>
              <CsbTable.TableCell w={500}>
                <Tooltip aria-label="A tooltip" label={item.url}>
                  <Text isTruncated color={'gray.500'} fontSize={'sm'} w={500}>
                    {item.url}
                  </Text>
                </Tooltip>
              </CsbTable.TableCell>
              <CsbTable.TableCell w={200}>
                <Tooltip aria-label="A tooltip">
                  <Text isTruncated color={'gray.500'} fontSize={'xs'} w={200}>
                    {item.companies.map((company) => company.name).join(',')}
                  </Text>
                </Tooltip>
              </CsbTable.TableCell>

              <CsbTable.TableCell isNumeric>
                <Flex gridGap={5} justifyContent={'end'}>
                  <CsbPlayButton
                    onClick={(e) => {
                      e.stopPropagation()
                      setMovie(item)
                      onOpen()
                    }}
                  />
                  {isWriteLearningMovie && (
                    <>
                      <Tooltip label={'対象企業設定'}>
                        <IconButton
                          aria-label="preview"
                          colorScheme={'blue'}
                          icon={<SettingsIcon />}
                          rounded={'full'}
                          onClick={(e) => {
                            e.stopPropagation()
                            const companyUuids = item.companies.map(
                              (company) => company.uuid
                            )
                            setCompanyUuids(companyUuids)
                            setUuid(item.uuid)
                            selectCompanyModal.onOpen()
                          }}
                        />
                      </Tooltip>
                      <CsbEditButton
                        onClick={(e) => {
                          e.stopPropagation()
                          onUpdate(item)
                        }}
                      />
                      <CsbDeleteButton
                        onClick={(e) => {
                          e.stopPropagation()
                          deleteModal.onOpen(item.uuid, item.name)
                        }}
                      />
                    </>
                  )}
                </Flex>
              </CsbTable.TableCell>
            </CsbTable.TableRow>
          ))}
        </CsbTable>
        <Flex mt={5}>
          {data ? (
            <CsbPagination
              current={current}
              defaultCurrent={current}
              pageSize={data.movies.metadata.limitValue}
              total={data.movies.metadata.totalCount}
              onChange={onChangePage('/movies')}
            />
          ) : null}
          <HStack ml={'auto'} spacing={3}>
            {isWriteLearningMovie && (
              <Button w={210} onClick={formModal.onCreate}>
                新しい動画の追加
              </Button>
            )}
          </HStack>
        </Flex>
        <CsbDeleteModal
          header={deleteModal.target}
          isOpen={deleteModal.isOpen}
          onCancel={deleteModal.onClose}
          onClose={deleteModal.onClose}
          onDelete={() =>
            deleteModal.onDelete(
              destroyMovieMutation({
                variables: {
                  input: { uuid: deleteModal.uuid ?? '' },
                },
              }),
              async () => {
                const { data } = await refetch()
                return {
                  metadata: {
                    currentPage: data.movies.metadata.currentPage,
                    limitValue: data.movies.metadata.limitValue,
                    totalCount: data.movies.metadata.totalCount,
                  },
                }
              },
              onChangePage('/movies')
            )
          }
        />
        <CsbFormModal
          header={
            formModal.isCreate
              ? '動画の作成'
              : `${formModal.form.getValues('name') || '動画'}の編集`
          }
          isLoading={formModal.isCreate ? isCreating : isUpdating}
          isOpen={formModal.isOpen}
          submit={formModal.isCreate ? '作成' : '編集'}
          onCancel={formModal.onCancel}
          onClose={formModal.onCancel}
          onSubmit={formModal.form.handleSubmit(onSubmit)}
        >
          <CsbFormControl
            errorText={findError(formModal.errors, 'name')?.message}
          >
            <Input
              {...formModal.form.register('name')}
              placeholder={'動画の名前*'}
            />
          </CsbFormControl>
          <CsbFormControl
            errorText={findError(formModal.errors, 'url')?.message}
          >
            <Input
              {...formModal.form.register('url')}
              placeholder={'動画のURL*'}
            />
          </CsbFormControl>
        </CsbFormModal>
        <CsbModal isOpen={isOpen} size={'3xl'} onClose={modalClose}>
          <CsbModalHeader>{currentMovie?.name}</CsbModalHeader>
          <ModalCloseButton />
          <CsbModalBody mt={5}>
            <AspectRatio maxW={640} mx={'auto'} ratio={16 / 9} w={'full'}>
              <>
                <Box h={4} w={4}>
                  <Spinner
                    color="blue.500"
                    emptyColor="gray.200"
                    size="xl"
                    speed="0.65s"
                    thickness="4px"
                  />
                </Box>
                <Vimeo
                  speed={true}
                  video={currentMovie?.url ?? ''}
                  width={640}
                  onError={(error) =>
                    setVimeoState((prevState) => ({
                      ...prevState,
                      error: !!error,
                    }))
                  }
                  onReady={() =>
                    setVimeoState((prevState) => ({
                      ...prevState,
                      loading: false,
                    }))
                  }
                />
                {vimeoState.error && (
                  <Alert status="error">
                    <AlertIcon />
                    プレビューを表示できません。
                    <br />
                    vimeoの閲覧権限を確認してください
                  </Alert>
                )}
              </>
            </AspectRatio>
          </CsbModalBody>
          <CsbModalFooter mt={6}>
            <Button colorScheme={'teal'} onClick={modalClose}>
              閉じる
            </Button>
          </CsbModalFooter>
        </CsbModal>
        <SelectCompaniesModal
          companyUuids={companyUuids}
          disclosure={selectCompanyModal}
          isSubmitting={isUpdatingMovieCompanies}
          uuid={uuid}
          onSubmit={onUpdateSelectCompanies}
        />
      </Base>
    )
  }
)
