import { PhishingResultNew } from '@src/components/pages/phishing/[id]/result'
import { usePhishingResultNewHooks } from '@src/components/pages/phishing/[id]/result/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const PhishingResultNewPage = memo(() => {
  const phishingResultNewHooks = usePhishingResultNewHooks()
  return (
    <Authorization>
      <PhishingResultNew {...phishingResultNewHooks} />
    </Authorization>
  )
})
