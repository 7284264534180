import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  forwardRef,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react'
import { useState } from 'react'

type CsbPasswordPropsType = InputProps

//passwordを表示、非表示するcomponent
export const CsbPassword = forwardRef<CsbPasswordPropsType, 'input'>(
  (props, ref) => {
    const [show, setShow] = useState(false)
    const handleClick = () => setShow((prevState) => !prevState)

    return (
      <InputGroup>
        <Input ref={ref} type={show ? 'text' : 'password'} {...props} />
        <InputRightElement>
          <IconButton
            aria-label={show ? 'hide password' : 'show password'}
            icon={show ? <ViewOffIcon /> : <ViewIcon />}
            onClick={handleClick}
          />
        </InputRightElement>
      </InputGroup>
    )
  }
)
