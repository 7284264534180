import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react'
import {
  CsbDeleteButton,
  CsbDeleteModal,
  CsbFormControl,
  CsbFormModal,
  CsbPagination,
  CsbSearchInput,
} from '@src/components'
import { CsbEditButton } from '@src/components/Buttons/CsbEditButton'
import { CsbTable } from '@src/components/CsbTable'
import { useGroupsHooksResult } from '@src/components/pages/groups/hooks'
import { Base } from '@src/layouts'
import { findError } from '@src/utils/findError'
import { memo } from 'react'

//グループ一覧ページのcomponent
export const Groups = memo(
  ({
    current,
    data,
    deleteModal,
    destroyGroupMutation,
    formModal,
    isCreating,
    isUpdating,
    isWriteGroup,
    onChangePage,
    onResetSearch,
    onSearch,
    onSubmit,
    onUpdate,
    refetch,
    searchForm,
  }: useGroupsHooksResult) => {
    return (
      <Base
        left={<Heading>グループ管理</Heading>}
        right={
          isWriteGroup && (
            <Button w={210} onClick={formModal.onCreate}>
              新しいグループの作成
            </Button>
          )
        }
      >
        <Box maxW={518} mt={12} w={'full'}>
          <CsbSearchInput
            placeholder={'グループ名を入力'}
            {...searchForm.register('searchWord')}
            onReset={onResetSearch}
            onSearch={onSearch}
          />
        </Box>
        <CsbTable labels={['グループ名', '説明', '']} wrapperProps={{ mt: 35 }}>
          {data?.groups.collection.map((item) => (
            <CsbTable.TableRow key={item.uuid}>
              <CsbTable.TableCell w={300}>
                <Tooltip aria-label="A tooltip" label={item.name}>
                  <Text isTruncated fontWeight={'bold'} w={300}>
                    {item.name}
                  </Text>
                </Tooltip>
              </CsbTable.TableCell>
              <CsbTable.TableCell w={300}>
                <Tooltip aria-label="A tooltip" label={item.description}>
                  <Text isTruncated color={'gray.500'} fontSize={'sm'} w={300}>
                    {item.description}
                  </Text>
                </Tooltip>
              </CsbTable.TableCell>
              <CsbTable.TableCell isNumeric>
                {isWriteGroup && (
                  <Flex gridGap={5} justifyContent={'end'}>
                    <CsbEditButton
                      onClick={(e) => {
                        e.stopPropagation()
                        onUpdate(item)
                      }}
                    />
                    <CsbDeleteButton
                      onClick={(e) => {
                        e.stopPropagation()
                        deleteModal.onOpen(item.uuid, item.name)
                      }}
                    />
                  </Flex>
                )}
              </CsbTable.TableCell>
            </CsbTable.TableRow>
          ))}
        </CsbTable>
        <Flex mt={5}>
          {data ? (
            <CsbPagination
              current={current}
              defaultCurrent={current}
              pageSize={data.groups.metadata.limitValue}
              total={data.groups.metadata.totalCount}
              onChange={onChangePage('/groups')}
            />
          ) : null}
          <HStack ml={'auto'} spacing={3}>
            {isWriteGroup && (
              <Button w={210} onClick={formModal.onCreate}>
                新しいグループの作成
              </Button>
            )}
          </HStack>
        </Flex>
        <CsbDeleteModal
          header={deleteModal.target}
          isOpen={deleteModal.isOpen}
          onCancel={deleteModal.onClose}
          onClose={deleteModal.onClose}
          onDelete={() =>
            deleteModal.onDelete(
              destroyGroupMutation({
                variables: {
                  input: { uuid: deleteModal.uuid ?? '' },
                },
              }),
              async () => {
                const { data } = await refetch()
                return {
                  metadata: {
                    currentPage: data.groups.metadata.currentPage,
                    limitValue: data.groups.metadata.limitValue,
                    totalCount: data.groups.metadata.totalCount,
                  },
                }
              },
              onChangePage('/groups')
            )
          }
        />
        <CsbFormModal
          header={
            formModal.isCreate
              ? 'グループの作成'
              : `${formModal.form.getValues('name') || 'グループ'}の編集`
          }
          isLoading={formModal.isCreate ? isCreating : isUpdating}
          isOpen={formModal.isOpen}
          submit={formModal.isCreate ? '作成' : '編集'}
          onCancel={formModal.onCancel}
          onClose={formModal.onCancel}
          onSubmit={formModal.form.handleSubmit(onSubmit)}
        >
          <CsbFormControl
            errorText={findError(formModal.errors, 'name')?.message}
          >
            <Input
              {...formModal.form.register('name')}
              isRequired
              placeholder={'グループの名前*'}
            />
          </CsbFormControl>
          <CsbFormControl
            errorText={findError(formModal.errors, 'description')?.message}
          >
            <Textarea
              {...formModal.form.register('description')}
              placeholder={'説明'}
            />
          </CsbFormControl>
        </CsbFormModal>
      </Base>
    )
  }
)
