import { Input, Select } from '@chakra-ui/react'
import { CsbFormControl } from '@src/components'
import {
  useCreateQuestionnaireResult,
  useUpdateQuestionnaireResult,
} from '@src/models/Questionnaire'
import React from 'react'
import { Controller } from 'react-hook-form'

export const QuestionnaireForm = ({
  findErrorMessage,
  form,
  isResearchSelectable,
  researches,
}:
  | Pick<
      useCreateQuestionnaireResult,
      'form' | 'findErrorMessage' | 'researches' | 'isResearchSelectable'
    >
  | Pick<
      useUpdateQuestionnaireResult,
      'form' | 'findErrorMessage' | 'researches' | 'isResearchSelectable'
    >) => {
  return (
    <>
      <CsbFormControl errorText={findErrorMessage('name')}>
        <Input
          {...form.register('name')}
          placeholder={'アンケートURLのラベル'}
        />
      </CsbFormControl>
      <CsbFormControl errorText={findErrorMessage('researchId')}>
        <Controller
          control={form.control}
          name={'researchId'}
          render={({ field: { onChange, value } }) => (
            <Select
              disabled={!isResearchSelectable}
              placeholder={'アンケートを選択してください'}
              value={value}
              onChange={onChange}
            >
              {researches.map((research) => (
                <option key={research.uuid} value={research.uuid}>
                  {research.name}
                </option>
              ))}
            </Select>
          )}
        />
      </CsbFormControl>
    </>
  )
}
