import { AGES } from '@src/constants'
import {
  PhishingResultChartQueryVariables,
  usePhishingResultSelectDataQuery,
} from '@src/graphql/generated/graphql'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

export const MAPPING = {
  FILTER: [
    {
      label: 'all',
      title: 'すべて',
      value: 0,
    },
    {
      label: 'organizations',
      title: '組織',
      value: 1,
    },
    {
      label: 'groups',
      title: 'グループ',
      value: 2,
    },
    {
      label: 'positions',
      title: '役職',
      value: 3,
    },
  ],
  PATTERNS: [
    {
      label: '開封',
      value: 1,
    },
    {
      label: 'webアクセス',
      value: 2,
    },
    {
      label: 'ログイン',
      value: 3,
    },
    {
      label: '回答',
      value: 4,
    },
  ],
} as const

type SelectType = {
  ages: { name: string; uuid: string }[]
  groups: { name: string; uuid: string }[]
  organizations: { name: string; uuid: string }[]
  positions: { name: string; uuid: string }[]
}

export const usePhishingResultContentsSelect = () => {
  const form = useForm<
    Omit<PhishingResultChartQueryVariables, 'uuid' | 'filterUuids'> & {
      filterUuids: string[]
    }
  >({
    defaultValues: {
      days: 7,
      filter: 0,
      filterUuids: [],
      pattern: 1,
    },
  })

  const [selectData, setSelectData] = useState<SelectType | null>(null)
  const filterSelectData = (input: number) => {
    const test = MAPPING.FILTER.find(({ value }) => value === input)
    if (!test) {
      return []
    }
    if (test.label === 'all') {
      return []
    }
    return selectData && selectData[test.label]
  }
  const { loading } = usePhishingResultSelectDataQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: ({ groups, organizations, positions }) => {
      setSelectData({
        ages: AGES.map(({ name }) => ({ name, uuid: name })),
        groups: groups.collection.map(({ name, uuid }) => ({
          name,
          uuid,
        })),
        organizations: organizations.collection.map(({ name, uuid }) => ({
          name,
          uuid,
        })),
        positions: positions.collection.map(({ name, uuid }) => ({
          name,
          uuid,
        })),
      })
    },
  })

  const isDisabledCheckBox = (value: string) => {
    return (
      form.watch('filterUuids').length > 9 &&
      !form.getValues('filterUuids').some((n) => n === value)
    )
  }

  const onChangeSelect = () => {
    console.log(form.getValues())
  }

  return {
    filterSelectData,
    form,
    isDisabledCheckBox,
    loading,
    onChangeSelect,
    selectData,
  }
}

type usePhishingResultContentsSelect = typeof usePhishingResultContentsSelect
export type usePhishingResultContentsSelectResult =
  ReturnType<usePhishingResultContentsSelect>
// export type usePhishingResultContentsSelectParameter = Parameters<usePhishingResultContentsSelect>[0]
