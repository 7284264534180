import { BaseResponseError } from '@src/errors/BaseResponseError'
import { Dispatch, SetStateAction, useState } from 'react'

type BaseResponseErrorType = [BaseResponseError?]

type useErrorsType = () => [
  errors: BaseResponseErrorType,
  setErrors: Dispatch<SetStateAction<BaseResponseErrorType>>
]

export const useErrors: useErrorsType = () => {
  const [errors, setErrors] = useState<BaseResponseErrorType>([])
  return [errors, setErrors]
}
