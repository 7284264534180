import { UseDisclosureReturn } from '@chakra-ui/hooks/src/use-disclosure'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  TableContainer,
} from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
  CsbPagination,
  CsbSearchInput,
} from '@src/components'
import { CsbTable } from '@src/components/CsbTable'
import { useCompaniesQuery } from '@src/graphql/generated/graphql'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

type SelectCompaniesModalPropsType = {
  companyUuids: string[]
  disclosure: UseDisclosureReturn
  isSubmitting: boolean
  onSubmit: (uuid: string, companyUuids: string[]) => void
  uuid: string
}

export const SelectCompaniesModal = ({
  companyUuids,
  disclosure: selectCompanyModal,
  isSubmitting,
  onSubmit: onUpdateSelectCompanies,
  uuid,
}: SelectCompaniesModalPropsType) => {
  // const selectCompanyModal = useDisclosure()
  const selectCompanyForm =
    // TODO anyを消す
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useForm<{ uuids: { [key: string]: undefined | any } }>()

  const searchCompanyForm = useForm<{ searchWord: string }>()

  const [companyPage, setCompanyPage] = useState(1)

  const pagination = {
    limit: 5,
    page: companyPage,
  }

  const { data: searchCompanies, refetch: refetchCompanies } =
    useCompaniesQuery({
      variables: {
        pagination: pagination,
        searchWord: searchCompanyForm.getValues('searchWord') ?? '',
      },
    })

  const onSearchCompany = searchCompanyForm.handleSubmit(async (inputData) => {
    try {
      await refetchCompanies({
        pagination: pagination,
        searchWord: inputData.searchWord,
      })
    } catch (e) {
      console.log(e)
    }
  })
  const onResetSearchCompany = (callback?: () => void) => {
    setCompanyPage(1)
    searchCompanyForm.setValue('searchWord', '')
    onSearchCompany().then(() => {
      callback?.()
    })
  }

  useEffect(() => {
    if (selectCompanyModal.isOpen) {
      const uuids = selectCompanyForm.getValues().uuids
      if (uuids) {
        Object.entries(uuids).forEach(([key]) => {
          selectCompanyForm.setValue(`uuids.${key}`, false)
        })
      }

      companyUuids.forEach((companyUuid) =>
        selectCompanyForm.setValue(`uuids.${companyUuid}`, true)
      )
      onResetSearchCompany()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectCompanyModal])

  const onSubmit = selectCompanyForm.handleSubmit(async (inputData) => {
    const uuids = Object.entries(inputData.uuids)
      .filter(([, value]) => value)
      .map(([key]) => key)
    onUpdateSelectCompanies(uuid, uuids)
    selectCompanyModal.onClose()
  })

  return (
    <>
      <CsbModal
        isOpen={selectCompanyModal.isOpen}
        size={'3xl'}
        onClose={selectCompanyModal.onClose}
      >
        <CsbModalHeader>対象企業設定</CsbModalHeader>
        <CsbModalBody>
          <Flex mt={3}>
            <Box maxW={518} w={'full'}>
              <CsbSearchInput
                placeholder={'企業名を入力'}
                {...searchCompanyForm.register('searchWord')}
                onReset={() => {
                  onResetSearchCompany()
                }}
                onSearch={() => {
                  setCompanyPage(1)
                  onSearchCompany().then()
                }}
              />
            </Box>
          </Flex>
          <Box mt={35}>
            <TableContainer mt={2}>
              <CsbTable labels={[{ label: '選択' }, { label: '企業名' }]}>
                {searchCompanies?.companies.collection.map((item) => (
                  <CsbTable.TableRow key={item.uuid}>
                    <CsbTable.TableCell>
                      <Controller
                        control={selectCompanyForm.control}
                        name={`uuids.${item.uuid}` as const}
                        render={({ field: { onChange, ...props } }) => (
                          <Checkbox
                            borderColor={'teal.500'}
                            isChecked={props.value}
                            onChange={(e) => {
                              e.stopPropagation()
                              onChange(e)
                            }}
                            {...props}
                          />
                        )}
                      />
                    </CsbTable.TableCell>
                    <CsbTable.TableCell
                      onClick={() => {
                        selectCompanyForm.setValue(
                          `uuids.${item.uuid}`,
                          !selectCompanyForm.getValues(`uuids.${item.uuid}`)
                        )
                      }}
                    >
                      {item.name ?? ''}
                    </CsbTable.TableCell>
                  </CsbTable.TableRow>
                ))}
              </CsbTable>
            </TableContainer>
          </Box>
          <Flex mt={5}>
            {searchCompanies ? (
              <CsbPagination
                current={companyPage}
                defaultCurrent={companyPage}
                pageSize={searchCompanies?.companies.metadata.limitValue}
                total={searchCompanies?.companies.metadata.totalCount}
                onChange={(currentPage) => {
                  setCompanyPage(currentPage ?? 1)
                  onSearchCompany().then()
                }}
              />
            ) : null}
          </Flex>
        </CsbModalBody>
        <CsbModalFooter mt={2}>
          <HStack spacing={6}>
            <Button colorScheme={'gray'} onClick={selectCompanyModal.onClose}>
              キャンセル
            </Button>
            <Button isLoading={isSubmitting} onClick={onSubmit}>
              設定
            </Button>
          </HStack>
        </CsbModalFooter>
      </CsbModal>
    </>
  )
}
