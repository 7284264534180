import { LocalStorage } from '@src/enums/localStorage'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { memo, ReactNode, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

type AuthorizationPropsType = {
  children: ReactNode
}

export const Authorization = memo(({ children }: AuthorizationPropsType) => {
  const navigate = useNavigate()
  const [accessToken] = useLocalStorage<string>(LocalStorage.ACCESS_TOKEN, '')

  //アクセストークンが存在するかどうあ
  const hasAccessToken = useMemo(() => {
    if (accessToken === undefined || accessToken === '') {
      navigate('/', { replace: true })
    }
    return true
  }, [accessToken, navigate])

  if (hasAccessToken) {
    return <>{children}</>
  }
  return <></>
})
