import { useDisclosure } from '@chakra-ui/react'
import { DocumentsQuery } from '@src/graphql/generated/graphql'
import { useUpdateDocument } from '@src/models/Document/useUpdateDocument'
import { useCallback, useState } from 'react'

type Document = DocumentsQuery['documents']['collection'][number]

export const useUpdateDocumentModal = () => {
  const [document, setDocument] = useState<Document>()
  const { isOpen, onClose: _onClose, onOpen: _onOpen } = useDisclosure()
  const {
    findErrorMessage,
    form,
    isLoading,
    onUpdateDocument: _onUpdateDocument,
    resetForm,
  } = useUpdateDocument({
    onCompleted: () => {
      _onClose()
    },
  })
  const onClose = useCallback(() => {
    resetForm()
    _onClose()
  }, [_onClose, resetForm])

  const onOpen = useCallback(
    (document: Document) => {
      _onOpen()
      setDocument(document)
      form.setValue('name', document.name)
      form.setValue('changeLog', document.changeLog)
      form.setValue('url', document.url)
    },
    [form, _onOpen, setDocument]
  )

  const onUpdateDocument = useCallback(() => {
    if (!document) return
    _onUpdateDocument(document.uuid)()
  }, [document, _onUpdateDocument])

  return {
    findErrorMessage,
    form,
    isLoading,
    isOpen,
    onClose,
    onOpen,
    onUpdateDocument,
  }
}

type UseUpdateDocumentModalType = typeof useUpdateDocumentModal
export type UseUpdateDocumentModalResult =
  ReturnType<UseUpdateDocumentModalType>
