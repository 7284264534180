import { CompaniesNew } from '@src/components/pages/companies/new'
import { useCompaniesNewHooks } from '@src/components/pages/companies/new/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

//企業作成ページ
export const CompaniesNewPage = memo(() => {
  const companiesNewHooks = useCompaniesNewHooks()
  return (
    <Authorization>
      <CompaniesNew {...companiesNewHooks} />
    </Authorization>
  )
})
