import { Button, HStack, Stack } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import {
  AdminResearchForm,
  UseUpdateAdminResearchModalResult,
} from '@src/models/AdminResearch'

export const UpdateAdminResearchModal = ({
  findErrorMessage,
  form,
  isLoading,
  isOpen,
  onClose,
  onUpdateAdminResearch,
}: UseUpdateAdminResearchModalResult) => (
  <CsbModal isOpen={isOpen} onClose={onClose}>
    <CsbModalHeader>共通アンケートを編集する</CsbModalHeader>
    <CsbModalBody mt={10}>
      <Stack spacing={6}>
        <AdminResearchForm findErrorMessage={findErrorMessage} form={form} />
      </Stack>
    </CsbModalBody>
    <CsbModalFooter mt={7}>
      <HStack spacing={6}>
        <Button colorScheme={'gray'} onClick={onClose}>
          キャンセル
        </Button>
        <Button isLoading={isLoading} onClick={onUpdateAdminResearch}>
          編集
        </Button>
      </HStack>
    </CsbModalFooter>
  </CsbModal>
)
