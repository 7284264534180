import { currentPageState } from '@src/recoil/currentPageState'
import { parse, stringify } from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'

//Paginationを更新するhooks
export const usePagination = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [current, setCurrent] = useQueryParam(
    'page',
    withDefault(NumberParam, 1)
  )

  const [, setCurrentPage] = useRecoilState(currentPageState)

  const onChangePage =
    (url?: string) =>
    (currentPage = 1) => {
      const search = stringify({
        ...parse(location.search),
        page: currentPage,
      })

      setCurrentPage(currentPage)

      navigate({
        pathname: url ? url : location.pathname,
        search: `?${search}`,
      })
    }

  const currentPage = useRecoilValue(currentPageState)

  const onBackListPage = (url?: string) => {
    const [path, search] = url
      ? (() => (url?.includes('?') ? url.split('?') : [url, '']))()
      : [location.pathname, location.search]

    const params =
      currentPage > 1
        ? stringify({
            ...parse(search),
            page: currentPage,
          })
        : search
    navigate({
      pathname: path,
      search: `?${params}`,
    })
  }

  return { current, onBackListPage, onChangePage, setCurrent }
}

export type usePaginationType = ReturnType<typeof usePagination>
