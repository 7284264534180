import { useToast } from '@chakra-ui/react'
import {
  CreateCompanyInput,
  useCreateCompanyMutation,
} from '@src/graphql/generated/graphql'
import { useErrors, useMe } from '@src/hooks'
import { companyNewFormDefaultValues } from '@src/pages/companies/formDefaultValues'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

//企業作成で使うhooksのまとめ
export const useCompaniesNewHooks = () => {
  const { role } = useMe()
  const isWriteCompany = !!role?.writeCompany
  const toast = useToast()
  const navigate = useNavigate()
  const [createCompany, { loading: isCreating }] = useCreateCompanyMutation()
  const [errors, setErrors] = useErrors()

  const form = useForm<CreateCompanyInput>({
    defaultValues: companyNewFormDefaultValues,
  })

  const onSubmit = form.handleSubmit(async ({ users, ...inputData }) => {
    const [{ birthdayOn, joinedOn, ...user }] = users
    try {
      await createCompany({
        variables: {
          input: {
            ...inputData,
            users: [
              {
                birthdayOn: birthdayOn || null,
                joinedOn: joinedOn || null,
                ...user,
              },
            ],
          },
        },
      })
      setErrors([])
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'success',
        title: '企業アカウントを追加しました',
      })
      await navigate('/companies')
      /**
       * TODO anyを消す
       * e instanceOf ApolloError
       * setErrorsを変更する
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: '企業アカウントの作成に失敗しました',
      })
      setErrors(e.graphQLErrors)
    }
  })

  //TODO navigateを渡さない
  return {
    errors,
    form,
    isCreating,
    isWriteCompany,
    navigate,
    onSubmit,
  }
}

export type useCompaniesNewHooksResult = ReturnType<typeof useCompaniesNewHooks>
