import { useDisclosure, UseDisclosureProps, useToast } from '@chakra-ui/react'
import { Dispatch, SetStateAction, useState } from 'react'

export type onDeleteType = (
  // TODO anyを消す
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteData: Promise<any>,
  refetchData: () => Promise<{
    metadata: { currentPage: number; limitValue: number; totalCount: number }
  }>,
  updatePage: (currentPage?: number) => void
) => Promise<void>

export type onOpenType = (uuid: string, target: string) => void

//TODO 型をきれいに書く
type useDeleteModalType = () => {
  onDelete: onDeleteType
  onOpen: onOpenType
  setTarget: Dispatch<SetStateAction<string | undefined>>
  setUuid: Dispatch<SetStateAction<string | undefined>>
  target: string | undefined
  uuid: string | undefined
} & Required<Pick<UseDisclosureProps, 'isOpen' | 'onClose'>>

export const useDeleteModal: useDeleteModalType = () => {
  const { isOpen, onClose, onOpen: modalOpen } = useDisclosure()
  const [uuid, setUuid] = useState<string | undefined>(undefined)
  const [target, setTarget] = useState<string | undefined>(undefined)
  const toast = useToast()

  const onOpen = (uuid: string, target: string) => {
    setUuid(uuid)
    setTarget(target)
    modalOpen()
  }

  const onDelete = async <T>(
    deleteData: Promise<T>,
    refetchData: () => Promise<{
      metadata: { currentPage: number; limitValue: number; totalCount: number }
    }>,
    updatePage: (currentPage?: number) => void
  ) => {
    try {
      //削除
      await deleteData

      //ページの情報をrefetch
      const {
        metadata: { currentPage, limitValue, totalCount },
      } = await refetchData()

      //削除したことを通知する
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'success',
        title: `${target}を削除しました`,
      })

      //refetch後ページャーの中身が0の場合
      const isEmptyPage = totalCount % limitValue === 0
      //refetch後ページャーが最初のページの場合
      const isInitialPage = currentPage === 0
      //ページャーの中身が0かつ2ページ目以降の場合
      if (isEmptyPage && !isInitialPage) {
        //ページャーの値を現在のページ-1ページに更新する
        updatePage(currentPage - 1)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      //エラーのときの処理
      const message =
        e.graphQLErrors.length > 0
          ? e.graphQLErrors[0].message
          : `エラー${target}を削除できませんでした。`
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: message,
      })
    }
  }

  return {
    isOpen,
    onClose,
    onDelete,
    onOpen,
    setTarget,
    setUuid,
    target,
    uuid,
  }
}
