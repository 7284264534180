import { useDisclosure, useToast } from '@chakra-ui/react'
import { useState } from 'react'

export const useCsbDestroyModalHooks = <T>() => {
  //useDisclosureをwrapping
  const { isOpen, onClose: _onClose, onOpen: _onOpen } = useDisclosure()
  //dataを管理するstate
  const [data, setData] = useState<T | undefined>(undefined)
  const [header, setHeader] = useState('')
  const toast = useToast()

  const onOpen = (callback?: () => void) => {
    _onOpen()
    callback?.()
  }
  const onClose = (callback?: () => void) => {
    _onClose()
    callback?.()
  }

  const onCancel = () => {
    onClose(() => {
      setData(undefined)
      setHeader(``)
    })
  }

  const onDestroy = async <T>(
    destroyMutation: Promise<T>,
    {
      onCompleted,
    }: {
      onCompleted?: () => void
    } = {}
  ) => {
    try {
      await destroyMutation
      //削除したことを通知する
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'success',
        title: `${header}を削除しました`,
      })
      _onClose()
      onCompleted?.()
    } catch (e) {
      //エラーのときの処理
      //e.graphQLErrors[0]は空なため一旦uuidで対応
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: `エラー${header}を削除できませんでした。`,
      })
    }
  }

  return {
    data,
    header,
    isOpen,
    onCancel,
    onClose,
    onDestroy,
    onOpen,
    setData,
    setHeader,
  }
}

export type useCsbDestroyModalHooksResult = ReturnType<
  typeof useCsbDestroyModalHooks
>
