import { PhishingEdit } from '@src/components/pages/phishing/[id]/edit'
import { usePhishingEditHooks } from '@src/components/pages/phishing/[id]/edit/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const PhishingEditPage = memo(() => {
  const phishingEditHooks = usePhishingEditHooks()
  return (
    <Authorization>
      <PhishingEdit {...phishingEditHooks} />
    </Authorization>
  )
})
