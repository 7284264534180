import { useDisclosure } from '@chakra-ui/react'
import { AdminResearchesQuery } from '@src/graphql/generated/graphql'
import { useUpdateAdminResearch } from '@src/models/AdminResearch'
import { format } from 'date-fns'
import { useCallback, useState } from 'react'

type AdminResearch =
  AdminResearchesQuery['adminResearches']['collection'][number]

export const useUpdateAdminResearchModal = () => {
  const [adminResearch, setAdminResearch] = useState<AdminResearch>()
  const { isOpen, onClose: _onClose, onOpen: _onOpen } = useDisclosure()
  const {
    findErrorMessage,
    form,
    isLoading,
    onUpdateAdminResearch: _onUpdateAdminResearch,
    resetForm,
  } = useUpdateAdminResearch({
    onCompleted: () => {
      _onClose()
    },
  })
  const onClose = useCallback(() => {
    resetForm()
    _onClose()
  }, [_onClose, resetForm])

  const onOpen = useCallback(
    (adminResearch: AdminResearch) => {
      _onOpen()
      setAdminResearch(adminResearch)
      form.setValue('name', adminResearch.name)
      form.setValue('description', adminResearch.description)
      adminResearch.publishedAt &&
        form.setValue(
          'publishedAt',
          format(new Date(adminResearch.publishedAt), 'yyyy-MM-dd')
        )
      adminResearch.closedAt &&
        form.setValue(
          'closedAt',
          format(new Date(adminResearch.closedAt), 'yyyy-MM-dd')
        )
    },
    [form, _onOpen, setAdminResearch]
  )

  const onUpdateAdminResearch = useCallback(() => {
    if (!adminResearch) return
    _onUpdateAdminResearch(adminResearch.uuid)()
  }, [adminResearch, _onUpdateAdminResearch])

  return {
    findErrorMessage,
    form,
    isLoading,
    isOpen,
    onClose,
    onOpen,
    onUpdateAdminResearch,
  }
}

type UseUpdateAdminResearchModalType = typeof useUpdateAdminResearchModal
export type UseUpdateAdminResearchModalResult =
  ReturnType<UseUpdateAdminResearchModalType>
// export type UseUpdateAdminResearchModalParameter = Parameters<UseUpdateAdminResearchModalType>[0]
