import { Login } from '@src/components/pages/login'
import { useLoginHooks } from '@src/components/pages/login/hooks'
import React, { memo } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

//ログインページ
export const LoginPage = memo(() => {
  const loginHooks = useLoginHooks()
  return (
    <GoogleReCaptchaProvider
      language="ja"
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ''}
    >
      <Login {...loginHooks} />
    </GoogleReCaptchaProvider>
  )
})
