import { UsersNew } from '@src/components/pages/users/new'
import { useUsersNewHooks } from '@src/components/pages/users/new/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const UsersNewPage = memo(() => {
  const usersNewHooks = useUsersNewHooks()
  return (
    <Authorization>
      <UsersNew {...usersNewHooks} />
    </Authorization>
  )
})
