import { ApolloError } from '@apollo/client'
import {
  CreatePdfInput as _CreatePdfInput,
  CreatePdfMutationResult,
  useCreatePdfMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors, useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { formReset } from '@src/utils/formReset'
import { useForm } from 'react-hook-form'

type CreatePdfInput = Omit<_CreatePdfInput, 'clientMutationId'>

export const useCreatePdf = ({
  onCompleted,
  onError,
}: Partial<BaseOptionsType<CreatePdfMutationResult['data']>> = {}) => {
  const toast = useToast()
  const [createPdf, { loading: isLoading }] = useCreatePdfMutation({
    refetchQueries: ['pdfs'],
  })
  const form = useForm<CreatePdfInput>({
    defaultValues: {
      name: '',
      url: '',
    },
  })
  const { findErrorMessage, resetErrors, setErrors } =
    useGraphQLErrors<keyof CreatePdfInput>()
  const resetForm = () => {
    form.setValue('name', '')
    form.setValue('url', '')
  }

  const onCreatePdf = form.handleSubmit(async (inputData) => {
    try {
      const { data } = await createPdf({
        variables: {
          input: inputData,
        },
      })
      formReset(form, resetErrors)
      onCompleted?.(data)
      toast({
        status: 'success',
        title: 'PDFを作成しました。',
      })
    } catch (e) {
      toast({
        status: 'error',
        title: 'PDFの作成に失敗しました。',
      })
      if (e instanceof ApolloError) {
        setErrors(e.graphQLErrors)
        onError?.(e)
      }
    }
  })

  return {
    findErrorMessage,
    form,
    isLoading,
    onCreatePdf,
    resetErrors,
    resetForm,
  }
}

type UseCreatePdf = typeof useCreatePdf
export type UseCreatePdfResult = ReturnType<UseCreatePdf>
