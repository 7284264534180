import { useLayoutEffect, useRef } from 'react'

type EmbedProps = {
  html: string
}

const Embed = (props: EmbedProps) => {
  const divRef = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    if (!divRef.current) {
      return
    }

    const fragment = document.createRange().createContextualFragment(props.html)

    divRef.current.appendChild(fragment)
  }, [props.html])

  return <div ref={divRef} />
}

export default Embed
