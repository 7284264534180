import {
  Box,
  BoxProps,
  Center,
  Collapse,
  Flex,
  Input,
  Spinner,
} from '@chakra-ui/react'
import { CsbDeleteButton, CsbFormControl } from '@src/components'
import { useCreateS3PresignedUrlMutationMutation } from '@src/graphql/generated/graphql'
import { UseCreatePdfResult } from '@src/models/Pdf/useCreatePdf'
import { UseUpdatePdfResult } from '@src/models/Pdf/useUpdatePdf'
import axios from 'axios'
import { memo, useState } from 'react'
import { useDropzone } from 'react-dropzone'

type CsbDropzonePropsType = ReturnType<typeof useDropzone> & BoxProps

export const Dropzone = memo(
  ({
    getInputProps,
    getRootProps,
    isDragActive,
    ...props
  }: CsbDropzonePropsType) => {
    return (
      <Box
        {...getRootProps()}
        _hover={{
          backgroundColor: 'gray.50',
        }}
        alignItems={'center'}
        bg={isDragActive ? 'gray.50' : 'white'}
        borderRadius={10}
        borderStyle={'dashed'}
        borderWidth={'1px'}
        color={'gray.500'}
        cursor={'pointer'}
        d={'flex'}
        flexDirection={'column'}
        fontWeight={'bold'}
        h={150}
        justifyContent={'center'}
        p={3}
        w={150}
        {...props}
      >
        <input {...getInputProps()} />
        <small>PDFを選択</small>
        <small>ドラッグ&ドロップ</small>
      </Box>
    )
  }
)

export const PdfForm = ({
  findErrorMessage,
  form,
}:
  | Pick<UseCreatePdfResult, 'form' | 'findErrorMessage'>
  | Pick<UseUpdatePdfResult, 'form' | 'findErrorMessage'>) => {
  const [isLoading, setIsLoading] = useState(false)
  const [createS3PresidedUrl] = useCreateS3PresignedUrlMutationMutation()
  const dropzone = useDropzone({
    accept: '.pdf',
    onDrop: async (acceptedFiles) => {
      setIsLoading(true)
      try {
        const { data } = await createS3PresidedUrl({
          variables: {
            input: {
              fileName: acceptedFiles[0]?.name ?? '',
              savePath: 'Pdf',
            },
          },
        })
        if (data && data.createS3PresignedUrlMutation) {
          const response = await axios.put(
            data.createS3PresignedUrlMutation.presignedUrl,
            acceptedFiles[0],
            {
              headers: {
                ...(acceptedFiles[0] && {
                  'Content-Type': acceptedFiles[0]?.type,
                }),
              },
            }
          )
          if (response.status === 200) {
            form.setValue('url', data.createS3PresignedUrlMutation.uploadUrl)
          } else {
            //アップロードできなかった場合
            form.setValue('url', '')
          }
        } else {
          //データがない場合
          form.setValue('url', '')
        }
      } catch (e) {
        console.log(e)
      } finally {
        setIsLoading(false)
      }
    },
  })
  return (
    <>
      <CsbFormControl errorText={findErrorMessage('name')}>
        <Input {...form.register('name')} placeholder={'ドキュメントの名前*'} />
      </CsbFormControl>
      <CsbFormControl errorText={findErrorMessage('url')}>
        <Flex>
          <Input {...form.register('url')} />
          <CsbDeleteButton
            isDisabled={!form.watch().url}
            ml={2}
            onClick={() => {
              form.setValue('url', '')
            }}
          />
        </Flex>
      </CsbFormControl>
      <Collapse animateOpacity in={!form.watch().url}>
        <Box mt={'12px !important'}>
          {isLoading ? (
            <Center bgColor={'gray.100'} h={150} w={'full'}>
              <Spinner />
            </Center>
          ) : (
            <Dropzone w={'full'} {...dropzone} />
          )}
        </Box>
      </Collapse>
    </>
  )
}
