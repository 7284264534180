import { ApolloError } from '@apollo/client'
import {
  DestroyAdminResearchInput,
  DestroyAdminResearchMutationResult,
  useDestroyAdminResearchMutation,
} from '@src/graphql/generated/graphql'
import { useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { useCallback } from 'react'

export const useDestroyAdminResearch = ({
  onCompleted,
  onError,
}: Partial<
  BaseOptionsType<DestroyAdminResearchMutationResult['data']>
> = {}) => {
  const [destroyAdminResearch, { loading: isLoading }] =
    useDestroyAdminResearchMutation({
      refetchQueries: ['adminResearches'],
    })
  const toast = useToast()
  const onDestroyAdminResearch = useCallback(
    async (uuid: DestroyAdminResearchInput['uuid']) => {
      try {
        const { data } = await destroyAdminResearch({
          variables: {
            input: { uuid },
          },
        })
        toast({
          status: 'success',
          title: 'アンケートを削除しました',
        })
        onCompleted?.(data)
      } catch (e) {
        toast({
          status: 'error',
          title: 'アンケートの削除に失敗しました。',
        })
        if (e instanceof ApolloError) {
          onError?.(e)
        }
      }
    },
    [onError, toast, onCompleted, destroyAdminResearch]
  )

  return { isLoading, onDestroyAdminResearch }
}

type UseDestroyAdminResearchType = typeof useDestroyAdminResearch
export type UseDestroyAdminResearchResult =
  ReturnType<UseDestroyAdminResearchType>
// export type UseDestroyAdminResearchParameter = Parameters<UseDestroyAdminResearchType>[0]
