import { Button, HStack, Stack } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import { UseCreateResearchQuestionModalResult } from '@src/models/Research'
import { ResearchQuestionForm } from '@src/models/Research/components/ResearchQuestionForm'

export const CreateResearchQuestionModal = ({
  findErrorMessage,
  form,
  id,
  isLoading,
  isOpen,
  onClose,
  onCreateResearchQuestion,
}: UseCreateResearchQuestionModalResult) => (
  <CsbModal isOpen={isOpen} onClose={onClose}>
    <CsbModalHeader>アンケートを作成する</CsbModalHeader>
    <CsbModalBody mt={10}>
      <Stack ml={2} spacing={6}>
        <ResearchQuestionForm findErrorMessage={findErrorMessage} form={form} />
      </Stack>
    </CsbModalBody>
    <CsbModalFooter mt={7}>
      <HStack spacing={6}>
        <Button colorScheme={'gray'} onClick={onClose}>
          キャンセル
        </Button>
        <Button
          isLoading={isLoading}
          onClick={onCreateResearchQuestion(id ?? '')}
        >
          作成
        </Button>
      </HStack>
    </CsbModalFooter>
  </CsbModal>
)
