import { useRiskUsersQuery } from '@src/graphql/generated/graphql'

export const useRiskUsers = (
  baseOptions: Partial<Parameters<typeof useRiskUsersQuery>[0]> = {}
) => {
  return useRiskUsersQuery(baseOptions)
}

export type useRiskUsersHooksResult = ReturnType<typeof useRiskUsers>
export type useRiskUsersHooksParameter = Parameters<typeof useRiskUsers>[0]
