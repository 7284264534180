import { paginationInput } from '@src/constants'
import { useMe, usePagination } from '@src/hooks'
import {
  useCreateResearchModal,
  useDestroyResearchModal,
  useResearches,
} from '@src/models/Research'
import { useCopyResearchModal } from '@src/models/Research/components/CopyResearchModal/useCopyResearchModal'
import { useUpdateResearchModal } from '@src/models/Research/components/UpdateResearchModal/useUpdateResearchModal'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

export const useResearchesHooks = () => {
  const { role } = useMe()
  const isWriteResearch = !!role?.writeResearch

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    searchWord: withDefault(StringParam, ''),
  })
  //ページネーション
  const { current, onChangePage } = usePagination()
  const researches = useResearches({
    variables: {
      pagination: paginationInput(current),
      searchWord: query.searchWord,
    },
  })
  const navigate = useNavigate()
  const createResearchModal = useCreateResearchModal()
  const destroyResearchModal = useDestroyResearchModal()
  const updateResearchModal = useUpdateResearchModal()
  const copyResearchModal = useCopyResearchModal()

  //search機能
  const searchForm = useForm<{ searchWord: string }>({
    defaultValues: {
      searchWord: query.searchWord,
    },
  })
  const onSearch = searchForm.handleSubmit(async (inputData) => {
    setQuery({
      page: 1,
      searchWord: inputData.searchWord,
    })
  })
  const onResetSearch = () => {
    searchForm.setValue('searchWord', '')

    setQuery({
      page: 1,
      searchWord: '',
    })
  }

  return {
    copyResearchModal,
    createResearchModal,
    current,
    destroyResearchModal,
    isWriteResearch,
    navigate,
    onChangePage,
    onResetSearch,
    onSearch,
    researches,
    searchForm,
    updateResearchModal,
  }
}

export type useResearchesHooksResult = ReturnType<typeof useResearchesHooks>
