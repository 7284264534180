import { Box, Button, Skeleton, Text } from '@chakra-ui/react'
import {
  CsbList,
  CsbListItem,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import { CsbDataModal } from '@src/components/Modal/CsbDataModal'
import { useTemplateModalResult } from '@src/components/Modal/CsbTemplateModal/hooks'
import { ComponentProps, memo } from 'react'

type CsbTemplateModalPropsType = useTemplateModalResult &
  Omit<ComponentProps<typeof CsbDataModal>, 'children'>

export const CsbTemplateModal = memo(
  ({
    data,
    loading,
    onCancel,
    onReflect,
    ...props
  }: CsbTemplateModalPropsType) => {
    return (
      <CsbDataModal size={'2xl'} {...props}>
        <CsbModalHeader>メールテンプレート一覧</CsbModalHeader>
        <CsbModalBody mt={'18px'}>
          <Text>
            {data && data.length <= 0
              ? 'メールテンプレートが存在しません。'
              : `${data?.length}件のテンプレートから選択できます。`}
          </Text>
          <Box mt={'38px'}>
            <Text
              alignItems={'center'}
              bg={'gray.50'}
              display={'flex'}
              fontSize={'xs'}
              fontWeight={'bold'}
              px={6}
              py={3}
            >
              テンプレート名
            </Text>
            <CsbList
              border={'none'}
              maxH={'300px'}
              overflowY={'auto'}
              p={0}
              rounded={'none'}
              shadow={'none'}
            >
              {!data || loading ? (
                <CsbListItem px={0}>
                  <Skeleton h={12} w={'full'} />
                </CsbListItem>
              ) : (
                data?.map((item) => (
                  <CsbListItem
                    display={'grid'}
                    gridColumnGap={3}
                    gridTemplateColumns={'1fr auto'}
                    key={item.uuid}
                  >
                    <Text isTruncated fontSize={'sm'} fontWeight={'bold'}>
                      {item.name}
                    </Text>
                    <Button onClick={() => onReflect(item)}>反映する</Button>
                  </CsbListItem>
                ))
              )}
            </CsbList>
          </Box>
        </CsbModalBody>
        <CsbModalFooter mt={7}>
          <Button colorScheme={'gray'} onClick={onCancel}>
            キャンセル
          </Button>
        </CsbModalFooter>
      </CsbDataModal>
    )
  }
)
