import { useDisclosure } from '@chakra-ui/react'
import { useCreateAdminResearchQuestion } from '@src/models/AdminResearch'
import { useCallback } from 'react'

export const useCreateAdminResearchQuestionModal = (id: string) => {
  const { isOpen, onClose: _onClose, onOpen } = useDisclosure()
  const {
    findErrorMessage,
    form,
    isLoading,
    onCreateAdminResearchQuestion,
    resetForm,
  } = useCreateAdminResearchQuestion({
    onCompleted: () => {
      _onClose()
    },
  })
  const onClose = useCallback(() => {
    resetForm()
    _onClose()
  }, [_onClose, resetForm])

  return {
    findErrorMessage,
    form,
    id,
    isLoading,
    isOpen,
    onClose,
    onCreateAdminResearchQuestion,
    onOpen,
  }
}

type UseAdminCreateResearchQuestionModalType =
  typeof useCreateAdminResearchQuestionModal
export type UseAdminCreateResearchQuestionModalResult =
  ReturnType<UseAdminCreateResearchQuestionModalType>
// export type UseAdminCreateResearchModalParameter = Parameters<UseAdminCreateResearchQuestionModalType>[0]
