import {
  CompaniesQuery,
  CompanyMailLimitKind,
} from '@src/graphql/generated/graphql'
import dayjs from 'dayjs'

type CompanyMailLimitsType =
  CompaniesQuery['companies']['collection'][0]['companyMailLimits']

type CompanyMailLimitType = CompanyMailLimitsType[0]

export const companyMailLimitKindTypes: Array<[CompanyMailLimitKind, string]> =
  [
    [CompanyMailLimitKind.PhishingOnly, '訓練'],
    [CompanyMailLimitKind.ContentsOnly, 'コンテンツ'],
    [CompanyMailLimitKind.Both, '訓練＋コンテンツ'],
  ]

const map = new Map(companyMailLimitKindTypes)

export const getCompanyMailLimitTypeName = (limitType: CompanyMailLimitKind) =>
  map.get(limitType) ?? ''

const toDates = (
  companyMailLimit: CompanyMailLimitType,
  defaultEndDate: string
) => [
  dayjs(companyMailLimit.startDate).format('YYYY/MM/DD'),
  companyMailLimit.endDate
    ? dayjs(companyMailLimit.endDate).format('YYYY/MM/DD')
    : defaultEndDate,
]

export const getActiveCompanyMailLimits = (
  companyMailLimits: CompanyMailLimitsType
) => {
  const today = dayjs().format('YYYY/MM/DD')
  return companyMailLimits.filter((companyMailLimit) => {
    const [startDate, endDate] = toDates(companyMailLimit, '9999/12/31')
    return startDate <= today && today <= endDate
  })
}

export const getActiveCompanyMailLimit = (
  companyMailLimits: CompanyMailLimitsType,
  isDeliveryContentsOnly: boolean
) => {
  const filters = getActiveCompanyMailLimits(companyMailLimits).filter(
    (companyMailLimit) => {
      if (isDeliveryContentsOnly) {
        return (
          companyMailLimit.limitType === CompanyMailLimitKind.ContentsOnly ||
          companyMailLimit.limitType === CompanyMailLimitKind.Both
        )
      } else {
        return (
          companyMailLimit.limitType === CompanyMailLimitKind.PhishingOnly ||
          companyMailLimit.limitType === CompanyMailLimitKind.Both
        )
      }
    }
  )
  return filters.length > 0 ? filters[0] : undefined
}

export const getActiveCompanyMailLimitStrings = (
  companyMailLimits: CompanyMailLimitsType
) =>
  getActiveCompanyMailLimits(companyMailLimits).map((companyMailLimit) => {
    const limitTypeName = getCompanyMailLimitTypeName(
      companyMailLimit.limitType
    )
    const [, endDate] = toDates(companyMailLimit, '期限なし')
    const endDateString = endDate === '期限なし' ? '期限なし' : ' 〜 ' + endDate

    return `${limitTypeName}：${companyMailLimit.currentCount.toLocaleString()}/${
      companyMailLimit.limitCount?.toLocaleString() ?? '無制限'
    }（${endDateString}）`
  })
