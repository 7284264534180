import { useDocumentsQuery } from '@src/graphql/generated/graphql'

export const useDocuments = (
  baseOptions: Partial<Parameters<typeof useDocumentsQuery>[0]> = {}
) => {
  return useDocumentsQuery(baseOptions)
}

type UseDocuments = typeof useDocuments
export type UseDocumentsResult = ReturnType<UseDocuments>
