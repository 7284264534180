import {
  useDisclosure,
  UseDisclosureProps,
  useToast,
  UseToastOptions,
} from '@chakra-ui/react'
import { useErrors } from '@src/hooks/useErrors'
import { Dispatch, SetStateAction, useState } from 'react'
import { useForm, UseFormProps, UseFormReturn } from 'react-hook-form'

type formStateType = 'create' | 'update' | 'add'

type useFormModalType = <FieldValues>(
  useFormProps: UseFormProps<FieldValues>
) => {
  errors: ReturnType<typeof useErrors>[0]
  form: UseFormReturn<FieldValues>
  isCreate: boolean
  isOpen: NonNullable<UseDisclosureProps['isOpen']>
  isUpdate: boolean
  onCancel: () => void
  onClose: NonNullable<UseDisclosureProps['onClose']>
  onCreate: () => void
  onOpen: NonNullable<UseDisclosureProps['onOpen']>
  setErrors: ReturnType<typeof useErrors>[1]
  setState: Dispatch<SetStateAction<formStateType>>
  setUuid: Dispatch<SetStateAction<string>>
  state: formStateType
  toast: (options: UseToastOptions) => void
  uuid: string
}

export const useFormModal: useFormModalType = (useFormProps) => {
  //新規作成、編集のModal
  const { isOpen, onClose, onOpen } = useDisclosure()
  //新規作成、編集の編集対象のuuid
  const [uuid, setUuid] = useState('')
  //新規作成、編集のどちらかか判定するstate
  const [state, setState] = useState<formStateType>('create')
  //formのエラーを格納する配列
  const [errors, setErrors] = useErrors()

  //作成するモーダルを開く関数
  const onCreate = () => {
    onOpen()
    setState('create')
  }

  //現在作成するモーダルかどうか
  const isCreate = state === 'create'
  //現在編集するモーダルかどうか
  const isUpdate = state === 'update'

  //useFormのwrapper
  const form = useForm(useFormProps)

  //新規作成、編集のModalを閉じる時の関数
  const onCancel = () => {
    setErrors([])
    onClose()
    form.reset()
  }

  //新規作成、編集のToast
  const toastBase = useToast()
  const toast = (options: UseToastOptions) => {
    return toastBase({
      duration: 3000,
      isClosable: true,
      position: 'top',
      ...options,
    })
  }

  return {
    errors,
    form,
    isCreate,
    isOpen,
    isUpdate,
    onCancel,
    onClose,
    onCreate,
    onOpen,
    setErrors,
    setState,
    setUuid,
    state,
    toast,
    uuid,
  }
}
