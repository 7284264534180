import { ApolloError } from '@apollo/client'
import {
  ResearchAnswerType,
  UpdateAdminResearchQuestionInput,
  UpdateAdminResearchQuestionMutationResult,
  useUpdateAdminResearchQuestionMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors, useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { formReset } from '@src/utils/formReset'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

export const useUpdateAdminResearchQuestion = ({
  onCompleted,
  onError,
}: Partial<
  BaseOptionsType<UpdateAdminResearchQuestionMutationResult['data']>
> = {}) => {
  const toast = useToast()
  const [updateAdminResearchQuestionMutation, { loading: isLoading }] =
    useUpdateAdminResearchQuestionMutation({
      refetchQueries: ['adminResearch'],
    })
  const form =
    useForm<
      Omit<UpdateAdminResearchQuestionInput, 'clientMutationId' | 'uuid'>
    >()
  const { findErrorMessage, resetErrors, setErrors } =
    useGraphQLErrors<
      keyof Omit<UpdateAdminResearchQuestionInput, 'clientMutationId' | 'uuid'>
    >()
  const resetForm = () => {
    formReset(form, resetErrors)
    form.resetField('answers.1')
    form.resetField('answers.2')
    form.resetField('answers.3')
    form.resetField('answers.4')
  }

  const onUpdateAdminResearchQuestion = useCallback(
    (uuid: UpdateAdminResearchQuestionInput['uuid']) =>
      form.handleSubmit(async ({ answerType, answers, ...inputData }) => {
        try {
          const { data } = await updateAdminResearchQuestionMutation({
            variables: {
              input: {
                ...inputData,
                answerType,
                answers:
                  answerType === ResearchAnswerType.Textarea
                    ? []
                    : answers.filter(Boolean),
                uuid,
              },
            },
          })
          formReset(form, resetErrors)
          onCompleted?.(data)
          toast({
            status: 'success',
            title: 'アンケートの更新に成功しました。',
          })
        } catch (e) {
          toast({
            status: 'error',
            title: 'アンケートの更新に失敗しました。',
          })
          if (e instanceof ApolloError) {
            setErrors(e.graphQLErrors)
            onError?.(e)
          }
        }
      }),
    [
      form,
      setErrors,
      resetErrors,
      toast,
      onCompleted,
      onError,
      updateAdminResearchQuestionMutation,
    ]
  )

  return {
    findErrorMessage,
    form,
    isLoading,
    onUpdateAdminResearchQuestion,
    resetForm,
  }
}

type UseUpdateAdminResearchQuestionType = typeof useUpdateAdminResearchQuestion
export type UseUpdateAdminResearchQuestionResult =
  ReturnType<UseUpdateAdminResearchQuestionType>
// export type UseUpdateAdminResearchQuestionParameter = Parameters<UseUpdateAdminResearchQuestionType>[0]
