import { Tag as ChakraTag, TagProps } from '@chakra-ui/react'

//tagデフォルトのcolorSchemeは薄いのでbgとcolorで作成
const scheme = {
  blue: {
    bg: 'blue.500',
    color: 'white',
  },
  gray: {
    bg: 'gray.200',
    color: 'black',
  },
  green: {
    bg: 'green.500',
    color: 'white',
  },
  orange: {
    bg: '#FF4500',
    color: 'white',
  },
  red: {
    bg: 'red.500',
    color: 'white',
  },
  teal: {
    bg: 'teal.500',
    color: 'white',
  },
} as const

type TagPropsType = Omit<TagProps, 'colorScheme'> & {
  colorScheme?: keyof typeof scheme
}

export const CsbTag = ({
  children,
  colorScheme = 'teal',
  ...props
}: TagPropsType) => {
  return (
    <ChakraTag
      fontSize={'xs'}
      fontWeight={'medium'}
      justifyContent={'center'}
      size={'sm'}
      {...scheme[colorScheme]}
      {...props}
    >
      {children}
    </ChakraTag>
  )
}
