import { ApolloError } from '@apollo/client'
import {
  UpdateAdminResearchMutationResult,
  UpdatePdfInput as _UpdatePdfInput,
  useUpdatePdfMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors, useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { formReset } from '@src/utils/formReset'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

type UpdatePdfInput = Omit<_UpdatePdfInput, 'clientMutationId' | 'uuid'>

export const useUpdatePdf = ({
  onCompleted,
  onError,
}: Partial<
  BaseOptionsType<UpdateAdminResearchMutationResult['data']>
> = {}) => {
  const toast = useToast()
  const [updatePdf, { loading: isLoading }] = useUpdatePdfMutation({
    refetchQueries: ['pdfs'],
  })
  const form = useForm<UpdatePdfInput>({
    defaultValues: {
      name: '',
      url: '',
    },
  })
  const { findErrorMessage, resetErrors, setErrors } =
    useGraphQLErrors<keyof UpdatePdfInput>()
  const resetForm = () => formReset(form, resetErrors)

  const onUpdatePdf = useCallback(
    (uuid: _UpdatePdfInput['uuid']) =>
      form.handleSubmit(async (inputData) => {
        try {
          const { data } = await updatePdf({
            variables: {
              input: { ...inputData, uuid },
            },
          })
          formReset(form, resetErrors)
          onCompleted?.(data)
          toast({
            status: 'success',
            title: 'PDFの更新に成功しました。',
          })
        } catch (e) {
          toast({
            status: 'error',
            title: 'PDFの更新に失敗しました。',
          })
          if (e instanceof ApolloError) {
            setErrors(e.graphQLErrors)
            onError?.(e)
          }
        }
      }),
    [form, setErrors, resetErrors, toast, onCompleted, onError, updatePdf]
  )

  return { findErrorMessage, form, isLoading, onUpdatePdf, resetForm }
}

type UseUpdatePdf = typeof useUpdatePdf
export type UseUpdatePdfResult = ReturnType<UseUpdatePdf>
