import {
  Box,
  Flex,
  Grid,
  Heading,
  RadioGroup,
  Tag,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { CsbFormControl, CsbHeader, CsbPrimaryBox } from '@src/components'
import { CsbNewLineText } from '@src/components/CsbNewLineText'
import { CsbQuestionCheckbox } from '@src/components/CsbQuestionCheckbox'
import { CsbQuestionRadio } from '@src/components/CsbQuestionRadio'
import { usePreviewResearchHooksResult } from '@src/components/pages/preview/researches/[id]/hooks'
import { ResearchQuestionsQuery } from '@src/graphql/generated/graphql'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Q = ({
  researchQuestion,
}: {
  researchQuestion: ResearchQuestionsQuery['researchQuestions'][number]
}) => {
  const { t } = useTranslation()
  const isIncludeNameNewLine = researchQuestion.name.indexOf('\n') > -1
  const [radioValue, setRadioValue] = useState('')
  const [checkboxValues, setCheckboxValues] = useState<string[]>([])

  return (
    <Box textAlign={'left'}>
      <Heading fontSize={16}>
        <CsbNewLineText text={researchQuestion.name} />
        {researchQuestion.isRequired ? (
          <Tag
            bgColor={'red.500'}
            color={'white'}
            fontWeight={'medium'}
            ml={!isIncludeNameNewLine ? 2 : 0}
          >
            {t('required')}
          </Tag>
        ) : (
          <></>
        )}
      </Heading>
      <CsbFormControl mt={4}>
        {researchQuestion.answerType === 'radio' ? (
          <>
            <RadioGroup style={{ display: 'flex', flexDirection: 'column' }}>
              <Grid gap={1} gridColumn={'1fr'}>
                {researchQuestion.answers.map((answer) => (
                  <CsbQuestionRadio
                    isChecked={radioValue === answer}
                    key={answer}
                    mb={2}
                    value={answer}
                    onChange={(e) => setRadioValue(e.target.value)}
                  >
                    {answer}
                  </CsbQuestionRadio>
                ))}
              </Grid>
            </RadioGroup>
          </>
        ) : researchQuestion.answerType === 'checkbox' ? (
          <>
            {researchQuestion.answers.map((answer, index) => (
              <CsbQuestionCheckbox
                isChecked={checkboxValues.includes(answer)}
                key={index}
                value={answer}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckboxValues([...checkboxValues, answer])
                  } else {
                    setCheckboxValues(
                      checkboxValues.filter((v) => v !== answer)
                    )
                  }
                }}
              >
                {answer}
              </CsbQuestionCheckbox>
            ))}
          </>
        ) : (
          <>
            <Textarea />
          </>
        )}
      </CsbFormControl>
    </Box>
  )
}

//アンケートページのcomponent
export const PreviewResearch = memo(
  ({
    data,
    isDisplayLogoInResearch,
    loading,
  }: usePreviewResearchHooksResult) => {
    const { t } = useTranslation()

    return (
      <>
        {isDisplayLogoInResearch ? <CsbHeader /> : null}
        <Flex
          alignItems="center"
          backgroundColor="gray.200"
          flexDirection="column"
          minHeight={isDisplayLogoInResearch ? 'calc(100vh - 80px)' : '100vh'}
          // justifyContent="center"
          p={20}
          width="100wh"
        >
          {!loading && data ? (
            <Box
              backgroundColor={'white'}
              maxW={600}
              p={5}
              rounded={5}
              textAlign={'center'}
              w={'full'}
            >
              <>
                <Text>{t('surveyMessage')}</Text>
                <CsbPrimaryBox
                  display={'flex'}
                  flexDirection={'column'}
                  gap={10}
                  mt={5}
                  px={5}
                  py={6}
                >
                  {data?.research.researchQuestions.map((researchQuestion) => (
                    <Q
                      key={researchQuestion.uuid}
                      researchQuestion={researchQuestion}
                    />
                  ))}
                </CsbPrimaryBox>
              </>
            </Box>
          ) : (
            <>{t('dataNotFound')}</>
          )}
        </Flex>
      </>
    )
  }
)
