import { SettingsIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Heading,
  HStack,
  IconButton,
  Input,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import {
  CsbDeleteButton,
  CsbDeleteModal,
  CsbFormControl,
  CsbFormModal,
  CsbList,
  CsbListItem,
  CsbModalBody,
  CsbModalHeader,
  CsbPagination,
  CsbSearchInput,
} from '@src/components'
import { CsbEditButton } from '@src/components/Buttons/CsbEditButton'
import { CsbTable } from '@src/components/CsbTable'
import { CsbDataModal } from '@src/components/Modal/CsbDataModal'
import { SelectCompaniesModal } from '@src/components/Modal/SelectCompanyModal/SelectCompaniesModal'
import { useSitesHooksResult } from '@src/components/pages/sites/hooks'
import { Base } from '@src/layouts'
import { findError } from '@src/utils/findError'
import React, { memo, useState } from 'react'

export const Sites = memo(
  ({
    current,
    data,
    dataModal,
    deleteModal,
    destroyPhishingSiteMutation,
    formModal,
    isCreating,
    isUpdating,
    isUpdatingPhishingSiteCompanies,
    isWritePhishingSite,
    onChangePage,
    onResetSearch,
    onSearch,
    onSubmit,
    onUpdate,
    onUpdateSelectCompanies,
    refetch,
    searchForm,
  }: useSitesHooksResult) => {
    const selectCompanyModal = useDisclosure()
    const [companyUuids, setCompanyUuids] = useState<string[]>([])
    const [uuid, setUuid] = useState<string>('')

    return (
      <Base
        left={<Heading>フィッシングサイト</Heading>}
        right={
          isWritePhishingSite && (
            <Button minW={210} onClick={formModal.onCreate}>
              新しいフィッシングサイトを追加
            </Button>
          )
        }
      >
        <Box maxW={518} mt={12} w={'full'}>
          <CsbSearchInput
            placeholder={'フィッシングサイト名を入力'}
            {...searchForm.register('searchWord')}
            onReset={onResetSearch}
            onSearch={onSearch}
          />
        </Box>
        <CsbTable
          labels={['フィッシングサイト', '説明', '対象企業', '']}
          wrapperProps={{ mt: 35 }}
        >
          {data?.phishingSites.collection.map((item) => (
            <CsbTable.TableRow key={item.uuid}>
              <CsbTable.TableCell w={300}>
                <Tooltip aria-label="A tooltip" label={item.name}>
                  <Text isTruncated fontWeight={'bold'} w={300}>
                    {item.name}
                  </Text>
                </Tooltip>
              </CsbTable.TableCell>
              <CsbTable.TableCell w={300}>
                <Tooltip aria-label="A tooltip" label={item.description}>
                  <Text isTruncated color={'gray.500'} fontSize={'sm'} w={300}>
                    {item.description}
                  </Text>
                </Tooltip>
              </CsbTable.TableCell>

              <CsbTable.TableCell w={200}>
                <Tooltip aria-label="A tooltip">
                  <Text
                    isTruncated
                    _hover={{ textDecoration: 'underline' }}
                    as={'button'}
                    color={'gray.500'}
                    fontSize={'xs'}
                    type={'button'}
                    w={200}
                    onClick={(e) => {
                      e.stopPropagation()
                      dataModal.onOpen()
                      dataModal.setData({
                        items: item.companies,
                        title: '対象企業',
                      })
                    }}
                  >
                    {item.companies.map((company) => company.name).join(',')}
                  </Text>
                </Tooltip>
              </CsbTable.TableCell>

              <CsbTable.TableCell isNumeric>
                {isWritePhishingSite && (
                  <Flex gridGap={5} justifyContent={'end'}>
                    <Tooltip label={'対象企業設定'}>
                      <IconButton
                        aria-label="preview"
                        colorScheme={'blue'}
                        icon={<SettingsIcon />}
                        rounded={'full'}
                        onClick={(e) => {
                          e.stopPropagation()
                          const companyUuids = item.companies.map(
                            (company) => company.uuid
                          )
                          setCompanyUuids(companyUuids)
                          setUuid(item.uuid)
                          selectCompanyModal.onOpen()
                        }}
                      />
                    </Tooltip>
                    <CsbEditButton
                      onClick={(e) => {
                        e.stopPropagation()
                        onUpdate(item)
                      }}
                    />
                    {!item.locked && (
                      <CsbDeleteButton
                        onClick={(e) => {
                          e.stopPropagation()
                          deleteModal.onOpen(item.uuid, item.name)
                        }}
                      />
                    )}
                  </Flex>
                )}
              </CsbTable.TableCell>
            </CsbTable.TableRow>
          ))}
        </CsbTable>
        <Flex mt={5}>
          {data ? (
            <CsbPagination
              current={current}
              defaultCurrent={current}
              pageSize={data.phishingSites.metadata.limitValue}
              total={data.phishingSites.metadata.totalCount}
              onChange={onChangePage('/sites')}
            />
          ) : null}
          <HStack ml={'auto'} spacing={3}>
            {isWritePhishingSite && (
              <Button minW={210} onClick={formModal.onCreate}>
                新しいフィッシングサイトを追加
              </Button>
            )}
          </HStack>
        </Flex>
        <CsbDeleteModal
          header={deleteModal.target}
          isOpen={deleteModal.isOpen}
          onCancel={deleteModal.onClose}
          onClose={deleteModal.onClose}
          onDelete={() =>
            deleteModal.onDelete(
              destroyPhishingSiteMutation({
                variables: {
                  input: { uuid: deleteModal.uuid ?? '' },
                },
              }),
              async () => {
                const { data } = await refetch()
                return {
                  metadata: {
                    currentPage: data.phishingSites.metadata.currentPage,
                    limitValue: data.phishingSites.metadata.limitValue,
                    totalCount: data.phishingSites.metadata.totalCount,
                  },
                }
              },
              onChangePage('/sites')
            )
          }
        />
        <CsbFormModal
          header={
            formModal.isCreate
              ? 'フィッシングサイトの追加'
              : `${
                  formModal.form.getValues('name') || 'フィッシングサイト'
                }の編集`
          }
          isLoading={formModal.isCreate ? isCreating : isUpdating}
          isOpen={formModal.isOpen}
          submit={formModal.isCreate ? '追加' : '編集'}
          onCancel={formModal.onCancel}
          onClose={formModal.onCancel}
          onSubmit={formModal.form.handleSubmit(onSubmit)}
        >
          <CsbFormControl
            errorText={findError(formModal.errors, 'name')?.message}
          >
            <Input
              {...formModal.form.register('name')}
              placeholder={'サイト名*'}
            />
          </CsbFormControl>
          <CsbFormControl
            errorText={findError(formModal.errors, 'description')?.message}
          >
            <Textarea
              {...formModal.form.register('description')}
              placeholder={'説明'}
            />
          </CsbFormControl>
          <CsbFormControl
            errorText={findError(formModal.errors, 'url')?.message}
          >
            <Input
              type={'url'}
              {...formModal.form.register('url')}
              placeholder={'url'}
            />
          </CsbFormControl>
          <CsbFormControl
            errorText={findError(formModal.errors, 'locked')?.message}
          >
            <Checkbox
              {...formModal.form.register('locked')}
              defaultChecked={formModal.form.getValues('locked')}
              onChange={(e) =>
                formModal.form.setValue('locked', e.target.checked)
              }
            >
              このフィッシングサイトを保護する
            </Checkbox>
          </CsbFormControl>
        </CsbFormModal>

        <SelectCompaniesModal
          companyUuids={companyUuids}
          disclosure={selectCompanyModal}
          isSubmitting={isUpdatingPhishingSiteCompanies}
          uuid={uuid}
          onSubmit={onUpdateSelectCompanies}
        />
        <CsbDataModal {...dataModal} size={'2xl'}>
          <CsbModalHeader>{dataModal.data?.title}</CsbModalHeader>
          <CsbModalBody mb={2} mt={2}>
            <Box mt={'38px'}>
              <Grid bg={'gray.50'} templateColumns={'1fr 3fr'}>
                <Text
                  alignItems={'center'}
                  display={'flex'}
                  fontSize={'xs'}
                  fontWeight={'bold'}
                  px={6}
                  py={3}
                >
                  ID
                </Text>
                <Text
                  alignItems={'center'}
                  display={'flex'}
                  fontSize={'xs'}
                  fontWeight={'bold'}
                  px={6}
                  py={3}
                >
                  企業名
                </Text>
              </Grid>
              <CsbList
                border={'none'}
                maxH={'300px'}
                overflowY={'auto'}
                p={0}
                rounded={'none'}
                shadow={'none'}
              >
                {dataModal.data?.items.map(({ id, name }, i) => (
                  <CsbListItem
                    display={'grid'}
                    gridTemplateColumns={'1fr 3fr'}
                    key={i}
                    p={0}
                  >
                    <Text fontSize={'md'} px={6}>
                      {id}
                    </Text>
                    <Text fontSize={'md'} px={4}>
                      {name}
                    </Text>
                  </CsbListItem>
                ))}
              </CsbList>
            </Box>
          </CsbModalBody>
        </CsbDataModal>
      </Base>
    )
  }
)
