import { Settings } from '@src/components/pages/settings'
import { useSettingHooks } from '@src/components/pages/settings/hooks'
import { Authorization } from '@src/layouts/Authorization'

export const SettingsPage = () => {
  const settingHooks = useSettingHooks()
  return (
    <Authorization>
      <Settings {...settingHooks} />
    </Authorization>
  )
}
