import { CsbModal } from '@src/components'
import { useCsbDataModalResult } from '@src/hooks/useCsbDataModal'
import { ComponentProps, memo, ReactNode } from 'react'

type CsbDataModalPropsType = {
  children: ReactNode
} & Omit<ComponentProps<typeof CsbModal>, 'children'> &
  Omit<useCsbDataModalResult, 'data' | 'setData'>

export const CsbDataModal = memo(
  ({ children, ...props }: CsbDataModalPropsType) => {
    return <CsbModal {...props}>{children}</CsbModal>
  }
)
