import { UsersEdit } from '@src/components/pages/users/[id]/edit'
import { useUsersEditHooks } from '@src/components/pages/users/[id]/edit/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const UsersEditPage = memo(() => {
  const usersEditHooks = useUsersEditHooks()
  return (
    <Authorization>
      <UsersEdit {...usersEditHooks} />
    </Authorization>
  )
})
