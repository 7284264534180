import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { CsbNextLink, CsbPrimaryBox, CsbTemplateModal } from '@src/components'
import { PhishingEditForm } from '@src/components/Form/PhishingEditForm'
import { CsbUserModal } from '@src/components/Modal/CsbUserModal'
import { usePhishingEditHooksResult } from '@src/components/pages/phishing/[id]/edit/hooks'
import { useLiftDeliveryReserveMailMutation } from '@src/graphql/generated/graphql'
import { Base } from '@src/layouts'
import dayjs from 'dayjs'
import { memo } from 'react'
import { useParams } from 'react-router-dom'

//フィシイング編集ページのcomponent
export const PhishingEdit = memo(
  ({
    data,
    errors,
    form,
    groups,
    isCompletedData,
    isDeliveryContentsOnly,
    isGroupsDataLoading,
    isOpen,
    isUpdating,
    isWritePhishingMail,
    loading,
    navigate,
    onBackListPage,
    onClose,
    onSubmit,
    resolveDeliveryContentsUrl,
    role,
    templateModal,
    userData,
    uuid,
  }: usePhishingEditHooksResult) => {
    const { id } = useParams<{ id: string }>()

    const toast = useToast()

    const [liftDeliveryReserveMail, { loading: liftLoading }] =
      useLiftDeliveryReserveMailMutation()

    const isLock = () => {
      return (
        !!data?.phishingEmail.reserveAt ||
        !!data?.phishingEmail.phishingEmailSchedules.length
      )
    }

    const {
      isOpen: isOpenReserveCancelModal,
      onClose: onCloseReserveCancelModal,
      onOpen: onOpenReserveCancelModal,
    } = useDisclosure()

    const removeReserveMail = async () => {
      try {
        await liftDeliveryReserveMail({
          variables: {
            input: {
              uuid: id as string,
            },
          },
        })
        onCloseReserveCancelModal()

        navigate(resolveDeliveryContentsUrl(`/phishing/${id}/edit`))
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'success',
          title: '予約を解除しました。',
        })
        // eslint-disable-next-line
      } catch (e: any) {
        // eslint-disable-next-line
        const errorMessages = e.graphQLErrors.map((error: any) => error.message)
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'error',
          title:
            errorMessages.length > 0
              ? errorMessages[0]
              : '予約解除に失敗しました。',
        })
        onCloseReserveCancelModal()
      }
    }

    return (
      <Base
        left={
          <VStack>
            <Heading>
              {isDeliveryContentsOnly
                ? 'コンテンツメールの編集'
                : 'メールの編集'}
            </Heading>
            {isLock() ? (
              <Tag colorScheme="teal" size={'sm'} variant="solid">
                {data?.phishingEmail.reserveAt &&
                  dayjs(data?.phishingEmail.reserveAt).format(
                    'YYYY/MM/DD HH:mm'
                  ) + 'に送信予定'}
                {data?.phishingEmail.hasPhishingEmailSchedules &&
                  data?.phishingEmail.firstScheduleAt &&
                  dayjs(data?.phishingEmail.firstScheduleAt).format(
                    'YYYY/MM/DD HH:mm'
                  ) + '〜随時送信予定'}
              </Tag>
            ) : null}
          </VStack>
        }
        right={
          <HStack spacing={3}>
            <Button
              colorScheme={'gray'}
              onClick={() =>
                onBackListPage(resolveDeliveryContentsUrl('/phishing'))
              }
            >
              一覧に戻る
            </Button>
            {isLock() ? (
              <>
                <CsbNextLink
                  href={resolveDeliveryContentsUrl(
                    `/phishing/${id}/result/send/revealing/schedule`
                  )}
                >
                  <Button>
                    {!isDeliveryContentsOnly ? '種明かし' : 'リマインド'}
                    メール設定
                  </Button>
                </CsbNextLink>

                <Button colorScheme={'red'} onClick={onOpenReserveCancelModal}>
                  送信予約の解除
                </Button>
              </>
            ) : null}

            {!isLock() ? (
              <>
                {isWritePhishingMail && (
                  <>
                    {!isDeliveryContentsOnly && (
                      <Button
                        colorScheme={'gray'}
                        onClick={templateModal.onOpen}
                      >
                        テンプレートから選ぶ
                      </Button>
                    )}
                    <CsbNextLink
                      href={resolveDeliveryContentsUrl(`/phishing/new`, {
                        params: {
                          reuseId: id,
                        },
                      })}
                    >
                      <Button as={'div'}>複製</Button>
                    </CsbNextLink>
                    <Menu>
                      <MenuButton
                        as={Button}
                        isLoading={isUpdating}
                        rightIcon={<ChevronDownIcon />}
                      >
                        配信メニュー
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          disabled={!role?.writePhishingMail}
                          onClick={onSubmit('draft')}
                        >
                          メールの保存
                        </MenuItem>
                        {!isDeliveryContentsOnly && (
                          <MenuItem
                            disabled={!role?.writePhishingMail}
                            onClick={onSubmit('preview_phishing_result')}
                          >
                            メールを保存して種明かしページを確認する
                          </MenuItem>
                        )}
                        <MenuItem
                          disabled={!role?.writePhishingMail}
                          onClick={onSubmit('preview_learning')}
                        >
                          メールを保存して学習ページを確認する
                        </MenuItem>
                        <MenuItem
                          disabled={!role?.writePhishingMail}
                          onClick={onSubmit('send')}
                        >
                          メールを保存して送信
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </>
                )}
              </>
            ) : null}

            <CsbUserModal
              data={userData}
              groups={groups}
              isGroupsDataLoading={isGroupsDataLoading}
              isLoading={isUpdating}
              isOpen={isOpen}
              uuid={uuid}
              onClose={onClose}
            />
          </HStack>
        }
      >
        <Box mt={45}>
          <CsbPrimaryBox px={10} py={'30px'}>
            {!loading && isCompletedData && (
              <PhishingEditForm
                errors={errors}
                form={form}
                isLock={isLock()}
                schedules={data?.phishingEmail.phishingEmailSchedules}
              />
            )}
          </CsbPrimaryBox>
        </Box>
        <Flex mt={5}>
          <HStack ml={'auto'} spacing={3}>
            <Button
              colorScheme={'gray'}
              onClick={() =>
                onBackListPage(resolveDeliveryContentsUrl('/phishing'))
              }
            >
              一覧に戻る
            </Button>

            {isLock() ? (
              <>
                <CsbNextLink
                  href={resolveDeliveryContentsUrl(
                    `/phishing/${id}/result/send/revealing/schedule`
                  )}
                >
                  <Button>
                    {!isDeliveryContentsOnly ? '種明かし' : 'リマインド'}
                    メール設定
                  </Button>
                </CsbNextLink>
                <Button colorScheme={'red'} onClick={onOpenReserveCancelModal}>
                  送信予約の解除
                </Button>
              </>
            ) : null}

            {!isLock() ? (
              <>
                {isWritePhishingMail && (
                  <>
                    {!isDeliveryContentsOnly && (
                      <Button
                        colorScheme={'gray'}
                        onClick={templateModal.onOpen}
                      >
                        テンプレートから選ぶ
                      </Button>
                    )}
                    <CsbNextLink
                      href={resolveDeliveryContentsUrl(`/phishing/new`, {
                        params: {
                          reuseId: id,
                        },
                      })}
                    >
                      <Button as={'div'}>複製</Button>
                    </CsbNextLink>
                  </>
                )}

                <Menu>
                  <MenuButton
                    as={Button}
                    isLoading={isUpdating}
                    rightIcon={<ChevronDownIcon />}
                  >
                    配信メニュー
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      disabled={!role?.writePhishingMail}
                      onClick={onSubmit('draft')}
                    >
                      メールの保存
                    </MenuItem>
                    {!isDeliveryContentsOnly && (
                      <MenuItem
                        disabled={!role?.writePhishingMail}
                        onClick={onSubmit('preview_phishing_result')}
                      >
                        メールを保存して種明かしページを確認する
                      </MenuItem>
                    )}
                    <MenuItem
                      disabled={!role?.writePhishingMail}
                      onClick={onSubmit('preview_learning')}
                    >
                      メールを保存して学習ページを確認する
                    </MenuItem>
                    <MenuItem
                      disabled={!role?.writePhishingMail}
                      onClick={onSubmit('send')}
                    >
                      メールを保存して送信
                    </MenuItem>
                  </MenuList>
                </Menu>
              </>
            ) : null}
          </HStack>
        </Flex>
        <CsbUserModal
          data={userData}
          groups={groups}
          isGroupsDataLoading={isGroupsDataLoading}
          isLoading={isUpdating}
          isOpen={isOpen}
          uuid={uuid}
          onClose={onClose}
        />
        <CsbTemplateModal {...templateModal} />
        <Modal
          isOpen={isOpenReserveCancelModal}
          onClose={onCloseReserveCancelModal}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>送信予約の解除</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <p>送信予約を解除しますか？</p>
              <p>
                {!isDeliveryContentsOnly ? '種明かし' : 'リマインド'}
                メールが設定されている場合、その設定も解除されます。
              </p>
            </ModalBody>

            <ModalFooter>
              <Button
                mr={3}
                variant="ghost"
                onClick={onCloseReserveCancelModal}
              >
                キャンセル
              </Button>
              <Button
                colorScheme="teal"
                isLoading={liftLoading}
                onClick={removeReserveMail}
              >
                予約を解除する
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Base>
    )
  }
)
