import { BaseCsbEditorPropsType } from '@src/components/CsbEditor'
import HtmlEditor, {
  ImageUpload,
  Item,
  MediaResizing,
  Toolbar,
} from 'devextreme-react/html-editor'
import { forwardRef, useEffect, useState } from 'react'

const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']
const fontValues = [
  'system-ui',
  'sans-serif',
  'serif',
  'monospace',
  '游ゴシック',
  '游明朝',
  'ＭＳ Ｐゴシック',
  'ＭＳ Ｐ明朝',
  'MS UI Gothic',
  'ＭＳ ゴシック',
  'ＭＳ 明朝',
  'メイリオ',
  'ヒラギノ角ゴ Pro',
  'ヒラギノ角ゴ ProN',
  'ヒラギノ角ゴ Std',
  'ヒラギノ角ゴ StdN',
  'ヒラギノ角ゴシック',
  'ヒラギノ丸ゴ Pro',
  'ヒラギノ丸ゴ ProN',
  'ヒラギノ明朝 Pro',
  'ヒラギノ明朝 ProN',
  'Arial',
  'Courier New',
  'Georgia',
  'Impact',
  'Lucida Console',
  'Tahoma',
  'Times New Roman',
  'Verdana',
]
const headerValues = [false, 1, 2, 3, 4, 5]

export type WysiwygEditorPropsType = {
  locked: boolean
} & BaseCsbEditorPropsType

export const WysiwygEditor = forwardRef<HtmlEditor, WysiwygEditorPropsType>(
  (props, ref) => {
    const [htmlText, setHtmlText] = useState<string>('')

    const onChangeText = (htmlText: string) => {
      if (props.onChangeHtml) {
        props.onChangeHtml(htmlText)
        setHtmlText(htmlText)
      }
    }

    useEffect(() => {
      if (props.data) {
        setHtmlText(props.data)
      }
    }, [props.data])

    const dialogTabs = [{ name: 'From This Device', value: ['file'] }]

    return (
      <HtmlEditor
        disabled={props.disabled || props.locked}
        height="500px"
        ref={ref}
        value={htmlText}
        valueType={'html'}
        onValueChanged={(e) => {
          if (!e.event) {
            return
          }
          onChangeText(e.value as string)
        }}
      >
        <MediaResizing enabled={true} />
        <ImageUpload
          fileUploadMode="server"
          fileUploaderOptions={{
            // eslint-disable-next-line
            onUploaded: (file: any) => {
              const url = JSON.parse(file.request.response).url
              setHtmlText(htmlText + '<img alt="" src="' + url + '" />')
            },
          }}
          tabs={dialogTabs}
          uploadUrl={`${process.env.REACT_APP_BACKEND_URL}/image/upload_file`}
        />
        <Toolbar>
          <Item name="undo" />
          <Item name="redo" />
          <Item name="separator" />
          <Item acceptedValues={sizeValues} name="size" />
          <Item acceptedValues={fontValues} name="font" />
          <Item name="separator" />
          <Item name="bold" />
          <Item name="italic" />
          <Item name="strike" />
          <Item name="underline" />
          <Item name="separator" />
          <Item name="alignLeft" />
          <Item name="alignCenter" />
          <Item name="alignRight" />
          <Item name="alignJustify" />
          <Item name="separator" />
          <Item name="orderedList" />
          <Item name="bulletList" />
          <Item name="separator" />
          <Item acceptedValues={headerValues} name="header" />
          <Item name="separator" />
          <Item name="color" />
          <Item name="background" />
          <Item name="separator" />
          <Item name="link" />
          <Item name="image" />
          <Item name="separator" />
          <Item name="clear" />
          <Item name="codeBlock" />
          <Item name="blockquote" />
          <Item name="separator" />
          <Item name="insertTable" />
          <Item name="deleteTable" />
          <Item name="insertRowAbove" />
          <Item name="insertRowBelow" />
          <Item name="deleteRow" />
          <Item name="insertColumnLeft" />
          <Item name="insertColumnRight" />
          <Item name="deleteColumn" />
        </Toolbar>
      </HtmlEditor>
    )
  }
)
