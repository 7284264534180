import { Spinner, SpinnerProps } from '@chakra-ui/react'

export const CsbSpinner = (props: SpinnerProps) => {
  return (
    <Spinner
      color="blue.500"
      emptyColor="gray.200"
      size="xl"
      speed="0.65s"
      thickness="4px"
      {...props}
    />
  )
}
