import { useToast } from '@chakra-ui/react'
import { templateFormDefaultValues } from '@src/components/pages/templates/templateFormDefaultValues'
import {
  CreateMailTemplateInput,
  useCreateMailTemplateMutation,
} from '@src/graphql/generated/graphql'
import { useErrors, useMe } from '@src/hooks'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

//メールテンプレート作成で使うhooksのまとめ
export const useTemplatesNewHooks = () => {
  const { role } = useMe()
  const isWriteMailTemplate = !!role?.writeMailTemplate
  const navigate = useNavigate()
  const toast = useToast()
  const [createMailTemplateMutation, { loading: isCreating }] =
    useCreateMailTemplateMutation()
  const [errors, setErrors] = useErrors()
  const form = useForm<CreateMailTemplateInput>({
    defaultValues: templateFormDefaultValues,
  })
  //保存と公開ボタンのloadingアニメーションを分岐するstate
  const [isDraftButton, setIsDraftButton] = useState(true)

  const onSubmit = (draft: boolean) =>
    form.handleSubmit(async (inputData: CreateMailTemplateInput) => {
      //保存ボタンか公開ボタンかどうか
      setIsDraftButton(draft)
      try {
        await createMailTemplateMutation({
          variables: {
            input: {
              ...inputData,
              draft,
            },
          },
        })
        setErrors([])
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'success',
          title: `${inputData.name || 'テンプレート'}を${
            draft ? '下書き保存' : '公開'
          }しました。`,
        })
        await navigate('/templates')
        /**
         * TODO anyを消す
         * e instanceOf ApolloError
         * setErrorsを変更する
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'error',
          title: `${inputData.name || 'テンプレート'}の${
            draft ? '下書き保存' : '保存'
          }に失敗しました。`,
        })
        setErrors(e.graphQLErrors)
      }
    })
  return {
    errors,
    form,
    isCreating,
    isDraftButton,
    isWriteMailTemplate,
    navigate,
    onSubmit,
  }
}

export type useTemplatesNewHooksResult = ReturnType<typeof useTemplatesNewHooks>
