import { DeleteIcon } from '@chakra-ui/icons'
import {
  forwardRef,
  IconButton,
  IconButtonProps,
  Tooltip,
} from '@chakra-ui/react'
type CsbDeleteButtonPropsType = Omit<IconButtonProps, 'children' | 'aria-label'>

export const CsbDeleteButton = forwardRef<CsbDeleteButtonPropsType, 'button'>(
  ({ ...props }, ref) => {
    return (
      <Tooltip label={'削除する'}>
        <IconButton
          colorScheme={'red'}
          icon={<DeleteIcon />}
          ref={ref}
          rounded={'full'}
          {...props}
          aria-label="delete"
        />
      </Tooltip>
    )
  }
)
