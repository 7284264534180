import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  HStack,
  Input,
  InputGroup,
  Skeleton,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {
  CsbFormControl,
  CsbListItem,
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
  CsbNextLink,
} from '@src/components'
import { TargetUserList } from '@src/components/List/TargetUsers/TargetUserList'
import { GroupData } from '@src/components/pages/phishing/hooks/usePhishingEmailGroups'
import {
  PhishingEmailUsersQuery,
  UpdatePhishingEmailMutationResult,
  usePhishingEmailUsersQuery,
  useSendPhishingEmailMutation,
  useSendSchedulePhishingEmailMutation,
} from '@src/graphql/generated/graphql'
import { useMe } from '@src/hooks'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import { getActiveCompanyMailLimit } from '@src/utils/companyMailLiimit'
import dayjs from 'dayjs'
import moment from 'moment'
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'

type CsbUserModalPropsType = {
  data:
    | NonNullable<
        NonNullable<
          UpdatePhishingEmailMutationResult['data']
        >['updatePhishingEmail']
      >['phishingEmail']
    | undefined
  groups: GroupData[]
  isGroupsDataLoading: boolean
  isLoading: boolean
  uuid: string
} & Omit<ComponentProps<typeof CsbModal>, 'children'>

const userLimitPerPage = 100

const collectUsers = (phishingEmailUsersQuery?: PhishingEmailUsersQuery) =>
  phishingEmailUsersQuery?.phishingEmailUsers.collection.map((user) => ({
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    target: user.avatarPath,
    testIgnore: user.testIgnore,
    uuid: user.uuid,
  })) ?? null

type GroupSchedule = {
  groupName: string
  groupUuid: string
  reserveAt: string
  sendCount: number
  sendMaxCount: number
  sendRequiredMinutes: number
}

const DateValidStatus = {
  Invalidate: 1,
  Small: 2,
  Valid: 0,
}

export const CsbUserModal = ({
  data,
  groups,
  isGroupsDataLoading,
  isLoading,
  onClose,
  uuid,
  ...props
}: CsbUserModalPropsType) => {
  const { isDeliveryContentsOnly, resolveDeliveryContentsUrl } =
    useDeliveryContents()
  const navigate = useNavigate()
  const { company } = useMe()
  const toast = useToast()

  // フィッシングメールの単一送信、スケジュール送信のMutation
  const [sendPhishingEmailMutation, { loading: isSendingPhishingEmail }] =
    useSendPhishingEmailMutation()

  // 現在有効な会社メール制限を取得
  const activeCompanyMailLimit = getActiveCompanyMailLimit(
    company?.companyMailLimits ?? [],
    isDeliveryContentsOnly
  )

  const [isLimited, isTest, isUnlimitedMail] = (() => {
    const countUpCount =
      data?.users?.filter((user) => !user.testIgnore).length ?? 0
    if (data?.isTest) {
      const isLimited = countUpCount > (company?.testMailCount ?? 0)
      return [isLimited, true, false]
    } else {
      // 会社メール制限が存在する場合
      // 無制限かどうか
      const isUnlimitedMail = activeCompanyMailLimit?.limitCount === null

      // 送信するメールが上限を超えるか
      const isLimited =
        !isUnlimitedMail &&
        (activeCompanyMailLimit?.currentCount ?? 0) + countUpCount >
          (activeCompanyMailLimit?.limitCount ?? 0)

      return [isLimited, false, isUnlimitedMail]
    }
  })()

  const [
    sendSchedulePhishingEmailMutation,
    { loading: isSendingSchedulePhishingEmail },
  ] = useSendSchedulePhishingEmailMutation()

  // 送信中かどうか
  const isSending = isSendingPhishingEmail || isSendingSchedulePhishingEmail
  //ユーザーが存在するか
  const isExistUsers = data ? data.users.length > 0 : false

  const sendModal = useDisclosure()
  const [reserveAt, setReserveAt] = useState<string>('')
  const [schedules, setSchedules] = useState<Array<GroupSchedule>>([])
  const [reserveAtError, setReserveAtError] = useState<string>('')
  const [scheduleErrors, setScheduleErrors] = useState<Array<string>>([])

  useEffect(() => {
    const schedules = groups.map((group) => ({
      groupName: group.groupName,
      groupUuid: group.groupUuid,
      reserveAt: '',
      sendCount: group.sendCount,
      sendMaxCount: group.sendCount,
      sendRequiredMinutes: group.sendRequiredMinutes,
    }))
    setSchedules(schedules)
  }, [groups])

  const [isGroupChunk, setIsGroupChunk] = useState<boolean>(false)

  const validDateTime = () => {
    // 現在の時間を10分後を保存したいので、メモ化
    const minTime = moment(new Date()).unix() + 60 * 10
    return (reserveAt: string) => {
      const date = moment(reserveAt, "YYYY-MM-DD'T'HH:mm")
      if (!date.isValid()) {
        return DateValidStatus.Invalidate
      }
      return minTime <= date.unix()
        ? DateValidStatus.Valid
        : DateValidStatus.Small
    }
  }

  const validReserveDateTimes = useCallback((schedules: GroupSchedule[]) => {
    const emptyArray = Array(schedules.length).fill('')
    setScheduleErrors(emptyArray)
    const valid = validDateTime()
    const hasErrors = (errors: string[]) => errors.some((error) => error)
    const reserveAts = schedules.map((s) => s.reserveAt)

    // 配信日時は全て入力されているかチェックする
    const emptyDateTimeErrors = reserveAts.map((reserveAt) =>
      !reserveAt ? '配信日時を指定してください。' : ''
    )
    if (hasErrors(emptyDateTimeErrors)) {
      setScheduleErrors(emptyDateTimeErrors)
      return false
    }

    // 配信日時は１０分以降であるか全日程をチェックする
    const inValidDateTimeErrors = reserveAts.map((reserveAt) =>
      valid(reserveAt) !== DateValidStatus.Valid
        ? '配信日時は現在時刻より10分後を指定してください。'
        : ''
    )
    if (hasErrors(inValidDateTimeErrors)) {
      setScheduleErrors(inValidDateTimeErrors)
      return false
    }

    // 重複している配信日時があるかチェックする
    const duplicateDateErrors = reserveAts.map((reserveAt, index) =>
      reserveAts.slice(0, index).includes(reserveAt, index - 1)
        ? '配信日時が重複しています。'
        : ''
    )
    if (hasErrors(duplicateDateErrors)) {
      setScheduleErrors(duplicateDateErrors)
      return false
    }

    // 配信時間がかぶらないかチェックを行う。
    const duplicateSentErrors = schedules.map((schedule, index) => {
      const start = moment(schedule.reserveAt)
      const end = moment(schedule.reserveAt).add(
        schedule.sendRequiredMinutes,
        'minutes'
      )
      const isInvalid = schedules.some((s, i) => {
        const date = moment(s.reserveAt)
        return index != i && start <= date && end > date
      })
      return isInvalid ? '配信日時が重複しています。' : ''
    })

    // 配信時間がかぶっている場合はエラーを設定
    if (hasErrors(duplicateSentErrors)) {
      setScheduleErrors(duplicateSentErrors)
      return false
    }

    return true
  }, [])

  const validReserveDateTime = useCallback((reserveAt: string) => {
    setReserveAtError('')
    if (!reserveAt) {
      return true
    }
    switch (validDateTime()(reserveAt)) {
      case DateValidStatus.Valid:
        return true
      case DateValidStatus.Invalidate:
        setReserveAtError('配信日時の形式が正しくありません。')
        return false
      default:
        setReserveAtError('配信日時は現在時刻より10分後を指定してください。')
        return false
    }
  }, [])

  const isValid = useMemo(() => {
    return !isGroupChunk
      ? validReserveDateTime(reserveAt)
      : validReserveDateTimes(schedules)
  }, [
    isGroupChunk,
    validReserveDateTime,
    reserveAt,
    validReserveDateTimes,
    schedules,
  ])

  const changeSchedules = (index: number, schedule: GroupSchedule) =>
    schedules.map((s, i) => (index !== i ? s : schedule))
  const updateSchedule = (index: number, schedule: GroupSchedule) => {
    setSchedules(changeSchedules(index, schedule))
  }

  const onSubmit = async () => {
    try {
      if (!isGroupChunk) {
        await sendPhishingEmailMutation({
          variables: {
            input: {
              reserveAt: !reserveAt ? null : moment(reserveAt).format(),
              uuid,
            },
          },
        })
      } else {
        const groupUuids = schedules.map((s) => s.groupUuid)
        const reserveAts = schedules.map((s) => moment(s.reserveAt).format())
        const sendCounts = schedules.map((s) => Number(s.sendCount))

        await sendSchedulePhishingEmailMutation({
          variables: {
            input: {
              groupUuid: groupUuids,
              reserveAt: reserveAts,
              sendCount: sendCounts,
              uuid,
            },
          },
        })
      }
      sendModal.onOpen()
    } catch (e) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: 'メールの送信に失敗しました。',
      })
    }
  }

  const onCloseSendMail = () => {
    onClose()
    sendModal.onClose()
    navigate(
      resolveDeliveryContentsUrl(
        `/phishing/${uuid}/result/send/revealing/schedule`
      )
    )
  }

  const clearScheduleData = (index: number) => {
    const schedule = { ...schedules[index], reserveAt: '' }
    updateSchedule(index, schedule)
  }

  const { data: phishingEmailUsersQuery, refetch } = usePhishingEmailUsersQuery(
    {
      skip: !uuid,
      variables: {
        pagination: {
          limit: userLimitPerPage,
          page: 1,
        },
        uuid: uuid,
      },
    }
  )

  const [users, setUsers] = useState(collectUsers(phishingEmailUsersQuery))
  const [metadata, setMetadata] = useState(
    phishingEmailUsersQuery?.phishingEmailUsers.metadata
  )

  const onPaging = async (page: number) => {
    const { data } = await refetch({
      pagination: {
        limit: userLimitPerPage,
        page: page,
      },
      uuid: uuid,
    })
    setUsers(collectUsers(data))
    setMetadata(data.phishingEmailUsers.metadata)
  }

  useEffect(() => {
    if (!uuid) return
    if (!props.isOpen) return
    setUsers(null)
    onPaging(1).then()
    // eslint-disable-next-line
  }, [uuid, props.isOpen])

  return (
    <>
      <CsbModal
        size={'3xl'}
        {...props}
        closeOnOverlayClick={false}
        onClose={onClose}
      >
        <CsbModalHeader>送信確認</CsbModalHeader>
        <CsbModalBody mt={'18px'}>
          {data && isExistUsers ? (
            <>
              {!data.learn && (
                <Alert status="warning">
                  <AlertIcon />
                  学習コンテンツが設定されていません。
                </Alert>
              )}

              {/* 契約期間を過ぎた場合（有効期限が切れた場合。または、有効な会社メール制限が存在しない場合）*/}
              {(!company?.isActive || !activeCompanyMailLimit) && (
                <Alert mb={2} status="error">
                  <AlertIcon />
                  契約期間を過ぎたためメールの送信ができません。
                </Alert>
              )}

              {/* 会社メール制限で上限に達している場合 */}
              {!isTest &&
                activeCompanyMailLimit &&
                !isUnlimitedMail &&
                isLimited && (
                  <Alert mb={2} status="error">
                    <AlertIcon />
                    {!isDeliveryContentsOnly
                      ? 'フィッシングメール'
                      : 'コンテンツ配信メール'}
                    の上限に達するため、送信できません。
                    <br />
                    送信対象者を減らしてください。
                    <br />
                    <br />
                    送信可能数 (
                    {(activeCompanyMailLimit?.currentCount ?? 0) +
                      (data?.users.length ?? 0)}{' '}
                    / {activeCompanyMailLimit?.limitCount ?? 0}通)
                    <br />
                    期間（
                    {activeCompanyMailLimit?.endDate
                      ? ' 〜 ' +
                        dayjs(activeCompanyMailLimit?.endDate).format(
                          'YYYY/MM/DD'
                        )
                      : '期限なし'}
                    ）
                  </Alert>
                )}

              {isTest && isLimited && (
                <Alert mb={2} status="error">
                  <AlertIcon />
                  {!isDeliveryContentsOnly
                    ? 'フィッシングメール'
                    : 'コンテンツ配信メール'}
                  のテスト配信の上限に達するため、送信できません。
                  <br />
                  送信対象者を減らしてください。
                  <br />
                  <br />
                  送信可能人数 ({+(data?.users.length ?? 0)} /{' '}
                  {company?.testMailCount ?? 0}人)
                </Alert>
              )}

              {company?.isActive && (
                <Text mt={1}>
                  {data.users.length}
                  件のメールアドレス宛に
                  {!isDeliveryContentsOnly
                    ? 'フィッシングメール'
                    : 'コンテンツ配信メール'}
                  を送信します。
                </Text>
              )}

              <Box mt={'38px'}>
                {isLoading || !isExistUsers ? (
                  <CsbListItem px={0}>
                    <Skeleton h={12} w={'full'} />
                  </CsbListItem>
                ) : users?.length ? (
                  <TargetUserList
                    collectionMetaData={metadata}
                    users={users}
                    onPaging={onPaging}
                  />
                ) : (
                  <Text>メールを送信できるユーザが存在しません。</Text>
                )}

                <Divider my={5} />

                <Box mt={5}>
                  <CsbFormControl labelText={'グループ分割'} mt={5}>
                    <HStack fontSize={'sm'}>
                      <Text>分割しない</Text>
                      <Switch
                        isChecked={isGroupChunk}
                        onChange={() => {
                          setIsGroupChunk(!isGroupChunk)
                        }}
                      />
                      <Text>分割する</Text>
                    </HStack>
                  </CsbFormControl>
                </Box>
                {!isGroupChunk && (
                  <Box mt={5} w={500}>
                    <CsbFormControl
                      errorText={reserveAtError}
                      helperText={'10分以降の時間を設定してください'}
                      labelText={'配信日時'}
                    >
                      <InputGroup maxW={300}>
                        <Input
                          type={'datetime-local'}
                          value={reserveAt}
                          onChange={(e) => {
                            console.log(e.target.value)
                            setReserveAt(e.target.value)
                          }}
                        ></Input>
                        <Button
                          colorScheme={'gray'}
                          ml={3}
                          onClick={() => {
                            setReserveAt('')
                          }}
                        >
                          クリア
                        </Button>
                      </InputGroup>
                    </CsbFormControl>
                  </Box>
                )}

                {isGroupChunk && (
                  <Box mt={5} w={650}>
                    {!(isGroupsDataLoading ?? false) &&
                      schedules.map((s, index) => (
                        <CsbFormControl
                          errorText={scheduleErrors[index]}
                          key={index}
                          labelText={index === 0 ? '分割配信' : ''}
                          mt={5}
                        >
                          {index === 0 && (
                            <Text color={'gray.500'} fontSize={'sm'} mb={4}>
                              配信時間は重複して登録できません。所要時間を加味して設定ください。
                            </Text>
                          )}
                          <InputGroup key={index}>
                            <Input
                              type={'datetime-local'}
                              value={s.reserveAt}
                              w={200}
                              onChange={(e) => {
                                const schedule = {
                                  ...schedules[index],
                                  reserveAt: e.target.value,
                                }
                                updateSchedule(index, schedule)
                              }}
                            ></Input>
                            <Button
                              colorScheme={'gray'}
                              ml={2}
                              p={2}
                              onClick={() => {
                                clearScheduleData(index)
                              }}
                            >
                              クリア
                            </Button>
                            <Text
                              alignItems={'center'}
                              display={'flex'}
                              fontSize={'sm'}
                              fontWeight={'bold'}
                              isTruncated={true}
                              px={6}
                              py={3}
                              w={200}
                            >
                              {s.groupName}
                            </Text>
                            <Text
                              alignItems={'center'}
                              display={'flex'}
                              fontSize={'sm'}
                              fontWeight={'bold'}
                              px={6}
                              py={3}
                              w={220}
                            >
                              {s.sendCount}人（所要時間:
                              {s.sendRequiredMinutes}
                              分）
                            </Text>
                          </InputGroup>
                        </CsbFormControl>
                      ))}
                    <CsbFormControl
                      helperText={'10分以降の時間を設定してください'}
                      mt={2}
                    ></CsbFormControl>
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <Text>メールを送信できるユーザが存在しません。</Text>
          )}
        </CsbModalBody>
        <CsbModalFooter mt={7}>
          <HStack spacing={6}>
            <CsbNextLink
              href={resolveDeliveryContentsUrl(`/phishing/${uuid}/edit`)}
              onClick={onClose}
            >
              <Button as={'div'} colorScheme={'gray'}>
                キャンセル
              </Button>
            </CsbNextLink>
            <Button
              isDisabled={
                !isValid ||
                !isExistUsers ||
                !company?.isActive ||
                (!isUnlimitedMail && isLimited)
              }
              isLoading={isSending}
              onClick={onSubmit}
            >
              {!reserveAt && !isGroupChunk ? '今すぐ配信する' : '予約配信する'}
            </Button>
          </HStack>
        </CsbModalFooter>
      </CsbModal>
      <CsbModal
        isOpen={sendModal.isOpen}
        onClose={() => console.log('overlay')}
      >
        <CsbModalHeader textAlign={'center'}>
          {!reserveAt && !isGroupChunk
            ? 'メールを送信しました'
            : '送信予約を行いました'}
        </CsbModalHeader>
        <CsbModalFooter display={'flex'} justifyContent={'center'} pt={10}>
          <Button onClick={onCloseSendMail}>閉じる</Button>
        </CsbModalFooter>
      </CsbModal>
    </>
  )
}
