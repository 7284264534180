import { Campaigns } from '@src/components/pages/campaigns'
import { useCampaignsHooks } from '@src/components/pages/campaigns/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const CampaignsPage = memo(() => {
  const campaignsHooks = useCampaignsHooks()
  return (
    <Authorization>
      <Campaigns {...campaignsHooks}></Campaigns>
    </Authorization>
  )
})
