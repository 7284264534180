import { ModalBody as ChakraModalBody, ModalBodyProps } from '@chakra-ui/react'

type ModalBodyPropsType = ModalBodyProps

export const CsbModalBody = ({ children, ...props }: ModalBodyPropsType) => {
  return (
    <ChakraModalBody px={0} py={0} {...props}>
      {children}
    </ChakraModalBody>
  )
}
