//ユーザー作成で使うhooksのまとめ
import { useToast } from '@chakra-ui/react'
import {
  CreateUserInput,
  useCreateUserMutation,
} from '@src/graphql/generated/graphql'
import { useErrors, useMe } from '@src/hooks'
import { userFormDefaultValues } from '@src/pages/users/formDefaultValues'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const useUsersNewHooks = () => {
  const { company, role } = useMe()
  const isWriteUser = !!role?.writeUser
  const navigate = useNavigate()
  const toast = useToast()
  const [createUserMutation, { loading: isCreating }] = useCreateUserMutation()
  const [errors, setErrors] = useErrors()
  const form = useForm<CreateUserInput>({
    defaultValues: userFormDefaultValues,
  })

  const onSubmit = form.handleSubmit(
    async ({
      birthdayOn,
      joinedOn,
      positionId,
      ...inputData
    }: CreateUserInput) => {
      try {
        await createUserMutation({
          variables: {
            input: {
              ...inputData,
              birthdayOn: birthdayOn || null,
              joinedOn: joinedOn || null,
              positionId: positionId || null,
            },
          },
        })
        setErrors([])
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'success',
          title: 'ユーザーを追加しました。',
        })
        await navigate('/users')
        /**
         * TODO anyを消す
         * e instanceOf ApolloError
         * setErrorsを変更する
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'error',
          title: 'ユーザーの作成に失敗しました。',
        })
        setErrors(e.graphQLErrors)
      }
    }
  )
  return {
    company,
    errors,
    form,
    isCreating,
    isWriteUser,
    navigate,
    onSubmit,
  }
}

export type useUsersNewHooksResult = ReturnType<typeof useUsersNewHooks>
