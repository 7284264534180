import {
  AspectRatio,
  Box,
  Center,
  Flex,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  theme,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { CsbPieChart, CsbSpinner } from '@src/components'
import { CsbHint } from '@src/components/CsbHint/CsbHint'
import { usePhishingResultNewContentHooksResult } from '@src/components/pages/phishing/[id]/result//PhishingResultNewContent'
import {
  usePhishingEmailQuery,
  usePhishingTargetsQuery,
} from '@src/graphql/generated/graphql'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import { PhishingResultOverview } from '@src/models/Phishing/PhishingResultOverview/PhishingResultOverview'
import { usePhishingResultOverview } from '@src/models/Phishing/PhishingResultOverview/usePhishingResultOverview'
import { useEffect } from 'react'

export const DataAggregation = ({
  id,
  usePhishingResultContentsSelectResult,
}: usePhishingResultNewContentHooksResult) => {
  const usePhishingResultOverviewResult = usePhishingResultOverview({
    variables: {
      uuid: id ?? '',
    },
  })

  const { refetch: refetchOverviewResult } = usePhishingResultOverviewResult

  const { isOpen, onClose, onOpen } = useDisclosure()
  const { isDeliveryContentsOnly } = useDeliveryContents()

  const { data, loading } = usePhishingEmailQuery({
    variables: { isDeliveryContentsOnly, uuid: id ?? '' },
  })

  const {
    data: targets,
    loading: isTargetLoading,
    refetch: refetchTargets,
  } = usePhishingTargetsQuery({
    variables: {
      isDeliveryFail: true,
      phishingUuid: id ?? '',
    },
  })

  useEffect(() => {
    refetchOverviewResult().then()
    refetchTargets().then()
  }, [
    refetchTargets,
    refetchOverviewResult,
    usePhishingResultContentsSelectResult.form.formState,
  ])

  const getAccessLabel = () => {
    if (loading) {
      return 'サイトアクセス数'
    }
    if (data?.phishingEmail.attach === null) {
      return 'サイトアクセス数'
    }
    if (data?.phishingEmail.attach?.fishingMethod === 'MACRO') {
      return '添付ファイルクリック'
    }
    if (data?.phishingEmail.attach?.fishingMethod === 'EMOTET') {
      return ''
    }
  }

  const getPer = (numerator: number, denominator: number) => {
    let result = Math.round((numerator / denominator) * 10000) / 100
    if (isNaN(result)) {
      result = 0
    }
    return result
  }

  const formatNumber = (num: number) =>
    new Intl.NumberFormat('ja-JP').format(num)

  const openSentFailTargetsModal = () => {
    onOpen()
    refetchTargets()
  }

  const widthDenominator = isDeliveryContentsOnly ? 4 : 3

  return (
    <>
      <Flex mb={'50px'} mt={100}>
        <PhishingResultOverview
          {...usePhishingResultOverviewResult}
          loadingFallback={
            <AspectRatio
              bg={'gray.50'}
              borderWidth={1}
              ratio={683.5 / 197}
              w={'full'}
            >
              <Center>
                <CsbSpinner />
              </Center>
            </AspectRatio>
          }
        >
          {({ phishingResultOverview }) => (
            <Box w={'100%'}>
              <Flex flexWrap={'wrap'} ml={'-16px'} w={'100%'}>
                <Box
                  borderColor={'gray.900'}
                  borderRight={1}
                  borderStyle={'solid'}
                  mb={25}
                  pb={18}
                  pl={'16px'}
                  w={1 / widthDenominator}
                >
                  <Text fontSize={13}>メール配信数</Text>
                  <Text fontSize={24} fontWeight={500}>
                    {formatNumber(phishingResultOverview.deliveryCount)}
                  </Text>
                </Box>
                <Box
                  borderColor={'gray.900'}
                  borderRight={1}
                  borderStyle={'solid'}
                  mb={25}
                  pb={18}
                  pl={'16px'}
                  w={1 / widthDenominator}
                >
                  <HStack>
                    <Text fontSize={13}>メール開封数</Text>
                    <CsbHint>
                      HTML形式でメール表示することでカウントされます。
                    </CsbHint>
                  </HStack>
                  <Flex align="center">
                    <Text fontSize={24} fontWeight={500}>
                      {formatNumber(phishingResultOverview.openCount)}
                    </Text>
                    <Text fontSize={'sm'} ml={2}>
                      (
                      {getPer(
                        phishingResultOverview.openCount,
                        phishingResultOverview.deliveryCount
                      )}
                      %)
                    </Text>
                  </Flex>
                </Box>

                {!isDeliveryContentsOnly && (
                  <>
                    {getAccessLabel() === '' ? null : (
                      <Box
                        borderColor={'gray.900'}
                        borderRight={1}
                        borderStyle={'solid'}
                        mb={25}
                        pb={18}
                        pl={'16px'}
                        w={1 / widthDenominator}
                      >
                        <HStack>
                          <Text fontSize={13}>{getAccessLabel()}</Text>
                          <CsbHint>
                            URLをクリックし、フィッシングサイトに遷移することでカウントされます。リンク型→サイトアクセス、標的型→添付ファイルクリック、URL型→表示なし
                          </CsbHint>
                        </HStack>
                        <Flex align="center">
                          <Text fontSize={24} fontWeight={500}>
                            {formatNumber(phishingResultOverview.accessCount)}
                          </Text>
                          <Text fontSize={'sm'} ml={2}>
                            (
                            {getPer(
                              phishingResultOverview.accessCount,
                              phishingResultOverview.deliveryCount
                            )}
                            %)
                          </Text>
                        </Flex>
                      </Box>
                    )}

                    <Box
                      borderColor={'gray.900'}
                      borderRight={1}
                      borderStyle={'solid'}
                      mb={25}
                      pb={18}
                      px={'16px'}
                      w={1 / widthDenominator}
                    >
                      <HStack>
                        <Text fontSize={13}>リスクのあるユーザー</Text>
                        <CsbHint>
                          フィッシングサイトにて「返信」「次へ」等のボタンをクリックするこでカウントされます。
                        </CsbHint>
                      </HStack>
                      <Flex align="center">
                        <Text fontSize={24} fontWeight={500}>
                          {formatNumber(phishingResultOverview.riskUsersCount)}
                        </Text>
                        <Text fontSize={'sm'} ml={2}>
                          (
                          {getPer(
                            phishingResultOverview.riskUsersCount,
                            phishingResultOverview.deliveryCount
                          )}
                          %)
                        </Text>
                      </Flex>
                    </Box>
                  </>
                )}
                <Box
                  borderColor={'gray.900'}
                  borderRight={1}
                  borderStyle={'solid'}
                  mb={25}
                  pb={18}
                  pl={'16px'}
                  w={1 / widthDenominator}
                >
                  <HStack>
                    <Text fontSize={13}>ラーニング回答</Text>
                    <CsbHint>
                      ラーニング画面にて問題に回答、返信をクリックすることでカウントされます。
                    </CsbHint>
                  </HStack>
                  <Flex align="center">
                    <Text fontSize={24} fontWeight={500}>
                      {formatNumber(phishingResultOverview.answeredUsersCount)}
                    </Text>
                    <Text fontSize={'sm'} ml={2}>
                      (
                      {getPer(
                        phishingResultOverview.answeredUsersCount,
                        phishingResultOverview.deliveryCount
                      )}
                      %)
                    </Text>
                  </Flex>
                </Box>
                <Box
                  borderColor={'gray.900'}
                  borderRight={1}
                  borderStyle={'solid'}
                  mb={25}
                  pb={18}
                  px={'16px'}
                  w={1 / widthDenominator}
                >
                  <HStack>
                    <Text fontSize={13}>送信失敗数</Text>
                    <Text fontSize={13}>
                      (<Link onClick={openSentFailTargetsModal}>一覧</Link>)
                    </Text>
                  </HStack>
                  <Flex align="center">
                    <Text fontSize={24} fontWeight={500}>
                      {formatNumber(phishingResultOverview.failCount)}
                    </Text>
                    <Text fontSize={'sm'} mb={3} ml={2}>
                      (
                      {getPer(
                        phishingResultOverview.failCount,
                        phishingResultOverview.deliveryCount
                      )}
                      %)
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          )}
        </PhishingResultOverview>
        {!isDeliveryContentsOnly && (
          <Flex
            align={'center'}
            direction={'column'}
            justify={'center'}
            w={'100%'}
          >
            <PhishingResultOverview
              {...usePhishingResultOverviewResult}
              loadingFallback={
                <AspectRatio
                  bg={'gray.50'}
                  borderWidth={1}
                  maxW={300}
                  ratio={1}
                  w={'full'}
                >
                  <Center>
                    <CsbSpinner />
                  </Center>
                </AspectRatio>
              }
            >
              {({ phishingResultOverview }) => (
                <CsbPieChart
                  backgroundColors={[
                    theme.colors.red['500'],
                    theme.colors.teal['500'],
                  ]}
                  data={[
                    phishingResultOverview.riskUsersCount,
                    phishingResultOverview.deliveryCount -
                      phishingResultOverview.riskUsersCount,
                  ]}
                  labels={['リスクのあるユーザー', '配信数']}
                  mx="auto"
                />
              )}
            </PhishingResultOverview>
          </Flex>
        )}
      </Flex>
      <Modal isOpen={isOpen} size={'full'} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>メール送信失敗者一覧</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isTargetLoading ? (
              <>読込中</>
            ) : (
              <TableContainer>
                {targets === undefined ||
                targets?.phishingTargets?.length === 0 ? (
                  'データがありません'
                ) : (
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>社員番号</Th>
                        <Th>メールアドレス</Th>
                        <Th>コード</Th>
                        <Th>エラー内容</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {targets?.phishingTargets?.map((target, i) => {
                        return (
                          <Tr key={i}>
                            <Td>{target.user.employeeNumber}</Td>
                            <Td>{target.user.email}</Td>
                            <Td>{target.statusCode}</Td>
                            <Td>{target.message}</Td>
                          </Tr>
                        )
                      })}
                    </Tbody>
                  </Table>
                )}
              </TableContainer>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
