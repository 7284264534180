import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react'
import { CsbPrimaryBox } from '@src/components'
import { UserEditForm } from '@src/components/Form/user/UserEditForm'
import { useUsersNewHooksResult } from '@src/components/pages/users/new/hooks'
import { Base } from '@src/layouts'
import { memo } from 'react'

//ユーザー作成ページのcomponent
export const UsersNew = memo(
  ({
    company,
    errors,
    form,
    isCreating,
    isWriteUser,
    navigate,
    onSubmit,
  }: useUsersNewHooksResult) => {
    return (
      <Base
        left={<Heading>ユーザーの作成</Heading>}
        right={
          <HStack spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧に戻る
            </Button>
            {isWriteUser && (
              <>
                {company?.maxUserCount === company?.currentUserCount ? (
                  <Button isDisabled>ユーザー数上限に達しています</Button>
                ) : (
                  <Button isLoading={isCreating} onClick={onSubmit}>
                    作成
                  </Button>
                )}
              </>
            )}
          </HStack>
        }
      >
        <Box mt={45}>
          <CsbPrimaryBox pb={'72px'} pt={35} px={45}>
            <Box as={'form'} w={'full'}>
              <VStack align={'start'}>
                <Heading fontSize={24}>基本情報</Heading>
                <Divider />
              </VStack>
              <UserEditForm errors={errors} form={form} />
            </Box>
          </CsbPrimaryBox>
        </Box>
        <Flex mt={5}>
          <HStack ml={'auto'} spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧に戻る
            </Button>
            {isWriteUser && (
              <>
                {company?.maxUserCount === company?.currentUserCount ? (
                  <Button isDisabled>ユーザー数上限に達しています</Button>
                ) : (
                  <Button isLoading={isCreating} onClick={onSubmit}>
                    作成
                  </Button>
                )}
              </>
            )}
          </HStack>
        </Flex>
      </Base>
    )
  }
)
