import { createIcon } from '@chakra-ui/icons'

export const CsbLinearIcon = createIcon({
  displayName: 'CsbLinearIcon',
  path: (
    <path
      d="M1 0C0.45 0 0 0.45 0 1C0 1.55 0.45 2 1 2C1.55 2 2 1.55 2 1C2 0.45 1.55 0 1 0ZM11 0C10.45 0 10 0.45 10 1C10 1.55 10.45 2 11 2C11.55 2 12 1.55 12 1C12 0.45 11.55 0 11 0ZM6 0C5.45 0 5 0.45 5 1C5 1.55 5.45 2 6 2C6.55 2 7 1.55 7 1C7 0.45 6.55 0 6 0Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 12 2',
})
