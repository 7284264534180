import {
  Button,
  Flex,
  Grid,
  Heading,
  HStack,
  Progress,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  CsbDeleteButton,
  CsbDeleteModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
  CsbNextLink,
  CsbPagination,
  CsbTag,
} from '@src/components'
import { CsbArchiveButton } from '@src/components/Buttons/CsbArchiveButton'
import { CsbCopyButton } from '@src/components/Buttons/CsbCopyButton'
import { CsbTable } from '@src/components/CsbTable'
import { CsbDataModal } from '@src/components/Modal/CsbDataModal'
import { usePhishingHooksResult } from '@src/components/pages/phishing/hooks'
import { Base } from '@src/layouts'
import { percentage } from '@src/utils/percentage'
import dayjs from 'dayjs'
import { memo } from 'react'
import { FaStar } from 'react-icons/fa'
import Rating from 'react-rating'

//フィシイング一覧ページのcomponent
export const Phishing = memo(
  ({
    current,
    data,
    dataModal,
    deleteModal,
    destroyPhishingEmailMutation,
    isArchiving,
    isDeliveryContentsOnly,
    isWritePhishingMail,
    navigate,
    onArchivePhishingEmail,
    onChangePage,
    refetch,
    resolveDeliveryContentsUrl,
  }: usePhishingHooksResult) => {
    return (
      <Base
        left={
          <Heading>
            {isDeliveryContentsOnly
              ? 'コンテンツメールの作成'
              : 'フィッシングメール'}
          </Heading>
        }
        right={
          <HStack spacing={3}>
            {!isDeliveryContentsOnly && (
              <CsbNextLink
                href={resolveDeliveryContentsUrl('/phishing/archives')}
              >
                <Button as={'div'} colorScheme={'gray'} w={160}>
                  アーカイブ済
                </Button>
              </CsbNextLink>
            )}
            {isWritePhishingMail && (
              <CsbNextLink href={resolveDeliveryContentsUrl('/phishing/new')}>
                <Button as={'div'} w={210}>
                  新しいメールの作成
                </Button>
              </CsbNextLink>
            )}
          </HStack>
        }
      >
        <CsbTable
          labels={['ステータス', 'ラベル', '']}
          wrapperProps={{ mt: 35 }}
        >
          {data?.phishingEmails.collection.map((item) => (
            <CsbTable.TableRow
              key={item.uuid}
              {...(isWritePhishingMail && !item.sentAt
                ? {
                    onClick: () =>
                      navigate(
                        resolveDeliveryContentsUrl(
                          `/phishing/${item.uuid}/${
                            item.sentAt ? 'result' : 'edit'
                          }`
                        )
                      ),
                  }
                : item.sentAt
                ? {
                    onClick: () =>
                      navigate(
                        resolveDeliveryContentsUrl(
                          `/phishing/${item.uuid}/result`
                        )
                      ),
                  }
                : {})}
            >
              <CsbTable.TableCell w={120}>
                {item.sentAt && !item.draft ? (
                  <CsbTag colorScheme={'teal'} size={'md'}>
                    送信済み
                  </CsbTag>
                ) : null}

                {!item.sentAt && item.reserveAt && !item.draft ? (
                  <CsbTag colorScheme={'blue'} size={'md'}>
                    予約配信
                  </CsbTag>
                ) : null}

                {!item.sentAt &&
                item.hasPhishingEmailSchedules &&
                !item.draft ? (
                  <CsbTag colorScheme={'green'} size={'md'}>
                    分割配信
                  </CsbTag>
                ) : null}

                {item.draft ? (
                  <CsbTag colorScheme={'gray'} size={'md'}>
                    下書き
                  </CsbTag>
                ) : null}

                {!isDeliveryContentsOnly && (
                  <Rating
                    readonly
                    emptySymbol={
                      <FaStar fill={'rgb(230,230,230)'} fontSize={'12px'} />
                    }
                    fractions={2}
                    fullSymbol={<FaStar fill={'orange'} fontSize={'12px'} />}
                    initialRating={item.rating ?? 1}
                  />
                )}
              </CsbTable.TableCell>
              <CsbTable.TableCell>
                <Flex align={'start'} direction={'column'}>
                  <Text color={'gray.600'} fontSize={'xs'} lineHeight={1}>
                    {item.sentAt && !item.draft
                      ? `${dayjs(item.sentAt).format(
                          'YYYY/MM/DD HH:mm'
                        )}に送信済み`
                      : null}

                    {!item.sentAt && item.reserveAt && !item.draft
                      ? `${dayjs(item.reserveAt).format(
                          'YYYY/MM/DD HH:mm'
                        )} に送信予定`
                      : null}

                    {!item.sentAt &&
                    item.hasPhishingEmailSchedules &&
                    item.firstScheduleAt &&
                    !item.draft
                      ? `${dayjs(item.firstScheduleAt ?? '').format(
                          'YYYY/MM/DD HH:mm'
                        )} 〜随時送信予定`
                      : null}

                    {item.draft ? `未送信` : null}
                  </Text>
                  <Grid>
                    <Tooltip aria-label="A tooltip" label={item.name}>
                      <Text
                        isTruncated
                        fontWeight={'bold'}
                        lineHeight={1}
                        mt={3.5}
                      >
                        {item.name}
                      </Text>
                    </Tooltip>
                  </Grid>
                  {!isDeliveryContentsOnly && (
                    <HStack mt={1} spacing={5} w={'full'}>
                      <Progress
                        colorScheme={item.draft ? 'gray' : 'teal'}
                        maxW={320}
                        size="sm"
                        value={percentage(
                          (item.openCount as number) /
                            (item.sendCount as number)
                        )}
                        w={'full'}
                      />
                      <Text fontSize={'sm'}>
                        {item.openCount as number} / {item.sendCount as number}
                        件
                      </Text>
                      {item.sentAt && (
                        <Text fontSize={'sm'}>
                          ログイン{item.loginCount as number}人
                        </Text>
                      )}
                    </HStack>
                  )}
                  {!item.revealingPhishingEmailSchedule ? (
                    <CsbTag colorScheme={'red'} mt={2} size={'md'}>
                      {!isDeliveryContentsOnly
                        ? '種明かしメール未設定'
                        : 'リマインドメール未設定'}
                    </CsbTag>
                  ) : (
                    <Text
                      color={'gray.600'}
                      fontSize={'xs'}
                      lineHeight={1}
                      mt={2}
                    >
                      {!isDeliveryContentsOnly
                        ? '種明かしメール設定済み'
                        : 'リマインドメール設定済み'}
                    </Text>
                  )}
                </Flex>
              </CsbTable.TableCell>
              <CsbTable.TableCell isNumeric>
                <Flex gridGap={5} justifyContent={'end'}>
                  {isWritePhishingMail && (
                    <CsbCopyButton
                      onClick={(e) => {
                        e.stopPropagation()
                        navigate(
                          resolveDeliveryContentsUrl(`/phishing/new`, {
                            params: {
                              reuseId: item.uuid,
                            },
                          })
                        )
                      }}
                    />
                  )}
                  {isWritePhishingMail && item.sentAt ? (
                    <>
                      {!isDeliveryContentsOnly && (
                        <CsbArchiveButton
                          onClick={(e) => {
                            e.stopPropagation()
                            dataModal.onOpen(() => {
                              dataModal.setData(item)
                            })
                          }}
                        >
                          アーカイブ
                        </CsbArchiveButton>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {isWritePhishingMail && (
                    <CsbDeleteButton
                      onClick={(e) => {
                        e.stopPropagation()
                        deleteModal.onOpen(item.uuid, item.name)
                      }}
                    />
                  )}
                </Flex>
              </CsbTable.TableCell>
            </CsbTable.TableRow>
          ))}
        </CsbTable>
        <Flex mt={5}>
          {data ? (
            <CsbPagination
              current={current}
              defaultCurrent={current}
              pageSize={data.phishingEmails.metadata.limitValue}
              total={data.phishingEmails.metadata.totalCount}
              onChange={onChangePage('/phishing')}
            />
          ) : null}
          <HStack ml={'auto'} spacing={3}>
            {!isDeliveryContentsOnly && (
              <CsbNextLink
                href={resolveDeliveryContentsUrl('/phishing/archives')}
              >
                <Button as={'div'} colorScheme={'gray'} w={160}>
                  アーカイブ済
                </Button>
              </CsbNextLink>
            )}
            {isWritePhishingMail && (
              <CsbNextLink href={resolveDeliveryContentsUrl('/phishing/new')}>
                <Button as={'div'} w={210}>
                  新しいメールの作成
                </Button>
              </CsbNextLink>
            )}
          </HStack>
        </Flex>
        <CsbDeleteModal
          header={deleteModal.target}
          isOpen={deleteModal.isOpen}
          onCancel={deleteModal.onClose}
          onClose={deleteModal.onClose}
          onDelete={() =>
            deleteModal.onDelete(
              destroyPhishingEmailMutation({
                variables: {
                  input: { uuid: deleteModal.uuid ?? '' },
                },
              }),
              async () => {
                const { data } = await refetch()
                return {
                  metadata: {
                    currentPage: data.phishingEmails.metadata.currentPage,
                    limitValue: data.phishingEmails.metadata.limitValue,
                    totalCount: data.phishingEmails.metadata.totalCount,
                  },
                }
              },
              onChangePage('/phishing')
            )
          }
        />
        <CsbDataModal {...dataModal}>
          <CsbModalHeader>警告</CsbModalHeader>
          <CsbModalBody mt={10}>
            {dataModal.data?.name}をアーカイブしますか？
          </CsbModalBody>
          <CsbModalFooter mt={7}>
            <HStack spacing={6}>
              <Button colorScheme={'gray'} onClick={dataModal.onClose}>
                キャンセル
              </Button>
              <Button isLoading={isArchiving} onClick={onArchivePhishingEmail}>
                アーカイブ
              </Button>
            </HStack>
          </CsbModalFooter>
        </CsbDataModal>
      </Base>
    )
  }
)
