import { chakra } from '@chakra-ui/react'
import Editor, { EditorProps } from '@monaco-editor/react'
import { BaseCsbEditorPropsType } from '@src/components/CsbEditor'
import React from 'react'

const ChakraEditor = chakra(Editor)

export type CodeEditorPropsType = {
  onChanged: () => void
} & BaseCsbEditorPropsType

export const CodeEditor = ({
  data,
  disabled,
  onChangeHtml,
  onChanged,
}: CodeEditorPropsType) => {
  const handleOnChange: EditorProps['onChange'] = (value) => {
    if (value !== undefined) {
      onChanged()
      onChangeHtml?.(value)
    }
  }

  return (
    <ChakraEditor
      borderWidth={'1px'}
      height="500px"
      language={'html'}
      options={{
        autoIndent: 'full',
        automaticLayout: true,
        formatOnPaste: true,
        formatOnType: true,
        minimap: {
          enabled: false,
        },
        readOnly: disabled,
        wordWrap: 'on',
      }}
      rounded={'sm'}
      value={data}
      onChange={handleOnChange}
    />
  )
}
