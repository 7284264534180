//ユーザー編集で使うhooksのまとめ
import { useToast } from '@chakra-ui/react'
import {
  UpdateUserInput,
  useUpdateUserMutation,
  useUserQuery,
} from '@src/graphql/generated/graphql'
import { useErrors, useMe } from '@src/hooks'
import { userFormDefaultValues } from '@src/pages/users/formDefaultValues'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

export const useUsersEditHooks = () => {
  const { role } = useMe()
  const isWriteUser = !!role?.writeUser
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const toast = useToast()
  const [updateUserMutation, { loading: isUpdating }] = useUpdateUserMutation()
  const [errors, setErrors] = useErrors()
  const form = useForm<Omit<UpdateUserInput, 'uuid'>>({
    defaultValues: userFormDefaultValues,
  })
  const { data, loading } = useUserQuery({
    onCompleted: ({ user }) => {
      form.setValue('avatarPath', user.avatarPath)
      form.setValue('birthdayOn', user.birthdayOn)
      form.setValue('joinedOn', user.joinedOn)
      form.setValue('email', user.email)
      form.setValue('firstName', user.firstName)
      form.setValue('gender', user.gender)
      form.setValue('positionId', user.position?.uuid)
      form.setValue('lastName', user.lastName)
      form.setValue('mobileNumber', user.mobileNumber)
      form.setValue('officeNumber', user.officeNumber)
      form.setValue('telephoneNumber', user.telephoneNumber)
      form.setValue('employeeNumber', user.employeeNumber)
      form.setValue('testIgnore', user.testIgnore)
      form.setValue('roleId', user.role.uuid)
      form.setValue(
        'groups',
        user.groups.map(({ uuid }) => uuid)
      )
      form.setValue(
        'organizations',
        user.organizations.map(({ uuid }) => uuid)
      )
      form.setValue('memo', user.memo)
    },
    variables: { uuid: id ?? '' },
  })

  const onSubmit = form.handleSubmit(
    async ({
      birthdayOn,
      joinedOn,
      password,
      passwordConfirmation,
      positionId,
      ...inputData
    }: Omit<UpdateUserInput, 'uuid'>) => {
      try {
        await updateUserMutation({
          variables: {
            input: {
              uuid: id ?? '',
              ...inputData,
              birthdayOn: birthdayOn ?? null,
              joinedOn: joinedOn ?? null,
              password: password || null,
              passwordConfirmation: passwordConfirmation || null,
              positionId: positionId || null,
            },
          },
        })
        setErrors([])
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'success',
          title: 'ユーザーを編集しました。',
        })
        await navigate('/users')
        /**
         * TODO anyを消す
         * e instanceOf ApolloError
         * setErrorsを変更する
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'error',
          title: 'ユーザーを編集に失敗しました。',
        })
        setErrors(e.graphQLErrors)
      }
    }
  )
  return {
    data,
    errors,
    form,
    isUpdating,
    isWriteUser,
    loading,
    navigate,
    onSubmit,
  }
}

export type useUsersEditHooksResult = ReturnType<typeof useUsersEditHooks>
