import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Progress,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
  CsbTag,
} from '@src/components'
import { CsbCopyButton } from '@src/components/Buttons/CsbCopyButton'
import { CsbUnArchiveButton } from '@src/components/Buttons/CsbUnArchiveButton'
import { CsbTable } from '@src/components/CsbTable'
import { CsbDataModal } from '@src/components/Modal/CsbDataModal'
import { usePhishingArchivesHooksResult } from '@src/components/pages/phishing/archives/hooks'
import { PhishingEmailsQuery } from '@src/models/PhishingEmails/PhishingEmailsQuery'
import { percentage } from '@src/utils/percentage'
import dayjs from 'dayjs'

type PhishingArchivesContentsPropsType = Pick<
  usePhishingArchivesHooksResult,
  'phishingEmailsQuery' | 'phishingArchivesContents'
>

export const PhishingArchivesContents = ({
  phishingArchivesContents: {
    dataModal,
    isUnArchiving,
    isWritePhishingMail,
    onNavigateResult,
    onReusePhishingMail,
    onUnArchivePhishingEmail,
  },
  phishingEmailsQuery,
}: PhishingArchivesContentsPropsType) => {
  return (
    <Box>
      <PhishingEmailsQuery {...phishingEmailsQuery}>
        {(data) => (
          <CsbTable
            labels={['ステータス', 'ラベル', '']}
            wrapperProps={{ mt: 35 }}
          >
            {data.collection.map((phishingEmail) => (
              <CsbTable.TableRow
                key={phishingEmail.uuid}
                onClick={() => onNavigateResult(phishingEmail.uuid)}
              >
                <CsbTable.TableCell w={120}>
                  <CsbTag
                    colorScheme={phishingEmail.sentAt ? 'teal' : 'gray'}
                    size={'md'}
                  >
                    {phishingEmail.sentAt ? '送信済み' : '下書き'}
                  </CsbTag>
                </CsbTable.TableCell>
                <CsbTable.TableCell>
                  <Flex align={'start'} direction={'column'}>
                    <Text color={'gray.600'} fontSize={'xs'} lineHeight={1}>
                      {phishingEmail.sentAt
                        ? `${dayjs(phishingEmail.sentAt).format(
                            'YYYY/MM/DD HH:mm'
                          )}に送信済み`
                        : '未送信'}
                    </Text>
                    <Grid>
                      <Tooltip
                        aria-label="A tooltip"
                        label={phishingEmail.name}
                      >
                        <Text
                          isTruncated
                          fontWeight={'bold'}
                          lineHeight={1}
                          mt={3.5}
                        >
                          {phishingEmail.name}
                        </Text>
                      </Tooltip>
                    </Grid>
                    <HStack mt={1} spacing={5} w={'full'}>
                      <Progress
                        colorScheme={phishingEmail.draft ? 'gray' : 'teal'}
                        maxW={320}
                        size="sm"
                        value={percentage(
                          (phishingEmail.openCount ?? 0) /
                            (phishingEmail.sendCount ?? 0)
                        )}
                        w={'full'}
                      />
                      <Text fontSize={'sm'}>
                        {phishingEmail.openCount ?? 0} /{' '}
                        {phishingEmail.sendCount ?? 0}件
                      </Text>
                      {phishingEmail.sentAt && (
                        <Text fontSize={'sm'}>
                          ログイン{phishingEmail.loginCount ?? 0}人
                        </Text>
                      )}
                    </HStack>
                  </Flex>
                </CsbTable.TableCell>
                <CsbTable.TableCell w={25}>
                  <Flex gridGap={5} justifyContent={'end'}>
                    {isWritePhishingMail && phishingEmail.sentAt && (
                      <>
                        <CsbCopyButton
                          onClick={onReusePhishingMail(phishingEmail.uuid)}
                        />
                        <CsbUnArchiveButton
                          onClick={(e) => {
                            e.stopPropagation()
                            dataModal.onOpen(() => {
                              dataModal.setData(phishingEmail)
                            })
                          }}
                        >
                          アーカイブ
                        </CsbUnArchiveButton>
                      </>
                    )}
                  </Flex>
                </CsbTable.TableCell>
              </CsbTable.TableRow>
            ))}
          </CsbTable>
        )}
      </PhishingEmailsQuery>
      <CsbDataModal {...dataModal}>
        <CsbModalHeader>警告</CsbModalHeader>
        <CsbModalBody mt={10}>
          {dataModal.data?.name}のアーカイブを解除しますか？
        </CsbModalBody>
        <CsbModalFooter mt={7}>
          <HStack spacing={6}>
            <Button colorScheme={'gray'} onClick={dataModal.onClose}>
              キャンセル
            </Button>
            <Button
              isLoading={isUnArchiving}
              onClick={onUnArchivePhishingEmail}
            >
              アーカイブを解除
            </Button>
          </HStack>
        </CsbModalFooter>
      </CsbDataModal>
    </Box>
  )
}
