import { HStack, Input, Select, Textarea, VStack } from '@chakra-ui/react'
import { CsbFormControl, CsbMultiSelect } from '@src/components'
import { DropDropUploader } from '@src/components/Form/DropDropUploader'
import { UserEditForm } from '@src/components/Form/user/UserEditForm'
import {
  Group,
  Organization,
  useEmployeeInformationQuery,
} from '@src/graphql/generated/graphql'
import { useMultiSelect } from '@src/hooks'
import { findError } from '@src/utils/findError'
import { ComponentProps } from 'react'

type EmployeeInformationPropsType = ComponentProps<typeof UserEditForm>

export const EmployeeInformation = ({
  errors,
  form,
}: EmployeeInformationPropsType) => {
  const { data, loading } = useEmployeeInformationQuery()

  //組織のselectを管理するhooks
  const {
    onChangeSelected: onChangeOrganizationsSelected,
    select: organizationsSelect,
  } = useMultiSelect<Pick<Organization, 'uuid' | 'name'>[] | undefined>(
    data?.organizations.collection,
    form.getValues('organizations')
  )

  //グループのselectを管理するhooks
  const { onChangeSelected: onChangeGroupsSelected, select: groupsSelect } =
    useMultiSelect<Pick<Group, 'uuid' | 'name'>[] | undefined>(
      data?.groups.collection,
      form.getValues('groups')
    )

  return (
    <VStack align={'start'} mt={6} spacing={5}>
      <HStack spacing={'35px'}>
        <CsbFormControl
          errorText={findError(errors, 'positionId')?.message}
          labelText={'役職'}
          w={285}
        >
          {!loading && (
            <Select
              placeholder="なし"
              {...form.register('positionId')}
              defaultValue={form.getValues('positionId') ?? ''}
            >
              {data?.positions.collection.map(({ name, uuid }) => (
                <option key={uuid} value={uuid}>
                  {name}
                </option>
              ))}
            </Select>
          )}
        </CsbFormControl>
        <CsbFormControl
          errorText={findError(errors, 'organizations')?.message}
          labelText={'組織'}
          w={285}
        >
          {!loading && (
            <CsbMultiSelect
              initialSelectedItems={organizationsSelect.selectedOptions}
              options={organizationsSelect.options}
              onChangeSelected={onChangeOrganizationsSelected((uuids) =>
                form.setValue('organizations', uuids)
              )}
            />
          )}
        </CsbFormControl>
      </HStack>
      <HStack spacing={'35px'}>
        <CsbFormControl
          errorText={findError(errors, 'groups')?.message}
          labelText={'グループ'}
          w={285}
        >
          {!loading && (
            <CsbMultiSelect
              initialSelectedItems={groupsSelect.selectedOptions}
              options={groupsSelect.options}
              onChangeSelected={onChangeGroupsSelected((uuids) =>
                form.setValue('groups', uuids)
              )}
            />
          )}
        </CsbFormControl>
        <CsbFormControl
          errorText={findError(errors, 'employeeNumber')?.message}
          labelText={'社員番号'}
          w={285}
        >
          <Input {...form.register('employeeNumber')} />
        </CsbFormControl>
      </HStack>
      <HStack spacing={'35px'}>
        <CsbFormControl
          errorText={findError(errors, 'telephoneNumber')?.message}
          labelText={'電話番号'}
          w={285}
        >
          <Input type={'tel'} {...form.register('telephoneNumber')} />
        </CsbFormControl>
        <CsbFormControl
          errorText={findError(errors, 'officeNumber')?.message}
          labelText={'内線番号'}
          w={285}
        >
          <Input type={'tel'} {...form.register('officeNumber')} />
        </CsbFormControl>
      </HStack>
      <CsbFormControl
        errorText={findError(errors, 'mobileNumber')?.message}
        labelText={'携帯番号'}
        w={285}
      >
        <Input type={'tel'} {...form.register('mobileNumber')} />
      </CsbFormControl>
      <CsbFormControl
        errorText={findError(errors, 'avatarPath')?.message}
        labelText={'アバター画像'}
        w={500}
      >
        <DropDropUploader
          savePath={'users/avatar'}
          url={form.getValues('avatarPath') || undefined}
          onChange={(url) => {
            form.setValue('avatarPath', url)
          }}
        />
      </CsbFormControl>

      <CsbFormControl
        errorText={findError(errors, `memo`)?.message}
        labelText={'メモ'}
        w={600}
      >
        <Textarea resize={'vertical'} {...form.register(`memo`)} rows={10} />
      </CsbFormControl>
    </VStack>
  )
}
