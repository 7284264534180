import { useDisclosure } from '@chakra-ui/react'
import { useCreateQuestionnaire } from '@src/models/Questionnaire'
import { useCallback } from 'react'

export const useCreateQuestionnaireModal = () => {
  const { isOpen, onClose: _onClose, onOpen } = useDisclosure()

  const {
    findErrorMessage,
    form,
    isLoading,
    isResearchSelectable,
    onCreateQuestionnaire,
    researches,
    resetForm,
  } = useCreateQuestionnaire({
    onCompleted: () => {
      _onClose()
    },
  })
  const onClose = useCallback(() => {
    resetForm()
    _onClose()
  }, [_onClose, resetForm])

  return {
    findErrorMessage,
    form,
    isLoading,
    isOpen,
    isResearchSelectable,
    onClose,
    onCreateQuestionnaire,
    onOpen,
    researches,
  }
}

type useCreateQuestionnaireModalType = typeof useCreateQuestionnaireModal
export type useCreateQuestionnaireModalResult =
  ReturnType<useCreateQuestionnaireModalType>
// export type useCreateResearchModalParameter = Parameters<useCreateResearchModalType>[0]
