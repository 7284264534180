import { paginationInput } from '@src/constants'
import { useMe, usePagination } from '@src/hooks'
import {
  useAdminResearches,
  useCreateAdminResearchModal,
  useDestroyAdminResearchModal,
} from '@src/models/AdminResearch'
import { useUpdateAdminResearchModal } from '@src/models/AdminResearch'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const useAdminResearchesHooks = () => {
  const { role } = useMe()
  const isWriteAdminResearch = !!role?.writeAdminResearch
  //ページネーション
  const { current, onChangePage } = usePagination()
  const researches = useAdminResearches({
    variables: {
      pagination: paginationInput(current),
    },
  })
  const navigate = useNavigate()
  const createAdminResearchModal = useCreateAdminResearchModal()
  const destroyAdminResearchModal = useDestroyAdminResearchModal()
  const updateAdminResearchModal = useUpdateAdminResearchModal()

  //search機能
  const searchForm = useForm<{ searchWord: string }>()
  const onSearch = searchForm.handleSubmit(async (inputData) => {
    try {
      await researches.refetch({
        pagination: {
          limit: 30,
          page: current,
        },
        searchWord: inputData.searchWord,
      })
    } catch (e) {
      console.log(e)
    }
  })
  const onResetSearch = () => {
    searchForm.resetField('searchWord')
    onSearch()
  }

  return {
    createAdminResearchModal,
    current,
    destroyAdminResearchModal,
    isWriteAdminResearch,
    navigate,
    onChangePage,
    onResetSearch,
    onSearch,
    researches,
    searchForm,
    updateAdminResearchModal,
  }
}

export type UseAdminResearchesHooksResult = ReturnType<
  typeof useAdminResearchesHooks
>
