import {
  AspectRatio,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  VStack,
} from '@chakra-ui/react'
import { CsbFormControl, CsbPrimaryBox } from '@src/components'
import { useCompaniesDetailHooksResult } from '@src/components/pages/companies/detail/hooks'
import { Base } from '@src/layouts'
import { getCompanyMailLimitTypeName } from '@src/utils/companyMailLiimit'
import dayjs from 'dayjs'
import React, { memo } from 'react'

//企業詳細ページのcomponent
export const CompaniesDetail = memo(
  ({
    company,
    isWriteCompany,
    onNavigatePage,
  }: useCompaniesDetailHooksResult) => {
    const PrimaryBoxStyle = {
      cursor: 'not-allowed',
      minH: '2.4em',
      mt: 2,
      opacity: 0.4,
      px: 4,
      py: 2,
      rounded: 'md',
    }

    return (
      <Base
        left={<Heading>{company?.name}</Heading>}
        right={
          <HStack spacing={3}>
            <Button colorScheme={'gray'} onClick={onNavigatePage('index')}>
              一覧へ戻る
            </Button>
            {isWriteCompany && (
              <Button onClick={onNavigatePage('edit')}>編集</Button>
            )}
          </HStack>
        }
      >
        <CsbPrimaryBox pb={'72px'} pt={35} px={45}>
          {company ? (
            <>
              <Heading fontSize={24} mb={3}>
                会社情報
              </Heading>
              <Divider />
              <VStack align={'start'} mt={6} spacing={5}>
                <CsbFormControl labelText={'企業ID'}>
                  <CsbPrimaryBox {...PrimaryBoxStyle}>
                    {company.id}
                  </CsbPrimaryBox>
                </CsbFormControl>
                <CsbFormControl labelText={'企業名'}>
                  <CsbPrimaryBox {...PrimaryBoxStyle}>
                    {company.name}
                  </CsbPrimaryBox>
                </CsbFormControl>
                <CsbFormControl labelText={'ドメイン'}>
                  <CsbPrimaryBox {...PrimaryBoxStyle}>
                    {company.domain}
                  </CsbPrimaryBox>
                </CsbFormControl>
                <CsbFormControl labelText={'郵便番号'}>
                  <CsbPrimaryBox {...PrimaryBoxStyle}>
                    {company.postalCode ?? '未設定'}
                  </CsbPrimaryBox>
                </CsbFormControl>
                <CsbFormControl labelText={'都道府県'}>
                  <CsbPrimaryBox {...PrimaryBoxStyle}>
                    {company.state ?? '未設定'}
                  </CsbPrimaryBox>
                </CsbFormControl>
                <CsbFormControl labelText={'市区町村'}>
                  <CsbPrimaryBox {...PrimaryBoxStyle}>
                    {company.address ?? '未設定'}
                  </CsbPrimaryBox>
                </CsbFormControl>
                <CsbFormControl labelText={'番地'}>
                  <CsbPrimaryBox {...PrimaryBoxStyle}>
                    {company.street ?? '未設定'}
                  </CsbPrimaryBox>
                </CsbFormControl>
                <CsbFormControl labelText={'建物名/部屋番号'}>
                  <CsbPrimaryBox {...PrimaryBoxStyle}>
                    {company.building ?? '未設定'}
                  </CsbPrimaryBox>
                </CsbFormControl>
                <CsbFormControl labelText={'企業ロゴ'}>
                  <AspectRatio maxW={'150px'} ratio={1}>
                    {company.logoPath ? (
                      <Image src={company.logoPath} />
                    ) : (
                      <CsbPrimaryBox {...PrimaryBoxStyle}>未設定</CsbPrimaryBox>
                    )}
                  </AspectRatio>
                </CsbFormControl>
              </VStack>
              <Box mt={55}>
                <Heading fontSize={24} mb={3}>
                  契約情報
                </Heading>
                <Divider />
                <VStack align={'start'} mt={6} spacing={5}>
                  <CsbFormControl
                    labelText={'ユーザー数上限'}
                    textAlign={'right'}
                  >
                    <CsbPrimaryBox {...PrimaryBoxStyle} w={100}>
                      {company.maxUserCount?.toLocaleString() ?? '無制限'}
                    </CsbPrimaryBox>
                  </CsbFormControl>

                  {company.companyMailLimits.length === 0 && (
                    <CsbFormControl
                      labelText={'月間最大送信数'}
                      textAlign={'right'}
                    >
                      <CsbPrimaryBox {...PrimaryBoxStyle} w={100}>
                        {company.maxMonthlyMailCount?.toLocaleString() ??
                          '無制限'}
                      </CsbPrimaryBox>
                    </CsbFormControl>
                  )}

                  <CsbFormControl labelText={'有効期限'}>
                    <CsbPrimaryBox {...PrimaryBoxStyle} w={250}>
                      {company.expiresAt
                        ? dayjs(company.expiresAt).format('YYYY/MM/DD')
                        : '期限なし'}
                    </CsbPrimaryBox>
                  </CsbFormControl>
                  <CsbFormControl
                    labelText={'テストメール配信可能人数'}
                    textAlign={'right'}
                  >
                    <CsbPrimaryBox {...PrimaryBoxStyle} w={100}>
                      {company.testMailCount?.toLocaleString() ?? ''}
                    </CsbPrimaryBox>
                  </CsbFormControl>
                  {company.companyMailLimits.map((companyMailLimit, index) => (
                    <HStack key={index} spacing={'20px'}>
                      <CsbFormControl
                        labelText={index === 0 ? '送信タイプ' : ''}
                      >
                        <CsbPrimaryBox {...PrimaryBoxStyle} w={250}>
                          {getCompanyMailLimitTypeName(
                            companyMailLimit.limitType
                          )}
                        </CsbPrimaryBox>
                      </CsbFormControl>

                      <CsbFormControl labelText={index === 0 ? '開始日' : ''}>
                        <CsbPrimaryBox {...PrimaryBoxStyle} w={250}>
                          {companyMailLimit.startDate
                            ? dayjs(companyMailLimit.startDate).format(
                                'YYYY/MM/DD'
                              )
                            : ''}
                        </CsbPrimaryBox>
                      </CsbFormControl>
                      <CsbFormControl labelText={index === 0 ? '終了日' : ''}>
                        <CsbPrimaryBox {...PrimaryBoxStyle} w={250}>
                          {companyMailLimit.endDate
                            ? dayjs(companyMailLimit.endDate).format(
                                'YYYY/MM/DD'
                              )
                            : '期限なし'}
                        </CsbPrimaryBox>
                      </CsbFormControl>
                      <CsbFormControl
                        labelText={index === 0 ? '最大送信数' : ''}
                      >
                        <CsbPrimaryBox
                          {...PrimaryBoxStyle}
                          textAlign={'right'}
                          w={100}
                        >
                          {companyMailLimit.limitCount?.toLocaleString() ??
                            '無制限'}
                        </CsbPrimaryBox>
                      </CsbFormControl>
                      <CsbFormControl labelText={index === 0 ? '現送信数' : ''}>
                        <CsbPrimaryBox
                          {...PrimaryBoxStyle}
                          textAlign={'right'}
                          w={100}
                        >
                          {companyMailLimit.currentCount.toLocaleString()}
                        </CsbPrimaryBox>
                      </CsbFormControl>
                    </HStack>
                  ))}
                </VStack>
              </Box>
            </>
          ) : (
            <Box>企業データがありません。</Box>
          )}
        </CsbPrimaryBox>
        <Flex mt={5}>
          <HStack ml={'auto'} spacing={3}>
            <Button colorScheme={'gray'} onClick={onNavigatePage('index')}>
              一覧へ戻る
            </Button>
            {isWriteCompany && (
              <Button onClick={onNavigatePage('edit')}>編集</Button>
            )}
          </HStack>
        </Flex>
      </Base>
    )
  }
)
