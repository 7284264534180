import { Box, BoxProps, Stack, Text } from '@chakra-ui/react'
import {
  adminMenu,
  baseMenu,
  contentMenu,
  isShowAdminMenu,
  isShowContentMenu,
} from '@src/components/Aside/asideMenu'
import { CsbAsideMenuItem } from '@src/components/Aside/CsbAsideMenuItem'
import { useMe } from '@src/hooks'
import { memo } from 'react'

type ShowAnyMenuPropsType = ReturnType<typeof adminMenu | typeof contentMenu>
const ShowAnyMenu = (menuList: ShowAnyMenuPropsType) => {
  return menuList.map(({ items, title }, menuIndex) => (
    <Stack key={`menu_${title}_${menuIndex}`} pt={10} px={4} spacing={1}>
      <Text color={'gray'} fontSize={'12px'} fontWeight={'bold'} mb={1}>
        {title}
      </Text>
      {items.map((item, index) => (
        <CsbAsideMenuItem {...item} key={`${item.text}_${index}`} />
      ))}
    </Stack>
  ))
}

type CsbAsidePropsType = Omit<BoxProps, 'children'>
export const CsbAside = memo(({ ...props }: CsbAsidePropsType) => {
  const { role } = useMe()

  return (
    <Box
      as="aside"
      bg="white"
      borderRightWidth={1}
      h="100vh"
      insetX={0}
      overflowY={'auto'}
      position="sticky"
      py={8}
      top={0}
      {...props}
    >
      <Stack px={4} spacing={1}>
        {role &&
          baseMenu(role).map((item, index) => (
            <CsbAsideMenuItem {...item} key={`${item.text}_${index}`} />
          ))}
      </Stack>
      {role && isShowAdminMenu(role) && ShowAnyMenu(adminMenu(role))}
      {role && isShowContentMenu(role) && ShowAnyMenu(contentMenu(role))}
    </Box>
  )
})
