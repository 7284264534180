import { DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import { CsbDropzone } from '@src/components'
import getCroppedImg from '@src/components/Form/getCroppedImg'
import { useUpload } from '@src/hooks/useUpload'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Cropper, { Area } from 'react-easy-crop'

type DropUploaderType = {
  crop?: boolean
  onChange: (url: string) => void
  savePath: string
  url?: string
}

export const DropDropUploader = ({
  crop,
  onChange,
  savePath,
  url,
}: DropUploaderType) => {
  const { image, loading, onUpload, removeImage, thumbnail } = useUpload({
    savePath,
    url,
  })

  const [cropping, setCropping] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  })

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels)
    },
    []
  )

  const { isOpen, onClose, onOpen } = useDisclosure()
  const [previewSrc, setPreviewSrc] = useState<string>('')

  useEffect(() => {
    onChange(image)
  }, [image, onChange])

  const dropzone = useDropzone({
    multiple: false,
    onDrop: crop
      ? (a) => {
          const reader = new FileReader()
          reader.addEventListener('load', () => {
            if (reader.result) {
              setPreviewSrc(reader.result.toString() || '')
              onOpen()
            }
          })
          reader.readAsDataURL(a[0])
        }
      : onUpload,
  })

  const handleSubmit = async () => {
    const files = await getCroppedImg(previewSrc, croppedAreaPixels)
    await onUpload(files)
    await onClose()
  }

  return (
    <HStack spacing={3}>
      {thumbnail !== '' ? (
        <Box
          bg={'white'}
          bgImage={thumbnail}
          bgSize={'cover'}
          borderRadius={10}
          borderWidth={'1px'}
          h={crop ? 225 : 150}
          overflow={'hidden'}
          position={'relative'}
          w={crop ? 400 : 150}
        >
          {loading ? (
            <Box
              alignItems={'center'}
              bgColor={'rgba(0,0,0,.5)'}
              d={'flex'}
              h={150}
              justifyContent={'center'}
              w={150}
            >
              <Spinner
                color="blue.500"
                emptyColor="gray.200"
                size="xl"
                speed="0.65s"
                thickness="4px"
              />
            </Box>
          ) : (
            <Box
              _hover={{
                opacity: 1,
              }}
              alignItems={'center'}
              bgColor={'rgba(0,0,0,.75)'}
              cursor={'pointer'}
              d={'flex'}
              h={crop ? 225 : 150}
              justifyContent={'center'}
              opacity={0}
              transition={'all .3s'}
              w={crop ? 400 : 150}
              onClick={removeImage}
            >
              <DeleteIcon color={'white'} h={12} w={12} />
            </Box>
          )}
        </Box>
      ) : null}

      {thumbnail === '' ? <CsbDropzone {...dropzone} /> : null}

      <Modal isOpen={isOpen} size={'full'} onClose={onClose}>
        <ModalContent>
          <ModalHeader>画像のトリミング</ModalHeader>
          <ModalCloseButton />
          <ModalBody position={'relative'}>
            <Cropper
              aspect={16 / 9}
              crop={cropping}
              cropSize={{
                height: 288,
                width: 512,
              }}
              image={previewSrc}
              minZoom={0.1}
              showGrid={true}
              zoom={zoom}
              onCropChange={setCropping}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </ModalBody>

          <ModalFooter>
            <Button isLoading={loading} mr={3} onClick={handleSubmit}>
              画像を保存
            </Button>
            <Button variant="ghost" onClick={onClose}>
              キャンセル
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  )
}
