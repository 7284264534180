import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Input,
  Select,
  VStack,
} from '@chakra-ui/react'
import { CsbEditor, CsbFormControl } from '@src/components'
import { BaseResponseError } from '@src/errors/BaseResponseError'
import {
  CreateMailTemplateInput,
  UpdateMailTemplateInput,
  useTemplatesEditFormMailFromsQuery,
} from '@src/graphql/generated/graphql'
import { findError } from '@src/utils/findError'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types'

type UserEditFormPropsType = {
  errors: [BaseResponseError?]
  form: UseFormReturn<CreateMailTemplateInput | UpdateMailTemplateInput>
}

export const TemplateEditForm = ({ errors, form }: UserEditFormPropsType) => {
  //グループをすべて取得
  const { data: mailForm } = useTemplatesEditFormMailFromsQuery({
    onCompleted: ({ mailFroms }) => {
      if (!form.getValues('mailFromId')) {
        form.setValue('mailFromId', mailFroms.collection[0].uuid)
      }
    },
  })

  const [reRender, setReRender] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [reRender, form])

  return (
    <VStack align={'start'} as={'form'}>
      <Box w={'full'}>
        <VStack align={'start'} spacing={5}>
          <CsbFormControl
            errorText={findError(errors, 'name')?.message}
            helperText={'管理用の表示名です。メール送信には使用されません。'}
            labelText={'管理用ラベル'}
            w={605}
          >
            <Input
              placeholder={'人事評価に関するアンケート'}
              {...form.register('name')}
            />
          </CsbFormControl>
          <HStack maxW={605} spacing={2} w={'full'}>
            <CsbFormControl
              errorText={findError(errors, 'fromName')?.message}
              labelText={'差出人名'}
            >
              <Input {...form.register('fromName')} />
            </CsbFormControl>
            <CsbFormControl
              errorText={findError(errors, 'mailFromId')?.message}
              labelText={'送信元メールアドレス'}
            >
              <Select
                {...form.register('mailFromId')}
                defaultValue={form.getValues('mailFromId')}
              >
                {mailForm?.mailFroms.collection.map((item) => (
                  <option key={item.uuid} value={item.uuid}>
                    {item.email}
                  </option>
                ))}
              </Select>
            </CsbFormControl>
          </HStack>
        </VStack>
        <VStack align={'start'} mt={10} spacing={5}>
          <CsbFormControl
            errorText={findError(errors, 'subject')?.message}
            helperText={'メールの件名を記入'}
            labelText={'件名'}
            w={605}
          >
            <Input
              placeholder={'人事評価に関するアンケート'}
              {...form.register('subject')}
            />
          </CsbFormControl>
          <Flex align={'end'} gridGap={5} maxW={600} w={'full'}>
            <CsbFormControl
              errorText={findError(errors, 'phishingLinkLabel')?.message}
              labelText={'フィッシングリンクテキスト'}
            >
              <Input
                defaultValue={form.getValues('phishingLinkLabel')}
                {...form.register('phishingLinkLabel')}
              />
            </CsbFormControl>
            <Button
              flexShrink={0}
              onClick={() => {
                setReRender((prevState) => !prevState)
                form.setValue('body', form.getValues('body') + '{{link}}')
              }}
            >
              リンクを挿入
            </Button>
          </Flex>
          <CsbFormControl
            errorText={findError(errors, 'body')?.message}
            labelText={'本文'}
            maxW={800}
            w={'full'}
          >
            <CsbEditor
              data={form.getValues('body') || ''}
              onChangeHtml={(html) => {
                form.setValue('body', html)
              }}
            />
          </CsbFormControl>
          <CsbFormControl labelText={'学習コンテンツメールの配信'}>
            <Controller
              control={form.control}
              name={'isSendLearningMail'}
              render={({ field }) => (
                <Checkbox
                  defaultIsChecked
                  isChecked={form.getValues('isSendLearningMail')}
                  ref={field.ref}
                  onChange={field.onChange}
                >
                  学習コンテンツメールを配信をする
                </Checkbox>
              )}
            />
          </CsbFormControl>
          <CsbFormControl
            helperText={
              'メール本文のリンククリックでリスクのある行動と判定します'
            }
          >
            <Controller
              control={form.control}
              name={'isPhishingSiteDirectAccess'}
              render={({ field }) => (
                <Checkbox
                  isChecked={form.getValues('isPhishingSiteDirectAccess')}
                  ref={field.ref}
                  onChange={field.onChange}
                >
                  フィッシングサイトを経由しない
                </Checkbox>
              )}
            />
          </CsbFormControl>
        </VStack>
      </Box>
    </VStack>
  )
}
