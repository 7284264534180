import { ApolloError } from '@apollo/client'
import {
  DestroyDocumentInput as _DestroyDocumentInput,
  DestroyDocumentMutationResult,
  useDestroyDocumentMutation,
} from '@src/graphql/generated/graphql'
import { useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { useCallback } from 'react'

type DestroyDocumentInput = Omit<_DestroyDocumentInput, 'clientMutationId'>

export const useDestroyDocument = ({
  onCompleted,
  onError,
}: Partial<BaseOptionsType<DestroyDocumentMutationResult['data']>> = {}) => {
  const [destroyDocument, { loading: isLoading }] = useDestroyDocumentMutation({
    refetchQueries: ['documents'],
  })
  const toast = useToast()
  const onDestroyDocument = useCallback(
    async (uuid: DestroyDocumentInput['uuid']) => {
      try {
        const { data } = await destroyDocument({
          variables: {
            input: { uuid },
          },
        })
        toast({
          status: 'success',
          title: 'ドキュメントを削除しました',
        })
        onCompleted?.(data)
      } catch (e) {
        toast({
          status: 'error',
          title: 'ドキュメントの削除に失敗しました。',
        })
        if (e instanceof ApolloError) {
          onError?.(e)
        }
      }
    },
    [onError, toast, onCompleted, destroyDocument]
  )

  return { isLoading, onDestroyDocument }
}
