import { useResearchQuery } from '@src/graphql/generated/graphql'

export const useResearch = (
  baseOptions: Partial<Parameters<typeof useResearchQuery>[0]> = {}
) => {
  return useResearchQuery(baseOptions)
}

type useResearchType = typeof useResearch
export type useResearchResult = ReturnType<useResearchType>
// export type useResearchParameter = Parameters<useProjectType>[0]
