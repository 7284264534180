import { CompanyMailLimitKind } from '@src/graphql/generated/graphql'
import dayjs from 'dayjs'

const companyFormDefaultValues = {
  domain: '',
  name: '',
  testMailCount: 0,
}

export const companyNewFormDefaultValues = {
  ...companyFormDefaultValues,
  companyMailLimits: [
    {
      currentCount: 0,
      endDate: null,
      id: null,
      limitCount: null,
      limitType: CompanyMailLimitKind.PhishingOnly,
      startDate: dayjs().format('YYYY-MM-DD'),
    },
  ],
  users: [
    {
      birthdayOn: null,
      email: '',
      firstName: '',
      lastName: '',
    },
  ],
}

export const companyEditFormDefaultValues = companyFormDefaultValues
