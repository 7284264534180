import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react'

const toastBase: UseToastOptions = {
  duration: 3000,
  isClosable: true,
  position: 'top',
}

/**
 * useToastのwrapper
 */
export const useToast = () => {
  const toast = useChakraToast()

  return (options?: UseToastOptions) => toast({ ...toastBase, ...options })
}

export type useToastResult = ReturnType<typeof useToast>
