import {
  Avatar,
  Button,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react'
import { useMe } from '@src/hooks'
import { memo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const CsbAccountMenu = memo(() => {
  const navigate = useNavigate()
  const { user } = useMe()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [ips, setIps] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const logout = async () => {
    navigate('/sign_in')
  }

  const modalOpen = async () => {
    setLoading(true)
    await onOpen()
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/dns`)
      const json = await res.json()
      setIps(json)
    } catch (e) {
      setIps(['IPアドレスの取得に失敗しました'])
    }
    setLoading(false)
  }

  return (
    <Menu>
      <MenuButton as={Button} bg={'white'} size={'none'} variant="outline">
        <Stack
          alignItems="center"
          as="span"
          direction="row"
          pl={2.5}
          pr={4}
          py={2.5}
          spacing={3.5}
        >
          <Avatar
            name={
              user?.lastName &&
              user?.firstName &&
              `${user?.lastName} ${user?.firstName}`
            }
            size="sm"
            src={user?.avatarPath || ''}
          />
          <Text as="span" color="teal.500" fontSize="xs" fontWeight="bold">
            {user?.email}
          </Text>
        </Stack>
      </MenuButton>
      {/*メニューが複数増える場合はここのpyを消す*/}
      <MenuList py={0}>
        <MenuItem onClick={modalOpen}>メールサーバIP一覧</MenuItem>
        <MenuItem onClick={logout}>ログアウト</MenuItem>
      </MenuList>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>メールサーバIP一覧</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <>ロード中</>
            ) : (
              <UnorderedList>
                {ips.map((ip, index) => {
                  return <ListItem key={index}>{ip}</ListItem>
                })}
              </UnorderedList>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={onClose}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Menu>
  )
})
