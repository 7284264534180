import { useDashboardQuery } from '@src/graphql/generated/graphql'

export const useDashboard = (
  props: Partial<Parameters<typeof useDashboardQuery>[0]>
) => {
  return useDashboardQuery(props)
}

type useDashboardType = typeof useDashboard
export type useDashboardResult = ReturnType<useDashboardType>
export type useDashboardParameter = Parameters<useDashboardType>[0]
