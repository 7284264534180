import ChocPagination from '@choc-ui/paginator'
import { ComponentProps } from 'react'

type PaginationPropsType = ComponentProps<typeof ChocPagination>

export const CsbPagination = ({ ...props }: PaginationPropsType) => {
  return (
    <ChocPagination
      colorScheme={'teal'}
      pageNeighbours={3}
      paginationProps={{ display: 'flex', mb: 5 }}
      responsive={{ activePage: true }}
      {...props}
    />
  )
}
