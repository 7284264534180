/**
 * 文字列を数値に変換する関数
 */
export const valueAsNumber = (value: unknown) => {
  if (typeof value === 'string' && value !== '') {
    const parseValue = parseInt(value)
    return isNaN(parseValue) ? undefined : parseValue
  } else if (typeof value === 'number') {
    return value
  }
  return undefined
}

/**
 * 文字列を数値に変換する関数
 */
export const valueAsDate = (value: unknown) => {
  if (value instanceof Date) {
    return value
  }
  if (typeof value === 'string' && value !== '') {
    return new Date(value)
  }
  return undefined
}
