import { PhishingEditFormQuery } from '@src/graphql/generated/graphql'
import { GroupBase, Select } from 'chakra-react-select'

export type MailFromOption = {
  label: string
  value: string
}

type MailFromsArray = PhishingEditFormQuery['mailFroms']['collection']

export const createMailFromOptions = (
  mailFroms?: MailFromsArray
): MailFromOption[] =>
  mailFroms?.map((mailFrom) => ({
    label: mailFrom.email,
    value: mailFrom.uuid,
  })) ?? []

export type MailFromProps = {
  isDisabled?: boolean
  mailFrom: MailFromOption | null
  mailFromOptions: MailFromOption[]
  setMalFrom: (mailFrom: MailFromOption | null) => void
}

export const getMalFromEmail = (
  uuid: string,
  mailFromOptions: MailFromOption[]
) => {
  const targets = mailFromOptions.filter((item) => item.value === uuid)
  return targets.length > 0 ? targets[0].label : ''
}

export const CsbMailFromSelect = ({
  isDisabled,
  mailFrom,
  mailFromOptions,
  setMalFrom,
}: MailFromProps) => (
  <Select<MailFromOption, false, GroupBase<MailFromOption>>
    isClearable={true}
    isDisabled={isDisabled}
    noOptionsMessage={() => ''}
    options={mailFromOptions}
    placeholder={'選択してください'}
    useBasicStyles={true}
    value={mailFrom}
    onChange={(value) => setMalFrom(value)}
  />
)
