import { Box, Button, ButtonGroup, HStack, Spacer } from '@chakra-ui/react'
import { CodeEditor } from '@src/components/Editor/CodeEditor'
import { EditorPreview } from '@src/components/Editor/EditorPreview'
import { WysiwygEditor } from '@src/components/Editor/WysiwygEditor'
import { useToast } from '@src/hooks'
import React, { useEffect, useState } from 'react'

const EDITOR_TYPE_OPTIONS = [
  {
    label: 'WYSIWYG',
    value: 'wysiwyg',
  },
  {
    label: 'HTML',
    value: 'html',
  },
] as const

type EditorType = typeof EDITOR_TYPE_OPTIONS[number]['value']

export type BaseCsbEditorPropsType = {
  data?: string
  disabled?: boolean
  isHtmlLocked?: boolean
  onChangeHtml?: (htmlText: string) => void
  onHtmlLocked?: (locked: boolean) => void
}

type CsbEditorPropsType = {
  availableEditorTypes?: EditorType[]
} & BaseCsbEditorPropsType

export const CsbEditor = ({
  availableEditorTypes = ['wysiwyg', 'html'],
  ...editorProps
}: CsbEditorPropsType) => {
  const availableEditorTypeOptions = EDITOR_TYPE_OPTIONS.filter((option) =>
    availableEditorTypes.includes(option.value)
  )

  const [type, setType] = useState<EditorType | undefined>(
    availableEditorTypeOptions[0]?.value
  )

  const [wysiwygLocked, setWysiwygLocked] = useState<boolean>(false)
  const toast = useToast()

  const onChanged = () => {
    if (wysiwygLocked) {
      return
    }
    toast({
      status: 'info',
      title: 'HTMLを編集したため、WYSIWYGエディタをロックしました。',
    })
    setWysiwygLocked(true)
    if (editorProps.onHtmlLocked) {
      editorProps.onHtmlLocked(true)
    }
  }
  const handleUnlock = () => {
    setWysiwygLocked(false)
    if (editorProps.onHtmlLocked) {
      editorProps.onHtmlLocked(false)
    }
  }

  useEffect(() => {
    const isHtmlLocked = editorProps.isHtmlLocked ?? false
    setWysiwygLocked(isHtmlLocked)
    setType(isHtmlLocked ? 'html' : 'wysiwyg')
  }, [editorProps.isHtmlLocked])

  const wysiwygEditorProps = {
    ...editorProps,
    ...{ locked: wysiwygLocked },
  }

  const codeEditorProps = {
    ...editorProps,
    ...{ onChanged: onChanged },
  }

  return (
    <Box>
      <HStack>
        {availableEditorTypeOptions.length > 1 && (
          <ButtonGroup bg={'gray.100'} mb={2} p={1} rounded={'md'} spacing={1}>
            {availableEditorTypeOptions.map((option) => (
              <Button
                _hover={{ bg: undefined }}
                bg={type === option.value ? 'white' : 'transparent'}
                color={type === option.value ? 'gray.900' : 'gray.700'}
                colorScheme={'whiteAlpha'}
                key={option.value}
                size={'xs'}
                onClick={() => setType(option.value)}
              >
                {option.label}
              </Button>
            ))}
          </ButtonGroup>
        )}
        <Spacer />
        <EditorPreview
          html={editorProps.data ?? ''}
          trigger={
            <Button colorScheme={'gray'} size={'xs'}>
              プレビュー
            </Button>
          }
        />
      </HStack>

      <Box position={'relative'}>
        {type === 'wysiwyg' && (
          <>
            <WysiwygEditor {...wysiwygEditorProps} />
            {wysiwygLocked && (
              <Button
                bottom={8}
                position={'absolute'}
                right={8}
                onClick={handleUnlock}
              >
                ロック解除
              </Button>
            )}
          </>
        )}
        {type === 'html' && <CodeEditor {...codeEditorProps} />}
      </Box>
    </Box>
  )
}
