import {
  CompanyAttach,
  CompanyAttachFileKind,
  CreateCompanyAttachInput,
  UpdateCompanyAttachInput,
  useCompanyAttachesQuery,
  useCreateCompanyAttachMutation,
  useDestroyCompanyAttachMutation,
  useUpdateCompanyAttachMutation,
} from '@src/graphql/generated/graphql'
import { useDeleteModal, useFormModal, useMe } from '@src/hooks'
import { useFileUpload } from '@src/hooks/useFileUpload'
import { ChangeEvent, useRef, useState } from 'react'

type CompanyAttachInputType =
  | CreateCompanyAttachInput
  | Omit<UpdateCompanyAttachInput, 'uuid'>

type PickCompanyAttach = Pick<
  CompanyAttach,
  'uuid' | 'fileName' | 'fileKind' | 'path'
>

export const useCompanyAttachesHooks = () => {
  const { data, loading, refetch } = useCompanyAttachesQuery({
    variables: { fileKind: null },
  })

  const attachFileKinds: Array<[CompanyAttachFileKind, string]> = [
    [CompanyAttachFileKind.Xlsm, 'マクロ付きExcelファイル（*.xlsm）'],
    [CompanyAttachFileKind.Docm, 'マクロ付きWordファイル（*.docm）'],
    [CompanyAttachFileKind.Xlsx, '外部アクセスExcelファイル（*.xlsx）'],
    [CompanyAttachFileKind.Docx, '外部アクセスWordファイル（*.docx）'],
    [CompanyAttachFileKind.Ppsx, '外部アクセスPowerPointファイル（*.ppsx）'],
    [CompanyAttachFileKind.Pdf, '外部アクセスPDFファイル（*.pdf）'],
  ]

  const attachFileKindMap = new Map(attachFileKinds)

  const getAttachFileKindName = (fileKind: CompanyAttachFileKind) =>
    attachFileKindMap.get(fileKind) ?? ''

  const { role } = useMe()
  const isWriteCompanyAttach = !!role?.writeCompanyAttach
  const [destroyCompanyAttachMutation] = useDestroyCompanyAttachMutation()
  const [updateCompanyAttachMutation, { loading: isUpdating }] =
    useUpdateCompanyAttachMutation()
  const [createCompanyAttachMutation, { loading: isCreating }] =
    useCreateCompanyAttachMutation()

  const formModal = useFormModal<CompanyAttachInputType>({
    defaultValues: {
      fileKind: CompanyAttachFileKind.Xlsm,
      fileName: '',
      filePath: '',
    },
  })
  const deleteModal = useDeleteModal()

  const onEdit = ({ fileKind, fileName, path, uuid }: PickCompanyAttach) => {
    formModal.onOpen()
    formModal.form.setValue('fileName', fileName)
    formModal.form.setValue('fileKind', fileKind)
    formModal.form.setValue('filePath', path)
    formModal.setUuid(uuid)
    formModal.setState('update')
  }

  const onSubmit = async (inputData: CompanyAttachInputType) => {
    const getFileName = () => {
      const fileName = formModal.form.getValues('fileName')
        ? formModal.form.getValues('fileName')
        : file?.name ?? ''

      if (fileName !== '' && fileName.indexOf('.') === -1) {
        switch (formModal.form.getValues('fileKind')) {
          case CompanyAttachFileKind.Xlsm:
            return fileName + '.xlsm'
          case CompanyAttachFileKind.Docm:
            return fileName + '.docm'
          case CompanyAttachFileKind.Xlsx:
            return fileName + '.xlsx'
          case CompanyAttachFileKind.Docx:
            return fileName + '.docx'
          case CompanyAttachFileKind.Ppsx:
            return fileName + '.ppsx'
          default:
            return fileName + '.pdf'
        }
      }
      return fileName
    }

    const fileName = getFileName() ?? ''
    try {
      switch (formModal.state) {
        case 'create':
          await createCompanyAttachMutation({
            variables: {
              input: {
                ...inputData,
                fileName: fileName ?? '',
                filePath: await onFileUpload(),
              },
            },
          })
          break
        case 'update':
          await updateCompanyAttachMutation({
            variables: {
              input: {
                ...inputData,
                fileName: fileName ?? '',
                uuid: formModal.uuid,
              },
            },
          })
          break
        default:
          new Error(`${formModal.state} does not exist`)
      }

      formModal.toast({
        status: 'success',
        title:
          (fileName || '添付ファイル') +
          (formModal.isCreate ? 'を登録しました。' : 'を編集しました。'),
      })

      formModal.onCancel()
      setFile(null)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const errorMessage = (() => {
        if (
          e.graphQLErrors.length &&
          e.graphQLErrors[0].extensions.attribute === 'pdf'
        ) {
          return e.graphQLErrors[0].message
        }
        return (
          (inputData.fileName || '添付ファイル') +
          (formModal.isCreate
            ? 'の登録に失敗しました。'
            : 'の編集に失敗しました。')
        )
      })()

      formModal.setErrors(e.graphQLErrors)
      formModal.toast({
        status: 'error',
        title: errorMessage,
      })
    }
    await refetch()
  }

  const onDelete = async (uuid: string) => {
    await destroyCompanyAttachMutation({
      variables: {
        input: { uuid: uuid },
      },
    })
    await refetch()
  }

  const { loading: isUploading, onUpload } = useFileUpload('attaches')
  const [file, setFile] = useState<File | null>(null)
  const fileRef = useRef<HTMLInputElement | null>(null)

  const onClickFileUpload = () => {
    if (fileRef.current !== null) {
      fileRef.current.click()
    }
  }

  const onChangeFiles = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target.files[0] !== null) {
      const file = event.target.files[0]
      setFile(file)

      const isValidFileType = () => {
        switch (formModal.form.getValues('fileKind')) {
          case CompanyAttachFileKind.Xlsm:
            return file.name.endsWith('.xlsm')
          case CompanyAttachFileKind.Docm:
            return file.name.endsWith('.docm')
          case CompanyAttachFileKind.Xlsx:
            return file.name.endsWith('.xlsx')
          case CompanyAttachFileKind.Docx:
            return file.name.endsWith('.docx')
          case CompanyAttachFileKind.Ppsx:
            return file.name.endsWith('.ppsx')
          case CompanyAttachFileKind.Pdf:
            return file.name.endsWith('.pdf')
          default:
            return false
        }
      }

      if (!isValidFileType()) {
        window.alert('ファイルの形式が違います。')
        onFileClear()
      }
    }
  }

  const onFileClear = () => {
    if (fileRef.current !== null) {
      fileRef.current.value = ''
      setFile(null)
    }
  }
  const onFileUpload = async () => {
    if (file !== null) {
      return await onUpload(file)
    }
    return ''
  }

  return {
    attachFileKinds,
    data,
    deleteModal,
    file,
    fileRef,
    formModal,
    getAttachFileKindName,
    isCreating,
    isUpdating,
    isUploading,
    isWriteCompanyAttach,
    loading,
    onChangeFiles,
    onClickFileUpload,
    onDelete,
    onEdit,
    onFileClear,
    onSubmit,
    refetch,
  }
}

export type UseCompanyAttachesHooksResult = ReturnType<
  typeof useCompanyAttachesHooks
>
