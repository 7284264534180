import { Button, HStack } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import { useCsbDestroyModalHooksResult } from '@src/components/Modal/CsbDestroyModal/hooks'

export const CsbDestroyModal = ({
  header,
  onCancel,
  onDestroy,
  ...props
}: { onDestroy: () => void } & Omit<
  useCsbDestroyModalHooksResult,
  'data' | 'setData' | 'onDestroy'
>) => {
  return (
    <CsbModal {...props}>
      <CsbModalHeader>警告</CsbModalHeader>
      <CsbModalBody mt={10}>{header}を削除しますか？</CsbModalBody>
      <CsbModalFooter mt={7}>
        <HStack spacing={6}>
          <Button colorScheme={'gray'} onClick={onCancel}>
            キャンセル
          </Button>
          <Button colorScheme={'red'} onClick={onDestroy}>
            削除
          </Button>
        </HStack>
      </CsbModalFooter>
    </CsbModal>
  )
}
