import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { CsbDeleteButton, CsbPagination, CsbSearchInput } from '@src/components'
import { CsbCopyButton } from '@src/components/Buttons/CsbCopyButton'
import { CsbEditButton } from '@src/components/Buttons/CsbEditButton'
import { CsbTable } from '@src/components/CsbTable'
import { useResearchesHooks } from '@src/components/pages/researches/hooks'
import { Base } from '@src/layouts'
import {
  CreateResearchModal,
  DestroyResearchModal,
  ResearchesQuery,
} from '@src/models/Research'
import { CopyResearchModal } from '@src/models/Research/components/CopyResearchModal'
import { UpdateResearchModal } from '@src/models/Research/components/UpdateResearchModal/UpdateResearchModal'
import dayjs from 'dayjs'
import { memo } from 'react'

//フィシイング一覧ページのcomponent
export const Researches = memo(() => {
  const {
    copyResearchModal,
    createResearchModal,
    current,
    destroyResearchModal,
    isWriteResearch,
    navigate,
    onChangePage,
    onResetSearch,
    onSearch,
    researches,
    searchForm,
    updateResearchModal,
  } = useResearchesHooks()
  return (
    <Base
      left={<Heading>アンケート管理</Heading>}
      right={
        isWriteResearch && (
          <Button w={210} onClick={createResearchModal.onOpen}>
            アンケートの追加
          </Button>
        )
      }
    >
      <Box maxW={518} mt={12} w={'full'}>
        <CsbSearchInput
          placeholder={'アンケート名を入力'}
          {...searchForm.register('searchWord')}
          onReset={onResetSearch}
          onSearch={onSearch}
        />
      </Box>
      <ResearchesQuery {...researches}>
        {({ researches }) => (
          <>
            <CsbTable
              labels={['アンケート名', '説明', '有効期限', '']}
              wrapperProps={{ mt: 35 }}
            >
              {researches.collection.map((research) => (
                <CsbTable.TableRow
                  key={research.uuid}
                  onClick={() => navigate(`/researches/${research.uuid}/edit`)}
                >
                  <CsbTable.TableCell w={300}>
                    <Tooltip aria-label="A tooltip" label={research.name}>
                      <Text isTruncated fontWeight={'bold'} w={300}>
                        {research.name}
                      </Text>
                    </Tooltip>
                  </CsbTable.TableCell>
                  <CsbTable.TableCell w={300}>
                    <Tooltip
                      aria-label="A tooltip"
                      label={research.description}
                    >
                      <Text
                        isTruncated
                        color={'gray.500'}
                        fontSize={'sm'}
                        w={300}
                      >
                        {research.description}
                      </Text>
                    </Tooltip>
                  </CsbTable.TableCell>

                  <CsbTable.TableCell w={300}>
                    <Tooltip
                      aria-label="A tooltip"
                      label={research.description}
                    >
                      <Text isTruncated fontSize={'sm'} w={100}>
                        {research.closedAt
                          ? dayjs(research.closedAt).format('YYYY/MM/DD')
                          : '期限なし'}
                      </Text>
                    </Tooltip>
                  </CsbTable.TableCell>

                  <CsbTable.TableCell isNumeric>
                    {isWriteResearch && (
                      <Flex gridGap={5} justifyContent={'end'}>
                        <Tooltip label={'プレビュー'}>
                          <IconButton
                            aria-label="preview"
                            colorScheme={'blue'}
                            icon={<ExternalLinkIcon />}
                            rounded={'full'}
                            onClick={(e) => {
                              e.stopPropagation()
                              window.open(
                                `/preview/researches/${research.uuid}`
                              )
                            }}
                          />
                        </Tooltip>
                        <CsbCopyButton
                          onClick={(e) => {
                            e.stopPropagation()
                            copyResearchModal.onOpen(research)
                          }}
                        />
                        <CsbEditButton
                          onClick={(e) => {
                            e.stopPropagation()
                            updateResearchModal.onOpen(research)
                          }}
                        />
                        <CsbDeleteButton
                          onClick={(e) => {
                            e.stopPropagation()
                            destroyResearchModal.onOpen(research)
                          }}
                        />
                      </Flex>
                    )}
                  </CsbTable.TableCell>
                </CsbTable.TableRow>
              ))}
            </CsbTable>
            <Flex mt={5}>
              <CsbPagination
                current={current}
                defaultCurrent={current}
                pageSize={researches.metadata.limitValue}
                total={researches.metadata.totalCount}
                onChange={onChangePage()}
              />
              <HStack ml={'auto'} spacing={3}>
                {isWriteResearch && (
                  <Button w={210} onClick={createResearchModal.onOpen}>
                    アンケートの追加
                  </Button>
                )}
              </HStack>
            </Flex>
          </>
        )}
      </ResearchesQuery>
      <UpdateResearchModal {...updateResearchModal} />
      <CreateResearchModal {...createResearchModal} />
      <CopyResearchModal {...copyResearchModal} />
      <DestroyResearchModal {...destroyResearchModal} />
    </Base>
  )
})
