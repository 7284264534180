import { paginationInput } from '@src/constants'
import { useMe, usePagination } from '@src/hooks'
import { useCreatePdfModal } from '@src/models/Pdf/components/CreatePdfModal/useCreatePdfModal'
import { useDestroyPdfModal } from '@src/models/Pdf/components/DestroyPdfModal/useDestroyPdfModal'
import { useUpdatePdfModal } from '@src/models/Pdf/components/UpdatePdfModal/useUpdatePdfModal'
import { usePdfs } from '@src/models/Pdf/usePdfs'
import { useForm } from 'react-hook-form'

export const usePdfsHooks = () => {
  const { role } = useMe()
  //TODO バックエンドの実装に合わせて変える
  const isWritePdf = !!role?.writePdf
  //ページネーション
  const { current, onChangePage } = usePagination()
  const pdfs = usePdfs({
    variables: {
      pagination: paginationInput(current),
    },
  })
  const createPdfModal = useCreatePdfModal()
  const destroyPdfModal = useDestroyPdfModal()
  const updatePdfModal = useUpdatePdfModal()

  //search機能
  const searchForm = useForm<{ searchWord: string }>()
  const onSearch = searchForm.handleSubmit(async (inputData) => {
    try {
      await pdfs.refetch({
        pagination: {
          limit: 30,
          page: current,
        },
        searchWord: inputData.searchWord,
      })
    } catch (e) {
      console.log(e)
    }
  })
  const onResetSearch = () => {
    searchForm.resetField('searchWord')
    onSearch()
  }

  return {
    createPdfModal,
    current,
    destroyPdfModal,
    isWritePdf,
    onChangePage,
    onResetSearch,
    onSearch,
    pdfs,
    searchForm,
    updatePdfModal,
  }
}

export type UsePdfsHooksResult = ReturnType<typeof usePdfsHooks>
