import { Box, Checkbox, CheckboxProps, Text } from '@chakra-ui/react'
import React from 'react'

const fuseguBlue = '#3CA5E6'
const customBlue10 = 'rgba(60, 165, 230, 0.1)'
const customBlue50 = 'rgba(60, 165, 230, 0.5)'

export type CsbQuestionCheckboxProps = {
  children: React.ReactNode
  isChecked: boolean
} & CheckboxProps

// CustomCheckboxProps型のpropsを受け取るカスタムチェックボックスコンポーネント
export const CsbQuestionCheckbox = ({
  children,
  isChecked,
  ...props
}: CsbQuestionCheckboxProps) => {
  const boxBg = isChecked ? fuseguBlue : customBlue10
  const textColor = isChecked ? 'white' : 'black'

  return (
    <Box
      sx={{
        bg: boxBg,
        border: '2px solid',
        borderColor: customBlue50,
        minHeight: '80px', // 中央揃え
      }}
    >
      <Checkbox
        _checked={{
          bg: fuseguBlue,
          borderColor: 'white',
          color: 'white',
        }}
        colorScheme={'blue'}
        height={'100%'}
        p={2}
        width={'100%'}
        {...props} // 他のすべてのpropsを渡す
      >
        <Text color={textColor}>{children}</Text>
      </Checkbox>
    </Box>
  )
}
