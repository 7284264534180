import {
  Box,
  Divider,
  Flex,
  List,
  ListItem,
  Progress,
  Text,
} from '@chakra-ui/react'
import { CsbNewLineText } from '@src/components/CsbNewLineText'
import { usePhishingResultNewContentHooksResult } from '@src/components/pages/phishing/[id]/result/PhishingResultNewContent'
import {
  usePhishingResultQuestionnaireQuery,
  usePhishingResultQuestionTextQuery,
} from '@src/graphql/generated/graphql'
import { useEffect } from 'react'

export const Questionnaire = ({
  id,
  usePhishingResultContentsSelectResult,
}: usePhishingResultNewContentHooksResult) => {
  const { data, loading, refetch } = usePhishingResultQuestionnaireQuery({
    variables: {
      phishingUuid: id ?? '',
    },
  })
  const {
    data: textData,
    loading: textLoading,
    refetch: refetchText,
  } = usePhishingResultQuestionTextQuery({
    variables: {
      phishingUuid: id ?? '',
    },
  })

  useEffect(() => {
    refetch().then()
    refetchText().then()
  }, [
    refetch,
    refetchText,
    usePhishingResultContentsSelectResult.form.formState,
  ])

  if (loading && textLoading) {
    return <></>
  }

  return (
    <Box mb={'100px'}>
      <Text fontSize={13} mb={21}>
        アンケート結果
      </Text>
      {data?.phishingResultQuestionnaire.map((n, index) => {
        return (
          <Box key={index}>
            <Box>
              <Box mb={4} width={'600px'}>
                <Text fontWeight={'bold'}>
                  <CsbNewLineText text={n.name} />
                </Text>
              </Box>
              <Box pl={4}>
                {n.answers.map((answer, answerIndex) => {
                  return (
                    <Flex key={answerIndex} mb={2}>
                      <Text width={'300px'}>{answer.title}</Text>
                      <Progress
                        mr={30}
                        value={answer.rate * 100}
                        width={'300px'}
                      />
                      <Text>
                        {Math.round(answer.rate * 10000) / 100}% ({answer.count}
                        件)
                      </Text>
                    </Flex>
                  )
                })}
              </Box>
            </Box>
            <Divider my={'16px'} />
          </Box>
        )
      })}

      {textData?.phishingResultQuestionText.map((n, index) => {
        return (
          <Box key={index}>
            <Box>
              <Box mb={4} width={'600px'}>
                <Text fontWeight={'bold'}>
                  <CsbNewLineText text={n.name} />
                </Text>
              </Box>
              <List spacing={3} width={'600px'}>
                {n.answers.length > 0 ? (
                  n.answers.map((answer, answerIndex) => {
                    return (
                      <Box key={answerIndex}>
                        <ListItem pl={4}>
                          <CsbNewLineText text={answer} />
                        </ListItem>
                        <Divider my={'16px'} />
                      </Box>
                    )
                  })
                ) : (
                  <ListItem pl={4}>データがありません</ListItem>
                )}
              </List>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
