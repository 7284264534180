import { useToast } from '@chakra-ui/react'
import { useReactDndContainerHooks } from '@src/components/ReactDnd'
import {
  ComicImageInput,
  UpdateComicInput,
  useComicQuery,
  useUpdateComicMutation,
} from '@src/graphql/generated/graphql'
import { useErrors, useMe } from '@src/hooks'
import { useUpload } from '@src/hooks/useUpload'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

type uuidv4Result = ReturnType<typeof uuidv4>

//コミック編集で使うhooksのまとめ
export const useComicsEditHooks = () => {
  const { role } = useMe()
  const isWriteLearningComic = !!role?.writeLearningComic
  const navigate = useNavigate()
  const [updateComicMutation, { loading: isUpdating }] =
    useUpdateComicMutation()
  const toast = useToast()
  const [, setErrors] = useErrors()
  const form = useForm<Omit<UpdateComicInput, 'uuid'>>()
  const [images, setImages] = useState<
    (ComicImageInput & { id: uuidv4Result })[]
  >([])
  const { id } = useParams<{ id: string }>()

  const { data, loading } = useComicQuery({
    onCompleted: ({ comic }) => {
      form.setValue('name', comic.name)
      form.setValue('description', comic.description)
      form.setValue('images', comic.images)
      setImages(comic.images.map((n) => ({ ...n, ...{ id: uuidv4() } })))
    },
    variables: { uuid: id ?? '' },
  })

  const { accept, drop, findData, moveData } = useReactDndContainerHooks({
    accept: 'comics',
    reactDndItems: images,
    setReactDndItems: setImages,
  })

  const onUpdate = form.handleSubmit(async ({ images, ...inputData }) => {
    try {
      await updateComicMutation({
        variables: {
          input: {
            ...inputData,
            images: images.map(({ url, uuid }) => ({
              url,
              uuid,
            })),
            uuid: id ?? '',
          },
        },
      })
      setErrors([])
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'success',
        title: `${inputData.name || 'コミック'}を編集しました。`,
      })
      await navigate('/comics')
      /**
       * TODO anyを消す
       * e instanceOf ApolloError
       * setErrorsを変更する
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setErrors(e.graphQLErrors)
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: `${inputData.name || 'コミック'}の編集に失敗しました。`,
      })
    }
  })

  //画像をアップロードするhook
  const upload = useUpload({
    savePath: 'comic',
  })

  const dropzone = useDropzone({
    onDrop: upload.onUpload,
  })

  const onRemoveImage = useCallback(
    (targetId: uuidv4Result) => {
      setImages((prevState) => prevState.filter(({ id }) => id !== targetId))
    },
    [setImages]
  )

  //画像が上がった時の処理
  useEffect(() => {
    //画像のurlが発行されるまで早期Return
    if (upload.image === '') return
    //ローカルで保存している画像の値に追加
    setImages((prevState) => [
      ...prevState,
      { id: uuidv4(), url: upload.image },
    ])
  }, [upload.image])

  //imagesが変更されるたびにformのimagesの値を更新する
  useEffect(() => {
    form.setValue('images', images)
  }, [images, form])

  return {
    ReactDndItemProps: {
      accept,
      findData,
      moveData,
    },
    data,
    drop,
    dropzone,
    images,
    isUpdating,
    isWriteLearningComic,
    loading,
    navigate,
    onRemoveImage,
    onUpdate,
    upload,
  }
}

export type useComicsEditHooksResult = ReturnType<typeof useComicsEditHooks>
