import { paginationInput, ROLES } from '@src/constants'
import {
  CreateRoleInput,
  Role,
  UpdateRoleInput,
  useCreateRoleMutation,
  useDestroyRoleMutation,
  useRolesQuery,
  useUpdateRoleMutation,
} from '@src/graphql/generated/graphql'
import { useDeleteModal, useFormModal, useMe, usePagination } from '@src/hooks'
import { useForm } from 'react-hook-form'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T]
function ObjectEntries<T extends Record<string, unknown>>(t: T): Entries<T>[] {
  // TODO anyを消す
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.entries(t) as any
}

//formで管理するpositionの型
type RoleInputType = CreateRoleInput | Omit<UpdateRoleInput, 'uuid'>

//権限を追加するページで使うhooksのまとめ
export const useRolesHooks = () => {
  const { company, refetch: refetchMe, role: meRole, user } = useMe()
  const isSameCompany = user?.company.id === company?.id
  const roles = ROLES.filter(
    (role) => !role.admin || (meRole?.admin && isSameCompany)
  )
  const isWriteRole = !!meRole?.writeRole
  const deleteModal = useDeleteModal()
  const [destroyRoleMutation] = useDestroyRoleMutation()
  const [createRoleMutation, { loading: isCreating }] = useCreateRoleMutation()
  const [updateRoleMutation, { loading: isUpdating }] = useUpdateRoleMutation()
  const formModal = useFormModal<RoleInputType>({
    defaultValues: {
      name: '',
    },
  })

  const onChangeAllRoles = (isAllChecked: boolean) => {
    roles.forEach((role) => {
      ObjectEntries(role.role).forEach(([_, roleName]) => {
        formModal.form.setValue(roleName.name, isAllChecked)
      })
    })
  }

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    searchWord: withDefault(StringParam, ''),
  })
  //ページネーション
  const { current, onChangePage } = usePagination()

  const { data, refetch } = useRolesQuery({
    variables: {
      pagination: paginationInput(current),
      searchWord: query.searchWord,
    },
  })

  //編集するModalを開くボタン
  const onUpdate = ({
    name,
    uuid,
    ...others
  }: Omit<Role, 'companyId' | 'createdAt' | 'updatedAt' | 'admin'>) => {
    formModal.onOpen()
    formModal.form.setValue('name', name ?? '')
    roles.forEach((role) => {
      ObjectEntries(role.role).forEach(([_, roleName]) => {
        formModal.form.setValue(roleName.name, others[roleName.name])
      })
    })
    formModal.setUuid(uuid)
    formModal.setState('update')
  }

  //新規作成、編集のModalのformを送信する関数
  const onSubmit = async (inputData: RoleInputType) => {
    try {
      //typeを判定
      switch (formModal.state) {
        case 'create':
          //createの場合はcreateGroupにinputDataを渡す
          await createRoleMutation({
            variables: {
              input: inputData,
            },
          })
          break
        case 'update':
          //createの場合はupdateGroupMutationにinputDataとuuidを渡す
          await updateRoleMutation({
            variables: {
              input: {
                ...inputData,
                uuid: formModal.uuid,
              },
            },
          })
          break
        //一応エラーを書く
        default:
          new Error(`${formModal.state} does not exist`)
      }

      //新規作成、編集をすればToastを発行する
      formModal.toast({
        status: 'success',
        title: formModal.isCreate
          ? `${inputData.name || '権限'}を登録しました。`
          : `${inputData.name || '権限'}を編集しました。`,
      })
      formModal.setErrors([])
      formModal.onCancel()
      await refetchMe()
      /**
       * TODO anyを消す
       * e instanceOf ApolloError
       * setErrorsを変更する
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      formModal.setErrors(e.graphQLErrors)
      //新規作成、編集が失敗した場合のToast
      formModal.toast({
        status: 'error',
        title: formModal.isCreate
          ? `${inputData.name || '権限'}の登録に失敗しました。`
          : `${inputData.name || '権限'}の編集に失敗しました。`,
      })
    }
    await refetch()
  }

  //search機能
  const searchForm = useForm<{ searchWord: string }>({
    defaultValues: {
      searchWord: query.searchWord,
    },
  })
  const onSearch = searchForm.handleSubmit(async (inputData) => {
    setQuery({
      page: 1,
      searchWord: inputData.searchWord,
    })
  })
  const onResetSearch = () => {
    searchForm.setValue('searchWord', '')

    setQuery({
      page: 1,
      searchWord: '',
    })
  }

  return {
    current,
    data,
    deleteModal,
    destroyRoleMutation,
    formModal,
    isCreating,
    isUpdating,
    isWriteRole,
    onChangeAllRoles,
    onChangePage,
    onResetSearch,
    onSearch,
    onSubmit,
    onUpdate,
    refetch,
    roles,
    searchForm,
  }
}

export type useRolesHooksResult = ReturnType<typeof useRolesHooks>
