import {
  Box,
  Button,
  chakra,
  Checkbox,
  Flex,
  Grid,
  Heading,
  HStack,
  Input,
  TableContainer,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  CsbDeleteButton,
  CsbDeleteModal,
  CsbFormControl,
  CsbFormModal,
  CsbPagination,
  CsbSearchInput,
} from '@src/components'
import { CsbEditButton } from '@src/components/Buttons/CsbEditButton'
import { CsbTable } from '@src/components/CsbTable'
import { useRolesHooksResult } from '@src/components/pages/roles/hooks'
import { Base } from '@src/layouts'
import { findError } from '@src/utils/findError'
import { memo } from 'react'

type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T]
function ObjectEntries<T extends Record<string, unknown>>(t: T): Entries<T>[] {
  // TODO anyを消す
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.entries(t) as any
}

//権限一覧ページ
export const Roles = memo(
  ({
    current,
    data,
    deleteModal,
    destroyRoleMutation,
    formModal,
    isCreating,
    isUpdating,
    isWriteRole,
    onChangeAllRoles,
    onChangePage,
    onResetSearch,
    onSearch,
    onSubmit,
    onUpdate,
    refetch,
    roles,
    searchForm,
  }: useRolesHooksResult) => {
    return (
      <Base
        left={<Heading>権限管理</Heading>}
        right={
          isWriteRole && (
            <Button w={210} onClick={formModal.onCreate}>
              新しい権限の作成
            </Button>
          )
        }
      >
        <Box maxW={518} mt={12} w={'full'}>
          <CsbSearchInput
            placeholder={'権限を入力'}
            {...searchForm.register('searchWord')}
            onReset={onResetSearch}
            onSearch={onSearch}
          />
        </Box>
        <TableContainer mt={35}>
          <CsbTable labels={['権限', ...roles.map(({ name }) => name), '']}>
            {data?.roles.collection.map((item) => {
              return (
                <CsbTable.TableRow key={item.uuid}>
                  <CsbTable.TableCell w={300}>
                    <Tooltip aria-label="A tooltip" label={item.name}>
                      <Text isTruncated fontWeight={'bold'} w={300}>
                        {item.name}
                      </Text>
                    </Tooltip>
                  </CsbTable.TableCell>
                  {roles.map((role) => {
                    return (
                      <CsbTable.TableCell
                        key={role.name}
                        textAlign={'center'}
                        w={'12em'}
                      >
                        <VStack align={'start'}>
                          {ObjectEntries(role.role).map(([type, roleName]) => (
                            <Checkbox
                              isReadOnly
                              isChecked={item[roleName.name]}
                              key={roleName.name}
                              pointerEvents={'none'}
                            >
                              {type === 'read' ? 'read' : 'write'}
                            </Checkbox>
                          ))}
                        </VStack>
                      </CsbTable.TableCell>
                    )
                  })}
                  <CsbTable.TableCell isNumeric>
                    {!item?.admin && isWriteRole && (
                      <Flex gridGap={5} justifyContent={'end'}>
                        <CsbEditButton
                          onClick={(e) => {
                            e.stopPropagation()
                            onUpdate(item)
                          }}
                        />
                        {!item?.isCompanyAdmin && (
                          <CsbDeleteButton
                            onClick={(e) => {
                              e.stopPropagation()
                              deleteModal.onOpen(item.uuid, item.name)
                            }}
                          />
                        )}
                      </Flex>
                    )}
                  </CsbTable.TableCell>
                </CsbTable.TableRow>
              )
            })}
          </CsbTable>
        </TableContainer>
        <Flex mt={5}>
          {data ? (
            <CsbPagination
              current={current}
              defaultCurrent={current}
              pageSize={data.roles.metadata.limitValue}
              total={data.roles.metadata.totalCount}
              onChange={onChangePage('/roles')}
            />
          ) : null}
          <HStack ml={'auto'} spacing={3}>
            {isWriteRole && (
              <Button w={210} onClick={formModal.onCreate}>
                新しい権限の作成
              </Button>
            )}
          </HStack>
        </Flex>
        <CsbDeleteModal
          header={deleteModal.target}
          isOpen={deleteModal.isOpen}
          onCancel={deleteModal.onClose}
          onClose={deleteModal.onClose}
          onDelete={() =>
            deleteModal.onDelete(
              destroyRoleMutation({
                variables: {
                  input: { uuid: deleteModal.uuid ?? '' },
                },
              }),
              async () => {
                const { data } = await refetch()
                return {
                  metadata: {
                    currentPage: data.roles.metadata.currentPage,
                    limitValue: data.roles.metadata.limitValue,
                    totalCount: data.roles.metadata.totalCount,
                  },
                }
              },
              onChangePage('/roles')
            )
          }
        />
        <CsbFormModal
          header={
            formModal.isCreate
              ? '権限の作成'
              : `${formModal.form.getValues('name') || '権限'}の編集`
          }
          isLoading={formModal.isCreate ? isCreating : isUpdating}
          isOpen={formModal.isOpen}
          size={'lg'}
          submit={formModal.isCreate ? '作成' : '編集'}
          onCancel={formModal.onCancel}
          onClose={formModal.onCancel}
          onSubmit={formModal.form.handleSubmit(onSubmit)}
        >
          <CsbFormControl
            errorText={findError(formModal.errors, 'name')?.message}
          >
            <Input
              {...formModal.form.register('name')}
              placeholder={'権限の名前*'}
            />
          </CsbFormControl>
          <HStack justifyContent={'end'}>
            <chakra.button
              _hover={{ textDecoration: 'underline' }}
              color={'blue.500'}
              type={'button'}
              onClick={() => onChangeAllRoles(true)}
            >
              すべて選択
            </chakra.button>
            <chakra.button
              _hover={{ textDecoration: 'underline' }}
              color={'blue.500'}
              type={'button'}
              onClick={() => onChangeAllRoles(false)}
            >
              すべて解除
            </chakra.button>
          </HStack>
          <Grid gap={4} overflowY={'auto'}>
            {roles
              .filter((role) => !role.admin)
              .map((role) => {
                return (
                  <Grid
                    key={`modal_${role.name}`}
                    templateColumns={'repeat(2,1fr)'}
                  >
                    <Text>{role.name}</Text>
                    <HStack>
                      {ObjectEntries(role.role).map(([type, roleName]) => (
                        <CsbFormControl
                          errorText={
                            findError(formModal.errors, roleName.name)?.message
                          }
                          key={`modal_${roleName.name}`}
                        >
                          <Checkbox
                            defaultChecked={
                              !!formModal.form.getValues(roleName.name)
                            }
                            {...formModal.form.register(roleName.name)}
                            isChecked={!!formModal.form.watch(roleName.name)}
                            onChange={(e) => {
                              formModal.form.setValue(
                                roleName.name,
                                e.target.checked
                              )
                              const isWrite = type !== 'read'
                              if (!isWrite) return
                              formModal.form.setValue(
                                role.role.read.name,
                                e.target.checked
                              )
                            }}
                          >
                            {type === 'read' ? '表示' : '編集'}
                          </Checkbox>
                        </CsbFormControl>
                      ))}
                    </HStack>
                  </Grid>
                )
              })}
            {roles.some((role) => role.admin) ? (
              <Text fontSize={'sm'} fontWeight={'bold'} mt={10}>
                管理者メニュー
              </Text>
            ) : null}
            {roles
              .filter((role) => role.admin)
              .map((role) => {
                return (
                  <Grid
                    key={`modal_${role.name}`}
                    templateColumns={'repeat(2,1fr)'}
                  >
                    <Text>{role.name}</Text>
                    <HStack>
                      {ObjectEntries(role.role).map(([type, roleName]) => (
                        <CsbFormControl
                          errorText={
                            findError(formModal.errors, roleName.name)?.message
                          }
                          key={`modal_${roleName.name}`}
                        >
                          <Checkbox
                            defaultChecked={
                              !!formModal.form.getValues(roleName.name)
                            }
                            {...formModal.form.register(roleName.name)}
                            isChecked={!!formModal.form.watch(roleName.name)}
                            onChange={(e) => {
                              formModal.form.setValue(
                                roleName.name,
                                e.target.checked
                              )
                              const isWrite = type !== 'read'
                              if (!isWrite) return
                              formModal.form.setValue(
                                role.role.read.name,
                                e.target.checked
                              )
                            }}
                          >
                            {type === 'read' ? '表示' : '編集'}
                          </Checkbox>
                        </CsbFormControl>
                      ))}
                    </HStack>
                  </Grid>
                )
              })}
          </Grid>
        </CsbFormModal>
      </Base>
    )
  }
)
