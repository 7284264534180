import { useDisclosure } from '@chakra-ui/react'
import { paginationInput } from '@src/constants'
import { LocalStorage } from '@src/enums/localStorage'
import {
  useCompaniesQuery,
  useDestroyCompanyMutation,
  useMeLazyQuery,
  useSwitchCompanyMutation,
} from '@src/graphql/generated/graphql'
import {
  useDeleteModal,
  useLocalStorage,
  useMe,
  usePagination,
} from '@src/hooks'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

//企業一覧で使うhooksのまとめ
export const useCompaniesHooks = () => {
  const navigate = useNavigate()
  const { company, role, setMe } = useMe()
  const isWriteCompany = !!role?.writeCompany
  const [companyName, setCompanyName] = useState<string>('')
  const [, setAccessToken] = useLocalStorage<string>(
    LocalStorage.ACCESS_TOKEN,
    ''
  )
  const deleteModal = useDeleteModal()
  const switchCompanyModal = useDisclosure()
  const [getMe] = useMeLazyQuery({
    onCompleted: (data) => {
      setMe(data.me)
    },
  })

  const [destroyCompany] = useDestroyCompanyMutation()

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    searchWord: withDefault(StringParam, ''),
  })
  const { current, onChangePage } = usePagination()
  const { data, refetch } = useCompaniesQuery({
    variables: {
      pagination: paginationInput(current),
      searchWord: query.searchWord,
    },
  })
  const [switchCompany] = useSwitchCompanyMutation()

  const onSwitchCompany = async (uuid: string) => {
    const result = await switchCompany({
      variables: {
        input: {
          uuid: uuid,
        },
      },
    })
    getMe()
    setCompanyName(result.data?.switchCompany?.company.name as string)
    setAccessToken(result.data?.switchCompany?.token)
    switchCompanyModal.onOpen()
  }

  //search機能
  const searchForm = useForm<{ searchWord: string }>({
    defaultValues: {
      searchWord: query.searchWord,
    },
  })
  const onSearch = searchForm.handleSubmit(async (inputData) => {
    setQuery({
      page: 1,
      searchWord: inputData.searchWord,
    })
  })
  const onResetSearch = () => {
    searchForm.setValue('searchWord', '')

    setQuery({
      page: 1,
      searchWord: '',
    })
  }

  //TODO navigate,current,onChangePage,refetch,destroyCompanyをリファクタリング
  return {
    company,
    companyName,
    current,
    data,
    deleteModal,
    destroyCompany,
    isWriteCompany,
    navigate,
    onChangePage,
    onResetSearch,
    onSearch,
    onSwitchCompany,
    refetch,
    searchForm,
    switchCompanyModal,
  }
}

export type useCompaniesHooksResult = ReturnType<typeof useCompaniesHooks>
