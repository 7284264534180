import { useComicWithPreviewQuery } from '@src/graphql/generated/graphql'
import { useParams } from 'react-router-dom'

//学習で使うhooksのまとめ
export const usePreviewComicsHooks = () => {
  //queryからidを抽出して返す
  const { id } = useParams<{ id: string }>()
  const { data, loading } = useComicWithPreviewQuery({
    variables: {
      uuid: id ?? '',
    },
  })

  return {
    data,
    loading,
  }
}

export type usePreviewComicsHooksResult = ReturnType<
  typeof usePreviewComicsHooks
>
