import { useDisclosure } from '@chakra-ui/react'
import { useCreateAdminResearch } from '@src/models/AdminResearch'
import { useCallback } from 'react'

export const useCreateAdminResearchModal = () => {
  const { isOpen, onClose: _onClose, onOpen } = useDisclosure()
  const {
    findErrorMessage,
    form,
    isLoading,
    onCreateAdminResearch,
    resetForm,
  } = useCreateAdminResearch({
    onCompleted: () => {
      _onClose()
    },
  })
  const onClose = useCallback(() => {
    resetForm()
    _onClose()
  }, [_onClose, resetForm])

  return {
    findErrorMessage,
    form,
    isLoading,
    isOpen,
    onClose,
    onCreateAdminResearch,
    onOpen,
  }
}

type UseCreateAdminResearchModalType = typeof useCreateAdminResearchModal
export type UseCreateAdminResearchModalResult =
  ReturnType<UseCreateAdminResearchModalType>
// export type UseCreateAdminResearchModalParameter = Parameters<UseCreateAdminResearchModalType>[0]
