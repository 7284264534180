import {
  Button,
  Checkbox,
  CheckboxGroup,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Select,
  VStack,
} from '@chakra-ui/react'
import {
  MAPPING,
  usePhishingResultContentsSelectResult,
} from '@src/components/pages/phishing/[id]/result//components/PhishingResultContentsSelect/usePhishingResultContentsSelect'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import { Controller } from 'react-hook-form'

export const PhishingResultContentsSelect = ({
  filterSelectData,
  form,
  isDisabledCheckBox,
  loading,
  onChangeSelect,
}: usePhishingResultContentsSelectResult) => {
  const { isDeliveryContentsOnly } = useDeliveryContents()

  return (
    <HStack spacing={3}>
      <Controller
        control={form.control}
        name={'pattern'}
        render={({ field: { onChange, value } }) => (
          <Select
            isDisabled={loading}
            maxW={240}
            value={value}
            onChange={(e) => {
              onChange(Number(e.target.value))
              onChangeSelect()
            }}
          >
            {MAPPING.PATTERNS.filter(({ value }) =>
              !isDeliveryContentsOnly ? true : ![2, 3].includes(value)
            ).map(({ label, value }) => (
              <option key={`${label}`} value={value}>
                {label}
              </option>
            ))}
          </Select>
        )}
      />
      <Controller
        control={form.control}
        name={'filter'}
        render={({ field: { onChange, value } }) => (
          <Select
            isDisabled={loading}
            maxW={240}
            value={value}
            onChange={(e) => {
              if (Number(e.target.value) === 0) {
                form.setValue('filterUuids', [])
              } else {
                form.setValue(
                  'filterUuids',
                  filterSelectData(Number(e.target.value))
                    ?.slice(0, 10)
                    .map(({ uuid }) => uuid) ?? []
                )
              }
              onChange(Number(e.target.value))
              onChangeSelect()
            }}
          >
            {MAPPING.FILTER.map(({ label, title, value }) => (
              <option key={`${label}`} value={value}>
                {title}
              </option>
            ))}
          </Select>
        )}
      />
      {form.watch('filter') !== 0 && (
        <Menu isLazy>
          <MenuButton as={Button}>
            {
              MAPPING.FILTER.find(({ value }) => value === form.watch('filter'))
                ?.title
            }
          </MenuButton>
          <MenuList
            maxH={224}
            minWidth="240px"
            overflowY={'auto'}
            px={5}
            py={4}
          >
            <Controller
              control={form.control}
              name={'filterUuids'}
              render={({ field: { onChange, value } }) => (
                <CheckboxGroup
                  value={value}
                  onChange={(e) => {
                    onChange(e)
                    onChangeSelect()
                  }}
                >
                  <VStack
                    align={'start'}
                    fontWeight={'bold'}
                    lineHeight={1}
                    spacing={4}
                  >
                    {filterSelectData(form.getValues('filter'))?.map(
                      ({ name, uuid }) => (
                        <Checkbox
                          isDisabled={isDisabledCheckBox(uuid)}
                          key={uuid}
                          value={uuid}
                        >
                          {name}
                        </Checkbox>
                      )
                    )}
                  </VStack>
                </CheckboxGroup>
              )}
            />
          </MenuList>
        </Menu>
      )}
      <Controller
        control={form.control}
        name={'days'}
        render={({ field: { onChange, value } }) => (
          <Select
            isDisabled={loading}
            maxW={240}
            value={value}
            onChange={(e) => {
              onChange(parseInt(e.target.value) ?? 7)
              onChangeSelect()
            }}
          >
            <option value={'7'}>7日</option>
            <option value={'14'}>14日</option>
          </Select>
        )}
      />
    </HStack>
  )
}
