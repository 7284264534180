import { Mails } from '@src/components/pages/mails'
import { useMailsHooks } from '@src/components/pages/mails/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const MailsPage = memo(() => {
  const mailsHooks = useMailsHooks()
  return (
    <Authorization>
      <Mails {...mailsHooks} />
    </Authorization>
  )
})
