import { Gender } from '@src/graphql/generated/graphql'

type GenderType = '1' | '2' | '3' | string

export const genderToString = (gender: Gender | GenderType) => {
  switch (gender) {
    case Gender.Man:
    case '1':
      return '男性'
    case Gender.Woman:
    case '2':
      return '女性'
    case Gender.Other:
    case '3':
      return '未設定'
    default:
      return '未設定'
  }
}
