import { Companies } from '@src/components/pages/companies'
import { useCompaniesHooks } from '@src/components/pages/companies/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

//企業一覧ページ
export const CompaniesPage = memo(() => {
  const companiesHooks = useCompaniesHooks()
  return (
    <Authorization>
      <Companies {...companiesHooks} />
    </Authorization>
  )
})
