import { CsbPrimaryBox } from '@src/components'
import { ComponentProps, ReactNode } from 'react'

type ListPropsType = {
  children: ReactNode
} & ComponentProps<typeof CsbPrimaryBox>

export const CsbList = ({ children, ...props }: ListPropsType) => {
  return (
    <CsbPrimaryBox as={'ul'} px={'8px'} py={'5px'} rounded={'lg'} {...props}>
      {children}
    </CsbPrimaryBox>
  )
}
