import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import {
  CsbList,
  CsbListItem,
  CsbPagination,
  CsbUserAvatar,
} from '@src/components'

type User = {
  avatarPath?: string | null | undefined
  email: string
  firstName: string
  lastName: string
  uuid: string
}

type CollectionMetadata = {
  __typename?: 'CollectionMetadata'
  currentPage: number
  limitValue: number
  totalCount: number
  totalPages: number
}

type TargetUserListPropsType = {
  collectionMetaData?: CollectionMetadata
  maxH?: string
  mt?: string | number
  onPaging: (page: number) => void
  users: User[]
}

export const TargetUserList = ({
  collectionMetaData,
  maxH,
  onPaging,
  users,
}: TargetUserListPropsType) => {
  return (
    <Box mt={'38px'}>
      <Grid bg={'gray.50'} templateColumns={'2fr 3fr'}>
        <Text
          alignItems={'center'}
          display={'flex'}
          fontSize={'xs'}
          fontWeight={'bold'}
          px={6}
          py={3}
        >
          氏名
        </Text>
        <Text
          alignItems={'center'}
          display={'flex'}
          fontSize={'xs'}
          fontWeight={'bold'}
          px={6}
          py={3}
        >
          メールアドレス
        </Text>
      </Grid>
      <CsbList
        border={'none'}
        maxH={!maxH ? '300px' : maxH}
        overflowY={'auto'}
        p={0}
        rounded={'none'}
        shadow={'none'}
      >
        {users.map((user, index) => (
          <CsbListItem
            display={'grid'}
            gridTemplateColumns={'2fr 3fr'}
            key={`${user.uuid}_${index}`}
            p={0}
          >
            <Box px={6}>
              <CsbUserAvatar
                name={
                  user.lastName &&
                  user.firstName &&
                  `${user.lastName} ${user.firstName}`
                }
                src={user.avatarPath ?? ''}
              />
            </Box>
            <Text fontSize={'sm'} px={6}>
              {user.email}
            </Text>
          </CsbListItem>
        ))}
      </CsbList>
      <Flex mt={5}>
        {collectionMetaData && (
          <CsbPagination
            current={collectionMetaData.currentPage}
            defaultCurrent={collectionMetaData.currentPage}
            pageSize={collectionMetaData.limitValue}
            total={collectionMetaData.totalCount}
            onChange={(currentPage) => {
              onPaging(currentPage ?? 1)
            }}
          />
        )}
      </Flex>
    </Box>
  )
}
