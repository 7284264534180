import { paginationInput } from '@src/constants'
import { usePagination } from '@src/hooks'
import { useAttaches } from '@src/models/Attach/useAttaches'

export const useAttachesHooks = () => {
  //ページネーション
  const { current, onChangePage } = usePagination()
  const attaches = useAttaches({
    variables: {
      pagination: paginationInput(current),
    },
  })

  return {
    attaches,
    current,
    onChangePage,
  }
}

export type UseAttachesResult = ReturnType<typeof useAttachesHooks>
