import { ModalProps as _ModalProps } from '@chakra-ui/react'
import { CsbModal } from '@src/components'
import { useCallback, useState } from 'react'

type ModalProps = Omit<_ModalProps, 'isOpen' | 'onClose'> &
  Partial<Pick<_ModalProps, 'isOpen' | 'onClose'>>

export const useModal = () => {
  const [_isOpen, setOpen] = useState<boolean>(false)

  const _onOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const _onClose = useCallback(() => {
    setOpen(false)
  }, [])

  const ModalWrapper = useCallback(
    ({ children, isOpen, onClose, ...props }: ModalProps) => {
      return (
        <CsbModal
          {...props}
          isOpen={isOpen ? isOpen : _isOpen}
          onClose={onClose ? onClose : _onClose}
        >
          {children}
        </CsbModal>
      )
    },
    [_isOpen, _onClose]
  )

  return [
    ModalWrapper,
    {
      isOpen: _isOpen,
      onClose: _onClose,
      onOpen: _onOpen,
    },
  ] as const
}
