import {
  CreatePhishingEmailInput,
  MailTemplateModalQuery,
  UpdatePhishingEmailInput,
  useMailTemplateModalLazyQuery,
} from '@src/graphql/generated/graphql'
import { useCsbDataModal } from '@src/hooks/useCsbDataModal'
import { UseFormReturn } from 'react-hook-form'

type TemplateModalData = MailTemplateModalQuery['mailTemplates']['collection']

export const useTemplateModal = (
  form: UseFormReturn<CreatePhishingEmailInput | UpdatePhishingEmailInput>
) => {
  const dataModal = useCsbDataModal<TemplateModalData>()
  //templateを遅延したら読み込む関数
  const [getTemplates, { loading }] = useMailTemplateModalLazyQuery({
    //getTemplatesが実行されCompleteされた時に実行される関数
    onCompleted: ({ mailTemplates }) => {
      //dataにtemplateの配列を代入する
      dataModal.setData(mailTemplates.collection)
    },
  })

  const onReflect = (item: TemplateModalData[number]) => {
    // form.setValue('name', item.name)
    form.setValue('fromName', item.fromName ?? '')
    form.setValue('subject', item.subject)
    form.setValue('body', item.body ?? '')
    form.setValue('mailFromId', item.mailFrom.uuid)
    form.setValue('phishingLinkLabel', item.phishingLinkLabel)
    form.setValue('isSendLearningMail', !!item.isSendLearningMail)
    form.setValue(
      'isPhishingSiteDirectAccess',
      !!item.isPhishingSiteDirectAccess
    )
    dataModal.onClose()
  }

  const onOpen = () => dataModal.onOpen(getTemplates)

  const onCancel = () => dataModal.onClose()

  return {
    ...dataModal,
    loading,
    onCancel,
    onOpen,
    onReflect,
  }
}

export type useTemplateModalResult = ReturnType<typeof useTemplateModal>
