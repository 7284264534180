import { Button, HStack } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import { useDestroyQuestionnaireModalResult } from '@src/models/Questionnaire/components/DestroyQuestionnaireModal/useDestroyQuestionnaireModal'

export const DestroyQuestionnaireModal = ({
  isLoading,
  isOpen,
  onClose,
  onDestroyQuestionnaire,
  questionnaire,
}: useDestroyQuestionnaireModalResult) => (
  <CsbModal isOpen={isOpen} onClose={onClose}>
    <CsbModalHeader>警告</CsbModalHeader>
    <CsbModalBody mt={10}>{questionnaire?.name}を削除しますか？</CsbModalBody>
    <CsbModalFooter mt={7}>
      <HStack spacing={6}>
        <Button colorScheme={'gray'} onClick={onClose}>
          キャンセル
        </Button>
        <Button
          colorScheme={'red'}
          isLoading={isLoading}
          onClick={onDestroyQuestionnaire}
        >
          削除
        </Button>
      </HStack>
    </CsbModalFooter>
  </CsbModal>
)
