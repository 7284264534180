import { Button, HStack, Stack } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import {
  AdminResearchForm,
  UseCreateAdminResearchModalResult,
} from '@src/models/AdminResearch'

export const CreateAdminResearchModal = ({
  findErrorMessage,
  form,
  isLoading,
  isOpen,
  onClose,
  onCreateAdminResearch,
}: UseCreateAdminResearchModalResult) => (
  <CsbModal isOpen={isOpen} onClose={onClose}>
    <CsbModalHeader>共通アンケートを作成する</CsbModalHeader>
    <CsbModalBody mt={10}>
      <Stack spacing={6}>
        <AdminResearchForm findErrorMessage={findErrorMessage} form={form} />
      </Stack>
    </CsbModalBody>
    <CsbModalFooter mt={7}>
      <HStack spacing={6}>
        <Button colorScheme={'gray'} onClick={onClose}>
          キャンセル
        </Button>
        <Button isLoading={isLoading} onClick={onCreateAdminResearch}>
          作成
        </Button>
      </HStack>
    </CsbModalFooter>
  </CsbModal>
)
