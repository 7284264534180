import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react'
import { CsbPrimaryBox } from '@src/components'
import { CompanyEditForm } from '@src/components/Form/CompanyEditForm'
import { useCompaniesEditHooksResult } from '@src/components/pages/companies/[id]/edit/hooks'
import { Base } from '@src/layouts'
import { memo } from 'react'

//企業編集ページのcomponent
export const CompaniesEdit = memo(
  ({
    data,
    errors,
    form,
    isUpdating,
    isWriteCompany,
    loading,
    navigate,
    onSubmit,
  }: useCompaniesEditHooksResult) => {
    return (
      <Base
        left={<Heading>企業アカウントの編集</Heading>}
        right={
          <HStack spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧へ戻る
            </Button>
            {isWriteCompany && (
              <Button isLoading={isUpdating} onClick={onSubmit}>
                保存
              </Button>
            )}
          </HStack>
        }
      >
        <CsbPrimaryBox pb={'72px'} pt={35} px={45}>
          <VStack align={'start'} spacing={55}>
            {data && !loading ? (
              <Box as={'form'} w={'full'}>
                <Heading fontSize={24} mb={3}>
                  基本情報
                </Heading>
                <Divider />
                <CompanyEditForm errors={errors} form={form} />
              </Box>
            ) : null}
          </VStack>
        </CsbPrimaryBox>
        <Flex mt={5}>
          <HStack ml={'auto'} spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧へ戻る
            </Button>
            {isWriteCompany && (
              <Button isLoading={isUpdating} onClick={onSubmit}>
                保存
              </Button>
            )}
          </HStack>
        </Flex>
      </Base>
    )
  }
)
