import { Box, Button, Flex, Heading, HStack } from '@chakra-ui/react'
import { CsbPrimaryBox, CsbTemplateModal } from '@src/components'
import { PhishingEditForm } from '@src/components/Form/PhishingEditForm'
import { CsbUserModal } from '@src/components/Modal/CsbUserModal'
import { usePhishingNewHooksResult } from '@src/components/pages/phishing/new/hooks'
import { Base } from '@src/layouts'
import { memo } from 'react'

//フィシイング一覧ページのcomponent
export const PhishingNew = memo(
  ({
    errors,
    form,
    groups,
    isCompletedData,
    isCreating,
    isDeliveryContentsOnly,
    isDraftButton,
    isGroupsDataLoading,
    isNew,
    isOpen,
    isWritePhishingMail,
    onBackListPage,
    onClose,
    onSubmit,
    resolveDeliveryContentsUrl,
    templateModal,
    userData,
    uuid,
  }: usePhishingNewHooksResult) => {
    return (
      <Base
        left={
          <Heading>
            {isDeliveryContentsOnly ? 'メールコンテンツの作成' : 'メールの作成'}
          </Heading>
        }
        right={
          <HStack spacing={3}>
            <Button
              colorScheme={'gray'}
              onClick={() =>
                onBackListPage(resolveDeliveryContentsUrl('/phishing'))
              }
            >
              一覧に戻る
            </Button>
            {isWritePhishingMail && (
              <>
                {!isDeliveryContentsOnly && (
                  <Button colorScheme={'gray'} onClick={templateModal.onOpen}>
                    テンプレートから選ぶ
                  </Button>
                )}
                <Button
                  colorScheme={'blue'}
                  isLoading={isDraftButton && isCreating}
                  onClick={onSubmit(true)}
                >
                  保存
                </Button>
                <Button
                  isLoading={!isDraftButton && isCreating}
                  onClick={onSubmit(false)}
                >
                  保存して送信する
                </Button>
              </>
            )}
          </HStack>
        }
      >
        <Box mt={45}>
          {isCompletedData && (
            <CsbPrimaryBox px={10} py={'30px'}>
              <PhishingEditForm errors={errors} form={form} isNew={isNew} />
            </CsbPrimaryBox>
          )}
        </Box>
        <Flex mt={5}>
          <HStack ml={'auto'} spacing={3}>
            <Button
              colorScheme={'gray'}
              onClick={() =>
                onBackListPage(resolveDeliveryContentsUrl('/phishing'))
              }
            >
              一覧に戻る
            </Button>
            {isWritePhishingMail && (
              <>
                {!isDeliveryContentsOnly && (
                  <Button colorScheme={'gray'} onClick={templateModal.onOpen}>
                    テンプレートから選ぶ
                  </Button>
                )}
                <Button
                  colorScheme={'blue'}
                  isLoading={isDraftButton && isCreating}
                  w={100}
                  onClick={onSubmit(true)}
                >
                  保存
                </Button>
                <Button
                  isLoading={!isDraftButton && isCreating}
                  onClick={onSubmit(false)}
                >
                  保存して送信する
                </Button>
              </>
            )}
          </HStack>
        </Flex>
        <CsbUserModal
          data={userData}
          groups={groups}
          isGroupsDataLoading={isGroupsDataLoading}
          isLoading={isCreating}
          isOpen={isOpen}
          uuid={uuid}
          onClose={onClose}
        />
        <CsbTemplateModal {...templateModal} />
      </Base>
    )
  }
)
