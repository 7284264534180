import {
  ModalHeader as ChakraModalHeader,
  ModalHeaderProps,
} from '@chakra-ui/react'

type ModalHeaderPropsType = ModalHeaderProps

export const CsbModalHeader = ({
  children,
  ...props
}: ModalHeaderPropsType) => {
  return (
    <ChakraModalHeader px={0} py={0} {...props}>
      {children}
    </ChakraModalHeader>
  )
}
