import {
  useResearchWithPreviewQuery,
  useSettingsQuery,
} from '@src/graphql/generated/graphql'
import { useQueryParams } from '@src/hooks'
import { useParams } from 'react-router-dom'

//アンケートのプレビューで使うhooksのまとめ
export const usePreviewResearchHooks = () => {
  //queryからidを抽出して返す
  const { id } = useParams<{ id: string }>()
  const { data, loading } = useResearchWithPreviewQuery({
    variables: {
      uuid: id ?? '',
    },
  })
  const { isDisplayLogo } = useQueryParams()

  const { data: settingData, loading: isSettingLoading } = useSettingsQuery()
  const isDisplayLogoInResearch =
    isDisplayLogo === '' || isDisplayLogo === undefined
      ? settingData?.setting?.isDisplayLogoInResearch ?? true
      : isDisplayLogo === 'true'

  return {
    data,
    isDisplayLogoInResearch,
    loading: loading || isSettingLoading,
  }
}

export type usePreviewResearchHooksResult = ReturnType<
  typeof usePreviewResearchHooks
>
