import { ApolloError } from '@apollo/client'
import {
  UpdateAdminResearchMutationResult,
  UpdateAttachInput as _UpdateAttachInput,
  useUpdateAttachFormMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors, useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

type UpdateAttachInput = Omit<_UpdateAttachInput, 'clientMutationId'>

export const useUpdateAttachForm = ({
  defaultValues,
  onCompleted,
  onError,
}: Partial<
  BaseOptionsType<UpdateAdminResearchMutationResult['data']> & {
    defaultValues: UpdateAttachInput
  }
> = {}) => {
  const toast = useToast()
  const [updateAttach, { loading: isLoading }] = useUpdateAttachFormMutation({
    refetchQueries: ['attaches'],
  })
  const form = useForm<UpdateAttachInput>({
    defaultValues: {
      ...{
        fileName: '',
        name: '',
        uuid: '',
      },
      ...defaultValues,
    },
  })
  const { findErrorMessage, resetErrors, setErrors } =
    useGraphQLErrors<keyof UpdateAttachInput>()
  const resetForm = useCallback(() => {
    resetErrors()
    form.reset()
  }, [resetErrors, form])

  const onUpdateAttach = useCallback(
    async ({ fileName, name, uuid }: UpdateAttachInput) => {
      try {
        const { data } = await updateAttach({
          variables: {
            input: {
              fileName,
              name,
              uuid,
            },
          },
        })
        resetForm()
        onCompleted?.(data)
        toast({
          status: 'success',
          title: '添付ファイルの更新に成功しました。',
        })
      } catch (e) {
        toast({
          status: 'error',
          title: '添付ファイルの更新に失敗しました。',
        })
        if (e instanceof ApolloError) {
          setErrors(e.graphQLErrors)
          onError?.(e)
        }
      }
    },
    [onCompleted, onError, resetForm, setErrors, toast, updateAttach]
  )

  return { findErrorMessage, form, isLoading, onUpdateAttach, resetForm }
}

type UseUpdateAttachForm = typeof useUpdateAttachForm
export type UseUpdateAttachFormResult = ReturnType<UseUpdateAttachForm>
