import { usePhishingResultChartQuery } from '@src/graphql/generated/graphql'

type usePhishingResultChartProps = Parameters<
  typeof usePhishingResultChartQuery
>[0]

export const usePhishingResultChart = (
  props: Partial<usePhishingResultChartProps>
) => {
  return usePhishingResultChartQuery(props)
}

type usePhishingResultChartType = typeof usePhishingResultChart
export type usePhishingResultChartResult =
  ReturnType<usePhishingResultChartType>
// export type usePhishingResultChartParameter = Parameters<usePhishingResultChartType>[0]
