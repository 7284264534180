import { useDisclosure } from '@chakra-ui/react'
import { paginationInput } from '@src/constants'
import { LocalStorage } from '@src/enums/localStorage'
import {
  CampaignSearch,
  ExcelExportPhishingReportInput,
  useCampaignsQuery,
  useExcelExportPhishingReportMutation,
  useMeLazyQuery,
  useSwitchCompanyMutation,
} from '@src/graphql/generated/graphql'
import {
  useErrors,
  useLocalStorage,
  useMe,
  usePagination,
  useToast,
} from '@src/hooks'
import { useCsbDataModal } from '@src/hooks/useCsbDataModal'
import dayjs from 'dayjs'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

export const useCampaignsHooks = () => {
  const { setMe } = useMe()
  const navigate = useNavigate()
  const switchCompanyModal = useDisclosure()
  const [switchCompany] = useSwitchCompanyMutation()
  const [, setAccessToken] = useLocalStorage<string>(
    LocalStorage.ACCESS_TOKEN,
    ''
  )
  const [getMe] = useMeLazyQuery({
    onCompleted: (data) => {
      setMe(data.me)
    },
  })
  const onSwitchCompany = useCallback(
    async (uuid: string) => {
      const result = await switchCompany({
        variables: {
          input: {
            uuid: uuid,
          },
        },
      })
      getMe().then()
      setAccessToken(result.data?.switchCompany?.token)
    },
    [getMe, setAccessToken, switchCompany]
  )

  const today = dayjs().format('YYYY-MM-DD')
  const [query, setQuery] = useQueryParams({
    companyName: withDefault(StringParam, ''),
    from: withDefault(StringParam, today),
    page: withDefault(NumberParam, 1),
    searchType: withDefault(StringParam, CampaignSearch.All),
    to: withDefault(StringParam, ''),
  })

  const { current, onChangePage } = usePagination()
  const searchForm = useForm<{
    companyName: string
    from: string
    searchType: string
    to: string
  }>({
    defaultValues: {
      companyName: query.companyName,
      from: query.from,
      searchType: query.searchType,
      to: query.to,
    },
  })

  const onSearch = searchForm.handleSubmit(async (inputData) => {
    const from = inputData.from ? inputData.from : today
    searchForm.setValue('from', from)
    setQuery({
      companyName: inputData.companyName,
      from: from,
      page: 1,
      searchType: inputData.searchType,
      to: inputData.to,
    })
  })

  const onResetSearch = () => {
    searchForm.setValue('from', today)
    searchForm.setValue('to', '')
    searchForm.setValue('searchType', CampaignSearch.All)
    searchForm.setValue('companyName', '')

    setQuery({
      companyName: '',
      from: today,
      page: 1,
      searchType: CampaignSearch.All,
      to: '',
    })
  }

  const { data } = useCampaignsQuery({
    variables: {
      companyName: query.companyName,
      from: query.from,
      pagination: paginationInput(current),
      searchType: query.searchType as CampaignSearch,
      to: query.to ? query.to : null,
    },
  })

  const dataModal = useCsbDataModal<{
    items: string[]
    title: string
  }>()

  const [excelExportPhishingReportMutation, { loading: isExporting }] =
    useExcelExportPhishingReportMutation()

  const exportModal = useDisclosure()
  const exportForm = useForm<ExcelExportPhishingReportInput>({
    defaultValues: {
      from: '',
      searchType: CampaignSearch.PhishingOnly,
      to: '',
    },
  })
  const toast = useToast()

  const [errors, setErrors] = useErrors()
  const onExport = exportForm.handleSubmit(async (inputData) => {
    try {
      const data = {
        ...{ from: inputData.from, searchType: inputData.searchType },
        ...(inputData.to ? { to: inputData.to } : {}),
      }

      await excelExportPhishingReportMutation({
        variables: {
          input: data,
        },
      })

      setErrors([])
      toast({
        status: 'success',
        title: 'エクスポートに成功しました。',
      })
      exportModal.onClose()

      /**
       * TODO anyを消す
       * e instanceOf ApolloError
       * setErrorsを変更する
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setErrors(e.graphQLErrors)
      toast({
        status: 'error',
        title: 'エクスポートに失敗しました。',
      })
    }
  })

  return {
    current,
    data,
    dataModal,
    errors,
    exportForm,
    exportModal,
    isExporting,
    navigate,
    onChangePage,
    onExport,
    onResetSearch,
    onSearch,
    onSwitchCompany,
    searchForm,
    switchCompanyModal,
  }
}

export type useCampaignsHooksResult = ReturnType<typeof useCampaignsHooks>
