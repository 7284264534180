import { ApolloError } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import {
  CsvExportTargetUserInput,
  CsvExportTargetUserMutation,
  useCsvExportTargetUserMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

export const useCsvExportTargetUser = ({
  onCompleted,
  onError,
}: Partial<BaseOptionsType<CsvExportTargetUserMutation>> = {}) => {
  const { id } = useParams<{ id: string }>()
  const [csvExportTargetUserMutation, { loading: isExporting }] =
    useCsvExportTargetUserMutation()

  const form = useForm<CsvExportTargetUserInput>({
    defaultValues: {
      rows: 1000,
      uuid: id,
    },
  })
  const toast = useToast()
  const { resetErrors, setErrors } = useGraphQLErrors()

  const onExportTargetUsers = form.handleSubmit(async (inputData) => {
    try {
      await csvExportTargetUserMutation({
        variables: {
          input: inputData,
        },
      })
      resetErrors()
      onCompleted?.()
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'success',
        title: '対象ユーザーをエクスポートメールを送信しました。',
      })
    } catch (e) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: '対象ユーザーのエクスポートに失敗しました。',
      })
      if (e instanceof ApolloError) {
        setErrors(e.graphQLErrors)
        onError?.(e)
      }
    }
  })

  return { form, isExporting, onExportTargetUsers }
}

type useCsvExportTargetUserType = typeof useCsvExportTargetUser
export type useCsvExportTargetUserHooksResult =
  ReturnType<useCsvExportTargetUserType>
export type useCsvExportTargetUserHooksParameter =
  Parameters<useCsvExportTargetUserType>[0]
