import {
  UnlockUserInput,
  useUnlockUserMutation,
} from '@src/graphql/generated/graphql'
import { useErrors, useQueryParams } from '@src/hooks'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

// ロック解除Hooks
export const useUnlockUserHooks = () => {
  const [unlockUserMutation, { loading }] = useUnlockUserMutation()

  const [errors, setErrors] = useErrors()
  // const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { unlockToken } = useQueryParams()

  const form = useForm<UnlockUserInput>({
    defaultValues: {
      password: '',
      passwordConfirmation: '',
      unlockToken: unlockToken as string,
    },
  })

  const onSubmit = form.handleSubmit(async (inputData) => {
    try {
      await unlockUserMutation({
        variables: {
          input: {
            ...inputData,
          },
        },
      })

      setErrors([])
      navigate('/sign_out?unlocked=true')
      /**
       * TODO anyを消す
       * e instanceOf ApolloError
       * setErrorsを変更する
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setErrors(e.graphQLErrors)
    }
  })

  return {
    errors,
    form,
    loading,
    onSubmit,
  }
}

export type useUnlockUserHooksResult = ReturnType<typeof useUnlockUserHooks>
