import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from '@chakra-ui/react'
import {
  CsbFormControl,
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
  CsbNextLink,
  CsbPrimaryBox,
} from '@src/components'
import { ResendRevealingEmailModal } from '@src/components/Modal/ResendRevealingEmailModal/ResendRevealingEmailModal'
import { usePhishingResultNewHooksResult } from '@src/components/pages/phishing/[id]/result//hooks'
import { PhishingResultNewContent } from '@src/components/pages/phishing/[id]/result//PhishingResultNewContent'
import { RevealingTarget } from '@src/graphql/generated/graphql'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import { Base } from '@src/layouts'
import { memo } from 'react'

//フィシイング結果ページのcomponent
export const PhishingResultNew = memo(
  ({
    exportLearningUrlForm,
    exportLearningUrlModal,
    exportPhishingAccessLogForm,
    exportPhishingAccessLogModal,
    exportPhishingReplyEmlModal,
    exportSendMailLogForm,
    exportSendMailLogModal,
    exportWordPhishingResultModal,
    id,
    isArchived,
    isLearningUrlExporting,
    isLoading,
    isPhishingAccessLogsExporting,
    isPhishingReplyEmlExporting,
    isRevealingPhishingEmailScheduled,
    isSendMailLogsExporting,
    isWordExporting,
    isWritePhishingMail,
    onBackListPage,
    onExportDownload,
    onExportLearningUrl,
    onExportPhishingAccessLogs,
    onExportPhishingReplyEml,
    onExportSendMailLogs,
    onExportWord,
    phishingEmailQuery,
    resendRevealingEmailModal,
    revealingTarget,
    setRevealingTarget,
  }: usePhishingResultNewHooksResult) => {
    const { isDeliveryContentsOnly, resolveDeliveryContentsUrl } =
      useDeliveryContents()
    const title = !isDeliveryContentsOnly
      ? '種明かしメール'
      : 'リマインドメール'
    return (
      <Base
        left={
          <Heading>
            {!isDeliveryContentsOnly
              ? 'フィッシング結果'
              : 'コンテンツ配信結果'}
          </Heading>
        }
        right={
          <HStack spacing={3}>
            <Button
              colorScheme={'gray'}
              onClick={() =>
                onBackListPage(resolveDeliveryContentsUrl('/phishing'))
              }
            >
              一覧に戻る
            </Button>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                エクスポート
              </MenuButton>
              <MenuList>
                <MenuGroup title={'集計結果'}>
                  <MenuItem
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_BACKEND_URL}/export/report/detail.pdf?uuid=${id}`,
                        '_blank'
                      )
                    }}
                  >
                    集計レポート(PDF)
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      exportWordPhishingResultModal.onOpen()
                    }}
                  >
                    集計レポート(Word)
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_BACKEND_URL}/export/report.xlsx?uuid=${id}&m=1`,
                        '_blank'
                      )
                    }}
                  >
                    組織別
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_BACKEND_URL}/export/report.xlsx?uuid=${id}&m=2`,
                        '_blank'
                      )
                    }}
                  >
                    グループ別
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_BACKEND_URL}/export/report.xlsx?uuid=${id}&m=3`,
                        '_blank'
                      )
                    }}
                  >
                    役職別
                  </MenuItem>
                  <MenuItem onClick={onExportDownload}>ユーザー別</MenuItem>
                </MenuGroup>
                <MenuDivider />
                <MenuGroup title={'アンケート'}>
                  <MenuItem
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_BACKEND_URL}/export/mail_ach.xlsx?uuid=${id}`,
                        '_blank'
                      )
                    }}
                  >
                    メールアンケート
                  </MenuItem>
                  {/*<MenuItem*/}
                  {/*  onClick={() => {*/}
                  {/*    window.open(*/}
                  {/*      `${process.env.REACT_APP_BACKEND_URL}/export/common_ach.xlsx?uuid=${id}`,*/}
                  {/*      '_blank'*/}
                  {/*    )*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  共通アンケート*/}
                  {/*</MenuItem>*/}
                </MenuGroup>
                <MenuDivider />
                <MenuGroup title={'アクセスログ'}>
                  <MenuItem
                    onClick={() => {
                      phishingEmailQuery.refetch().then(() => {
                        exportPhishingAccessLogModal.onOpen()
                      })
                    }}
                  >
                    エクスポート
                  </MenuItem>
                </MenuGroup>
                <MenuDivider />
                <MenuGroup title={'メール送信ログ'}>
                  <MenuItem
                    onClick={() => {
                      phishingEmailQuery.refetch().then(() => {
                        exportSendMailLogModal.onOpen()
                      })
                    }}
                  >
                    エクスポート
                  </MenuItem>
                </MenuGroup>
                {(phishingEmailQuery.data?.phishingReplyCount ?? 0) > 0 && (
                  <>
                    <MenuDivider />
                    <MenuGroup title={'返信メール'}>
                      <MenuItem
                        onClick={() => {
                          exportPhishingReplyEmlModal.onOpen()
                        }}
                      >
                        エクスポート
                      </MenuItem>
                    </MenuGroup>
                  </>
                )}
                {!!phishingEmailQuery.data?.phishingEmail
                  .hasLearningContent && (
                  <>
                    <MenuDivider />
                    <MenuGroup title={'学習ページURL'}>
                      <MenuItem
                        onClick={() => {
                          phishingEmailQuery.refetch().then(() => {
                            exportLearningUrlModal.onOpen()
                          })
                        }}
                      >
                        エクスポート
                      </MenuItem>
                    </MenuGroup>
                  </>
                )}
              </MenuList>
            </Menu>
            {isWritePhishingMail && (
              <CsbNextLink
                href={resolveDeliveryContentsUrl(
                  `/phishing/${id}/result/send/revealing/schedule`
                )}
              >
                <Button>
                  {!isDeliveryContentsOnly ? '種明かし' : 'リマインド'}
                  メール設定
                </Button>
              </CsbNextLink>
            )}
            {!isArchived && isWritePhishingMail && (
              <Menu>
                <MenuButton
                  as={Button}
                  isLoading={phishingEmailQuery.loading}
                  rightIcon={<ChevronDownIcon />}
                >
                  {!isDeliveryContentsOnly
                    ? '種明かしメール再送'
                    : 'リマインドメール再送'}
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setRevealingTarget(RevealingTarget.All)
                      resendRevealingEmailModal.onOpen()
                    }}
                  >
                    全員に送信
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setRevealingTarget(RevealingTarget.Unanswered)
                      resendRevealingEmailModal.onOpen()
                    }}
                  >
                    未回答ユーザーへの送信
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setRevealingTarget(RevealingTarget.NoLearningAccess)
                      resendRevealingEmailModal.onOpen()
                    }}
                  >
                    ラーニング未アクセスユーザーへの送信
                  </MenuItem>
                  {!isDeliveryContentsOnly && (
                    <>
                      <MenuItem
                        onClick={() => {
                          setRevealingTarget(
                            RevealingTarget.NoLearningAccessAndRiskUser
                          )
                          resendRevealingEmailModal.onOpen()
                        }}
                      >
                        ラーニング未アクセスでリスクのあるユーザーへの送信
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setRevealingTarget(RevealingTarget.RiskUser)
                          resendRevealingEmailModal.onOpen()
                        }}
                      >
                        リスクのあるユーザーへの送信
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setRevealingTarget(
                            RevealingTarget.UnansweredAndRiskUser
                          )
                          resendRevealingEmailModal.onOpen()
                        }}
                      >
                        未回答でリスクのあるユーザーへの送信
                      </MenuItem>
                    </>
                  )}
                  <CsbNextLink
                    href={resolveDeliveryContentsUrl(
                      `/phishing/${id}/result/send/revealing`
                    )}
                  >
                    <MenuItem>ユーザーを指定して送信</MenuItem>
                  </CsbNextLink>
                </MenuList>
              </Menu>
            )}
            {isWritePhishingMail && (
              <CsbNextLink
                href={resolveDeliveryContentsUrl(`/phishing/new`, {
                  params: {
                    reuseId: id,
                  },
                })}
              >
                <Button as={'div'}>複製</Button>
              </CsbNextLink>
            )}
          </HStack>
        }
      >
        {!isRevealingPhishingEmailScheduled && (
          <Heading mb={8} size={'md'}>
            <Box border="2px solid" borderColor="red.400" padding="2">
              <Text color={'red.400'}>
                {title}の設定がおこなわれていません。
              </Text>
            </Box>
          </Heading>
        )}
        <CsbPrimaryBox mt={45} pb={'72px'} pt={35} px={45}>
          <PhishingResultNewContent />
        </CsbPrimaryBox>
        <Flex mt={5}>
          <HStack ml={'auto'} spacing={3}>
            <Button
              colorScheme={'gray'}
              onClick={() =>
                onBackListPage(resolveDeliveryContentsUrl('/phishing'))
              }
            >
              一覧に戻る
            </Button>
            <Button isLoading={isLoading} onClick={onExportDownload}>
              エクスポート
            </Button>
            {isWritePhishingMail && (
              <>
                <CsbNextLink
                  href={resolveDeliveryContentsUrl(
                    `/phishing/${id}/result/send/revealing/schedule`
                  )}
                >
                  <Button>
                    {!isDeliveryContentsOnly ? '種明かし' : 'リマインド'}
                    メール設定
                  </Button>
                </CsbNextLink>
                <CsbNextLink
                  href={resolveDeliveryContentsUrl(`/phishing/new`, {
                    params: {
                      reuseId: id,
                    },
                  })}
                >
                  <Button as={'div'}>複製</Button>
                </CsbNextLink>
              </>
            )}
          </HStack>
        </Flex>

        <ResendRevealingEmailModal
          disclosure={resendRevealingEmailModal}
          target={revealingTarget}
          uuid={id ?? ''}
        />

        <CsbModal
          isOpen={exportPhishingAccessLogModal.isOpen}
          onClose={exportPhishingAccessLogModal.onClose}
        >
          <CsbModalHeader>アクセスログのエクスポート</CsbModalHeader>
          <CsbModalBody mt={4}>
            <Alert status="warning">
              <AlertIcon />
              <Text fontSize={'sm'}>
                ログインしているユーザーのメールアドレスにダウンロードURLを記載したメールが送信されます。
              </Text>
            </Alert>
            <Stack mt={2} spacing={4}>
              <CsbFormControl>
                <Text>
                  全レコード件数:{' '}
                  {phishingEmailQuery.data?.phishingAccessLogsCount ?? 0}
                </Text>
              </CsbFormControl>

              <CsbFormControl labelText={'1ファイルに表示するレコード件数*'}>
                <Flex align={'center'} justify={'end'}>
                  <NumberInput
                    defaultValue={
                      exportPhishingAccessLogForm.getValues('rows') ?? 0
                    }
                    max={10000}
                    maxW={100}
                    min={0}
                  >
                    <NumberInputField
                      textAlign={'right'}
                      {...exportPhishingAccessLogForm.register('rows', {
                        valueAsNumber: true,
                      })}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text ml={2}>件</Text>
                </Flex>
              </CsbFormControl>
            </Stack>
          </CsbModalBody>
          <CsbModalFooter mt={7}>
            <HStack spacing={6}>
              <Button
                colorScheme={'gray'}
                onClick={exportPhishingAccessLogModal.onClose}
              >
                キャンセル
              </Button>
              <Button
                isLoading={isPhishingAccessLogsExporting}
                onClick={onExportPhishingAccessLogs}
              >
                エクスポート
              </Button>
            </HStack>
          </CsbModalFooter>
        </CsbModal>

        <CsbModal
          isOpen={exportSendMailLogModal.isOpen}
          onClose={exportSendMailLogModal.onClose}
        >
          <CsbModalHeader>メール送信ログのエクスポート</CsbModalHeader>
          <CsbModalBody mt={4}>
            <Alert status="warning">
              <AlertIcon />
              <Text fontSize={'sm'}>
                ログインしているユーザーのメールアドレスにダウンロードURLを記載したメールが送信されます。
              </Text>
            </Alert>
            <Stack mt={2} spacing={4}>
              <Text>
                全レコード件数:{' '}
                {phishingEmailQuery.data?.sendMailLogsCount ?? 0}
              </Text>

              <CsbFormControl labelText={'1ファイルに表示するレコード件数*'}>
                <Flex align={'center'} justify={'end'}>
                  <NumberInput
                    defaultValue={exportSendMailLogForm.getValues('rows') ?? 0}
                    max={10000}
                    maxW={100}
                    min={0}
                  >
                    <NumberInputField
                      textAlign={'right'}
                      {...exportSendMailLogForm.register('rows', {
                        valueAsNumber: true,
                      })}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text ml={2}>件</Text>
                </Flex>
              </CsbFormControl>
            </Stack>
          </CsbModalBody>
          <CsbModalFooter mt={7}>
            <HStack spacing={6}>
              <Button
                colorScheme={'gray'}
                onClick={exportSendMailLogModal.onClose}
              >
                キャンセル
              </Button>
              <Button
                isLoading={isSendMailLogsExporting}
                onClick={onExportSendMailLogs}
              >
                エクスポート
              </Button>
            </HStack>
          </CsbModalFooter>
        </CsbModal>
        <CsbModal
          isOpen={exportWordPhishingResultModal.isOpen}
          onClose={exportWordPhishingResultModal.onClose}
        >
          <CsbModalHeader>集計レポート（Word）エクスポート</CsbModalHeader>
          <CsbModalBody mt={10}>
            <Text fontSize={'sm'}>
              集計レポート（Word）がメールに添付されて送信されます。
            </Text>
          </CsbModalBody>
          <CsbModalFooter mt={7}>
            <HStack spacing={6}>
              <Button
                colorScheme={'gray'}
                onClick={exportWordPhishingResultModal.onClose}
              >
                キャンセル
              </Button>
              <Button isLoading={isWordExporting} onClick={onExportWord}>
                エクスポート
              </Button>
            </HStack>
          </CsbModalFooter>
        </CsbModal>
        <CsbModal
          isOpen={exportPhishingReplyEmlModal.isOpen}
          onClose={exportPhishingReplyEmlModal.onClose}
        >
          <CsbModalHeader>返信メールエクスポート</CsbModalHeader>
          <CsbModalBody mt={10}>
            <Text fontSize={'sm'}>
              返信メール（eml)がメールに添付されて送信されます。
            </Text>
          </CsbModalBody>
          <CsbModalFooter mt={7}>
            <HStack spacing={6}>
              <Button
                colorScheme={'gray'}
                onClick={exportPhishingReplyEmlModal.onClose}
              >
                キャンセル
              </Button>
              <Button
                isLoading={isPhishingReplyEmlExporting}
                onClick={onExportPhishingReplyEml}
              >
                エクスポート
              </Button>
            </HStack>
          </CsbModalFooter>
        </CsbModal>
        <CsbModal
          isOpen={exportLearningUrlModal.isOpen}
          onClose={exportLearningUrlModal.onClose}
        >
          <CsbModalHeader>学習ページURLのエクスポート</CsbModalHeader>
          <CsbModalBody mt={4}>
            <Alert status="warning">
              <AlertIcon />
              <Text fontSize={'sm'}>
                ログインしているユーザーのメールアドレスにダウンロードURLを記載したメールが送信されます。
              </Text>
            </Alert>
            <Stack mt={2} spacing={4}>
              <Text>
                全レコード件数:{' '}
                {phishingEmailQuery.data?.phishingTargetCount ?? 0}
              </Text>

              <CsbFormControl labelText={'1ファイルに表示するレコード件数*'}>
                <Flex align={'center'} justify={'end'}>
                  <NumberInput
                    defaultValue={exportLearningUrlForm.getValues('rows') ?? 0}
                    max={10000}
                    maxW={100}
                    min={0}
                  >
                    <NumberInputField
                      textAlign={'right'}
                      {...exportLearningUrlForm.register('rows', {
                        valueAsNumber: true,
                      })}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text ml={2}>件</Text>
                </Flex>
              </CsbFormControl>
            </Stack>
          </CsbModalBody>
          <CsbModalFooter mt={7}>
            <HStack spacing={6}>
              <Button
                colorScheme={'gray'}
                onClick={exportLearningUrlModal.onClose}
              >
                キャンセル
              </Button>
              <Button
                isLoading={isLearningUrlExporting}
                onClick={onExportLearningUrl}
              >
                エクスポート
              </Button>
            </HStack>
          </CsbModalFooter>
        </CsbModal>
      </Base>
    )
  }
)
