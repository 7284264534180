import { ApolloError } from '@apollo/client'
import {
  DestroyResearchInput,
  DestroyResearchMutationResult,
  useDestroyResearchMutation,
} from '@src/graphql/generated/graphql'
import { useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { useCallback } from 'react'

export const useDestroyResearch = ({
  onCompleted,
  onError,
}: Partial<BaseOptionsType<DestroyResearchMutationResult['data']>> = {}) => {
  const [destroyResearch, { loading: isLoading }] = useDestroyResearchMutation({
    refetchQueries: ['researches'],
  })
  const toast = useToast()
  const onDestroyResearch = useCallback(
    async (uuid: DestroyResearchInput['uuid']) => {
      try {
        const { data } = await destroyResearch({
          variables: {
            input: { uuid },
          },
        })
        toast({
          status: 'success',
          title: 'アンケートを削除しました',
        })
        onCompleted?.(data)
      } catch (e) {
        toast({
          status: 'error',
          title: 'アンケートの削除に失敗しました。',
        })
        if (e instanceof ApolloError) {
          onError?.(e)
        }
      }
    },
    [onError, toast, onCompleted, destroyResearch]
  )

  return { isLoading, onDestroyResearch }
}

type useDestroyResearchType = typeof useDestroyResearch
export type useDestroyResearchResult = ReturnType<useDestroyResearchType>
// export type useDestroyResearchParameter = Parameters<useDestroyResearchType>[0]
