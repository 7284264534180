import { useToast } from '@chakra-ui/react'
import { useCsbVimeo } from '@src/components/CsbVimeo/CsbVimeo'
import {
  LearningAnswerInput,
  useAdminAnswerResearchesQuery,
  useAnswerResearchesQuery,
  useLearnedMutation,
  useLearningAnswerMutation,
  useLearningQuery,
} from '@src/graphql/generated/graphql'
import { useErrors } from '@src/hooks'
import { useAdminResearchQuestions } from '@src/models/AdminResearch'
import { useLearningResult } from '@src/models/Learning'
import { useResearchQuestions } from '@src/models/Research'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

//学習で使うhooksのまとめ
export const useLearningHooks = () => {
  //queryからidを抽出して返す
  const { id = '' } = useParams<{ id: string }>()
  const toast = useToast()
  const navigate = useNavigate()
  const [isLearned, setIsLearned] = useState<boolean>(false)
  const csbVimeo = useCsbVimeo()
  const [errors, setErrors] = useErrors()

  //formの初期値のquestionsを管理するstate
  const [questions, setQuestions] = useState<
    Pick<LearningAnswerInput, 'answers'>
  >({
    answers: [],
  })

  //現ページのshortPathからlearnのdataを取得する
  const { data, loading } = useLearningQuery({
    onCompleted: ({ learning }) => {
      //問題が登録されていない時早期Return
      if (!learning.learn) return
      if (!learning.learn.questions.length) return

      setIsLearned(!!learning.isLearned)

      //dataから拾ってきた値を加工してformにわたす
      setQuestions({
        answers: learning.learn.questions.map(({ uuid }) => ({
          // correctUuid: corrects[0].uuid,
          correctUuid: '',
          questionUuid: uuid,
        })),
      })
    },
    variables: { shortPath: id },
  })

  const learningResult = useLearningResult({
    skip: !isLearned,
    variables: {
      shortPath: id,
    },
  })

  const researchQuestions = useResearchQuestions({
    variables: {
      shortPath: id,
    },
  })
  const adminResearchQuestions = useAdminResearchQuestions({
    variables: {
      shortPath: id,
    },
  })
  const answerResearches = useAnswerResearchesQuery({
    variables: {
      shortPath: id,
    },
  })
  const adminAnswerResearches = useAdminAnswerResearchesQuery({
    variables: {
      shortPath: id,
    },
  })

  /**
   * ユーザーが答えたデータを管理するform
   * @see https://github.com/react-hook-form/react-hook-form/issues/2492
   */
  const form = useForm<LearningAnswerInput>({
    defaultValues: useMemo(() => questions, [questions]),
  })

  //questionsとformに変更があるたびにformをリセットする
  useEffect(() => {
    form.reset(questions)
  }, [questions, form])

  const onNavigateThanksPage = (isHideAnswerResult = false) => {
    const params = isHideAnswerResult ? '?isHideAnswerResult=1' : ''
    navigate(`/learning/${id}/thanks${params}`, { replace: true })
  }

  const [learningAnswerMutation] = useLearningAnswerMutation()
  const [learnedMutation] = useLearnedMutation()

  const onSubmit = form.handleSubmit(async (inputData) => {
    try {
      await learningAnswerMutation({
        variables: {
          input: {
            ...inputData,
            shortPath: id,
          },
        },
      })
      onNavigateThanksPage()
      /* eslint-disable @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      setErrors(e.graphQLErrors)
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        // description: '問題名や回答が空欄の様です。問題名と回答を埋めてください',
        status: 'error',
        title: '回答に失敗しました。',
      })
    }
  })

  const onLearnComplete = async () => {
    try {
      await learnedMutation({
        variables: {
          input: {
            shortPath: id,
          },
        },
      })
      onNavigateThanksPage()
    } catch (e) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: '学習の完了に失敗しました。',
      })
    }
  }

  return {
    adminAnswerResearches,
    adminResearchQuestions,
    answerResearches,
    csbVimeo,
    data,
    errors,
    form,
    id,
    learningResult,
    loading,
    onLearnComplete,
    onNavigateThanksPage,
    onSubmit,
    researchQuestions,
  }
}

export type useLearningHooksResult = ReturnType<typeof useLearningHooks>
