import { useReactDndContainerHooksResult } from '@src/components/ReactDnd/useReactDndContainerHooks'
import { memo, ReactNode } from 'react'
import { useDrag, useDrop } from 'react-dnd'

/**
 * @see https://react-dnd.github.io/react-dnd/examples/sortable/simple
 * @see https://codesandbox.io/s/wonderful-panini-o3ozx?file=/src/index.tsx
 */
type ReactDndItemPropsType = {
  children: ReactNode
  id: string
} & Omit<useReactDndContainerHooksResult, 'drop'> &
  Omit<JSX.IntrinsicElements['div'], 'children'>

type ItemType = {
  id: string
  originalIndex: number
}

const useReactDndItemHooks = ({
  accept,
  findData,
  id,
  moveData,
}: Omit<ReactDndItemPropsType, 'children'>) => {
  const originalIndex = findData(id).index
  const [{ isDragging }, drag] = useDrag(
    () => ({
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          moveData(droppedId, originalIndex)
        }
      },
      item: { id, originalIndex },
      type: accept,
    }),
    [id, originalIndex, moveData]
  )

  const [, drop] = useDrop(
    () => ({
      accept,
      canDrop: () => false,
      hover: ({ id: draggedId }: ItemType) => {
        if (draggedId === id) return
        moveData(draggedId, findData(id).index)
      },
    }),
    [findData, moveData]
  )

  const dragDrop = (node: HTMLElement | null) => drag(drop(node))
  return { dragDrop, isDragging }
}

export const ReactDndItem = memo(
  ({
    accept,
    children,
    findData,
    id,
    moveData,
    ...props
  }: ReactDndItemPropsType) => {
    const { dragDrop, isDragging } = useReactDndItemHooks({
      accept,
      findData,
      id,
      moveData,
    })

    const opacity = isDragging ? 0.5 : 1
    return (
      <div ref={dragDrop} style={{ opacity }} {...props}>
        {children}
      </div>
    )
  }
)
