export const AGES = [
  {
    name: '10代',
  },
  {
    name: '20代',
  },
  {
    name: '30代',
  },
  {
    name: '40代',
  },
  {
    name: '50代',
  },
  {
    name: '60代',
  },
]
