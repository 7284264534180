import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Link,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { CsbDeleteButton, CsbPagination, CsbSearchInput } from '@src/components'
import { CsbEditButton } from '@src/components/Buttons/CsbEditButton'
import { CsbTable } from '@src/components/CsbTable'
import { usePdfsHooks } from '@src/components/pages/pdfs/hooks'
import { Base } from '@src/layouts'
import { CreatePdfModal } from '@src/models/Pdf/components/CreatePdfModal/CreatePdfModal'
import { DestroyPdfModal } from '@src/models/Pdf/components/DestroyPdfModal/DestroyPdfModal'
import { UpdatePdfModal } from '@src/models/Pdf/components/UpdatePdfModal/UpdatePdfModal'
import { PdfsQuery } from '@src/models/Pdf/PdfsQuery'
import { memo } from 'react'

//フィシイング一覧ページのcomponent
export const Pdfs = memo(() => {
  const {
    createPdfModal,
    current,
    destroyPdfModal,
    isWritePdf,
    onChangePage,
    onResetSearch,
    onSearch,
    pdfs,
    searchForm,
    updatePdfModal,
  } = usePdfsHooks()
  return (
    <Base
      left={<Heading>PDF管理</Heading>}
      right={
        isWritePdf && (
          <Button w={210} onClick={createPdfModal.onOpen}>
            PDFの作成
          </Button>
        )
      }
    >
      <Box maxW={518} mt={12} w={'full'}>
        <CsbSearchInput
          placeholder={'PDF名を入力'}
          {...searchForm.register('searchWord')}
          onReset={onResetSearch}
          onSearch={onSearch}
        />
      </Box>
      <PdfsQuery {...pdfs}>
        {({ pdfs }) => (
          <>
            <CsbTable labels={['PDF名', 'URL', '']} wrapperProps={{ mt: 35 }}>
              {pdfs.collection.map((pdf) => (
                <CsbTable.TableRow key={pdf.uuid}>
                  <CsbTable.TableCell w={300}>
                    <Tooltip aria-label="A tooltip" label={pdf.name}>
                      <Text isTruncated fontWeight={'bold'} w={300}>
                        {pdf.name}
                      </Text>
                    </Tooltip>
                  </CsbTable.TableCell>
                  <CsbTable.TableCell>
                    <Tooltip aria-label="A tooltip" label={pdf.url}>
                      <Text isTruncated color={'gray.500'} fontSize={'sm'}>
                        <Link isExternal href={pdf.url}>
                          {pdf.url}
                        </Link>
                      </Text>
                    </Tooltip>
                  </CsbTable.TableCell>
                  <CsbTable.TableCell isNumeric>
                    {isWritePdf && (
                      <Flex gridGap={5} justifyContent={'end'}>
                        <CsbEditButton
                          onClick={(e) => {
                            e.stopPropagation()
                            updatePdfModal.onOpen(pdf)
                          }}
                        />
                        <CsbDeleteButton
                          onClick={(e) => {
                            e.stopPropagation()
                            destroyPdfModal.onOpen(pdf)
                          }}
                        />
                      </Flex>
                    )}
                  </CsbTable.TableCell>
                </CsbTable.TableRow>
              ))}
            </CsbTable>
            <Flex mt={5}>
              <CsbPagination
                current={current}
                defaultCurrent={current}
                pageSize={pdfs.metadata.limitValue}
                total={pdfs.metadata.totalCount}
                onChange={onChangePage()}
              />
              <HStack ml={'auto'} spacing={3}>
                {isWritePdf && (
                  <Button w={210} onClick={createPdfModal.onOpen}>
                    PDFの作成
                  </Button>
                )}
              </HStack>
            </Flex>
          </>
        )}
      </PdfsQuery>
      <UpdatePdfModal {...updatePdfModal} />
      <CreatePdfModal {...createPdfModal} />
      <DestroyPdfModal {...destroyPdfModal} />
    </Base>
  )
})
