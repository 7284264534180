import { Button, HStack, Stack } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import { ResearchForm } from '@src/models/Research'
import { UseUpdateResearchModalResult } from '@src/models/Research/components/UpdateResearchModal/useUpdateResearchModal'

export const UpdateResearchModal = ({
  findErrorMessage,
  form,
  isLoading,
  isOpen,
  onClose,
  onUpdateResearch,
}: UseUpdateResearchModalResult) => (
  <CsbModal isOpen={isOpen} onClose={onClose}>
    <CsbModalHeader>アンケートを編集する</CsbModalHeader>
    <CsbModalBody mt={10}>
      <Stack spacing={6}>
        <ResearchForm findErrorMessage={findErrorMessage} form={form} />
      </Stack>
    </CsbModalBody>
    <CsbModalFooter mt={7}>
      <HStack spacing={6}>
        <Button colorScheme={'gray'} onClick={onClose}>
          キャンセル
        </Button>
        <Button isLoading={isLoading} onClick={onUpdateResearch}>
          編集
        </Button>
      </HStack>
    </CsbModalFooter>
  </CsbModal>
)
