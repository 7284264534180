import { ArrowDownIcon, ArrowUpIcon, DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  HStack,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Textarea,
} from '@chakra-ui/react'
import { CsbFormControl } from '@src/components'
import { ResearchAnswerType } from '@src/graphql/generated/graphql'
import { UseCreateResearchQuestionResult } from '@src/models/Research'
import { Controller } from 'react-hook-form'

const DIRECTION = {
  DOWN: 'down',
  UP: 'up',
} as const

type Direction = typeof DIRECTION[keyof typeof DIRECTION]

const moveAnswer = (answers: string[], src: number, direction: Direction) => {
  const dest = direction === DIRECTION.DOWN ? src + 1 : src - 1
  if (dest < 0 || dest >= answers.length) return answers

  const tmp = answers[src]
  answers[src] = answers[dest]
  answers[dest] = tmp

  return answers
}

export type OnFieldNameType = 'name' | 'isRequired' | 'answerType' | 'answers'
export type OnFieldValueType = boolean | string | string[]

// PickupするベースはUseCreateResearchQuestionResultにしないとエラーになったため、
// UseCreateResearchQuestionResultのみにしている。動作上は問題ないので、ひとまずこのままにしておく。
export const ResearchQuestionForm = ({
  findErrorMessage,
  form,
  onChange,
}: Pick<UseCreateResearchQuestionResult, 'form'> & {
  findErrorMessage: (name: OnFieldNameType) => string | undefined
  onChange?: (name: OnFieldNameType, value: OnFieldValueType) => void
}) => (
  <>
    <CsbFormControl errorText={findErrorMessage('name')} labelText={'質問'}>
      <Textarea
        {...form.register('name')}
        onChange={(e) => {
          form.setValue('name', e.target.value)
          if (onChange) onChange('name', form.getValues('name'))
        }}
      />
    </CsbFormControl>

    <CsbFormControl labelText={'必須'} mt={4}>
      <Controller
        control={form.control}
        name={'isRequired'}
        render={({ field }) => (
          <Checkbox
            defaultChecked
            isChecked={form.getValues('isRequired')}
            ref={field.ref}
            onChange={(e) => {
              field.onChange(e)
              if (onChange) onChange('isRequired', e.target.checked)
            }}
          >
            入力必須にする
          </Checkbox>
        )}
      />
    </CsbFormControl>

    <CsbFormControl
      errorText={findErrorMessage('answerType')}
      labelText={'回答方法'}
      mt={4}
    >
      <Controller
        control={form.control}
        name={'answerType'}
        render={({ field: { onChange: fieldOnChange, value } }) => (
          <RadioGroup
            value={`${value}`}
            onChange={(e) => {
              fieldOnChange(e)
              if (onChange) onChange('answerType', e)
            }}
          >
            <Flex gap={5}>
              <Radio value={ResearchAnswerType.Checkbox}>複数選択可</Radio>
              <Radio value={ResearchAnswerType.Radio}>単一選択</Radio>
              <Radio value={ResearchAnswerType.Textarea}>自由入力</Radio>
            </Flex>
          </RadioGroup>
        )}
      />
    </CsbFormControl>
    {[ResearchAnswerType.Checkbox, ResearchAnswerType.Radio].includes(
      form.watch().answerType
    ) ? (
      <Box mt={4}>
        {(form.getValues('answers') ?? []).map((_, index) => (
          <HStack alignItems={'center'} key={index} spacing={2} w={'full'}>
            <CsbFormControl
              errorText={findErrorMessage('answers')}
              key={index}
              labelText={`選択肢${index + 1}`}
            >
              <HStack spacing={2}>
                <Input
                  {...form.register(`answers.${index}`)}
                  onChange={(e) => {
                    form.setValue(`answers.${index}`, e.target.value)
                    if (onChange) onChange('answers', form.getValues('answers'))
                  }}
                />
                <ButtonGroup alignContent={'center'} spacing={1} width={110}>
                  <IconButton
                    aria-label="up-answer"
                    disabled={index === 0}
                    icon={<ArrowUpIcon />}
                    size={'sm'}
                    onClick={() => {
                      const answers = moveAnswer(
                        form.getValues('answers') ?? [],
                        index,
                        DIRECTION.UP
                      )
                      form.setValue('answers', answers)
                      if (onChange) onChange('answers', answers)
                    }}
                  />
                  <IconButton
                    aria-label="down-answer"
                    disabled={
                      index === (form.getValues('answers') ?? []).length - 1
                    }
                    icon={<ArrowDownIcon />}
                    size={'sm'}
                    onClick={() => {
                      const answers = moveAnswer(
                        form.getValues('answers') ?? [],
                        index,
                        DIRECTION.DOWN
                      )
                      form.setValue('answers', answers)
                      if (onChange) onChange('answers', answers)
                    }}
                  />
                  <IconButton
                    aria-label="delete-answer"
                    colorScheme={'red'}
                    disabled={(form.getValues('answers') ?? []).length <= 4}
                    icon={<DeleteIcon />}
                    size={'sm'}
                    onClick={() => {
                      const answers = form.getValues('answers') ?? []
                      const deletedAnswers = answers.filter(
                        (_, i) => i !== index
                      )
                      form.setValue('answers', deletedAnswers)
                      if (onChange) onChange('answers', deletedAnswers)
                    }}
                  />
                </ButtonGroup>
              </HStack>
            </CsbFormControl>
          </HStack>
        ))}
        <Flex mt={5}>
          <HStack ml={'auto'} spacing={3}>
            <Button
              onClick={() => {
                const answers = form.getValues('answers') ?? []
                form.setValue('answers', [...answers, ''])
                if (onChange) onChange('answers', form.getValues('answers'))
              }}
            >
              追加
            </Button>
          </HStack>
        </Flex>
      </Box>
    ) : (
      <></>
    )}
  </>
)
