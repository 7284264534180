import { Users } from '@src/components/pages/users'
import { useUsersHooks } from '@src/components/pages/users/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const UsersPage = memo(() => {
  const usersHooks = useUsersHooks()
  return (
    <Authorization>
      <Users {...usersHooks} />
    </Authorization>
  )
})
