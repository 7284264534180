import { forwardRef, IconButton, IconButtonProps } from '@chakra-ui/react'
import { RiMovieLine } from 'react-icons/all'
type CsbDeleteButtonPropsType = Omit<IconButtonProps, 'children' | 'aria-label'>

export const CsbPlayButton = forwardRef<CsbDeleteButtonPropsType, 'button'>(
  ({ ...props }, ref) => {
    return (
      <IconButton
        icon={<RiMovieLine />}
        ref={ref}
        rounded={'full'}
        {...props}
        aria-label="delete"
      />
    )
  }
)
