import { useDisclosure, useInterval } from '@chakra-ui/react'
import {
  SendTargetQuery,
  SendTargetSendType,
  useSendRevealingEmailMutation,
  useSendTargetLazyQuery,
  useSendTargetsQuery,
  useTargetsCountQuery,
} from '@src/graphql/generated/graphql'
import { useToast } from '@src/hooks'
import { useCsbDataModal } from '@src/hooks/useCsbDataModal'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import { useFileUpload } from '@src/hooks/useFileUpload'
import { useCsvExportTargetUser } from '@src/models/Phishing/CsvExportTarget/useCsvExportTargetUser'
import { ChangeEvent, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

//ユーザーインポートで使うhooksのまとめ
export const useSendRevealingHooks = () => {
  const { id } = useParams<{ id: string }>()
  const { isDeliveryContentsOnly } = useDeliveryContents()

  const { loading, onUpload } = useFileUpload('upload_csv/revealings', true)
  // noinspection DuplicatedCode
  const [csv, setCsv] = useState<File | null>(null)
  const fileRef = useRef<HTMLInputElement | null>(null)
  const navigate = useNavigate()
  const toast = useToast()

  const [registerCsvUrl, { loading: uploading }] =
    useSendRevealingEmailMutation()

  const { data: targets, refetch } = useSendTargetsQuery({
    variables: {
      sendType: SendTargetSendType.Revealing,
      uuid: id ?? '',
    },
  })

  const dataModal =
    useCsbDataModal<
      NonNullable<SendTargetQuery['sendTarget']>['sendTargetEmails']
    >()

  const [getSendTargets] = useSendTargetLazyQuery({
    onCompleted: ({ sendTarget }) => {
      dataModal.setData(sendTarget?.sendTargetEmails ?? [])
    },
  })

  const csvExportTargetModal = useDisclosure()

  const { form, isExporting, onExportTargetUsers } = useCsvExportTargetUser({
    onCompleted: () => {
      csvExportTargetModal.onClose()
    },
  })

  const { data: targetsCountData } = useTargetsCountQuery({
    variables: { uuid: id ?? '' },
  })
  const targetsCount = targetsCountData?.targetsCount ?? 0

  const sendRevealingModal = useDisclosure()

  useInterval(async () => {
    await refetch()
  }, 3000)

  // noinspection DuplicatedCode
  const onClickFileUpload = () => {
    if (fileRef.current !== null) {
      fileRef.current.click()
    }
  }

  const onChangeFiles = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target.files[0] !== null) {
      setCsv(event.target.files[0])
    }
  }

  const onFileClear = () => {
    if (fileRef.current !== null) {
      fileRef.current.value = ''
      setCsv(null)
    }
  }
  const onFileUpload = async () => {
    if (csv !== null) {
      sendRevealingModal.onClose()
      const uploadUrl = await onUpload(csv)
      try {
        await registerCsvUrl({
          variables: {
            input: {
              filePath: uploadUrl,
              override: false,
              uuid: id ?? '',
            },
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        const message =
          e.graphQLErrors.length > 0
            ? e.graphQLErrors[0].message
            : 'CSVのインポートに失敗しました。'
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'error',
          title: message,
        })
        // ファイルクリア
        onFileClear()
      }

      await refetch()
    }
  }

  return {
    csv,
    csvExportTargetModal,
    dataModal,
    fileRef,
    form,
    getSendTargets,
    isDeliveryContentsOnly,
    isExporting,
    loading,
    navigate,
    onChangeFiles,
    onClickFileUpload,
    onExportTargetUsers,
    onFileClear,
    onFileUpload,
    sendRevealingModal,
    targets,
    targetsCount,
    uploading,
  }
}

export type useSendRevealingHooksResult = ReturnType<
  typeof useSendRevealingHooks
>
