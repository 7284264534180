import { ApolloError } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import {
  CsvExportLearningUrlInput,
  CsvExportLearningUrlMutation,
  useCsvExportLearningUrlMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

export const useCsvExportLearningUrl = ({
  onCompleted,
  onError,
}: Partial<BaseOptionsType<CsvExportLearningUrlMutation>> = {}) => {
  const { id } = useParams<{ id: string }>()
  const [csvExportLearningUrlMutation, { loading: isExporting }] =
    useCsvExportLearningUrlMutation()
  const form = useForm<CsvExportLearningUrlInput>({
    defaultValues: {
      rows: 1000,
      uuid: id,
    },
  })
  const toast = useToast()
  const { resetErrors, setErrors } = useGraphQLErrors()

  const onExportLearningUrl = form.handleSubmit(async (inputData) => {
    try {
      await csvExportLearningUrlMutation({
        variables: {
          input: inputData,
        },
      })
      resetErrors()
      onCompleted?.()
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'success',
        title: '学習ページURLのエクスポート情報をメールに送信しました。',
      })
    } catch (e) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: '学習ページURLのエクスポートに失敗しました。',
      })
      if (e instanceof ApolloError) {
        setErrors(e.graphQLErrors)
        onError?.(e)
      }
    }
  })

  return { form, isExporting, onExportLearningUrl }
}

type useCsvExportLearningUrlType = typeof useCsvExportLearningUrl
export type useCsvExportLearningUrlHooksResult =
  ReturnType<useCsvExportLearningUrlType>
export type useCsvExportLearningUrlParameter =
  Parameters<useCsvExportLearningUrlType>[0]
