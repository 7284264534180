import {
  Alert,
  AlertIcon,
  Box,
  Button,
  CircularProgress,
  Divider,
  Flex,
  Heading,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
  VStack,
} from '@chakra-ui/react'
import {
  CsbFormControl,
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
  CsbTag,
} from '@src/components'
import { CsbTable } from '@src/components/CsbTable'
import { CsbDataModal } from '@src/components/Modal/CsbDataModal'
import { useSendRevealingHooksResult } from '@src/components/pages/phishing/[id]/result/send/revealing/hooks'
import { Base } from '@src/layouts'
import dayjs from 'dayjs'
import { memo } from 'react'

//種明かしメール送信用ページのcomponent
export const PhishingResultSendRevealing = memo(
  ({
    csv,
    csvExportTargetModal,
    dataModal,
    fileRef,
    form,
    getSendTargets,
    isDeliveryContentsOnly,
    isExporting,
    loading,
    navigate,
    onChangeFiles,
    onClickFileUpload,
    onExportTargetUsers,
    onFileClear,
    onFileUpload,
    sendRevealingModal,
    targets,
    targetsCount,
    uploading,
  }: useSendRevealingHooksResult) => {
    return (
      <Base
        left={
          <Heading>
            {!isDeliveryContentsOnly
              ? '種明かしメール送信'
              : 'リマインドメール送信'}
          </Heading>
        }
        right={
          <HStack spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              戻る
            </Button>
          </HStack>
        }
      >
        <Box
          alignItems={'start'}
          borderColor={'gray.200'}
          borderWidth={2}
          mb={5}
          p={3}
          rounded={10}
        >
          <input
            hidden
            multiple={false}
            ref={fileRef}
            type="file"
            onChange={onChangeFiles}
          />

          <>
            {csv !== null ? (
              <>
                <Table mb={3}>
                  <Tbody>
                    <Tr>
                      <Th w={200}>ファイル名</Th>
                      <Td>{csv.name}</Td>
                    </Tr>
                    <Tr>
                      <Th>ファイルタイプ</Th>
                      <Td>{csv.type}</Td>
                    </Tr>
                    <Tr>
                      <Th>ファイル容量</Th>
                      <Td>{csv.size}バイト</Td>
                    </Tr>
                  </Tbody>
                </Table>
                <HStack display={'flex'} justifyContent={'end'} spacing={3}>
                  <Button colorScheme={'gray'} onClick={onFileClear}>
                    クリア
                  </Button>
                  <Button
                    isLoading={loading || uploading}
                    onClick={sendRevealingModal.onOpen}
                  >
                    {!isDeliveryContentsOnly
                      ? '種明かしメールを送信する'
                      : 'リマインドメールを送信する'}
                  </Button>
                </HStack>
              </>
            ) : (
              <>
                <Box>
                  <Text as={'p'} mb={3}>
                    送信対象のCSVファイルを選択してください
                  </Text>
                </Box>
                <VStack align="stretch" spacing={3}>
                  <Box>
                    <Button onClick={onClickFileUpload}>
                      CSVファイルの選択
                    </Button>
                  </Box>
                  <Divider />
                  <Box>
                    <Button
                      colorScheme={'gray'}
                      onClick={csvExportTargetModal.onOpen}
                    >
                      送信対象CSVファイルのエクスポート
                    </Button>
                  </Box>
                </VStack>
              </>
            )}
          </>
        </Box>
        <TableContainer mt={35}>
          <CsbTable
            labels={[
              { label: 'ステータス' },
              { label: '送信開始日' },
              { isNumeric: true, label: '送信数' },
              { isNumeric: true, label: '成功数' },
              { label: '' },
            ]}
          >
            {targets?.sendTargets?.map((sendTarget, i) => (
              <CsbTable.TableRow key={i}>
                <CsbTable.TableCell w={300}>
                  {' '}
                  {sendTarget.completeAt === null ? (
                    <CircularProgress
                      isIndeterminate
                      color="teal"
                      size={'25px'}
                    />
                  ) : (
                    '送信完了'
                  )}
                </CsbTable.TableCell>
                <CsbTable.TableCell>
                  {dayjs(sendTarget.startAt).format('YYYY/MM/DD HH:mm')}
                </CsbTable.TableCell>
                <CsbTable.TableCell isNumeric w={200}>
                  {sendTarget.recordCount}
                </CsbTable.TableCell>
                <CsbTable.TableCell isNumeric w={200}>
                  {sendTarget.successCount}
                </CsbTable.TableCell>
                <CsbTable.TableCell isNumeric w={300}>
                  <Button
                    disabled={sendTarget.completeAt === null}
                    onClick={() =>
                      dataModal.onOpen(async () => {
                        await getSendTargets({
                          variables: {
                            uuid: sendTarget.uuid,
                          },
                        })
                      })
                    }
                  >
                    結果を確認する
                  </Button>
                </CsbTable.TableCell>
              </CsbTable.TableRow>
            ))}
          </CsbTable>
        </TableContainer>
        <Flex mt={5}>
          <Text
            color={'gray'}
            fontSize={'sm'}
            fontWeight={'bold'}
            textAlign={'right'}
          >
            最新の取り込み結果5件
          </Text>
          <HStack ml={'auto'} spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              戻る
            </Button>
          </HStack>
        </Flex>
        <CsbDataModal {...dataModal} size={'4xl'}>
          <Heading>送信結果</Heading>
          <TableContainer mt={35}>
            <CsbTable
              labels={[
                '結果',
                '失敗理由',
                '社員番号',
                '氏名',
                'メールアドレス',
                '役職',
                '組織',
                'グループ',
              ]}
              wrapperProps={{
                border: 'none',
                maxH: '300px',
                overflowY: 'auto',
              }}
            >
              {dataModal.data
                ? dataModal.data.map((item) => (
                    <CsbTable.TableRow key={item.targetUuid}>
                      <CsbTable.TableCell>
                        <CsbTag colorScheme={item.success ? 'teal' : 'red'}>
                          {item.success ? '成功' : '失敗'}
                        </CsbTag>
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        <Text color={'red'} fontSize={'sm'}>
                          {item.errorText}
                        </Text>
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {item.employeeNumber}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {item.lastName &&
                          item.firstName &&
                          `${item.lastName} ${item.firstName}`}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>{item.email}</CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {item.position && <CsbTag>{item.position}</CsbTag>}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        <HStack>
                          {item.organization
                            ?.split(',')
                            .map(
                              (organization, index) =>
                                organization && (
                                  <CsbTag
                                    key={`organization_${organization}_${index}`}
                                  >
                                    {organization}
                                  </CsbTag>
                                )
                            )}
                        </HStack>
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        <HStack>
                          {item.group
                            ?.split(',')
                            .map(
                              (group, index) =>
                                group && (
                                  <CsbTag key={`group_${group}_${index}`}>
                                    {group}
                                  </CsbTag>
                                )
                            )}
                        </HStack>
                      </CsbTable.TableCell>
                    </CsbTable.TableRow>
                  ))
                : 'loading...'}
            </CsbTable>
          </TableContainer>
        </CsbDataModal>

        <CsbModal
          isOpen={csvExportTargetModal.isOpen}
          onClose={csvExportTargetModal.onClose}
        >
          <CsbModalHeader>エクスポート</CsbModalHeader>
          <CsbModalBody mt={4}>
            <Alert status="warning">
              <AlertIcon />
              <Text fontSize={'sm'}>
                ログインしているユーザーのメールアドレスにダウンロードURLを記載したメールが送信されます。
              </Text>
            </Alert>
            <Stack mt={2} spacing={4}>
              <Text>全レコード件数: {targetsCount}</Text>
              <CsbFormControl labelText={'1ファイルに表示するレコード件数*'}>
                <Flex align={'center'} justify={'end'}>
                  <NumberInput
                    defaultValue={form.getValues('rows') ?? 0}
                    max={10000}
                    maxW={100}
                    min={0}
                  >
                    <NumberInputField
                      textAlign={'right'}
                      {...form.register('rows', {
                        valueAsNumber: true,
                      })}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text ml={2}>件</Text>
                </Flex>
              </CsbFormControl>
            </Stack>
          </CsbModalBody>
          <CsbModalFooter mt={7}>
            <HStack spacing={6}>
              <Button
                colorScheme={'gray'}
                onClick={csvExportTargetModal.onClose}
              >
                キャンセル
              </Button>
              <Button isLoading={isExporting} onClick={onExportTargetUsers}>
                エクスポート
              </Button>
            </HStack>
          </CsbModalFooter>
        </CsbModal>

        <CsbModal
          isOpen={sendRevealingModal.isOpen}
          onClose={sendRevealingModal.onClose}
        >
          <CsbModalHeader>警告</CsbModalHeader>
          <CsbModalBody mt={10}>
            {!isDeliveryContentsOnly
              ? '種明かしメールを送信しますか？'
              : 'リマインドメールを送信しますか？'}
          </CsbModalBody>
          <CsbModalFooter mt={7}>
            <HStack spacing={6}>
              <Button colorScheme={'gray'} onClick={sendRevealingModal.onClose}>
                キャンセル
              </Button>
              <Button onClick={onFileUpload}>送信</Button>
            </HStack>
          </CsbModalFooter>
        </CsbModal>
      </Base>
    )
  }
)
