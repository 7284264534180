import { AspectRatio, Box, Center, Heading, theme } from '@chakra-ui/react'
import { CsbSpinner } from '@src/components'
import { DataAggregation } from '@src/components/pages/phishing/[id]/result//components/DataAggregation/DataAggregation'
import { Detail } from '@src/components/pages/phishing/[id]/result//components/Detail/Detail'
import { LearningCorrectAnswerRate } from '@src/components/pages/phishing/[id]/result//components/LearningCorrectAnswerRate/LearningCorrectAnswerRate'
import { MailDetail } from '@src/components/pages/phishing/[id]/result//components/MailDetail/MailDetail'
import { PhishingResultContentsSelect } from '@src/components/pages/phishing/[id]/result//components/PhishingResultContentsSelect/PhishingResultContentsSelect'
import { usePhishingResultContentsSelect } from '@src/components/pages/phishing/[id]/result//components/PhishingResultContentsSelect/usePhishingResultContentsSelect'
import { RiskUsers } from '@src/components/pages/phishing/[id]/result//components/RiskUsers/RiskUsers'
import { useMailDetail } from '@src/components/pages/phishing/[id]/result/components/MailDetail/useMailDetail'
import { Questionnaire } from '@src/components/pages/phishing/[id]/result/components/Questionnaire/Questionnaire'
import { ReplyUsers } from '@src/components/pages/phishing/[id]/result/components/ReplyUsers/ReplyUsers'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import {
  PhishingResultChart,
  usePhishingResultChart,
} from '@src/models/Phishing/PhishingResultChart'
import { Line } from 'react-chartjs-2'
import { useParams } from 'react-router-dom'

const usePhishingResultNewContent = () => {
  const { id } = useParams<{ id: string }>()
  const usePhishingResultContentsSelectResult =
    usePhishingResultContentsSelect()

  return {
    id,
    usePhishingResultContentsSelectResult,
  }
}

export type usePhishingResultNewContentHooksResult = ReturnType<
  typeof usePhishingResultNewContent
>

export const PhishingResultNewContent = () => {
  const { id, usePhishingResultContentsSelectResult } =
    usePhishingResultNewContent()
  const { isDeliveryContentsOnly } = useDeliveryContents()

  const useMailDetailResult = useMailDetail()

  const form = usePhishingResultContentsSelectResult.form
  const usePhishingResultChartResult = usePhishingResultChart({
    variables: {
      days: form.watch('days'),
      filter: form.watch('filter'),
      filterUuids: form.watch('filterUuids'),
      pattern: form.watch('pattern'),
      uuid: id ?? '',
    },
  })

  const COLORS = [
    theme.colors.teal['500'],
    theme.colors.blue['500'],
    theme.colors.yellow['500'],
    theme.colors.orange['500'],
    theme.colors.cyan['500'],
    theme.colors.purple['500'],
    theme.colors.pink['500'],
  ]

  return (
    <>
      <PhishingResultContentsSelect
        {...usePhishingResultContentsSelectResult}
      />
      <Box mt={5}>
        <PhishingResultChart
          {...usePhishingResultChartResult}
          loadingFallback={
            <AspectRatio bg={'gray.50'} borderWidth={1} ratio={1188 / 316}>
              <Center>
                <CsbSpinner />
              </Center>
            </AspectRatio>
          }
          noDataFallback={
            <AspectRatio bg={'gray.50'} borderWidth={1} ratio={1188 / 316}>
              <Center>
                <Heading fontSize={'md'}>表示できるデータがありません</Heading>
              </Center>
            </AspectRatio>
          }
        >
          {({ phishingResultChart }) => (
            <Line
              data={{
                datasets: phishingResultChart.map((phishingResult, i) => {
                  const colorLength = COLORS.length
                  const index = i + colorLength
                  return {
                    backgroundColor: COLORS[index % colorLength],
                    borderColor: COLORS[index % colorLength],
                    data: phishingResult.datasets[0].data,
                    label: phishingResult.datasets[0].labels[0],
                  }
                }),
                labels: phishingResultChart[0].labels,
              }}
              fallbackContent={
                <AspectRatio bg={'gray.50'} borderWidth={1} ratio={1188 / 316}>
                  <Center>
                    <CsbSpinner />
                  </Center>
                </AspectRatio>
              }
              height={80}
            />
          )}
        </PhishingResultChart>
      </Box>
      <DataAggregation {...{ id, usePhishingResultContentsSelectResult }} />
      <LearningCorrectAnswerRate
        {...{ id, usePhishingResultContentsSelectResult }}
      />

      {id ? (
        <Questionnaire {...{ id, usePhishingResultContentsSelectResult }} />
      ) : (
        ''
      )}
      {isDeliveryContentsOnly ? (
        ''
      ) : (
        <RiskUsers {...{ id, usePhishingResultContentsSelectResult }} />
      )}
      {isDeliveryContentsOnly ? (
        ''
      ) : (
        <ReplyUsers {...{ id, usePhishingResultContentsSelectResult }} />
      )}
      <Detail {...{ id, usePhishingResultContentsSelectResult }} />
      <MailDetail {...useMailDetailResult} />
    </>
  )
}
