import { Roles } from '@src/components/pages/roles'
import { useRolesHooks } from '@src/components/pages/roles/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const RolesPage = memo(() => {
  const rolesHooks = useRolesHooks()
  return (
    <Authorization>
      <Roles {...rolesHooks} />
    </Authorization>
  )
})
