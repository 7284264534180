import { useToast } from '@chakra-ui/react'
import {
  UpdateMailTemplateInput,
  useMailTemplateQuery,
  useUpdateMailTemplateMutation,
} from '@src/graphql/generated/graphql'
import { useErrors, useMe } from '@src/hooks'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

//メールテンプレート更新で使うhooksのまとめ
export const useTemplatesEditHooks = () => {
  const { role } = useMe()
  const isWriteMailTemplate = !!role?.writeMailTemplate
  const navigate = useNavigate()
  const toast = useToast()
  const { id } = useParams<{ id: string }>()
  const [updateMailTemplateMutation, { loading: isUpdating }] =
    useUpdateMailTemplateMutation()
  const [errors, setErrors] = useErrors()
  const form = useForm<Omit<UpdateMailTemplateInput, 'uuid'>>()
  const { data, loading } = useMailTemplateQuery({
    onCompleted: ({ mailTemplate }) => {
      form.setValue('name', mailTemplate.name)
      form.setValue('fromName', mailTemplate.fromName ?? '')
      form.setValue('subject', mailTemplate.subject)
      form.setValue('body', mailTemplate.body ?? '')
      form.setValue('mailFromId', mailTemplate.mailFrom.uuid)
      form.setValue('phishingLinkLabel', mailTemplate.phishingLinkLabel)
      form.setValue('isSendLearningMail', !!mailTemplate.isSendLearningMail)
      form.setValue(
        'isPhishingSiteDirectAccess',
        !!mailTemplate.isPhishingSiteDirectAccess
      )
    },
    variables: { uuid: id ?? '' },
  })
  //保存と公開ボタンのloadingアニメーションを分岐するstate
  const [isDraftButton, setIsDraftButton] = useState(true)

  const onSubmit = (draft: boolean) =>
    form.handleSubmit(async (inputData) => {
      //保存ボタンか公開ボタンかどうか
      setIsDraftButton(draft)
      try {
        await updateMailTemplateMutation({
          variables: {
            input: {
              ...inputData,
              draft,
              uuid: id ?? '',
            },
          },
        })
        setErrors([])
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'success',
          title: `${inputData.name || 'テンプレート'}を${
            draft ? '下書き保存' : '編集し、公開'
          }しました。`,
        })
        await navigate('/templates')
        /**
         * TODO anyを消す
         * e instanceOf ApolloError
         * setErrorsを変更する
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'error',
          title: `${inputData.name || 'テンプレート'}の${
            draft ? '下書き保存' : '保存'
          }に失敗しました。`,
        })
        setErrors(e.graphQLErrors)
      }
    })
  return {
    data,
    errors,
    form,
    isDraftButton,
    isUpdating,
    isWriteMailTemplate,
    loading,
    navigate,
    onSubmit,
  }
}

export type useTemplatesEditHooksResult = ReturnType<
  typeof useTemplatesEditHooks
>
