import { PAGES } from '@src/constants'
import { Head } from '@src/layouts'
import { NotFoundPage } from '@src/pages'
import { Navigate, Route, Routes } from 'react-router-dom'

export const Router = () => {
  return (
    <Routes>
      <Route element={<Navigate replace to={PAGES.SignIn.path} />} path="/" />
      {Object.entries(PAGES).map(([, page]) => (
        <Route
          element={
            <>
              <Head title={page.title} />
              {page.children}
            </>
          }
          key={`page_${page.path}`}
          path={page.path}
        />
      ))}
      <Route element={<NotFoundPage />} path="*" />
    </Routes>
  )
}
