import { Dashboard } from '@src/components/pages/dashboard'
import { useDashboardHooks } from '@src/components/pages/dashboard/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const DashboardPage = memo(() => {
  const dashboardHooks = useDashboardHooks()
  return (
    <Authorization>
      <Dashboard {...dashboardHooks} />
    </Authorization>
  )
})
