import { Button, HStack } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import { UseDestroyAdminResearchModalResult } from '@src/models/AdminResearch'

export const DestroyAdminResearchModal = ({
  adminResearch,
  isLoading,
  isOpen,
  onClose,
  onDestroyAdminResearch,
}: UseDestroyAdminResearchModalResult) => (
  <CsbModal isOpen={isOpen} onClose={onClose}>
    <CsbModalHeader>警告</CsbModalHeader>
    <CsbModalBody mt={10}>{adminResearch?.name}を削除しますか？</CsbModalBody>
    <CsbModalFooter mt={7}>
      <HStack spacing={6}>
        <Button colorScheme={'gray'} onClick={onClose}>
          キャンセル
        </Button>
        <Button
          colorScheme={'red'}
          isLoading={isLoading}
          onClick={onDestroyAdminResearch}
        >
          削除
        </Button>
      </HStack>
    </CsbModalFooter>
  </CsbModal>
)
