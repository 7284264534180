import { ArrowDownIcon, ArrowUpIcon, DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import { CsbPrimaryBox } from '@src/components/CsbPrimaryBox'
import {
  useResearchesDetail,
  UseResearchesDetailResult,
} from '@src/components/pages/researches/[id]/edit/hooks'
import type {
  ResearchQuery as ResearchQueryType,
  ResearchQuestionInput,
  UpdateResearchQuestionInput,
} from '@src/graphql/generated/graphql'
import { Base } from '@src/layouts'
import {
  CreateResearchQuestionModal,
  ResearchQuery,
} from '@src/models/Research'
import { ResearchQuestionForm } from '@src/models/Research/components/ResearchQuestionForm'
import {
  OnFieldNameType,
  OnFieldValueType,
} from '@src/models/Research/components/ResearchQuestionForm/ResearchQuestionForm'
import {
  useUpdateResearchQuestions,
  UseUpdateResearchQuestionsResult,
} from '@src/models/Research/useUpdateResearchQuestions'
import { findError } from '@src/utils/findError'
import { memo, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

type UpdateResearchQuestionProps =
  ResearchQueryType['research']['researchQuestions'][number]

const UpdateResearchQuestion = ({
  answerType,
  isRequired,
  isWriteResearch,
  name,
  onChangeResearchQuestions,
  questionCount,
  registerAnswers,
  seqNo,
  updateResearchQuestions,
  uuid,
}: UpdateResearchQuestionProps &
  Pick<UseResearchesDetailResult, 'isWriteResearch'> & {
    onChangeResearchQuestions: (
      researchQuestions: ResearchQuestionInput[]
    ) => void
    questionCount: number
    seqNo: number
    updateResearchQuestions: UseUpdateResearchQuestionsResult
  }) => {
  const form =
    useForm<Omit<UpdateResearchQuestionInput, 'clientMutationId' | 'uuid'>>()
  const index = seqNo - 1

  useEffect(() => {
    form.setValue('answerType', answerType)
    form.setValue('name', name)
    form.setValue('isRequired', isRequired)
    form.setValue('answers', registerAnswers)

    updateResearchQuestions.form.setValue(`questions.${index}.uuid`, uuid)
    updateResearchQuestions.form.setValue(
      `questions.${index}.answerType`,
      answerType
    )
    updateResearchQuestions.form.setValue(`questions.${index}.name`, name)
    updateResearchQuestions.form.setValue(
      `questions.${index}.isRequired`,
      isRequired
    )
    updateResearchQuestions.form.setValue(
      `questions.${index}.answers`,
      registerAnswers
    )
  }, [
    uuid,
    answerType,
    name,
    isRequired,
    registerAnswers,
    form,
    updateResearchQuestions.form,
    index,
  ])

  const onChangeQuestions = (
    name: OnFieldNameType,
    value: OnFieldValueType
  ) => {
    updateResearchQuestions.form.setValue(`questions.${index}.${name}`, value)
  }

  const findErrorMessage = useCallback(
    (attributeName: OnFieldNameType) => {
      const error = findError(
        updateResearchQuestions.errors,
        `${attributeName}${index}`
      )
      return error?.message
    },
    [index, updateResearchQuestions.errors]
  )

  const move = (direction: 'up' | 'down') => {
    const dest = direction === 'down' ? index + 1 : index - 1
    if (dest < 0 || dest >= questionCount) return

    const questions = updateResearchQuestions.form.getValues('questions')
    const tmp = questions[index]
    questions[index] = questions[dest]
    questions[dest] = tmp
    updateResearchQuestions.form.setValue(`questions`, questions)
    onChangeResearchQuestions(questions)
  }

  return (
    <CsbPrimaryBox mt={3} position={'relative'} px={5} py={5}>
      <ResearchQuestionForm
        findErrorMessage={findErrorMessage}
        form={form}
        onChange={onChangeQuestions}
      />
      {isWriteResearch && (
        <ButtonGroup alignContent={'center'} spacing={1}>
          <IconButton
            aria-label="up"
            disabled={seqNo === 1}
            icon={<ArrowUpIcon />}
            isLoading={updateResearchQuestions.isLoading}
            position={'absolute'}
            right={0}
            top={0}
            transform={'translate(-160%,-50%)'}
            onClick={() => move('up')}
          />

          <IconButton
            aria-label="down"
            disabled={seqNo === questionCount}
            icon={<ArrowDownIcon />}
            isLoading={updateResearchQuestions.isLoading}
            position={'absolute'}
            right={0}
            top={0}
            transform={'translate(-55%,-50%)'}
            onClick={() => move('down')}
          />

          <IconButton
            aria-label="delete"
            colorScheme={'red'}
            icon={<DeleteIcon />}
            isLoading={updateResearchQuestions.isLoading}
            position={'absolute'}
            right={0}
            top={0}
            transform={'translate(50%,-50%)'}
            onClick={() => {
              const questions = updateResearchQuestions.form
                .getValues('questions')
                .filter((_, i) => i !== index)
              updateResearchQuestions.form.setValue(`questions`, questions)
              onChangeResearchQuestions(questions)
            }}
          />
        </ButtonGroup>
      )}
    </CsbPrimaryBox>
  )
}

//フィシイング一覧ページのcomponent
export const ResearchesDetail = memo(() => {
  const { createResearchQuestionModal, isWriteResearch, navigate, research } =
    useResearchesDetail()
  const updateResearchQuestions = useUpdateResearchQuestions()
  const onSubmitAll = updateResearchQuestions.onUpdateResearchQuestions(
    research.data?.research.uuid ?? ''
  )
  const onChangeResearchQuestions = (questions: ResearchQuestionInput[]) => {
    const newQuestions = questions.map(
      (q) =>
        ({
          answerType: q.answerType,
          isRequired: q.isRequired,
          name: q.name,
          registerAnswers: q.answers,
          uuid: q.uuid,
        } as UpdateResearchQuestionProps)
    )
    setResearchQuestions(newQuestions)
  }

  const [researchQuestions, setResearchQuestions] = useState<
    UpdateResearchQuestionProps[]
  >([])

  useEffect(() => {
    setResearchQuestions(research.data?.research.researchQuestions ?? [])
  }, [research.data?.research.researchQuestions])

  return (
    <Base
      left={<Heading>アンケート編集</Heading>}
      right={
        <HStack spacing={3}>
          <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
            一覧へ戻る
          </Button>
          {isWriteResearch && <Button onClick={onSubmitAll}>保存</Button>}
        </HStack>
      }
    >
      <CsbPrimaryBox pb={'72px'} pt={35} px={45}>
        <ResearchQuery {...research}>
          {({ research }) => {
            return (
              <>
                <Box
                  as={'ul'}
                  display={'flex'}
                  flexDirection={'column'}
                  gap={5}
                >
                  {researchQuestions.map((researchQuestion, index) => (
                    <Box
                      as={'li'}
                      key={researchQuestion.uuid}
                      listStyleType={'none'}
                    >
                      <UpdateResearchQuestion
                        isWriteResearch={isWriteResearch}
                        {...researchQuestion}
                        questionCount={research.researchQuestions.length}
                        seqNo={index + 1}
                        updateResearchQuestions={updateResearchQuestions}
                        onChangeResearchQuestions={onChangeResearchQuestions}
                      />
                    </Box>
                  ))}
                </Box>
              </>
            )
          }}
        </ResearchQuery>
        <Button mt={5} onClick={createResearchQuestionModal.onOpen}>
          新規作成
        </Button>
      </CsbPrimaryBox>
      <Flex mt={5}>
        <HStack ml={'auto'} spacing={3}>
          <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
            一覧へ戻る
          </Button>
          {isWriteResearch && <Button onClick={onSubmitAll}>保存</Button>}
        </HStack>
      </Flex>
      <CreateResearchQuestionModal {...createResearchQuestionModal} />
    </Base>
  )
})
