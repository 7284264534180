import {
  UpdateResearchQuestionsInput,
  useUpdateResearchQuestionsMutation,
} from '@src/graphql/generated/graphql'
import { useErrors, useToast } from '@src/hooks'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const useUpdateResearchQuestions = () => {
  const toast = useToast()
  const [updateResearchQuestions, { loading: isLoading }] =
    useUpdateResearchQuestionsMutation({
      refetchQueries: ['research'],
    })
  const [errors, setErrors] = useErrors()
  const form = useForm<Omit<UpdateResearchQuestionsInput, 'clientMutationId'>>()
  const navigate = useNavigate()

  const onUpdateResearchQuestions = useCallback(
    (uuid: UpdateResearchQuestionsInput['uuid']) =>
      form.handleSubmit(async ({ questions }) => {
        try {
          await updateResearchQuestions({
            variables: {
              input: {
                questions: questions,
                uuid,
              },
            },
          })

          toast({
            status: 'success',
            title: 'アンケートの更新に成功しました。',
          })
          navigate(`/researches`)
          /* eslint-disable @typescript-eslint/no-explicit-any */
        } catch (e: any) {
          toast({
            status: 'error',
            title: 'アンケートの更新に失敗しました。',
          })
          setErrors(e.graphQLErrors)
        }
      }),
    [form, updateResearchQuestions, toast, navigate, setErrors]
  )

  return {
    errors,
    form,
    isLoading,
    onUpdateResearchQuestions,
    setErrors,
  }
}

type UseUpdateResearchQuestionsType = typeof useUpdateResearchQuestions
export type UseUpdateResearchQuestionsResult =
  ReturnType<UseUpdateResearchQuestionsType>
