import { ApolloError } from '@apollo/client'
import {
  CreateAdminResearchQuestionInput,
  CreateAdminResearchQuestionMutationResult,
  ResearchAnswerType,
  useCreateAdminResearchQuestionMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors, useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { formReset } from '@src/utils/formReset'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

export const useCreateAdminResearchQuestion = ({
  onCompleted,
  onError,
}: Partial<
  BaseOptionsType<CreateAdminResearchQuestionMutationResult['data']>
> = {}) => {
  const toast = useToast()
  const [createAdminResearchQuestion, { loading: isLoading }] =
    useCreateAdminResearchQuestionMutation({
      refetchQueries: ['adminResearch'],
    })
  const form = useForm<
    Omit<
      CreateAdminResearchQuestionInput,
      'clientMutationId' | 'adminResearchUuid'
    >
  >({
    defaultValues: {
      answerType: ResearchAnswerType.Checkbox,
    },
  })
  const { findErrorMessage, resetErrors, setErrors } =
    useGraphQLErrors<
      keyof Omit<
        CreateAdminResearchQuestionInput,
        'clientMutationId' | 'adminResearchUuid'
      >
    >()
  const resetForm = () => {
    formReset(form, resetErrors)
    form.resetField('answers.1')
    form.resetField('answers.2')
    form.resetField('answers.3')
    form.resetField('answers.4')
  }

  const onCreateAdminResearchQuestion = useCallback(
    (
      adminResearchUuid: CreateAdminResearchQuestionInput['adminResearchUuid']
    ) =>
      form.handleSubmit(async ({ answerType, answers, ...inputData }) => {
        try {
          const { data } = await createAdminResearchQuestion({
            variables: {
              input: {
                ...inputData,
                adminResearchUuid,
                answerType,
                answers:
                  answerType === ResearchAnswerType.Textarea
                    ? []
                    : answers.filter(Boolean),
              },
            },
          })
          formReset(form, resetErrors)
          onCompleted?.(data)
          toast({
            status: 'success',
            title: 'アンケートの更新に成功しました。',
          })
        } catch (e) {
          toast({
            status: 'error',
            title: 'アンケートの更新に失敗しました。',
          })
          if (e instanceof ApolloError) {
            setErrors(e.graphQLErrors)
            onError?.(e)
          }
        }
      }),
    [
      form,
      setErrors,
      resetErrors,
      toast,
      onCompleted,
      onError,
      createAdminResearchQuestion,
    ]
  )

  return {
    findErrorMessage,
    form,
    isLoading,
    onCreateAdminResearchQuestion,
    resetForm,
  }
}

type UseCreateAdminResearchQuestionType = typeof useCreateAdminResearchQuestion
export type UseCreateAdminResearchQuestionResult =
  ReturnType<UseCreateAdminResearchQuestionType>
// export type UseCreateAdminResearchQuestionParameter = Parameters<UseCreateAdminResearchQuestionType>[0]
