import { TemplatesNew } from '@src/components/pages/templates/new'
import { useTemplatesNewHooks } from '@src/components/pages/templates/new/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const TemplatesNewPage = memo(() => {
  const templatesNewHooks = useTemplatesNewHooks()
  return (
    <Authorization>
      <TemplatesNew {...templatesNewHooks} />
    </Authorization>
  )
})
