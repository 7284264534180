import update from 'immutability-helper'
import { Dispatch, SetStateAction, useCallback } from 'react'
import { useDrop } from 'react-dnd'

export const useReactDndContainerHooks = <T extends { id: string }>({
  accept,
  reactDndItems,
  setReactDndItems,
}: {
  accept: string
  reactDndItems: T[]
  setReactDndItems: Dispatch<SetStateAction<T[]>>
}) => {
  const findData = useCallback(
    (id: string) => {
      const item = reactDndItems.filter((n) => n.id === id)[0]
      return {
        index: reactDndItems.indexOf(item),
        item,
      }
    },
    [reactDndItems]
  )

  const moveData = useCallback(
    (id: string, atIndex: number) => {
      const { index, item } = findData(id)
      setReactDndItems(
        update(reactDndItems, {
          $splice: [
            [index, 1],
            [atIndex, 0, item],
          ],
        })
      )
    },
    [findData, reactDndItems, setReactDndItems]
  )

  const [, drop] = useDrop(() => ({ accept }))
  return {
    accept,
    drop,
    findData,
    moveData,
  }
}

export type useReactDndContainerHooksResult = ReturnType<
  typeof useReactDndContainerHooks
>
