import { BaseFallback, BaseQueryLayoutPropsType } from '@src/models/Base'
import { usePhishingResultCorrectAnswerRateResult } from '@src/models/Phishing'

export const PhishingResultCorrectAnswerRate = ({
  children,
  data,
  error,
  errorFallback,
  loading,
  loadingFallback,
  noDataFallback,
}: BaseQueryLayoutPropsType<usePhishingResultCorrectAnswerRateResult>) => {
  if (error) {
    return <BaseFallback type={'error'}>{errorFallback}</BaseFallback>
  }

  //apolloはdataをcacheするためloadingとdataを見る必要がある
  if (loading && !data) {
    return <BaseFallback type={'loading'}>{loadingFallback}</BaseFallback>
  }

  if (!data) {
    return <BaseFallback type={'noData'}>{noDataFallback}</BaseFallback>
  }

  return typeof children === 'function' ? children(data) : children
}
