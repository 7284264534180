import { Positions } from '@src/components/pages/positions'
import { usePositionsHooks } from '@src/components/pages/positions/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const PositionPage = memo(() => {
  const positionsHooks = usePositionsHooks()
  return (
    <Authorization>
      <Positions {...positionsHooks} />
    </Authorization>
  )
})
