import { useToast } from '@chakra-ui/react'
import { paginationInput } from '@src/constants'
import {
  CreateMailFromInput,
  UpdateMailFromInput,
  useCreateMailFromMutation,
  useDestroyMailFromMutation,
  useMailFromsQuery,
  useUpdateMailFromCompaniesMutation,
  useUpdateMailFromMutation,
} from '@src/graphql/generated/graphql'
import { useDeleteModal, useFormModal, useMe, usePagination } from '@src/hooks'
import { useCsbDataModal } from '@src/hooks/useCsbDataModal'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

//メールを追加するページで使うhooksのまとめ
export const useMailsHooks = () => {
  const { role } = useMe()
  const isWriteFromDomain = !!role?.writeFromDomain
  const deleteModal = useDeleteModal()
  const [destroyMailFromMutation] = useDestroyMailFromMutation()
  const [createMailFromMutation, { loading: isCreating }] =
    useCreateMailFromMutation()
  const [updateMailFromMutation, { loading: isUpdating }] =
    useUpdateMailFromMutation()
  const formModal = useFormModal<
    CreateMailFromInput | Omit<UpdateMailFromInput, 'uuid'>
  >({
    defaultValues: {
      email: '',
      locked: false,
    },
  })

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    searchWord: withDefault(StringParam, ''),
  })
  //ページネーション
  const { current, onChangePage } = usePagination()

  const { data, refetch } = useMailFromsQuery({
    variables: {
      pagination: paginationInput(current),
      searchWord: query.searchWord,
    },
  })

  //編集するModalを開くボタン
  const onUpdate = ({
    closedAt,
    description,
    email,
    locked,
    publishedAt,
    uuid,
  }: UpdateMailFromInput) => {
    formModal.onOpen()
    formModal.form.setValue('email', email)
    formModal.form.setValue('description', description)
    formModal.form.setValue('locked', locked)
    formModal.form.setValue(
      'publishedAt',
      publishedAt ? dayjs(publishedAt).format('YYYY-MM-DD') : undefined
    )
    formModal.form.setValue(
      'closedAt',
      closedAt ? dayjs(closedAt).format('YYYY-MM-DD') : undefined
    )
    formModal.setUuid(uuid)
    formModal.setState('update')
  }

  //新規作成、編集のModalのformを送信する関数
  const onSubmit = async ({
    closedAt,
    publishedAt,
    ...inputData
  }: CreateMailFromInput | Omit<UpdateMailFromInput, 'uuid'>) => {
    const publishedAtInput = publishedAt
      ? dayjs(publishedAt).toISOString()
      : null
    const closedAtInput = closedAt ? dayjs(closedAt).toISOString() : null
    try {
      //typeを判定
      switch (formModal.state) {
        case 'create':
          //createの場合はcreateGroupにinputDataを渡す
          await createMailFromMutation({
            variables: {
              input: {
                ...inputData,
                closedAt: closedAtInput,
                publishedAt: publishedAtInput,
              },
            },
          })
          break
        case 'update':
          //createの場合はupdateGroupMutationにinputDataとuuidを渡す
          await updateMailFromMutation({
            variables: {
              input: {
                ...inputData,
                closedAt: closedAtInput,
                publishedAt: publishedAtInput,
                uuid: formModal.uuid,
              },
            },
          })
          break
        //一応エラーを書く
        default:
          new Error(`${formModal.state} does not exist`)
      }

      //新規作成、編集をすればToastを発行する
      formModal.toast({
        status: 'success',
        title: formModal.isCreate
          ? `${inputData.email || 'メール'}を追加しました。`
          : `${inputData.email || 'メール'}を編集しました。`,
      })
      formModal.setErrors([])
      formModal.onCancel()
      /**
       * TODO anyを消す
       * e instanceOf ApolloError
       * setErrorsを変更する
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      formModal.setErrors(e.graphQLErrors)
      //新規作成、編集が失敗した場合のToast
      formModal.toast({
        status: 'error',
        title: formModal.isCreate
          ? `${inputData.email || 'メール'}の追加に失敗しました。`
          : `${inputData.email || 'メール'}の編集に失敗しました。`,
      })
    }
    await refetch()
  }

  // noinspection DuplicatedCode
  const dataModal = useCsbDataModal<{
    items: { id: number; name: string; uuid: string }[]
    title: string
  }>()

  //search機能
  const searchForm = useForm<{ searchWord: string }>({
    defaultValues: {
      searchWord: query.searchWord,
    },
  })
  const onSearch = searchForm.handleSubmit(async (inputData) => {
    setQuery({
      page: 1,
      searchWord: inputData.searchWord,
    })
  })
  const onResetSearch = () => {
    searchForm.setValue('searchWord', '')

    setQuery({
      page: 1,
      searchWord: '',
    })
  }

  const [
    updateMailFromCompaniesMutation,
    { loading: isUpdatingMailFromCompanies },
  ] = useUpdateMailFromCompaniesMutation()

  const toast = useToast()

  const onUpdateSelectCompanies = async (
    uuid: string,
    companiesUuid: string[]
  ) => {
    try {
      await updateMailFromCompaniesMutation({
        variables: {
          input: {
            companies: companiesUuid,
            uuid: uuid,
          },
        },
      })
      toast({
        status: 'success',
        title: 'Fromドメインの対象企業を設定しました。',
      })
    } catch (e) {
      toast({
        status: 'error',
        title: 'Fromドメインの対象企業の設定に失敗しました。',
      })
    }
    await refetch()
  }

  return {
    current,
    data,
    dataModal,
    deleteModal,
    destroyMailFromMutation,
    formModal,
    isCreating,
    isUpdating,
    isUpdatingMailFromCompanies,
    isWriteFromDomain,
    onChangePage,
    onResetSearch,
    onSearch,
    onSubmit,
    onUpdate,
    onUpdateSelectCompanies,
    refetch,
    searchForm,
  }
}

export type useMailsHooksResult = ReturnType<typeof useMailsHooks>
