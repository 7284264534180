import { useDisclosure, useToast } from '@chakra-ui/react'
import { LocalStorage } from '@src/enums/localStorage'
import {
  RevealingTarget,
  usePhishingEmailQuery,
  usePhishingReplyEmlExportMutation,
  useWordExportPhishingResultMutation,
} from '@src/graphql/generated/graphql'
import { useLocalStorage, useMe, usePagination } from '@src/hooks'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import { useCsvExportSendMailLog } from '@src/models/Phishing/CsvExportSendMailLog/useCsvExportSendMailLog'
import { useCsvExportLearningUrl } from '@src/models/Phishing/CsvLearningUrl/useCsvExportLearningUrl'
import { useCsvExportPhishingAccessLog } from '@src/models/Phishing/CsvPhishingAccessLog/useCsvExportPhishingAccessLog'
import axios from 'axios'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

export const usePhishingResultNewHooks = () => {
  const { role } = useMe()
  const isWritePhishingMail = !!role?.writePhishingMail
  const { onBackListPage } = usePagination()
  const { id } = useParams<{ id: string }>()
  const { isDeliveryContentsOnly } = useDeliveryContents()
  const toast = useToast()
  const [accessToken] = useLocalStorage<string>(LocalStorage.ACCESS_TOKEN, '')
  const [isLoading, setIsLoading] = useState(false)

  const resendRevealingEmailModal = useDisclosure()

  const phishingEmailQuery = usePhishingEmailQuery({
    skip: !id,
    variables: {
      isDeliveryContentsOnly,
      uuid: id ?? '',
    },
  })

  const isArchived = !!phishingEmailQuery.data?.phishingEmail?.archivedAt
  const isRevealingPhishingEmailScheduled = phishingEmailQuery.loading
    ? true
    : !!phishingEmailQuery.data?.phishingEmail.revealingPhishingEmailSchedule
        ?.id

  const exportPhishingAccessLogModal = useDisclosure()
  const {
    form: exportPhishingAccessLogForm,
    isExporting: isPhishingAccessLogsExporting,
    onExportPhishingAccessLogs,
  } = useCsvExportPhishingAccessLog({
    onCompleted: () => {
      exportPhishingAccessLogModal.onClose()
    },
  })

  const exportSendMailLogModal = useDisclosure()
  const {
    form: exportSendMailLogForm,
    isExporting: isSendMailLogsExporting,
    onExportSendMailLogs,
  } = useCsvExportSendMailLog({
    onCompleted: () => {
      exportSendMailLogModal.onClose()
    },
  })

  const onExportDownload = async () => {
    setIsLoading(true)
    try {
      // TODO anyを消す
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const excel: any = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/export/phishing_result.xlsx`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            uuid: id,
          },
          responseType: 'blob',
        }
      )
      const url = URL.createObjectURL(
        new Blob([excel.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        dayjs().format('YYYY-MM-DD') + '_phishing_result.xlsx'
      )
      document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(url)
      link.parentNode?.removeChild(link)
    } catch (e) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: 'エクスポートに失敗しました。',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const exportWordPhishingResultModal = useDisclosure()
  const [wordExportPhishingResultMutation, { loading: isWordExporting }] =
    useWordExportPhishingResultMutation()
  const onExportWord = async () => {
    try {
      await wordExportPhishingResultMutation({
        variables: {
          input: { uuid: id ?? '' },
        },
      })
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'success',
        title: '集計レポート（Word）のエクスポートに成功しました。',
      })
      exportWordPhishingResultModal.onClose()
    } catch (e) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: '集計レポート（Word）のエクスポートに失敗しました。',
      })
    }
  }

  const exportPhishingReplyEmlModal = useDisclosure()
  const [
    phishingReplyEmlExportMutation,
    { loading: isPhishingReplyEmlExporting },
  ] = usePhishingReplyEmlExportMutation()
  const onExportPhishingReplyEml = async () => {
    try {
      await phishingReplyEmlExportMutation({
        variables: {
          input: { uuid: id ?? '' },
        },
      })
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'success',
        title: '返信メールのエクスポートに成功しました。',
      })
      exportPhishingReplyEmlModal.onClose()
    } catch (e) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: '返信メールのエクスポートに失敗しました。',
      })
    }
  }

  const [revealingTarget, setRevealingTarget] = useState<RevealingTarget>(
    RevealingTarget.All
  )

  const exportLearningUrlModal = useDisclosure()
  const {
    form: exportLearningUrlForm,
    isExporting: isLearningUrlExporting,
    onExportLearningUrl,
  } = useCsvExportLearningUrl({
    onCompleted: () => {
      exportLearningUrlModal.onClose()
    },
  })

  return {
    exportLearningUrlForm,
    exportLearningUrlModal,
    exportPhishingAccessLogForm,
    exportPhishingAccessLogModal,
    exportPhishingReplyEmlModal,
    exportSendMailLogForm,
    exportSendMailLogModal,
    exportWordPhishingResultModal,
    id,
    isArchived,
    isLearningUrlExporting,
    isLoading,
    isPhishingAccessLogsExporting,
    isPhishingReplyEmlExporting,
    isRevealingPhishingEmailScheduled,
    isSendMailLogsExporting,
    isWordExporting,
    isWritePhishingMail,
    onBackListPage,
    onExportDownload,
    onExportLearningUrl,
    onExportPhishingAccessLogs,
    onExportPhishingReplyEml,
    onExportSendMailLogs,
    onExportWord,
    phishingEmailQuery,
    resendRevealingEmailModal,
    revealingTarget,
    setRevealingTarget,
  }
}

export type usePhishingResultNewHooksResult = ReturnType<
  typeof usePhishingResultNewHooks
>
