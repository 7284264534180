import { DeleteIcon } from '@chakra-ui/icons'
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  HStack,
  IconButton,
  Image,
  Spinner,
} from '@chakra-ui/react'
import { CsbDropzone, CsbPrimaryBox, CsbTag } from '@src/components'
import { useComicsEditHooksResult } from '@src/components/pages/comics/edit/hooks'
import { ReactDndItem } from '@src/components/ReactDnd'
import { Base } from '@src/layouts'
import { memo } from 'react'

//コミック詳細ページのcomponent
export const ComicsEdit = memo(
  ({
    ReactDndItemProps,
    data,
    drop,
    dropzone,
    images,
    isUpdating,
    isWriteLearningComic,
    loading,
    navigate,
    onRemoveImage,
    onUpdate,
    upload,
  }: useComicsEditHooksResult) => {
    return (
      <Base
        left={<Heading>{data?.comic.name || 'コミック'}の編集</Heading>}
        right={
          <HStack spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧へ戻る
            </Button>
            {isWriteLearningComic && (
              <Button isLoading={isUpdating} onClick={onUpdate}>
                保存
              </Button>
            )}
          </HStack>
        }
      >
        <CsbPrimaryBox pb={'72px'} pt={35} px={45}>
          {data && !loading ? (
            <Grid gap={10} ref={drop} templateColumns="repeat(4, 1fr)">
              {images.map(({ id, url, uuid }, index) => (
                <ReactDndItem
                  id={id}
                  key={`${url}_${uuid}`}
                  {...ReactDndItemProps}
                  {...(!isWriteLearningComic && {
                    style: { pointerEvents: 'none' },
                  })}
                >
                  <Box
                    cursor={'move'}
                    key={`${id}_${index}`}
                    position={'relative'}
                  >
                    <CsbTag left={1} position={'absolute'} top={1} zIndex={1}>
                      {index + 1}ページ
                    </CsbTag>
                    <AspectRatio ratio={7 / 8}>
                      <Image
                        fallback={
                          <Box
                            alignItems={'center'}
                            bgColor={'rgba(0,0,0,.5)'}
                            d={'flex'}
                            h={150}
                            justifyContent={'center'}
                            w={150}
                          >
                            <Spinner
                              color="blue.500"
                              emptyColor="gray.200"
                              size="xl"
                              speed="0.65s"
                              thickness="4px"
                            />
                          </Box>
                        }
                        src={url}
                      />
                    </AspectRatio>
                    {isWriteLearningComic && (
                      <IconButton
                        aria-label="delete"
                        colorScheme={'red'}
                        gridColumn={'1/2'}
                        icon={<DeleteIcon />}
                        position={'absolute'}
                        right={0}
                        top={0}
                        onClick={() => onRemoveImage(id)}
                      />
                    )}
                  </Box>
                </ReactDndItem>
              ))}
              {upload.loading && (
                <AspectRatio ratio={7 / 8}>
                  <>
                    <Image src={upload.thumbnail} />
                    <Box
                      alignItems={'center'}
                      bgColor={'rgba(0,0,0,.5)'}
                      d={'flex'}
                      h={150}
                      justifyContent={'center'}
                      w={150}
                    >
                      <Spinner
                        color="blue.500"
                        emptyColor="gray.200"
                        size="xl"
                        speed="0.65s"
                        thickness="4px"
                      />
                    </Box>
                  </>
                </AspectRatio>
              )}
              {isWriteLearningComic && (
                <AspectRatio ratio={7 / 8}>
                  <CsbDropzone {...dropzone} />
                </AspectRatio>
              )}
            </Grid>
          ) : (
            <>loading...</>
          )}
        </CsbPrimaryBox>
        <Flex mt={5}>
          <HStack ml={'auto'} spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧へ戻る
            </Button>
            {isWriteLearningComic && (
              <Button isLoading={isUpdating} onClick={onUpdate}>
                保存
              </Button>
            )}
          </HStack>
        </Flex>
      </Base>
    )
  }
)
