import { useMe } from '@src/hooks'
import {
  useCreateResearchQuestionModal,
  useResearch,
} from '@src/models/Research'
import { useNavigate, useParams } from 'react-router-dom'

export const useResearchesDetail = () => {
  const { id } = useParams<{ id: string }>()
  const { role } = useMe()
  const isWriteResearch = !!role?.writeResearch
  const navigate = useNavigate()
  const research = useResearch({
    skip: !id,
    variables: {
      uuid: id ?? '',
    },
  })
  const createResearchQuestionModal = useCreateResearchQuestionModal(id ?? '')

  return {
    createResearchQuestionModal,
    isWriteResearch,
    navigate,
    research,
  }
}

export type UseResearchesDetailResult = ReturnType<typeof useResearchesDetail>
