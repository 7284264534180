//学習詳細で使うhooksのまとめ
import { useToast } from '@chakra-ui/react'
import { BaseResponseError } from '@src/errors/BaseResponseError'
import {
  QuestionInput,
  useLearnQuery,
  useUpdateLearnQuestionsMutation,
} from '@src/graphql/generated/graphql'
import { useErrors, useMe } from '@src/hooks'
import { useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

type FormValues = {
  questions: QuestionInput[]
}

export const useLearnDetailHooks = () => {
  const { role } = useMe()
  const isWriteLearningText = !!role?.writeLearningText

  /**
   * 問題の雛形
   * 関数の外で定義するとバグるので必ず関数の内部に定義する
   */
  const initialQuestions = {
    answerType: 1,
    corrects: [
      {
        correct: false,
        name: '',
      },
      {
        correct: false,
        name: '',
      },
    ],
    explanation: '',
    name: '',
  }

  const toast = useToast()
  const navigate = useNavigate()
  //queryからidを抽出して返す
  const { id } = useParams<{ id: string }>()
  const [questions, setQuestions] = useState<FormValues>({
    questions: [initialQuestions],
  })
  //現ページのuuidからlearnのdataを取得する
  const { data, loading } = useLearnQuery({
    onCompleted: ({ learn }) => {
      setQuestions({
        questions: learn.questions.map((item) => ({
          answerType: item.answerType,
          corrects: item.corrects.map((correct) => ({
            correct: correct.correct,
            name: correct.name,
            uuid: correct.uuid,
          })),
          explanation: item.explanation,
          name: item.name,
          uuid: item.uuid,
        })),
      })
    },
    variables: { uuid: id ?? '' },
  })

  const [updateLearnQuestionsMutation] = useUpdateLearnQuestionsMutation()

  /**
   * questionのform
   * @see https://github.com/react-hook-form/react-hook-form/issues/2492
   */
  const form = useForm<FormValues>({
    defaultValues: useMemo(() => questions, [questions]),
    mode: 'onChange',
  })

  useEffect(() => {
    form.reset(questions)
  }, [questions, form])

  const {
    append: questionAppend,
    fields: questionFields,
    move: questionMove,
    remove: questionRemove,
  } = useFieldArray({
    control: form.control,
    name: 'questions',
  })

  const [errors, setErrors] = useErrors()

  //問題を追加する処理
  const onAddQuestion = () => {
    questionAppend(initialQuestions)
  }

  //送信する処理
  const onSubmit = form.handleSubmit(async (inputData) => {
    try {
      await updateLearnQuestionsMutation({
        variables: {
          input: {
            questions: inputData.questions,
            uuid: id ?? '',
          },
        },
      })
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'success',
        title: '問題を追加しました。',
      })
      navigate('/learn')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const correctErrors = e.graphQLErrors.filter(
        (error?: BaseResponseError) => {
          return error?.extensions.attribute.indexOf('corrects') !== -1
        }
      )
      const errorMessage =
        correctErrors.length > 0
          ? '回答が空欄の様です。回答を埋めてください'
          : '入力エラーがあります。'

      toast({
        description: errorMessage,
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: '問題の追加に失敗しました。',
      })
      setErrors(e.graphQLErrors)
    }
  })
  return {
    data,
    errors,
    form,
    isWriteLearningText,
    loading,
    navigate,
    onAddQuestion,
    onSubmit,
    questionFields,
    questionMove,
    questionRemove,
  }
}

export type useLearnDetailHooksResult = ReturnType<typeof useLearnDetailHooks>
