import { paginationInput } from '@src/constants'
import {
  CreateOrganizationInput,
  Organization,
  UpdateOrganizationInput,
  useCreateOrganizationMutation,
  useDestroyOrganizationMutation,
  useOrganizationsQuery,
  useUpdateOrganizationMutation,
} from '@src/graphql/generated/graphql'
import { useDeleteModal, useFormModal, useMe, usePagination } from '@src/hooks'
import { useForm } from 'react-hook-form'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

//formで管理するorganizationの型
type OrganizationInputType =
  | CreateOrganizationInput
  | Omit<UpdateOrganizationInput, 'uuid'>

//企業一覧で使うhooksのまとめ
export const useOrganizationsHooks = () => {
  const { role } = useMe()
  const isWriteOrganization = !!role?.writeOrganization
  const [destroyOrganizationMutation] = useDestroyOrganizationMutation()
  const [updateOrganizationMutation, { loading: isUpdating }] =
    useUpdateOrganizationMutation()
  const deleteModal = useDeleteModal()
  const [createOrganizationMutation, { loading: isCreating }] =
    useCreateOrganizationMutation()
  const formModal = useFormModal<OrganizationInputType>({
    defaultValues: {
      description: '',
      name: '',
    },
  })

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    searchWord: withDefault(StringParam, ''),
  })
  //ページネーション
  const { current, onChangePage } = usePagination()

  const { data, refetch } = useOrganizationsQuery({
    variables: {
      pagination: paginationInput(current),
      searchWord: query.searchWord,
    },
  })

  //編集するModalを開くボタン
  const onUpdate = ({
    description,
    name,
    uuid,
  }: Pick<Organization, 'name' | 'description' | 'uuid'>) => {
    formModal.onOpen()
    formModal.form.setValue('name', name)
    formModal.form.setValue('description', description)
    formModal.setUuid(uuid)
    formModal.setState('update')
  }

  //新規作成、編集のModalのformを送信する関数
  const onSubmit = async (inputData: OrganizationInputType) => {
    try {
      //typeを判定
      switch (formModal.state) {
        case 'create':
          //createの場合はcreateOrganizationMutationにinputDataを渡す
          await createOrganizationMutation({
            variables: {
              input: inputData,
            },
          })
          break
        case 'update':
          //createの場合はupdateOrganizationMutationにinputDataとuuidを渡す
          await updateOrganizationMutation({
            variables: {
              input: {
                ...inputData,
                uuid: formModal.uuid,
              },
            },
          })
          break
        //一応エラーを書く
        default:
          new Error(`${formModal.state} does not exist`)
      }

      //新規作成、編集をすればToastを発行する
      formModal.toast({
        status: 'success',
        title: formModal.isCreate
          ? `${inputData.name || '組織'}を作成しました。`
          : `${inputData.name || '組織'}を編集しました。`,
      })
      formModal.onCancel()
      /**
       * TODO anyを消す
       * e instanceOf ApolloError
       * setErrorsを変更する
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      formModal.setErrors(e.graphQLErrors)
      //新規作成、編集が失敗した場合のToast
      formModal.toast({
        status: 'error',
        title: formModal.isCreate
          ? `${inputData.name || '組織'}の作成に失敗しました。`
          : `${inputData.name || '組織'}の編集に失敗しました。`,
      })
    }
    await refetch()
  }

  //search機能
  const searchForm = useForm<{ searchWord: string }>({
    defaultValues: {
      searchWord: query.searchWord,
    },
  })
  const onSearch = searchForm.handleSubmit(async (inputData) => {
    setQuery({
      page: 1,
      searchWord: inputData.searchWord,
    })
  })
  const onResetSearch = () => {
    searchForm.setValue('searchWord', '')

    setQuery({
      page: 1,
      searchWord: undefined,
    })
  }

  return {
    current,
    data,
    deleteModal,
    destroyOrganizationMutation,
    formModal,
    isCreating,
    isUpdating,
    isWriteOrganization,
    onChangePage,
    onResetSearch,
    onSearch,
    onSubmit,
    onUpdate,
    refetch,
    searchForm,
  }
}

export type useOrganizationsHooksResult = ReturnType<
  typeof useOrganizationsHooks
>
