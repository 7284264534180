import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
import { theme as selectTheme } from 'chakra-ui-select'

export const theme = extendTheme(
  withDefaultColorScheme({ colorScheme: 'teal' }),
  {
    components: {
      Button: {
        defaultProps: {
          size: 'md',
        },
        sizes: {
          lg: {
            fontSize: 'sm',
          },
          md: {
            fontSize: 'sm',
          },
          sm: {
            fontSize: 'sm',
          },
        },
      },
      Heading: {
        defaultProps: {
          size: 'lg',
        },
      },
      SelectMultiple: selectTheme.SelectMultiple,
    },
    styles: {
      global: {
        body: {
          overflowX: 'hidden',
        },
      },
    },
  }
)
