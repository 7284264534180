import { CopyIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useClipboard,
} from '@chakra-ui/react'
import { CsbDeleteButton, CsbPagination } from '@src/components'
import { CsbEditButton } from '@src/components/Buttons/CsbEditButton'
import { CsbTable } from '@src/components/CsbTable'
import { useQuestionnairesHooks } from '@src/components/pages/questionnaires/hooks'
import { Base } from '@src/layouts'
import {
  CreateQuestionnaireModal,
  DestroyQuestionnaireModal,
  UpdateQuestionnaireModal,
} from '@src/models/Questionnaire'
import { QuestionnairesQuery } from '@src/models/Questionnaire/QuestionnairesQuery'
import dayjs from 'dayjs'
import { memo } from 'react'

const CopyUrlButton = memo(({ uuid }: { uuid: string }) => {
  const baseUrl = window.location.href
  const url = `${baseUrl}/${uuid}`
  const { hasCopied, onCopy } = useClipboard(url)
  return (
    <Tooltip
      hasArrow
      aria-label="A tooltip"
      label={hasCopied ? 'URLをコピーしました' : 'URLをコピーする'}
    >
      <IconButton
        aria-label="url"
        colorScheme={'gray'}
        icon={<CopyIcon />}
        rounded={'full'}
        onClick={(e) => {
          e.stopPropagation()
          onCopy()
        }}
      />
    </Tooltip>
  )
})

//アンケートURL発行一覧ページのcomponent
export const Questionnaires = memo(() => {
  const {
    createQuestionnaireModal,
    current,
    destroyQuestionnaireModal,
    isWriteResearch,
    navigate,
    onChangePage,
    questionnaires,
    updateQuestionnaireModal,
  } = useQuestionnairesHooks()
  return (
    <Base
      left={<Heading>アンケートURL</Heading>}
      right={
        isWriteResearch && (
          <Button w={210} onClick={createQuestionnaireModal.onOpen}>
            アンケートURL発行
          </Button>
        )
      }
    >
      <QuestionnairesQuery {...questionnaires}>
        {({ questionnaires }) => (
          <>
            <CsbTable
              labels={['ラベル', '選択アンケート', '作成日時', '']}
              wrapperProps={{ mt: 35 }}
            >
              {questionnaires.collection.map((questionnaire) => (
                <CsbTable.TableRow
                  key={questionnaire.uuid}
                  onClick={() =>
                    navigate(`/questionnaires/${questionnaire.uuid}/result`)
                  }
                >
                  <CsbTable.TableCell w={300}>
                    <Tooltip aria-label="A tooltip" label={questionnaire.name}>
                      <Text isTruncated fontWeight={'bold'} w={300}>
                        {questionnaire.name}
                      </Text>
                    </Tooltip>
                  </CsbTable.TableCell>
                  <CsbTable.TableCell w={300}>
                    <Tooltip
                      aria-label="A tooltip"
                      label={questionnaire.research.name}
                    >
                      <Text isTruncated fontSize={'sm'} w={200}>
                        {questionnaire.research.name}
                      </Text>
                    </Tooltip>
                  </CsbTable.TableCell>

                  <CsbTable.TableCell w={150}>
                    <Tooltip aria-label="A tooltip" label={'作成日時'}>
                      <Text fontSize={'sm'} w={150}>
                        {dayjs(questionnaire.createdAt).format(
                          'YYYY-MM-DD HH:mm'
                        )}
                      </Text>
                    </Tooltip>
                  </CsbTable.TableCell>

                  <CsbTable.TableCell isNumeric>
                    {isWriteResearch && (
                      <Flex gridGap={5} justifyContent={'end'}>
                        <CopyUrlButton uuid={questionnaire.uuid} />
                        <Tooltip
                          aria-label="A tooltip"
                          label={'アンケートページを開く'}
                        >
                          <IconButton
                            aria-label="url"
                            colorScheme={'blue'}
                            icon={<ExternalLinkIcon />}
                            rounded={'full'}
                            onClick={(e) => {
                              e.stopPropagation()
                              window.open(
                                `/questionnaires/${questionnaire.uuid}`,
                                '_blank'
                              )
                            }}
                          />
                        </Tooltip>
                        <Tooltip aria-label="A tooltip" label={'変更する'}>
                          <CsbEditButton
                            onClick={(e) => {
                              e.stopPropagation()
                              updateQuestionnaireModal.onOpen(questionnaire)
                            }}
                          />
                        </Tooltip>
                        <CsbDeleteButton
                          onClick={(e) => {
                            e.stopPropagation()
                            destroyQuestionnaireModal.onOpen(questionnaire)
                          }}
                        />
                      </Flex>
                    )}
                  </CsbTable.TableCell>
                </CsbTable.TableRow>
              ))}
            </CsbTable>
            <Flex mt={5}>
              <CsbPagination
                current={current}
                defaultCurrent={current}
                pageSize={questionnaires.metadata.limitValue}
                total={questionnaires.metadata.totalCount}
                onChange={onChangePage()}
              />
              <HStack ml={'auto'} spacing={3}>
                {isWriteResearch && (
                  <Button w={210} onClick={createQuestionnaireModal.onOpen}>
                    アンケートURL発行
                  </Button>
                )}
              </HStack>
            </Flex>
          </>
        )}
      </QuestionnairesQuery>
      <CreateQuestionnaireModal {...createQuestionnaireModal} />
      <UpdateQuestionnaireModal {...updateQuestionnaireModal} />
      <DestroyQuestionnaireModal {...destroyQuestionnaireModal} />
    </Base>
  )
})
