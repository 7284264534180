import { Comics } from '@src/components/pages/comics'
import { useComicsHooks } from '@src/components/pages/comics/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const ComicsPage = memo(() => {
  const comicsHooks = useComicsHooks()
  return (
    <Authorization>
      <Comics {...comicsHooks} />
    </Authorization>
  )
})
