import { AspectRatio, AspectRatioProps, Box, theme } from '@chakra-ui/react'
import { Pie } from 'react-chartjs-2'

const COLORS = [
  theme.colors.teal['500'],
  theme.colors.blue['500'],
  theme.colors.yellow['500'],
  theme.colors.orange['500'],
  theme.colors.cyan['500'],
  theme.colors.purple['500'],
  theme.colors.pink['500'],
]

type CsbPieChartPropsType = {
  backgroundColors?: string[]
  data: number[]
  labels: string[]
} & Omit<AspectRatioProps, 'children'>

/**
 * Chartライブラリのwrapper
 * @see https://www.chartjs.org/docs/latest/samples/other-charts/pie.html
 * @see https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/Pie.js
 */
export const CsbPieChart = ({
  backgroundColors,
  data,
  labels,
  ...props
}: CsbPieChartPropsType) => {
  const getCurrentValue = (index: number) => {
    return data[index]
  }

  const getSumDataValue = () => {
    return data.reduce((a, b) => {
      return a + b
    })
  }

  return (
    <AspectRatio maxW={300} ratio={1} w={'full'} {...props}>
      {data.length && !data.every((n) => n === 0) ? (
        <Pie
          data={{
            datasets: [
              {
                backgroundColor: backgroundColors ?? COLORS,
                data,
              },
            ],
            labels,
          }}
          options={{
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: (context) => {
                    const currentValue = getCurrentValue(context.dataIndex)
                    const total = getSumDataValue()

                    const percentage = parseFloat(
                      ((currentValue / total) * 100).toFixed(1)
                    )
                    return `${currentValue + ' (' + percentage + '%)'}`
                  },
                  title: (tooltipItem) => `${tooltipItem[0]?.label}`,
                },
              },
            },
          }}
        />
      ) : (
        <Box>データがありません</Box>
      )}
    </AspectRatio>
  )
}
