import {
  forwardRef,
  IconButton,
  IconButtonProps,
  Tooltip,
} from '@chakra-ui/react'
import { BsFiles } from 'react-icons/bs'
type CsbCopyButtonButtonPropsType = Omit<
  IconButtonProps,
  'children' | 'aria-label'
>

export const CsbCopyButton = forwardRef<CsbCopyButtonButtonPropsType, 'button'>(
  ({ ...props }, ref) => {
    return (
      <Tooltip label={'複製する'}>
        <IconButton
          colorScheme={'teal'}
          fontSize="18px"
          icon={<BsFiles />}
          ref={ref}
          rounded={'full'}
          {...props}
          aria-label="copy"
        />
      </Tooltip>
    )
  }
)
