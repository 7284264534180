//動画一覧で使うhooksのまとめ
import { useDisclosure } from '@chakra-ui/react'
import { paginationInput } from '@src/constants'
import {
  CreateMovieInput,
  Movie,
  UpdateMovieInput,
  useCreateMovieMutation,
  useDestroyMovieMutation,
  useMoviesQuery,
  useUpdateMovieCompaniesMutation,
  useUpdateMovieMutation,
} from '@src/graphql/generated/graphql'
import {
  useDeleteModal,
  useFormModal,
  useMe,
  usePagination,
  useToast,
} from '@src/hooks'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

type movieType = NonNullable<
  ReturnType<typeof useMoviesQuery>['data']
>['movies']['collection'][number]

export const useMoviesHooks = () => {
  const { role } = useMe()
  const isWriteLearningMovie = !!role?.writeLearningMovie
  const { isOpen, onClose, onOpen } = useDisclosure()
  const deleteModal = useDeleteModal()
  const [destroyMovieMutation] = useDestroyMovieMutation()
  const [createMovieMutation, { loading: isCreating }] =
    useCreateMovieMutation()
  const [updateMovieMutation, { loading: isUpdating }] =
    useUpdateMovieMutation()
  const formModal = useFormModal<CreateMovieInput>({
    defaultValues: {
      name: '',
      url: '',
    },
  })
  //現在のmovieのデータを管理するstate
  const [currentMovie, setMovie] = useState<movieType | null>(null)

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    searchWord: withDefault(StringParam, ''),
  })
  //ページネーション
  const { current, onChangePage } = usePagination()

  const { data, refetch } = useMoviesQuery({
    variables: {
      pagination: paginationInput(current),
      searchWord: query.searchWord,
    },
  })

  //編集するModalを開くボタン
  const onUpdate = ({
    name,
    url,
    uuid,
  }: Pick<Movie, 'uuid' | 'name' | 'url'>) => {
    formModal.onOpen()
    formModal.form.setValue('name', name)
    formModal.form.setValue('url', url)
    formModal.setUuid(uuid)
    formModal.setState('update')
  }

  //新規作成、編集のModalのformを送信する関数
  const onSubmit = async (inputData: CreateMovieInput | UpdateMovieInput) => {
    try {
      //typeを判定
      switch (formModal.state) {
        case 'create':
          //createの場合はcreateGroupにinputDataを渡す
          await createMovieMutation({
            variables: {
              input: inputData,
            },
          })
          break
        case 'update':
          //createの場合はupdateGroupMutationにinputDataとuuidを渡す
          await updateMovieMutation({
            variables: {
              input: {
                ...inputData,
                uuid: formModal.uuid,
              },
            },
          })
          break
        //一応エラーを書く
        default:
          new Error(`${formModal.state} does not exist`)
      }

      //新規作成、編集をすればToastを発行する
      formModal.toast({
        status: 'success',
        title: formModal.isCreate
          ? `${inputData.name || '動画'}を登録しました。`
          : `${inputData.name || '動画'}を編集しました。`,
      })
      formModal.onCancel()
      /**
       * TODO anyを消す
       * e instanceOf ApolloError
       * setErrorsを変更する
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      formModal.setErrors(e.graphQLErrors)
      //新規作成、編集が失敗した場合のToast
      formModal.toast({
        status: 'error',
        title: formModal.isCreate
          ? `${inputData.name || '動画'}の作成に失敗しました。`
          : `${inputData.name || '動画'}の編集に失敗しました。`,
      })
    }
    await refetch()
  }

  //search機能
  const searchForm = useForm<{ searchWord: string }>({
    defaultValues: {
      searchWord: query.searchWord,
    },
  })
  const onSearch = searchForm.handleSubmit(async (inputData) => {
    setQuery({
      page: 1,
      searchWord: inputData.searchWord,
    })
  })
  const onResetSearch = () => {
    searchForm.setValue('searchWord', '')

    setQuery({
      page: 1,
      searchWord: '',
    })
  }

  const [updateMovieCompaniesMutation, { loading: isUpdatingMovieCompanies }] =
    useUpdateMovieCompaniesMutation()

  const toast = useToast()

  const onUpdateSelectCompanies = async (
    uuid: string,
    companyUuids: string[]
  ) => {
    try {
      await updateMovieCompaniesMutation({
        variables: {
          input: {
            companies: companyUuids,
            uuid: uuid,
          },
        },
      })
      toast({
        status: 'success',
        title: '動画の対象企業を設定しました。',
      })
    } catch (e) {
      toast({
        status: 'error',
        title: '動画の対象企業の設定に失敗しました。',
      })
    }
    await refetch()
  }

  return {
    current,
    currentMovie,
    data,
    deleteModal,
    destroyMovieMutation,
    formModal,
    isCreating,
    isOpen,
    isUpdating,
    isUpdatingMovieCompanies,
    isWriteLearningMovie,
    onChangePage,
    onClose,
    onOpen,
    onResetSearch,
    onSearch,
    onSubmit,
    onUpdate,
    onUpdateSelectCompanies,
    refetch,
    searchForm,
    setMovie,
  }
}

export type useMoviesHooksResult = ReturnType<typeof useMoviesHooks>
