import { Documents } from '@src/components/pages/documents'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const DocumentsPage = memo(() => {
  return (
    <Authorization>
      <Documents />
    </Authorization>
  )
})
