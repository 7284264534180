import { useAdminResearchQuestionsQuery } from '@src/graphql/generated/graphql'

export const useAdminResearchQuestions = (
  baseOptions: Partial<
    Parameters<typeof useAdminResearchQuestionsQuery>[0]
  > = {}
) => {
  return useAdminResearchQuestionsQuery(baseOptions)
}

type UseAdminResearchQuestionsType = typeof useAdminResearchQuestions
export type UseAdminResearchQuestionsResult =
  ReturnType<UseAdminResearchQuestionsType>
// export type UseAdminResearchQuestionsParameter = Parameters<UseAdminResearchQuestionsType>[0]
