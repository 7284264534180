import { usePhishingResultCorrectAnswerRateQuery } from '@src/graphql/generated/graphql'

export const usePhishingResultCorrectAnswerRate = (
  baseOptions: Partial<
    Parameters<typeof usePhishingResultCorrectAnswerRateQuery>[0]
  > = {}
) => {
  return usePhishingResultCorrectAnswerRateQuery(baseOptions)
}

type usePhishingResultCorrectAnswerRateType =
  typeof usePhishingResultCorrectAnswerRate

export type usePhishingResultCorrectAnswerRateResult =
  ReturnType<usePhishingResultCorrectAnswerRateType>
export type usePhishingResultCorrectAnswerRateParameter =
  Parameters<usePhishingResultCorrectAnswerRateType>[0]
