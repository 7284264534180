import { Box, Grid, Heading, Image, Spinner } from '@chakra-ui/react'
import { CsbHeader } from '@src/components'
import { usePreviewComicsHooksResult } from '@src/components/pages/preview/comics/[id]/hooks'
import { memo } from 'react'

//コミックのプレビューページのcomponent
export const PreviewComics = memo(
  ({ data, loading }: usePreviewComicsHooksResult) => {
    return (
      <>
        <CsbHeader />
        <Box maxW={'650px'} mx={'auto'} px={'15px'} py={20}>
          <Grid rowGap={10}>
            {!loading && data ? (
              <Box>
                <Heading mb={3} textAlign={'center'}>
                  {data.comic.name}
                </Heading>
                <Grid rowGap={10}>
                  {data.comic.images.map((image, index) => (
                    <Box key={`${image.uuid}_${index}`}>
                      <Image
                        fallback={
                          <Box
                            alignItems={'center'}
                            bgColor={'rgba(0,0,0,.5)'}
                            d={'flex'}
                            h={150}
                            justifyContent={'center'}
                            w={150}
                          >
                            <Spinner
                              color="blue.500"
                              emptyColor="gray.200"
                              size="xl"
                              speed="0.65s"
                              thickness="4px"
                            />
                          </Box>
                        }
                        src={image.url}
                        w={'full'}
                      />
                    </Box>
                  ))}
                </Grid>
              </Box>
            ) : (
              <>データが存在しません</>
            )}
          </Grid>
        </Box>
      </>
    )
  }
)
