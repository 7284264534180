import { useResearchesQuery } from '@src/graphql/generated/graphql'

export const useResearches = (
  baseOptions: Partial<Parameters<typeof useResearchesQuery>[0]> = {}
) => {
  return useResearchesQuery(baseOptions)
}

type useResearchesType = typeof useResearches
export type useResearchesResult = ReturnType<useResearchesType>
// export type useResearchesParameter = Parameters<useProjectType>[0]
