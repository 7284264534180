import {
  Button,
  forwardRef,
  HStack,
  Input,
  InputGroup,
  InputProps,
  InputRightAddon,
} from '@chakra-ui/react'
import { CsbFormControl } from '@src/components'
import { ComponentProps } from 'react'

type SearchInputPropsType = {
  colorScheme?: 'teal' | 'gray'
  formControlProps?: Omit<ComponentProps<typeof CsbFormControl>, 'children'>
  onReset?: () => void
  onSearch?: () => void
} & Omit<InputProps, 'colorScheme' | 'children'>

export const CsbSearchInput = forwardRef<SearchInputPropsType, 'input'>(
  (
    { colorScheme = 'teal', formControlProps, onReset, onSearch, ...props },
    ref
  ) => {
    return (
      <HStack spacing={2}>
        <CsbFormControl {...formControlProps}>
          <InputGroup>
            <Input
              bg={'white'}
              type="text"
              {...props}
              ref={ref}
              onKeyDown={(e) => {
                e.stopPropagation()
                if (e.key.toLowerCase() !== 'enter') return
                onSearch?.()
              }}
            />
            <InputRightAddon px={0}>
              <Button
                colorScheme={colorScheme}
                fontWeight={'normal'}
                roundedLeft={'none'}
                onClick={onSearch}
              >
                検索
              </Button>
            </InputRightAddon>
          </InputGroup>
        </CsbFormControl>
        <Button colorScheme={'gray'} onClick={onReset}>
          リセット
        </Button>
      </HStack>
    )
  }
)
