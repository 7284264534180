import {
  CreatePhishingEmailInput,
  PhishingResultLayoutTemplate,
} from '@src/graphql/generated/graphql'

export const phishingFormDefaultValues: CreatePhishingEmailInput = {
  attachId: null,
  attachName: '',
  body: '',
  clientMutationId: null,
  comicId: null,
  draft: true,
  fromName: '',
  groupIds: [],
  isArchiveAttach: false,
  isDeliveryContentsOnly: false,
  isDisplayLogoInLearning: true,
  isDisplayLogoInResearch: true,
  isFromAsReply: false,
  isPhishingSiteDirectAccess: false,
  isRiskReplyUser: false,
  isSendAllUsers: false,
  isSendLearningMail: true,
  isTest: false,
  isTrackMailOpen: true,
  learnId: null,
  learningComment: null,
  learningContentComment: null,
  mailFromId: '',
  movieId: null,
  name: '',
  organizationIds: [],
  originalLearningUrl: '',
  pdfLink: '',
  phishingLinkLabel: '',
  phishingResultBody: '',
  phishingResultLayoutTemplate: PhishingResultLayoutTemplate.Default,
  phishingResultSign: '',
  phishingResultTitle: '',
  phishingSiteId: null,
  positionIds: [],
  rating: 3,
  researchId: null,
  reserveAt: null,
  revealingPhishingEmailFromEmail: null,
  revealingPhishingEmailFromName: '情報漏えい防ぐくん',
  revealingPhishingEmailHtml: null,
  revealingPhishingEmailMailFromId: null,
  revealingPhishingEmailSubject: '',
  riskUserEmailHtml: '',
  riskUserEmailSubject: '',
  sendBlockInterval: null,
  sendBlockSize: 1,
  sendEmailPattern: 1,
  subject: '',
}

export const phishingDeliveryContentsOnlyFormDefaultValues: CreatePhishingEmailInput =
  {
    body: `
<p>{{full_name}}様</p>
<p>いつも情報漏えい防ぐくんをご利用頂きありがとうございます。</p>
<p>システム管理者様より、学習コンテンツが配布されました。</p>
<p>{{send_date+7}}までに、学習を完了させてください。</p>
<p>{{link}}</p>
    `,
    clientMutationId: null,
    comicId: null,
    draft: true,
    fromName: '情報漏えい防ぐくん',
    groupIds: [],
    isArchiveAttach: false,
    isDeliveryContentsOnly: false,
    isDisplayLogoInLearning: true,
    isDisplayLogoInResearch: true,
    isFromAsReply: false,
    isPhishingSiteDirectAccess: false,
    isRiskReplyUser: false,
    isSendAllUsers: false,
    isSendLearningMail: true,
    isTest: false,
    isTrackMailOpen: true,
    learnId: null,
    learningComment: null,
    learningContentComment: null,
    mailFromId: '',
    movieId: null,
    name: '',
    organizationIds: [],
    originalLearningUrl: '',
    pdfLink: '',
    phishingLinkLabel: '',
    phishingResultBody: '',
    phishingResultLayoutTemplate: PhishingResultLayoutTemplate.Default,
    phishingResultSign: '',
    phishingResultTitle: '',
    phishingSiteId: null,
    positionIds: [],
    rating: 3,
    researchId: null,
    reserveAt: null,
    revealingPhishingEmailFromEmail: null,
    revealingPhishingEmailFromName: '情報漏えい防ぐくん',
    revealingPhishingEmailHtml: null,
    revealingPhishingEmailMailFromId: null,
    revealingPhishingEmailSubject: '',
    riskUserEmailHtml: '',
    riskUserEmailSubject: '',
    sendEmailPattern: 1,
    subject: '学習コンテンツの配布',
  }
