import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react'
import { CsbPrimaryBox } from '@src/components'
import { CompanyEditForm } from '@src/components/Form/CompanyEditForm'
import { UserEditForm } from '@src/components/Form/user/UserEditForm'
import { useCompaniesNewHooksResult } from '@src/components/pages/companies/new/hooks'
import { Base } from '@src/layouts'
import { memo } from 'react'

//企業作成ページのcomponent
export const CompaniesNew = memo(
  ({
    errors,
    form,
    isCreating,
    isWriteCompany,
    navigate,
    onSubmit,
  }: useCompaniesNewHooksResult) => {
    return (
      <Base
        left={<Heading>企業アカウントの作成</Heading>}
        right={
          <HStack spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧へ戻る
            </Button>
            {isWriteCompany && (
              <Button isLoading={isCreating} onClick={onSubmit}>
                保存
              </Button>
            )}
          </HStack>
        }
      >
        <CsbPrimaryBox pb={'72px'} pt={35} px={45}>
          <VStack align={'start'} spacing={55}>
            <Box as={'form'} w={'full'}>
              <Heading fontSize={24} mb={3}>
                基本情報
              </Heading>
              <Divider />
              <CompanyEditForm errors={errors} form={form} />
              <VStack align={'start'} mt={16}>
                <Heading fontSize={24}>初期ユーザー情報</Heading>
                <Divider />
              </VStack>
              <UserEditForm errors={errors} form={form} type={'company'} />
            </Box>
          </VStack>
        </CsbPrimaryBox>
        <Flex mt={5}>
          <HStack ml={'auto'} spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧へ戻る
            </Button>
            {isWriteCompany && (
              <Button isLoading={isCreating} onClick={onSubmit}>
                保存
              </Button>
            )}
          </HStack>
        </Flex>
      </Base>
    )
  }
)
