import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translation_en from './en.json'
import translation_ja from './ja.json'

const resources = {
  en: {
    translation: translation_en,
  },
  ja: {
    translation: translation_ja,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    lng: 'ja',
    resources,
  })
  .then()

const language = window.navigator.language
i18n.changeLanguage(language).then()
export default i18n
