import { useMeQuery } from '@src/graphql/generated/graphql'
import { meState } from '@src/recoil/meState'
import { useRecoilState } from 'recoil'

//globalで管理しているmeStateのwrapper
export const useMe = () => {
  const [me, setMe] = useRecoilState(meState)
  const { refetch } = useMeQuery({ skip: true })

  //現在のユーザーの情報
  const user = me?.user
  //現在の企業の情報
  const company = me?.company

  //現在のroleのbooleanのオブジェクト
  const role = user?.role

  return {
    company,
    refetch,
    role,
    setMe,
    user,
  }
}
