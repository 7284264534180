import { Button, HStack } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import { ComponentProps } from 'react'

type DeleteModalPropsType = {
  header?: string
  onCancel: () => void
  onDelete: () => void
} & Omit<ComponentProps<typeof CsbModal>, 'children'>

export const CsbDeleteModal = ({
  header,
  onCancel,
  onDelete,
  ...props
}: DeleteModalPropsType) => {
  const handleDelete = () => {
    onDelete()
    props.onClose()
  }

  return (
    <CsbModal {...props}>
      <CsbModalHeader>警告</CsbModalHeader>
      <CsbModalBody mt={10}>{header}を削除しますか？</CsbModalBody>
      <CsbModalFooter mt={7}>
        <HStack spacing={6}>
          <Button colorScheme={'gray'} onClick={onCancel}>
            キャンセル
          </Button>
          <Button colorScheme={'red'} onClick={handleDelete}>
            削除
          </Button>
        </HStack>
      </CsbModalFooter>
    </CsbModal>
  )
}
