import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  CsbFormControl,
  CsbList,
  CsbListItem,
  CsbNextLink,
  CsbPrimaryBox,
} from '@src/components'
import { usePhishingResultHooksResult } from '@src/components/pages/phishing/[id]/result/old/hooks'
import { PieChats } from '@src/components/pages/phishing/[id]/result/old/PieChats'
import { CsbStats } from '@src/components/Stats/CsbStats'
import { Base } from '@src/layouts'
import dayjs from 'dayjs'
import { memo } from 'react'

//フィシイング結果ページのcomponent
export const PhishingResult = memo(
  ({
    data,
    id,
    loading,
    navigate,
    onExportDownload,
  }: usePhishingResultHooksResult) => {
    return (
      <Base
        left={
          <Box>
            <Heading>フィッシング結果</Heading>
            <Text color={'gray.500'} fontSize={'xs'} fontWeight={'medium'}>
              {!loading &&
                data &&
                `${dayjs(data.phishingEmail.sentAt).format(
                  'YYYY/MM/DD HH:mm'
                )}に送信済み`}
            </Text>
          </Box>
        }
        right={
          <HStack spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧に戻る
            </Button>
            <Button onClick={onExportDownload}>エクスポート</Button>
            <CsbNextLink href={`/phishing/new?reuseId=${id}`}>
              <Button as={'div'}>複製</Button>
            </CsbNextLink>
          </HStack>
        }
      >
        <Grid gap={10} mt={70}>
          <CsbPrimaryBox
            display={'grid'}
            gridGap={'48px 24px'}
            gridTemplateColumns={'repeat(4,1fr)'}
            pb={9}
            pt={22}
            px={10}
            rounded={'md'}
          >
            <CsbStats
              label={'配信数'}
              loading={loading}
              unit={'通'}
              value={data?.phishingResultDelivery.deliveryCount}
            />
            {data && <PieChats data={data.phishingResultDelivery} />}
          </CsbPrimaryBox>
          <CsbPrimaryBox
            display={'grid'}
            gridGap={'48px 24px'}
            gridTemplateColumns={'repeat(4,1fr)'}
            pb={9}
            pt={22}
            px={10}
            rounded={'md'}
          >
            <CsbStats
              hint={`(${data?.phishingResultMailOpen.targetCount} / ${data?.phishingResultMailOpen.deliveryCount}通)`}
              label={'メール開封率'}
              loading={loading}
              unit={'%'}
              value={data?.phishingResultMailOpen.targetRate}
            />
            {data && <PieChats data={data.phishingResultMailOpen} />}
          </CsbPrimaryBox>
          <CsbPrimaryBox
            display={'grid'}
            gridGap={'48px 24px'}
            gridTemplateColumns={'repeat(4,1fr)'}
            pb={9}
            pt={22}
            px={10}
            rounded={'md'}
          >
            <CsbStats
              hint={`(${data?.phishingResultWebAccess.targetCount} / ${data?.phishingResultWebAccess.deliveryCount}通)`}
              label={'サイトアクセス率'}
              loading={loading}
              unit={'%'}
              value={data?.phishingResultWebAccess.targetRate}
            />
            {data && <PieChats data={data.phishingResultWebAccess} />}
          </CsbPrimaryBox>
          <CsbPrimaryBox
            display={'grid'}
            gridGap={'48px 24px'}
            gridTemplateColumns={'repeat(4,1fr)'}
            pb={9}
            pt={22}
            px={10}
            rounded={'md'}
          >
            <CsbStats
              hint={`(${data?.phishingResultLogin.targetCount} / ${data?.phishingResultLogin.deliveryCount}通)`}
              label={'サイトログイン率'}
              loading={loading}
              unit={'%'}
              value={data?.phishingResultLogin.targetRate}
            />
            {data && <PieChats data={data.phishingResultLogin} />}
          </CsbPrimaryBox>
          <CsbPrimaryBox
            display={'grid'}
            gridGap={'48px 24px'}
            gridTemplateColumns={'repeat(4,1fr)'}
            pb={9}
            pt={22}
            px={10}
            rounded={'md'}
          >
            <CsbStats
              hint={`(${data?.phishingResultLearningAccess.targetCount} / ${data?.phishingResultLearningAccess.deliveryCount}通)`}
              label={'ラーニングアクセス'}
              loading={loading}
              unit={'%'}
              value={data?.phishingResultLearningAccess.targetRate}
            />
            {data && <PieChats data={data.phishingResultLearningAccess} />}
          </CsbPrimaryBox>
          <CsbPrimaryBox
            display={'grid'}
            gridGap={'48px 24px'}
            gridTemplateColumns={'repeat(4,1fr)'}
            pb={9}
            pt={22}
            px={10}
            rounded={'md'}
          >
            <CsbStats
              hint={`(${data?.phishingResultLearningAnswer.targetCount} / ${data?.phishingResultLearningAnswer.deliveryCount}通)`}
              label={'ラーニング回答'}
              loading={loading}
              unit={'%'}
              value={data?.phishingResultLearningAnswer.targetRate}
            />
            {data && <PieChats data={data.phishingResultLearningAnswer} />}
          </CsbPrimaryBox>
          <Grid gridGap={'48px 24px'} gridTemplateColumns={'repeat(4,1fr)'}>
            <CsbPrimaryBox pb={9} pt={22} px={10} rounded={'md'}>
              <CsbStats
                hint={`(${data?.phishingResultLearningCorrect.targetCount}問)`}
                label={'ラーニング正答率'}
                loading={loading}
                unit={'%'}
                value={data?.phishingResultLearningCorrect.targetRate}
              />
            </CsbPrimaryBox>
          </Grid>
        </Grid>
        <Box mt={16}>
          <Heading>詳細</Heading>
          <CsbList as={'dl'} mt={5}>
            <CsbListItem
              as={'dt'}
              display={'grid'}
              gridTemplateColumns={'1fr 1fr 1fr 1fr'}
              minH={'52px'}
              px={0}
            >
              <Text fontSize={'xs'} fontWeight={'bold'} px={6}>
                年齢
              </Text>
              <Text fontSize={'xs'} fontWeight={'bold'} px={6}>
                開封率
              </Text>
              <Text fontSize={'xs'} fontWeight={'bold'} px={6}>
                クリック率
              </Text>
              <Text fontSize={'xs'} fontWeight={'bold'} px={6}>
                ログイン率
              </Text>
            </CsbListItem>
            {data?.phishingResultGenerations.map((item, index, array) => (
              <CsbListItem
                as={'dd'}
                display={'grid'}
                divider={array.length - 1 !== index}
                gridTemplateColumns={'1fr 1fr 1fr 1fr'}
                key={`${item.generation}_${index}`}
                minH={'52px'}
                px={0}
              >
                <Text fontSize={'sm'} px={6}>
                  {item.generation}代
                </Text>
                <Text fontSize={'sm'} px={6}>
                  {item.mailOpenRate}% ({item.mailOpenCount}/
                  {item.mailDeliveryCount}件)
                </Text>
                <Text fontSize={'sm'} px={6}>
                  {item.mailClickRate}% ({item.mailClickRate}/
                  {item.mailDeliveryCount}件)
                </Text>
                <Text fontSize={'sm'} px={6}>
                  {item.webLoginRate}% ({item.webLoginCount}/
                  {item.mailDeliveryCount}件)
                </Text>
              </CsbListItem>
            ))}
          </CsbList>
        </Box>
        <Box mt={16}>
          <Heading>メールの内容</Heading>
          <CsbPrimaryBox mt={5} px={10} py={'30px'}>
            <VStack align={'start'} maxW={458} spacing={5} w={'full'}>
              <CsbFormControl labelText={'管理用ラベル'} w={605}>
                <CsbPrimaryBox
                  cursor={'not-allowed'}
                  mt={2}
                  opacity={0.4}
                  px={4}
                  py={2}
                  rounded={'md'}
                >
                  {data?.phishingEmail.name}
                </CsbPrimaryBox>
              </CsbFormControl>
              <CsbFormControl labelText={'送信元メールアドレス'} w={605}>
                <CsbPrimaryBox
                  cursor={'not-allowed'}
                  mt={2}
                  opacity={0.4}
                  px={4}
                  py={2}
                  rounded={'md'}
                >
                  {data?.phishingEmail.mailFrom.email}
                </CsbPrimaryBox>
              </CsbFormControl>
              <CsbFormControl labelText={'件名'} w={605}>
                <CsbPrimaryBox
                  cursor={'not-allowed'}
                  mt={2}
                  opacity={0.4}
                  px={4}
                  py={2}
                  rounded={'md'}
                >
                  {data?.phishingEmail.subject}
                </CsbPrimaryBox>
              </CsbFormControl>
              <CsbFormControl labelText={'本文'} w={605}>
                <CsbPrimaryBox
                  cursor={'not-allowed'}
                  dangerouslySetInnerHTML={{
                    __html: data?.phishingEmail.body || '',
                  }}
                  mt={2}
                  opacity={0.4}
                  px={4}
                  py={2}
                  rounded={'md'}
                />
              </CsbFormControl>
            </VStack>
          </CsbPrimaryBox>
        </Box>
        <Flex mt={5}>
          <HStack ml={'auto'} spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧に戻る
            </Button>
            <Button onClick={onExportDownload}>エクスポート</Button>
            <CsbNextLink href={`/phishing/new?reuseId=${id}`}>
              <Button as={'div'}>複製</Button>
            </CsbNextLink>
          </HStack>
        </Flex>
      </Base>
    )
  }
)
