import { Input, Textarea } from '@chakra-ui/react'
import { CsbFormControl } from '@src/components'
import {
  useCopyResearchResult,
  useCreateResearchResult,
  useUpdateResearchResult,
} from '@src/models/Research'
import dayjs from 'dayjs'

export const ResearchForm = ({
  findErrorMessage,
  form,
}:
  | Pick<useCreateResearchResult, 'form' | 'findErrorMessage'>
  | Pick<useUpdateResearchResult, 'form' | 'findErrorMessage'>
  | Pick<useCopyResearchResult, 'form' | 'findErrorMessage'>) => {
  return (
    <>
      <CsbFormControl errorText={findErrorMessage('name')}>
        <Input {...form.register('name')} placeholder={'アンケートの名前*'} />
      </CsbFormControl>
      <CsbFormControl errorText={findErrorMessage('description')}>
        <Textarea
          {...form.register('description')}
          placeholder={'アンケートの説明*'}
        />
      </CsbFormControl>
      <CsbFormControl
        errorText={findErrorMessage('closedAt')}
        labelText={'有効期限'}
      >
        <Input
          w={300}
          {...form.register('closedAt', {
            setValueAs: (value) => {
              return value === '' ? null : dayjs(value).format('YYYY-MM-DD')
            },
          })}
          type={'date'}
        />
      </CsbFormControl>

      {/*<CsbFormControl errorText={findErrorMessage('answerType')}>*/}
      {/*  <Input*/}
      {/*    type={'number'}*/}
      {/*    {...form.register('answerType')}*/}
      {/*    placeholder={'アンケートの種類'}*/}
      {/*  />*/}
      {/*</CsbFormControl>*/}
    </>
  )
}
