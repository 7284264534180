import { useDisclosure } from '@chakra-ui/react'
import { BaseResponseError } from '@src/errors/BaseResponseError'
import {
  CreateRevealingPhishingEmailScheduleInput,
  RevealingRepeat,
  RevealingSend,
  RevealingTarget,
  UpdateRevealingPhishingEmailScheduleInput,
  useCreateRevealingPhishingEmailScheduleMutation,
  useDestroyRevealingPhishingEmailScheduleMutation,
  useRevealingEmailTargetsQuery,
  useRevealingPhishingEmailScheduleLogsQuery,
  useRevealingPhishingEmailScheduleQuery,
  useSettingsQuery,
  useUpdateRevealingPhishingEmailScheduleMutation,
} from '@src/graphql/generated/graphql'
import { useErrors, useMe, useToast } from '@src/hooks'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

export const useSendRevealingScheduleHooks = () => {
  const { id } = useParams<{ id: string }>()
  const { isDeliveryContentsOnly, resolveDeliveryContentsUrl } =
    useDeliveryContents()
  const { role } = useMe()
  const isWritePhishingMail = !!role?.writePhishingMail
  const toast = useToast()
  const navigate = useNavigate()
  const sendRevealingModal = useDisclosure()
  const revealingPhishingEmailModal = useDisclosure()

  const { data: settingData, loading: isSettingLoading } = useSettingsQuery({})

  const form = useForm<CreateRevealingPhishingEmailScheduleInput>({
    defaultValues: {
      isSchedule: false,
      phishingEmailUuid: id ?? '',
      repeatCount: 1,
      repeatInterval: 1,
      repeatType: RevealingRepeat.None,
      sendRevealingMailAt: '15:30',
      sendRevealingMailDays: 5,
      sendTargetType: RevealingTarget.All,
      sendType: RevealingSend.DaysAfterRevealing,
    },
  })

  const [errors, setErrors] = useErrors()

  const [createRevealingPhishingEmailSchedule, { loading: isCreating }] =
    useCreateRevealingPhishingEmailScheduleMutation()
  const [updateRevealingPhishingEmailSchedule, { loading: isUpdating }] =
    useUpdateRevealingPhishingEmailScheduleMutation()
  const [destroyRevealingPhishingEmailSchedule, { loading: isDestroying }] =
    useDestroyRevealingPhishingEmailScheduleMutation()
  const { data, loading: isLoading } = useRevealingPhishingEmailScheduleQuery({
    skip: !id,
    variables: {
      isDeliveryContentsOnly: isDeliveryContentsOnly,
      uuid: id ?? '',
    },
  })

  const { data: logs, loading: isLogLoading } =
    useRevealingPhishingEmailScheduleLogsQuery({
      skip: !id,
      variables: { uuid: id ?? '' },
    })
  const {
    data: targetsData,
    loading: isTargetsLoading,
    refetch: refetchRevealingEmailTargets,
  } = useRevealingEmailTargetsQuery({
    skip: !id,
    variables: {
      pagination: {
        limit: 100,
        page: 1,
      },
      target: form.getValues('sendTargetType') ?? RevealingTarget.All,
      uuid: id ?? '',
    },
  })

  const getTime = (time: string) => {
    const [hour, minute] = time.split(':')
    const h = Number(hour.includes('T') ? hour.split('T')[1] : hour)
    const m = Number(minute)
    if (isNaN(h) || isNaN(m)) return null
    return dayjs().hour(h).minute(m).format()
  }
  const title = !isDeliveryContentsOnly ? '種明かしメール' : 'リマインドメール'

  const onDestroy = async () => {
    try {
      await destroyRevealingPhishingEmailSchedule({
        variables: {
          input: {
            uuid: id ?? '',
          },
        },
      })
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'success',
        title: `${title}のスケジュールを削除しました。`,
      })
      navigate(resolveDeliveryContentsUrl(`/phishing/${id ?? ''}/result`))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setErrors(e.graphQLErrors)
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: `${title}のスケジュールの削除に失敗しました。`,
      })
    }
  }

  const onSubmit = form.handleSubmit(async ({ ...inputData }) => {
    try {
      const dates =
        inputData.dates?.filter((date) => !!date).map((date) => dayjs(date)) ??
        []
      const firstScheduleAt = inputData.firstScheduleAt
        ? dayjs(inputData.firstScheduleAt ?? '')
        : null
      const sendRevealingMailAt = inputData.sendRevealingMailAt
        ? getTime(inputData.sendRevealingMailAt)
        : null
      const repeatBaseDatetime = inputData.repeatBaseDatetime
        ? dayjs(inputData.repeatBaseDatetime ?? '')
        : null

      if (data?.revealingPhishingEmailSchedule) {
        const updateData = Object.fromEntries(
          Object.entries(inputData).filter(
            ([key]) => key !== 'phishingEmailUuid'
          )
        ) as UpdateRevealingPhishingEmailScheduleInput

        await updateRevealingPhishingEmailSchedule({
          variables: {
            input: {
              ...updateData,
              dates: dates,
              firstScheduleAt: firstScheduleAt,
              repeatBaseDatetime: repeatBaseDatetime,
              sendRevealingMailAt: sendRevealingMailAt,
              uuid: id ?? '',
            },
          },
        })
      } else {
        await createRevealingPhishingEmailSchedule({
          variables: {
            input: {
              ...inputData,
              dates: dates,
              firstScheduleAt: firstScheduleAt,
              repeatBaseDatetime: repeatBaseDatetime,
              sendRevealingMailAt: sendRevealingMailAt,
            },
          },
        })
      }

      setErrors([])
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'success',
        title: `${title}のスケジュールを設定しました。`,
      })
      navigate(
        resolveDeliveryContentsUrl(
          `/phishing/${id ?? ''}/result/send/revealing/schedule`
        )
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setErrors(e.graphQLErrors)
      const firstSendError = e.graphQLErrors.find(
        (error: BaseResponseError) =>
          error.extensions.attribute === 'change_first_send'
      )

      const message = firstSendError
        ? firstSendError.message
        : `${title}のスケジュールの設定に失敗しました。`

      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: message,
      })
    }
  })

  const loading =
    isLoading ||
    isLogLoading ||
    isTargetsLoading ||
    isSettingLoading ||
    isCreating ||
    isUpdating ||
    isDestroying

  return {
    data,
    errors,
    form,
    id,
    isDeliveryContentsOnly,
    isWritePhishingMail,
    loading,
    logs,
    navigate,
    onDestroy,
    onSubmit,
    refetchRevealingEmailTargets,
    resolveDeliveryContentsUrl,
    revealingPhishingEmailModal,
    sendRevealingModal,
    settingData,
    targetsData,
  }
}

export type useSendRevealingScheduleHooksResult = ReturnType<
  typeof useSendRevealingScheduleHooks
>
