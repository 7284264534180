import { LearnDetail } from '@src/components/pages/learn/[id]'
import { useLearnDetailHooks } from '@src/components/pages/learn/[id]/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const LearnDetailPage = memo(() => {
  const learnDetailHooks = useLearnDetailHooks()
  return (
    <Authorization>
      <LearnDetail {...learnDetailHooks} />
    </Authorization>
  )
})
