import { useDisclosure } from '@chakra-ui/react'
import { useCreateResearch } from '@src/models/Research'
import { useCallback } from 'react'

export const useCreateResearchModal = () => {
  const { isOpen, onClose: _onClose, onOpen } = useDisclosure()
  const { findErrorMessage, form, isLoading, onCreateResearch, resetForm } =
    useCreateResearch({
      onCompleted: () => {
        _onClose()
      },
    })
  const onClose = useCallback(() => {
    resetForm()
    _onClose()
  }, [_onClose, resetForm])

  return {
    findErrorMessage,
    form,
    isLoading,
    isOpen,
    onClose,
    onCreateResearch,
    onOpen,
  }
}

type useCreateResearchModalType = typeof useCreateResearchModal
export type UseCreateResearchModalResult =
  ReturnType<useCreateResearchModalType>
// export type useCreateResearchModalParameter = Parameters<useCreateResearchModalType>[0]
