import { useResearchQuestionsQuery } from '@src/graphql/generated/graphql'

export const useResearchQuestions = (
  baseOptions: Partial<Parameters<typeof useResearchQuestionsQuery>[0]> = {}
) => {
  return useResearchQuestionsQuery(baseOptions)
}

type UseResearchQuestionsType = typeof useResearchQuestions
export type UseResearchQuestionsResult = ReturnType<UseResearchQuestionsType>
// export type UseResearchQuestionsParameter = Parameters<UseResearchQuestionsType>[0]
