import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  List,
  ListItem,
  Progress,
  Text,
} from '@chakra-ui/react'
import { CsbPrimaryBox } from '@src/components'
import { CsbNewLineText } from '@src/components/CsbNewLineText'
import { useQuestionnaireResultHooksResult } from '@src/components/pages/questionnaires/[id]/result/hooks'
import { Base } from '@src/layouts'

export const QuestionnaireResult = ({
  choiceData,
  id,
  loading,
  navigate,
  questionnaireData,
  textData,
}: useQuestionnaireResultHooksResult) => {
  if (loading) {
    return <></>
  }
  return (
    <Base
      left={<Heading>アンケート結果</Heading>}
      right={
        <HStack spacing={3}>
          <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
            一覧に戻る
          </Button>
          <Button
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_BACKEND_URL}/export/questionnaire.xlsx?uuid=${id}`,
                '_blank'
              )
            }}
          >
            エクスポート
          </Button>
        </HStack>
      }
    >
      <Heading fontSize={16}>
        {questionnaireData?.questionnaire.research.name ?? ''}
      </Heading>

      {!loading && (choiceData || textData) ? (
        <CsbPrimaryBox mt={35} pb={'72px'} pt={35} px={45}>
          {choiceData?.questionnaireResult.map((n, index) => {
            return (
              <Box key={index}>
                <Box>
                  <Box mb={4} width={'600px'}>
                    <Text fontWeight={'bold'}>
                      <CsbNewLineText text={n.name} />
                    </Text>
                  </Box>
                  <Box pl={4}>
                    {n.answerQuestionnaires.map((answer, answerIndex) => {
                      return (
                        <Flex key={answerIndex} mb={2}>
                          <Text width={'300px'}>{answer.title}</Text>
                          <Progress
                            mr={30}
                            value={answer.rate * 100}
                            width={'300px'}
                          />
                          <Text>
                            {Math.round(answer.rate * 10000) / 100}% (
                            {answer.count}
                            件)
                          </Text>
                        </Flex>
                      )
                    })}
                  </Box>
                </Box>
                <Divider my={'16px'} />
              </Box>
            )
          })}

          {textData?.questionnaireResultText.map((n, index) => {
            return (
              <Box key={index}>
                <Box>
                  <Box mb={4} width={'600px'}>
                    <Text fontWeight={'bold'}>{n.name}</Text>
                  </Box>
                  <List spacing={3} width={'600px'}>
                    {n.answerQuestionnaires.length > 0 ? (
                      n.answerQuestionnaires.map((answer, answerIndex) => {
                        return (
                          <Box key={answerIndex}>
                            <ListItem pl={4}>
                              <CsbNewLineText text={answer} />
                            </ListItem>
                            <Divider my={'16px'} />
                          </Box>
                        )
                      })
                    ) : (
                      <ListItem pl={4}>データがありません</ListItem>
                    )}
                  </List>
                </Box>
              </Box>
            )
          })}
        </CsbPrimaryBox>
      ) : (
        <>データが存在しません。</>
      )}

      <Flex mt={5}>
        <HStack ml={'auto'} spacing={3}>
          <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
            一覧に戻る
          </Button>
          <Button
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_BACKEND_URL}/export/questionnaire.xlsx?uuid=${id}`,
                '_blank'
              )
            }}
          >
            エクスポート
          </Button>
        </HStack>
      </Flex>
    </Base>
  )
}
