import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react'
import { CsbPrimaryBox } from '@src/components'
import { UserEditForm } from '@src/components/Form/user/UserEditForm'
import { useUsersEditHooksResult } from '@src/components/pages/users/[id]/edit/hooks'
import { Base } from '@src/layouts'
import { memo } from 'react'

//ユーザー編集ページのcomponent
export const UsersEdit = memo(
  ({
    data,
    errors,
    form,
    isUpdating,
    isWriteUser,
    loading,
    navigate,
    onSubmit,
  }: useUsersEditHooksResult) => {
    return (
      <Base
        left={<Heading>ユーザーの編集</Heading>}
        right={
          <HStack spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧に戻る
            </Button>
            {isWriteUser && (
              <Button isLoading={isUpdating} onClick={onSubmit}>
                保存
              </Button>
            )}
          </HStack>
        }
      >
        <Box mt={45}>
          <CsbPrimaryBox pb={'72px'} pt={35} px={45}>
            {data && !loading && (
              <Box as={'form'} w={'full'}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>基本情報</Heading>
                  <Divider />
                </VStack>
                <UserEditForm errors={errors} form={form} isNew={false} />
              </Box>
            )}
          </CsbPrimaryBox>
        </Box>
        <Flex mt={5}>
          <HStack ml={'auto'} spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧に戻る
            </Button>
            {isWriteUser && (
              <Button isLoading={isUpdating} onClick={onSubmit}>
                保存
              </Button>
            )}
          </HStack>
        </Flex>
      </Base>
    )
  }
)
