import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Heading,
  HStack,
  Input,
  Skeleton,
  VStack,
} from '@chakra-ui/react'
import { CsbEditor, CsbFormControl, CsbPrimaryBox } from '@src/components'
import { RevealingPhishingForm } from '@src/components/Form/RevealingPhishingForm'
import { PhishingEmailSchedules } from '@src/components/pages/phishing/[id]/PhishingEmailSchedules'
import { useMailDetailResult } from '@src/components/pages/phishing/[id]/result/components/MailDetail/useMailDetail'
import { CompanyAttachFileKind } from '@src/graphql/generated/graphql'
import { useMe } from '@src/hooks'
import { findError } from '@src/utils/findError'
import { ReactNode } from 'react'
import { Controller } from 'react-hook-form'
import { FaStar } from 'react-icons/fa'
import Rating from 'react-rating'

type HideDeliveryContentsOnlyProps = {
  children: ReactNode
  isDeliveryContentsOnly: boolean
}

const HideDeliveryContentsOnly = ({
  children,
  isDeliveryContentsOnly,
}: HideDeliveryContentsOnlyProps) => {
  if (isDeliveryContentsOnly) {
    return null
  }
  return <>{children}</>
}

export const MailDetail = ({
  data,
  errors,
  isDeliveryContentsOnly,
  isRemindUpdating,
  isRevealingUpdating,
  loading,
  mailFroms,
  onRemindSubmit,
  onRevealingSubmit,
  remindForm,
  revealingForm,
  setting,
}: useMailDetailResult) => {
  const { role } = useMe()
  const isWritePhishingMail = !!role?.writePhishingMail

  const organizationNames =
    data?.phishingEmail.organizations.map((n) => n.name) ?? []
  const positionNames = data?.phishingEmail.positions.map((n) => n.name) ?? []
  const groupsNames = data?.phishingEmail.groups.map((n) => n.name) ?? []

  const getCompanyAttachFileName = (
    fileKind?: CompanyAttachFileKind,
    fileName?: string
  ) => {
    if (!fileName) {
      return '未選択'
    }

    const fileKindName = (() => {
      switch (fileKind) {
        case CompanyAttachFileKind.Xlsm:
          return 'マクロ付きExcelファイル'
        case CompanyAttachFileKind.Docm:
          return 'マクロ付きWordファイル'
        case CompanyAttachFileKind.Ppsx:
          return '外部アクセスPowerPointファイル'
        case CompanyAttachFileKind.Pdf:
          return '外部アクセスPDFファイル'
      }
    })()
    return `${fileKindName}（${fileName}）`
  }

  const removeStyleTag = (html: string) =>
    html.replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, '')

  return (
    <Flex flexDir={'column'} mt={16}>
      <Heading>メールの内容</Heading>
      <CsbPrimaryBox mt={5} px={10} py={'30px'}>
        <VStack align={'start'} maxW={605} spacing={5} w={'full'}>
          <CsbFormControl labelText={'管理用ラベル'}>
            {loading ? (
              <Skeleton h={42} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {data?.phishingEmail.name}
              </CsbPrimaryBox>
            )}
          </CsbFormControl>
          <HideDeliveryContentsOnly
            isDeliveryContentsOnly={isDeliveryContentsOnly}
          >
            <CsbFormControl labelText={'難易度'}>
              {loading ? (
                <Skeleton h={38} mt={2} w={160} />
              ) : (
                <Box cursor={'not-allowed'}>
                  <Rating
                    readonly
                    emptySymbol={
                      <FaStar fill={'rgb(230,230,230)'} fontSize={'32px'} />
                    }
                    fractions={2}
                    fullSymbol={<FaStar fill={'orange'} fontSize={'32px'} />}
                    initialRating={data?.phishingEmail.rating ?? 3}
                  />
                </Box>
              )}
            </CsbFormControl>
          </HideDeliveryContentsOnly>
          <HStack spacing={2} w={'full'}>
            <CsbFormControl labelText={'差出人名'}>
              {loading ? (
                <Skeleton h={42} mt={2} />
              ) : (
                <CsbPrimaryBox
                  cursor={'not-allowed'}
                  mt={2}
                  opacity={0.4}
                  px={4}
                  py={2}
                  rounded={'md'}
                >
                  {data?.phishingEmail.fromName}
                </CsbPrimaryBox>
              )}
            </CsbFormControl>
            <CsbFormControl labelText={'送信元メールアドレス'}>
              {loading ? (
                <Skeleton h={42} mt={2} />
              ) : (
                <CsbPrimaryBox
                  cursor={'not-allowed'}
                  mt={2}
                  opacity={0.4}
                  px={4}
                  py={2}
                  rounded={'md'}
                >
                  {data?.phishingEmail.mailFrom.email}
                </CsbPrimaryBox>
              )}
            </CsbFormControl>
          </HStack>
          <HideDeliveryContentsOnly
            isDeliveryContentsOnly={isDeliveryContentsOnly}
          >
            <CsbFormControl labelText={'フィッシングサイト'}>
              {loading ? (
                <Skeleton h={42} mt={2} />
              ) : (
                <CsbPrimaryBox
                  cursor={'not-allowed'}
                  mt={2}
                  opacity={0.4}
                  px={4}
                  py={2}
                  rounded={'md'}
                >
                  {data?.phishingEmail.phishingSite?.name}
                </CsbPrimaryBox>
              )}
            </CsbFormControl>
          </HideDeliveryContentsOnly>
          <CsbFormControl>
            {loading ? (
              <Skeleton h={'24px'} mt={2} />
            ) : (
              <Checkbox isDisabled isChecked={!!data?.phishingEmail.isTest}>
                テストとして送信する
              </Checkbox>
            )}
          </CsbFormControl>
          <CsbFormControl>
            {loading ? (
              <Skeleton h={'24px'} mt={2} />
            ) : (
              <Checkbox
                isDisabled
                isChecked={!!data?.phishingEmail.isSendAllUsers}
              >
                全員にメールを送信する
              </Checkbox>
            )}
          </CsbFormControl>
          <CsbFormControl labelText={'組織'}>
            {loading ? (
              <Skeleton h={42} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {organizationNames.length > 0
                  ? organizationNames.join(', ')
                  : '未設定'}
              </CsbPrimaryBox>
            )}
          </CsbFormControl>
          <CsbFormControl labelText={'役職'}>
            {loading ? (
              <Skeleton h={42} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {positionNames.length > 0 ? positionNames.join(', ') : '未設定'}
              </CsbPrimaryBox>
            )}
          </CsbFormControl>
          <CsbFormControl labelText={'グループ'}>
            {loading ? (
              <Skeleton h={42} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {groupsNames.length > 0 ? groupsNames.join(', ') : '未設定'}
              </CsbPrimaryBox>
            )}
          </CsbFormControl>
          <CsbFormControl labelText={'件名'}>
            {loading ? (
              <Skeleton h={42} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {data?.phishingEmail.subject}
              </CsbPrimaryBox>
            )}
          </CsbFormControl>
          <CsbFormControl labelText={'メールフォーマット'}>
            {loading ? (
              <Skeleton h={42} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {data?.phishingEmail.sendEmailPattern === 1
                  ? 'HTML形式'
                  : 'テキスト形式'}
              </CsbPrimaryBox>
            )}
          </CsbFormControl>
          <CsbFormControl
            labelText={
              isDeliveryContentsOnly
                ? 'コンテンツリンクテキスト'
                : 'フィッシングリンクテキスト'
            }
          >
            {loading ? (
              <Skeleton h={42} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {data?.phishingEmail.phishingLinkLabel}
              </CsbPrimaryBox>
            )}
          </CsbFormControl>
          <CsbFormControl labelText={'本文'} w={605}>
            {loading ? (
              <Skeleton h={250} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                dangerouslySetInnerHTML={{
                  __html: removeStyleTag(data?.phishingEmail.body || ''),
                }}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              />
            )}
          </CsbFormControl>
          <CsbFormControl labelText={'添付するファイル'}>
            {loading ? (
              <Skeleton h={42} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {data?.phishingEmail.attach?.name
                  ? data?.phishingEmail.attach?.name
                  : getCompanyAttachFileName(
                      data?.phishingEmail.companyAttach?.fileKind,
                      data?.phishingEmail.companyAttach?.fileName
                    )}
              </CsbPrimaryBox>
            )}
          </CsbFormControl>
          <CsbFormControl labelText={'添付ファイル名'}>
            {loading ? (
              <Skeleton h={42} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {data?.phishingEmail.attachName ?? '未選択'}
              </CsbPrimaryBox>
            )}
          </CsbFormControl>
          <CsbFormControl labelText={'添付ファイル・圧縮'}>
            {loading ? (
              <Skeleton h={42} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {data?.phishingEmail.isArchiveAttach
                  ? '圧縮する'
                  : '圧縮しない'}
              </CsbPrimaryBox>
            )}
          </CsbFormControl>
          <CsbFormControl labelText={'添付ファイル・ZIPファイル名'}>
            {loading ? (
              <Skeleton h={42} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {data?.phishingEmail.archiveAttachName ?? ''}
              </CsbPrimaryBox>
            )}
          </CsbFormControl>

          <CsbFormControl labelText={'添付ファイル・パスワード設定'}>
            {loading ? (
              <Skeleton h={42} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {data?.phishingEmail.isArchiveAttachPassword
                  ? '設定する'
                  : '設定しない'}
              </CsbPrimaryBox>
            )}
          </CsbFormControl>
          {data?.phishingEmail.isArchiveAttachPassword && (
            <>
              <CsbFormControl labelText={'添付ファイル・パスワード'}>
                {loading ? (
                  <Skeleton h={42} mt={2} />
                ) : (
                  <CsbPrimaryBox
                    cursor={'not-allowed'}
                    mt={2}
                    opacity={0.4}
                    px={4}
                    py={2}
                    rounded={'md'}
                  >
                    {data?.phishingEmail.archiveAttachPassword}
                  </CsbPrimaryBox>
                )}
              </CsbFormControl>
              <CsbFormControl labelText={'添付ファイル・パスワード通知'}>
                {loading ? (
                  <Skeleton h={42} mt={2} />
                ) : (
                  <CsbPrimaryBox
                    cursor={'not-allowed'}
                    mt={2}
                    opacity={0.4}
                    px={4}
                    py={2}
                    rounded={'md'}
                  >
                    {data?.phishingEmail.isCustomNotifyPassword
                      ? '設定する'
                      : '設定しない'}
                  </CsbPrimaryBox>
                )}
              </CsbFormControl>
              {data?.phishingEmail.isCustomNotifyPassword && (
                <>
                  <CsbFormControl
                    labelText={'添付ファイル・パスワード通知メールの件名'}
                  >
                    {loading ? (
                      <Skeleton h={42} mt={2} />
                    ) : (
                      <CsbPrimaryBox
                        cursor={'not-allowed'}
                        mt={2}
                        opacity={0.4}
                        px={4}
                        py={2}
                        rounded={'md'}
                      >
                        {data?.phishingEmail.notifyArchivePasswordSubject}
                      </CsbPrimaryBox>
                    )}
                  </CsbFormControl>
                  <CsbFormControl
                    labelText={'添付ファイル・パスワード通知メールの本文'}
                  >
                    {loading ? (
                      <Skeleton h={42} mt={2} />
                    ) : (
                      <CsbPrimaryBox
                        cursor={'not-allowed'}
                        dangerouslySetInnerHTML={{
                          __html: removeStyleTag(
                            data?.phishingEmail.notifyArchivePasswordHtml || ''
                          ),
                        }}
                        mt={2}
                        opacity={0.4}
                        px={4}
                        py={2}
                        rounded={'md'}
                      />
                    )}
                  </CsbFormControl>
                </>
              )}
            </>
          )}
        </VStack>

        <VStack align={'start'} maxW={600} mt={10} spacing={5}>
          <VStack align={'start'} w={'full'}>
            <Heading fontSize={24}>学習コンテンツ</Heading>
            <Divider />
          </VStack>
          <CsbFormControl labelText={'動画'}>
            {loading ? (
              <Skeleton h={42} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {data?.phishingEmail.movie?.name ?? '未選択'}
              </CsbPrimaryBox>
            )}
          </CsbFormControl>
          <CsbFormControl labelText={'コミック'}>
            {loading ? (
              <Skeleton h={42} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {data?.phishingEmail.comic?.name ?? '未選択'}
              </CsbPrimaryBox>
            )}
          </CsbFormControl>
          <CsbFormControl labelText={'学習コンテンツ'}>
            {loading ? (
              <Skeleton h={42} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {data?.phishingEmail.learn?.name ?? '未選択'}
              </CsbPrimaryBox>
            )}
          </CsbFormControl>
          <HideDeliveryContentsOnly
            isDeliveryContentsOnly={isDeliveryContentsOnly}
          >
            <CsbFormControl labelText={'種明かしメール'}>
              {loading ? (
                <Skeleton h={42} mt={2} />
              ) : (
                <CsbPrimaryBox
                  cursor={'not-allowed'}
                  mt={2}
                  opacity={0.4}
                  px={4}
                  py={2}
                  rounded={'md'}
                >
                  {data?.phishingEmail.sendLearningMailDays === -1
                    ? '種明かしメールを送信しない'
                    : data?.phishingEmail.sendLearningMailDays === 0
                    ? '当日'
                    : `${data?.phishingEmail.sendLearningMailDays}日後`}
                </CsbPrimaryBox>
              )}
            </CsbFormControl>
          </HideDeliveryContentsOnly>

          <VStack align={'start'} w={'full'}>
            <Heading fontSize={24}>
              {!isDeliveryContentsOnly ? '種明かしメール' : 'リマインドメール'}
            </Heading>
            <Divider />
          </VStack>

          {loading ? (
            <Skeleton h={42} mt={2} />
          ) : (
            <>
              <RevealingPhishingForm
                errors={errors}
                form={revealingForm}
                isWritePhishingMail={isWritePhishingMail}
                loading={loading}
                mailFroms={mailFroms?.mailFroms.collection ?? []}
              />
              <HStack spacing={3}>
                <Button
                  disabled={isRevealingUpdating || !isWritePhishingMail}
                  onClick={() => {
                    revealingForm.setValue(
                      'revealingPhishingEmailSubject',
                      setting?.setting.revealingPhishingEmailSubject ?? ''
                    )
                    revealingForm.setValue(
                      'revealingPhishingEmailHtml',
                      setting?.setting.revealingPhishingEmailHtml ?? ''
                    )
                  }}
                >
                  デフォルトに戻す
                </Button>
                <Button
                  disabled={isRevealingUpdating || !isWritePhishingMail}
                  onClick={onRevealingSubmit()}
                >
                  更新
                </Button>
              </HStack>
            </>
          )}

          <HideDeliveryContentsOnly
            isDeliveryContentsOnly={isDeliveryContentsOnly}
          >
            <CsbFormControl labelText={'リスクユーザーへのメールの配信'}>
              {loading ? (
                <Skeleton h={'24px'} mt={2} />
              ) : (
                <Checkbox
                  isDisabled
                  isChecked={data?.phishingEmail.isSendLearningMail ?? true}
                >
                  リスクユーザーへメールを配信をする
                </Checkbox>
              )}
            </CsbFormControl>
          </HideDeliveryContentsOnly>
          <HideDeliveryContentsOnly
            isDeliveryContentsOnly={isDeliveryContentsOnly}
          >
            <CsbFormControl>
              {loading ? (
                <Skeleton h={'24px'} mt={2} />
              ) : (
                <Checkbox
                  isDisabled
                  isChecked={
                    data?.phishingEmail.isPhishingSiteDirectAccess ?? false
                  }
                >
                  フィッシングサイトを経由しない
                </Checkbox>
              )}
            </CsbFormControl>
          </HideDeliveryContentsOnly>
          <CsbFormControl labelText={'アンケート'}>
            {loading ? (
              <Skeleton h={42} mt={2} />
            ) : (
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {data?.phishingEmail.research?.name ?? '未選択'}
              </CsbPrimaryBox>
            )}
          </CsbFormControl>
        </VStack>

        {(data?.phishingEmail.phishingEmailSchedules.length ?? 0) > 0 && (
          <VStack align={'start'} maxW={700} mt={10} spacing={5}>
            <VStack align={'start'} w={'full'}>
              <Heading fontSize={24}>分割配信スケジュール</Heading>
              <Divider />
            </VStack>
            <PhishingEmailSchedules
              schedules={data?.phishingEmail.phishingEmailSchedules ?? []}
            />
          </VStack>
        )}

        <Accordion allowToggle maxW={600} mt={10}>
          <AccordionItem sx={{ border: 'none' }}>
            <AccordionButton
              sx={{
                '&:hover': {
                  backgroundColor: 'white',
                },
              }}
            >
              <Box as="span" flex="1" textAlign="right">
                詳細設定
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <VStack align={'start'} maxW={600} mt={5} spacing={5}>
                <VStack align={'start'} w={'full'}>
                  <Heading fontSize={24}>メール配信分割</Heading>
                  <Divider />
                </VStack>
                <>
                  <CsbFormControl labelText={'メール配信間隔'}>
                    {loading ? (
                      <Skeleton h={42} mt={2} />
                    ) : (
                      <CsbPrimaryBox
                        cursor={'not-allowed'}
                        mt={2}
                        opacity={0.4}
                        px={4}
                        py={2}
                        rounded={'md'}
                      >
                        {data?.phishingEmail.sendBlockInterval
                          ? `${data?.phishingEmail.sendBlockInterval}分`
                          : '未選択'}
                      </CsbPrimaryBox>
                    )}
                  </CsbFormControl>
                  {data?.phishingEmail.sendBlockInterval && (
                    <CsbFormControl labelText={'グループあたりの送信数'}>
                      {loading ? (
                        <Skeleton h={42} mt={2} />
                      ) : (
                        <CsbPrimaryBox
                          cursor={'not-allowed'}
                          mt={2}
                          opacity={0.4}
                          px={4}
                          py={2}
                          rounded={'md'}
                        >
                          {data?.phishingEmail.sendBlockSize}
                        </CsbPrimaryBox>
                      )}
                    </CsbFormControl>
                  )}
                </>
              </VStack>

              <VStack align={'start'} maxW={600} mt={10} spacing={5}>
                <VStack align={'start'} w={'full'}>
                  <Heading fontSize={24}>メール開封検知設定</Heading>
                  <Divider />
                </VStack>
                <>
                  <CsbFormControl labelText={'メール開封検知'}>
                    {loading ? (
                      <Skeleton h={42} mt={2} />
                    ) : (
                      <CsbPrimaryBox
                        cursor={'not-allowed'}
                        mt={2}
                        opacity={0.4}
                        px={4}
                        py={2}
                        rounded={'md'}
                      >
                        {data?.phishingEmail.isTrackMailOpen
                          ? '開封を検知する'
                          : '開封を検知しない'}
                      </CsbPrimaryBox>
                    )}
                  </CsbFormControl>
                </>
              </VStack>
              {!isDeliveryContentsOnly && (
                <>
                  <VStack align={'start'} maxW={600} mt={10} spacing={5}>
                    <VStack align={'start'}>
                      <Heading fontSize={24}>
                        リスクユーザーへのメール通知
                      </Heading>
                      <Divider />
                    </VStack>
                    <>
                      <CsbFormControl labelText={'メールの件名'}>
                        {loading ? (
                          <Skeleton h={42} mt={2} />
                        ) : (
                          <CsbPrimaryBox
                            cursor={'not-allowed'}
                            mt={2}
                            opacity={0.4}
                            px={4}
                            py={2}
                            rounded={'md'}
                          >
                            {data?.phishingEmail.riskUserEmailSubject}
                          </CsbPrimaryBox>
                        )}
                      </CsbFormControl>
                      <CsbFormControl labelText={'メールの本文'}>
                        {loading ? (
                          <Skeleton h={42} mt={2} />
                        ) : (
                          <CsbPrimaryBox
                            cursor={'not-allowed'}
                            dangerouslySetInnerHTML={{
                              __html: removeStyleTag(
                                data?.phishingEmail.riskUserEmailHtml || ''
                              ),
                            }}
                            mt={2}
                            opacity={0.4}
                            px={4}
                            py={2}
                            rounded={'md'}
                          />
                        )}
                      </CsbFormControl>
                    </>
                  </VStack>
                  <VStack align={'start'} maxW={600} mt={10} spacing={5}>
                    <VStack align={'start'}>
                      <Heading fontSize={24}>種明かし画面</Heading>
                      <Divider />
                    </VStack>
                    <>
                      <CsbFormControl labelText={'ページタイトル'}>
                        {loading ? (
                          <Skeleton h={42} mt={2} />
                        ) : (
                          <CsbPrimaryBox
                            cursor={'not-allowed'}
                            dangerouslySetInnerHTML={{
                              __html: removeStyleTag(
                                data?.phishingEmail.phishingResultTitle || ''
                              ),
                            }}
                            mt={2}
                            opacity={0.4}
                            px={4}
                            py={2}
                            rounded={'md'}
                          />
                        )}
                      </CsbFormControl>
                      <CsbFormControl labelText={'ページ本文'}>
                        {loading ? (
                          <Skeleton h={42} mt={2} />
                        ) : (
                          <CsbPrimaryBox
                            cursor={'not-allowed'}
                            dangerouslySetInnerHTML={{
                              __html: removeStyleTag(
                                data?.phishingEmail.phishingResultBody || ''
                              ),
                            }}
                            mt={2}
                            opacity={0.4}
                            px={4}
                            py={2}
                            rounded={'md'}
                          />
                        )}
                      </CsbFormControl>
                      <CsbFormControl labelText={'種明かし画面ボタンテキスト'}>
                        {loading ? (
                          <Skeleton h={42} mt={2} />
                        ) : (
                          <CsbPrimaryBox
                            cursor={'not-allowed'}
                            mt={2}
                            opacity={0.4}
                            px={4}
                            py={2}
                            rounded={'md'}
                          >
                            {data?.phishingEmail.linkText || ''}
                          </CsbPrimaryBox>
                        )}
                      </CsbFormControl>
                      <CsbFormControl labelText={'種明かし画面の署名'}>
                        {loading ? (
                          <Skeleton h={42} mt={2} />
                        ) : (
                          <CsbPrimaryBox
                            cursor={'not-allowed'}
                            dangerouslySetInnerHTML={{
                              __html: removeStyleTag(
                                data?.phishingEmail.phishingResultSign || ''
                              ),
                            }}
                            mt={2}
                            opacity={0.4}
                            px={4}
                            py={2}
                            rounded={'md'}
                          />
                        )}
                      </CsbFormControl>
                    </>
                  </VStack>
                </>
              )}

              <VStack align={'start'} w={'full'}>
                <Heading fontSize={24}>リマインド（繰り返し）メール</Heading>
                <Divider />
              </VStack>

              {loading ? (
                <Skeleton h={42} mt={2} />
              ) : (
                <>
                  <CsbFormControl
                    errorText={findError(errors, 'remindEmailSubject')?.message}
                    labelText={'メールの件名'}
                    mt={5}
                  >
                    <Controller
                      control={remindForm.control}
                      name={'remindEmailSubject'}
                      render={({ field }) => (
                        <Input
                          w={'full'}
                          {...field}
                          value={field.value || ''}
                        />
                      )}
                    />
                  </CsbFormControl>
                  <CsbFormControl
                    errorText={findError(errors, 'remindEmailHtml')?.message}
                    labelText={'メールの本文'}
                    mt={5}
                  >
                    <Controller
                      control={remindForm.control}
                      name={'remindEmailHtml'}
                      render={({ field: { onChange, value } }) => (
                        <CsbEditor
                          data={value ?? ''}
                          isHtmlLocked={
                            !!remindForm.getValues('isRemindEmailHtmlLock')
                          }
                          onChangeHtml={onChange}
                          onHtmlLocked={(locked) =>
                            remindForm.setValue('isRemindEmailHtmlLock', locked)
                          }
                        />
                      )}
                    />
                  </CsbFormControl>

                  <HStack mt={5} spacing={3}>
                    <Button
                      disabled={isRemindUpdating || !isWritePhishingMail}
                      onClick={() => {
                        remindForm.setValue(
                          'remindEmailSubject',
                          setting?.setting.remindEmailSubject ?? ''
                        )
                        remindForm.setValue(
                          'remindEmailHtml',
                          setting?.setting.remindEmailHtml ?? ''
                        )
                      }}
                    >
                      デフォルトに戻す
                    </Button>
                    <Button
                      disabled={isRemindUpdating || !isWritePhishingMail}
                      onClick={onRemindSubmit()}
                    >
                      更新
                    </Button>
                  </HStack>
                </>
              )}

              <VStack align={'start'} maxW={600} mt={10} spacing={5}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>学習コンテンツ設定</Heading>
                  <Divider />
                </VStack>
                <>
                  <CsbFormControl labelText={'学習コンテンツの説明'}>
                    {loading ? (
                      <Skeleton h={42} mt={2} />
                    ) : (
                      <CsbPrimaryBox
                        cursor={'not-allowed'}
                        dangerouslySetInnerHTML={{
                          __html: removeStyleTag(
                            data?.phishingEmail.learningContentComment || ''
                          ),
                        }}
                        mt={2}
                        opacity={0.4}
                        px={4}
                        py={2}
                        rounded={'md'}
                      />
                    )}
                  </CsbFormControl>
                  <CsbFormControl labelText={'学習の説明'}>
                    {loading ? (
                      <Skeleton h={42} mt={2} />
                    ) : (
                      <CsbPrimaryBox
                        cursor={'not-allowed'}
                        dangerouslySetInnerHTML={{
                          __html: removeStyleTag(
                            data?.phishingEmail.learningComment || ''
                          ),
                        }}
                        mt={2}
                        opacity={0.4}
                        px={4}
                        py={2}
                        rounded={'md'}
                      />
                    )}
                  </CsbFormControl>
                </>
              </VStack>

              <VStack align={'start'} maxW={600} mt={10} spacing={5}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>ロゴ表示設定</Heading>
                  <Divider />
                </VStack>
                <>
                  <CsbFormControl labelText={'学習ページ'}>
                    {loading ? (
                      <Skeleton h={42} mt={2} />
                    ) : (
                      <CsbPrimaryBox
                        cursor={'not-allowed'}
                        mt={2}
                        opacity={0.4}
                        px={4}
                        py={2}
                        rounded={'md'}
                      >
                        {data?.phishingEmail.isDisplayLogoInLearning
                          ? '表示する'
                          : '表示しない'}
                      </CsbPrimaryBox>
                    )}
                  </CsbFormControl>
                  <CsbFormControl labelText={'アンケート回答画面'}>
                    {loading ? (
                      <Skeleton h={42} mt={2} />
                    ) : (
                      <CsbPrimaryBox
                        cursor={'not-allowed'}
                        mt={2}
                        opacity={0.4}
                        px={4}
                        py={2}
                        rounded={'md'}
                      >
                        {data?.phishingEmail.isDisplayLogoInResearch
                          ? '表示する'
                          : '表示しない'}
                      </CsbPrimaryBox>
                    )}
                  </CsbFormControl>
                </>
              </VStack>

              <VStack align={'start'} maxW={600} mt={10} spacing={5}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>メール返信設定</Heading>
                  <Divider />
                </VStack>
                <>
                  <CsbFormControl
                    labelText={'返信先を送信元メールアドレスにする'}
                  >
                    {loading ? (
                      <Skeleton h={42} mt={2} />
                    ) : (
                      <CsbPrimaryBox
                        cursor={'not-allowed'}
                        mt={2}
                        opacity={0.4}
                        px={4}
                        py={2}
                        rounded={'md'}
                      >
                        {data?.phishingEmail.isFromAsReply ? 'ON' : 'OFF'}
                      </CsbPrimaryBox>
                    )}
                  </CsbFormControl>

                  {!isDeliveryContentsOnly && (
                    <CsbFormControl
                      labelText={'メール返信ユーザーをリスクユーザーにする'}
                    >
                      {loading ? (
                        <Skeleton h={42} mt={2} />
                      ) : (
                        <CsbPrimaryBox
                          cursor={'not-allowed'}
                          mt={2}
                          opacity={0.4}
                          px={4}
                          py={2}
                          rounded={'md'}
                        >
                          {data?.phishingEmail.isRiskReplyUser ? 'ON' : 'OFF'}
                        </CsbPrimaryBox>
                      )}
                    </CsbFormControl>
                  )}
                </>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </CsbPrimaryBox>
    </Flex>
  )
}
