import { ApolloError } from '@apollo/client'
import {
  CreateResearchInput,
  CreateResearchMutationResult,
  useCreateResearchMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors, useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { formReset } from '@src/utils/formReset'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

export const useCreateResearch = ({
  onCompleted,
  onError,
}: Partial<BaseOptionsType<CreateResearchMutationResult['data']>> = {}) => {
  const toast = useToast()
  const [createResearch, { loading: isLoading }] = useCreateResearchMutation({
    refetchQueries: ['researches'],
  })
  const form = useForm<Omit<CreateResearchInput, 'clientMutationId'>>()
  const { findErrorMessage, resetErrors, setErrors } =
    useGraphQLErrors<keyof Omit<CreateResearchInput, 'clientMutationId'>>()
  const resetForm = () => formReset(form, resetErrors)

  const onCreateResearch = useCallback(
    () =>
      form.handleSubmit(async (inputData) => {
        try {
          const { data } = await createResearch({
            variables: {
              input: inputData,
            },
          })
          formReset(form, resetErrors)
          onCompleted?.(data)
          toast({
            status: 'success',
            title: 'アンケートを作成しました。',
          })
        } catch (e) {
          toast({
            status: 'error',
            title: 'アンケートの作成に失敗しました。',
          })
          if (e instanceof ApolloError) {
            setErrors(e.graphQLErrors)
            onError?.(e)
          }
        }
      })(),
    [resetErrors, createResearch, setErrors, toast, form, onCompleted, onError]
  )

  return {
    findErrorMessage,
    form,
    isLoading,
    onCreateResearch,
    resetErrors,
    resetForm,
  }
}

type useCreateResearchType = typeof useCreateResearch
export type useCreateResearchResult = ReturnType<useCreateResearchType>
// export type useCreateResearchParameter = Parameters<useCreateResearchType>[0]
