import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * @see https://v5.reactrouter.com/web/guides/scroll-restoration
 */

export const ScrollToTop = () => {
  //ページが変わるごとにscroll位置を0にセットする
  const { pathname } = useLocation()

  useEffect(() => {
    //なぜかawaitしないと動作しない
    ;(async () => {
      await new Promise<void>((resolve) => {
        setTimeout(() => window.scrollTo(0, 0), 1)
        resolve()
      })
    })()
  }, [pathname])

  return null
}
