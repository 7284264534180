import { Box, Button, Flex, Heading, HStack } from '@chakra-ui/react'
import { CsbPrimaryBox } from '@src/components'
import { TemplateEditForm } from '@src/components/Form/TemplatesEditForm'
import { useTemplatesNewHooksResult } from '@src/components/pages/templates/new/hooks'
import { Base } from '@src/layouts'
import { memo } from 'react'

//メールテンプレート一覧ページのcomponent
export const TemplatesNew = memo(
  ({
    errors,
    form,
    isCreating,
    isDraftButton,
    isWriteMailTemplate,
    navigate,
    onSubmit,
  }: useTemplatesNewHooksResult) => {
    return (
      <Base
        left={<Heading>テンプレートの作成</Heading>}
        right={
          <HStack spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧に戻る
            </Button>
            {isWriteMailTemplate && (
              <>
                <Button
                  colorScheme={'blue'}
                  isLoading={isDraftButton && isCreating}
                  onClick={onSubmit(true)}
                >
                  下書き保存
                </Button>
                <Button
                  isLoading={!isDraftButton && isCreating}
                  onClick={onSubmit(false)}
                >
                  保存して公開
                </Button>
              </>
            )}
          </HStack>
        }
      >
        <Box mt={45}>
          <CsbPrimaryBox pb={'72px'} pt={35} px={45}>
            <TemplateEditForm errors={errors} form={form} />
          </CsbPrimaryBox>
        </Box>
        <Flex mt={5}>
          <HStack ml={'auto'} spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧に戻る
            </Button>
            {isWriteMailTemplate && (
              <>
                <Button
                  colorScheme={'blue'}
                  isLoading={isDraftButton && isCreating}
                  onClick={onSubmit(true)}
                >
                  下書き保存
                </Button>
                <Button
                  isLoading={!isDraftButton && isCreating}
                  onClick={onSubmit(false)}
                >
                  保存して公開
                </Button>
              </>
            )}
          </HStack>
        </Flex>
      </Base>
    )
  }
)
