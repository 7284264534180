import {
  Button,
  chakra,
  HStack,
  ModalCloseButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useClipboard,
} from '@chakra-ui/react'
import { CsbModalBody, CsbModalFooter } from '@src/components'
import { useModal } from '@src/components/Modal/useModal'
import { PHISHING_MAIL_PLACEHOLDERS } from '@src/constants/mailPlaceholder'
import { memo, useCallback } from 'react'

const TooltipButton = memo(({ placeholder }: { placeholder: string }) => {
  const { hasCopied, onCopy } = useClipboard(placeholder)
  return (
    <Tooltip
      hasArrow
      isOpen={hasCopied}
      label={`${placeholder} をコピーしました`}
      placement={'top'}
    >
      <chakra.button
        _hover={{ bgColor: 'gray.100' }}
        type={'button'}
        onClick={onCopy}
      >
        {placeholder}
      </chakra.button>
    </Tooltip>
  )
})

type useMailPlaceholderModalProps = {
  description: string
  example: string
  placeholder: string
}[]

export const getMailPlaceholders = (isDeliveryContentsOnly: boolean) =>
  // コンテンツ配信の場合は、ラーニングページへのリンクと説明を変更する
  !isDeliveryContentsOnly
    ? PHISHING_MAIL_PLACEHOLDERS.filter(
        (m) => !['{{learning_url}}', '{{url}}'].includes(m.placeholder)
      )
    : PHISHING_MAIL_PLACEHOLDERS.filter(
        (m) => m.placeholder !== '{{phishing_url}}'
      ).map((m) =>
        m.placeholder !== '{{link}}'
          ? m
          : {
              description: 'ラーニングページへのリンク',
              example: 'https://fusegu.cloud/learning/***',
              placeholder: m.placeholder,
            }
      )

export const useMailPlaceholderModal = (
  mailPlaceholders: useMailPlaceholderModalProps
) => {
  const [Modal, { isOpen, onClose, onOpen }] = useModal()

  const ModalWrapper = useCallback(() => {
    return (
      <Modal isOpen={isOpen} size={'3xl'} onClose={onClose}>
        <ModalCloseButton />
        <CsbModalBody mt={10}>
          <TableContainer>
            <Table colorScheme={'gray'}>
              <Thead>
                <Tr>
                  <Th>プレースホルダー </Th>
                  <Th>内容</Th>
                  <Th>表示例</Th>
                </Tr>
              </Thead>
              <Tbody>
                {mailPlaceholders.map(
                  ({ description, example, placeholder }, index) => (
                    <Tr fontSize={'sm'} key={`Tr_${placeholder}_${index}`}>
                      <Td>
                        <TooltipButton placeholder={placeholder} />
                      </Td>
                      <Td>{description}</Td>
                      <Td>{example}</Td>
                    </Tr>
                  )
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </CsbModalBody>
        <CsbModalFooter mt={7}>
          <HStack spacing={6}>
            <Button colorScheme={'gray'} onClick={onClose}>
              閉じる
            </Button>
          </HStack>
        </CsbModalFooter>
      </Modal>
    )
  }, [Modal, isOpen, mailPlaceholders, onClose])

  return [ModalWrapper, { isOpen, onClose, onOpen }] as const
}
