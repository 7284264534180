import { PhishingEmailsQueryHookResult } from '@src/graphql/generated/graphql'
import { ReactNode } from 'react'

type PhishingEmailsQueryHookResultData = NonNullable<
  PhishingEmailsQueryHookResult['data']
>['phishingEmails']

type PhishingEmailsQueryPropsType = {
  children: (data: PhishingEmailsQueryHookResultData) => ReactNode
} & PhishingEmailsQueryHookResult

export const PhishingEmailsQuery = ({
  children,
  data,
  error,
  loading,
}: PhishingEmailsQueryPropsType) => {
  if (error) {
    return <>error</>
  }

  if (loading && !data) {
    return <>loading...</>
  }

  if (!data) {
    return <>no data</>
  }

  return <>{children(data.phishingEmails)}</>
}
