import { useDisclosure } from '@chakra-ui/react'
import { QuestionnairesQuery } from '@src/graphql/generated/graphql'
import { useDestroyQuestionnaire } from '@src/models/Questionnaire'
import { useCallback, useState } from 'react'

type Questionnaire = QuestionnairesQuery['questionnaires']['collection'][number]

export const useDestroyQuestionnaireModal = () => {
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>()
  const { isOpen, onClose: _onClose, onOpen: _onOpen } = useDisclosure()
  const { isLoading, onDestroyQuestionnaire: _onDestroyQuestionnaire } =
    useDestroyQuestionnaire({
      onCompleted: () => {
        _onClose()
      },
    })
  const onClose = useCallback(() => {
    _onClose()
  }, [_onClose])

  const onOpen = useCallback(
    (questionnaire: Questionnaire) => {
      _onOpen()
      setQuestionnaire(questionnaire)
    },
    [_onOpen, setQuestionnaire]
  )

  const onDestroyQuestionnaire = useCallback(() => {
    if (!questionnaire) return
    _onDestroyQuestionnaire(questionnaire.uuid)
  }, [questionnaire, _onDestroyQuestionnaire])

  return {
    isLoading,
    isOpen,
    onClose,
    onDestroyQuestionnaire,
    onOpen,
    questionnaire,
  }
}

type useDestroyQuestionnaireModalType = typeof useDestroyQuestionnaireModal
export type useDestroyQuestionnaireModalResult =
  ReturnType<useDestroyQuestionnaireModalType>
// export type useDestroyResearchModalParameter = Parameters<useDestroyResearchModalType>[0]
