import { Groups } from '@src/components/pages/groups'
import { useGroupsHooks } from '@src/components/pages/groups/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const GroupsPage = memo(() => {
  const groupsHooks = useGroupsHooks()
  return (
    <Authorization>
      <Groups {...groupsHooks} />
    </Authorization>
  )
})
