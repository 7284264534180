import { PhishingResultSendRevealingSchedule } from '@src/components/pages/phishing/[id]/result/send/revealing/schedule'
import { useSendRevealingScheduleHooks } from '@src/components/pages/phishing/[id]/result/send/revealing/schedule/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const PhishingResultSendRevealingSchedulePage = memo(() => {
  const useSendRevealingSchedule = useSendRevealingScheduleHooks()

  return (
    <Authorization>
      <PhishingResultSendRevealingSchedule {...useSendRevealingSchedule} />
    </Authorization>
  )
})
