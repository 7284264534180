import { ApolloError } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import {
  AnswerQuestionnaireInput,
  useAnswerQuestionnaireMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors } from '@src/hooks'
import { formReset } from '@src/utils/formReset'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const useAnswerQuestionnaire = () => {
  const [answerQuestionnaire, { loading: isLoading }] =
    useAnswerQuestionnaireMutation()
  const form =
    useForm<Omit<AnswerQuestionnaireInput, 'clientMutationId' | 'uuid'>>()
  const { errors, resetErrors, setErrors } =
    useGraphQLErrors<
      keyof Omit<AnswerQuestionnaireInput, 'clientMutationId' | 'uuid'>
    >()
  const resetForm = () => formReset(form, resetErrors)

  const { t } = useTranslation()
  const toast = useToast()

  const findError = (attributeName: string) => {
    const error = errors.find(
      ({ extensions }) => extensions?.attribute === attributeName
    )
    return error?.message ?? ''
  }
  const processing = useRef(false)

  const onAnswerQuestionnaire = (
    uuid: AnswerQuestionnaireInput['uuid'],
    callback: () => void
  ) =>
    form.handleSubmit(async ({ answers }) => {
      if (processing.current) return
      processing.current = true

      try {
        await answerQuestionnaire({
          variables: {
            input: {
              answers: answers.map((n) => n.filter(Boolean).map((n) => n)),
              uuid,
            },
          },
        })
        resetForm()
        callback()
      } catch (e) {
        if (e instanceof ApolloError) {
          setErrors(e.graphQLErrors)
          const requiredErrorMessage = t('requiredError')
          toast({
            duration: 3000,
            isClosable: true,
            position: 'top',
            status: 'error',
            title: requiredErrorMessage,
          })
        }
      } finally {
        processing.current = false
      }
    })

  return {
    findError,
    form,
    isLoading,
    onAnswerQuestionnaire,
    resetForm,
  }
}

type useAnswerQuestionnaireType = typeof useAnswerQuestionnaire
export type useAnswerQuestionnaireResult =
  ReturnType<useAnswerQuestionnaireType>
// export type useAnswerQuestionnaireParameter = Parameters<useAnswerQuestionnaireType>[0]
