import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  Heading,
  Input,
  Stack,
  VisuallyHidden,
} from '@chakra-ui/react'
import { CsbFormControl, CsbLogoIcon, CsbPrimaryBox } from '@src/components'
import { useLoginHooksResult } from '@src/components/pages/login/hooks'
import { EmptyLayout } from '@src/layouts/EmptyLayout'
import { memo } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

//ログインページのcomponent
export const Login = memo(
  ({ errors, form, loading, onSubmit }: useLoginHooksResult) => {
    const { executeRecaptcha } = useGoogleReCaptcha()

    if (!executeRecaptcha) {
      return null
    }

    const handleSubmit = async () => {
      const token = await executeRecaptcha('authUser')
      form.setValue('recaptchaToken', token)
      await onSubmit()
    }

    return (
      <EmptyLayout>
        <Flex align={'center'} justify={'center'} minH={'100vh'}>
          <Flex
            align="center"
            justify="space-between"
            maxW={898}
            mx="auto"
            w="full"
          >
            <Heading
              aria-labelledby="title"
              as="h1"
              maxW={276}
              mt={-16}
              w={'full'}
            >
              <VisuallyHidden id="title">情報漏えい防ぐくん</VisuallyHidden>
              <CsbLogoIcon h={'auto'} w={'full'} />
            </Heading>
            <CsbPrimaryBox
              as="form"
              maxW={402}
              pb={10}
              pt={12}
              px={10}
              rounded={'lg'}
              w="full"
              // pb={4}
              onSubmit={(e) => {
                // preventDefault しないとうまく動作しない
                e.preventDefault()
                handleSubmit().then()
              }}
            >
              <Stack spacing={6}>
                <Heading as="h2" fontWeight="bold" size="lg">
                  ログイン
                </Heading>
                {errors.length > 0 && errors[0] ? (
                  <Alert status="error">
                    <AlertIcon />
                    {errors[0].message}
                  </Alert>
                ) : null}
                <CsbFormControl id="companyId">
                  <Input
                    {...form.register('companyId')}
                    _placeholder={{ color: 'blackAlpha.500' }}
                    placeholder="企業ID"
                    type="number"
                  />
                </CsbFormControl>
                <CsbFormControl id="email">
                  <Input
                    {...form.register('email')}
                    _placeholder={{ color: 'blackAlpha.500' }}
                    placeholder="メールアドレス"
                    type="email"
                  />
                </CsbFormControl>
                <CsbFormControl id="password">
                  <Input
                    {...form.register('password')}
                    _placeholder={{ color: 'blackAlpha.500' }}
                    placeholder="パスワード"
                    type="password"
                  />
                </CsbFormControl>
                <Button isLoading={loading} type={'submit'}>
                  ログイン
                </Button>
              </Stack>
              {/*<Stack*/}
              {/*  mt={4}*/}
              {/*  spacing={4}*/}
              {/*  divider={<StackDivider borderColor="gray.200" />}*/}
              {/*>*/}
              {/*  <Box textAlign={'center'}>*/}
              {/*    <Link*/}
              {/*      href={'/'}*/}
              {/*      textAlign="center"*/}
              {/*      color="teal.500"*/}
              {/*      fontSize="xs"*/}
              {/*      fontWeight="bold"*/}
              {/*    >*/}
              {/*      サインアップ*/}
              {/*    </Link>*/}
              {/*  </Box>*/}
              {/*  <Box textAlign={'center'}>*/}
              {/*    <Link*/}
              {/*      href={'/'}*/}
              {/*      textAlign="center"*/}
              {/*      color="teal.500"*/}
              {/*      fontSize="xs"*/}
              {/*      fontWeight="bold"*/}
              {/*    >*/}
              {/*      パスワードを忘れた方*/}
              {/*    </Link>*/}
              {/*  </Box>*/}
              {/*</Stack>*/}
            </CsbPrimaryBox>
          </Flex>
        </Flex>
      </EmptyLayout>
    )
  }
)
