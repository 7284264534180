import { Box, Flex, HStack, Link, Text } from '@chakra-ui/react'
import { CsbAside, CsbLoginHeader, CsbNextLink } from '@src/components'
import { useMe } from '@src/hooks'
import { getActiveCompanyMailLimitStrings } from '@src/utils/companyMailLiimit'
import { memo, ReactNode } from 'react'
import { BsEnvelope, BsPerson } from 'react-icons/bs'

type BasePropsType = {
  children: ReactNode
  left?: ReactNode
  right?: ReactNode
}

export const Base = memo(({ children, left, right }: BasePropsType) => {
  const { company } = useMe()

  return (
    <>
      <Box>
        <CsbLoginHeader
          bg={'white'}
          left={0}
          position={'fixed'}
          top={0}
          w={'full'}
          zIndex={999}
        />
        <CsbAside
          height={'calc(100vh - 80px)'}
          left={0}
          position={'fixed'}
          top={'80px'}
          w={'210px'}
          zIndex={999}
        />
        <Box
          left={'210px'}
          position={'relative'}
          px={10}
          py={'100px'}
          top={'80px'}
          w={'calc(100vw - 210px)'}
        >
          <Flex
            align={'center'}
            bg={'gray.50'}
            borderBottom={'1px'}
            borderBottomColor={'gray.100'}
            left={210}
            mb={5}
            position={'fixed'}
            px={3}
            py={1}
            textAlign={'right'}
            top={'80px'}
            w={'calc(100vw - 210px)'}
            zIndex={800}
          >
            <CsbNextLink
              flexShrink={0}
              fontSize={'12px'}
              href={`/companies/detail`}
            >
              <Link as={'p'}>{`${company?.name ?? ''}（企業ID:${
                company?.id
              }）`}</Link>
            </CsbNextLink>
            <HStack ml={'auto'} spacing={4}>
              <HStack spacing={2}>
                <BsPerson />
                <Text fontSize={'12px'}>
                  {company?.currentUserCount?.toLocaleString()}
                  {company?.maxUserCount?.toLocaleString() &&
                    ` / ${company.maxUserCount.toLocaleString()}`}
                </Text>
              </HStack>
              <HStack spacing={2}>
                <BsEnvelope />
                {company?.companyMailLimits.length === 0 ? (
                  <Text fontSize={'12px'}>
                    {company?.currentMonthlyMailCount?.toLocaleString()}
                    {company?.maxMonthlyMailCount?.toLocaleString() &&
                      ` / ${company.maxMonthlyMailCount.toLocaleString()}`}
                  </Text>
                ) : (
                  <>
                    {getActiveCompanyMailLimitStrings(
                      company?.companyMailLimits ?? []
                    ).map((limit, index) => (
                      <Text fontSize={'12px'} key={index}>
                        {limit}
                      </Text>
                    ))}
                  </>
                )}
              </HStack>
              <Text color={'red.400'} fontSize={'12px'} fontWeight={'bold'}>
                {!company?.isActive && <>契約期間が切れています</>}
              </Text>
            </HStack>
          </Flex>
          <Flex justifyContent={'space-between'}>
            {left}
            {right}
          </Flex>
          <Box mt={12}>{children}</Box>
        </Box>
      </Box>
    </>
  )
})
