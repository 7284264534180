import { BaseResponseError } from '@src/errors/BaseResponseError'

/**
 * スネークケースに変換する関数
 * @see https://stackoverflow.com/questions/54246477/how-to-convert-camelcase-to-snake-case-in-javascript
 */
const toSnakeCase = (inputString: string) => {
  return inputString
    .split('')
    .map((character) => {
      if (/^\./.test(character)) {
        return character
      }
      if (character === character.toUpperCase()) {
        return '_' + character.toLowerCase()
      } else {
        return character
      }
    })
    .join('')
}

export const findError = (
  errors: [BaseResponseError?],
  attributeName: string
) => {
  return errors.find((n) => {
    return n?.extensions.attribute === toSnakeCase(attributeName)
  })
}
