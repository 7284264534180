import { CompaniesEdit } from '@src/components/pages/companies/[id]/edit'
import { useCompaniesEditHooks } from '@src/components/pages/companies/[id]/edit/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

//企業編集ページ
export const CompaniesEditPage = memo(() => {
  const companiesEditHooks = useCompaniesEditHooks()
  return (
    <Authorization>
      <CompaniesEdit {...companiesEditHooks} />
    </Authorization>
  )
})
