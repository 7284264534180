import { useMe } from '@src/hooks'
import {
  useAdminResearch,
  useCreateAdminResearchQuestionModal,
} from '@src/models/AdminResearch'
import { useNavigate, useParams } from 'react-router-dom'

export const useAdminResearchesDetail = () => {
  const { id } = useParams<{ id: string }>()
  const { role } = useMe()
  const isWriteAdminResearch = !!role?.writeAdminResearch
  const navigate = useNavigate()
  const adminResearch = useAdminResearch({
    skip: !id,
    variables: {
      uuid: id ?? '',
    },
  })
  const createAdminResearchQuestionModal = useCreateAdminResearchQuestionModal(
    id ?? ''
  )

  return {
    adminResearch,
    createAdminResearchQuestionModal,
    id,
    isWriteAdminResearch,
    navigate,
  }
}

export type UseAdminResearchesDetailResult = ReturnType<
  typeof useAdminResearchesDetail
>
