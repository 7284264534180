import { useCreateS3PresignedUrlMutationMutation } from '@src/graphql/generated/graphql'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'

//savePathは引き数として受け取る
type useUploadType = (props: { savePath: string; url?: string }) => {
  error: unknown
  image: string
  loading: boolean
  // TODO anyを消す
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpload: (acceptedFiles: any) => Promise<void>
  removeImage: () => void
  thumbnail: string
}

//画像をアップロードするhooks
export const useUpload: useUploadType = ({ savePath, url = '' }) => {
  //画像をアップロード中かのstate
  const [loading, setLoading] = useState<boolean>(false)
  const [createS3PresignedUrlMutationMutation] =
    useCreateS3PresignedUrlMutationMutation()
  //base64形式のstate
  const [thumbnail, setThumbnail] = useState<string>('')
  //awsのurlのstate
  const [image, setImage] = useState<string>('')
  //errorのstate
  const [error, setError] = useState<unknown | null>()

  //画像のstateを初期化
  useEffect(() => {
    setImage(url || '')
    setThumbnail(url || '')
  }, [url])

  //画像のstateを削除
  const removeImage = () => {
    setImage('')
    setThumbnail('')
  }

  const onUpload = useCallback(
    async (acceptedFiles) => {
      setLoading(true)
      try {
        const reader = new FileReader()
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          setThumbnail(reader.result as string)
        }
        reader.readAsDataURL(acceptedFiles[0])

        const result = await createS3PresignedUrlMutationMutation({
          variables: {
            input: {
              fileName: acceptedFiles[0].name,
              savePath,
            },
          },
        })

        const options = {
          headers: {
            'Content-Type': acceptedFiles[0].type,
          },
        }

        const res = await axios.put(
          result.data?.createS3PresignedUrlMutation?.presignedUrl as string,
          acceptedFiles[0],
          options
        )

        if (res.status === 200) {
          setImage(
            result.data?.createS3PresignedUrlMutation?.uploadUrl as string
          )
          setError(null)
        } else {
          //200以外の時はerrorを返す
          setError('error')
        }
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    },
    [createS3PresignedUrlMutationMutation, savePath]
  )

  return {
    error,
    image,
    loading,
    onUpload,
    removeImage,
    setImage,
    thumbnail,
  }
}
