import {
  UpdateRemindEmailInput,
  UpdateRevealingPhishingEmailInput,
  useMailFromsQuery,
  usePhishingEmailQuery,
  useSettingsQuery,
  useUpdateRemindEmailMutation,
  useUpdateRevealingPhishingEmailMutation,
} from '@src/graphql/generated/graphql'
import { useErrors, useToast } from '@src/hooks'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import { phishingFormDefaultValues } from '@src/pages/phishing/formDefaultValues'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

export const useMailDetail = () => {
  const { id } = useParams<{ id: string }>()
  const { isDeliveryContentsOnly } = useDeliveryContents()

  const revealingForm = useForm<
    Omit<UpdateRevealingPhishingEmailInput, 'uuid'>
  >({
    defaultValues: phishingFormDefaultValues,
  })
  const remindForm = useForm<Omit<UpdateRemindEmailInput, 'uuid'>>({
    defaultValues: phishingFormDefaultValues,
  })

  const { data, loading } = usePhishingEmailQuery({
    onCompleted: async ({ phishingEmail }) => {
      revealingForm.setValue(
        'revealingPhishingEmailFromEmail',
        phishingEmail?.revealingPhishingEmailFromEmail
      )
      revealingForm.setValue(
        'revealingPhishingEmailFromName',
        phishingEmail?.revealingPhishingEmailFromName
      )
      revealingForm.setValue(
        'revealingPhishingEmailMailFromId',
        phishingEmail?.revealingPhishingEmailMailFrom?.uuid
      )
      revealingForm.setValue(
        'revealingPhishingEmailSubject',
        phishingEmail?.revealingPhishingEmailSubject ?? ''
      )
      revealingForm.setValue(
        'revealingPhishingEmailHtml',
        phishingEmail?.revealingPhishingEmailHtml ?? ''
      )
      revealingForm.setValue(
        'isRevealingPhishingEmailHtmlLock',
        !!phishingEmail?.isRevealingPhishingEmailHtmlLock
      )
      remindForm.setValue(
        'remindEmailSubject',
        phishingEmail?.remindEmailSubject
      )
      remindForm.setValue('remindEmailHtml', phishingEmail?.remindEmailHtml)
      remindForm.setValue(
        'isRemindEmailHtmlLock',
        !!phishingEmail?.isRemindEmailHtmlLock
      )
    },
    variables: { isDeliveryContentsOnly, uuid: id ?? '' },
  })
  const { data: setting, loading: settingLoading } = useSettingsQuery()
  const { data: mailFroms, loading: mailFromsLoading } = useMailFromsQuery({
    variables: {
      pagination: {
        limit: 99999,
        page: 1,
      },
    },
  })

  const isLoading = loading || settingLoading || mailFromsLoading

  const [errors, setErrors] = useErrors()
  const toast = useToast()

  //種明かしメールをアップデートする関数
  const [
    updateRevealingPhishingEmailMutation,
    { loading: isRevealingUpdating },
  ] = useUpdateRevealingPhishingEmailMutation({
    onCompleted: ({ updateRevealingPhishingEmail }) => {
      revealingForm.setValue(
        'revealingPhishingEmailFromEmail',
        updateRevealingPhishingEmail?.phishingEmail
          .revealingPhishingEmailFromEmail
      )
      revealingForm.setValue(
        'revealingPhishingEmailFromName',
        updateRevealingPhishingEmail?.phishingEmail
          .revealingPhishingEmailFromName
      )
      revealingForm.setValue(
        'revealingPhishingEmailMailFromId',
        updateRevealingPhishingEmail?.phishingEmail
          .revealingPhishingEmailMailFrom?.uuid
      )
      revealingForm.setValue(
        'revealingPhishingEmailSubject',
        updateRevealingPhishingEmail?.phishingEmail
          ?.revealingPhishingEmailSubject ?? ''
      )
      revealingForm.setValue(
        'revealingPhishingEmailHtml',
        updateRevealingPhishingEmail?.phishingEmail
          ?.revealingPhishingEmailHtml ?? ''
      )
      revealingForm.setValue(
        'isRevealingPhishingEmailHtmlLock',
        !!updateRevealingPhishingEmail?.phishingEmail
          ?.isRevealingPhishingEmailHtmlLock
      )
    },
  })
  const [updateRemindEmailMutation, { loading: isRemindUpdating }] =
    useUpdateRemindEmailMutation({
      onCompleted: ({ updateRemindEmail }) => {
        remindForm.setValue(
          'remindEmailSubject',
          updateRemindEmail?.phishingEmail.remindEmailSubject
        )
        remindForm.setValue(
          'remindEmailHtml',
          updateRemindEmail?.phishingEmail.remindEmailHtml
        )
        remindForm.setValue(
          'isRemindEmailHtmlLock',
          !!updateRemindEmail?.phishingEmail?.isRemindEmailHtmlLock
        )
      },
    })

  const onRevealingSubmit = () =>
    revealingForm.handleSubmit(
      async ({
        clientMutationId,
        isRevealingPhishingEmailHtmlLock,
        revealingPhishingEmailFromEmail,
        revealingPhishingEmailFromName,
        revealingPhishingEmailHtml,
        revealingPhishingEmailMailFromId,
        revealingPhishingEmailSubject,
      }) => {
        const name = !isDeliveryContentsOnly
          ? '種明かしメール'
          : 'リマインドメール'
        try {
          await updateRevealingPhishingEmailMutation({
            variables: {
              input: {
                clientMutationId,
                isRevealingPhishingEmailHtmlLock,
                revealingPhishingEmailFromEmail,
                revealingPhishingEmailFromName,
                revealingPhishingEmailHtml,
                revealingPhishingEmailMailFromId,
                revealingPhishingEmailSubject,
                uuid: id ?? '',
              },
            },
          })
          setErrors([])

          //保存ボタンの場合のみToastを発行しページ遷移する
          toast({
            status: 'success',
            title: `${name}を更新しました。`,
          })

          /**
           * TODO anyを消す
           * e instanceOf ApolloError
           * setErrorsを変更する
           */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          toast({
            status: 'error',
            title: `${name}の更新に失敗しました。`,
          })
          setErrors(e.graphQLErrors)
        }
      }
    )

  const onRemindSubmit = () =>
    remindForm.handleSubmit(
      async ({
        clientMutationId,
        isRemindEmailHtmlLock,
        remindEmailHtml,
        remindEmailSubject,
      }) => {
        try {
          await updateRemindEmailMutation({
            variables: {
              input: {
                clientMutationId,
                isRemindEmailHtmlLock,
                remindEmailHtml,
                remindEmailSubject,
                uuid: id ?? '',
              },
            },
          })
          setErrors([])

          //保存ボタンの場合のみToastを発行しページ遷移する
          toast({
            status: 'success',
            title: `リマインド（繰り返し）メールを更新しました。`,
          })

          /**
           * TODO anyを消す
           * e instanceOf ApolloError
           * setErrorsを変更する
           */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          toast({
            status: 'error',
            title: `リマインド（繰り返し）メールの更新に失敗しました。`,
          })
          setErrors(e.graphQLErrors)
        }
      }
    )

  return {
    data,
    errors,
    isDeliveryContentsOnly,
    isRemindUpdating,
    isRevealingUpdating,
    loading: isLoading,
    mailFroms,
    onRemindSubmit,
    onRevealingSubmit,
    remindForm,
    revealingForm,
    setting,
  }
}

type useMailDetail = typeof useMailDetail
export type useMailDetailResult = ReturnType<useMailDetail>
