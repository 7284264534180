import { usePhishingResultOverviewQuery } from '@src/graphql/generated/graphql'

export const usePhishingResultOverview = (
  baseOptions: Partial<
    Parameters<typeof usePhishingResultOverviewQuery>[0]
  > = {}
) => {
  return usePhishingResultOverviewQuery(baseOptions)
}

export type usePhishingResultOverviewHooksResult = ReturnType<
  typeof usePhishingResultOverview
>
export type usePhishingResultOverviewHooksParameter = Parameters<
  typeof usePhishingResultOverview
>[0]
