import { BaseLoadingFallback, BaseQueryLayoutProps } from '@src/models/Base'
import { UseResearchResultResult } from '@src/models/Learning/useLearningResult'

export const LearningResultQuery = ({
  children,
  data,
  error,
  errorFallback,
  loading,
  loadingFallback,
  noDataFallback,
}: BaseQueryLayoutProps<UseResearchResultResult>) => {
  if (error) {
    return errorFallback ? errorFallback : null
  }

  //apolloはdataをcacheするためloadingとdataを見る必要がある
  if (loading && !data) {
    return loadingFallback ? loadingFallback : <BaseLoadingFallback />
  }

  if (!data || data.answerResult.length === 0) {
    return noDataFallback ? noDataFallback : null
  }

  return typeof children === 'function' ? children(data) : children
}
