import { ApolloError } from '@apollo/client'
import {
  DestroyAdminResearchQuestionInput,
  DestroyAdminResearchQuestionMutationResult,
  useDestroyAdminResearchQuestionMutation,
} from '@src/graphql/generated/graphql'
import { useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { useCallback } from 'react'

export const useDestroyAdminResearchQuestion = ({
  onCompleted,
  onError,
}: Partial<
  BaseOptionsType<DestroyAdminResearchQuestionMutationResult['data']>
> = {}) => {
  const [destroyAdminResearchQuestion, { loading: isLoading }] =
    useDestroyAdminResearchQuestionMutation({
      refetchQueries: ['adminResearch'],
    })
  const toast = useToast()
  const onDestroyAdminResearchQuestion = useCallback(
    async (uuid: DestroyAdminResearchQuestionInput['uuid']) => {
      try {
        const { data } = await destroyAdminResearchQuestion({
          variables: {
            input: { uuid },
          },
        })
        toast({
          status: 'success',
          title: 'アンケートを削除しました',
        })
        onCompleted?.(data)
      } catch (e) {
        toast({
          status: 'error',
          title: 'アンケートの削除に失敗しました。',
        })
        if (e instanceof ApolloError) {
          onError?.(e)
        }
      }
    },
    [onError, toast, onCompleted, destroyAdminResearchQuestion]
  )

  return { isLoading, onDestroyAdminResearchQuestion }
}

type UseDestroyAdminResearchQuestionType =
  typeof useDestroyAdminResearchQuestion
export type UseDestroyAdminResearchQuestionResult =
  ReturnType<UseDestroyAdminResearchQuestionType>
// export type UseDestroyAdminResearchQuestionParameter = Parameters<UseDestroyAdminResearchQuestionType>[0]
