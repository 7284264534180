import { useQuestionnairesQuery } from '@src/graphql/generated/graphql'

export const useQuestionnaires = (
  baseOptions: Partial<Parameters<typeof useQuestionnairesQuery>[0]> = {}
) => {
  return useQuestionnairesQuery(baseOptions)
}

type useQuestionnairesType = typeof useQuestionnaires
export type useQuestionnairesResult = ReturnType<useQuestionnairesType>
// export type useQuestionnairesParameter = Parameters<useProjectType>[0]
