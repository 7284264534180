import { useDisclosure } from '@chakra-ui/react'
import { useCreateResearchQuestion } from '@src/models/Research'
import { useCallback } from 'react'

export const useCreateResearchQuestionModal = (id: string) => {
  const { isOpen, onClose: _onClose, onOpen } = useDisclosure()
  const {
    findErrorMessage,
    form,
    isLoading,
    onCreateResearchQuestion,
    resetForm,
  } = useCreateResearchQuestion({
    onCompleted: () => {
      _onClose()
    },
  })
  const onClose = useCallback(() => {
    resetForm()
    _onClose()
  }, [_onClose, resetForm])

  return {
    findErrorMessage,
    form,
    id,
    isLoading,
    isOpen,
    onClose,
    onCreateResearchQuestion,
    onOpen,
  }
}

type UseCreateResearchQuestionModalType = typeof useCreateResearchQuestionModal
export type UseCreateResearchQuestionModalResult =
  ReturnType<UseCreateResearchQuestionModalType>
// export type UseCreateResearchModalParameter = Parameters<UseCreateResearchQuestionModalType>[0]
