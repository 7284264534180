import { UseDisclosureReturn } from '@chakra-ui/hooks/src/use-disclosure'
import { Button, HStack, Text, useToast } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import { TargetUserList } from '@src/components/List/TargetUsers/TargetUserList'
import {
  RevealingEmailTargetsQuery,
  RevealingTarget,
  useResendRevealingEmailMutation,
  useRevealingEmailTargetsQuery,
} from '@src/graphql/generated/graphql'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import { memo, useEffect, useState } from 'react'

type RevealingEmailTargetModalPropsType = {
  disclosure: UseDisclosureReturn
  target: RevealingTarget
  uuid: string
}

const userLimitPerPage = 100

const getTargetName = (target: RevealingTarget) => {
  switch (target) {
    case RevealingTarget.All:
      return '全員'
    case RevealingTarget.Unanswered:
      return '未回答ユーザー'
    case RevealingTarget.NoLearningAccess:
      return 'ラーニング未アクセスユーザー'
    case RevealingTarget.NoLearningAccessAndRiskUser:
      return 'ラーニング未アクセスでリスクのあるユーザー'
    case RevealingTarget.RiskUser:
      return 'リスクのあるユーザー'
    case RevealingTarget.UnansweredAndRiskUser:
      return '未回答でリスクのあるユーザー'
  }
}

const collectUsers = (data?: RevealingEmailTargetsQuery) =>
  data?.revealingEmailTargets.collection.map((target) => ({
    email: target.user.email,
    firstName: target.user.firstName,
    lastName: target.user.lastName,
    target: target.user.avatarPath,
    uuid: target.user.uuid,
  })) ?? null

export const ResendRevealingEmailModal = memo(
  ({
    disclosure: resendRevealingEmailModal,
    target,
    uuid,
  }: RevealingEmailTargetModalPropsType) => {
    const { isDeliveryContentsOnly } = useDeliveryContents()

    const title = !isDeliveryContentsOnly
      ? '種明かしメール'
      : 'リマインドメール'
    const targetName = getTargetName(target)
    const toast = useToast()

    const { data, refetch } = useRevealingEmailTargetsQuery({
      variables: {
        pagination: {
          limit: userLimitPerPage,
          page: 1,
        },
        target: target,
        uuid: uuid,
      },
    })

    const [users, setUsers] = useState(collectUsers(data))
    const [metadata, setMetadata] = useState(
      data?.revealingEmailTargets.metadata
    )

    const onPaging = async (page: number) => {
      const { data } = await refetch({
        pagination: {
          limit: userLimitPerPage,
          page: page,
        },
        target: target,
        uuid: uuid,
      })
      setUsers(collectUsers(data))
      setMetadata(data.revealingEmailTargets.metadata)
    }

    useEffect(() => {
      setUsers(null)
      onPaging(1).then()
      // eslint-disable-next-line
    }, [target])

    const [resendRevealingEmail, { loading: isSending }] =
      useResendRevealingEmailMutation({
        onCompleted: () => {
          toast({
            duration: 3000,
            isClosable: true,
            position: 'top',
            status: 'success',
            title: `${title}を再送しました。`,
          })
        },
        onError: () => {
          toast({
            duration: 3000,
            isClosable: true,
            position: 'top',
            status: 'error',
            title: '再送信に失敗しました',
          })
        },
      })

    const onSubmit = async () => {
      resendRevealingEmailModal.onClose()
      await resendRevealingEmail({
        variables: {
          input: {
            target,
            uuid,
          },
        },
      })
    }

    return (
      <>
        <CsbModal
          isOpen={users !== null && resendRevealingEmailModal.isOpen}
          size={'2xl'}
          onClose={resendRevealingEmailModal.onClose}
        >
          <CsbModalHeader>送信確認</CsbModalHeader>
          <CsbModalBody mt={'18px'}>
            {users?.length ? (
              <>
                <Text mt={1}>
                  {metadata?.totalCount ?? 0}
                  件のメールアドレス宛に{title}を{targetName}に再送します。
                </Text>
                <TargetUserList
                  collectionMetaData={metadata}
                  users={users}
                  onPaging={onPaging}
                />
              </>
            ) : (
              <Text>メールを送信できるユーザが存在しません。</Text>
            )}
          </CsbModalBody>
          <CsbModalFooter mt={7}>
            <HStack spacing={6}>
              <Button
                as={'div'}
                colorScheme={'gray'}
                isLoading={isSending}
                onClick={resendRevealingEmailModal.onClose}
              >
                キャンセル
              </Button>
              {users?.length && (
                <Button isLoading={isSending} onClick={onSubmit}>
                  送信する
                </Button>
              )}
            </HStack>
          </CsbModalFooter>
        </CsbModal>
      </>
    )
  }
)
