import { useResearches } from '@src/models/Research/useResearches'
import dayjs from 'dayjs'

export const useSelectableResearches = () => {
  const researchParams = {
    variables: {
      pagination: { limit: 9999, page: 1 },
      searchWord: null,
    },
  }
  const researches = useResearches(researchParams)
  const today = dayjs(new Date()).format('YYYY/MM/DD')
  return (
    researches.data?.researches.collection.filter((research) => {
      return (
        research.closedAt === null ||
        dayjs(research.closedAt).format('YYYY/MM/DD') >= today
      )
    }) ?? []
  )
}
