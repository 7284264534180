import { useDisclosure } from '@chakra-ui/react'
import { useCreatePdf } from '@src/models/Pdf/useCreatePdf'
import { useCallback } from 'react'

export const useCreatePdfModal = () => {
  const { isOpen, onClose: _onClose, onOpen } = useDisclosure()
  const { findErrorMessage, form, isLoading, onCreatePdf, resetForm } =
    useCreatePdf({
      onCompleted: () => {
        _onClose()
      },
    })
  const onClose = useCallback(() => {
    resetForm()
    _onClose()
  }, [_onClose, resetForm])

  return {
    findErrorMessage,
    form,
    isLoading,
    isOpen,
    onClose,
    onCreatePdf,
    onOpen,
  }
}

type UseCreatePdfModalType = typeof useCreatePdfModal
export type UseCreatePdfModalResult = ReturnType<UseCreatePdfModalType>
