import { UsersImport } from '@src/components/pages/users/import'
import { useUsersImportHooks } from '@src/components/pages/users/import/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const UsersImportPage = memo(() => {
  const usersImportHooks = useUsersImportHooks()
  return (
    <Authorization>
      <UsersImport {...usersImportHooks} />
    </Authorization>
  )
})
