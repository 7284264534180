import { ApolloError } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import {
  DestroyUsersInput,
  DestroyUsersMutation,
  useDestroyUsersMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base/types'

export const useDestroyUsers = ({
  onCompleted,
  onError,
}: Partial<BaseOptionsType<DestroyUsersMutation>> = {}) => {
  const [destroyUsersMutation, { loading }] = useDestroyUsersMutation()
  const { resetErrors, setErrors } =
    useGraphQLErrors<keyof Omit<DestroyUsersInput, 'clientMutationId'>>()
  const toast = useToast()

  const onDestroyUsers = async (uuids: DestroyUsersInput['uuids']) => {
    try {
      const { data } = await destroyUsersMutation({
        variables: {
          input: { uuids },
        },
      })

      resetErrors()
      onCompleted?.(data)
      const errorCount = data?.destroyUsers?.errors.length ?? 0
      if (errorCount > 0) {
        const hasDeletedUsers = errorCount !== uuids.length
        const errorMessage = hasDeletedUsers
          ? 'ユーザーを削除しました。一部のユーザは管理者ユーザー、またはご自身のため削除できませんでした。'
          : '管理者ユーザー、またはご自身のため削除できませんでした。'

        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'error',
          title: errorMessage,
        })
      } else {
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'success',
          title: 'ユーザーを削除しました。',
        })
      }
    } catch (e) {
      if (e instanceof ApolloError) {
        onError?.(e)
        setErrors(e.graphQLErrors)
      }
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: 'ユーザーの削除に失敗しました。',
      })
    }
  }

  return { loading, onDestroyUsers }
}

export type useDestroyUsersHooksResult = ReturnType<typeof useDestroyUsers>
