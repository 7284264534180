import {
  forwardRef,
  IconButton,
  IconButtonProps,
  Tooltip,
} from '@chakra-ui/react'
import { BsInboxFill } from 'react-icons/bs'
type CsbArchiveButtonButtonPropsType = Omit<
  IconButtonProps,
  'children' | 'aria-label'
>

export const CsbArchiveButton = forwardRef<
  CsbArchiveButtonButtonPropsType,
  'button'
>(({ ...props }, ref) => {
  return (
    <Tooltip label={'アーカイブする'}>
      <IconButton
        colorScheme={'blue'}
        fontSize="20px"
        icon={<BsInboxFill />}
        ref={ref}
        rounded={'full'}
        {...props}
        aria-label="delete"
      />
    </Tooltip>
  )
})
