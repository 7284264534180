import { Box, Text } from '@chakra-ui/react'
import { CsbTable } from '@src/components/CsbTable'
import { PhishingEmailQuery } from '@src/graphql/generated/graphql'
import dayjs from 'dayjs'

type PhishingEmailScheduleArray =
  PhishingEmailQuery['phishingEmail']['phishingEmailSchedules']

type PhishingEmailSchedulesPropsType = {
  schedules?: PhishingEmailScheduleArray
}

export const PhishingEmailSchedules = ({
  schedules,
}: PhishingEmailSchedulesPropsType) => {
  return (
    <>
      <Box maxW={600} mb={'80px'} mt={35}>
        <CsbTable
          footer={false}
          labels={[
            { label: '配信日時' },
            { label: 'グループ名' },
            { label: '対象件数' },
          ]}
        >
          {(schedules ?? []).map(({ group, reservedAt, sendCount }, index) => (
            <CsbTable.TableRow key={index}>
              <CsbTable.TableCell w={220}>
                {dayjs(reservedAt).format('YYYY/MM/DD HH:mm') + ' 〜'}
              </CsbTable.TableCell>
              <CsbTable.TableCell w={260}>
                <Text isTruncated w={230}>
                  {group?.name ?? '無所属'}
                </Text>
              </CsbTable.TableCell>
              <CsbTable.TableCell textAlign={'right'} w={100}>
                {sendCount}件
              </CsbTable.TableCell>
            </CsbTable.TableRow>
          ))}
        </CsbTable>
      </Box>
    </>
  )
}
