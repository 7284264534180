import { LinkDomains } from '@src/components/pages/link-domains'
import { useLinkDomainsHooks } from '@src/components/pages/link-domains/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const LinkDomainsPage = memo(() => {
  const linkDomainsHooks = useLinkDomainsHooks()
  return (
    <Authorization>
      <LinkDomains {...linkDomainsHooks} />
    </Authorization>
  )
})
