import { useDisclosure } from '@chakra-ui/react'
import { PdfsQuery } from '@src/graphql/generated/graphql'
import { useDestroyPdf } from '@src/models/Pdf/useDestroyPdf'
import { useCallback, useState } from 'react'

type Pdf = PdfsQuery['pdfs']['collection'][number]

export const useDestroyPdfModal = () => {
  const [Pdf, setPdf] = useState<Pdf>()
  const { isOpen, onClose: _onClose, onOpen: _onOpen } = useDisclosure()
  const { isLoading, onDestroyPdf: _onDestroyPdf } = useDestroyPdf({
    onCompleted: () => {
      _onClose()
    },
  })
  const onClose = useCallback(() => {
    _onClose()
  }, [_onClose])

  const onOpen = useCallback(
    (Pdf: Pdf) => {
      _onOpen()
      setPdf(Pdf)
    },
    [_onOpen, setPdf]
  )

  const onDestroyPdf = useCallback(() => {
    if (!Pdf) return
    _onDestroyPdf(Pdf.uuid)
  }, [Pdf, _onDestroyPdf])

  return {
    Pdf,
    isLoading,
    isOpen,
    onClose,
    onDestroyPdf,
    onOpen,
  }
}

type UseDestroyPdfModalType = typeof useDestroyPdfModal
export type UseDestroyPdfModalResult = ReturnType<UseDestroyPdfModalType>
