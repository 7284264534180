import {
  Table,
  TableProps,
  TableRowProps,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { CsbPrimaryBox } from '@src/components/CsbPrimaryBox'
import { ComponentProps, ReactNode } from 'react'

type CsbTablePropsType = {
  children: ReactNode
  footer?: boolean
  labels:
    | string[]
    | {
        isNumeric?: boolean
        label: string
        width?: number
      }[]
  wrapperProps?: Omit<ComponentProps<typeof CsbPrimaryBox>, 'children'>
} & Omit<TableProps, 'children'>

export const CsbTable = ({
  children,
  footer = true,
  labels,
  wrapperProps,
  ...props
}: CsbTablePropsType) => {
  return (
    <CsbPrimaryBox {...wrapperProps} overflow={'auto'}>
      <Table colorScheme={'gray'} {...props}>
        <Thead>
          <Tr>
            {labels.map((item, index, array) => (
              <Th
                isNumeric={
                  typeof item === 'object'
                    ? item.isNumeric
                    : array.length - 1 === index
                }
                key={`${item}_${index}`}
                p={typeof item === 'object' && item.width === 0 ? 0 : undefined}
                w={typeof item === 'object' ? item.width : undefined}
              >
                {typeof item === 'object' ? item.label : item}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
        {footer && (
          <Tfoot>
            <Tr>
              {labels.map((item, index, array) => (
                <Th
                  isNumeric={
                    typeof item === 'object'
                      ? item.isNumeric
                      : array.length - 1 === index
                  }
                  key={`${item}_${index}`}
                  p={
                    typeof item === 'object' && item.width === 0 ? 0 : undefined
                  }
                  w={typeof item === 'object' ? item.width : undefined}
                >
                  {typeof item === 'object' ? item.label : item}
                </Th>
              ))}
            </Tr>
          </Tfoot>
        )}
      </Table>
    </CsbPrimaryBox>
  )
}

const TableRow = ({ onClick, ...props }: TableRowProps) => {
  const hover = onClick && { backgroundColor: 'gray.200', cursor: 'pointer' }
  return <Tr _hover={hover} {...props} onClick={onClick} />
}

CsbTable.TableCell = Td
CsbTable.TableRow = TableRow
