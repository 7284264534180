import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  Portal,
  RadioGroup,
  Spinner,
  Tag,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { Global } from '@emotion/react'
import { CsbFormControl, CsbHeader, CsbPrimaryBox } from '@src/components'
import { CsbNewLineText } from '@src/components/CsbNewLineText'
import { CsbQuestionCheckbox } from '@src/components/CsbQuestionCheckbox'
import { CsbQuestionRadio } from '@src/components/CsbQuestionRadio'
import { useQuestionnaireAnswerHooksResult } from '@src/components/pages/questionnaires/[id]/hooks'
import {
  AnswerQuestionnaireInput,
  ResearchQuestionsByResearchUuidQuery,
} from '@src/graphql/generated/graphql'
import { memo, useCallback, useEffect, useState } from 'react'
import { Controller, useForm, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const Q = ({
  findError,
  form,
  parentIndex,
  researchQuestion,
}: {
  findError: (attributeName: string) => string
  form: UseFormReturn<
    Omit<AnswerQuestionnaireInput, 'clientMutationId' | 'uuid'>
  >
  parentIndex: number
  researchQuestion: ResearchQuestionsByResearchUuidQuery['researchQuestionsByResearchUuid'][number]
}) => {
  // noinspection DuplicatedCode
  const _form = useForm<{
    _answers: string[]
  }>()
  // noinspection DuplicatedCode
  const { t } = useTranslation()
  const isIncludeNameNewLine = researchQuestion.name.indexOf('\n') > -1

  return (
    <Box textAlign={'left'}>
      <Heading fontSize={16}>
        <CsbNewLineText text={researchQuestion.name} />
        {researchQuestion.isRequired ? (
          <Tag
            bgColor={'red.500'}
            color={'white'}
            fontWeight={'medium'}
            ml={!isIncludeNameNewLine ? 2 : 0}
          >
            {t('required')}
          </Tag>
        ) : (
          <></>
        )}
      </Heading>
      <CsbFormControl errorText={findError(`answers.${parentIndex}`)} mt={4}>
        {researchQuestion.answerType === 'radio' ? (
          <>
            <Controller
              control={form.control}
              name={`answers.${parentIndex}.0`}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Grid gap={1} gridColumn={'1fr'}>
                    {researchQuestion.answers.map((answer) => (
                      <CsbQuestionRadio
                        isChecked={field.value === answer}
                        key={answer}
                        mr={5}
                        value={answer}
                      >
                        {answer}
                      </CsbQuestionRadio>
                    ))}
                  </Grid>
                </RadioGroup>
              )}
            />
          </>
        ) : researchQuestion.answerType === 'checkbox' ? (
          <>
            <Grid gap={1} gridColumn={'1fr'}>
              {researchQuestion.answers.map((answer, index) => (
                <Controller
                  control={_form.control}
                  key={answer}
                  name={`_answers.${index}`}
                  render={({ field: { onChange, value } }) => (
                    <CsbQuestionCheckbox
                      isChecked={
                        form.getValues(`answers.${parentIndex}.${index}`) ===
                        answer
                      }
                      value={value}
                      onChange={(e) => {
                        const currentChecked = e.target.checked
                        onChange(e.target.checked)
                        if (currentChecked) {
                          form.setValue(
                            `answers.${parentIndex}.${index}`,
                            answer
                          )
                        } else {
                          form.setValue(`answers.${parentIndex}.${index}`, '')
                        }
                      }}
                    >
                      {answer}
                    </CsbQuestionCheckbox>
                  )}
                />
              ))}
            </Grid>
          </>
        ) : (
          <>
            <Controller
              control={form.control}
              name={`answers.${parentIndex}.0`}
              render={({ field }) => <Textarea {...field} />}
            />
          </>
        )}
      </CsbFormControl>
    </Box>
  )
}

export const QuestionnaireAnswer = memo(
  ({
    answerQuestionnaire,
    hasResearch,
    id,
    isDisplayLogoInResearch,
    isResearchLoading,
    isSend,
    researchQuestions,
    setIsSend,
  }: useQuestionnaireAnswerHooksResult) => {
    const { t } = useTranslation()
    const [isFocused, setIsFocused] = useState(false)

    // 最初のエラーのindexを取得
    const findErrorIndex = useCallback(() => {
      return (
        researchQuestions.data?.researchQuestionsByResearchUuid.findIndex(
          (_, i) => {
            const message = answerQuestionnaire.findError(`answers.${i}`)
            const values = answerQuestionnaire.form.getValues(`answers.${i}`)
            return (
              message !== '' && (values?.filter((s) => !!s)?.length ?? 0) === 0
            )
          }
        ) ?? -1
      )
    }, [
      answerQuestionnaire,
      researchQuestions.data?.researchQuestionsByResearchUuid,
    ])

    useEffect(() => {
      if (isFocused) {
        return
      }
      const errorIndex = findErrorIndex()
      if (errorIndex !== -1) {
        const element = document.getElementById(`question${errorIndex}`)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
        setIsFocused(true)
      }
    }, [isFocused, findErrorIndex, setIsFocused])

    return (
      <>
        {!isResearchLoading && isDisplayLogoInResearch ? <CsbHeader /> : null}
        <Flex
          alignItems="center"
          backgroundColor="gray.200"
          flexDirection="column"
          minHeight={isDisplayLogoInResearch ? 'calc(100vh - 80px)' : '100vh'}
          p={20}
          width="100wh"
        >
          <Box
            backgroundColor={'white'}
            maxW={600}
            p={5}
            rounded={5}
            textAlign={'center'}
            w={'full'}
          >
            {isResearchLoading ? (
              <Portal>
                <Global
                  styles={{
                    body: {
                      overflow: 'hidden',
                    },
                  }}
                />
                <Box
                  backdropFilter={'blur(3px)'}
                  bg={'blackAlpha.600'}
                  h={'full'}
                  inset={0}
                  pos={'fixed'}
                  w={'full'}
                >
                  <Center h={'full'}>
                    <Spinner
                      color="blue.500"
                      emptyColor="gray.200"
                      size="xl"
                      speed="0.65s"
                      thickness="4px"
                    />
                  </Center>
                </Box>
              </Portal>
            ) : (
              <>
                {hasResearch ? (
                  <>
                    {!isSend ? (
                      <>
                        <Text>{t('surveyMessage')}</Text>
                        <CsbPrimaryBox
                          display={'flex'}
                          flexDirection={'column'}
                          gap={10}
                          mt={5}
                          px={5}
                          py={6}
                        >
                          {researchQuestions.data?.researchQuestionsByResearchUuid.map(
                            (researchQuestion, index) => {
                              return (
                                <div id={`question${index}`} key={index}>
                                  <Q
                                    findError={answerQuestionnaire.findError}
                                    form={answerQuestionnaire.form}
                                    key={researchQuestion.uuid}
                                    parentIndex={index}
                                    researchQuestion={researchQuestion}
                                  />
                                </div>
                              )
                            }
                          )}
                          <Button
                            isDisabled={isSend}
                            mt={10}
                            onClick={() => {
                              setIsFocused(false)
                              answerQuestionnaire.onAnswerQuestionnaire(
                                id ?? '',
                                () => setIsSend()
                              )()
                            }}
                          >
                            {t('submit')}
                          </Button>
                        </CsbPrimaryBox>
                      </>
                    ) : (
                      <>
                        <Box pt={8}>{t('thanksToAnswer')}</Box>
                        <Box pb={5} pt={5}>
                          {t('closeWindow')}
                        </Box>
                      </>
                    )}
                  </>
                ) : (
                  <Box pb={5} pt={8}>
                    {t('closeWindow')}
                  </Box>
                )}
              </>
            )}
          </Box>
        </Flex>
      </>
    )
  }
)
