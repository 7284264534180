import { ApolloError } from '@apollo/client'
import {
  CreateQuestionnaireInput,
  CreateQuestionnaireMutationResult,
  useCreateQuestionnaireMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors, useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { useSelectableResearches } from '@src/models/Research'
import { formReset } from '@src/utils/formReset'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

export const useCreateQuestionnaire = ({
  onCompleted,
  onError,
}: Partial<
  BaseOptionsType<CreateQuestionnaireMutationResult['data']>
> = {}) => {
  const toast = useToast()
  const [createQuestionnaire, { loading: isLoading }] =
    useCreateQuestionnaireMutation({
      refetchQueries: ['questionnaires'],
    })
  const form = useForm<Omit<CreateQuestionnaireInput, 'clientMutationId'>>()
  const { findErrorMessage, resetErrors, setErrors } =
    useGraphQLErrors<keyof Omit<CreateQuestionnaireInput, 'clientMutationId'>>()
  const resetForm = () => formReset(form, resetErrors)

  const researches = useSelectableResearches()

  const onCreateQuestionnaire = useCallback(
    () =>
      form.handleSubmit(async (inputData) => {
        try {
          const { data } = await createQuestionnaire({
            variables: {
              input: inputData,
            },
          })
          formReset(form, resetErrors)
          onCompleted?.(data)
          toast({
            status: 'success',
            title: 'アンケートURLを発行しました。',
          })
        } catch (e) {
          toast({
            status: 'error',
            title: 'アンケートURLの発行に失敗しました。',
          })
          if (e instanceof ApolloError) {
            setErrors(e.graphQLErrors)
            onError?.(e)
          }
        }
      })(),
    [
      resetErrors,
      createQuestionnaire,
      setErrors,
      toast,
      form,

      onCompleted,
      onError,
    ]
  )

  return {
    findErrorMessage,
    form,
    isLoading,
    isResearchSelectable: true,
    onCreateQuestionnaire,
    researches,
    resetErrors,
    resetForm,
  }
}

type useCreateQuestionnaireType = typeof useCreateQuestionnaire
export type useCreateQuestionnaireResult =
  ReturnType<useCreateQuestionnaireType>
// export type useCreateQuestionnaireParameter = Parameters<useCreateQuestionnaireType>[0]
