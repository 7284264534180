import {
  forwardRef,
  IconButton,
  IconButtonProps,
  Tooltip,
} from '@chakra-ui/react'
import { BsReplyFill } from 'react-icons/bs'
type CsbArchiveButtonButtonPropsType = Omit<
  IconButtonProps,
  'children' | 'aria-label'
>

export const CsbUnArchiveButton = forwardRef<
  CsbArchiveButtonButtonPropsType,
  'button'
>(({ ...props }, ref) => {
  return (
    <Tooltip label={'アーカイブを解除する'}>
      <IconButton
        colorScheme={'blue'}
        fontSize="20px"
        icon={<BsReplyFill />}
        ref={ref}
        rounded={'full'}
        {...props}
        aria-label="delete"
      />
    </Tooltip>
  )
})
