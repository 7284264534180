import {
  Button,
  Flex,
  Heading,
  HStack,
  Skeleton,
  Stack,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import {
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
  CsbPagination,
} from '@src/components'
import { CsbEditButton } from '@src/components/Buttons/CsbEditButton'
import { CsbTable } from '@src/components/CsbTable'
import { useModal } from '@src/components/Modal/useModal'
import { useAttachesHooks } from '@src/components/pages/attaches/hooks'
import type { AttachesQuery as _AttachesQuery } from '@src/graphql/generated/graphql'
import { Base } from '@src/layouts'
import { AttachesQuery } from '@src/models/Attach/AttachesQuery'
import { UpdateAttachForm } from '@src/models/Attach/components/UpdateAttachForm/UpdateAttachForm'
import { useUpdateAttachForm } from '@src/models/Attach/components/UpdateAttachForm/useUpdateForm'
import { memo, useCallback } from 'react'

type Attach = _AttachesQuery['attaches']['collection'][number]

//添付ファイル一覧ページのcomponent
export const Attaches = memo(() => {
  const { attaches, current, onChangePage } = useAttachesHooks()
  const [Modal, { onClose: _onClose, onOpen: _onOpen }] = useModal()
  const { findErrorMessage, form, isLoading, onUpdateAttach, resetForm } =
    useUpdateAttachForm({
      onCompleted: () => {
        _onClose()
      },
    })
  const onClose = useCallback(() => {
    resetForm()
    _onClose()
  }, [_onClose, resetForm])

  const onOpen = useCallback(
    async (attach: Attach) => {
      form.setValue('uuid', attach.uuid)
      form.setValue('name', attach.name)
      form.setValue('fileName', attach.fileName)
      _onOpen()
    },
    [_onOpen, form]
  )

  return (
    <>
      <Base left={<Heading>添付ファイル管理</Heading>}>
        <CsbTable
          labels={['管理ラベル', '添付ファイル名', '']}
          wrapperProps={{ mt: 35 }}
        >
          <AttachesQuery
            {...attaches}
            loadingFallback={
              <>
                <Tr>
                  <Td colspan={3} h={'73px'} px={4} py={2}>
                    <Skeleton h={'full'} rounded={'none'} w={'full'} />
                  </Td>
                </Tr>
                <Tr>
                  <Td colspan={3} h={'73px'} px={4} py={2}>
                    <Skeleton h={'full'} rounded={'none'} w={'full'} />
                  </Td>
                </Tr>
              </>
            }
          >
            {({ attaches }) => (
              <>
                {attaches.collection.map((attach) => (
                  <CsbTable.TableRow key={attach.uuid}>
                    <CsbTable.TableCell w={300}>
                      <Tooltip aria-label="A tooltip" label={attach.name}>
                        <Text isTruncated fontWeight={'bold'} w={300}>
                          {attach.name}
                        </Text>
                      </Tooltip>
                    </CsbTable.TableCell>
                    <CsbTable.TableCell w={300}>
                      <Tooltip aria-label="A tooltip" label={attach.fileName}>
                        <Text
                          isTruncated
                          color={'gray.500'}
                          fontSize={'sm'}
                          w={300}
                        >
                          {attach.fileName}
                        </Text>
                      </Tooltip>
                    </CsbTable.TableCell>
                    <CsbTable.TableCell isNumeric>
                      <Flex gridGap={5} justifyContent={'end'}>
                        <CsbEditButton
                          onClick={(e) => {
                            e.stopPropagation()
                            onOpen(attach)
                          }}
                        />
                      </Flex>
                    </CsbTable.TableCell>
                  </CsbTable.TableRow>
                ))}
              </>
            )}
          </AttachesQuery>
        </CsbTable>
        <AttachesQuery {...attaches} loadingFallback={<></>}>
          {({ attaches }) => (
            <Flex mt={5}>
              <CsbPagination
                current={current}
                defaultCurrent={current}
                pageSize={attaches.metadata.limitValue}
                total={attaches.metadata.totalCount}
                onChange={onChangePage()}
              />
            </Flex>
          )}
        </AttachesQuery>
      </Base>
      <Modal onClose={onClose}>
        <CsbModalHeader>ファイル情報の編集</CsbModalHeader>
        <CsbModalBody mt={10}>
          <Stack spacing={6}>
            <UpdateAttachForm findErrorMessage={findErrorMessage} form={form} />
          </Stack>
        </CsbModalBody>
        <CsbModalFooter mt={7}>
          <HStack spacing={6}>
            <Button colorScheme={'gray'} onClick={onClose}>
              キャンセル
            </Button>
            <Button
              isLoading={isLoading}
              onClick={form.handleSubmit(onUpdateAttach)}
            >
              編集
            </Button>
          </HStack>
        </CsbModalFooter>
      </Modal>
    </>
  )
})
