import { Button, HStack, Stack } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import {
  AdminResearchQuestionForm,
  UseAdminCreateResearchQuestionModalResult,
} from '@src/models/AdminResearch'

export const CreateAdminResearchQuestionModal = ({
  findErrorMessage,
  form,
  id,
  isLoading,
  isOpen,
  onClose,
  onCreateAdminResearchQuestion,
}: UseAdminCreateResearchQuestionModalResult) => (
  <CsbModal isOpen={isOpen} onClose={onClose}>
    <CsbModalHeader>共通アンケートを作成する</CsbModalHeader>
    <CsbModalBody mt={10}>
      <Stack spacing={6}>
        <AdminResearchQuestionForm
          findErrorMessage={findErrorMessage}
          form={form}
        />
      </Stack>
    </CsbModalBody>
    <CsbModalFooter mt={7}>
      <HStack spacing={6}>
        <Button colorScheme={'gray'} onClick={onClose}>
          キャンセル
        </Button>
        <Button
          isLoading={isLoading}
          onClick={onCreateAdminResearchQuestion(id ?? '')}
        >
          作成
        </Button>
      </HStack>
    </CsbModalFooter>
  </CsbModal>
)
