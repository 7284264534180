import { useToast } from '@chakra-ui/react'
import {
  PhishingEmail,
  usePhishingEmailsQuery,
  useUnarchivePhishingEmailMutation,
} from '@src/graphql/generated/graphql'
import { useMe, usePagination } from '@src/hooks'
import { useCsbDataModal } from '@src/hooks/useCsbDataModal'
import { MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

//フィッシングアーカイブ一覧で使うhooksのまとめ
export const usePhishingArchivesHooks = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { role } = useMe()
  const isWritePhishingMail = !!role?.writePhishingMail
  const dataModal =
    useCsbDataModal<
      Pick<
        PhishingEmail,
        | 'uuid'
        | 'sentAt'
        | 'name'
        | 'draft'
        | 'openCount'
        | 'sendCount'
        | 'loginCount'
      >
    >()

  //ページネーション
  const usePaginationResult = usePagination()

  const phishingEmailsQuery = usePhishingEmailsQuery({
    variables: {
      isArchive: true,
      pagination: {
        limit: 30,
        page: usePaginationResult.current,
      },
    },
  })

  const [unarchivePhishingEmailMutation, { loading: isUnArchiving }] =
    useUnarchivePhishingEmailMutation()

  const onReusePhishingMail =
    (uuid: PhishingEmail['uuid']) => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      navigate(`/phishing/new?reuseId=${uuid}`)
    }

  const onNavigateResult = (uuid: PhishingEmail['uuid']) => {
    navigate(`/phishing/${uuid}/result`)
  }

  const onUnArchivePhishingEmail = async () => {
    try {
      //削除
      await unarchivePhishingEmailMutation({
        variables: {
          input: {
            uuid: dataModal.data?.uuid ?? '',
          },
        },
      })
      dataModal.onClose()

      //削除したことを通知する
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'success',
        title: `${dataModal.data?.name}のアーカイブを解除しました`,
      })

      //ページの情報をrefetch
      const {
        data: {
          phishingEmails: { metadata },
        },
      } = await phishingEmailsQuery.refetch()

      //refetch後ページャーの中身が0の場合
      const isEmptyPage = metadata.totalCount % metadata.limitValue === 0
      //refetch後ページャーが最初のページの場合
      const isInitialPage = metadata.currentPage === 0
      //ページャーの中身が0かつ2ページ目以降の場合
      if (isEmptyPage && !isInitialPage) {
        //ページャーの値を現在のページ-1ページに更新する
        usePaginationResult.onChangePage('/phishing/archives')(
          metadata.currentPage - 1
        )
      }
    } catch {
      //エラーのときの処理
      //e.graphQLErrors[0]は空なため一旦uuidで対応
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: `エラー${dataModal.data?.name}のアーカイブを解除できませんでした。`,
      })
    }
  }

  return {
    navigate,
    phishingArchivesContents: {
      dataModal,
      isUnArchiving,
      isWritePhishingMail,
      onNavigateResult,
      onReusePhishingMail,
      onUnArchivePhishingEmail,
    },
    phishingEmailsQuery,
    usePaginationResult,
  }
}

export type usePhishingArchivesHooksResult = ReturnType<
  typeof usePhishingArchivesHooks
>
