import { Button } from '@chakra-ui/react'
import { CsbNextLink } from '@src/components'
import { parse } from 'query-string'
import { memo } from 'react'
import { useLocation, useMatch } from 'react-router-dom'

type MenuItemPropsType = {
  href: string
  icon: JSX.Element
  text: string
}

export const CsbAsideMenuItem = memo(
  ({ href, icon, text }: MenuItemPropsType) => {
    const location = useLocation()
    const match = useMatch(href)
    const isPhishingArchivesPage =
      location.pathname === '/phishing/archives' &&
      href === '/phishing/archives'
    const isPhishingPage = match && href === '/phishing'
    const { isDeliveryContentsOnly } = parse(location.search, {
      parseBooleans: true,
    })

    const isActive = () => {
      if (isDeliveryContentsOnly) {
        if (/isDeliveryContentsOnly/.test(href)) {
          return 'gray.100'
        }
        return undefined
      }

      //アーカイブページの場合はactiveを返す
      if (isPhishingArchivesPage) {
        return 'gray.100'
      }

      //フィッシング＋フィッシングの下層ページの場合
      if (isPhishingPage) {
        //アーカイブページ以外にactiveを返す
        return location.pathname === '/phishing/archives'
          ? undefined
          : 'gray.100'
      }
      if (match) {
        return 'gray.100'
      }

      return undefined
    }

    return (
      <CsbNextLink href={href}>
        <Button
          as={'div'}
          bg={isActive()}
          colorScheme={'gray'}
          justifyContent={'left'}
          leftIcon={icon}
          variant="ghost"
          w={'100%'}
        >
          {text}
        </Button>
      </CsbNextLink>
    )
  }
)
