import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Link,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { CsbDeleteButton, CsbPagination, CsbSearchInput } from '@src/components'
import { CsbEditButton } from '@src/components/Buttons/CsbEditButton'
import { CsbTable } from '@src/components/CsbTable'
import { useDocumentsHooks } from '@src/components/pages/documents/hooks'
import { Base } from '@src/layouts'
import { CreateDocumentModal } from '@src/models/Document/components/CreateDocumentModal/CreateDocumentModal'
import { DestroyDocumentModal } from '@src/models/Document/components/DestroyDocumentModal/DestroyDocumentModal'
import { UpdateDocumentModal } from '@src/models/Document/components/UpdateDocumentModal/UpdateDocumentModal'
import { DocumentsQuery } from '@src/models/Document/DocumentsQuery'
import { memo } from 'react'

//フィシイング一覧ページのcomponent
export const Documents = memo(() => {
  const {
    createDocumentModal,
    current,
    destroyDocumentModal,
    documents,
    isWriteDocument,
    onChangePage,
    onResetSearch,
    onSearch,
    searchForm,
    updateDocumentModal,
  } = useDocumentsHooks()
  return (
    <Base
      left={<Heading>ドキュメント管理</Heading>}
      right={
        isWriteDocument && (
          <Button w={210} onClick={createDocumentModal.onOpen}>
            ドキュメントの作成
          </Button>
        )
      }
    >
      <Box maxW={518} mt={12} w={'full'}>
        <CsbSearchInput
          placeholder={'ドキュメント名を入力'}
          {...searchForm.register('searchWord')}
          onReset={onResetSearch}
          onSearch={onSearch}
        />
      </Box>
      <DocumentsQuery {...documents}>
        {({ documents }) => (
          <>
            <CsbTable
              labels={['ドキュメント名', 'チェンジログ', 'URL', '']}
              wrapperProps={{ mt: 35 }}
            >
              {documents.collection.map((document) => (
                <CsbTable.TableRow key={document.uuid}>
                  <CsbTable.TableCell w={300}>
                    <Tooltip aria-label="A tooltip" label={document.name}>
                      <Text isTruncated fontWeight={'bold'} w={300}>
                        {document.name}
                      </Text>
                    </Tooltip>
                  </CsbTable.TableCell>
                  <CsbTable.TableCell w={300}>
                    <Tooltip aria-label="A tooltip" label={document.changeLog}>
                      <Text
                        isTruncated
                        color={'gray.500'}
                        fontSize={'sm'}
                        w={300}
                      >
                        {document.changeLog}
                      </Text>
                    </Tooltip>
                  </CsbTable.TableCell>
                  <CsbTable.TableCell>
                    <Tooltip aria-label="A tooltip" label={document.url}>
                      <Text isTruncated color={'gray.500'} fontSize={'sm'}>
                        <Link isExternal href={document.url}>
                          {document.url}
                        </Link>
                      </Text>
                    </Tooltip>
                  </CsbTable.TableCell>
                  <CsbTable.TableCell isNumeric>
                    {isWriteDocument && (
                      <Flex gridGap={5} justifyContent={'end'}>
                        <CsbEditButton
                          onClick={(e) => {
                            e.stopPropagation()
                            updateDocumentModal.onOpen(document)
                          }}
                        />
                        <CsbDeleteButton
                          onClick={(e) => {
                            e.stopPropagation()
                            destroyDocumentModal.onOpen(document)
                          }}
                        />
                      </Flex>
                    )}
                  </CsbTable.TableCell>
                </CsbTable.TableRow>
              ))}
            </CsbTable>
            <Flex mt={5}>
              <CsbPagination
                current={current}
                defaultCurrent={current}
                pageSize={documents.metadata.limitValue}
                total={documents.metadata.totalCount}
                onChange={onChangePage()}
              />
              <HStack ml={'auto'} spacing={3}>
                {isWriteDocument && (
                  <Button w={210} onClick={createDocumentModal.onOpen}>
                    ドキュメントの作成
                  </Button>
                )}
              </HStack>
            </Flex>
          </>
        )}
      </DocumentsQuery>
      <UpdateDocumentModal {...updateDocumentModal} />
      <CreateDocumentModal {...createDocumentModal} />
      <DestroyDocumentModal {...destroyDocumentModal} />
    </Base>
  )
})
