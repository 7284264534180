import { ApolloError } from '@apollo/client'
import {
  CreateResearchQuestionInput,
  CreateResearchQuestionMutationResult,
  ResearchAnswerType,
  useCreateResearchQuestionMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors, useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { formReset } from '@src/utils/formReset'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

export const useCreateResearchQuestion = ({
  onCompleted,
  onError,
}: Partial<
  BaseOptionsType<CreateResearchQuestionMutationResult['data']>
> = {}) => {
  const toast = useToast()
  const [createResearchQuestion, { loading: isLoading }] =
    useCreateResearchQuestionMutation({
      refetchQueries: ['research'],
    })
  const form = useForm<
    Omit<CreateResearchQuestionInput, 'clientMutationId' | 'researchUuid'>
  >({
    defaultValues: {
      answerType: ResearchAnswerType.Checkbox,
      answers: new Array(4).fill(''),
      isRequired: true,
    },
  })
  const { findErrorMessage, resetErrors, setErrors } =
    useGraphQLErrors<
      keyof Omit<
        CreateResearchQuestionInput,
        'clientMutationId' | 'researchUuid'
      >
    >()

  const resetForm = () => {
    formReset(form, resetErrors)
  }

  const onCreateResearchQuestion = useCallback(
    (researchUuid: CreateResearchQuestionInput['researchUuid']) =>
      form.handleSubmit(async ({ answerType, answers, ...inputData }) => {
        try {
          const { data } = await createResearchQuestion({
            variables: {
              input: {
                ...inputData,
                answerType,
                answers:
                  answerType === ResearchAnswerType.Textarea
                    ? []
                    : answers.filter(Boolean),
                researchUuid,
              },
            },
          })
          formReset(form, resetErrors)
          onCompleted?.(data)
          toast({
            status: 'success',
            title: 'アンケートの更新に成功しました。',
          })
        } catch (e) {
          toast({
            status: 'error',
            title: 'アンケートの更新に失敗しました。',
          })
          if (e instanceof ApolloError) {
            setErrors(e.graphQLErrors)
            onError?.(e)
          }
        }
      }),
    [
      form,
      setErrors,
      resetErrors,
      toast,
      onCompleted,
      onError,
      createResearchQuestion,
    ]
  )

  return {
    findErrorMessage,
    form,
    isLoading,
    onCreateResearchQuestion,
    resetForm,
  }
}

type UseCreateResearchQuestionType = typeof useCreateResearchQuestion
export type UseCreateResearchQuestionResult =
  ReturnType<UseCreateResearchQuestionType>
// export type UseCreateResearchQuestionParameter = Parameters<UseCreateResearchQuestionType>[0]
