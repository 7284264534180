import { Box } from '@chakra-ui/react'
import { memo } from 'react'
import { useDropzone } from 'react-dropzone'

type CsbDropzonePropsType = ReturnType<typeof useDropzone>

export const CsbDropzone = memo(
  ({ getInputProps, getRootProps, isDragActive }: CsbDropzonePropsType) => {
    return (
      <Box
        {...getRootProps()}
        _hover={{
          backgroundColor: 'gray.50',
        }}
        alignItems={'center'}
        bg={isDragActive ? 'gray.50' : 'white'}
        borderRadius={10}
        borderStyle={'dashed'}
        borderWidth={'1px'}
        color={'gray.500'}
        cursor={'pointer'}
        d={'flex'}
        flexDirection={'column'}
        fontWeight={'bold'}
        h={150}
        justifyContent={'center'}
        p={3}
        w={150}
      >
        <input {...getInputProps()} />
        <small>画像を選択</small>
        <small>ドラッグ&ドロップ</small>
      </Box>
    )
  }
)
