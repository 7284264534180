import { UseFormReturn } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'

export const formReset = <T extends FieldValues>(
  form: UseFormReturn<T>,
  callback?: VoidFunction
) => {
  Object.keys(form.getValues()).forEach((name) => {
    form.resetField(name as keyof typeof form.getValues)
  })
  callback?.()
}
