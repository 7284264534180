import { Learn } from '@src/components/pages/learn'
import { useLearnHooks } from '@src/components/pages/learn/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const LearnPage = memo(() => {
  const learnHooks = useLearnHooks()
  return (
    <Authorization>
      <Learn {...learnHooks} />
    </Authorization>
  )
})
