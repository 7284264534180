import { ArrowDownIcon, ArrowUpIcon, DeleteIcon } from '@chakra-ui/icons'
import {
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  IconButton,
  Input,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { CsbFormControl, CsbPrimaryBox } from '@src/components'
import { useLearnDetailHooksResult } from '@src/components/pages/learn/[id]/hooks'
import { QuestionInput } from '@src/graphql/generated/graphql'
import { Base } from '@src/layouts'
import { findError } from '@src/utils/findError'
import { ChangeEvent, Fragment, memo, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types'

type FormValues = {
  questions: QuestionInput[]
}

type AnswersPropsType = {
  form: UseFormReturn<FormValues>
  isWriteLearningText: boolean
  parentIndex: number
}

//回答のform
const Answers = ({
  form,
  isWriteLearningText,
  parentIndex,
}: AnswersPropsType) => {
  const { append, fields, remove } = useFieldArray({
    control: form.control,
    name: `questions.${parentIndex}.corrects`,
  })

  //回答を追加する関数
  const onAddAnswer = () => {
    append({
      correct: false,
      name: '',
    })
  }

  const isMaxAnswerLength = fields.length >= 6

  //正解のboolを管理するstate
  const [corrects, setCorrects] = useState(fields.map(({ correct }) => correct))

  /**
   * チェックボックスを管理するstate
   * @param index
   */
  const onChangeCheckBox =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      //現在の配列から新しい配列を生成し、すべてfalseをセットする
      const newCorrects = corrects.map((_) => false)
      //onChangeされた値を代入する
      newCorrects[index] = event.target.checked
      //上記で作った配列をsetCorrectsにわたす
      setCorrects(newCorrects)
      //react hook formに反映させる
      newCorrects.forEach((value, i) => {
        form.setValue(`questions.${parentIndex}.corrects.${i}.correct`, value)
      })
    }

  return (
    <>
      <Grid mt={3} rowGap={1}>
        {fields.map((item, index) => (
          <Fragment key={`${item.id}_${index}`}>
            <Grid alignItems={'center'} templateColumns={'auto 1fr auto'}>
              <FormControl
                alignItems={'center'}
                display={'flex'}
                gridColumn={'2/3'}
              >
                <FormLabel display={'block'} flexShrink={0} my={0}>
                  回答
                </FormLabel>
                <Checkbox
                  gridColumn={'3/4'}
                  isChecked={corrects[index]}
                  onChange={onChangeCheckBox(index)}
                />
                <Input
                  ml={2}
                  {...form.register(
                    `questions.${parentIndex}.corrects.${index}.name`
                  )}
                />
                {isWriteLearningText && (
                  <IconButton
                    aria-label="delete"
                    colorScheme={'red'}
                    gridColumn={'1/2'}
                    icon={<DeleteIcon />}
                    isDisabled={fields.length <= 2}
                    ml={1}
                    onClick={() => remove(index)}
                  />
                )}
              </FormControl>
            </Grid>
          </Fragment>
        ))}
      </Grid>
      {isWriteLearningText && (
        <Button isDisabled={isMaxAnswerLength} mt={3} onClick={onAddAnswer}>
          回答を追加する
        </Button>
      )}
    </>
  )
}

//学習詳細ページのcomponent
export const LearnDetail = memo(
  ({
    data,
    errors,
    form,
    isWriteLearningText,
    loading,
    navigate,
    onAddQuestion,
    onSubmit,
    questionFields,
    questionMove,
    questionRemove,
  }: useLearnDetailHooksResult) => {
    const move = (index: number, direction: 'up' | 'down') => {
      const diff = direction === 'up' ? -1 : 1
      questionMove(index, index + diff)
    }

    return (
      <Base
        left={<Heading>{data?.learn.name}の編集</Heading>}
        right={
          <HStack spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧へ戻る
            </Button>
            {isWriteLearningText && <Button onClick={onSubmit}>保存</Button>}
          </HStack>
        }
      >
        <CsbPrimaryBox pb={'72px'} pt={35} px={45}>
          <Grid rowGap={10}>
            {data &&
              !loading &&
              questionFields.map((questionField, questionIndex) => (
                <VStack
                  align={'start'}
                  key={`${questionField.id}_${questionIndex}`}
                  spacing={55}
                >
                  <CsbPrimaryBox p={5} position={'relative'} w={'full'}>
                    {isWriteLearningText && (
                      <ButtonGroup alignContent={'center'} spacing={1}>
                        {questionField.uuid && (
                          <>
                            <IconButton
                              aria-label="up"
                              disabled={questionIndex === 0}
                              icon={<ArrowUpIcon />}
                              position={'absolute'}
                              right={0}
                              top={0}
                              transform={'translate(-160%,-50%)'}
                              onClick={() => move(questionIndex, 'up')}
                            />
                            <IconButton
                              aria-label="down"
                              disabled={
                                questionIndex === questionFields.length - 1
                              }
                              icon={<ArrowDownIcon />}
                              position={'absolute'}
                              right={0}
                              top={0}
                              transform={'translate(-55%,-50%)'}
                              onClick={() => move(questionIndex, 'down')}
                            />
                          </>
                        )}
                        <IconButton
                          aria-label="delete"
                          colorScheme={'red'}
                          icon={<DeleteIcon />}
                          isDisabled={questionFields.length === 1}
                          position={'absolute'}
                          right={0}
                          top={0}
                          transform={'translate(50%,-50%)'}
                          onClick={() => questionRemove(questionIndex)}
                        />
                      </ButtonGroup>
                    )}
                    <CsbFormControl
                      errorText={
                        findError(errors, `name${questionIndex}`)?.message
                      }
                      labelText={'設問'}
                    >
                      <Textarea
                        {...form.register(`questions.${questionIndex}.name`)}
                      />
                    </CsbFormControl>
                    <Answers
                      form={form}
                      isWriteLearningText={isWriteLearningText}
                      parentIndex={questionIndex}
                    />
                    <CsbFormControl
                      errorText={
                        findError(errors, `explanation${questionIndex}`)
                          ?.message
                      }
                      labelText={'問題の解説'}
                      mt={5}
                    >
                      <Textarea
                        mt={1}
                        rows={5}
                        {...form.register(
                          `questions.${questionIndex}.explanation`
                        )}
                      />
                    </CsbFormControl>
                  </CsbPrimaryBox>
                </VStack>
              ))}
          </Grid>
          {isWriteLearningText && (
            <Button mt={5} onClick={onAddQuestion}>
              問題を追加する
            </Button>
          )}
        </CsbPrimaryBox>
        <Flex mt={5}>
          <HStack ml={'auto'} spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧へ戻る
            </Button>
            {isWriteLearningText && <Button onClick={onSubmit}>保存</Button>}
          </HStack>
        </Flex>
      </Base>
    )
  }
)
