import { ChevronDownIcon, Icon } from '@chakra-ui/icons'
import { Box } from '@chakra-ui/react'
import {
  ArrowIndicator,
  SelectButton,
  SelectControl,
  SelectedItemTag,
  SelectMenu,
  SelectMenuList,
  SelectMultiple,
  SelectMultipleProps,
  SelectOption,
  SelectSearchInput,
  SelectValueContainer,
} from 'chakra-ui-select'
import { memo } from 'react'

export type MultiSelectOptionType = {
  label: string
  value: string
}

type CsbMultiSelectPropsType = {
  initialSelectedItems: MultiSelectOptionType[]
  isDisabled?: boolean
  onChangeSelected: (selectedItem: MultiSelectOptionType[]) => void
  options: MultiSelectOptionType[]
} & Omit<
  SelectMultipleProps,
  'initialSelectedItems' | 'isDisabled' | 'onChange' | 'children'
>

/**
 * @see https://github.com/ejoc/chakra-ui-select#select-multiple-example
 */
export const CsbMultiSelect = memo(
  ({
    initialSelectedItems,
    isDisabled = false,
    onChangeSelected,
    options,
    ...props
  }: CsbMultiSelectPropsType) => {
    const itemToString = (item: MultiSelectOptionType | null) =>
      item?.label ?? ''
    return (
      <SelectMultiple
        initialSelectedItems={initialSelectedItems}
        isDisabled={isDisabled}
        my={4}
        w="full"
        onChange={(changes) => onChangeSelected(changes.selectedItems ?? [])}
        {...props}
      >
        {({ inputValue, selectedItems }) => {
          const getFilteredItems = (currentItems: MultiSelectOptionType[]) => {
            return currentItems.filter((item: MultiSelectOptionType) => {
              const isSelected = !selectedItems
                .map((n) => n.value)
                .includes(item.value)
              if (inputValue) {
                return (
                  isSelected &&
                  itemToString(item)
                    .toLowerCase()
                    .startsWith(inputValue.toLowerCase())
                )
              }
              return isSelected
            })
          }
          const items = getFilteredItems(options)
          return (
            <>
              <SelectControl borderColor={'gray.200'} shadow={'none'}>
                <SelectValueContainer>
                  {selectedItems?.map((selectedItem, index) => (
                    <SelectedItemTag
                      colorScheme={'gray'}
                      index={index}
                      key={`issues-item-${index}`}
                      pointerEvents={isDisabled ? 'none' : 'auto'}
                      selectedItem={selectedItem}
                    >
                      {selectedItem.label}
                    </SelectedItemTag>
                  ))}
                  <SelectSearchInput
                    placeholder={props.placeholder ?? 'なし'}
                  />
                </SelectValueContainer>
                <SelectButton aria-label="toggle menu">
                  <ArrowIndicator>
                    <Icon as={ChevronDownIcon} boxSize="1.2em" />
                  </ArrowIndicator>
                </SelectButton>
              </SelectControl>
              <SelectMenu>
                <SelectMenuList
                  bg={'gray.50'}
                  border={'1px solid'}
                  borderColor={'gray.300'}
                >
                  {items.map((option, index) => (
                    <SelectOption
                      index={index}
                      key={option.value}
                      value={option}
                    >
                      {option.label}
                    </SelectOption>
                  ))}
                  {items.length <= 0 && (
                    <Box pl={3} pr={9} py={2}>
                      なし
                    </Box>
                  )}
                </SelectMenuList>
              </SelectMenu>
            </>
          )
        }}
      </SelectMultiple>
    )
  }
)
