import { PhishingArchives } from '@src/components/pages/phishing/archives'
import { usePhishingArchivesHooks } from '@src/components/pages/phishing/archives/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

//フィッシングアーカイブ一覧
export const PhishingArchivesPage = memo(() => {
  const phishingArchivesHooks = usePhishingArchivesHooks()
  return (
    <Authorization>
      <PhishingArchives {...phishingArchivesHooks} />
    </Authorization>
  )
})
