import { Box, Button, Center, Flex, Heading, Text } from '@chakra-ui/react'
import { Global } from '@emotion/react'
import { CsbHeader } from '@src/components'
import { useNavigate } from 'react-router-dom'

export const NotFoundPage = () => {
  const navigate = useNavigate()
  return (
    <Flex flexDirection={'column'} h={'full'}>
      <Global
        styles={{
          '#root': {
            height: '100%',
          },
          'html,body': {
            height: '100%',
          },
        }}
      />
      <CsbHeader />
      <Box as={'main'} flex={1} px={'15px'} py={20}>
        <Center flexDirection={'column'} h={'full'} pb={20}>
          <Heading fontSize={100} lineHeight={1}>
            404
          </Heading>
          <Text fontSize={30} fontWeight={'bold'} lineHeight={1} mt={5}>
            お探しのページは存在しません。
          </Text>
          <Text
            fontWeight={'bold'}
            lineHeight={1.5}
            maxW={600}
            mt={3}
            w={'full'}
          >
            お探しのページはすでに削除されている・公開期間が終わっている・
            アクセスしたアドレスが異なっているなどの理由で見つかりませんでした。
          </Text>
          <Button mt={10} onClick={() => navigate(-1)}>
            前のページに戻る
          </Button>
        </Center>
      </Box>
    </Flex>
  )
}
