import { ApolloError } from '@apollo/client'
import {
  DestroyPdfInput as _DestroyPdfInput,
  DestroyPdfMutationResult,
  useDestroyPdfMutation,
} from '@src/graphql/generated/graphql'
import { useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { useCallback } from 'react'

type DestroyPdfInput = Omit<_DestroyPdfInput, 'clientMutationId'>

export const useDestroyPdf = ({
  onCompleted,
  onError,
}: Partial<BaseOptionsType<DestroyPdfMutationResult['data']>> = {}) => {
  const [destroyPdf, { loading: isLoading }] = useDestroyPdfMutation({
    refetchQueries: ['pdfs'],
  })
  const toast = useToast()
  const onDestroyPdf = useCallback(
    async (uuid: DestroyPdfInput['uuid']) => {
      try {
        const { data } = await destroyPdf({
          variables: {
            input: { uuid },
          },
        })
        toast({
          status: 'success',
          title: 'PDFを削除しました',
        })
        onCompleted?.(data)
      } catch (e) {
        toast({
          status: 'error',
          title: 'PDFの削除に失敗しました。',
        })
        if (e instanceof ApolloError) {
          onError?.(e)
        }
      }
    },
    [onError, toast, onCompleted, destroyPdf]
  )

  return { isLoading, onDestroyPdf }
}
