import { useMe } from '@src/hooks'
import { useNavigate } from 'react-router-dom'

//企業詳細で使うhooksのまとめ
export const useCompaniesDetailHooks = () => {
  const { company, role } = useMe()
  const isWriteCompany = !!role?.writeCompany
  const navigate = useNavigate()

  //ページ遷移させる関数
  const onNavigatePage = (page: 'index' | 'edit') => () => {
    switch (page) {
      case 'index':
        return navigate(isWriteCompany ? '/companies' : '/dashboard')
      case 'edit':
        return navigate(`/companies/${company?.uuid}/edit`)
      default:
        new Error(`${page} does not exist`)
    }
  }

  return {
    company,
    isWriteCompany,
    onNavigatePage,
  }
}

export type useCompaniesDetailHooksResult = ReturnType<
  typeof useCompaniesDetailHooks
>
