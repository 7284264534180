import { Grid, Input, Textarea } from '@chakra-ui/react'
import { CsbFormControl } from '@src/components'
import {
  UseCreateAdminResearchResult,
  UseUpdateAdminResearchResult,
} from '@src/models/AdminResearch'
import { addMonths, format } from 'date-fns'

export const AdminResearchForm = ({
  findErrorMessage,
  form,
}:
  | Pick<UseCreateAdminResearchResult, 'form' | 'findErrorMessage'>
  | Pick<UseUpdateAdminResearchResult, 'form' | 'findErrorMessage'>) => {
  return (
    <>
      <CsbFormControl errorText={findErrorMessage('name')}>
        <Input {...form.register('name')} placeholder={'アンケートの名前*'} />
      </CsbFormControl>
      <CsbFormControl errorText={findErrorMessage('description')}>
        <Textarea
          {...form.register('description')}
          placeholder={'アンケートの説明'}
        />
      </CsbFormControl>
      <Grid gap={2}>
        <CsbFormControl
          errorText={findErrorMessage('publishedAt')}
          labelText={'アンケート開始日'}
        >
          <Input
            defaultValue={format(new Date(), 'yyyy-MM-dd')}
            mt={-1}
            type={'date'}
            {...form.register('publishedAt')}
          />
        </CsbFormControl>
        <CsbFormControl
          errorText={findErrorMessage('closedAt')}
          labelText={'アンケート終了日'}
        >
          <Input
            defaultValue={format(addMonths(new Date(), 1), 'yyyy-MM-dd')}
            mt={-1}
            type={'date'}
            {...form.register('closedAt')}
          />
        </CsbFormControl>
      </Grid>
    </>
  )
}
