import { memo } from 'react'
import { Helmet } from 'react-helmet-async'

type HeadPropsType = {
  description?: string
  title?: string
}

export const Head = memo(({ description, title }: HeadPropsType) => {
  return (
    <Helmet>
      <title>{title ?? '情報漏えい 防ぐくん'}</title>
      {description && <meta content={description} name="description" />}
    </Helmet>
  )
})
