import { PhishingResultSendRevealing } from '@src/components/pages/phishing/[id]/result/send/revealing'
import { useSendRevealingHooks } from '@src/components/pages/phishing/[id]/result/send/revealing/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const PhishingResultSendRevealingPage = memo(() => {
  const sendRevealingHooks = useSendRevealingHooks()
  return (
    <Authorization>
      <PhishingResultSendRevealing {...sendRevealingHooks} />
    </Authorization>
  )
})
