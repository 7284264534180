import {
  Box,
  CloseButton,
  Divider,
  Fade,
  HStack,
  Spacer,
  Text,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react'
import React, { cloneElement, ReactElement, useRef, useState } from 'react'
import Moveable, { OnDrag, OnResize } from 'react-moveable'

type Props = {
  html: string
  trigger: ReactElement
}

export const EditorPreview = ({ html, trigger }: Props) => {
  const moveableRef = useRef<HTMLDivElement>(null)

  const [isFocus, setIsFocus] = useState(false)
  const { isOpen, onClose, onOpen } = useDisclosure()

  useOutsideClick({
    handler: () => setIsFocus(false),
    ref: moveableRef,
  })

  const handleOnDrag = (event: OnDrag) => {
    event.target.style.transform = event.transform
  }

  const handleOnResize = (event: OnResize) => {
    event.target.style.width = `${event.width}px`
    event.target.style.height = `${event.height}px`
    event.target.style.transform = event.drag.transform
  }

  // 画面編集時、style タグがページに反映されてしまうので、style タグは除去する
  const fixedHtml = html.replace(
    /<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi,
    ''
  )

  return (
    <>
      {cloneElement(trigger, { onClick: onOpen })}
      <Fade in={isOpen}>
        <Box
          bg={'white'}
          borderWidth={'1px'}
          cursor={'move'}
          height={'500px'}
          overflow={'scroll'}
          pointerEvents={isOpen ? 'auto' : 'none'}
          position={'fixed'}
          px={4}
          py={3}
          ref={moveableRef}
          right={16}
          rounded={'md'}
          shadow={'lg'}
          top={32}
          width={'lg'}
          zIndex={'modal'}
          onMouseDown={() => setIsFocus(true)}
        >
          <HStack>
            <Text fontWeight={'bold'}>プレビュー</Text>
            <Spacer />
            <CloseButton onClick={onClose} />
          </HStack>
          <Divider my={2} />
          <Box>
            <Box
              dangerouslySetInnerHTML={{ __html: fixedHtml }}
              fontSize={'13px'}
            />
          </Box>
        </Box>

        <Moveable
          draggable
          hideDefaultLines={!isFocus}
          origin={false}
          resizable={isFocus}
          target={moveableRef}
          throttleDrag={0}
          onDrag={handleOnDrag}
          onResize={handleOnResize}
        />
      </Fade>
    </>
  )
}
