import { Box, BoxProps, Grid, Tag, Text } from '@chakra-ui/react'
import { CsbPrimaryBox } from '@src/components'
import { LearningResultQuery } from '@src/models/Learning'
import { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

type LearningAnswerResultProps = Omit<
  ComponentProps<typeof LearningResultQuery>,
  'children'
> &
  Pick<BoxProps, 'mt' | 'mb'>

export const LearningAnswerResult = ({
  mb,
  mt,
  ...props
}: LearningAnswerResultProps) => {
  const { t } = useTranslation()
  return (
    <LearningResultQuery {...props}>
      {({ answerResult }) => (
        <Box display={'flex'} flexDirection={'column'} gap={4} mb={mb} mt={mt}>
          {answerResult.map(({ answer, question }, index) => (
            <CsbPrimaryBox
              display={'grid'}
              gap={8}
              key={`${answer?.uuid}_${index}`}
              px={4}
              py={6}
              textAlign={'left'}
            >
              <Grid rowGap={2}>
                <Text fontWeight={'bold'}>{t('learn.result.question')}</Text>
                <Text
                  fontSize={16}
                  lineHeight={1.25}
                  whiteSpace={'pre-wrap'}
                  wordBreak={'break-all'}
                >
                  {question?.name}
                </Text>
              </Grid>
              <Grid rowGap={2}>
                <Text fontWeight={'bold'}>{t('learn.result.answer')}</Text>
                <Text fontSize={16} lineHeight={1.25}>
                  {answer?.selectCorrect?.name}
                </Text>
                <Box>
                  <Tag
                    bgColor={answer?.correct ? 'teal' : 'red.500'}
                    color={'white'}
                    fontWeight={'medium'}
                  >
                    {answer?.correct
                      ? t('learn.result.correct')
                      : t('learn.result.incorrect')}
                  </Tag>
                </Box>
              </Grid>
              {!answer?.correct && (
                <Grid rowGap={2}>
                  <Text fontWeight={'bold'}>
                    {t('learn.result.correctAnswer')}
                  </Text>
                  <Text fontSize={16} lineHeight={1.25}>
                    {question?.corrects.find((n) => n?.correct)?.name}
                  </Text>
                </Grid>
              )}
              {question?.explanation && (
                <Grid rowGap={2}>
                  <Text fontWeight={'bold'}>
                    {t('learn.result.explanation')}
                  </Text>
                  <Text
                    fontSize={16}
                    lineHeight={1.25}
                    whiteSpace={'pre-wrap'}
                    wordBreak={'break-all'}
                  >
                    {question.explanation}
                  </Text>
                </Grid>
              )}
            </CsbPrimaryBox>
          ))}
        </Box>
      )}
    </LearningResultQuery>
  )
}
