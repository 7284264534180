import {
  FormControl as ChakraFormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
} from '@chakra-ui/react'
import { ReactNode } from 'react'

type FormControlPropsType = {
  errorText?: string
  helperText?: string | ReactNode
  labelText?: ReactNode
} & FormControlProps

// forwardRefに変更するかも
export const CsbFormControl = ({
  children,
  errorText,
  helperText,
  labelText,
  ...props
}: FormControlPropsType) => {
  return (
    <ChakraFormControl
      {...props}
      isInvalid={errorText !== undefined && errorText !== ''}
    >
      <Grid gap={2}>
        {labelText && (
          <FormLabel color={'gray.700'} fontWeight={'medium'} mb={0}>
            {labelText}
          </FormLabel>
        )}
        {children}
        {helperText && typeof helperText === 'string' ? (
          <FormHelperText color={'gray.500'}>{helperText}</FormHelperText>
        ) : (
          helperText
        )}
        {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}
      </Grid>
    </ChakraFormControl>
  )
}
