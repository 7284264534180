import {
  Button,
  chakra,
  HStack,
  ModalCloseButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useClipboard,
} from '@chakra-ui/react'
import { CsbModalBody, CsbModalFooter } from '@src/components'
import { useModal } from '@src/components/Modal/useModal'
import { cloneElement, memo, ReactElement } from 'react'

type Props = {
  trigger: ReactElement
}

const TooltipButton = memo(({ placeholder }: { placeholder: string }) => {
  const { hasCopied, onCopy } = useClipboard(placeholder)
  return (
    <Tooltip
      hasArrow
      isOpen={hasCopied}
      label={`${placeholder} をコピーしました`}
      placement={'top'}
    >
      <chakra.button
        _hover={{ bgColor: 'gray.100' }}
        type={'button'}
        onClick={onCopy}
      >
        {placeholder}
      </chakra.button>
    </Tooltip>
  )
})

export const PhishingResultPagePlaceholderModal = ({ trigger }: Props) => {
  const [Modal, { isOpen, onClose, onOpen }] = useModal()

  return (
    <>
      {cloneElement(trigger, { onClick: onOpen })}
      <Modal isOpen={isOpen} size={'3xl'} onClose={onClose}>
        <ModalCloseButton />
        <CsbModalBody mt={10}>
          <TableContainer>
            <Table colorScheme={'gray'}>
              <Thead>
                <Tr>
                  <Th>プレースホルダー </Th>
                  <Th>内容</Th>
                  <Th>表示例</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr fontSize={'sm'}>
                  <Td>
                    <TooltipButton placeholder={'{{learning_url}}'} />
                  </Td>
                  <Td>ラーニングページへのリンク</Td>
                  <Td>https://fusegu.cloud/learning/***</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </CsbModalBody>
        <CsbModalFooter mt={7}>
          <HStack spacing={6}>
            <Button colorScheme={'gray'} onClick={onClose}>
              閉じる
            </Button>
          </HStack>
        </CsbModalFooter>
      </Modal>
    </>
  )
}
