import {
  PhishingEmailGroupsQuery,
  usePhishingEmailGroupsQuery,
} from '@src/graphql/generated/graphql'
import { useState } from 'react'

export type GroupData = {
  groupName: string
  groupUuid: string
  sendCount: number
  sendRequiredMinutes: number
}

const convertToGroupData = (groupsData: PhishingEmailGroupsQuery | undefined) =>
  groupsData?.phishingEmailGroups.map((group) => ({
    groupName: group.group.name,
    groupUuid: group.group.uuid,
    sendCount: group.targetCount,
    sendRequiredMinutes: group.sendRequiredMinutes,
  })) ?? []

export const usePhishingEmailGroups = (uuid: string) => {
  const [groups, setGroups] = useState<Array<GroupData>>([])
  const { loading: isGroupsDataLoading, refetch } = usePhishingEmailGroupsQuery(
    {
      skip: !uuid,
      variables: { uuid: uuid },
    }
  )

  const updatePhishingEmailGroups = () =>
    refetch().then((res) => {
      setGroups(convertToGroupData(res.data))
    })

  return {
    groups,
    isGroupsDataLoading,
    updatePhishingEmailGroups,
  }
}
