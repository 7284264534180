import { useToast } from '@chakra-ui/react'
import { LocalStorage } from '@src/enums/localStorage'
import { usePhishingResultPageQuery } from '@src/graphql/generated/graphql'
import { useLocalStorage } from '@src/hooks'
import axios from 'axios'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

//フィッシング結果で使うhooksのまとめ
export const usePhishingResultHooks = () => {
  const navigate = useNavigate()
  const toast = useToast()
  //フィッシング結果のすべてのボタンstate
  const [showAll, setShowAll] = useState<boolean>(false)
  //ページネーション
  // const { current, onChangePage } = usePagination()
  const { id } = useParams<{ id: string }>()

  const { data, loading } = usePhishingResultPageQuery({
    variables: { uuid: id ?? '' },
  })

  const [accessToken] = useLocalStorage<string>(LocalStorage.ACCESS_TOKEN, '')

  const onExportDownload = async () => {
    try {
      // TODO anyを消す
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const excel: any = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/export/phishing_result.xlsx`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            uuid: id,
          },
          responseType: 'blob',
        }
      )
      const url = URL.createObjectURL(
        new Blob([excel.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        dayjs().format('YYYY-MM-DD') + '_phishing_result.xlsx'
      )
      document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(url)
      link.parentNode?.removeChild(link)
    } catch (e) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: 'エクスポートに失敗しました。',
      })
    }
  }

  return {
    data,
    id,
    loading,
    navigate,
    onExportDownload,
    setShowAll,
    showAll,
  }
}

export type usePhishingResultHooksResult = ReturnType<
  typeof usePhishingResultHooks
>
