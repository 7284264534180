import { Box, Grid, Heading, RadioGroup, Text } from '@chakra-ui/react'
import { CsbHeader } from '@src/components'
import { CsbNewLineText } from '@src/components/CsbNewLineText'
import { CsbQuestionRadio } from '@src/components/CsbQuestionRadio'
import { usePreviewLearnHooksResult } from '@src/components/pages/preview/learn/[id]/hooks'
import {
  Correct,
  Question as QuestionType,
} from '@src/graphql/generated/graphql'
import { memo, useState } from 'react'

type QuestionPropsType = {
  question: Pick<
    QuestionType,
    'uuid' | 'name' | 'answerType' | 'explanation'
  > & {
    corrects: Pick<Correct, 'name' | 'uuid'>[]
  }
}

const Question = ({ question }: QuestionPropsType) => {
  //現在のラジオボタンの値を管理するstate
  const [value, setValue] = useState<string | null>(null)

  //radioボタンが変わるたびにvalueを更新する
  const onChange = (nextValue: string) => {
    setValue(nextValue)
  }

  return (
    <Box>
      <Text fontWeight={'bold'} mb={3}>
        <CsbNewLineText text={question.name} />
      </Text>
      <RadioGroup
        style={{ display: 'flex', flexDirection: 'column' }}
        value={value ?? ''}
        onChange={onChange}
      >
        <Grid gap={4} gridColumn={'1fr'}>
          {question.corrects.map((correct) => (
            <CsbQuestionRadio
              isChecked={value === correct.uuid}
              key={correct.uuid}
              value={correct.uuid}
            >
              {correct.name}
            </CsbQuestionRadio>
          ))}
        </Grid>
      </RadioGroup>
    </Box>
  )
}

//学習ページのcomponent
export const PreviewLearn = memo(
  ({ data, isDisplayLogoInLearning, loading }: usePreviewLearnHooksResult) => {
    return (
      <>
        {isDisplayLogoInLearning ? <CsbHeader /> : null}
        <Box maxW={'650px'} mx={'auto'} px={'15px'} py={20}>
          <Grid rowGap={10}>
            {!loading && data ? (
              <Box>
                <Heading mb={5} textAlign={'center'}>
                  {data.learn.name}
                </Heading>
                <Grid rowGap={10}>
                  {data.learn.questions.map((question, index) => (
                    <Box key={`${question.uuid}_${index}`}>
                      <Question
                        key={`${question.uuid}_${index}`}
                        question={question}
                      />
                    </Box>
                  ))}
                </Grid>
              </Box>
            ) : (
              <>データが存在しません。</>
            )}
          </Grid>
        </Box>
      </>
    )
  }
)
