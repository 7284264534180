import { useDisclosure } from '@chakra-ui/react'
import { PdfsQuery } from '@src/graphql/generated/graphql'
import { useUpdatePdf } from '@src/models/Pdf/useUpdatePdf'
import { useCallback, useState } from 'react'

type Pdf = PdfsQuery['pdfs']['collection'][number]

export const useUpdatePdfModal = () => {
  const [Pdf, setPdf] = useState<Pdf>()
  const { isOpen, onClose: _onClose, onOpen: _onOpen } = useDisclosure()
  const {
    findErrorMessage,
    form,
    isLoading,
    onUpdatePdf: _onUpdatePdf,
    resetForm,
  } = useUpdatePdf({
    onCompleted: () => {
      _onClose()
    },
  })
  const onClose = useCallback(() => {
    resetForm()
    _onClose()
  }, [_onClose, resetForm])

  const onOpen = useCallback(
    (Pdf: Pdf) => {
      _onOpen()
      setPdf(Pdf)
      form.setValue('name', Pdf.name)
      form.setValue('url', Pdf.url)
    },
    [form, _onOpen, setPdf]
  )

  const onUpdatePdf = useCallback(() => {
    if (!Pdf) return
    _onUpdatePdf(Pdf.uuid)()
  }, [Pdf, _onUpdatePdf])

  return {
    findErrorMessage,
    form,
    isLoading,
    isOpen,
    onClose,
    onOpen,
    onUpdatePdf,
  }
}

type UseUpdatePdfModalType = typeof useUpdatePdfModal
export type UseUpdatePdfModalResult = ReturnType<UseUpdatePdfModalType>
