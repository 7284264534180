import { chakra } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

type CsbNextLinkPropsType = {
  children: ReactNode
  href: string
}

/**
 * NextLinkの薄いwrapper
 * ここでpropsを一括指定する
 * passHrefを指定することでchakraのcomponentにリンクを渡すことが可能
 * @see https://github.com/chakra-ui/chakra-ui/issues/2248#issuecomment-733445511
 */

const Link = ({ href, ...props }: CsbNextLinkPropsType) => {
  return <RouterLink to={href} {...props} />
}
export const CsbNextLink = chakra(Link)
