import {
  BaseErrorFallback,
  BaseLoadingFallback,
  BaseNoDataFallback,
  BaseQueryLayoutProps,
} from '@src/models/Base'
import { UsePdfsResult } from '@src/models/Pdf/usePdfs'

export const PdfsQuery = ({
  children,
  data,
  error,
  errorFallback,
  loading,
  loadingFallback,
  noDataFallback,
}: BaseQueryLayoutProps<UsePdfsResult>) => {
  if (error) {
    return errorFallback ? errorFallback : <BaseErrorFallback />
  }

  //apolloはdataをcacheするためloadingとdataを見る必要がある
  if (loading && !data) {
    return loadingFallback ? loadingFallback : <BaseLoadingFallback />
  }

  if (!data) {
    return noDataFallback ? noDataFallback : <BaseNoDataFallback />
  }

  return typeof children === 'function' ? children(data) : children
}
