import { Button, HStack, Stack } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import { ComponentProps } from 'react'

type CsbFormModalPropsType = {
  header: string
  isDisabled?: boolean
  isLoading?: boolean
  onCancel: () => void
  onSubmit: () => void
  submit: string
} & ComponentProps<typeof CsbModal>

export const CsbFormModal = ({
  children,
  header,
  isDisabled,
  isLoading,
  onCancel,
  onSubmit,
  submit,
  ...props
}: CsbFormModalPropsType) => {
  return (
    <CsbModal {...props}>
      <CsbModalHeader>{header}</CsbModalHeader>
      <CsbModalBody mt={10}>
        <Stack spacing={6}>{children}</Stack>
      </CsbModalBody>
      <CsbModalFooter mt={7}>
        <HStack spacing={6}>
          <Button colorScheme={'gray'} onClick={onCancel}>
            キャンセル
          </Button>
          <Button
            isDisabled={isDisabled}
            isLoading={isLoading}
            onClick={onSubmit}
          >
            {submit}
          </Button>
        </HStack>
      </CsbModalFooter>
    </CsbModal>
  )
}
