import { Button, Flex, Heading, HStack } from '@chakra-ui/react'
import { CsbPagination } from '@src/components'
import { usePhishingArchivesHooksResult } from '@src/components/pages/phishing/archives/hooks'
import { PhishingArchivesContents } from '@src/components/pages/phishing/archives/PhishingArchivesContents'
import { Base } from '@src/layouts'
import { PhishingEmailsQuery } from '@src/models/PhishingEmails/PhishingEmailsQuery'
import { memo } from 'react'

//フィシイング一覧ページのcomponent
export const PhishingArchives = memo(
  ({
    navigate,
    phishingArchivesContents,
    phishingEmailsQuery,
    usePaginationResult,
  }: usePhishingArchivesHooksResult) => {
    return (
      <Base
        left={<Heading>アーカイブ済</Heading>}
        right={
          <HStack spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate('/phishing')}>
              フィッシングメール一覧に戻る
            </Button>
          </HStack>
        }
      >
        <PhishingArchivesContents
          {...{ phishingArchivesContents, phishingEmailsQuery }}
        />
        <Flex mt={5}>
          <PhishingEmailsQuery {...phishingEmailsQuery}>
            {(data) => (
              <CsbPagination
                current={usePaginationResult.current}
                defaultCurrent={usePaginationResult.current}
                pageSize={data.metadata.limitValue}
                total={data.metadata.totalCount}
                onChange={usePaginationResult.onChangePage(
                  '/phishing/archives'
                )}
              />
            )}
          </PhishingEmailsQuery>
          <HStack ml={'auto'} spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate('/phishing')}>
              フィッシングメール一覧に戻る
            </Button>
          </HStack>
        </Flex>
      </Base>
    )
  }
)
