import { ApolloError } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import {
  CsvExportUserInput,
  CsvExportUserMutation,
  useCsvExportUserMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { useForm } from 'react-hook-form'

export const useCsvExportUser = ({
  onCompleted,
  onError,
}: Partial<BaseOptionsType<CsvExportUserMutation>> = {}) => {
  const [csvExportUserMutation, { loading: isExporting }] =
    useCsvExportUserMutation()
  const form = useForm<CsvExportUserInput>({
    defaultValues: {
      rows: 1000,
    },
  })
  const toast = useToast()
  const { resetErrors, setErrors } = useGraphQLErrors()

  const onExportUsers = form.handleSubmit(async (inputData) => {
    try {
      await csvExportUserMutation({
        variables: {
          input: inputData,
        },
      })
      resetErrors()
      onCompleted?.()
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'success',
        title: 'ユーザーのエクスポート情報をメールに送信しました。',
      })
    } catch (e) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: 'ユーザーのエクスポートに失敗しました。',
      })
      if (e instanceof ApolloError) {
        setErrors(e.graphQLErrors)
        onError?.(e)
      }
    }
  })

  return { form, isExporting, onExportUsers }
}

type useCsvExportUserType = typeof useCsvExportUser
export type useCsvExportUserHooksResult = ReturnType<useCsvExportUserType>
export type useCsvExportUserHooksParameter = Parameters<useCsvExportUserType>[0]
