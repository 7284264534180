import {
  useLearnWithPreviewQuery,
  useSettingsQuery,
} from '@src/graphql/generated/graphql'
import { useQueryParams } from '@src/hooks'
import { useParams } from 'react-router-dom'

//学習のプレビューで使うhooksのまとめ
export const usePreviewLearnHooks = () => {
  //queryからidを抽出して返す
  const { id } = useParams<{ id: string }>()
  const { data, loading } = useLearnWithPreviewQuery({
    variables: {
      uuid: id ?? '',
    },
  })
  const { isDisplayLogo } = useQueryParams()
  const { data: settingData, loading: isSettingLoading } = useSettingsQuery()
  const isDisplayLogoInLearning =
    isDisplayLogo === '' || isDisplayLogo === undefined
      ? settingData?.setting?.isDisplayLogoInLearning ?? true
      : isDisplayLogo === 'true'

  return {
    data,
    isDisplayLogoInLearning,
    loading: loading || isSettingLoading,
  }
}

export type usePreviewLearnHooksResult = ReturnType<typeof usePreviewLearnHooks>
