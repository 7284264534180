import { paginationInput } from '@src/constants'
import {
  SwitchPhishingSiteDomainInput,
  usePhishingLinkDomainsQuery,
  useSwitchPhishingSiteDomainMutation,
} from '@src/graphql/generated/graphql'
import { useFormModal, useMe, usePagination } from '@src/hooks'

export const useLinkDomainsHooks = () => {
  const { role } = useMe()
  const isWriteLinkDomain = !!role?.writeLinkDomain
  const [switchPhishingSiteDomainMutation, { loading: isUpdating }] =
    useSwitchPhishingSiteDomainMutation()
  const formModal = useFormModal<
    Omit<SwitchPhishingSiteDomainInput, 'uuid'> & {
      linkDomain: string
      siteDomain: string
    }
  >({
    defaultValues: {
      phishingSiteDomainUuid: '',
    },
  })

  //ページネーション
  const { current, onChangePage } = usePagination()

  const { data, refetch } = usePhishingLinkDomainsQuery({
    variables: {
      pagination: paginationInput(current),
    },
  })

  //編集するModalを開くボタン
  const onUpdate = ({
    linkDomain,
    phishingSiteDomainUuid,
    siteDomain,
    uuid,
  }: SwitchPhishingSiteDomainInput & {
    linkDomain: string
    siteDomain: string
  }) => {
    formModal.onOpen()
    formModal.setUuid(uuid)
    formModal.form.setValue('phishingSiteDomainUuid', phishingSiteDomainUuid)
    formModal.form.setValue('siteDomain', siteDomain)
    formModal.form.setValue('linkDomain', linkDomain)
    formModal.setState('update')
  }

  //新規作成、編集のModalのformを送信する関数
  const onSubmit = async (
    inputData: Omit<SwitchPhishingSiteDomainInput, 'uuid'>
  ) => {
    try {
      //typeを判定
      switch (formModal.state) {
        case 'update':
          //createの場合はupdateGroupMutationにinputDataとuuidを渡す
          await switchPhishingSiteDomainMutation({
            variables: {
              input: {
                phishingSiteDomainUuid: inputData.phishingSiteDomainUuid,
                uuid: formModal.uuid,
              },
            },
          })
          break
        //一応エラーを書く
        default:
          new Error(`${formModal.state} does not exist`)
      }

      //編集をすればToastを発行する
      formModal.toast({
        status: 'success',
        title: 'フィッシングサイトドメインを更新しました。',
      })
      formModal.setErrors([])
      formModal.onCancel()
      /**
       * TODO anyを消す
       * e instanceOf ApolloError
       * setErrorsを変更する
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      formModal.setErrors(e.graphQLErrors)
      //新規作成、編集が失敗した場合のToast
      formModal.toast({
        status: 'error',
        title: 'サイトドメインの切替えに失敗しました。',
      })
    }
    await refetch()
  }

  return {
    current,
    data,
    formModal,
    isUpdating,
    isWriteLinkDomain,
    onChangePage,
    onSubmit,
    onUpdate,
    refetch,
  }
}

export type useLinkDomainsHooksResult = ReturnType<typeof useLinkDomainsHooks>
