//何かしらのdataをモーダルが開くたびにstateに入れて管理するhooks
import { useDisclosure } from '@chakra-ui/react'
import { useCallback, useState } from 'react'

export type useCsbDataModalResult = ReturnType<typeof useCsbDataModal>

export const useCsbDataModal = <T>() => {
  //useDisclosureをwrapping
  const { isOpen, onClose: _onClose, onOpen: _onOpen } = useDisclosure()
  //dataを管理するstate
  const [data, setData] = useState<T | undefined>(undefined)

  //モーダルを開く時の処理
  const onOpen = useCallback(
    (callback?: () => void) => {
      //callbackがあれば実行する
      callback?.()
      _onOpen()
    },
    [_onOpen]
  )

  //モーダルを閉じる時の処理
  const onClose = useCallback(() => {
    //dataをundefinedに設定する
    setData(undefined)
    _onClose()
  }, [setData, _onClose])

  return {
    data,
    isOpen,
    onClose,
    onOpen,
    setData,
  }
}
