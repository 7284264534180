import { Attaches } from '@src/components/pages/attaches'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const AttachesPage = memo(() => {
  return (
    <Authorization>
      <Attaches />
    </Authorization>
  )
})
