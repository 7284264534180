import { useToast } from '@chakra-ui/react'
import {
  PhishingEmail,
  useArchivePhishingEmailMutation,
  useDestroyPhishingEmailMutation,
  usePhishingEmailsQuery,
} from '@src/graphql/generated/graphql'
import { useDeleteModal, useMe, usePagination } from '@src/hooks'
import { useCsbDataModal } from '@src/hooks/useCsbDataModal'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import { useNavigate } from 'react-router-dom'

//フィッシング一覧で使うhooksのまとめ
export const usePhishingHooks = () => {
  const { isDeliveryContentsOnly, resolveDeliveryContentsUrl } =
    useDeliveryContents()
  const navigate = useNavigate()
  const toast = useToast()
  const [destroyPhishingEmailMutation] = useDestroyPhishingEmailMutation()
  const deleteModal = useDeleteModal()
  const dataModal =
    useCsbDataModal<
      Pick<
        PhishingEmail,
        | 'uuid'
        | 'sentAt'
        | 'name'
        | 'draft'
        | 'openCount'
        | 'sendCount'
        | 'loginCount'
        | 'rating'
      >
    >()
  const { role } = useMe()
  const isWritePhishingMail = !!role?.writePhishingMail
  //ページネーション
  const { current, onChangePage } = usePagination()

  const { data, refetch } = usePhishingEmailsQuery({
    variables: {
      isArchive: false,
      isDeliveryContentsOnly,
      pagination: {
        limit: 30,
        page: current,
      },
    },
  })

  const [archivePhishingEmailMutation, { loading: isArchiving }] =
    useArchivePhishingEmailMutation()

  const onArchivePhishingEmail = async () => {
    try {
      //削除
      await archivePhishingEmailMutation({
        variables: {
          input: {
            uuid: dataModal.data?.uuid ?? '',
          },
        },
      })
      dataModal.onClose()

      //削除したことを通知する
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'success',
        title: `${dataModal.data?.name}をアーカイブしました`,
      })

      //ページの情報をrefetch
      const {
        data: {
          phishingEmails: { metadata },
        },
      } = await refetch()

      //refetch後ページャーの中身が0の場合
      const isEmptyPage = metadata.totalCount % metadata.limitValue === 0
      //refetch後ページャーが最初のページの場合
      const isInitialPage = metadata.currentPage === 0
      //ページャーの中身が0かつ2ページ目以降の場合
      if (isEmptyPage && !isInitialPage) {
        //ページャーの値を現在のページ-1ページに更新する
        onChangePage('/phishing')(metadata.currentPage - 1)
      }
    } catch {
      //エラーのときの処理
      //e.graphQLErrors[0]は空なため一旦uuidで対応
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: `エラー${dataModal.data?.name}をアーカイブできませんでした。`,
      })
    }
  }

  return {
    current,
    data,
    dataModal,
    deleteModal,
    destroyPhishingEmailMutation,
    isArchiving,
    isDeliveryContentsOnly,
    isWritePhishingMail,
    navigate,
    onArchivePhishingEmail,
    onChangePage,
    refetch,
    resolveDeliveryContentsUrl,
  }
}

export type usePhishingHooksResult = ReturnType<typeof usePhishingHooks>
