import { paginationInput } from '@src/constants'
import {
  useDestroyMailTemplateMutation,
  useMailTemplatesQuery,
} from '@src/graphql/generated/graphql'
import { useDeleteModal, useMe, usePagination } from '@src/hooks'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

//メールテンプレート一覧で使うhooksのまとめ
export const useTemplatesHooks = () => {
  const { role } = useMe()
  const isWriteMailTemplate = !!role?.writeMailTemplate
  const navigate = useNavigate()
  const [destroyMailTemplateMutation] = useDestroyMailTemplateMutation()
  const deleteModal = useDeleteModal()
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    searchWord: withDefault(StringParam, ''),
  })
  //ページネーション
  const { current, onChangePage } = usePagination()

  const { data, refetch } = useMailTemplatesQuery({
    variables: {
      pagination: paginationInput(current),
      searchWord: query.searchWord,
    },
  })

  //search機能
  const searchForm = useForm<{ searchWord: string }>({
    defaultValues: {
      searchWord: query.searchWord,
    },
  })
  const onSearch = searchForm.handleSubmit(async (inputData) => {
    setQuery({
      page: 1,
      searchWord: inputData.searchWord,
    })
  })
  const onResetSearch = () => {
    searchForm.setValue('searchWord', '')

    setQuery({
      page: 1,
      searchWord: '',
    })
  }

  return {
    current,
    data,
    deleteModal,
    destroyMailTemplateMutation,
    isWriteMailTemplate,
    navigate,
    onChangePage,
    onResetSearch,
    onSearch,
    refetch,
    searchForm,
  }
}

export type useTemplatesHooksResult = ReturnType<typeof useTemplatesHooks>
