import { usePdfsQuery } from '@src/graphql/generated/graphql'

export const usePdfs = (
  baseOptions: Partial<Parameters<typeof usePdfsQuery>[0]> = {}
) => {
  return usePdfsQuery(baseOptions)
}

type UsePdfs = typeof usePdfs
export type UsePdfsResult = ReturnType<UsePdfs>
