import { DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  CsbFormControl,
  CsbList,
  CsbListItem,
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
  CsbPrimaryBox,
} from '@src/components'
import { TargetUserList } from '@src/components/List/TargetUsers/TargetUserList'
import { useSendRevealingScheduleHooksResult } from '@src/components/pages/phishing/[id]/result/send/revealing/schedule/hooks'
import { MAIL_DAYS } from '@src/constants'
import {
  RevealingEmailTargetsQuery,
  RevealingPhishingEmailScheduleLogsQueryHookResult,
  RevealingRepeat,
  RevealingSend,
  RevealingTarget,
} from '@src/graphql/generated/graphql'
import { Base } from '@src/layouts'
import { findError } from '@src/utils/findError'
import dayjs from 'dayjs'
import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Controller } from 'react-hook-form'

type RevealingPhishingEmailType = NonNullable<
  RevealingPhishingEmailScheduleLogsQueryHookResult['data']
>['revealingPhishingEmailScheduleLogs'][0]['revealingPhishingEmail']

//種明かしメールスケジュール送信用ページのcomponent
export const PhishingResultSendRevealingSchedule = memo(
  ({
    data,
    errors,
    form,
    id,
    isDeliveryContentsOnly,
    isWritePhishingMail,
    loading,
    logs,
    navigate,
    onDestroy,
    onSubmit,
    refetchRevealingEmailTargets,
    resolveDeliveryContentsUrl,
    revealingPhishingEmailModal,
    sendRevealingModal,
    settingData,
    targetsData,
  }: useSendRevealingScheduleHooksResult) => {
    const title = !isDeliveryContentsOnly
      ? '種明かしメール'
      : 'リマインドメール'

    const sendTargetTypes: Array<[RevealingTarget, string]> = [
      [RevealingTarget.All, '全員'],
      [RevealingTarget.Unanswered, '未回答ユーザー'],
      [RevealingTarget.NoLearningAccess, 'ラーニング未アクセスユーザー'],
      [
        RevealingTarget.NoLearningAccessAndRiskUser,
        'ラーニング未アクセスでリスクのあるユーザー',
      ],
      [RevealingTarget.RiskUser, 'リスクのあるユーザー'],
      [RevealingTarget.UnansweredAndRiskUser, '未回答のリスクのあるユーザー'],
    ]
    const sendTargetMap = new Map(sendTargetTypes)

    const sendTypes = [
      [
        RevealingSend.DaysAfterRevealing,
        !isDeliveryContentsOnly ? '訓練メール配信後' : 'コンテンツメール配信後',
      ],
      [RevealingSend.Schedule, '日時指定'],
      [RevealingSend.None, 'なし'],
    ]

    const repeatTypes = [
      [RevealingRepeat.RepeatCount, '繰り返し指定'],
      [RevealingRepeat.Dates, '日時指定'],
      [RevealingRepeat.None, 'なし'],
    ]

    const filterSendTargetTypes = isDeliveryContentsOnly
      ? [
          RevealingTarget.NoLearningAccessAndRiskUser.valueOf(),
          RevealingTarget.RiskUser.valueOf(),
          RevealingTarget.UnansweredAndRiskUser.valueOf(),
        ]
      : []

    const [sendType, setSendType] = useState<RevealingSend>(
      form.getValues('sendType') || RevealingSend.Schedule
    )
    const [repeatType, setRepeatType] = useState<RevealingRepeat>(
      form.getValues('repeatType') || RevealingRepeat.None
    )
    const [phishingEmailSentAt, setPhishingEmailSentAt] = useState<
      string | null
    >(null)
    const [lastScheduleSentAt, setLastScheduleSentAt] = useState<string | null>(
      null
    )
    const [repeatDates, setRepeatDates] = useState([''])
    const [actionName, setActionName] = useState('')
    const [revealingPhishingEmail, setRevealingPhishingEmail] =
      useState<RevealingPhishingEmailType | null>(null)
    const [isPhishingEmailSent, setIsPhishingEmailSent] = useState(false)

    // スケジュール日時が最後に送信した日時よりも小さい場合か判定を行う
    const isLessEqualLastScheduleSentAt = (date: string) =>
      lastScheduleSentAt !== null && date && date <= lastScheduleSentAt

    // 未送信のスケジュール日時のみを取得する
    const noSendScheduleDates = repeatDates.filter(
      (date) => !isLessEqualLastScheduleSentAt(date)
    )

    const isEdit = !!data?.revealingPhishingEmailSchedule
    const isSent = (logs?.revealingPhishingEmailScheduleLogs.length ?? 0) > 0
    const isCompleted =
      data?.revealingPhishingEmailSchedule?.completedAt ?? false

    useEffect(() => {
      if (isEdit) {
        form.setValue(
          'sendTargetType',
          data.revealingPhishingEmailSchedule?.sendTargetType ??
            RevealingTarget.All
        )
        form.setValue(
          'sendType',
          data.revealingPhishingEmailSchedule?.sendType ??
            RevealingSend.Schedule
        )
        setSendType(form.getValues('sendType'))
        const firstScheduleAt = data.revealingPhishingEmailSchedule
          ?.firstScheduleAt
          ? dayjs(data.revealingPhishingEmailSchedule?.firstScheduleAt).format(
              'YYYY-MM-DD HH:mm'
            )
          : null
        form.setValue('firstScheduleAt', firstScheduleAt)
        setFirstScheduleAt(firstScheduleAt)
        form.setValue(
          'sendRevealingMailDays',
          data.revealingPhishingEmailSchedule?.sendRevealingMailDays ?? 1
        )
        if (data.revealingPhishingEmailSchedule?.sendRevealingMailAt) {
          form.setValue(
            'sendRevealingMailAt',
            dayjs(
              data.revealingPhishingEmailSchedule?.sendRevealingMailAt
            ).format('HH:mm')
          )
        }
        form.setValue(
          'repeatType',
          data.revealingPhishingEmailSchedule?.repeatType ??
            RevealingRepeat.RepeatCount
        )
        setRepeatType(form.getValues('repeatType'))

        if (form.getValues('repeatType') === RevealingRepeat.RepeatCount) {
          if (form.getValues('sendType') === RevealingSend.None) {
            const repeatBaseDatetime = data?.revealingPhishingEmailSchedule
              ?.repeatBaseDatetime
              ? dayjs(
                  data?.revealingPhishingEmailSchedule?.repeatBaseDatetime
                ).format('YYYY-MM-DD HH:mm')
              : null
            form.setValue('repeatBaseDatetime', repeatBaseDatetime)
            setRepeatBaseDatetime(repeatBaseDatetime)
          }

          form.setValue(
            'repeatCount',
            data.revealingPhishingEmailSchedule?.repeatCount
          )
          form.setValue(
            'repeatInterval',
            data.revealingPhishingEmailSchedule?.repeatInterval
          )
          form.setValue(
            'answeredRate',
            data.revealingPhishingEmailSchedule?.answeredRate
          )
        }
      }

      form.setValue(
        'isSchedule',
        data?.revealingPhishingEmailSchedule?.isSchedule ?? false
      )

      const dates = (() => {
        const dates =
          data?.revealingPhishingEmailSchedule?.revealingPhishingEmailScheduleDates.map(
            (date) => dayjs(date.scheduleAt).format('YYYY-MM-DD HH:mm')
          ) ?? ['']
        return dates.length > 0 ? dates : ['']
      })()

      form.setValue('dates', dates)
      setRepeatDates(dates)

      const revealingPhishingEmailScheduleLogs =
        logs?.revealingPhishingEmailScheduleLogs ?? []

      const lastSentAt =
        revealingPhishingEmailScheduleLogs[
          revealingPhishingEmailScheduleLogs.length - 1
        ]?.sentAt ?? null

      setLastScheduleSentAt(
        lastSentAt ? dayjs(lastSentAt).format('YYYY-MM-DD HH:mm') : null
      )
      setPhishingEmailSentAt(
        data?.phishingEmail.sentAt
          ? dayjs(data.phishingEmail.sentAt).format('YYYY-MM-DD HH:mm')
          : data?.phishingEmail.reserveAt
          ? dayjs(data.phishingEmail.reserveAt).format('YYYY-MM-DD HH:mm')
          : null
      )

      setIsPhishingEmailSent(!!data?.phishingEmail.sentAt)
    }, [
      data?.phishingEmail,
      data?.revealingPhishingEmailSchedule,
      form,
      isEdit,
      logs?.revealingPhishingEmailScheduleLogs,
    ])

    useEffect(() => {
      if (isEdit) {
        return
      }

      // -1の可能性があるので、0以下の場合は0にする
      const sendRevealingMailDays = !isDeliveryContentsOnly
        ? (settingData?.setting.sendLearningMailDays ?? 0) <= 0
          ? 0
          : settingData?.setting.sendLearningMailDays ?? 0
        : null

      const mailAt = settingData?.setting.sendLearningMailAt

      const sendRevealingMailAt = !isDeliveryContentsOnly
        ? dayjs(mailAt ?? '15:30').format('HH:mm')
        : null

      form.setValue('sendRevealingMailDays', sendRevealingMailDays)
      form.setValue('sendRevealingMailAt', sendRevealingMailAt)
    }, [form, isDeliveryContentsOnly, isEdit, settingData])

    const onChangeSendTargetType = (e: ChangeEvent<HTMLSelectElement>) => {
      const targetType = e.target.value as RevealingTarget
      form.setValue('sendTargetType', targetType)
      onPagingTargetUsers(1).then()
    }

    const onChangeSendType = (value: string) => {
      const sendType = value as RevealingSend
      form.setValue('sendType', sendType)
      setSendType(sendType)

      // 繰り返し基準日時は初回送信タイプが変更されたら、クリアする
      form.setValue('repeatBaseDatetime', '')
      setRepeatBaseDatetime('')
    }

    const onChangeSendRevealingMailDays = (
      e: ChangeEvent<HTMLSelectElement>
    ) => {
      const sendRevealingMailDays = Number(e.target.value)
      form.setValue('sendRevealingMailDays', sendRevealingMailDays)
    }
    const onChangeRepeatType = (value: string) => {
      const repeatType = value as RevealingRepeat
      form.setValue('repeatType', repeatType)
      setRepeatType(repeatType)
    }
    const onSave = () => {
      // フォームのデータを初期化
      if (form.getValues('sendType') === RevealingSend.DaysAfterRevealing) {
        form.setValue('firstScheduleAt', null)
        setFirstScheduleAt(null)
      } else if (form.getValues('sendType') === RevealingSend.Schedule) {
        if (!isDeliveryContentsOnly) {
          form.setValue(
            'sendRevealingMailDays',
            settingData?.setting.sendLearningMailDays
          )
          form.setValue(
            'sendRevealingMailAt',
            settingData?.setting.sendLearningMailAt
          )
        } else {
          form.setValue('sendRevealingMailDays', 1)
          form.setValue('sendRevealingMailAt', null)
        }
      }

      if (form.getValues('repeatType') === RevealingRepeat.RepeatCount) {
        form.setValue('dates', [''])
        setRepeatDates([''])
      } else {
        form.setValue('repeatCount', 1)
        form.setValue('repeatInterval', 1)
        form.setValue('answeredRate', null)
      }

      // 初回送信なしで、繰り返し条件が繰り返しの場合、繰り返し基準日時をクリア
      if (
        form.getValues('sendType') !== RevealingSend.None ||
        form.getValues('repeatType') !== RevealingRepeat.RepeatCount
      ) {
        form.setValue('repeatBaseDatetime', null)
        setRepeatBaseDatetime(null)
      }

      sendRevealingModal.onClose()
      onSubmit().then()
    }

    const onDelete = () => {
      sendRevealingModal.onClose()
      onDestroy().then()
    }

    const addDates = () => {
      const dates = [...(form.getValues('dates') ?? ['']), '']
      setRepeatDates(dates)
      form.setValue('dates', dates)
    }

    const deleteDates = (index: number) => {
      const dates = (form.getValues('dates') ?? []).filter(
        (_, i) => i !== index
      )
      setRepeatDates(dates)
      form.setValue('dates', dates)
    }

    const onChangeDates = (value: string, index: number) => {
      const dates = form.getValues('dates') ?? ['']
      dates[index] = dayjs(value).format('YYYY-MM-DD HH:mm')
      setRepeatDates(dates)
      form.setValue('dates', dates)
    }

    const collectTargetUsers = useCallback(
      (data?: RevealingEmailTargetsQuery) => {
        return (
          data?.revealingEmailTargets.collection.map((target) => ({
            email: target.user.email,
            firstName: target.user.firstName,
            lastName: target.user.lastName,
            target: target.user.avatarPath,
            uuid: target.user.uuid,
          })) || []
        )
      },
      []
    )

    const [firstScheduleAt, setFirstScheduleAt] = useState(
      form.getValues('firstScheduleAt')
    )
    const [repeatBaseDatetime, setRepeatBaseDatetime] = useState(
      form.getValues('repeatBaseDatetime')
    )
    const [targetUsers, setTargetUsers] = useState<
      ReturnType<typeof collectTargetUsers>
    >([])
    const [targetUsersMetadata, setTargetUsersMetadata] = useState(
      targetsData?.revealingEmailTargets.metadata
    )

    const onPagingTargetUsers = useCallback(
      async (page: number) => {
        const { data } = await refetchRevealingEmailTargets({
          pagination: {
            limit: 100,
            page: page,
          },
          target: form.getValues('sendTargetType') ?? RevealingTarget.All,
          uuid: id ?? '',
        })

        setTargetUsers(collectTargetUsers(data))
        setTargetUsersMetadata(data.revealingEmailTargets.metadata)
      },
      [collectTargetUsers, form, id, refetchRevealingEmailTargets]
    )

    useEffect(() => {
      setTargetUsers(collectTargetUsers(targetsData))
      setTargetUsersMetadata(targetsData?.revealingEmailTargets.metadata)
    }, [collectTargetUsers, targetsData])

    const answeredRateKind = (() => {
      const sendTargetType = form.getValues('sendTargetType')
      // 全員、未回答ユーザー、ラーニング未アクセスユーザーの場合は「全体」
      // 上記以外の場合は「リスクのあるユーザー」
      if (
        [
          RevealingTarget.All,
          RevealingTarget.Unanswered,
          RevealingTarget.NoLearningAccess,
        ].includes(sendTargetType)
      ) {
        return '全体'
      } else {
        return 'リスクのあるユーザー'
      }
    })()

    const getMinFirstScheduleAt = () => {
      const currentDate = dayjs().format('YYYY-MM-DD HH:mm')
      return phishingEmailSentAt === null || currentDate >= phishingEmailSentAt
        ? currentDate
        : phishingEmailSentAt
    }

    const onShowRevealingEmail = (
      revealingPhishingEmail: RevealingPhishingEmailType
    ) => {
      setRevealingPhishingEmail(revealingPhishingEmail)
      revealingPhishingEmailModal.onOpen()
    }

    const removeStyleTag = (html: string) =>
      html.replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, '')

    return (
      <Base
        left={<Heading>{title}設定</Heading>}
        right={
          <HStack spacing={3}>
            <Button
              colorScheme={'gray'}
              onClick={() =>
                navigate(
                  resolveDeliveryContentsUrl(
                    isPhishingEmailSent
                      ? `/phishing/${id ?? ''}/result`
                      : `/phishing/${id ?? ''}/edit`
                  )
                )
              }
            >
              戻る
            </Button>
            {isWritePhishingMail && (
              <>
                <Button
                  disabled={loading || isCompleted}
                  onClick={() => {
                    setActionName('設定')
                    sendRevealingModal.onOpen()
                  }}
                >
                  設定
                </Button>
                {isEdit && (
                  <Button
                    colorScheme={'red'}
                    disabled={loading}
                    onClick={() => {
                      setActionName('削除')
                      sendRevealingModal.onOpen()
                    }}
                  >
                    削除
                  </Button>
                )}
              </>
            )}
          </HStack>
        }
      >
        <Box mt={45}>
          {!loading && !isEdit && (
            <Heading mb={8} size={'md'}>
              <Box border="2px solid" borderColor="red.400" padding="2">
                <Text color={'red.400'}>
                  {title}の設定がおこなわれていません。
                </Text>
              </Box>
            </Heading>
          )}

          <CsbPrimaryBox pb={'72px'} pt={35} px={45}>
            <VStack align={'start'} maxW={600} spacing={5} w={'full'}>
              <VStack align={'start'}>
                <Box w={'full'}>
                  <VStack align={'start'}>
                    <Heading fontSize={24}>初回送信設定</Heading>
                    <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                      初回{title}を送信する条件を設定します。
                    </Text>
                    {!isDeliveryContentsOnly && (
                      <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                        種明かしメールはリスクのあるユーザー以外に送信されます。
                      </Text>
                    )}
                    <Divider />
                  </VStack>
                  <VStack align={'start'} maxW={500} mt={6} spacing={5}>
                    <CsbFormControl labelText={'初回送信条件'}>
                      <Controller
                        control={form.control}
                        name={'sendType'}
                        render={({ field: { ref, ...rest } }) => (
                          <RadioGroup
                            isDisabled={loading || isCompleted || isSent}
                            {...rest}
                            onChange={onChangeSendType}
                          >
                            <Flex gap={5}>
                              {sendTypes.map(([key, value]) => (
                                <Radio key={key} ref={ref} value={key}>
                                  {value}
                                </Radio>
                              ))}
                            </Flex>
                          </RadioGroup>
                        )}
                      />
                    </CsbFormControl>
                    {sendType === RevealingSend.Schedule && (
                      <CsbFormControl
                        errorText={
                          findError(errors, 'firstScheduleAt')?.message
                        }
                        labelText={'配信日時'}
                        maxW={250}
                        mt={6}
                      >
                        <InputGroup>
                          <Input
                            disabled={loading || isCompleted || isSent}
                            min={getMinFirstScheduleAt()}
                            type={'datetime-local'}
                            value={firstScheduleAt}
                            onChange={(e) => {
                              form.setValue(
                                'firstScheduleAt',
                                dayjs(e.target.value).format('YYYY-MM-DD HH:mm')
                              )
                              setFirstScheduleAt(
                                form.getValues('firstScheduleAt')
                              )
                            }}
                          ></Input>
                          <Button
                            colorScheme={'gray'}
                            disabled={loading || isCompleted || isSent}
                            ml={3}
                            onClick={() => {
                              form.setValue('firstScheduleAt', '')
                              setFirstScheduleAt('')
                            }}
                          >
                            クリア
                          </Button>
                        </InputGroup>
                      </CsbFormControl>
                    )}
                    {sendType === RevealingSend.DaysAfterRevealing && (
                      <Box mt={6}>
                        <CsbFormControl
                          errorText={
                            findError(errors, 'sendRevealingMailDays')?.message
                          }
                          helperText={`${
                            !isDeliveryContentsOnly
                              ? '訓練メール'
                              : 'コンテンツ配信'
                          }の何日後に種明かしメールを送信するか選択できます。`}
                          labelText={title}
                        >
                          <Controller
                            control={form.control}
                            name={'sendRevealingMailDays'}
                            render={({ ...field }) => (
                              <Select
                                isDisabled={loading || isCompleted || isSent}
                                value={
                                  form.getValues('sendRevealingMailDays') ?? 6
                                }
                                {...field}
                                onChange={onChangeSendRevealingMailDays}
                              >
                                {MAIL_DAYS.map((item, index) => (
                                  <option
                                    key={`mail_day_${item}_${index}`}
                                    value={item}
                                  >
                                    {item > 0 ? `${item}日後` : '当日'}
                                  </option>
                                ))}
                              </Select>
                            )}
                          />
                        </CsbFormControl>
                        <CsbFormControl
                          errorText={
                            findError(errors, 'sendRevealingMailAt')?.message
                          }
                          labelText={'送信時間'}
                          mt={6}
                        >
                          <Controller
                            control={form.control}
                            name={'sendRevealingMailAt'}
                            render={({ field }) => (
                              <Input
                                isDisabled={loading || isCompleted || isSent}
                                type={'time'}
                                {...field}
                                onChange={(e) => {
                                  form.setValue(
                                    'sendRevealingMailAt',
                                    e.target.value
                                  )
                                }}
                              />
                            )}
                          />
                        </CsbFormControl>
                      </Box>
                    )}
                  </VStack>
                </Box>
              </VStack>
              {(isDeliveryContentsOnly ||
                (!isDeliveryContentsOnly &&
                  repeatType !== RevealingRepeat.None)) && (
                <VStack align={'start'}>
                  <Box w={'full'}>
                    <VStack align={'start'} mt={10}>
                      <Heading fontSize={24}>
                        {!isDeliveryContentsOnly ? '繰り返し' : ''}送信対象
                      </Heading>
                      <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                        {title}を{!isDeliveryContentsOnly ? '繰り返し' : ''}
                        送信する対象を選択します。
                      </Text>
                      <Divider />
                    </VStack>
                    <VStack align={'start'} mt={6} spacing={5}>
                      <CsbFormControl
                        errorText={findError(errors, 'sendTargetType')?.message}
                        labelText={'送信対象'}
                      >
                        <Controller
                          control={form.control}
                          name={'sendTargetType'}
                          render={({ ...field }) => (
                            <Select
                              isDisabled={loading || isCompleted}
                              maxW={400}
                              value={form.getValues('sendTargetType')}
                              {...field}
                              onChange={onChangeSendTargetType}
                            >
                              {sendTargetTypes
                                .filter(
                                  ([key]) =>
                                    !filterSendTargetTypes.includes(key)
                                )
                                .map(([key, value]) => (
                                  <option key={key} value={key}>
                                    {value}
                                  </option>
                                ))}
                            </Select>
                          )}
                        />
                      </CsbFormControl>
                    </VStack>
                  </Box>
                </VStack>
              )}
              <VStack align={'start'}>
                <Box mt={10} w={'full'}>
                  <VStack align={'start'}>
                    <Heading fontSize={24}>繰り返し設定</Heading>
                    <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                      リマインド（繰り返し）メールを送信する対象を選択します。
                    </Text>
                    <Divider />
                  </VStack>
                  <VStack align={'start'} maxW={500} mt={6} spacing={5}>
                    <CsbFormControl labelText={'繰り返し条件'}>
                      <Controller
                        control={form.control}
                        name={'repeatType'}
                        render={({ field: { ref, ...rest } }) => (
                          <RadioGroup
                            isDisabled={loading || isCompleted || isSent}
                            {...rest}
                            onChange={onChangeRepeatType}
                          >
                            <Flex gap={5}>
                              {repeatTypes.map(([key, value]) => (
                                <Radio key={key} ref={ref} value={key}>
                                  {value}
                                </Radio>
                              ))}
                            </Flex>
                          </RadioGroup>
                        )}
                      />
                    </CsbFormControl>
                    {repeatType === RevealingRepeat.RepeatCount && (
                      <Box mt={6}>
                        {form.getValues('sendType') === RevealingSend.None && (
                          <CsbFormControl
                            errorText={
                              findError(errors, 'repeatBaseDatetime')?.message
                            }
                            helperText={
                              '初回送信がなしの場合、入力してください。'
                            }
                            labelText={'繰り返し開始日時'}
                            maxW={400}
                            mb={5}
                          >
                            <InputGroup maxW={280}>
                              <Input
                                disabled={
                                  loading ||
                                  isCompleted ||
                                  isLessEqualLastScheduleSentAt(
                                    form.getValues('repeatBaseDatetime')
                                  )
                                }
                                min={dayjs().format('YYYY-MM-DDTHH:mm')}
                                type={'datetime-local'}
                                value={repeatBaseDatetime}
                                onChange={(e) => {
                                  const repeatBaseDatetime = dayjs(
                                    e.target.value
                                  ).format('YYYY-MM-DD HH:mm')
                                  form.setValue(
                                    'repeatBaseDatetime',
                                    repeatBaseDatetime
                                  )
                                  setRepeatBaseDatetime(repeatBaseDatetime)
                                }}
                              ></Input>
                              <Button
                                colorScheme={'gray'}
                                disabled={loading || isCompleted || isSent}
                                ml={3}
                                onClick={() => {
                                  form.setValue('repeatBaseDatetime', '')
                                  setRepeatBaseDatetime('')
                                }}
                              >
                                クリア
                              </Button>
                            </InputGroup>
                          </CsbFormControl>
                        )}
                        <CsbFormControl
                          errorText={
                            findError(errors, 'repeatInterval')?.message
                          }
                          helperText={`${
                            form.getValues('sendType') !== RevealingSend.None
                              ? '初回送信'
                              : '繰り返し開始日時送信'
                          }後、何日間隔で送信するか選択できます。`}
                          labelText={'送信間隔（日）'}
                        >
                          <Flex align={'center'}>
                            <Controller
                              control={form.control}
                              name={'repeatInterval'}
                              render={({
                                field: { ref, value, ...restField },
                              }) => {
                                const rest = {
                                  ...restField,
                                  ...{ value: Number(value ?? 1) },
                                }
                                return (
                                  <NumberInput
                                    isDisabled={loading || isCompleted}
                                    max={10}
                                    maxW={100}
                                    min={1}
                                    step={1}
                                    {...rest}
                                    onChange={(value) => {
                                      form.setValue(
                                        'repeatInterval',
                                        Number(value)
                                      )
                                    }}
                                  >
                                    <NumberInputField
                                      name={rest.name}
                                      ref={ref}
                                      textAlign={'right'}
                                    />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                )
                              }}
                            />
                            <Text ml={2}>日間隔</Text>
                          </Flex>
                        </CsbFormControl>
                        <CsbFormControl
                          errorText={findError(errors, 'repeatCount')?.message}
                          helperText={'何回送信するか選択できます。'}
                          labelText={'送信回数'}
                          mt={6}
                        >
                          <Flex align={'center'}>
                            <Controller
                              control={form.control}
                              name={'repeatCount'}
                              render={({
                                field: { ref, value, ...restField },
                              }) => {
                                const rest = {
                                  ...restField,
                                  ...{ value: Number(value ?? 1) },
                                }
                                return (
                                  <NumberInput
                                    isDisabled={loading || isCompleted}
                                    max={10}
                                    maxW={100}
                                    min={1}
                                    step={1}
                                    {...rest}
                                    onChange={(value) => {
                                      form.setValue(
                                        'repeatCount',
                                        Number(value)
                                      )
                                    }}
                                  >
                                    <NumberInputField
                                      name={rest.name}
                                      ref={ref}
                                      textAlign={'right'}
                                    />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                )
                              }}
                            />
                            <Text ml={2}>回</Text>
                          </Flex>
                        </CsbFormControl>
                        <CsbFormControl
                          errorText={findError(errors, 'answeredRate')?.message}
                          helperText={
                            '指定した回答率に到達した場合、メールは送信されません。'
                          }
                          labelText={'回答率（' + answeredRateKind + '）'}
                          mt={6}
                        >
                          <Flex align={'center'}>
                            <Controller
                              control={form.control}
                              name={'answeredRate'}
                              render={({
                                field: { ref, value, ...restField },
                              }) => {
                                const rest = {
                                  ...restField,
                                  ...{
                                    value: value ? Number(value) : 0.0,
                                  },
                                }
                                return (
                                  <NumberInput
                                    isDisabled={loading || isCompleted}
                                    max={100.0}
                                    maxW={100}
                                    min={0.0}
                                    precision={1}
                                    step={0.1}
                                    {...rest}
                                    onChange={(value) => {
                                      form.setValue(
                                        'answeredRate',
                                        Number(value)
                                      )
                                    }}
                                  >
                                    <NumberInputField
                                      name={rest.name}
                                      ref={ref}
                                      textAlign={'right'}
                                    />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                )
                              }}
                            />
                            <Text ml={2}>%</Text>
                          </Flex>
                        </CsbFormControl>
                      </Box>
                    )}
                    {repeatType === RevealingRepeat.Dates && (
                      <>
                        <CsbFormControl
                          errorText={findError(errors, 'dates')?.message}
                          labelText={'配信日時'}
                          maxW={300}
                          mt={4}
                        >
                          {repeatDates.map((date, index) => (
                            <InputGroup key={index}>
                              <Input
                                disabled={
                                  loading ||
                                  isCompleted ||
                                  isLessEqualLastScheduleSentAt(date)
                                }
                                min={dayjs().format('YYYY-MM-DDTHH:mm')}
                                type={'datetime-local'}
                                value={date}
                                onChange={(e) => {
                                  onChangeDates(e.target.value, index)
                                }}
                              ></Input>
                              {!isLessEqualLastScheduleSentAt(date) && (
                                <IconButton
                                  aria-label="delete-date"
                                  colorScheme={'red'}
                                  disabled={
                                    loading ||
                                    noSendScheduleDates.length <= 1 ||
                                    isCompleted
                                  }
                                  icon={<DeleteIcon />}
                                  ml={3}
                                  mt={1}
                                  size={'sm'}
                                  onClick={() => {
                                    deleteDates(index)
                                  }}
                                />
                              )}
                            </InputGroup>
                          ))}
                        </CsbFormControl>
                        <Flex mt={10} w={'full'}>
                          <HStack align={'right'} ml={'auto'} spacing={3}>
                            <Button
                              disabled={loading || isCompleted}
                              onClick={addDates}
                            >
                              追加
                            </Button>
                          </HStack>
                        </Flex>
                      </>
                    )}
                  </VStack>
                </Box>
              </VStack>
              <VStack align={'start'}>
                <CsbFormControl
                  labelText={`${
                    isDeliveryContentsOnly ? 'コンテンツ配信' : '訓練'
                  }メールの配信間隔を保持する`}
                  mt={10}
                >
                  <Controller
                    control={form.control}
                    name={'isSchedule'}
                    render={({ field: { onChange, value } }) => (
                      <HStack fontSize={'sm'}>
                        <Text>無効</Text>
                        <Switch
                          isChecked={value ?? true}
                          isDisabled={loading || isCompleted}
                          onChange={onChange}
                        />
                        <Text>有効</Text>
                      </HStack>
                    )}
                  />
                </CsbFormControl>
              </VStack>
            </VStack>
          </CsbPrimaryBox>

          {repeatType !== RevealingRepeat.None && (
            <Box mt={10}>
              <Flex>
                <Heading fontSize={16}>
                  {!isDeliveryContentsOnly ? '繰り返し' : ''}送信対象者
                </Heading>
              </Flex>
              <Box>
                <TargetUserList
                  collectionMetaData={targetUsersMetadata}
                  maxH={'600px'}
                  users={targetUsers ?? []}
                  onPaging={onPagingTargetUsers}
                />
                {(targetUsersMetadata?.totalCount ?? 0) > 0 && (
                  <Text mt={1}>
                    {targetUsersMetadata?.totalCount ?? 0}
                    件のメールアドレス宛に送信します。
                  </Text>
                )}
              </Box>
            </Box>
          )}

          <Box mt={6}>
            <Flex>
              <Heading fontSize={16}>送信履歴</Heading>
            </Flex>
            <Box maxW={900} mb={'80px'}>
              <Box mt={'38px'}>
                <Grid bg={'gray.50'} templateColumns={'2fr 2fr 1fr 2fr 1fr'}>
                  <Text
                    alignItems={'center'}
                    display={'flex'}
                    fontSize={'xs'}
                    fontWeight={'bold'}
                    px={3}
                    py={3}
                  >
                    実行日時
                  </Text>
                  <Text
                    alignItems={'center'}
                    display={'flex'}
                    fontSize={'xs'}
                    fontWeight={'bold'}
                    px={3}
                    py={3}
                  >
                    送信対象
                  </Text>
                  <Text
                    alignItems={'center'}
                    display={'flex'}
                    fontSize={'xs'}
                    fontWeight={'bold'}
                    px={3}
                    py={3}
                  >
                    送信数
                  </Text>
                  <Text
                    alignItems={'center'}
                    display={'flex'}
                    fontSize={'xs'}
                    fontWeight={'bold'}
                    px={3}
                    py={3}
                  >
                    送信状況
                  </Text>
                  <Text
                    alignItems={'center'}
                    display={'flex'}
                    fontSize={'xs'}
                    fontWeight={'bold'}
                    px={3}
                    py={3}
                  >
                    送信内容
                  </Text>
                </Grid>
                <CsbList
                  border={'none'}
                  overflowY={'auto'}
                  p={0}
                  rounded={'none'}
                  shadow={'none'}
                >
                  {logs?.revealingPhishingEmailScheduleLogs.map(
                    (
                      {
                        isSent,
                        revealingPhishingEmail,
                        sendTargetType,
                        sentAt,
                        targetCount,
                      },
                      index
                    ) => (
                      <CsbListItem
                        display={'grid'}
                        gridTemplateColumns={'2fr 2fr 1fr 2fr 1fr'}
                        key={index}
                        p={0}
                      >
                        <Text fontSize={'sm'} px={3}>
                          {dayjs(sentAt).format('YYYY/MM/DD HH:mm')}
                        </Text>
                        <Text fontSize={'sm'} px={3}>
                          {sendTargetMap.get(sendTargetType) ?? '初回送信'}
                        </Text>
                        <Text fontSize={'sm'} px={6} textAlign={'right'}>
                          {targetCount}
                        </Text>
                        <Text fontSize={'sm'} px={3}>
                          {isSent ? '送信済' : '未送信（回答率到達）'}
                        </Text>
                        {isSent ? (
                          <Button
                            alignContent={'center'}
                            size={'sm'}
                            w={'80px'}
                            onClick={() => {
                              onShowRevealingEmail(revealingPhishingEmail)
                            }}
                          >
                            確認
                          </Button>
                        ) : null}
                      </CsbListItem>
                    )
                  )}
                </CsbList>
              </Box>
            </Box>
          </Box>
        </Box>

        <Flex mt={5}>
          <HStack ml={'auto'} spacing={3}>
            <Button
              colorScheme={'gray'}
              onClick={() =>
                navigate(
                  resolveDeliveryContentsUrl(
                    isPhishingEmailSent
                      ? `/phishing/${id ?? ''}/result`
                      : `/phishing/${id ?? ''}/edit`
                  )
                )
              }
            >
              戻る
            </Button>
            {isWritePhishingMail && (
              <>
                <Button
                  disabled={loading || isCompleted}
                  onClick={() => {
                    setActionName('設定')
                    sendRevealingModal.onOpen()
                  }}
                >
                  設定
                </Button>
                {isEdit && (
                  <Button
                    colorScheme={'red'}
                    disabled={loading}
                    onClick={() => {
                      setActionName('削除')
                      sendRevealingModal.onOpen()
                    }}
                  >
                    削除
                  </Button>
                )}
              </>
            )}
          </HStack>
        </Flex>

        <CsbModal
          isOpen={sendRevealingModal.isOpen}
          onClose={sendRevealingModal.onClose}
        >
          <CsbModalHeader>警告</CsbModalHeader>
          <CsbModalBody mt={10}>
            {title}のスケジュールを{actionName}しますか？
          </CsbModalBody>
          <CsbModalFooter mt={7}>
            <HStack spacing={6}>
              <Button colorScheme={'gray'} onClick={sendRevealingModal.onClose}>
                キャンセル
              </Button>
              {actionName === '削除' ? (
                <Button colorScheme={'red'} onClick={onDelete}>
                  {actionName}
                </Button>
              ) : (
                <Button onClick={onSave}>{actionName}</Button>
              )}
            </HStack>
          </CsbModalFooter>
        </CsbModal>

        <CsbModal
          isOpen={revealingPhishingEmailModal.isOpen}
          size={'2xl'}
          onClose={revealingPhishingEmailModal.onClose}
        >
          <CsbModalHeader>{title}送信内容</CsbModalHeader>
          <CsbModalBody mt={10}>
            <CsbFormControl labelText={'差出人名'}>
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {revealingPhishingEmail?.revealingPhishingEmailFromName ??
                  '配信メールと同一'}
              </CsbPrimaryBox>
            </CsbFormControl>
            <CsbFormControl labelText={'メールアドレス'} mt={5}>
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {revealingPhishingEmail?.revealingPhishingEmailFromEmail ??
                  revealingPhishingEmail?.revealingPhishingEmailMailFrom
                    ?.email ??
                  '配信メールと同一'}
              </CsbPrimaryBox>
            </CsbFormControl>

            <CsbFormControl labelText={'件名'} mt={5}>
              <CsbPrimaryBox
                cursor={'not-allowed'}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              >
                {revealingPhishingEmail?.revealingPhishingEmailSubject || ''}
              </CsbPrimaryBox>
            </CsbFormControl>
            <CsbFormControl labelText={'本文'} mt={5} w={605}>
              <CsbPrimaryBox
                cursor={'not-allowed'}
                dangerouslySetInnerHTML={{
                  __html: removeStyleTag(
                    revealingPhishingEmail?.revealingPhishingEmailHtml || ''
                  ),
                }}
                mt={2}
                opacity={0.4}
                px={4}
                py={2}
                rounded={'md'}
              />
            </CsbFormControl>
          </CsbModalBody>
          <CsbModalFooter mt={7}>
            <HStack spacing={6}>
              <Button
                colorScheme={'gray'}
                onClick={revealingPhishingEmailModal.onClose}
              >
                閉じる
              </Button>
            </HStack>
          </CsbModalFooter>
        </CsbModal>
      </Base>
    )
  }
)
