import { useResearchQuestionsByResearchUuidQuery } from '@src/graphql/generated/graphql'

export const useResearchQuestionsByResearchUuid = (
  baseOptions: Partial<
    Parameters<typeof useResearchQuestionsByResearchUuidQuery>[0]
  > = {}
) => {
  return useResearchQuestionsByResearchUuidQuery(baseOptions)
}

type useResearchQuestionsByResearchUuidType =
  typeof useResearchQuestionsByResearchUuid
export type useResearchQuestionsByResearchUuidResult =
  ReturnType<useResearchQuestionsByResearchUuidType>
// export type useResearchQuestionsByResearchUuidParameter = Parameters<useResearchQuestionsByResearchUuidType>[0]
