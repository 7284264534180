import { Button, HStack, Stack } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import { useCreateQuestionnaireModalResult } from '@src/models/Questionnaire/components/CreateQuestionnaireModal/useCreateQuestionnaireModal'
import { QuestionnaireForm } from '@src/models/Questionnaire/components/QuestionnaireForm'

export const CreateQuestionnaireModal = ({
  findErrorMessage,
  form,
  isLoading,
  isOpen,
  isResearchSelectable,
  onClose,
  onCreateQuestionnaire,
  researches,
}: useCreateQuestionnaireModalResult) => (
  <CsbModal isOpen={isOpen} onClose={onClose}>
    <CsbModalHeader>アンケートURLを発行する</CsbModalHeader>
    <CsbModalBody mt={10}>
      <Stack spacing={6}>
        <QuestionnaireForm
          findErrorMessage={findErrorMessage}
          form={form}
          isResearchSelectable={isResearchSelectable}
          researches={researches}
        />
      </Stack>
    </CsbModalBody>
    <CsbModalFooter mt={7}>
      <HStack spacing={6}>
        <Button colorScheme={'gray'} onClick={onClose}>
          キャンセル
        </Button>
        <Button isLoading={isLoading} onClick={onCreateQuestionnaire}>
          作成
        </Button>
      </HStack>
    </CsbModalFooter>
  </CsbModal>
)
