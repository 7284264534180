import { ReactNode } from 'react'

type BaseFallbackPropsType = {
  children?: ReactNode
  type: 'error' | 'loading' | 'noData'
}

//dont use it!!!
export const BaseFallback = ({ children, type }: BaseFallbackPropsType) => {
  switch (type) {
    case 'error':
      return <>{children ? children : 'error'}</>
    case 'loading':
      return <>{children ? children : 'loading...'}</>
    case 'noData':
      return <>{children ? children : 'no data'}</>
  }
}
