import { DownloadIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react'
import {
  CsbDeleteButton,
  CsbDeleteModal,
  CsbFormControl,
  CsbFormModal,
} from '@src/components'
import { CsbEditButton } from '@src/components/Buttons/CsbEditButton'
import { CsbTable } from '@src/components/CsbTable'
import { UseCompanyAttachesHooksResult } from '@src/components/pages/custom-attaches/hooks'
import { CompanyAttachFileKind } from '@src/graphql/generated/graphql'
import { Base } from '@src/layouts'
import { findError } from '@src/utils/findError'
import React, { memo } from 'react'
import { Controller } from 'react-hook-form'
//添付ファイル一覧ページのcomponent
export const CustomAttaches = memo(
  ({
    attachFileKinds,
    data,
    deleteModal,
    file,
    fileRef,
    formModal,
    getAttachFileKindName,
    isCreating,
    isUpdating,
    isUploading,
    isWriteCompanyAttach,
    onChangeFiles,
    onClickFileUpload,
    onDelete,
    onEdit,
    onFileClear,
    onSubmit,
    refetch,
  }: UseCompanyAttachesHooksResult) => {
    return (
      <>
        <Base
          left={<Heading>添付ファイル管理</Heading>}
          right={
            isWriteCompanyAttach && (
              <Button as={'div'} w={210} onClick={formModal.onCreate}>
                新しい添付ファイルの登録
              </Button>
            )
          }
        >
          <Box maxW={518} mt={12} w={'full'}>
            <Select
              placeholder={'全てのファイル'}
              onChange={async (e) => {
                const params =
                  e.target.value === ''
                    ? { fileKind: null }
                    : { fileKind: e.target.value as CompanyAttachFileKind }
                await refetch(params)
              }}
            >
              {attachFileKinds.map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </Select>
          </Box>

          <CsbTable
            labels={['添付ファイル名', 'ファイルタイプ', '']}
            wrapperProps={{ mt: 35 }}
          >
            <>
              {data?.companyAttaches.map((attach) => (
                <CsbTable.TableRow key={attach.uuid}>
                  <CsbTable.TableCell w={300}>
                    <Tooltip aria-label="A tooltip" label={attach.fileName}>
                      <Text isTruncated fontWeight={'bold'} w={300}>
                        {attach.fileName}
                      </Text>
                    </Tooltip>
                  </CsbTable.TableCell>

                  <CsbTable.TableCell w={330}>
                    <Tooltip
                      aria-label="A tooltip"
                      label={getAttachFileKindName(attach.fileKind)}
                    >
                      <Text
                        isTruncated
                        color={'gray.500'}
                        fontSize={'sm'}
                        w={300}
                      >
                        {getAttachFileKindName(attach.fileKind)}
                      </Text>
                    </Tooltip>
                  </CsbTable.TableCell>
                  <CsbTable.TableCell isNumeric>
                    {isWriteCompanyAttach && (
                      <Flex gridGap={5} justifyContent={'end'}>
                        <Tooltip aria-label="A tooltip" label={'ダウンロード'}>
                          <IconButton
                            aria-label="url"
                            colorScheme={'blue'}
                            icon={<DownloadIcon />}
                            rounded={'full'}
                            onClick={(e) => {
                              e.stopPropagation()
                              window.open(attach.url, '_blank')
                            }}
                          />
                        </Tooltip>

                        <CsbEditButton
                          onClick={(e) => {
                            e.stopPropagation()
                            onEdit(attach)
                          }}
                        />
                        <CsbDeleteButton
                          onClick={(e) => {
                            e.stopPropagation()
                            deleteModal.onOpen(attach.uuid, attach.fileName)
                          }}
                        />
                      </Flex>
                    )}
                  </CsbTable.TableCell>
                </CsbTable.TableRow>
              ))}
            </>
          </CsbTable>
          <Flex mt={5}>
            <HStack ml={'auto'} spacing={3}>
              {isWriteCompanyAttach && (
                <Button as={'div'} w={210} onClick={formModal.onCreate}>
                  新しい添付ファイルの登録
                </Button>
              )}
            </HStack>
          </Flex>
          <CsbDeleteModal
            header={deleteModal.target}
            isOpen={deleteModal.isOpen}
            onCancel={deleteModal.onClose}
            onClose={deleteModal.onClose}
            onDelete={async () => await onDelete(deleteModal.uuid ?? '')}
          />
          <CsbFormModal
            header={
              formModal.isCreate
                ? '添付ファイルの登録'
                : `${
                    formModal.form.getValues('fileName') || '添付ファイル'
                  }の更新`
            }
            isDisabled={file === null && formModal.isCreate}
            isLoading={
              formModal.isCreate
                ? isCreating || isUploading
                : isUpdating || isUploading
            }
            isOpen={formModal.isOpen}
            submit={formModal.isCreate ? '登録' : '更新'}
            onCancel={formModal.onCancel}
            onClose={formModal.onCancel}
            onSubmit={formModal.form.handleSubmit(onSubmit)}
          >
            <CsbFormControl
              errorText={findError(formModal.errors, 'fileKind')?.message}
            >
              {formModal.isCreate ? (
                <Controller
                  control={formModal.form.control}
                  name={'fileKind'}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      disabled={formModal.state === 'update'}
                      value={value}
                      onChange={onChange}
                    >
                      {attachFileKinds.map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                    </Select>
                  )}
                />
              ) : (
                <Text>
                  {getAttachFileKindName(formModal.form.getValues('fileKind'))}
                </Text>
              )}
            </CsbFormControl>
            <CsbFormControl
              errorText={findError(formModal.errors, 'fileName')?.message}
            >
              <Input
                {...formModal.form.register('fileName')}
                isRequired
                placeholder={'添付ファイルの名前*'}
              />
            </CsbFormControl>

            <CsbFormControl
              errorText={findError(formModal.errors, 'filePath')?.message}
            >
              <Box
                alignItems={'start'}
                borderColor={'gray.200'}
                borderWidth={2}
                p={3}
                rounded={10}
              >
                <input
                  hidden
                  multiple={false}
                  ref={fileRef}
                  type="file"
                  onChange={onChangeFiles}
                />
                {formModal.isUpdate ? (
                  <Text>{formModal.form.getValues('filePath')}</Text>
                ) : file !== null ? (
                  <>
                    <Table mb={3}>
                      <Tbody>
                        <Tr>
                          <Td>{file.name}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                    <HStack display={'flex'} justifyContent={'end'} spacing={3}>
                      <Button colorScheme={'gray'} onClick={onFileClear}>
                        クリア
                      </Button>
                    </HStack>
                  </>
                ) : (
                  <>
                    <VStack align="stretch" spacing={3}>
                      <Box>
                        <Button onClick={onClickFileUpload}>
                          添付ファイルの選択
                        </Button>
                      </Box>
                    </VStack>
                  </>
                )}
              </Box>
            </CsbFormControl>
          </CsbFormModal>
        </Base>
      </>
    )
  }
)
