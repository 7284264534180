import { parse, ParsedQuery, stringify } from 'query-string'
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * フィッシングページでisDeliveryContentsOnlyのqueryがついているかどうか
 */
export const useDeliveryContents = () => {
  const name = 'isDeliveryContentsOnly' as const
  const { search } = useLocation()
  const query = parse(search, { parseBooleans: true })
  const isDeliveryContentsOnly = ((query: ParsedQuery<string | boolean>) => {
    const isDeliveryContentsOnly = query[name]
    if (isDeliveryContentsOnly === true) {
      return isDeliveryContentsOnly
    }
    return false
  })(query)

  const resolveDeliveryContentsUrl = useCallback(
    (
      url: string,
      {
        params,
      }: Partial<{
        params?: Record<string, unknown>
      }> = {}
    ) => {
      const query = stringify({
        ...params,
        ...(isDeliveryContentsOnly
          ? {
              [name]: true,
            }
          : {}),
      })
      return `${url}?${query}`
    },
    [isDeliveryContentsOnly]
  )
  return {
    isDeliveryContentsOnly,
    resolveDeliveryContentsUrl,
  }
}
