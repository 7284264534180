import { Flex, Spinner, Text } from '@chakra-ui/react'

type CsbStatsPropsType = {
  hint?: string
  label: string
  loading: boolean
  unit: string
  value: number | undefined
}

export const CsbStats = ({
  hint,
  label,
  loading,
  unit,
  value = 0,
}: CsbStatsPropsType) => {
  return (
    <Flex
      alignItems={'center'}
      flexDirection={'column'}
      justifyContent={'center'}
    >
      {loading ? (
        <Spinner
          color="blue.500"
          emptyColor="gray.200"
          size="xl"
          speed="0.65s"
          thickness="4px"
        />
      ) : (
        <>
          <Text color={'gray.500'} fontSize={'xl'} fontWeight={'bold'}>
            {label}
          </Text>
          <Text fontSize={'3xl'} fontWeight={'bold'} mt={4}>
            {value}
            <Text as={'small'} fontSize={'16px'}>
              {unit}
            </Text>
          </Text>
          {hint ? (
            <Text color={'gray.500'} fontSize={'sm'} fontWeight={'bold'}>
              {hint}
            </Text>
          ) : null}
        </>
      )}
    </Flex>
  )
}
