import { CustomAttaches } from '@src/components/pages/custom-attaches'
import { useCompanyAttachesHooks } from '@src/components/pages/custom-attaches/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const CustomAttachesPage = memo(() => {
  const companyAttachesHooks = useCompanyAttachesHooks()
  return (
    <Authorization>
      <CustomAttaches {...companyAttachesHooks} />
    </Authorization>
  )
})
