import { useDisclosure } from '@chakra-ui/react'
import { DocumentsQuery } from '@src/graphql/generated/graphql'
import { useDestroyDocument } from '@src/models/Document/useDestroyDocument'
import { useCallback, useState } from 'react'

type Document = DocumentsQuery['documents']['collection'][number]

export const useDestroyDocumentModal = () => {
  const [document, setDocument] = useState<Document>()
  const { isOpen, onClose: _onClose, onOpen: _onOpen } = useDisclosure()
  const { isLoading, onDestroyDocument: _onDestroyDocument } =
    useDestroyDocument({
      onCompleted: () => {
        _onClose()
      },
    })
  const onClose = useCallback(() => {
    _onClose()
  }, [_onClose])

  const onOpen = useCallback(
    (document: Document) => {
      _onOpen()
      setDocument(document)
    },
    [_onOpen, setDocument]
  )

  const onDestroyDocument = useCallback(() => {
    if (!document) return
    _onDestroyDocument(document.uuid)
  }, [document, _onDestroyDocument])

  return {
    document,
    isLoading,
    isOpen,
    onClose,
    onDestroyDocument,
    onOpen,
  }
}

type UseDestroyDocumentModalType = typeof useDestroyDocumentModal
export type UseDestroyDocumentModalResult =
  ReturnType<UseDestroyDocumentModalType>
