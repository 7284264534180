import { QuestionnaireAnswer } from '@src/components/pages/questionnaires/[id]'
import { useQuestionnaireAnswerHooks } from '@src/components/pages/questionnaires/[id]/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const QuestionnaireAnswerPage = memo(() => {
  const questionnaireAnswerHooks = useQuestionnaireAnswerHooks()
  return (
    <Authorization>
      <QuestionnaireAnswer {...questionnaireAnswerHooks} />
    </Authorization>
  )
})
