import {
  AdminResearchesDetailPage,
  AdminResearchesPage,
  AttachesPage,
  ComicsEditPage,
  ComicsPage,
  CompaniesDetailPage,
  CompaniesEditPage,
  CompaniesNewPage,
  CompaniesPage,
  CustomAttachesPage,
  DashboardPage,
  DocumentsPage,
  GroupsPage,
  LearnDetailPage,
  LearningPage,
  LearningThanksPage,
  LearnPage,
  LinkDomainsPage,
  LoginPage,
  LogoutPage,
  MailsPage,
  MoviesPage,
  OrganizationsPage,
  PdfsPage,
  PhishingArchivesPage,
  PhishingEditPage,
  PhishingNewPage,
  PhishingPage,
  PhishingResultNewPage,
  PhishingResultPage,
  PhishingResultSendRevealingPage,
  PhishingResultSendRevealingSchedulePage,
  PositionPage,
  PreviewComicsPage,
  PreviewLearnPage,
  QuestionnaireAnswerPage,
  QuestionnaireResultPage,
  QuestionnairesPage,
  ResearchesDetailPage,
  ResearchesPage,
  RolesPage,
  SettingsPage,
  SitesPage,
  TemplatesEditPage,
  TemplatesNewPage,
  TemplatesPage,
  UsersEditPage,
  UsersImportPage,
  UsersNewPage,
  UsersPage,
} from '@src/pages'
import { CampaignsPage } from '@src/pages/campaigns'
import { PreviewLearningPage } from '@src/pages/preview/learning/[id]'
import { PreviewResearchPage } from '@src/pages/preview/researches/[id]'
import { UnlockUserPage } from '@src/pages/unlock-user'
import { UserLockLogsPage } from '@src/pages/user-lock-logs'

export const PAGES = {
  AdminResearches: {
    auth: true,
    children: <AdminResearchesPage />,
    exact: true,
    path: '/admin-researches',
    title: '共通アンケート作成',
  },
  AdminResearchesEdit: {
    auth: true,
    children: <AdminResearchesDetailPage />,
    exact: true,
    path: '/admin-researches/:id/edit',
    title: '共通アンケート編集',
  },
  Attaches: {
    auth: true,
    children: <AttachesPage />,
    exact: true,
    path: '/attaches',
    title: '添付ファイル',
  },
  Campaigns: {
    auth: true,
    children: <CampaignsPage />,
    exact: true,
    path: '/campaigns',
    title: 'キャンペーン一覧',
  },
  Comics: {
    auth: true,
    children: <ComicsPage />,
    exact: true,
    path: '/comics',
    title: 'コミック一覧',
  },
  ComicsEdit: {
    auth: true,
    children: <ComicsEditPage />,
    exact: true,
    path: '/comics/:id/edit',
    title: 'コミック編集',
  },
  Companies: {
    auth: true,
    children: <CompaniesPage />,
    exact: true,
    path: '/companies',
    title: '企業一覧',
  },
  CompaniesDetail: {
    auth: true,
    children: <CompaniesDetailPage />,
    exact: true,
    path: '/companies/detail',
    title: '企業詳細',
  },
  CompaniesEdit: {
    auth: true,
    children: <CompaniesEditPage />,
    exact: true,
    path: '/companies/:id/edit',
    title: '企業編集',
  },
  CompaniesNew: {
    auth: true,
    children: <CompaniesNewPage />,
    exact: true,
    path: '/companies/new',
    title: '企業作成',
  },
  CustomAttaches: {
    auth: true,
    children: <CustomAttachesPage />,
    exact: true,
    path: '/custom-attaches',
    title: 'カスタム添付ファイル',
  },
  Dashboard: {
    auth: true,
    children: <DashboardPage />,
    exact: true,
    path: '/dashboard',
    title: 'ダッシュボード',
  },
  Documents: {
    auth: true,
    children: <DocumentsPage />,
    exact: true,
    path: '/documents',
    title: 'ドキュメント作成',
  },
  Groups: {
    auth: true,
    children: <GroupsPage />,
    exact: true,
    path: '/groups',
    title: 'グループ一覧',
  },
  Learn: {
    auth: true,
    children: <LearnPage />,
    exact: true,
    path: '/learn',
    title: '学習コンテンツ一覧',
  },
  LearnDetail: {
    auth: true,
    children: <LearnDetailPage />,
    exact: true,
    path: '/learn/:id',
    title: '学習コンテンツ詳細',
  },
  Learning: {
    auth: false,
    children: <LearningPage />,
    exact: true,
    path: '/learning/:id',
    title: '学習コンテンツ',
  },
  LearningThanks: {
    auth: false,
    children: <LearningThanksPage />,
    exact: true,
    path: '/learning/:id/thanks',
    title: '回答ありがとうございました',
  },
  LinkDomains: {
    auth: true,
    children: <LinkDomainsPage />,
    exact: true,
    path: '/link-domains',
    title: 'リンクドメイン一覧',
  },
  Mails: {
    auth: true,
    children: <MailsPage />,
    exact: true,
    path: '/mails',
    title: 'ドメイン管理',
  },
  Movies: {
    auth: true,
    children: <MoviesPage />,
    exact: true,
    path: '/movies',
    title: '動画一覧',
  },
  Organizations: {
    auth: true,
    children: <OrganizationsPage />,
    exact: true,
    path: '/organizations',
    title: '組織一覧',
  },
  Pdfs: {
    auth: true,
    children: <PdfsPage />,
    exact: true,
    path: '/pdfs',
    title: 'PDF一覧',
  },
  Phishing: {
    auth: true,
    children: <PhishingPage />,
    exact: true,
    path: '/phishing',
    title: 'フィッシングメール一覧',
  },
  PhishingArchives: {
    auth: true,
    children: <PhishingArchivesPage />,
    exact: true,
    path: '/phishing/archives',
    title: 'フィッシングメールアーカイブ',
  },
  PhishingEdit: {
    auth: true,
    children: <PhishingEditPage />,
    exact: true,
    path: '/phishing/:id/edit',
    title: 'フィッシングメール編集',
  },
  PhishingNew: {
    auth: true,
    children: <PhishingNewPage />,
    exact: true,
    path: '/phishing/new',
    title: 'フィッシングメール作成',
  },
  PhishingResult: {
    auth: true,
    children: <PhishingResultPage />,
    exact: true,
    path: '/phishing/:id/result/old',
    title: 'フィッシングメール結果',
  },
  PhishingResultNew: {
    auth: true,
    children: <PhishingResultNewPage />,
    exact: true,
    path: '/phishing/:id/result',
    title: 'フィッシングメール結果',
  },
  PhishingResultSendRevealing: {
    auth: true,
    children: <PhishingResultSendRevealingPage />,
    exact: true,
    path: '/phishing/:id/result/send/revealing',
    title: 'フィッシングメール種明かしメール個別送信',
  },
  PhishingResultSendRevealingSchedule: {
    auth: true,
    children: <PhishingResultSendRevealingSchedulePage />,
    exact: true,
    path: '/phishing/:id/result/send/revealing/schedule',
    title: 'フィッシングメール種明かしメールスケジュール送信',
  },
  Position: {
    auth: true,
    children: <PositionPage />,
    exact: true,
    path: '/positions',
    title: '役職一覧',
  },
  PreviewComics: {
    auth: false,
    children: <PreviewComicsPage />,
    exact: true,
    path: '/preview/comics/:id',
    title: 'コミックのプレビュー',
  },
  PreviewLearn: {
    auth: false,
    children: <PreviewLearnPage />,
    exact: true,
    path: '/preview/learn/:id',
    title: '学習問題のプレビュー',
  },
  PreviewLearning: {
    auth: false,
    children: <PreviewLearningPage />,
    exact: true,
    path: '/preview/learning/:id',
    title: '学習問題の全体プレビュー',
  },
  PreviewResearch: {
    auth: false,
    children: <PreviewResearchPage />,
    exact: true,
    path: '/preview/researches/:id',
    title: 'アンケートのプレビュー',
  },
  QuestionnaireAnswer: {
    auth: false,
    children: <QuestionnaireAnswerPage />,
    exact: true,
    path: '/questionnaires/:id',
    title: 'アンケート回答',
  },
  QuestionnaireResult: {
    auth: true,
    children: <QuestionnaireResultPage />,
    exact: true,
    path: '/questionnaires/:id/result',
    title: 'アンケート結果',
  },
  Questionnaires: {
    auth: true,
    children: <QuestionnairesPage />,
    exact: true,
    path: '/questionnaires',
    title: 'アンケートURL発行',
  },
  Researches: {
    auth: true,
    children: <ResearchesPage />,
    exact: true,
    path: '/researches',
    title: 'アンケート作成',
  },
  ResearchesEdit: {
    auth: true,
    children: <ResearchesDetailPage />,
    exact: true,
    path: '/researches/:id/edit',
    title: 'アンケート編集',
  },
  Roles: {
    auth: true,
    children: <RolesPage />,
    exact: true,
    path: '/roles',
    title: '権限一覧',
  },
  Settings: {
    auth: true,
    children: <SettingsPage />,
    exact: true,
    path: '/settings',
    title: '設定',
  },
  SignIn: {
    auth: false,
    children: <LoginPage />,
    exact: true,
    path: '/sign_out',
    title: 'ログアウト',
  },
  SignOut: {
    auth: false,
    children: <LogoutPage />,
    exact: true,
    path: '/sign_in',
    title: 'ログイン',
  },
  Sites: {
    auth: true,
    children: <SitesPage />,
    exact: true,
    path: '/sites',
    title: 'フィッシングサイト一覧',
  },
  Templates: {
    auth: true,
    children: <TemplatesPage />,
    exact: true,
    path: '/templates',
    title: 'メールテンプレート一覧',
  },
  TemplatesEdit: {
    auth: true,
    children: <TemplatesEditPage />,
    exact: true,
    path: '/templates/:id/edit',
    title: 'メールテンプレート編集',
  },
  TemplatesNew: {
    auth: true,
    children: <TemplatesNewPage />,
    exact: true,
    path: '/templates/new',
    title: 'メールテンプレート作成',
  },
  UnlockUser: {
    auth: false,
    children: <UnlockUserPage />,
    exact: true,
    path: '/unlock_user',
    title: 'アカウントロック解除',
  },
  UserLockLogs: {
    auth: true,
    children: <UserLockLogsPage />,
    exact: true,
    path: '/user_lock_logs',
    title: 'ユーザーロックログ一覧',
  },
  Users: {
    auth: true,
    children: <UsersPage />,
    exact: true,
    path: '/users',
    title: 'ユーザー一覧',
  },
  UsersEdit: {
    auth: true,
    children: <UsersEditPage />,
    exact: true,
    path: '/users/:id/edit',
    title: 'ユーザー編集',
  },
  UsersImport: {
    auth: true,
    children: <UsersImportPage />,
    exact: true,
    path: '/users/import',
    title: 'ユーザーインポート',
  },
  UsersNew: {
    auth: true,
    children: <UsersNewPage />,
    exact: true,
    path: '/users/new',
    title: 'ユーザー作成',
  },
} as const

export type pageKeyTypes = keyof typeof PAGES
export type pageObjectType = typeof PAGES[keyof typeof PAGES]
export type pagePaths = typeof PAGES[pageKeyTypes]['path']
