import { ApolloError } from '@apollo/client'
import {
  CreateDocumentInput as _CreateDocumentInput,
  CreateDocumentMutationResult,
  useCreateDocumentMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors, useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { formReset } from '@src/utils/formReset'
import { useForm } from 'react-hook-form'

type CreateDocumentInput = Omit<_CreateDocumentInput, 'clientMutationId'>

export const useCreateDocument = ({
  onCompleted,
  onError,
}: Partial<BaseOptionsType<CreateDocumentMutationResult['data']>> = {}) => {
  const toast = useToast()
  const [createDocument, { loading: isLoading }] = useCreateDocumentMutation({
    refetchQueries: ['documents'],
  })
  const form = useForm<CreateDocumentInput>({
    defaultValues: {
      changeLog: '',
      name: '',
      url: '',
    },
  })
  const { findErrorMessage, resetErrors, setErrors } =
    useGraphQLErrors<keyof CreateDocumentInput>()
  const resetForm = () => {
    form.setValue('changeLog', '')
    form.setValue('name', '')
    form.setValue('url', '')
  }

  const onCreateDocument = form.handleSubmit(async (inputData) => {
    try {
      const { data } = await createDocument({
        variables: {
          input: inputData,
        },
      })
      formReset(form, resetErrors)
      onCompleted?.(data)
      toast({
        status: 'success',
        title: 'ドキュメントを作成しました。',
      })
    } catch (e) {
      toast({
        status: 'error',
        title: 'ドキュメントの作成に失敗しました。',
      })
      if (e instanceof ApolloError) {
        setErrors(e.graphQLErrors)
        onError?.(e)
      }
    }
  })

  return {
    findErrorMessage,
    form,
    isLoading,
    onCreateDocument,
    resetErrors,
    resetForm,
  }
}

type UseCreateDocument = typeof useCreateDocument
export type UseCreateDocumentResult = ReturnType<UseCreateDocument>
