import { Flex, Text } from '@chakra-ui/react'
import { CsbPieChart } from '@src/components'

type PieDataType = {
  data: number[]
  labels: string[]
}

type PieDataTypes = {
  generations?: PieDataType
  organizations?: PieDataType
  positions?: PieDataType
}

type PieChatsPropsType<T extends PieDataTypes> = {
  data: T
}

const pieChats = [
  {
    label: '役職',
    name: 'positions',
  },
  {
    label: '組織',
    name: 'organizations',
  },
  {
    label: '年齢',
    name: 'generations',
  },
] as const

export const PieChats = <T extends PieDataTypes>({
  data,
}: PieChatsPropsType<T>) => {
  return (
    <>
      {pieChats.map(({ label, name }, index) => (
        <Flex
          align={'center'}
          direction={'column'}
          justify={'center'}
          key={`${name}_${index}`}
          mx={'auto'}
          pb={9}
          pt={22}
          w={'min(100%, 200px)'}
        >
          <Text color={'gray.500'} fontSize={'xl'} fontWeight={'bold'}>
            {label}
          </Text>
          <CsbPieChart
            data={data[name]?.data ?? []}
            labels={data[name]?.labels ?? []}
            mt={5}
            mx="auto"
          />
        </Flex>
      ))}
    </>
  )
}
