import { useInterval } from '@chakra-ui/react'
import {
  CsvImportStatusUsersQuery,
  useCsvImportStatusQuery,
  useCsvImportStatusUsersLazyQuery,
  useCsvImportUserMutation,
} from '@src/graphql/generated/graphql'
import { useToast } from '@src/hooks'
import { useCsbDataModal } from '@src/hooks/useCsbDataModal'
import { useFileUpload } from '@src/hooks/useFileUpload'
import { ChangeEvent, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//ユーザーインポートで使うhooksのまとめ
export const useUsersImportHooks = () => {
  const { loading, onUpload } = useFileUpload('upload_csv/users', true)
  const [csv, setCsv] = useState<File | null>(null)
  const fileRef = useRef<HTMLInputElement | null>(null)
  const navigate = useNavigate()
  const toast = useToast()

  const [registerCsvUrl, { loading: uploading }] = useCsvImportUserMutation()

  const { data: imports, refetch: refetchStatus } = useCsvImportStatusQuery()
  const dataModal =
    useCsbDataModal<
      NonNullable<
        CsvImportStatusUsersQuery['csvImportStatusUsers']
      >['csvImportUsers']
    >()
  const [getUsers] = useCsvImportStatusUsersLazyQuery({
    onCompleted: ({ csvImportStatusUsers }) => {
      dataModal.setData(csvImportStatusUsers?.csvImportUsers ?? [])
    },
  })

  useInterval(async () => {
    await refetchStatus()
  }, 3000)

  const onClickFileUpload = () => {
    if (fileRef.current !== null) {
      fileRef.current.click()
    }
  }

  const onChangeFiles = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target.files[0] !== null) {
      setCsv(event.target.files[0])
    }
  }

  const onFileClear = () => {
    if (fileRef.current !== null) {
      fileRef.current.value = ''
      setCsv(null)
    }
  }
  const onFileUpload = async () => {
    if (csv !== null) {
      const uploadUrl = await onUpload(csv)
      try {
        await registerCsvUrl({
          variables: {
            input: {
              filePath: uploadUrl,
              override: false,
            },
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        const message =
          e.graphQLErrors.length > 0
            ? e.graphQLErrors[0].message
            : 'ユーザーCSVのインポートに失敗しました。'
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'error',
          title: message,
        })
        // ファイルクリア
        onFileClear()
      }
      refetchStatus()
    }
  }
  return {
    csv,
    dataModal,
    fileRef,
    getUsers,
    imports,
    loading,
    navigate,
    onChangeFiles,
    onClickFileUpload,
    onFileClear,
    onFileUpload,
    uploading,
  }
}

export type useUsersImportHooksResult = ReturnType<typeof useUsersImportHooks>
