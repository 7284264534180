import { Sites } from '@src/components/pages/sites'
import { useSitesHooks } from '@src/components/pages/sites/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const SitesPage = memo(() => {
  const sitesHooks = useSitesHooks()
  return (
    <Authorization>
      <Sites {...sitesHooks} />
    </Authorization>
  )
})
