import {
  Avatar,
  AvatarProps,
  Grid,
  HStack,
  Text,
  Tooltip,
} from '@chakra-ui/react'

type UserAvatarPropsType = {
  description?: string
} & AvatarProps

export const CsbUserAvatar = ({
  description,
  ...props
}: UserAvatarPropsType) => {
  return (
    <HStack spacing={6}>
      <Avatar size={'md'} {...props} />
      <Grid rowGap={1}>
        <Tooltip aria-label="A tooltip" label={props.name}>
          <Text isTruncated fontWeight={'bold'} lineHeight={1}>
            {props.name}
          </Text>
        </Tooltip>
        <Tooltip aria-label="A tooltip" label={description}>
          <Text isTruncated color={'gray.600'} fontSize={'sm'} lineHeight={1}>
            {description}
          </Text>
        </Tooltip>
      </Grid>
    </HStack>
  )
}
