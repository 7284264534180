import { Box, Flex, Heading, Select, Skeleton, Text } from '@chakra-ui/react'
import { CsbPagination } from '@src/components'
import { CsbCounter } from '@src/components/CsbCounter'
import { CsbTable } from '@src/components/CsbTable'
import { usePhishingResultNewContentHooksResult } from '@src/components/pages/phishing/[id]/result/PhishingResultNewContent'
import { usePagination } from '@src/hooks'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import { PhishingResultDetails } from '@src/models/Phishing/PhishingResultDetails/PhishingResultDetails'
import { usePhishingResultDetails } from '@src/models/Phishing/PhishingResultDetails/usePhishingResultDetails'
import { useEffect, useState } from 'react'

export const Detail = ({
  id,
  usePhishingResultContentsSelectResult,
}: usePhishingResultNewContentHooksResult) => {
  //ページネーション
  const { current, onChangePage } = usePagination()
  const [select, setSelect] = useState(1)
  const usePhishingResultDetailsResult = usePhishingResultDetails({
    variables: {
      model: select,
      pagination: {
        limit: 10,
        page: current,
      },
      uuid: id ?? '',
    },
  })

  const { refetch } = usePhishingResultDetailsResult
  useEffect(() => {
    refetch().then()
  }, [refetch, usePhishingResultContentsSelectResult.form.formState])

  const { isDeliveryContentsOnly } = useDeliveryContents()

  const labels = !isDeliveryContentsOnly
    ? [
        { label: '' },
        { label: '' },
        { label: '配信数' },
        { label: '開封率' },
        { label: 'アクセス率' },
        { label: 'ログイン率' },
        { label: 'ラーニング完了率' },
      ]
    : [
        { label: '' },
        { label: '' },
        { label: '配信数' },
        { label: '開封率' },
        { label: 'ラーニング完了率' },
      ]

  return (
    <>
      <Flex>
        <Heading mb={'40px'}>詳細</Heading>
        <Box ml={'auto'}>
          <Select
            value={select}
            onChange={(e) => {
              setSelect(parseInt(e.target.value) ?? 1)
            }}
          >
            <option value="1">組織</option>
            <option value="2">役職</option>
            <option value="3">グループ</option>
          </Select>
        </Box>
      </Flex>
      <CsbTable labels={labels}>
        <PhishingResultDetails
          {...usePhishingResultDetailsResult}
          loadingFallback={[1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, index) => (
            <CsbTable.TableRow key={`PhishingResultDetails_loading_${index}`}>
              <CsbTable.TableCell colSpan={8}>
                <Skeleton h={12} />
              </CsbTable.TableCell>
            </CsbTable.TableRow>
          ))}
        >
          {(data) => (
            <>
              {data.phishingResultDetails.collection.map((item) => (
                <CsbTable.TableRow key={item.name}>
                  <CsbTable.TableCell colSpan={2}>
                    {item.name}
                  </CsbTable.TableCell>
                  <CsbTable.TableCell>
                    <Text fontWeight={'bold'}>
                      {item.deliveryCount && <>{item.deliveryCount}</>}
                    </Text>
                  </CsbTable.TableCell>
                  <CsbTable.TableCell>
                    <Text fontWeight={'bold'}>{item.openRate}</Text>
                    <Text fontSize={'xs'}>{item.openCount}</Text>
                  </CsbTable.TableCell>
                  {!isDeliveryContentsOnly && (
                    <>
                      <CsbTable.TableCell>
                        <Text fontWeight={'bold'}>{item.accessRate}</Text>
                        <Text fontSize={'xs'}>{item.accessCount}</Text>
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        <Text
                          color={item.loginWarning ? 'red.500' : undefined}
                          fontWeight={'bold'}
                        >
                          {item.loginRate}
                        </Text>
                        <Text fontSize={'xs'}>{item.loginCount}</Text>
                      </CsbTable.TableCell>
                    </>
                  )}
                  <CsbTable.TableCell>
                    <Text
                      color={item.learnedWarning ? 'red.500' : undefined}
                      fontWeight={'bold'}
                    >
                      {item.learnedRate}
                    </Text>
                    <Text fontSize={'xs'}>{item.learnedCount}</Text>
                  </CsbTable.TableCell>
                </CsbTable.TableRow>
              ))}
            </>
          )}
        </PhishingResultDetails>
      </CsbTable>
      <PhishingResultDetails
        {...usePhishingResultDetailsResult}
        loadingFallback={<Skeleton h={'52px'} mt={5} />}
      >
        {(data) => (
          <Flex mt={5}>
            <CsbPagination
              current={current}
              defaultCurrent={current}
              pageSize={data.phishingResultDetails.metadata.limitValue}
              total={data.phishingResultDetails.metadata.totalCount}
              onChange={onChangePage()}
            />
            <Box ml={'auto'}>
              <CsbCounter
                data={{
                  ...data.phishingResultDetails.metadata,
                  currentCount: data.phishingResultDetails.collection.length,
                }}
              />
            </Box>
          </Flex>
        )}
      </PhishingResultDetails>
    </>
  )
}
