import { Button, HStack, Stack } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import { ResearchForm } from '@src/models/Research'
import { UseCreateResearchModalResult } from '@src/models/Research/components/CreateResearchModal/useCreateResearchModal'

export const CreateResearchModal = ({
  findErrorMessage,
  form,
  isLoading,
  isOpen,
  onClose,
  onCreateResearch,
}: UseCreateResearchModalResult) => (
  <CsbModal isOpen={isOpen} onClose={onClose}>
    <CsbModalHeader>アンケートを作成する</CsbModalHeader>
    <CsbModalBody mt={10}>
      <Stack spacing={6}>
        <ResearchForm findErrorMessage={findErrorMessage} form={form} />
      </Stack>
    </CsbModalBody>
    <CsbModalFooter mt={7}>
      <HStack spacing={6}>
        <Button colorScheme={'gray'} onClick={onClose}>
          キャンセル
        </Button>
        <Button isLoading={isLoading} onClick={onCreateResearch}>
          作成
        </Button>
      </HStack>
    </CsbModalFooter>
  </CsbModal>
)
