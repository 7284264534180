import { useToast } from '@chakra-ui/react'
import { LocalStorage } from '@src/enums/localStorage'
import {
  AuthUserInput,
  useAuthUserMutation,
} from '@src/graphql/generated/graphql'
import { useErrors, useLocalStorage, useQueryParams } from '@src/hooks'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
//ログインで使うhooksのまとめ
export const useLoginHooks = () => {
  const [authUserMutation] = useAuthUserMutation()
  const [, setAccessToken] = useLocalStorage<string>(
    LocalStorage.ACCESS_TOKEN,
    ''
  )

  const [errors, setErrors] = useErrors()
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const { companyId, unlocked } = useQueryParams()
  const toast = useToast()

  const form = useForm<AuthUserInput>({
    defaultValues: {
      companyId: Number(companyId),
      email: '',
      password: '',
      recaptchaToken: '',
    },
  })

  const onSubmit = form.handleSubmit(
    async ({ companyId, ...inputData }: AuthUserInput) => {
      setLoading(true)

      try {
        const response = await authUserMutation({
          variables: {
            input: {
              ...inputData,
              companyId: Number(companyId) || 0,
            },
          },
        })

        setLoading(false)
        setErrors([])
        setAccessToken(response.data?.authUser?.token)
        navigate(response.data?.authUser?.redirectPath as string)
        /**
         * TODO anyを消す
         * e instanceOf ApolloError
         * setErrorsを変更する
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        setErrors(e.graphQLErrors)
        setLoading(false)
      }
    }
  )

  useEffect(() => {
    if (unlocked === 'true') {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'success',
        title: 'アカウントのロックを解除しました。',
      })
      return
    }
  }, [toast, unlocked])

  return {
    errors,
    form,
    loading,
    onSubmit,
  }
}

export type useLoginHooksResult = ReturnType<typeof useLoginHooks>
