import { Button, HStack } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import { UseDestroyDocumentModalResult } from '@src/models/Document/components/DestroyDocumentModal/useDestroyDocumentModal'

export const DestroyDocumentModal = ({
  document,
  isLoading,
  isOpen,
  onClose,
  onDestroyDocument,
}: UseDestroyDocumentModalResult) => (
  <CsbModal isOpen={isOpen} onClose={onClose}>
    <CsbModalHeader>警告</CsbModalHeader>
    <CsbModalBody mt={10}>{document?.name}を削除しますか？</CsbModalBody>
    <CsbModalFooter mt={7}>
      <HStack spacing={6}>
        <Button colorScheme={'gray'} onClick={onClose}>
          キャンセル
        </Button>
        <Button
          colorScheme={'red'}
          isLoading={isLoading}
          onClick={onDestroyDocument}
        >
          削除
        </Button>
      </HStack>
    </CsbModalFooter>
  </CsbModal>
)
