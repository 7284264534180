import { DeleteIcon, QuestionOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  CenterProps,
  chakra,
  Checkbox,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Switch,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { CsbEditor, CsbFormControl, CsbPrimaryBox } from '@src/components'
import { DropDropUploader } from '@src/components/Form/DropDropUploader'
import {
  getMailPlaceholders,
  useMailPlaceholderModal,
} from '@src/components/Modal/MailPlaceholderModal/MailPlaceholderModal'
import { useSettingHooksResult } from '@src/components/pages/settings/hooks'
import { MAIL_DAYS } from '@src/constants'
import {
  IpFilterInput,
  PhishingResultLayoutTemplate,
} from '@src/graphql/generated/graphql'
import { Base } from '@src/layouts'
import { findError } from '@src/utils/findError'
import { ChangeEvent, memo, ReactNode, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'

type InputLoadingProps = {
  children: ReactNode
  isLoading: boolean
  loadingFallbackProps?: Omit<CenterProps, 'children'>
}

const InputLoading = ({
  children,
  isLoading,
  loadingFallbackProps,
}: InputLoadingProps) => {
  return (
    <Box pos={'relative'}>
      {children}
      {isLoading && (
        <Center
          bgColor={'gray.100'}
          h={10}
          inset={0}
          pos={'absolute'}
          {...loadingFallbackProps}
        >
          <Spinner size="xs" />
        </Center>
      )}
    </Box>
  )
}

//メールテンプレート更新ページのcomponent
export const Settings = memo(
  ({
    errors,
    form,
    isDefaultPhishingResultLayout,
    isLoadedSettingData,
    isWriteConfig,
    isWriteIpFilter,
    loading,
    onSubmit,
  }: useSettingHooksResult) => {
    const mailPlaceHolders = getMailPlaceholders(false)

    const [MailPlaceholderModal, { onOpen: mailPlaceholderModalOnOpen }] =
      useMailPlaceholderModal(mailPlaceHolders)

    // noinspection DuplicatedCode
    const onChangeIsArchiveAttach = (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value) {
        form.setValue('isArchiveAttachPassword', false)
        form.setValue('archiveAttachPassword', '')
        form.setValue('isCustomNotifyPassword', false)
        form.setValue('notifyArchivePasswordSubject', '')
        form.setValue('notifyArchivePasswordHtml', '')
      }
    }

    const onChangeIsArchiveAttachPassword = (
      e: ChangeEvent<HTMLInputElement>
    ) => {
      if (!e.target.value) {
        form.setValue('archiveAttachPassword', '')
        form.setValue('isCustomNotifyPassword', false)
        form.setValue('notifyArchivePasswordSubject', '')
        form.setValue('notifyArchivePasswordHtml', '')
      }
    }

    const onChangeIsCustomNotifyPassword = (
      e: ChangeEvent<HTMLInputElement>
    ) => {
      if (!e.target.value) {
        form.setValue('notifyArchivePasswordSubject', '')
        form.setValue('notifyArchivePasswordHtml', '')
      }
    }

    const [ipFilters, setIpFilters] = useState<IpFilterInput[]>(
      form.getValues('ipFilters') ?? []
    )

    useEffect(() => {
      if (isLoadedSettingData) {
        setIpFilters(form.getValues('ipFilters') ?? [])
      }
    }, [form, isLoadedSettingData])

    const onAddIpFilter = () => {
      const newIpFilters = [
        ...(form.getValues('ipFilters') || []),
        ...[
          {
            id: null,
            ipAddress: '',
            userAgent: '',
          },
        ],
      ]
      setIpFilters(newIpFilters)
    }

    const onDeleteIpFilter = (index: number) => {
      const ipFilters = form.getValues('ipFilters') || []
      const heads = ipFilters.slice(0, index)
      const tails = ipFilters.slice(index + 1)
      const filters = [...heads, ...tails]
      const newIpFilters =
        filters.length === 0
          ? [{ id: null, ipAddress: '', userAgent: '' }]
          : filters
      setIpFilters(newIpFilters)
    }

    useEffect(() => {
      form.setValue('ipFilters', ipFilters)
    }, [ipFilters, form])

    return (
      <Base
        left={<Heading>設定</Heading>}
        right={
          isWriteConfig && (
            <HStack spacing={6}>
              <Button isLoading={loading} onClick={onSubmit}>
                保存
              </Button>
            </HStack>
          )
        }
      >
        <Box mt={45}>
          <CsbPrimaryBox pb={'72px'} pt={35} px={45}>
            <VStack align={'start'}>
              <Box w={'full'}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>種明かしのメール通知</Heading>
                  <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                    フィッシングメールに引っかからなかったユーザーを対象に種明かしメールを送信します
                  </Text>
                  <Divider />
                </VStack>
                <VStack align={'start'} maxW={300} mt={6} spacing={5}>
                  <CsbFormControl
                    errorText={
                      findError(errors, 'sendLearningMailDays')?.message
                    }
                    labelText={'メールの送信日'}
                  >
                    <InputLoading isLoading={loading}>
                      <Controller
                        control={form.control}
                        name={'sendLearningMailDays'}
                        render={({ field }) => (
                          <Select {...field}>
                            {MAIL_DAYS.map((item, index) => {
                              if (item === -1) {
                                return (
                                  <option
                                    key={`mail_day_${item}_${index}`}
                                    value={item}
                                  >
                                    種明かしメールを送信しない
                                  </option>
                                )
                              } else if (item === 0) {
                                return (
                                  <option
                                    key={`mail_day_${item}_${index}`}
                                    value={item}
                                  >
                                    当日
                                  </option>
                                )
                              } else {
                                return (
                                  <option
                                    key={`mail_day_${item}_${index}`}
                                    value={item}
                                  >
                                    {item}日後
                                  </option>
                                )
                              }
                            })}
                          </Select>
                        )}
                      />
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
                <VStack align={'start'} maxW={300} mt={10} spacing={5}>
                  <CsbFormControl
                    errorText={findError(errors, 'learn')?.message}
                    labelText={'メールの送信時間'}
                  >
                    <InputLoading isLoading={loading}>
                      <Controller
                        control={form.control}
                        name={'sendLearningMailAt'}
                        render={({ field }) => (
                          <Input type={'time'} {...field} />
                        )}
                      />
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
                <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                  <CsbFormControl
                    errorText={
                      findError(errors, 'revealingPhishingEmailSubject')
                        ?.message
                    }
                    labelText={'メールの件名'}
                  >
                    <InputLoading isLoading={loading}>
                      <Controller
                        control={form.control}
                        name={'revealingPhishingEmailSubject'}
                        render={({ field }) => (
                          <Input
                            w={'full'}
                            {...field}
                            value={field.value || ''}
                          />
                        )}
                      />
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
                <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                  <CsbFormControl
                    errorText={
                      findError(errors, 'revealingPhishingEmailHtml')?.message
                    }
                    labelText={'メールの本文'}
                  >
                    <InputLoading
                      isLoading={loading}
                      loadingFallbackProps={{ h: '238px' }}
                    >
                      <Controller
                        control={form.control}
                        name={'revealingPhishingEmailHtml'}
                        render={({ field: { onChange, value } }) => (
                          <CsbEditor
                            data={value ?? ''}
                            isHtmlLocked={
                              form.getValues(
                                'isRevealingPhishingEmailHtmlLock'
                              ) ?? false
                            }
                            onChangeHtml={onChange}
                            onHtmlLocked={(locked) =>
                              form.setValue(
                                'isRevealingPhishingEmailHtmlLock',
                                locked
                              )
                            }
                          />
                        )}
                      />
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
              </Box>
            </VStack>

            <VStack align={'start'} mt={20}>
              <Box w={'full'}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>リスクユーザーへのメール通知</Heading>
                  <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                    フィッシングに引っかかったユーザーを対象にメールを送信します
                  </Text>
                  <Divider />
                </VStack>
                <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                  <CsbFormControl
                    errorText={
                      findError(errors, 'riskUserEmailSubject')?.message
                    }
                    labelText={'メールの件名'}
                  >
                    <InputLoading isLoading={loading}>
                      <Controller
                        control={form.control}
                        name={'riskUserEmailSubject'}
                        render={({ field }) => (
                          <Input
                            w={'full'}
                            {...field}
                            value={field.value || ''}
                          />
                        )}
                      />
                    </InputLoading>
                  </CsbFormControl>
                </VStack>

                <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                  <CsbFormControl
                    errorText={findError(errors, 'riskUserEmailHtml')?.message}
                    labelText={'メールの本文'}
                  >
                    <InputLoading
                      isLoading={loading}
                      loadingFallbackProps={{ h: '238px' }}
                    >
                      <Controller
                        control={form.control}
                        name={'riskUserEmailHtml'}
                        render={({ field: { onChange, value } }) => (
                          <CsbEditor
                            data={value ?? ''}
                            isHtmlLocked={
                              form.getValues('isRiskUserEmailHtmlLock') ?? false
                            }
                            onChangeHtml={onChange}
                            onHtmlLocked={(locked) =>
                              form.setValue('isRiskUserEmailHtmlLock', locked)
                            }
                          />
                        )}
                      />
                    </InputLoading>
                  </CsbFormControl>
                </VStack>

                <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                  <CsbFormControl
                    errorText={findError(errors, 'isSendLearningMail')?.message}
                  >
                    <Controller
                      control={form.control}
                      name={'isSendLearningMail'}
                      render={({ field }) => (
                        <Checkbox
                          defaultChecked
                          isChecked={
                            form.getValues('isSendLearningMail') as boolean
                          }
                          ref={field.ref}
                          onChange={field.onChange}
                        >
                          リスクユーザーへメールを配信をする
                        </Checkbox>
                      )}
                    />
                  </CsbFormControl>
                </VStack>
              </Box>
            </VStack>

            <VStack align={'start'} mt={20}>
              <Box w={'full'}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>リマインド（繰り返し）メール</Heading>
                  <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                    リマインド（繰り返し）メールを送信します
                  </Text>
                  <Divider />
                </VStack>
                <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                  <CsbFormControl
                    errorText={findError(errors, 'remindEmailSubject')?.message}
                    labelText={'メールの件名'}
                  >
                    <InputLoading isLoading={loading}>
                      <Controller
                        control={form.control}
                        name={'remindEmailSubject'}
                        render={({ field }) => (
                          <Input
                            w={'full'}
                            {...field}
                            value={field.value || ''}
                          />
                        )}
                      />
                    </InputLoading>
                  </CsbFormControl>
                </VStack>

                <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                  <CsbFormControl
                    errorText={findError(errors, 'remindEmailHtml')?.message}
                    labelText={'メールの本文'}
                  >
                    <InputLoading
                      isLoading={loading}
                      loadingFallbackProps={{ h: '238px' }}
                    >
                      <Controller
                        control={form.control}
                        name={'remindEmailHtml'}
                        render={({ field: { onChange, value } }) => (
                          <CsbEditor
                            data={value ?? ''}
                            onChangeHtml={onChange}
                          />
                        )}
                      />
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
              </Box>
            </VStack>

            <VStack align={'start'} mt={20}>
              <Box w={'full'}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>種明かし画面</Heading>
                  <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                    種明かし画面で表示するテキストを設定できます
                  </Text>
                  <Divider />
                </VStack>
                <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                  <CsbFormControl
                    errorText={
                      findError(errors, 'phishingResultLayoutTemplate')?.message
                    }
                    labelText={'レイアウトテンプレート'}
                  >
                    <Select {...form.register('phishingResultLayoutTemplate')}>
                      <option value={PhishingResultLayoutTemplate.Default}>
                        デフォルト
                      </option>
                      <option value={PhishingResultLayoutTemplate.Custom}>
                        カスタム
                      </option>
                    </Select>
                  </CsbFormControl>
                </VStack>
                <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                  <CsbFormControl
                    errorText={
                      findError(errors, 'phishingResultTitle')?.message
                    }
                    helperText={
                      isDefaultPhishingResultLayout
                        ? undefined
                        : 'タイトルタグとして利用されます'
                    }
                    labelText={'ページタイトル'}
                  >
                    <InputLoading
                      isLoading={loading}
                      loadingFallbackProps={{ h: '238px' }}
                    >
                      <Controller
                        control={form.control}
                        name={'phishingResultTitle'}
                        render={({ field }) => (
                          <Textarea
                            resize={'vertical'}
                            rows={isDefaultPhishingResultLayout ? 10 : 1}
                            w="100%"
                            {...field}
                            value={field.value || ''}
                          />
                        )}
                      />
                    </InputLoading>
                  </CsbFormControl>
                </VStack>

                <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                  <CsbFormControl
                    errorText={findError(errors, 'phishingResultBody')?.message}
                    labelText={'ページ本文'}
                    maxW={800}
                    w={'full'}
                  >
                    <InputLoading
                      isLoading={loading}
                      loadingFallbackProps={{ h: '238px' }}
                    >
                      {loading &&
                      form.getValues('phishingResultBody') ? null : (
                        <Controller
                          control={form.control}
                          name={'phishingResultBody'}
                          render={({ field: { onChange, value } }) => (
                            <CsbEditor
                              data={value ?? ''}
                              isHtmlLocked={
                                form.getValues('isPhishingResultBodyLock') ??
                                false
                              }
                              onChangeHtml={onChange}
                              onHtmlLocked={(locked) =>
                                form.setValue(
                                  'isPhishingResultBodyLock',
                                  locked
                                )
                              }
                            />
                          )}
                        />
                      )}
                    </InputLoading>
                  </CsbFormControl>
                </VStack>

                {isDefaultPhishingResultLayout && (
                  <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                    <CsbFormControl
                      errorText={
                        findError(errors, 'phishingResultSign')?.message
                      }
                      labelText={'署名'}
                    >
                      <InputLoading
                        isLoading={loading}
                        loadingFallbackProps={{ h: '238px' }}
                      >
                        {loading &&
                        form.getValues('phishingResultSign') ? null : (
                          <Controller
                            control={form.control}
                            name={'phishingResultSign'}
                            render={({ field: { onChange, value } }) => (
                              <CsbEditor
                                data={value ?? ''}
                                isHtmlLocked={
                                  form.getValues('isPhishingResultSignLock') ??
                                  false
                                }
                                onChangeHtml={onChange}
                                onHtmlLocked={(locked) =>
                                  form.setValue(
                                    'isPhishingResultSignLock',
                                    locked
                                  )
                                }
                              />
                            )}
                          />
                        )}
                      </InputLoading>
                    </CsbFormControl>
                  </VStack>
                )}

                {isDefaultPhishingResultLayout && (
                  <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                    <CsbFormControl
                      errorText={findError(errors, 'linkText')?.message}
                      labelText={'学習コンテンツボタンテキスト'}
                    >
                      <InputLoading isLoading={loading}>
                        <Controller
                          control={form.control}
                          name={'linkText'}
                          render={({ field }) => (
                            <Input
                              w={'full'}
                              {...field}
                              value={field.value || ''}
                            />
                          )}
                        />
                      </InputLoading>
                    </CsbFormControl>
                  </VStack>
                )}

                {isDefaultPhishingResultLayout && (
                  <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                    <CsbFormControl
                      errorText={findError(errors, 'logoPath')?.message}
                      labelText={'種明かし画面ロゴ'}
                    >
                      <DropDropUploader
                        crop={false}
                        savePath={'settings/logo'}
                        url={form.getValues('logoPath') ?? ''}
                        onChange={(url) => {
                          form.setValue('logoPath', url)
                        }}
                      />
                      <Text color={'gray.600'} fontSize={'sm'}>
                        推奨: 512px x 288px
                      </Text>
                    </CsbFormControl>

                    <CsbFormControl>
                      <Controller
                        control={form.control}
                        name={'useLogo'}
                        render={({ field }) => (
                          <Checkbox
                            defaultChecked
                            isChecked={form.getValues('useLogo') as boolean}
                            ref={field.ref}
                            onChange={field.onChange}
                          >
                            アップロードしたロゴを使用する
                          </Checkbox>
                        )}
                      />
                    </CsbFormControl>
                  </VStack>
                )}
              </Box>
            </VStack>
            <VStack align={'start'} mt={20}>
              <Box w={'full'}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>メールフォーマット</Heading>
                  <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                    メール文章のフォーマットを指定します
                  </Text>
                  <Divider />
                </VStack>
                <VStack align={'start'} maxW={300} mt={6} spacing={5}>
                  <CsbFormControl
                    errorText={findError(errors, 'sendEmailPattern')?.message}
                    labelText={'フォーマット'}
                  >
                    <InputLoading isLoading={loading}>
                      <Select
                        defaultValue={form.getValues('sendEmailPattern') || 1}
                        {...form.register('sendEmailPattern')}
                      >
                        <option value={1}>メールをHTML形式で送信する</option>
                        <option value={2}>
                          メールをテキスト形式で送信する
                        </option>
                      </Select>
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
              </Box>
            </VStack>

            <VStack align={'start'} mt={20}>
              <Box w={'full'}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>添付ファイル設定</Heading>
                  <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                    添付ファイルのデフォルト設定を行います
                  </Text>
                  <Divider />
                </VStack>
                <VStack align={'start'} maxW={496} mt={6} spacing={5}>
                  <CsbFormControl
                    errorText={findError(errors, 'isArchiveAttach')?.message}
                    labelText={'添付ファイル・圧縮（ZIP）'}
                  >
                    <InputLoading isLoading={loading}>
                      <Controller
                        control={form.control}
                        name={'isArchiveAttach'}
                        render={({ field: { onChange, value } }) => (
                          <HStack fontSize={'sm'} mt={1}>
                            <Text>圧縮しない</Text>
                            <Switch
                              isChecked={value ?? false}
                              onChange={(e) => {
                                onChange(e)
                                onChangeIsArchiveAttach(e)
                              }}
                            />
                            <Text>圧縮する</Text>
                          </HStack>
                        )}
                      />
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
                {form.watch('isArchiveAttach') && (
                  <>
                    <VStack align={'start'} maxW={496} mt={6} spacing={5}>
                      <CsbFormControl
                        errorText={
                          findError(errors, 'isArchiveAttachPassword')?.message
                        }
                        labelText={'添付ファイル・パスワード設定'}
                      >
                        <InputLoading isLoading={loading}>
                          <Controller
                            control={form.control}
                            name={'isArchiveAttachPassword'}
                            render={({ field: { onChange, value } }) => (
                              <HStack fontSize={'sm'} mt={1}>
                                <Text>設定しない</Text>
                                <Switch
                                  isChecked={value ?? true}
                                  onChange={(e) => {
                                    onChange(e)
                                    onChangeIsArchiveAttachPassword(e)
                                  }}
                                />
                                <Text>設定する</Text>
                              </HStack>
                            )}
                          />
                        </InputLoading>
                      </CsbFormControl>
                    </VStack>
                    {form.watch('isArchiveAttachPassword') && (
                      <>
                        <VStack align={'start'} maxW={496} mt={6} spacing={5}>
                          <CsbFormControl
                            errorText={
                              findError(errors, 'archiveAttachPassword')
                                ?.message
                            }
                            labelText={'添付ファイル・解凍パスワード'}
                          >
                            <InputLoading isLoading={loading}>
                              <Input
                                {...form.register('archiveAttachPassword')}
                              />
                            </InputLoading>
                          </CsbFormControl>
                        </VStack>
                        <VStack align={'start'} maxW={496} mt={6} spacing={5}>
                          <CsbFormControl
                            errorText={
                              findError(errors, 'isCustomNotifyPassword')
                                ?.message
                            }
                            labelText={'添付ファイル・解凍パスワード通知'}
                          >
                            <InputLoading isLoading={loading}>
                              <Controller
                                control={form.control}
                                name={'isCustomNotifyPassword'}
                                render={({ field: { onChange, value } }) => (
                                  <HStack fontSize={'sm'} mt={1}>
                                    <Text>
                                      設定しない（訓練メール内に記載）
                                    </Text>
                                    <Switch
                                      isChecked={value ?? true}
                                      onChange={(e) => {
                                        onChange(e)
                                        onChangeIsCustomNotifyPassword(e)
                                      }}
                                    />
                                    <Text>設定する</Text>
                                  </HStack>
                                )}
                              />
                            </InputLoading>
                          </CsbFormControl>
                        </VStack>
                        {form.watch('isCustomNotifyPassword') && (
                          <>
                            <VStack
                              align={'start'}
                              maxW={496}
                              mt={6}
                              spacing={5}
                            >
                              <CsbFormControl
                                errorText={
                                  findError(
                                    errors,
                                    'notifyArchivePasswordSubject'
                                  )?.message
                                }
                                labelText={
                                  '添付ファイル・解凍パスワード通知メールの件名'
                                }
                              >
                                <InputLoading isLoading={loading}>
                                  <Controller
                                    control={form.control}
                                    name={'notifyArchivePasswordSubject'}
                                    render={({ field }) => (
                                      <Input
                                        w={'full'}
                                        {...field}
                                        value={field.value || ''}
                                      />
                                    )}
                                  />
                                </InputLoading>
                              </CsbFormControl>
                            </VStack>
                            <VStack
                              align={'start'}
                              maxW={496}
                              mt={6}
                              spacing={5}
                            >
                              <CsbFormControl
                                errorText={
                                  findError(errors, 'notifyArchivePasswordHtml')
                                    ?.message
                                }
                                labelText={
                                  <HStack>
                                    <Text>
                                      {
                                        '添付ファイル・解凍パスワード通知メールの文章'
                                      }
                                    </Text>
                                    <chakra.button
                                      type={'button'}
                                      onClick={mailPlaceholderModalOnOpen}
                                    >
                                      <QuestionOutlineIcon color={'teal.500'} />
                                    </chakra.button>
                                  </HStack>
                                }
                              >
                                <InputLoading isLoading={loading}>
                                  <Controller
                                    control={form.control}
                                    name={'notifyArchivePasswordHtml'}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <CsbEditor
                                        data={value ?? ''}
                                        isHtmlLocked={
                                          form.getValues(
                                            'isNotifyArchivePasswordHtmlLock'
                                          ) ?? false
                                        }
                                        onChangeHtml={onChange}
                                        onHtmlLocked={(locked) =>
                                          form.setValue(
                                            'isNotifyArchivePasswordHtmlLock',
                                            locked
                                          )
                                        }
                                      />
                                    )}
                                  />
                                </InputLoading>
                              </CsbFormControl>
                            </VStack>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>
            </VStack>

            <VStack align={'start'} mt={20}>
              <Box w={'full'}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>フィッシングサイト経由設定</Heading>
                  <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                    フィッシングサイトを経由せずにメール本文のリンククリックでリスクのある行動と判定します
                  </Text>
                  <Divider />
                </VStack>
                <VStack align={'start'} maxW={496} mt={6} spacing={5}>
                  <CsbFormControl
                    errorText={
                      findError(errors, 'isPhishingSiteDirectAccess')?.message
                    }
                  >
                    <Controller
                      control={form.control}
                      name={'isPhishingSiteDirectAccess'}
                      render={({ field }) => (
                        <Checkbox
                          defaultChecked
                          isChecked={
                            form.getValues(
                              'isPhishingSiteDirectAccess'
                            ) as boolean
                          }
                          ref={field.ref}
                          onChange={field.onChange}
                        >
                          フィッシングサイトを経由しない
                        </Checkbox>
                      )}
                    />
                  </CsbFormControl>
                </VStack>
              </Box>
            </VStack>

            <VStack align={'start'} mt={20}>
              <Box w={'full'}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>メール画像組込み設定</Heading>
                  <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                    訓練メール本文中内の画像を添付ファイルにする設定を行います
                  </Text>
                  <Divider />
                </VStack>
                <VStack align={'start'} maxW={496} mt={6} spacing={5}>
                  <CsbFormControl
                    errorText={findError(errors, 'embedImages')?.message}
                  >
                    <Controller
                      control={form.control}
                      name={'embedImages'}
                      render={({ field }) => (
                        <Checkbox
                          defaultChecked
                          isChecked={form.getValues('embedImages') as boolean}
                          ref={field.ref}
                          onChange={field.onChange}
                        >
                          メール本文中の画像を添付ファイルにする
                        </Checkbox>
                      )}
                    />
                  </CsbFormControl>
                </VStack>
              </Box>
            </VStack>

            <VStack align={'start'} mt={20}>
              <Box w={'full'}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>メール送信間隔</Heading>
                  <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                    メール送信間隔を設定できます
                  </Text>
                  <Divider />
                </VStack>
                <VStack align={'start'} maxW={496} mt={6} spacing={5}>
                  <CsbFormControl
                    errorText={findError(errors, 'sendMailInterval')?.message}
                    labelText={'メール送信間隔（秒）'}
                  >
                    <InputLoading
                      isLoading={loading}
                      loadingFallbackProps={{ h: '238px' }}
                    >
                      <Flex align={'center'}>
                        <Controller
                          control={form.control}
                          name={'sendMailInterval'}
                          render={({ field: { ref, value, ...restField } }) => {
                            const rest = {
                              ...restField,
                              ...{ value: Number(value ?? 1.0) },
                            }
                            return (
                              <NumberInput
                                max={15.0}
                                maxW={100}
                                min={0.1}
                                step={0.1}
                                {...rest}
                                onChange={(value) => {
                                  form.setValue(
                                    'sendMailInterval',
                                    Number(value)
                                  )
                                }}
                              >
                                <NumberInputField
                                  name={rest.name}
                                  ref={ref}
                                  textAlign={'right'}
                                />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            )
                          }}
                        />
                        <Text ml={2}>秒</Text>
                      </Flex>
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
              </Box>
            </VStack>

            <VStack align={'start'} mt={20}>
              <Box w={'full'}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>メール開封検知設定</Heading>
                  <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                    メール開封検知を設定できます
                  </Text>
                  <Divider />
                </VStack>
                <VStack align={'start'} maxW={496} mt={6} spacing={5}>
                  <CsbFormControl
                    errorText={findError(errors, 'isTrackMailOpen')?.message}
                    labelText={'メール開封検知'}
                  >
                    <InputLoading
                      isLoading={loading}
                      loadingFallbackProps={{ h: '238px' }}
                    >
                      <Controller
                        control={form.control}
                        name={'isTrackMailOpen'}
                        render={({ field: { onChange, value } }) => (
                          <HStack fontSize={'sm'}>
                            <Text>開封を検知しない</Text>
                            <Switch
                              isChecked={value ?? true}
                              onChange={onChange}
                            />
                            <Text>開封を検知する</Text>
                          </HStack>
                        )}
                      />
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
              </Box>
            </VStack>

            <VStack align={'start'} mt={20}>
              <Box w={'full'}>
                <VStack align={'start'} w={'full'}>
                  <Heading fontSize={24}>学習コンテンツ設定</Heading>
                  <Divider />
                </VStack>

                <VStack align={'start'} maxW={496} mt={6} spacing={5}>
                  <CsbFormControl
                    errorText={
                      findError(errors, 'learningContentComment')?.message
                    }
                    labelText={'学習コンテンツ・コメント'}
                  >
                    {loading &&
                    form.getValues('learningContentComment') ? null : (
                      <Controller
                        control={form.control}
                        name={'learningContentComment'}
                        render={({ field: { onChange, value } }) => (
                          <CsbEditor
                            data={value ?? ''}
                            isHtmlLocked={
                              form.getValues('isLearningContentCommentLock') ??
                              false
                            }
                            onChangeHtml={onChange}
                            onHtmlLocked={(locked) =>
                              form.setValue(
                                'isLearningContentCommentLock',
                                locked
                              )
                            }
                          />
                        )}
                      />
                    )}
                  </CsbFormControl>
                </VStack>

                <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                  <CsbFormControl
                    errorText={findError(errors, 'learningComment')?.message}
                    labelText={'学習・コメント'}
                  >
                    {loading && form.getValues('learningComment') ? null : (
                      <Controller
                        control={form.control}
                        name={'learningComment'}
                        render={({ field: { onChange, value } }) => (
                          <CsbEditor
                            data={value ?? ''}
                            isHtmlLocked={
                              form.getValues('isLearningCommentLock') ?? false
                            }
                            onChangeHtml={onChange}
                            onHtmlLocked={(locked) =>
                              form.setValue('isLearningCommentLock', locked)
                            }
                          />
                        )}
                      />
                    )}
                  </CsbFormControl>
                </VStack>
              </Box>
            </VStack>

            <VStack align={'start'} mt={20}>
              <Box w={'full'}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>添付ファイルプロキシ設定</Heading>
                  <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                    添付ファイルで参照する、訓練ページや開封判定に利用するAPIのプロキシを設定します。
                  </Text>
                  <Divider />
                </VStack>
                <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                  <CsbFormControl
                    errorText={findError(errors, 'proxyApiHost')?.message}
                    labelText={'APIホスト'}
                  >
                    <InputLoading isLoading={loading}>
                      <Controller
                        control={form.control}
                        name={'proxyApiHost'}
                        render={({ field }) => (
                          <Input
                            placeholder={process.env.REACT_APP_BACKEND_URL}
                            w={'full'}
                            {...field}
                            value={field.value || ''}
                          />
                        )}
                      />
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
                <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                  <CsbFormControl
                    errorText={findError(errors, 'proxyPhishingHost')?.message}
                    labelText={'訓練ページホスト'}
                  >
                    <InputLoading isLoading={loading}>
                      <Controller
                        control={form.control}
                        name={'proxyPhishingHost'}
                        render={({ field }) => (
                          <Input
                            placeholder={process.env.REACT_APP_PHISHING_URL}
                            w={'full'}
                            {...field}
                            value={field.value || ''}
                          />
                        )}
                      />
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
              </Box>
            </VStack>
            <VStack align={'start'} mt={20}>
              <Box w={'full'}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>ロゴ表示設定</Heading>
                  <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                    ロゴ表示のON,OFFを設定できます
                  </Text>
                  <Divider />
                </VStack>
                <VStack align={'start'} maxW={496} mt={6} spacing={5}>
                  <CsbFormControl
                    errorText={
                      findError(errors, 'isDisplayLogoInLearning')?.message
                    }
                    labelText={'学習ページ'}
                  >
                    <InputLoading
                      isLoading={loading}
                      loadingFallbackProps={{ h: '238px' }}
                    >
                      <Controller
                        control={form.control}
                        name={'isDisplayLogoInLearning'}
                        render={({ field: { onChange, value } }) => (
                          <HStack fontSize={'sm'}>
                            <Text>表示しない</Text>
                            <Switch
                              isChecked={value ?? true}
                              onChange={onChange}
                            />
                            <Text>表示する</Text>
                          </HStack>
                        )}
                      />
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
                <VStack align={'start'} maxW={496} mt={10} spacing={5}>
                  <CsbFormControl
                    errorText={
                      findError(errors, 'isDisplayLogoInResearch')?.message
                    }
                    labelText={'アンケート回答画面'}
                  >
                    <InputLoading
                      isLoading={loading}
                      loadingFallbackProps={{ h: '238px' }}
                    >
                      <Controller
                        control={form.control}
                        name={'isDisplayLogoInResearch'}
                        render={({ field: { onChange, value } }) => (
                          <HStack fontSize={'sm'}>
                            <Text>表示しない</Text>
                            <Switch
                              isChecked={value ?? true}
                              onChange={onChange}
                            />
                            <Text>表示する</Text>
                          </HStack>
                        )}
                      />
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
              </Box>
            </VStack>
            <VStack align={'start'} mt={20}>
              <Box w={'full'}>
                <VStack align={'start'}>
                  <Heading fontSize={24}>メール返信ユーザーリスク設定</Heading>
                  <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                    メールに返信したユーザーをリスクユーザーとして設定できます。
                  </Text>
                  <Divider />
                </VStack>
                <VStack align={'start'} maxW={496} mt={6} spacing={5}>
                  <CsbFormControl
                    errorText={findError(errors, 'isRiskReplyUser')?.message}
                    labelText={'メール返信ユーザー'}
                  >
                    <InputLoading
                      isLoading={loading}
                      loadingFallbackProps={{ h: '238px' }}
                    >
                      <Controller
                        control={form.control}
                        name={'isRiskReplyUser'}
                        render={({ field: { onChange, value } }) => (
                          <HStack fontSize={'sm'}>
                            <Text>リスクユーザーにしない</Text>
                            <Switch
                              isChecked={value ?? true}
                              onChange={onChange}
                            />
                            <Text>リスクユーザーにする</Text>
                          </HStack>
                        )}
                      />
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
              </Box>
            </VStack>

            <VStack align={'start'} mt={20}>
              <Box w={'full'}>
                <Heading fontSize={24}>アクセス判定除外設定</Heading>
                <Divider />
              </Box>
              <Box w={'full'}>
                <VStack align={'start'} mt={6}>
                  <Heading fontSize={18}>指定回数除外</Heading>
                  <Text color={'gray.400'} fontSize={'sm'} lineHeight={1}>
                    アクセス判定を除外する回数を設定できます
                  </Text>
                </VStack>
                <VStack align={'start'} maxW={496} mt={4} spacing={5}>
                  <CsbFormControl
                    errorText={
                      findError(errors, 'mailOpenedAllowCount')?.message
                    }
                    labelText={'メール開封'}
                  >
                    <InputLoading
                      isLoading={loading}
                      loadingFallbackProps={{ h: '238px' }}
                    >
                      <Flex align={'center'}>
                        <Controller
                          control={form.control}
                          name={'mailOpenedAllowCount'}
                          render={({ field: { ref, value, ...restField } }) => {
                            const rest = {
                              ...restField,
                              ...{ value: Number(value ?? 0) },
                            }
                            return (
                              <NumberInput
                                max={999}
                                maxW={100}
                                min={0}
                                {...rest}
                                onChange={(value) => {
                                  form.setValue(
                                    'mailOpenedAllowCount',
                                    Number(value)
                                  )
                                }}
                              >
                                <NumberInputField
                                  name={rest.name}
                                  ref={ref}
                                  textAlign={'right'}
                                />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            )
                          }}
                        />
                        <Text ml={2}>回</Text>
                      </Flex>
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
                <VStack align={'start'} maxW={496} mt={6} spacing={5}>
                  <CsbFormControl
                    errorText={
                      findError(errors, 'webAccessedAllowCount')?.message
                    }
                    labelText={'Webアクセス'}
                  >
                    <InputLoading
                      isLoading={loading}
                      loadingFallbackProps={{ h: '238px' }}
                    >
                      <Flex align={'center'}>
                        <Controller
                          control={form.control}
                          name={'webAccessedAllowCount'}
                          render={({ field: { ref, value, ...restField } }) => {
                            const rest = {
                              ...restField,
                              ...{ value: Number(value ?? 0) },
                            }
                            return (
                              <NumberInput
                                max={999}
                                maxW={100}
                                min={0}
                                {...rest}
                                onChange={(value) => {
                                  form.setValue(
                                    'webAccessedAllowCount',
                                    Number(value)
                                  )
                                }}
                              >
                                <NumberInputField
                                  name={rest.name}
                                  ref={ref}
                                  textAlign={'right'}
                                />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            )
                          }}
                        />
                        <Text ml={2}>回</Text>
                      </Flex>
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
                <VStack align={'start'} maxW={496} mt={6} spacing={5}>
                  <CsbFormControl
                    errorText={findError(errors, 'webLoginAllowCount')?.message}
                    labelText={'ログイン'}
                  >
                    <InputLoading
                      isLoading={loading}
                      loadingFallbackProps={{ h: '238px' }}
                    >
                      <Flex align={'center'}>
                        <Controller
                          control={form.control}
                          name={'webLoginAllowCount'}
                          render={({ field: { ref, value, ...restField } }) => {
                            const rest = {
                              ...restField,
                              ...{ value: Number(value ?? 0) },
                            }
                            return (
                              <NumberInput
                                max={999}
                                maxW={100}
                                min={0}
                                {...rest}
                                onChange={(value) => {
                                  form.setValue(
                                    'webLoginAllowCount',
                                    Number(value)
                                  )
                                }}
                              >
                                <NumberInputField
                                  name={rest.name}
                                  ref={ref}
                                  textAlign={'right'}
                                />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            )
                          }}
                        />
                        <Text ml={2}>回</Text>
                      </Flex>
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
                <VStack align={'start'} maxW={496} mt={6} spacing={5}>
                  <CsbFormControl
                    errorText={
                      findError(errors, 'learningAccessedAllowCount')?.message
                    }
                    labelText={'学習ページ'}
                  >
                    <InputLoading
                      isLoading={loading}
                      loadingFallbackProps={{ h: '238px' }}
                    >
                      <Flex align={'center'}>
                        <Controller
                          control={form.control}
                          name={'learningAccessedAllowCount'}
                          render={({ field: { ref, value, ...restField } }) => {
                            const rest = {
                              ...restField,
                              ...{ value: Number(value ?? 0) },
                            }
                            return (
                              <NumberInput
                                max={999}
                                maxW={100}
                                min={0}
                                {...rest}
                                onChange={(value) => {
                                  form.setValue(
                                    'learningAccessedAllowCount',
                                    Number(value)
                                  )
                                }}
                              >
                                <NumberInputField
                                  name={rest.name}
                                  ref={ref}
                                  textAlign={'right'}
                                />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            )
                          }}
                        />
                        <Text ml={2}>回</Text>
                      </Flex>
                    </InputLoading>
                  </CsbFormControl>
                </VStack>
              </Box>

              {isWriteIpFilter && (
                <Box w={'full'}>
                  <VStack align={'start'} mb={2} mt={6}>
                    <Heading fontSize={18}>IPアドレス・UserAgent除外</Heading>
                    <Text
                      color={'gray.400'}
                      fontSize={'sm'}
                      lineHeight={1}
                    ></Text>
                  </VStack>
                  <VStack align={'start'} maxW={780} spacing={5}>
                    {ipFilters.map((_, index) => (
                      <>
                        <HStack key={index} spacing={'20px'}>
                          <CsbFormControl
                            labelText={index === 0 ? 'IPアドレス' : ''}
                          >
                            <Input
                              w={350}
                              {...form.register(`ipFilters.${index}.ipAddress`)}
                            />
                          </CsbFormControl>
                          <CsbFormControl
                            labelText={index === 0 ? 'UserAgent' : ''}
                          >
                            <Input
                              w={350}
                              {...form.register(`ipFilters.${index}.userAgent`)}
                            />
                          </CsbFormControl>
                          {index > 0 && (
                            <IconButton
                              aria-label="delete-ip-filter"
                              colorScheme={'red'}
                              icon={<DeleteIcon />}
                              size={'sm'}
                              onClick={() => {
                                onDeleteIpFilter(index)
                              }}
                            />
                          )}
                        </HStack>
                        {findError(errors, `ipFilters.${index}`) && (
                          <CsbFormControl
                            errorText={
                              findError(errors, `ipFilters.${index}`)?.message
                            }
                            fontSize={'md'}
                          />
                        )}
                      </>
                    ))}
                    <Flex mt={10} w={'full'}>
                      <HStack align={'right'} ml={'auto'} spacing={3}>
                        <Button onClick={onAddIpFilter}>追加</Button>
                      </HStack>
                    </Flex>
                  </VStack>
                </Box>
              )}
            </VStack>
          </CsbPrimaryBox>
        </Box>
        <Flex mt={5}>
          <HStack ml={'auto'} spacing={3}>
            {isWriteConfig && (
              <HStack spacing={6}>
                <Button isLoading={loading} onClick={onSubmit}>
                  保存
                </Button>
              </HStack>
            )}
          </HStack>
        </Flex>
        <MailPlaceholderModal />
      </Base>
    )
  }
)
