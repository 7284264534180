import {
  Flex,
  ListItem,
  Progress,
  Skeleton,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { CsbNewLineText } from '@src/components/CsbNewLineText'
import { usePhishingResultNewContentHooksResult } from '@src/components/pages/phishing/[id]/result/PhishingResultNewContent'
import {
  PhishingResultCorrectAnswerRate,
  usePhishingResultCorrectAnswerRate,
} from '@src/models/Phishing'
import { useEffect } from 'react'

export const LearningCorrectAnswerRate = ({
  id,
  usePhishingResultContentsSelectResult,
}: usePhishingResultNewContentHooksResult) => {
  const usePhishingResultCorrectAnswerRateResult =
    usePhishingResultCorrectAnswerRate({
      variables: {
        uuid: id ?? '',
      },
    })

  const { refetch } = usePhishingResultCorrectAnswerRateResult
  useEffect(() => {
    refetch().then()
  }, [refetch, usePhishingResultContentsSelectResult.form.formState])

  return (
    <>
      <Text fontSize={13} mb={21}>
        ラーニング正答率
      </Text>
      <UnorderedList
        display={'grid'}
        gridGap={34}
        gridTemplateColumns={'1fr'}
        listStyleType={'none'}
        mb={'80px'}
        ml={0}
      >
        <PhishingResultCorrectAnswerRate
          {...usePhishingResultCorrectAnswerRateResult}
          loadingFallback={
            <>
              <Skeleton h={56.5} />
              <Skeleton h={56.5} />
              <Skeleton h={56.5} />
            </>
          }
        >
          {({ phishingResultCorrectAnswerRate }) =>
            phishingResultCorrectAnswerRate.map(
              (phishingResultCorrectAnswer, index) => (
                <ListItem key={phishingResultCorrectAnswer.question.uuid}>
                  <Text>
                    <CsbNewLineText
                      text={`Q${index + 1} ${
                        phishingResultCorrectAnswer.question.name
                      }`}
                    />
                  </Text>
                  <Flex align={'center'} mt={13}>
                    <Progress
                      colorScheme={
                        phishingResultCorrectAnswer.rate <= 10
                          ? 'red'
                          : undefined
                      }
                      mr={30}
                      value={phishingResultCorrectAnswer.rate}
                      w={400}
                    />
                    <Text fontSize={13}>
                      {phishingResultCorrectAnswer.rate}%
                    </Text>
                  </Flex>
                </ListItem>
              )
            )
          }
        </PhishingResultCorrectAnswerRate>
      </UnorderedList>
    </>
  )
}
