import { useAttachesQuery } from '@src/graphql/generated/graphql'

export const useAttaches = (
  baseOptions: Partial<Parameters<typeof useAttachesQuery>[0]> = {}
) => {
  return useAttachesQuery(baseOptions)
}

type UseAttaches = typeof useAttaches
export type UseAttachesResult = ReturnType<UseAttaches>
