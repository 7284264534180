import { Text, TextProps } from '@chakra-ui/react'

type dataType = {
  currentCount: number
  currentPage: number
  limitValue: number
  totalCount: number
}

type CsbCounterPropsType<T> = {
  data: T
} & TextProps

export const CsbCounter = <T extends dataType>({
  data,
  ...props
}: CsbCounterPropsType<T>) => {
  return (
    <Text color={'gray'} fontSize={'sm'} fontWeight={'bold'} {...props}>
      全 {data.totalCount}件中 {1 + (data.currentPage - 1) * data.limitValue}
      件〜
      {data.currentCount + (data.currentPage - 1) * data.limitValue}件を表示
    </Text>
  )
}
