import { Button, HStack, Stack } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import { DocumentForm } from '@src/models/Document/components/DocumentForm/DocumentForm'
import { UseUpdateDocumentModalResult } from '@src/models/Document/components/UpdateDocumentModal/useUpdateDocumentModal'

export const UpdateDocumentModal = ({
  findErrorMessage,
  form,
  isLoading,
  isOpen,
  onClose,
  onUpdateDocument,
}: UseUpdateDocumentModalResult) => (
  <CsbModal isOpen={isOpen} onClose={onClose}>
    <CsbModalHeader>ドキュメントを編集する</CsbModalHeader>
    <CsbModalBody mt={10}>
      <Stack spacing={6}>
        <DocumentForm findErrorMessage={findErrorMessage} form={form} />
      </Stack>
    </CsbModalBody>
    <CsbModalFooter mt={7}>
      <HStack spacing={6}>
        <Button colorScheme={'gray'} onClick={onClose}>
          キャンセル
        </Button>
        <Button isLoading={isLoading} onClick={onUpdateDocument}>
          編集
        </Button>
      </HStack>
    </CsbModalFooter>
  </CsbModal>
)
