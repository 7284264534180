import { useDisclosure } from '@chakra-ui/react'
import { useCsbDestroyModalHooks } from '@src/components/Modal/CsbDestroyModal/hooks'
import { paginationInput } from '@src/constants'
import {
  useDestroyUserMutation,
  User,
  useUsersGroupsQuery,
  useUsersOrganizationsQuery,
  useUsersQuery,
} from '@src/graphql/generated/graphql'
import { useMe, usePagination } from '@src/hooks'
import { useCsbDataModal } from '@src/hooks/useCsbDataModal'
import { useCsvExportUser } from '@src/models/User/CsvExportUser/useCsvExportUser'
import { useDestroyUsers } from '@src/models/Users/useDestroyUsers'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

type SearchFormValues = {
  groupId?: string
  organizationId?: string
  searchWord?: string
}

// type selectType = {
//   positions: string
//   age: string
//   gender: string
// }

//ユーザー一覧で使うhooksのまとめ
export const useUsersHooks = () => {
  const navigate = useNavigate()
  const { company, role, user } = useMe()
  const isWriteUser = !!role?.writeUser
  const [destroyUserMutation] = useDestroyUserMutation()
  const dataModal = useCsbDataModal<{
    items: { name: string; uuid: string }[]
    title: string
  }>()
  const csbDestroyModalResult =
    useCsbDestroyModalHooks<
      NonNullable<
        ReturnType<typeof useUsersQuery>['data']
      >['users']['collection'][number]
    >()

  const [query, setQuery] = useQueryParams({
    groupId: withDefault(StringParam, ''),
    organizationId: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    searchWord: withDefault(StringParam, ''),
  })
  const { current, onChangePage } = usePagination()

  const { data, refetch } = useUsersQuery({
    variables: {
      groupId: query.groupId,
      organizationId: query.organizationId,
      pagination: paginationInput(current),
      searchWord: query.searchWord,
    },
  })
  const { data: organizations } = useUsersOrganizationsQuery()
  const { data: groups } = useUsersGroupsQuery()

  //すべての部署
  // const { data: positions } = useUsersPositionsQuery()
  //上記の値を管理するstate
  // const [select, setSelect] = useState<selectType>({
  //   positions: '',
  //   age: '',
  //   gender: '',
  // })

  //ユーザーテーブルでhoverされているユーザーのuuidを保存する
  const [hoverUser, setHoverUser] = useState<User['uuid'] | undefined>(
    undefined
  )

  const onMouseLeaveUser = () => {
    setHoverUser(undefined)
  }

  const onMouseOverUser = (uuid: User['uuid']) => {
    setHoverUser(uuid)
  }

  const useDestroyUsersResult = useDestroyUsers()

  //上記の値を更新する関数
  // const handleSelect =
  //   (type: keyof selectType) => (event: ChangeEvent<HTMLSelectElement>) => {
  //     const currentSelect = { ...select, [type]: event.target.value }
  //
  //     setSelect(currentSelect)
  //     // TODO ソート機能追加
  //   }

  const csvExportUserModal = useDisclosure()
  const { form, isExporting, onExportUsers } = useCsvExportUser({
    onCompleted: () => {
      csvExportUserModal.onClose()
    },
  })

  //search機能
  const searchForm = useForm<SearchFormValues>({
    defaultValues: {
      groupId: query.groupId,
      organizationId: query.organizationId,
      searchWord: query.searchWord,
    },
  })
  const onSearch = searchForm.handleSubmit(async (inputData) => {
    setQuery({
      groupId: inputData.groupId,
      organizationId: inputData.organizationId,
      page: 1,
      searchWord: inputData.searchWord,
    })
  })
  const onResetSearch = () => {
    searchForm.setValue('groupId', '')
    searchForm.setValue('organizationId', '')
    searchForm.setValue('searchWord', '')

    setQuery({
      groupId: undefined,
      organizationId: undefined,
      page: 1,
      searchWord: undefined,
    })
  }
  return {
    company,
    csbDestroyModalResult,
    csvExportUserModal,
    current,
    data,
    // positions,
    // handleSelect,
    dataModal,

    destroyUserMutation,

    form,

    groups,

    hoverUser,

    isExporting,

    isWriteUser,

    navigate,

    onChangePage,

    onExportUsers,
    onMouseLeaveUser,
    onMouseOverUser,
    onResetSearch,
    onSearch,
    organizations,
    refetch,
    searchForm,
    useDestroyUsersResult,
    user,
  }
}

export type useUsersHooksResult = ReturnType<typeof useUsersHooks>
