import { useDisclosure } from '@chakra-ui/react'
import { QuestionnairesQuery } from '@src/graphql/generated/graphql'
import { useUpdateQuestionnaire } from '@src/models/Questionnaire'
import { useCallback, useState } from 'react'

type Questionnaire = QuestionnairesQuery['questionnaires']['collection'][number]

export const useUpdateQuestionnaireModal = () => {
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>()
  const { isOpen, onClose: _onClose, onOpen: _onOpen } = useDisclosure()

  const {
    findErrorMessage,
    form,
    isLoading,
    isResearchSelectable,
    onUpdateQuestionnaire: _onUpdateQuestionnaire,
    researches,
    resetForm,
  } = useUpdateQuestionnaire({
    onCompleted: () => {
      _onClose()
    },
  })
  const onClose = useCallback(() => {
    resetForm()
    _onClose()
  }, [_onClose, resetForm])

  const onOpen = useCallback(
    (questionnaire: Questionnaire) => {
      _onOpen()
      setQuestionnaire(questionnaire)
      form.setValue('name', questionnaire.name)
      form.setValue('researchId', questionnaire.research.uuid)
    },
    [form, _onOpen, setQuestionnaire]
  )

  const onUpdateQuestionnaire = useCallback(() => {
    if (!questionnaire) return
    _onUpdateQuestionnaire(questionnaire.uuid)()
  }, [questionnaire, _onUpdateQuestionnaire])

  return {
    findErrorMessage,
    form,
    isLoading,
    isOpen,
    isResearchSelectable,
    onClose,
    onOpen,
    onUpdateQuestionnaire,
    researches,
  }
}

type useUpdateQuestionnaireModalType = typeof useUpdateQuestionnaireModal
export type useUpdateQuestionnaireModalResult =
  ReturnType<useUpdateQuestionnaireModalType>
// export type useUpdateResearchModalParameter = Parameters<useUpdateResearchModalType>[0]
