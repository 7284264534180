import { QuestionOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  chakra,
  Flex,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Text,
  useUpdateEffect,
} from '@chakra-ui/react'
import { CsbEditor, CsbFormControl } from '@src/components'
import { InputControl } from '@src/components/Form/InputControl/InputControl'
import {
  getMailPlaceholders,
  useMailPlaceholderModal,
} from '@src/components/Modal/MailPlaceholderModal/MailPlaceholderModal'
import { BaseResponseError } from '@src/errors/BaseResponseError'
import {
  CompanyAttach,
  CompanyAttachFileKind,
  CreatePhishingEmailInput,
  PhishingEditFormQueryResult,
  PhishingMethod,
  UpdatePhishingEmailInput,
} from '@src/graphql/generated/graphql'
import { findError } from '@src/utils/findError'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types'

type AttachFileFormPropsType = {
  data?: PhishingEditFormQueryResult['data']
  errors: [BaseResponseError?]
  //TODO anyを消す
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<CreatePhishingEmailInput | UpdatePhishingEmailInput | any>
  isLock?: boolean
  isWritePhishingMail: boolean
}

const AttachFileTypes = {
  // 登録したもの
  Custom: 2,
  // 標準
  Standard: 1,
}

type AttachFile = typeof AttachFileTypes[keyof typeof AttachFileTypes]

export const AttachFileForm = ({
  data,
  errors,
  form,
  isLock,
  isWritePhishingMail,
}: AttachFileFormPropsType) => {
  const getAttachFileType = () => {
    return form.getValues('companyAttachId')
      ? AttachFileTypes.Custom
      : AttachFileTypes.Standard
  }
  const [attachFileType, setAttachFileType] = useState<AttachFile>(
    getAttachFileType()
  )
  const [isArchiveAttachVisible, setIsArchiveAttachVisible] = useState(false)
  const [isDisableArchiveAttach, setIsDisableArchiveAttach] = useState(false)

  const findAttachByAttachId = useCallback(
    (attachId: string) =>
      data?.attaches.collection.find((n) => n.uuid === attachId),
    [data?.attaches.collection]
  )

  const findCompanyAttachByAttachId = useCallback(
    (attachId: string) =>
      data?.companyAttaches.find((n) => n.uuid === attachId),
    [data?.companyAttaches]
  )

  // コンテンツ配信の場合は、ラーニングページへのリンクと説明を変更する
  const mailPlaceHolders = getMailPlaceholders(false)

  const [MailPlaceholderModal, { onOpen: mailPlaceholderModalOnOpen }] =
    useMailPlaceholderModal(mailPlaceHolders)

  useUpdateEffect(() => {
    const attach = findAttachByAttachId(form.watch().attachId)
    form.setValue('attachName', attach?.fileName ?? '')
    setIsArchiveAttachVisible(!!attach)
    setIsDisableArchiveAttach(attach?.fishingMethod === PhishingMethod.Emotet)
    if (attach?.fishingMethod === PhishingMethod.Emotet) {
      form.setValue('isArchiveAttach', true)
    }
  }, [form.watch('attachId')])

  useUpdateEffect(() => {
    const attach = findCompanyAttachByAttachId(form.watch().companyAttachId)
    form.setValue('attachName', attach?.fileName ?? '')
    setIsArchiveAttachVisible(!!attach)
  }, [form.watch('companyAttachId')])

  useEffect(() => {
    if (attachFileType === AttachFileTypes.Standard) {
      const attach = findAttachByAttachId(form.getValues('attachId'))
      setIsArchiveAttachVisible(!!attach)
      setIsDisableArchiveAttach(attach?.fishingMethod === PhishingMethod.Emotet)
    } else {
      const attach = findCompanyAttachByAttachId(
        form.getValues('companyAttachId')
      )
      setIsArchiveAttachVisible(!!attach)
    }
  }, [attachFileType, form, findAttachByAttachId, findCompanyAttachByAttachId])

  const getCompanyAttachFileName = (
    item: Pick<CompanyAttach, 'fileName' | 'fileKind'>
  ) => {
    const fileKindName = (() => {
      switch (item.fileKind) {
        case CompanyAttachFileKind.Xlsm:
          return 'マクロ付きExcelファイル'
        case CompanyAttachFileKind.Docm:
          return 'マクロ付きWordファイル'
        case CompanyAttachFileKind.Xlsx:
          return '外部アクセスExcelファイル'
        case CompanyAttachFileKind.Docx:
          return '外部アクセスWordファイル'
        case CompanyAttachFileKind.Ppsx:
          return '外部アクセスPowerPointファイル'
        case CompanyAttachFileKind.Pdf:
          return '外部アクセスPDFファイル'
      }
    })()
    return `${item.fileName}（${fileKindName}）`
  }

  // 種明かしメールの送信元アドレスタイプ変更時の処理
  const onChangeAttachFile = (value: string) => {
    const attachFileType = Number(value)
    setAttachFileType(attachFileType)
    if (attachFileType === AttachFileTypes.Standard) {
      form.setValue('companyAttachId', null)
    } else {
      form.setValue('attachId', null)
    }
    setIsArchiveAttachVisible(false)
  }

  // noinspection DuplicatedCode
  const onChangeIsArchiveAttach = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      form.setValue('isArchiveAttachPassword', false)
      form.setValue('archiveAttachPassword', '')
      form.setValue('isCustomNotifyPassword', false)
      form.setValue('notifyArchivePasswordSubject', '')
      form.setValue('notifyArchivePasswordHtml', '')
    }
  }

  const onChangeIsArchiveAttachPassword = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.checked) {
      form.setValue('archiveAttachPassword', '')
      form.setValue('isCustomNotifyPassword', false)
      form.setValue('notifyArchivePasswordSubject', '')
      form.setValue('notifyArchivePasswordHtml', '')
    }
  }

  const onChangeIsCustomNotifyPassword = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      form.setValue('notifyArchivePasswordSubject', '')
      form.setValue('notifyArchivePasswordHtml', '')
    }
  }

  return (
    <Box maxW={600} w={'full'}>
      <CsbFormControl labelText={'添付ファイルの種類'} mt={4}>
        <RadioGroup value={attachFileType} onChange={onChangeAttachFile}>
          <Flex gap={5}>
            <Radio value={AttachFileTypes.Standard}>標準のものを使用</Radio>
            <Radio value={AttachFileTypes.Custom}>登録したものを使用</Radio>
          </Flex>
        </RadioGroup>
      </CsbFormControl>
      {attachFileType === AttachFileTypes.Standard && (
        <CsbFormControl
          errorText={findError(errors, 'attachId')?.message}
          labelText={'添付するファイル'}
          mt={5}
        >
          <Controller
            control={form.control}
            name={'attachId'}
            render={({ field }) => (
              <Select {...field} disabled={isLock || !isWritePhishingMail}>
                <option value={''}>未選択</option>
                {data?.attaches.collection.map((item, index) => (
                  <option key={`${item.uuid}_${index}`} value={item.uuid}>
                    {item.name}
                  </option>
                ))}
              </Select>
            )}
          />
        </CsbFormControl>
      )}
      {attachFileType === AttachFileTypes.Custom && (
        <CsbFormControl
          errorText={findError(errors, 'companyAttachId')?.message}
          labelText={'添付するファイル'}
          mt={5}
        >
          <Controller
            control={form.control}
            name={'companyAttachId'}
            render={({ field }) => (
              <Select {...field} disabled={isLock || !isWritePhishingMail}>
                <option value={''}>未選択</option>
                {data?.companyAttaches.map((item, index) => (
                  <option key={`${item.uuid}_${index}`} value={item.uuid}>
                    {getCompanyAttachFileName(item)}
                  </option>
                ))}
              </Select>
            )}
          />
        </CsbFormControl>
      )}
      <CsbFormControl
        errorText={findError(errors, 'attachName')?.message}
        labelText={'ファイル名'}
        mt={5}
      >
        <InputControl
          control={form.control}
          isDisabled={
            form.getValues('attachName') === '' ||
            isLock ||
            !isWritePhishingMail
          }
          name={'attachName'}
        />
      </CsbFormControl>
      {isArchiveAttachVisible && (
        <>
          <CsbFormControl
            errorText={findError(errors, 'isArchiveAttach')?.message}
            labelText={'添付ファイル・圧縮（ZIP）'}
            mt={5}
          >
            <Controller
              control={form.control}
              name={'isArchiveAttach'}
              render={({ field: { onChange, value } }) => (
                <HStack fontSize={'sm'} mt={1}>
                  <Text>圧縮しない</Text>
                  <Switch
                    isChecked={value ?? true}
                    isDisabled={
                      isLock || isDisableArchiveAttach || !isWritePhishingMail
                    }
                    onChange={(e) => {
                      onChange(e)
                      onChangeIsArchiveAttach(e)
                    }}
                  />
                  <Text>圧縮する</Text>
                </HStack>
              )}
            />
          </CsbFormControl>

          {form.watch('isArchiveAttach') && (
            <>
              <CsbFormControl
                errorText={findError(errors, 'archiveAttachName')?.message}
                helperText={
                  '添付ファイル名とは別にZIPファイル名を指定した場合に入力してください'
                }
                labelText={'ZIPファイル名'}
                mt={5}
              >
                <Input
                  {...form.register('archiveAttachName')}
                  disabled={isLock || !isWritePhishingMail}
                />
              </CsbFormControl>
              <CsbFormControl
                errorText={
                  findError(errors, 'isArchiveAttachPassword')?.message
                }
                labelText={'添付ファイル・パスワード設定'}
                mt={5}
              >
                <Controller
                  control={form.control}
                  name={'isArchiveAttachPassword'}
                  render={({ field: { onChange, value } }) => (
                    <HStack fontSize={'sm'} mt={1}>
                      <Text>設定しない</Text>
                      <Switch
                        isChecked={value ?? true}
                        isDisabled={isLock || !isWritePhishingMail}
                        onChange={(e) => {
                          onChange(e)
                          onChangeIsArchiveAttachPassword(e)
                        }}
                      />
                      <Text>設定する</Text>
                    </HStack>
                  )}
                />
              </CsbFormControl>
              {form.watch('isArchiveAttachPassword') && (
                <>
                  <CsbFormControl
                    errorText={
                      findError(errors, 'archiveAttachPassword')?.message
                    }
                    labelText={'添付ファイル・パスワード'}
                    mt={5}
                  >
                    <Input
                      {...form.register('archiveAttachPassword')}
                      disabled={isLock || !isWritePhishingMail}
                    />
                    <Text fontSize={'14px'} textColor={'GrayText'}>
                      半角英数字と記号「!#$%()*+-./:;=?@[]^_|~」が利用できます
                    </Text>
                  </CsbFormControl>
                  <CsbFormControl
                    errorText={
                      findError(errors, 'isCustomNotifyPassword')?.message
                    }
                    labelText={'添付ファイル・パスワード通知'}
                    mt={5}
                  >
                    <Controller
                      control={form.control}
                      name={'isCustomNotifyPassword'}
                      render={({ field: { onChange, value } }) => (
                        <HStack fontSize={'sm'} mt={1}>
                          <Text>設定しない（訓練メール内に記載）</Text>
                          <Switch
                            isChecked={value ?? true}
                            isDisabled={isLock || !isWritePhishingMail}
                            onChange={(e) => {
                              onChange(e)
                              onChangeIsCustomNotifyPassword(e)
                            }}
                          />
                          <Text>設定する</Text>
                        </HStack>
                      )}
                    />
                  </CsbFormControl>
                  {form.watch('isCustomNotifyPassword') && (
                    <>
                      <CsbFormControl
                        errorText={
                          findError(errors, 'notifyArchivePasswordSubject')
                            ?.message
                        }
                        labelText={'添付ファイル・パスワード通知メールの件名'}
                        mt={5}
                      >
                        <Controller
                          control={form.control}
                          name={'notifyArchivePasswordSubject'}
                          render={({ field }) => (
                            <Input
                              w={'full'}
                              {...field}
                              disabled={isLock || !isWritePhishingMail}
                              value={field.value || ''}
                            />
                          )}
                        />
                      </CsbFormControl>
                      <CsbFormControl
                        errorText={
                          findError(errors, 'notifyArchivePasswordHtml')
                            ?.message
                        }
                        labelText={
                          <HStack>
                            <Text disabled={isLock}>
                              {'添付ファイル・パスワード通知メールの文章'}
                            </Text>
                            <chakra.button
                              type={'button'}
                              onClick={mailPlaceholderModalOnOpen}
                            >
                              <QuestionOutlineIcon color={'teal.500'} />
                            </chakra.button>
                          </HStack>
                        }
                        mt={5}
                      >
                        <Controller
                          control={form.control}
                          name={'notifyArchivePasswordHtml'}
                          render={({ field: { onChange, value } }) => (
                            <CsbEditor
                              data={value ?? ''}
                              disabled={isLock || !isWritePhishingMail}
                              isHtmlLocked={
                                !!form.getValues(
                                  'isNotifyArchivePasswordHtmlLock'
                                )
                              }
                              onChangeHtml={onChange}
                              onHtmlLocked={(locked) =>
                                form.setValue(
                                  'isNotifyArchivePasswordHtmlLock',
                                  locked
                                )
                              }
                            />
                          )}
                        />
                      </CsbFormControl>
                    </>
                  )}
                </>
              )}
            </>
          )}
          <Button
            disabled={isLock || !isWritePhishingMail}
            mt={5}
            onClick={() => {
              form.setValue(
                'isArchiveAttach',
                data?.setting.isArchiveAttach ?? false
              )
              form.setValue(
                'isArchiveAttachPassword',
                data?.setting.isArchiveAttachPassword ?? false
              )
              form.setValue(
                'archiveAttachPassword',
                data?.setting.archiveAttachPassword ?? ''
              )
              form.setValue(
                'isCustomNotifyPassword',
                data?.setting.isCustomNotifyPassword ?? false
              )
              form.setValue(
                'notifyArchivePasswordSubject',
                data?.setting.notifyArchivePasswordSubject ?? ''
              )
              form.setValue(
                'notifyArchivePasswordHtml',
                data?.setting.notifyArchivePasswordHtml ?? ''
              )
            }}
          >
            デフォルトに戻す
          </Button>
        </>
      )}
      <MailPlaceholderModal />
    </Box>
  )
}
