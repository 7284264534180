import { TemplatesEdit } from '@src/components/pages/templates/[id]/edit'
import { useTemplatesEditHooks } from '@src/components/pages/templates/[id]/edit/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const TemplatesEditPage = memo(() => {
  const templatesEditHooks = useTemplatesEditHooks()
  return (
    <Authorization>
      <TemplatesEdit {...templatesEditHooks} />
    </Authorization>
  )
})
