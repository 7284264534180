import { Flex, FlexProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

//TODO dividerは改善の余地あり
type ListItemPropsType = {
  children: ReactNode
  divider?: boolean
} & FlexProps

export const CsbListItem = ({
  children,
  divider = true,
  ...props
}: ListItemPropsType) => {
  return (
    <Flex
      align={'center'}
      as={'li'}
      borderBottom={divider ? '1px solid' : ''}
      borderColor={'gray.200'}
      h={'full'}
      listStyleType={'none'}
      minH={75}
      pl={'26px'}
      pr={'20px'}
      width={'full'}
      {...props}
    >
      {children}
    </Flex>
  )
}
