import { CompaniesDetail } from '@src/components/pages/companies/detail'
import { useCompaniesDetailHooks } from '@src/components/pages/companies/detail/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

//企業詳細ページ
export const CompaniesDetailPage = memo(() => {
  const companiesDetailHooks = useCompaniesDetailHooks()
  return (
    <Authorization>
      <CompaniesDetail {...companiesDetailHooks} />
    </Authorization>
  )
})
