import { Gender } from '@src/graphql/generated/graphql'

export const GENDERS = [
  {
    name: '男',
    value: Gender.Man,
  },
  {
    name: '女',
    value: Gender.Woman,
  },
  {
    name: '未設定',
    value: Gender.Other,
  },
]
