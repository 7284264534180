import { Box, Button, Flex, Heading, Skeleton, Tooltip } from '@chakra-ui/react'
import { CsbPagination, CsbUserAvatar } from '@src/components'
import { CsbCounter } from '@src/components/CsbCounter'
import { CsbTable } from '@src/components/CsbTable'
import { useReplyUsersHooks } from '@src/components/pages/phishing/[id]/result/components/ReplyUsers/useReplyUsers'
import { usePhishingResultNewContentHooksResult } from '@src/components/pages/phishing/[id]/result/PhishingResultNewContent'
import { ReplyUsers as ReplyUsersModel } from '@src/models/Phishing/ReplyUsers/ReplyUsers'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'

export const ReplyUsers = ({
  id,
  usePhishingResultContentsSelectResult,
}: usePhishingResultNewContentHooksResult) => {
  const { current, onChangePage, useReplyUsersResult } = useReplyUsersHooks(
    id ?? ''
  )

  const { refetch } = useReplyUsersResult
  useEffect(() => {
    refetch().then()
  }, [refetch, usePhishingResultContentsSelectResult.form.formState])

  return (
    <>
      <Flex>
        <Heading fontSize={16}>メール返信ユーザー</Heading>
      </Flex>
      <Box mb={'80px'} mt={35}>
        <CsbTable
          labels={[
            { label: '氏名' },
            { label: '' },
            { label: 'メールアドレス' },
            { label: '社員番号' },
            { label: '返信日時' },
            { label: '返信メール' },
          ]}
        >
          <ReplyUsersModel
            {...useReplyUsersResult}
            loadingFallback={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => (
              <CsbTable.TableRow key={`ReplyUsers_loading_${index}`}>
                <CsbTable.TableCell colSpan={8}>
                  <Skeleton h={12} />
                </CsbTable.TableCell>
              </CsbTable.TableRow>
            ))}
            noDataFallback={<>data nasi</>}
          >
            {(data) => (
              <>
                {data.phishingReplyTargets.collection.map(
                  ({ fromEmail, messageReceivedAt, target }) => (
                    <CsbTable.TableRow key={target.user.uuid}>
                      <CsbTable.TableCell colSpan={2}>
                        <CsbUserAvatar
                          description={target.user.email}
                          name={
                            target.user.lastName &&
                            target.user.firstName &&
                            `${target.user.lastName} ${target.user.firstName}`
                          }
                          src={target.user.avatarPath || undefined}
                        />
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>{fromEmail}</CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {target.user.employeeNumber &&
                          target.user.employeeNumber}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {messageReceivedAt
                          ? dayjs(messageReceivedAt).format('YYYY/MM/DD HH:mm')
                          : '-'}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        <Tooltip
                          aria-label="A tooltip"
                          label={
                            '返信メール（emlファイル）をダウンロードできます'
                          }
                        >
                          <Button
                            onClick={(e) => {
                              e.stopPropagation()
                              window.open(
                                `${process.env.REACT_APP_BACKEND_URL}/export/phishing_reply_eml?uuid=${target.shortPath}`,
                                '_blank'
                              )
                            }}
                          >
                            ダウンロード
                          </Button>
                        </Tooltip>
                      </CsbTable.TableCell>
                    </CsbTable.TableRow>
                  )
                )}
              </>
            )}
          </ReplyUsersModel>
        </CsbTable>
        <ReplyUsersModel
          {...useReplyUsersResult}
          loadingFallback={<Skeleton h={'52px'} mt={5} />}
        >
          {(data) => (
            <Flex mt={5}>
              <CsbPagination
                current={current}
                defaultCurrent={current}
                pageSize={data.phishingReplyTargets.metadata.limitValue}
                total={data.phishingReplyTargets.metadata.totalCount}
                onChange={onChangePage()}
              />
              <Box ml={'auto'}>
                <CsbCounter
                  data={{
                    ...data.phishingReplyTargets.metadata,
                    currentCount: data.phishingReplyTargets.collection.length,
                  }}
                />
              </Box>
            </Flex>
          )}
        </ReplyUsersModel>
      </Box>
    </>
  )
}
