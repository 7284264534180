import { Templates } from '@src/components/pages/templates'
import { useTemplatesHooks } from '@src/components/pages/templates/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const TemplatesPage = memo(() => {
  const templatesHooks = useTemplatesHooks()
  return (
    <Authorization>
      <Templates {...templatesHooks} />
    </Authorization>
  )
})
