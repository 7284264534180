import {
  Box,
  Checkbox,
  Divider,
  Heading,
  HStack,
  Input,
  Select,
  VStack,
} from '@chakra-ui/react'
import { CsbFormControl, CsbPassword } from '@src/components'
import { EmployeeInformation } from '@src/components/Form/user/EmployeeInformation'
import { GENDERS } from '@src/constants'
import { BaseResponseError } from '@src/errors/BaseResponseError'
import {
  CreateUserInput,
  UpdateUserInput,
  useUserEditFormQuery,
} from '@src/graphql/generated/graphql'
import { useMe } from '@src/hooks'
import { findError } from '@src/utils/findError'
import { UseFormReturn } from 'react-hook-form/dist/types'

type UserEditFormPropsType = {
  errors: [BaseResponseError?]
  //TODO anyを消す
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<CreateUserInput | Omit<UpdateUserInput, 'uuid'> | any>
  isNew?: boolean
  //objectとして切り出した方が拡張しやすいかも
  type?: 'company' | 'user'
}

export const UserEditForm = ({
  errors,
  form,
  isNew = true,
  type = 'user',
}: UserEditFormPropsType) => {
  //user以外の場合はusersのprefixを付与
  const formNameSpace = type === 'user' ? '' : 'users.'
  //企業でユーザーを作成する時のタイプ
  const formInputNameSpace = type === 'company' ? 'users.0.' : ''

  const { data, loading: isLoading } = useUserEditFormQuery({
    skip: type === 'company',
  })

  const { role } = useMe()

  return (
    <VStack align={'start'} spacing={55}>
      <VStack align={'start'} mt={6} spacing={5}>
        <HStack spacing={'35px'}>
          <CsbFormControl
            errorText={findError(errors, `${formNameSpace}lastName`)?.message}
            labelText={'氏名(姓)*'}
            w={285}
          >
            <Input {...form.register(`${formInputNameSpace}lastName`)} />
          </CsbFormControl>
          <CsbFormControl
            errorText={findError(errors, `${formNameSpace}firstName`)?.message}
            labelText={'氏名(名)*'}
            w={285}
          >
            <Input {...form.register(`${formInputNameSpace}firstName`)} />
          </CsbFormControl>
        </HStack>
        <HStack spacing={'35px'}>
          <CsbFormControl
            errorText={findError(errors, `${formNameSpace}gender`)?.message}
            labelText={'性別'}
            w={285}
          >
            <Select
              {...form.register(`${formInputNameSpace}gender`)}
              defaultValue={form.getValues(`${formInputNameSpace}gender`)}
            >
              {GENDERS.map(({ name, value }) => (
                <option key={`GENDERS_${name}_${value}`} value={value}>
                  {name}
                </option>
              ))}
            </Select>
          </CsbFormControl>
          <CsbFormControl
            errorText={findError(errors, `${formNameSpace}birthdayOn`)?.message}
            labelText={'生年月日'}
            w={285}
          >
            <Input
              {...form.register(`${formInputNameSpace}birthdayOn`)}
              type={'date'}
            />
          </CsbFormControl>
        </HStack>
        <HStack spacing={'35px'}>
          {type === 'user' && role?.writeRole && (
            <CsbFormControl
              errorText={findError(errors, `${formNameSpace}roleId`)?.message}
              labelText={'権限'}
              w={285}
            >
              {!isLoading && (
                <Select
                  {...form.register('roleId')}
                  defaultValue={!isLoading ? form.getValues('roleId') : ''}
                >
                  {data?.roles.collection.map((item) => (
                    <option key={item.uuid} value={item.uuid}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              )}
            </CsbFormControl>
          )}
          <CsbFormControl
            errorText={findError(errors, `${formNameSpace}joinedOn`)?.message}
            labelText={'入社日'}
            w={285}
          >
            <Input
              {...form.register(`${formInputNameSpace}joinedOn`)}
              type={'date'}
            />
          </CsbFormControl>
        </HStack>
        {!isLoading && (
          <HStack spacing={'35px'}>
            <CsbFormControl
              errorText={
                findError(errors, `${formNameSpace}testIgnore`)?.message
              }
              labelText={'集計から除外'}
              w={285}
            >
              <Checkbox
                {...form.register(`${formInputNameSpace}testIgnore`)}
                defaultChecked={form.getValues(
                  `${formInputNameSpace}testIgnore`
                )}
                onChange={(element) => {
                  form.setValue(
                    `${formInputNameSpace}testIgnore`,
                    element.target.checked
                  )
                }}
              >
                除外する
              </Checkbox>
            </CsbFormControl>
          </HStack>
        )}
      </VStack>
      <Box w={'full'}>
        <VStack align={'start'}>
          <Heading fontSize={24}>ログイン情報</Heading>
          <Divider />
        </VStack>
        <VStack align={'start'} mt={6} spacing={5}>
          <CsbFormControl
            errorText={findError(errors, `${formNameSpace}email`)?.message}
            labelText={'メールアドレス*'}
            w={605}
          >
            <Input
              type="email"
              {...form.register(`${formInputNameSpace}email`)}
            />
          </CsbFormControl>
          <CsbFormControl
            errorText={findError(errors, `${formNameSpace}password`)?.message}
            labelText={`パスワード${isNew ? '*' : ''}`}
            w={605}
          >
            <CsbPassword
              {...form.register(`${formInputNameSpace}password`)}
              autoComplete="new-password"
            />
          </CsbFormControl>
          <CsbFormControl
            errorText={
              findError(errors, `${formNameSpace}passwordConfirmation`)?.message
            }
            labelText={`パスワード(確認)${isNew ? '*' : ''}`}
            w={605}
          >
            <CsbPassword
              {...form.register(`${formInputNameSpace}passwordConfirmation`)}
            />
          </CsbFormControl>
        </VStack>
      </Box>

      {type === 'user' && (
        <Box w={'full'}>
          <VStack align={'start'}>
            <Heading fontSize={24}>社員情報</Heading>
            <Divider />
          </VStack>
          <EmployeeInformation errors={errors} form={form} />
        </Box>
      )}
    </VStack>
  )
}
