import { MultiSelectOptionType } from '@src/components/Input/CsbMultiSelect'
import { useCallback, useMemo } from 'react'

type multiSelectType = {
  options: MultiSelectOptionType[]
  selectedOptions: MultiSelectOptionType[]
}

type useMultiSelectType = <
  T extends { name: string; uuid: string }[] | undefined
>(
  anyQueries: T,
  uuids: string[]
) => {
  onChangeSelected: (
    setUuids: (ids: string[]) => void
  ) => (selectedItem: MultiSelectOptionType[]) => void
  select: multiSelectType
}

//MultiSelectの初期値やイベントのhooks
export const useMultiSelect: useMultiSelectType = (anyQueries, uuids) => {
  //uuidが存在するか判定する
  const hasUuid = useCallback(
    (uuid: string) => {
      return uuids.find((currentUuid) => currentUuid === uuid)
    },
    [uuids]
  )

  const select = useMemo(() => {
    return {
      options:
        anyQueries?.map(({ name, uuid }) => ({
          label: name,
          value: uuid,
        })) ?? [],
      selectedOptions:
        anyQueries
          ?.filter(({ uuid }) => hasUuid(uuid))
          .map(({ name, uuid }) => ({
            label: name,
            value: uuid,
          })) ?? [],
    }
  }, [anyQueries, hasUuid])

  //multiSelectが変わる時の関数
  const onChangeSelected = useCallback(
    (setUuids: (ids: string[]) => void) =>
      (selectedItem: MultiSelectOptionType[]) => {
        const uuids = selectedItem.map(({ value }) => value)
        setUuids(uuids)
      },
    []
  )

  return {
    onChangeSelected,
    select,
  }
}
