import { useAdminResearchQuery } from '@src/graphql/generated/graphql'

export const useAdminResearch = (
  baseOptions: Partial<Parameters<typeof useAdminResearchQuery>[0]> = {}
) => {
  return useAdminResearchQuery(baseOptions)
}

type UseAdminResearchType = typeof useAdminResearch
export type UseAdminResearchResult = ReturnType<UseAdminResearchType>
// export type UseAdminResearchParameter = Parameters<UseProjectType>[0]
