import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  HStack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  CsbDeleteButton,
  CsbDeleteModal,
  CsbNextLink,
  CsbPagination,
  CsbSearchInput,
  CsbTag,
} from '@src/components'
import { CsbTable } from '@src/components/CsbTable'
import { useTemplatesHooksResult } from '@src/components/pages/templates/hooks'
import { Base } from '@src/layouts'
import React, { memo } from 'react'

//メールテンプレート一覧ページのcomponent
export const Templates = memo(
  ({
    current,
    data,
    deleteModal,
    destroyMailTemplateMutation,
    isWriteMailTemplate,
    navigate,
    onChangePage,
    onResetSearch,
    onSearch,
    refetch,
    searchForm,
  }: useTemplatesHooksResult) => {
    return (
      <Base
        left={<Heading>テンプレート</Heading>}
        right={
          isWriteMailTemplate && (
            <CsbNextLink href={'/templates/new'}>
              <Button as={'div'}>新しいテンプレートの作成</Button>
            </CsbNextLink>
          )
        }
      >
        <Box maxW={518} mt={12} w={'full'}>
          <CsbSearchInput
            placeholder={'テンプレート名を入力'}
            {...searchForm.register('searchWord')}
            onReset={onResetSearch}
            onSearch={onSearch}
          />
        </Box>
        <CsbTable
          labels={['ステータス', 'ラベル', '']}
          wrapperProps={{ mt: 35 }}
        >
          {data?.mailTemplates.collection.map((item) => (
            <CsbTable.TableRow
              key={item.uuid}
              onClick={() => navigate(`/templates/${item.uuid}/edit`)}
            >
              <CsbTable.TableCell w={120}>
                <CsbTag colorScheme={item.draft ? 'blue' : 'teal'} size={'md'}>
                  {item.draft ? '下書き' : '公開済み'}
                </CsbTag>
              </CsbTable.TableCell>
              <CsbTable.TableCell w={400}>
                <Grid rowGap={1}>
                  <Tooltip aria-label="A tooltip" label={item.name}>
                    <Text isTruncated fontWeight={'bold'} lineHeight={1}>
                      {item.name}
                    </Text>
                  </Tooltip>
                  <Tooltip aria-label="A tooltip" label={item.mailFrom.email}>
                    <Text
                      isTruncated
                      color={'gray.600'}
                      fontSize={'sm'}
                      lineHeight={1}
                    >
                      {item.mailFrom.email}
                    </Text>
                  </Tooltip>
                </Grid>
              </CsbTable.TableCell>
              <CsbTable.TableCell isNumeric>
                {isWriteMailTemplate && (
                  <CsbDeleteButton
                    onClick={(e) => {
                      e.stopPropagation()
                      deleteModal.onOpen(item.uuid, item.name)
                    }}
                  >
                    削除
                  </CsbDeleteButton>
                )}
              </CsbTable.TableCell>
            </CsbTable.TableRow>
          ))}
        </CsbTable>
        <Flex mt={5}>
          {data ? (
            <CsbPagination
              current={current}
              defaultCurrent={current}
              pageSize={data.mailTemplates.metadata.limitValue}
              total={data.mailTemplates.metadata.totalCount}
              onChange={onChangePage('/templates')}
            />
          ) : null}
          <HStack ml={'auto'} spacing={3}>
            {isWriteMailTemplate && (
              <CsbNextLink href={'/templates/new'}>
                <Button as={'div'}>新しいテンプレートの作成</Button>
              </CsbNextLink>
            )}
          </HStack>
        </Flex>
        <CsbDeleteModal
          header={deleteModal.target}
          isOpen={deleteModal.isOpen}
          onCancel={deleteModal.onClose}
          onClose={deleteModal.onClose}
          onDelete={() =>
            deleteModal.onDelete(
              destroyMailTemplateMutation({
                variables: {
                  input: { uuid: deleteModal.uuid ?? '' },
                },
              }),
              async () => {
                const { data } = await refetch()
                return {
                  metadata: {
                    currentPage: data.mailTemplates.metadata.currentPage,
                    limitValue: data.mailTemplates.metadata.limitValue,
                    totalCount: data.mailTemplates.metadata.totalCount,
                  },
                }
              },
              onChangePage('/templates')
            )
          }
        />
      </Base>
    )
  }
)
