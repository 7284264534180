import { DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import { CsbPrimaryBox } from '@src/components/CsbPrimaryBox'
import {
  useAdminResearchesDetail,
  UseAdminResearchesDetailResult,
} from '@src/components/pages/admin-researches/[id]/edit/hooks'
import { AdminResearchQuery as AdminResearchQueryType } from '@src/graphql/generated/graphql'
import { Base } from '@src/layouts'
import {
  AdminResearchQuery,
  AdminResearchQuestionForm,
  CreateAdminResearchQuestionModal,
  useDestroyAdminResearchQuestion,
  useUpdateAdminResearchQuestion,
} from '@src/models/AdminResearch'
import { memo, useEffect } from 'react'

type UpdateAdminResearchQuestionProps =
  AdminResearchQueryType['adminResearch']['adminResearchQuestions'][number]

const UpdateAdminResearchQuestion = ({
  answerType,
  answers,
  isWriteAdminResearch,
  name,
  uuid,
}: UpdateAdminResearchQuestionProps &
  Pick<UseAdminResearchesDetailResult, 'isWriteAdminResearch'>) => {
  const updateAdminResearchQuestion = useUpdateAdminResearchQuestion({
    onCompleted: (data) => {
      if (!data) return
      if (!data.updateAdminResearchQuestion) return
      const { adminResearchQuestion } = data.updateAdminResearchQuestion
      updateAdminResearchQuestion.form.setValue(
        'answerType',
        adminResearchQuestion.answerType
      )
      updateAdminResearchQuestion.form.setValue(
        'name',
        adminResearchQuestion.name
      )
      adminResearchQuestion.answers.map((answer, index) => {
        updateAdminResearchQuestion.form.setValue(
          `answers.${index + 1}`,
          answer
        )
      })
    },
  })
  const destroyAdminResearchQuestion = useDestroyAdminResearchQuestion()
  useEffect(() => {
    updateAdminResearchQuestion.form.setValue('answerType', answerType)
    updateAdminResearchQuestion.form.setValue('name', name)
    answers.map((answer, index) => {
      updateAdminResearchQuestion.form.setValue(`answers.${index + 1}`, answer)
    })
  }, [answerType, answers, name, updateAdminResearchQuestion.form])

  return (
    <CsbPrimaryBox position={'relative'} px={5} py={5}>
      <AdminResearchQuestionForm
        findErrorMessage={updateAdminResearchQuestion.findErrorMessage}
        form={updateAdminResearchQuestion.form}
      />
      {isWriteAdminResearch && (
        <Button
          isLoading={updateAdminResearchQuestion.isLoading}
          mt={5}
          onClick={updateAdminResearchQuestion.onUpdateAdminResearchQuestion(
            uuid
          )}
        >
          更新
        </Button>
      )}
      {isWriteAdminResearch && (
        <IconButton
          aria-label="delete"
          colorScheme={'red'}
          icon={<DeleteIcon />}
          isLoading={destroyAdminResearchQuestion.isLoading}
          position={'absolute'}
          right={0}
          top={0}
          transform={'translate(50%,-50%)'}
          onClick={() =>
            destroyAdminResearchQuestion.onDestroyAdminResearchQuestion(uuid)
          }
        />
      )}
    </CsbPrimaryBox>
  )
}

//フィシイング一覧ページのcomponent
export const AdminResearchesDetail = memo(() => {
  const {
    adminResearch,
    createAdminResearchQuestionModal,
    isWriteAdminResearch,
    navigate,
  } = useAdminResearchesDetail()
  return (
    <Base
      left={<Heading>共通アンケート編集</Heading>}
      right={
        <HStack spacing={3}>
          <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
            一覧へ戻る
          </Button>
        </HStack>
      }
    >
      <CsbPrimaryBox pb={'72px'} pt={35} px={45}>
        <AdminResearchQuery {...adminResearch}>
          {({ adminResearch }) => (
            <>
              <Box as={'ul'} display={'flex'} flexDirection={'column'} gap={5}>
                {adminResearch.adminResearchQuestions.map(
                  (adminResearchQuestion) => (
                    <Box
                      as={'li'}
                      key={adminResearchQuestion.uuid}
                      listStyleType={'none'}
                    >
                      <UpdateAdminResearchQuestion
                        isWriteAdminResearch={isWriteAdminResearch}
                        {...adminResearchQuestion}
                      />
                    </Box>
                  )
                )}
              </Box>
            </>
          )}
        </AdminResearchQuery>
        <Button mt={5} onClick={createAdminResearchQuestionModal.onOpen}>
          新規作成
        </Button>
      </CsbPrimaryBox>
      <Flex mt={5}>
        <HStack ml={'auto'} spacing={3}>
          <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
            一覧へ戻る
          </Button>
        </HStack>
      </Flex>
      <CreateAdminResearchQuestionModal {...createAdminResearchQuestionModal} />
    </Base>
  )
})
