import { useReplyUsersQuery } from '@src/graphql/generated/graphql'

export const useReplyUsers = (
  baseOptions: Partial<Parameters<typeof useReplyUsersQuery>[0]> = {}
) => {
  return useReplyUsersQuery(baseOptions)
}

export type useReplyUsersHooksResult = ReturnType<typeof useReplyUsers>
export type useReplyUsersHooksParameter = Parameters<typeof useReplyUsers>[0]
