import { Button, HStack } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import { UseDestroyPdfModalResult } from '@src/models/Pdf/components/DestroyPdfModal/useDestroyPdfModal'

export const DestroyPdfModal = ({
  Pdf,
  isLoading,
  isOpen,
  onClose,
  onDestroyPdf,
}: UseDestroyPdfModalResult) => (
  <CsbModal isOpen={isOpen} onClose={onClose}>
    <CsbModalHeader>警告</CsbModalHeader>
    <CsbModalBody mt={10}>{Pdf?.name}を削除しますか？</CsbModalBody>
    <CsbModalFooter mt={7}>
      <HStack spacing={6}>
        <Button colorScheme={'gray'} onClick={onClose}>
          キャンセル
        </Button>
        <Button
          colorScheme={'red'}
          isLoading={isLoading}
          onClick={onDestroyPdf}
        >
          削除
        </Button>
      </HStack>
    </CsbModalFooter>
  </CsbModal>
)
