import { Organizations } from '@src/components/pages/organizations'
import { useOrganizationsHooks } from '@src/components/pages/organizations/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const OrganizationsPage = memo(() => {
  const organizationsHooks = useOrganizationsHooks()
  return (
    <Authorization>
      <Organizations {...organizationsHooks} />
    </Authorization>
  )
})
