import { Text } from '@chakra-ui/react'

export const CsbNewLineText = ({ text }: { text: string }) => {
  if (text.indexOf('\n') === -1) {
    return <>{text}</>
  }

  const newLines = text.split('\n').map((str, index, array) => {
    return index === array.length - 1 ? (
      <Text mb={2}>{str}</Text>
    ) : (
      <Text mb={2}>
        {str}
        <br />
      </Text>
    )
  })
  return <>{newLines}</>
}
