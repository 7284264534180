import { EditIcon } from '@chakra-ui/icons'
import { forwardRef, IconButton, IconButtonProps } from '@chakra-ui/react'
type CsbDeleteButtonPropsType = Omit<IconButtonProps, 'children' | 'aria-label'>

export const CsbEditButton = forwardRef<CsbDeleteButtonPropsType, 'button'>(
  ({ ...props }, ref) => {
    return (
      <IconButton
        colorScheme={'teal'}
        icon={<EditIcon />}
        ref={ref}
        rounded={'full'}
        {...props}
        aria-label="edit"
      />
    )
  }
)
