/* eslint-disable @typescript-eslint/no-explicit-any */
import { QuestionOutlineIcon } from '@chakra-ui/icons'
import {
  chakra,
  Flex,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react'
import { CsbEditor, CsbFormControl } from '@src/components'
import {
  createMailFromOptions,
  CsbMailFromSelect,
  getMalFromEmail,
  MailFromOption,
} from '@src/components/CsbMailFromSelect/CsbMailFromSelect'
import { useMailPlaceholderModal } from '@src/components/Modal/MailPlaceholderModal/MailPlaceholderModal'
import { REVEALING_MAIL_PLACEHOLDERS } from '@src/constants/mailPlaceholder'
import { BaseResponseError } from '@src/errors/BaseResponseError'
import {
  CreatePhishingEmailInput,
  PhishingEditFormQuery,
  UpdatePhishingEmailInput,
  UpdateRevealingPhishingEmailInput,
} from '@src/graphql/generated/graphql'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import { findError } from '@src/utils/findError'
import { useEffect, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types'

type MailFormsArray = PhishingEditFormQuery['mailFroms']['collection']

type RevealingPhishingFormPropsType = {
  errors: [BaseResponseError?]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<
    | CreatePhishingEmailInput
    | UpdatePhishingEmailInput
    | UpdateRevealingPhishingEmailInput
    | any
  >
  isLock?: boolean
  isWritePhishingMail: boolean
  loading?: boolean
  mailFroms?: MailFormsArray
}

const RevealingFromEmail = {
  // メールアドレス直接入力
  Email: 2,
  // 登録メールアドレスから選択
  FromId: 1,
  // 訓練メールと一緒
  None: 0,
}

export const RevealingPhishingForm = ({
  errors,
  form,
  isLock = false,
  isWritePhishingMail,
  loading,
  mailFroms,
}: RevealingPhishingFormPropsType) => {
  const { isDeliveryContentsOnly } = useDeliveryContents()
  const [MailRevealingModal, { onOpen: mailRevealingModalOnOpen }] =
    useMailPlaceholderModal(REVEALING_MAIL_PLACEHOLDERS)

  const revealingFromEmailType = (() => {
    const revealingPhishingEmailFromEmail = form.watch(
      'revealingPhishingEmailFromEmail'
    )
    const revealingPhishingEmailMailFromId = form.watch(
      'revealingPhishingEmailMailFromId'
    )
    if (revealingPhishingEmailFromEmail !== null) {
      return RevealingFromEmail.Email
    }
    if (
      revealingPhishingEmailMailFromId !== null &&
      revealingPhishingEmailMailFromId !== undefined
    ) {
      return RevealingFromEmail.FromId
    }
    return RevealingFromEmail.None
  })()

  const mailFromOptions: MailFromOption[] = useMemo(
    () => createMailFromOptions(mailFroms),
    [mailFroms]
  )

  // 種明かしメールの送信元アドレスタイプ変更時の処理
  const onChangeRevealingFromEmailType = (value: string) => {
    const revealingFromEmailType = Number(value)

    if (revealingFromEmailType === RevealingFromEmail.None) {
      form.setValue('revealingPhishingEmailFromName', null)
      form.setValue('revealingPhishingEmailMailFromId', null)
      form.setValue('revealingPhishingEmailFromEmail', null)
    } else if (revealingFromEmailType === RevealingFromEmail.FromId) {
      form.setValue(
        'revealingPhishingEmailMailFromId',
        mailFromOptions ? mailFromOptions[0].value : null
      )
      form.setValue('revealingPhishingEmailFromEmail', null)
    } else if (revealingFromEmailType === RevealingFromEmail.Email) {
      form.setValue('revealingPhishingEmailMailFromId', null)
      form.setValue('revealingPhishingEmailFromEmail', '')
    }
  }

  // 種明かしメールの送信元メールアドレスのエラー表示のための関数
  const getRevealingEmailFromEmailError = () => {
    if (revealingFromEmailType === RevealingFromEmail.FromId) {
      return findError(errors, 'revealingPhishingEmailMailFromId')?.message
    }
    if (revealingFromEmailType === RevealingFromEmail.Email) {
      return findError(errors, 'revealingPhishingEmailFromEmail')?.message
    }
    return ''
  }

  const mailFromLabel = useMemo(
    () =>
      getMalFromEmail(
        form.getValues('revealingPhishingEmailMailFromId'),
        mailFromOptions
      ),
    [form, mailFromOptions]
  )

  const [mailFrom, setMailFrom] = useState<MailFromOption | null>({
    label: '',
    value: '',
  })

  useEffect(() => {
    if (mailFromLabel) {
      setMailFrom({
        label: mailFromLabel,
        value: form.getValues('revealingPhishingEmailMailFromId'),
      })
    } else {
      setMailFrom(null)
    }
  }, [form, mailFromLabel, mailFromOptions])

  return (
    <>
      <CsbFormControl labelText={'送信元メールアドレス'} mt={4}>
        {!loading && (
          <RadioGroup
            isDisabled={isLock}
            value={revealingFromEmailType}
            onChange={onChangeRevealingFromEmailType}
          >
            <Flex gap={5}>
              <Radio
                disabled={!isWritePhishingMail}
                value={RevealingFromEmail.None}
              >
                {!isDeliveryContentsOnly
                  ? '訓練メールと同じものを使用'
                  : '配信メールと同じものを使用'}
              </Radio>
              <Radio
                disabled={!isWritePhishingMail}
                value={RevealingFromEmail.FromId}
              >
                選択
              </Radio>
              <Radio
                disabled={!isWritePhishingMail}
                value={RevealingFromEmail.Email}
              >
                入力
              </Radio>
            </Flex>
          </RadioGroup>
        )}
      </CsbFormControl>

      <CsbFormControl errorText={getRevealingEmailFromEmailError()}>
        <HStack spacing={2} w={'full'}>
          {revealingFromEmailType !== RevealingFromEmail.None && (
            <CsbFormControl
              errorText={
                findError(errors, 'revealingPhishingEmailFromName')?.message
              }
              labelText={'差出人名'}
            >
              {!loading && (
                <Input
                  {...form.register('revealingPhishingEmailFromName')}
                  disabled={isLock || !isWritePhishingMail}
                />
              )}
            </CsbFormControl>
          )}
          {revealingFromEmailType === RevealingFromEmail.FromId && (
            <CsbFormControl
              errorText={
                findError(errors, 'revealingPhishingEmailMailFromId')?.message
              }
              labelText={'メールアドレス'}
            >
              {!loading && (
                <CsbMailFromSelect
                  isDisabled={isLock || !isWritePhishingMail}
                  mailFrom={mailFrom}
                  mailFromOptions={mailFromOptions}
                  setMalFrom={(mailFrom) => {
                    setMailFrom(mailFrom)
                    form.setValue(
                      'revealingPhishingEmailMailFromId',
                      mailFrom?.value ?? ''
                    )
                  }}
                />
              )}
            </CsbFormControl>
          )}
          {revealingFromEmailType === RevealingFromEmail.Email && (
            <CsbFormControl labelText={'メールアドレス'}>
              {!loading && (
                <Input
                  {...form.register('revealingPhishingEmailFromEmail')}
                  disabled={isLock || !isWritePhishingMail}
                />
              )}
            </CsbFormControl>
          )}
        </HStack>
      </CsbFormControl>

      <CsbFormControl
        errorText={findError(errors, 'revealingPhishingEmailSubject')?.message}
        labelText={
          !isDeliveryContentsOnly
            ? '種明かしメールの件名'
            : 'リマインドメールの件名'
        }
      >
        <Controller
          control={form.control}
          name={'revealingPhishingEmailSubject'}
          render={({ field }) => (
            <Input
              // disabled={isLock}
              w={'full'}
              {...field}
              disabled={isLock || !isWritePhishingMail}
              value={field.value || ''}
            />
          )}
        />
      </CsbFormControl>
      <CsbFormControl
        errorText={findError(errors, 'revealingPhishingEmailHtml')?.message}
        labelText={
          <HStack>
            <Text disabled={isLock || !isWritePhishingMail}>
              {!isDeliveryContentsOnly
                ? '種明かしメールの文章'
                : 'リマインドメールの文章'}
            </Text>
            <chakra.button
              disabled={isLock || !isWritePhishingMail}
              type={'button'}
              onClick={mailRevealingModalOnOpen}
            >
              <QuestionOutlineIcon color={'teal.500'} />
            </chakra.button>
          </HStack>
        }
      >
        <Controller
          control={form.control}
          name={'revealingPhishingEmailHtml'}
          render={({ field: { onChange, value } }) => (
            <CsbEditor
              data={value ?? ''}
              disabled={isLock || !isWritePhishingMail}
              isHtmlLocked={
                !!form.getValues('isRevealingPhishingEmailHtmlLock')
              }
              onChangeHtml={onChange}
              onHtmlLocked={(locked) =>
                form.setValue('isRevealingPhishingEmailHtmlLock', locked)
              }
            />
          )}
        />
      </CsbFormControl>
      <MailRevealingModal />
    </>
  )
}
