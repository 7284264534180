import { Box, Flex, Input, Radio, RadioGroup } from '@chakra-ui/react'
import { CsbFormControl } from '@src/components'
import { ResearchAnswerType } from '@src/graphql/generated/graphql'
import {
  UseCreateAdminResearchQuestionResult,
  UseUpdateAdminResearchQuestionResult,
} from '@src/models/AdminResearch'
import { Controller } from 'react-hook-form'

export const AdminResearchQuestionForm = ({
  findErrorMessage,
  form,
}:
  | Pick<UseCreateAdminResearchQuestionResult, 'form' | 'findErrorMessage'>
  | Pick<
      UseUpdateAdminResearchQuestionResult,
      'form' | 'findErrorMessage'
    >) => (
  <>
    <CsbFormControl errorText={findErrorMessage('name')} labelText={'質問'}>
      <Input {...form.register('name')} />
    </CsbFormControl>
    <CsbFormControl
      errorText={findErrorMessage('answerType')}
      labelText={'回答方法'}
      mt={4}
    >
      <Controller
        control={form.control}
        name={'answerType'}
        render={({ field: { onChange, value } }) => (
          <RadioGroup value={`${value}`} onChange={onChange}>
            <Flex gap={5}>
              <Radio value={ResearchAnswerType.Checkbox}>複数選択可</Radio>
              <Radio value={ResearchAnswerType.Radio}>単一選択</Radio>
              <Radio value={ResearchAnswerType.Textarea}>自由入力</Radio>
            </Flex>
          </RadioGroup>
        )}
      />
    </CsbFormControl>
    {[ResearchAnswerType.Checkbox, ResearchAnswerType.Radio].includes(
      form.watch().answerType
    ) ? (
      <Box mt={4}>
        <CsbFormControl labelText={'選択肢1'}>
          <Input {...form.register('answers.1')} />
        </CsbFormControl>
        <CsbFormControl labelText={'選択肢2'} mt={4}>
          <Input {...form.register('answers.2')} />
        </CsbFormControl>
        <CsbFormControl labelText={'選択肢3'} mt={4}>
          <Input {...form.register('answers.3')} />
        </CsbFormControl>
        <CsbFormControl labelText={'選択肢4'} mt={4}>
          <Input {...form.register('answers.4')} />
        </CsbFormControl>
      </Box>
    ) : (
      <></>
    )}
  </>
)
