import { Box, Checkbox, Flex, Heading, Skeleton, Text } from '@chakra-ui/react'
import { CsbPagination, CsbTag, CsbUserAvatar } from '@src/components'
import { CsbCounter } from '@src/components/CsbCounter'
import { CsbTable } from '@src/components/CsbTable'
import { useRiskUsersHooks } from '@src/components/pages/phishing/[id]/result/components/RiskUsers/useRiskUsers'
import { usePhishingResultNewContentHooksResult } from '@src/components/pages/phishing/[id]/result/PhishingResultNewContent'
import { RiskUsers as RiskUsersModel } from '@src/models/Phishing/RiskUsers/RiskUsers'
import { percentage } from '@src/utils/percentage'
import { ComponentProps, useEffect } from 'react'

const IsDoneTag = ({
  isDone,
  ...props
}: { isDone: boolean } & ComponentProps<typeof CsbTag>) => {
  return (
    <CsbTag
      {...props}
      colorScheme={isDone ? 'teal' : 'red'}
      fontWeight={'bold'}
      minW={70}
      size={'md'}
    >
      {isDone ? '完了' : '未完了'}
    </CsbTag>
  )
}

export const RiskUsers = ({
  id,
  usePhishingResultContentsSelectResult,
}: usePhishingResultNewContentHooksResult) => {
  const { check, current, onChangePage, setCheck, useRiskUsersResult } =
    useRiskUsersHooks(id ?? '')

  const { refetch } = useRiskUsersResult
  useEffect(() => {
    refetch().then()
  }, [refetch, usePhishingResultContentsSelectResult.form.formState])

  return (
    <>
      <Flex>
        <Heading fontSize={16}>リスクのあるユーザー</Heading>
        <Checkbox
          isChecked={check}
          ml={'auto'}
          onChange={(e) => {
            setCheck(e.target.checked)
          }}
        >
          <Text fontSize={13}>ラーニング未完了のユーザーのみ表示</Text>
        </Checkbox>
      </Flex>
      <Box mb={'80px'} mt={35}>
        <CsbTable
          labels={[
            { label: '氏名' },
            { label: '' },
            { label: '社員番号' },
            { label: 'ラーニング' },
            { label: '正答数' },
            { label: '正答率' },
            { label: 'ID文字数' },
            { label: 'パスワード文字数' },
          ]}
        >
          <RiskUsersModel
            {...useRiskUsersResult}
            loadingFallback={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => (
              <CsbTable.TableRow key={`PhishingResultDetails_loading_${index}`}>
                <CsbTable.TableCell colSpan={8}>
                  <Skeleton h={12} />
                </CsbTable.TableCell>
              </CsbTable.TableRow>
            ))}
            noDataFallback={<>data nasi</>}
          >
            {(data) => (
              <>
                {data.riskUsers.collection.map(
                  ({ answeredAt, correct, phishingLoginLogs, quiz, user }) => (
                    <CsbTable.TableRow key={user.uuid}>
                      <CsbTable.TableCell colSpan={2}>
                        <CsbUserAvatar
                          description={user.email}
                          name={
                            user.lastName &&
                            user.firstName &&
                            `${user.lastName} ${user.firstName}`
                          }
                          src={user.avatarPath || undefined}
                        />
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {user.employeeNumber && <>{user.employeeNumber}</>}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        <IsDoneTag isDone={!!answeredAt} />
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {correct && quiz ? `${correct}/${quiz}` : '-'}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {correct && quiz
                          ? `${percentage(correct / quiz)}%`
                          : '-'}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {(phishingLoginLogs?.length ?? 0) > 0
                          ? phishingLoginLogs?.[0].loginIdCharacterCount
                          : '-'}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {(phishingLoginLogs?.length ?? 0) > 0 &&
                        (phishingLoginLogs?.[0].passwordCharacterCount ?? 0) > 0
                          ? phishingLoginLogs?.[0].passwordCharacterCount ?? 0
                          : '-'}
                      </CsbTable.TableCell>
                    </CsbTable.TableRow>
                  )
                )}
              </>
            )}
          </RiskUsersModel>
        </CsbTable>
        <RiskUsersModel
          {...useRiskUsersResult}
          loadingFallback={<Skeleton h={'52px'} mt={5} />}
        >
          {(data) => (
            <Flex mt={5}>
              <CsbPagination
                current={current}
                defaultCurrent={current}
                pageSize={data.riskUsers.metadata.limitValue}
                total={data.riskUsers.metadata.totalCount}
                onChange={onChangePage()}
              />
              <Box ml={'auto'}>
                <CsbCounter
                  data={{
                    ...data.riskUsers.metadata,
                    currentCount: data.riskUsers.collection.length,
                  }}
                />
              </Box>
            </Flex>
          )}
        </RiskUsersModel>
      </Box>

      {/*<Box mb={'80px'}>*/}
      {/*  <CsbTable*/}
      {/*    labels={[*/}
      {/*      '氏名',*/}
      {/*      '',*/}
      {/*      '社員番号',*/}
      {/*      'ラーニング',*/}
      {/*      '前回ラーニング',*/}
      {/*      '正答数',*/}
      {/*      '前回正答数',*/}
      {/*      '前回比較',*/}
      {/*    ]}*/}
      {/*  >*/}
      {/*    {_data.map((item) => (*/}
      {/*      <CsbTable.TableRow key={item.uuid}>*/}
      {/*        <CsbTable.TableCell colSpan={2}>*/}
      {/*          <CsbUserAvatar*/}
      {/*            name={*/}
      {/*              item.lastName &&*/}
      {/*              item.firstName &&*/}
      {/*              `${item.lastName} ${item.firstName}`*/}
      {/*            }*/}
      {/*            description={item.email}*/}
      {/*            src={item.avatarPath || undefined}*/}
      {/*          />*/}
      {/*        </CsbTable.TableCell>*/}
      {/*        <CsbTable.TableCell>*/}
      {/*          {item.employeeNumber && <>{item.employeeNumber}</>}*/}
      {/*        </CsbTable.TableCell>*/}
      {/*        <CsbTable.TableCell>*/}
      {/*          <IsDoneTag isDone={item.isLearning} />*/}
      {/*        </CsbTable.TableCell>*/}
      {/*        <CsbTable.TableCell>*/}
      {/*          <IsDoneTag isDone={item.isBeforeLearning} />*/}
      {/*        </CsbTable.TableCell>*/}
      {/*        <CsbTable.TableCell>*/}
      {/*          {item.correctAnswers && item.correctAnswers}*/}
      {/*        </CsbTable.TableCell>*/}
      {/*        <CsbTable.TableCell>*/}
      {/*          {item.correctBeforeAnswers && item.correctAnswers}*/}
      {/*        </CsbTable.TableCell>*/}
      {/*        <CsbTable.TableCell>*/}
      {/*          {item.comparison && item.comparison}*/}
      {/*        </CsbTable.TableCell>*/}
      {/*      </CsbTable.TableRow>*/}
      {/*    ))}*/}
      {/*  </CsbTable>*/}
      {/*</Box>*/}
    </>
  )
}
