import { useToast } from '@chakra-ui/react'
import { paginationInput } from '@src/constants'
import {
  CreateLearnInput,
  Learn as LearnType,
  useCreateLearnMutation,
  useDestroyLearnMutation,
  useLearnsQuery,
  useUpdateLearnCompaniesMutation,
  useUpdateLearnMutation,
} from '@src/graphql/generated/graphql'
import { useDeleteModal, useFormModal, useMe, usePagination } from '@src/hooks'
import { useCsbDataModal } from '@src/hooks/useCsbDataModal'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

//学習一覧で使うhooksのまとめ
export const useLearnHooks = () => {
  const { role } = useMe()
  const isWriteLearningText = !!role?.writeLearningText
  const navigate = useNavigate()
  const [destroyLearnMutation] = useDestroyLearnMutation()
  const [updateLearnMutation, { loading: isUpdating }] =
    useUpdateLearnMutation()
  const deleteModal = useDeleteModal()
  const [createLearn, { loading: isCreating }] = useCreateLearnMutation()
  const formModal = useFormModal<CreateLearnInput>({
    defaultValues: {
      description: '',
      name: '',
    },
  })

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    searchWord: withDefault(StringParam, ''),
  })
  //ページネーション
  const { current, onChangePage } = usePagination()

  const { data, refetch } = useLearnsQuery({
    variables: {
      pagination: paginationInput(current),
      searchWord: query.searchWord,
    },
  })

  //編集するModalを開くボタン
  const onUpdate = ({
    description,
    name,
    uuid,
  }: Pick<LearnType, 'name' | 'description' | 'uuid'>) => {
    formModal.onOpen()
    formModal.form.setValue('name', name)
    formModal.form.setValue('description', description)
    formModal.setUuid(uuid)
    formModal.setState('update')
  }

  //新規作成、編集のModalのformを送信する関数
  const onSubmit = formModal.form.handleSubmit(async (inputData) => {
    try {
      //typeを判定
      switch (formModal.state) {
        case 'create':
          //createの場合はcreateGroupにinputDataを渡す
          await createLearn({
            variables: {
              input: inputData,
            },
          })
          break
        case 'update':
          //createの場合はupdateGroupMutationにinputDataとuuidを渡す
          await updateLearnMutation({
            variables: {
              input: {
                ...inputData,
                uuid: formModal.uuid,
              },
            },
          })
          break
        //一応エラーを書く
        default:
          new Error(`${formModal.state} does not exist`)
      }

      //新規作成、編集をすればToastを発行する
      formModal.toast({
        status: 'success',
        title: formModal.isCreate
          ? `${inputData.name || '学習'}を登録しました。`
          : `${inputData.name || '学習'}を編集しました。`,
      })
      formModal.onCancel()
      /**
       * TODO anyを消す
       * e instanceOf ApolloError
       * setErrorsを変更する
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      formModal.setErrors(e.graphQLErrors)
      //新規作成、編集が失敗した場合のToast
      formModal.toast({
        status: 'error',
        title: formModal.isCreate
          ? `${inputData.name || '学習'}の作成に失敗しました。`
          : `${inputData.name || '学習'}の編集に失敗しました。`,
      })
    }
    await refetch()
  })

  // noinspection DuplicatedCode
  const dataModal = useCsbDataModal<{
    items: { id: number; name: string; uuid: string }[]
    title: string
  }>()

  //search機能
  const searchForm = useForm<{ searchWord: string }>({
    defaultValues: {
      searchWord: query.searchWord,
    },
  })
  const onSearch = searchForm.handleSubmit(async (inputData) => {
    setQuery({
      page: 1,
      searchWord: inputData.searchWord,
    })
  })
  const onResetSearch = () => {
    searchForm.setValue('searchWord', '')

    setQuery({
      page: 1,
      searchWord: '',
    })
  }

  const [updateLearnCompaniesMutation, { loading: isUpdatingLearnCompanies }] =
    useUpdateLearnCompaniesMutation()

  const toast = useToast()

  const onUpdateSelectCompanies = async (
    uuid: string,
    companyUuids: string[]
  ) => {
    try {
      await updateLearnCompaniesMutation({
        variables: {
          input: {
            companies: companyUuids,
            uuid: uuid,
          },
        },
      })
      toast({
        status: 'success',
        title: '学習コンテンツの対象企業を設定しました。',
      })
    } catch (e) {
      toast({
        status: 'error',
        title: '学習コンテンツの対象企業の設定に失敗しました。',
      })
    }
    await refetch()
  }

  return {
    current,
    data,
    dataModal,
    deleteModal,
    destroyLearnMutation,
    formModal,
    isCreating,
    isUpdating,
    isUpdatingLearnCompanies,
    isWriteLearningText,
    navigate,
    onChangePage,
    onResetSearch,
    onSearch,
    onSubmit,
    onUpdate,
    onUpdateSelectCompanies,
    refetch,
    searchForm,
  }
}

export type useLearnHooksResult = ReturnType<typeof useLearnHooks>
