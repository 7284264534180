import { useDashboard } from '@src/models/Dashboard'
import { format } from 'date-fns'
import moment from 'moment'
import { useState } from 'react'

export const useDashboardHooks = () => {
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment(new Date()).startOf('month')
  )
  const [endDate, setEndDate] = useState<moment.Moment>(moment(new Date()))
  const [focusedInput, setFocusedInput] = useState<
    'startDate' | 'endDate' | null
  >(null)
  const useDashboardResult = useDashboard({
    variables: {
      fromAt: format(new Date(startDate.format()), 'yyyy-MM-dd'),
      toAt: format(new Date(endDate.format()), 'yyyy-MM-dd'),
    },
  })

  return {
    dateRangePicker: {
      endDate,
      focusedInput,
      setEndDate,
      setFocusedInput,
      setStartDate,
      startDate,
    },
    useDashboardResult,
  }
}

export type useDashboardHooksResult = ReturnType<typeof useDashboardHooks>
