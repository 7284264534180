import { ComicsEdit } from '@src/components/pages/comics/edit'
import { useComicsEditHooks } from '@src/components/pages/comics/edit/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const ComicsEditPageChildren = memo(() => {
  const comicsEditHooks = useComicsEditHooks()
  return <ComicsEdit {...comicsEditHooks} />
})

export const ComicsEditPage = memo(() => {
  return (
    <Authorization>
      <DndProvider backend={HTML5Backend}>
        <ComicsEditPageChildren />
      </DndProvider>
    </Authorization>
  )
})
