import { useCsbVimeo } from '@src/components/CsbVimeo/CsbVimeo'
import { useLearningWithPreviewQuery } from '@src/graphql/generated/graphql'
import { useDeliveryContents } from '@src/hooks/useDeliveryContents'
import { useParams } from 'react-router-dom'

//学習の全体プレビューで使うhooksのまとめ
export const usePreviewLearningHooks = () => {
  //queryからidを抽出して返す
  const { id } = useParams<{ id: string }>()
  const { isDeliveryContentsOnly } = useDeliveryContents()
  const csbVimeo = useCsbVimeo()

  const { data, loading } = useLearningWithPreviewQuery({
    variables: {
      isDeliveryContentsOnly,
      uuid: id ?? '',
    },
  })

  return {
    csbVimeo,
    data,
    loading: loading,
  }
}

export type usePreviewLearnHooksResult = ReturnType<
  typeof usePreviewLearningHooks
>
