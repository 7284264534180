import { ApolloError } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  UpdateAdminResearchInput,
  UpdateAdminResearchMutationResult,
  useUpdateAdminResearchMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors, useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { formReset } from '@src/utils/formReset'
import { valueAsDate } from '@src/utils/input'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

export const useUpdateAdminResearch = ({
  onCompleted,
  onError,
}: Partial<
  BaseOptionsType<UpdateAdminResearchMutationResult['data']>
> = {}) => {
  const toast = useToast()
  const [updateAdminResearch, { loading: isLoading }] =
    useUpdateAdminResearchMutation({
      refetchQueries: ['adminResearches'],
    })
  const form = useForm<
    Omit<UpdateAdminResearchInput, 'clientMutationId' | 'uuid'>
  >({
    resolver: zodResolver(
      z.object({
        closedAt: z.preprocess((value) => valueAsDate(value), z.date()),
        description: z.any(),
        name: z.any(),
        publishedAt: z.preprocess((value) => valueAsDate(value), z.date()),
      })
    ),
  })
  const { findErrorMessage, resetErrors, setErrors } =
    useGraphQLErrors<
      keyof Omit<UpdateAdminResearchInput, 'clientMutationId' | 'uuid'>
    >()
  const resetForm = () => formReset(form, resetErrors)

  const onUpdateAdminResearch = useCallback(
    (uuid: UpdateAdminResearchInput['uuid']) =>
      form.handleSubmit(async (inputData) => {
        try {
          const { data } = await updateAdminResearch({
            variables: {
              input: { ...inputData, uuid },
            },
          })
          formReset(form, resetErrors)
          onCompleted?.(data)
          toast({
            status: 'success',
            title: 'アンケートの更新に成功しました。',
          })
        } catch (e) {
          toast({
            status: 'error',
            title: 'アンケートの更新に失敗しました。',
          })
          if (e instanceof ApolloError) {
            setErrors(e.graphQLErrors)
            onError?.(e)
          }
        }
      }),
    [
      form,
      setErrors,
      resetErrors,
      toast,
      onCompleted,
      onError,
      updateAdminResearch,
    ]
  )

  return { findErrorMessage, form, isLoading, onUpdateAdminResearch, resetForm }
}

type UseUpdateAdminResearchType = typeof useUpdateAdminResearch
export type UseUpdateAdminResearchResult =
  ReturnType<UseUpdateAdminResearchType>
// export type UseUpdateAdminResearchParameter = Parameters<UseUpdateAdminResearchType>[0]
