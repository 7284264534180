import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  ModalCloseButton,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  CsbDeleteButton,
  CsbDeleteModal,
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
  CsbNextLink,
  CsbPagination,
  CsbSearchInput,
} from '@src/components'
import { CsbEditButton } from '@src/components/Buttons/CsbEditButton'
import { CsbTable } from '@src/components/CsbTable'
import { useCompaniesHooksResult } from '@src/components/pages/companies/hooks'
import { Base } from '@src/layouts'
import { getActiveCompanyMailLimitStrings } from '@src/utils/companyMailLiimit'
import dayjs from 'dayjs'
import { memo } from 'react'

//企業詳細ページのcomponent
export const Companies = memo(
  ({
    company,
    companyName,
    current,
    data,
    deleteModal,
    destroyCompany,
    isWriteCompany,
    navigate,
    onChangePage,
    onResetSearch,
    onSearch,
    onSwitchCompany,
    refetch,
    searchForm,
    switchCompanyModal,
  }: useCompaniesHooksResult) => {
    return (
      <>
        <Base
          left={<Heading>企業アカウント</Heading>}
          right={
            isWriteCompany && (
              <CsbNextLink href={`/companies/new`}>
                <Button as={'div'} w={210}>
                  新しい企業アカウントの作成
                </Button>
              </CsbNextLink>
            )
          }
        >
          <Box maxW={518} mt={12} w={'full'}>
            <CsbSearchInput
              placeholder={'企業名を入力'}
              {...searchForm.register('searchWord')}
              onReset={onResetSearch}
              onSearch={onSearch}
            />
          </Box>
          <CsbTable
            labels={[
              {
                label: '企業名',
                width: 300,
              },
              {
                label: '企業URL',
                width: 300,
              },
              {
                isNumeric: true,
                label: '最大ユーザー数',
                width: 250,
              },
              {
                isNumeric: true,
                label: '最大送信数',
                width: 300,
              },
              {
                isNumeric: true,
                label: '有効期限',
                width: 200,
              },

              {
                label: '',
              },
            ]}
            wrapperProps={{ mt: 35, whiteSpace: 'nowrap' }}
          >
            {data?.companies.collection.map((item) => (
              <CsbTable.TableRow
                key={item.uuid}
                onClick={
                  company?.uuid !== item.uuid
                    ? () => onSwitchCompany(item.uuid)
                    : undefined
                }
              >
                <CsbTable.TableCell>
                  <Tooltip aria-label="A tooltip" label={item.name}>
                    <Text isTruncated fontWeight={'bold'} maxW={300}>
                      {item.name}
                    </Text>
                  </Tooltip>
                </CsbTable.TableCell>
                <CsbTable.TableCell>
                  <Tooltip aria-label="A tooltip" label={item.domain}>
                    <Text
                      isTruncated
                      color={'gray.500'}
                      fontSize={'sm'}
                      maxW={300}
                    >
                      {item.domain}
                    </Text>
                  </Tooltip>
                </CsbTable.TableCell>
                <CsbTable.TableCell isNumeric>
                  {item.currentUserCount?.toLocaleString()}/
                  {item.maxUserCount?.toLocaleString() ?? '無制限'}
                </CsbTable.TableCell>
                <CsbTable.TableCell isNumeric>
                  {item.companyMailLimits.length === 0
                    ? `月間最大送信数：${item.currentMonthlyMailCount?.toLocaleString()}/${
                        item.maxMonthlyMailCount?.toLocaleString() ?? '無制限'
                      }`
                    : getActiveCompanyMailLimitStrings(
                        item.companyMailLimits
                      ).map((limit, index) => (
                        <Text fontSize={'md'} key={index}>
                          {limit}
                        </Text>
                      ))}
                </CsbTable.TableCell>
                <CsbTable.TableCell
                  isNumeric
                  color={item.isActive ? undefined : 'red.500'}
                >
                  {item.expiresAt
                    ? dayjs(item.expiresAt).format('YYYY/MM/DD')
                    : '期限なし'}
                </CsbTable.TableCell>
                <CsbTable.TableCell isNumeric>
                  {isWriteCompany && (
                    <Flex gridGap={5} justifyContent={'end'}>
                      <CsbEditButton
                        onClick={(e) => {
                          e.stopPropagation()
                          navigate(`/companies/${item.uuid}/edit`)
                        }}
                      />
                      {!item.protected && company?.uuid !== item.uuid && (
                        <CsbDeleteButton
                          onClick={(e) => {
                            e.stopPropagation()
                            deleteModal.onOpen(item.uuid, item.name)
                          }}
                        />
                      )}
                    </Flex>
                  )}
                </CsbTable.TableCell>
              </CsbTable.TableRow>
            ))}
          </CsbTable>
          <Flex mt={5}>
            {data ? (
              <CsbPagination
                current={current}
                defaultCurrent={current}
                pageSize={data.companies.metadata.limitValue}
                total={data.companies.metadata.totalCount}
                onChange={onChangePage('/companies')}
              />
            ) : null}
            <HStack ml={'auto'} spacing={3}>
              {isWriteCompany && (
                <CsbNextLink href={`/companies/new`}>
                  <Button as={'div'} w={210}>
                    新しい企業アカウントの作成
                  </Button>
                </CsbNextLink>
              )}
            </HStack>
          </Flex>
          <CsbDeleteModal
            header={deleteModal.target}
            isOpen={deleteModal.isOpen}
            onCancel={deleteModal.onClose}
            onClose={deleteModal.onClose}
            onDelete={() =>
              deleteModal.onDelete(
                destroyCompany({
                  variables: {
                    input: { uuid: deleteModal.uuid ?? '' },
                  },
                }),
                async () => {
                  const { data } = await refetch()
                  return {
                    metadata: {
                      currentPage: data.companies.metadata.currentPage,
                      limitValue: data.companies.metadata.limitValue,
                      totalCount: data.companies.metadata.totalCount,
                    },
                  }
                },
                onChangePage('/companies')
              )
            }
          />
        </Base>
        <CsbModal
          isOpen={switchCompanyModal.isOpen}
          onClose={switchCompanyModal.onClose}
        >
          <CsbModalHeader>企業を切り替えました</CsbModalHeader>
          <ModalCloseButton />
          <CsbModalBody mt={5}>
            「{companyName}」にログインしました。
          </CsbModalBody>
          <CsbModalFooter mt={6}>
            <Button colorScheme={'teal'} onClick={switchCompanyModal.onClose}>
              閉じる
            </Button>
          </CsbModalFooter>
        </CsbModal>
      </>
    )
  }
)
