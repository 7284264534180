import {
  Button,
  Flex,
  Heading,
  HStack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
  CsbPagination,
} from '@src/components'
import { CsbTable } from '@src/components/CsbTable'
import { useUserLockLogsHooksResult } from '@src/components/pages/user-lock-logs/hooks'
import { UserLock } from '@src/graphql/generated/graphql'
import { Base } from '@src/layouts'
import dayjs from 'dayjs'
import { memo } from 'react'

//学習一覧ページのcomponent
export const UserLockLogs = memo(
  ({
    current,
    data,
    form,
    isUnlocking,
    onChangePage,
    onSubmit,
  }: useUserLockLogsHooksResult) => {
    const forceUnlockModal = useDisclosure()

    return (
      <Base left={<Heading>ユーザーロックログ</Heading>}>
        <CsbTable
          labels={[
            '企業名',
            'ユーザー名',
            'メールアドレス',
            '状態',
            '日時',
            '',
          ]}
          wrapperProps={{ mt: 35 }}
        >
          {data?.userLockLogs.collection.map((item, index) => (
            <CsbTable.TableRow key={index}>
              <CsbTable.TableCell w={200}>
                <Tooltip aria-label="A tooltip" label={item.user.company.name}>
                  <Text isTruncated w={200}>
                    {item.user.company.name}
                  </Text>
                </Tooltip>
              </CsbTable.TableCell>
              <CsbTable.TableCell w={200}>
                <Tooltip
                  aria-label="A tooltip"
                  label={item.user.lastName + item.user.firstName}
                >
                  <Text isTruncated w={200}>
                    {item.user.lastName + item.user.firstName}
                  </Text>
                </Tooltip>
              </CsbTable.TableCell>
              <CsbTable.TableCell w={200}>
                <Tooltip aria-label="A tooltip" label={item.user.email}>
                  <Text isTruncated w={200}>
                    {item.user.email}
                  </Text>
                </Tooltip>
              </CsbTable.TableCell>

              <CsbTable.TableCell w={100}>
                <Text fontSize={'sm'} w={100}>
                  {item.lockType === UserLock.Locked ? 'ロック' : 'ロック解除'}
                </Text>
              </CsbTable.TableCell>
              <CsbTable.TableCell w={130}>
                <Text fontSize={'sm'} w={130}>
                  {dayjs(item.createdAt).format('YYYY/MM/DD HH:mm')}
                </Text>
              </CsbTable.TableCell>

              <CsbTable.TableCell isNumeric>
                <Flex gridGap={5} justifyContent={'end'}>
                  {item.lockType === UserLock.Locked &&
                    item.user.unlockToken === item.unlockToken && (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation()
                          form.setValue('userUuid', item.user.uuid)
                          forceUnlockModal.onOpen()
                        }}
                      >
                        解除
                      </Button>
                    )}
                </Flex>
              </CsbTable.TableCell>
            </CsbTable.TableRow>
          ))}
        </CsbTable>
        <Flex mt={5}>
          {data ? (
            <CsbPagination
              current={current}
              defaultCurrent={current}
              pageSize={data.userLockLogs.metadata.limitValue}
              total={data.userLockLogs.metadata.totalCount}
              onChange={onChangePage('/user_lock_logs')}
            />
          ) : null}
        </Flex>

        <CsbModal
          isOpen={forceUnlockModal.isOpen}
          onClose={forceUnlockModal.onClose}
        >
          <CsbModalHeader>確認</CsbModalHeader>
          <CsbModalBody mt={4}>
            <Text fontSize={'sm'}>ロックを強制解除しますか？</Text>
          </CsbModalBody>
          <CsbModalFooter mt={7}>
            <HStack spacing={6}>
              <Button colorScheme={'gray'} onClick={forceUnlockModal.onClose}>
                キャンセル
              </Button>
              <Button
                isLoading={isUnlocking}
                onClick={() => {
                  onSubmit().then(() => forceUnlockModal.onClose())
                }}
              >
                ロック解除
              </Button>
            </HStack>
          </CsbModalFooter>
        </CsbModal>
      </Base>
    )
  }
)
