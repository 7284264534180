import { useDisclosure } from '@chakra-ui/react'
import { ResearchesQuery } from '@src/graphql/generated/graphql'
import { useUpdateResearch } from '@src/models/Research'
import { useCallback, useState } from 'react'

type Research = ResearchesQuery['researches']['collection'][number]

export const useUpdateResearchModal = () => {
  const [research, setResearch] = useState<Research>()
  const { isOpen, onClose: _onClose, onOpen: _onOpen } = useDisclosure()
  const {
    findErrorMessage,
    form,
    isLoading,
    onUpdateResearch: _onUpdateResearch,
    resetForm,
  } = useUpdateResearch({
    onCompleted: () => {
      _onClose()
    },
  })
  const onClose = useCallback(() => {
    resetForm()
    _onClose()
  }, [_onClose, resetForm])

  const onOpen = useCallback(
    (research: Research) => {
      _onOpen()
      setResearch(research)
      form.setValue('name', research.name)
      form.setValue('description', research.description)
      if (research.closedAt) {
        form.setValue('closedAt', research.closedAt)
      }
    },
    [form, _onOpen, setResearch]
  )

  const onUpdateResearch = useCallback(() => {
    if (!research) return
    _onUpdateResearch(research.uuid)()
  }, [research, _onUpdateResearch])

  return {
    findErrorMessage,
    form,
    isLoading,
    isOpen,
    onClose,
    onOpen,
    onUpdateResearch,
  }
}

type useUpdateResearchModalType = typeof useUpdateResearchModal
export type UseUpdateResearchModalResult =
  ReturnType<useUpdateResearchModalType>
// export type useUpdateResearchModalParameter = Parameters<useUpdateResearchModalType>[0]
