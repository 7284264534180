import { usePagination } from '@src/hooks'
import { useRiskUsers } from '@src/models/Phishing/RiskUsers/useRiskUsers'
import { useState } from 'react'

export const useRiskUsersHooks = (id: string) => {
  //ページネーション
  const { current, onChangePage } = usePagination()
  const [check, setCheck] = useState(false)
  const useRiskUsersResult = useRiskUsers({
    variables: {
      pagination: {
        limit: 10,
        page: current,
      },
      riskUserOnly: check,
      uuid: id,
    },
  })

  return {
    check,
    current,
    onChangePage,
    setCheck,
    useRiskUsersResult,
  }
}

export type useRiskUsersHooksResult = ReturnType<typeof useRiskUsersHooks>
