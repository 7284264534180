import { CreateMailTemplateInput } from '@src/graphql/generated/graphql'

export const templateFormDefaultValues: CreateMailTemplateInput = {
  body: '',
  draft: true,
  fromName: '',
  isPhishingSiteDirectAccess: false,
  isSendLearningMail: true,
  mailFromId: '',
  name: '',
  phishingLinkLabel: '',
  subject: '',
}
