import { Movies } from '@src/components/pages/movies'
import { useMoviesHooks } from '@src/components/pages/movies/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const MoviesPage = memo(() => {
  const moviesHooks = useMoviesHooks()
  return (
    <Authorization>
      <Movies {...moviesHooks} />
    </Authorization>
  )
})
