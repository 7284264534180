import { useCreateS3PresignedUrlMutationMutation } from '@src/graphql/generated/graphql'
import axios from 'axios'
import { useState } from 'react'

type useFileUploadType = (
  savePath: string,
  isPrivate?: boolean
) => {
  error: unknown
  loading: boolean
  onUpload: (file: File) => Promise<string>
  uploadUrl: string
}

//画像をアップロードするhooks
export const useFileUpload: useFileUploadType = (
  savePath: string,
  isPrivate = false
) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [uploadUrl] = useState<string>('')
  const [createS3PresignedUrlMutationMutation] =
    useCreateS3PresignedUrlMutationMutation()

  //errorのstate
  const [error] = useState<unknown | null>()

  const onUpload = async (file: File) => {
    setLoading(true)

    const result = await createS3PresignedUrlMutationMutation({
      variables: {
        input: {
          fileName: file.name,
          isPrivate: isPrivate,
          savePath: savePath,
        },
      },
    })

    const options = {
      headers: {
        'Content-Type': file.type,
      },
    }

    const res = await axios.put(
      result.data?.createS3PresignedUrlMutation?.presignedUrl as string,
      file,
      options
    )

    setLoading(false)

    if (res.status === 200) {
      return result.data?.createS3PresignedUrlMutation?.uploadUrl as string
    }
    return ''
  }

  return {
    error,
    loading,
    onUpload,
    uploadUrl,
  }
}
