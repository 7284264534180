import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { CsbDeleteButton, CsbPagination, CsbSearchInput } from '@src/components'
import { CsbEditButton } from '@src/components/Buttons/CsbEditButton'
import { CsbTable } from '@src/components/CsbTable'
import { useAdminResearchesHooks } from '@src/components/pages/admin-researches/hooks'
import { Base } from '@src/layouts'
import {
  AdminResearchesQuery,
  CreateAdminResearchModal,
  DestroyAdminResearchModal,
} from '@src/models/AdminResearch'
import { UpdateAdminResearchModal } from '@src/models/AdminResearch'
import { format } from 'date-fns'
import { memo } from 'react'

//フィシイング一覧ページのcomponent
export const AdminResearches = memo(() => {
  const {
    createAdminResearchModal,
    current,
    destroyAdminResearchModal,
    isWriteAdminResearch,
    navigate,
    onChangePage,
    onResetSearch,
    onSearch,
    researches,
    searchForm,
    updateAdminResearchModal,
  } = useAdminResearchesHooks()
  return (
    <Base
      left={<Heading>共通アンケート管理</Heading>}
      right={
        isWriteAdminResearch && (
          <Button w={210} onClick={createAdminResearchModal.onOpen}>
            共通アンケートの作成
          </Button>
        )
      }
    >
      <Box maxW={518} mt={12} w={'full'}>
        <CsbSearchInput
          placeholder={'共通アンケート名を入力'}
          {...searchForm.register('searchWord')}
          onReset={onResetSearch}
          onSearch={onSearch}
        />
      </Box>
      <AdminResearchesQuery {...researches}>
        {({ adminResearches }) => (
          <>
            <CsbTable
              labels={['共通アンケート名', '説明', '開始日', '終了日', '']}
              wrapperProps={{ mt: 35 }}
            >
              {adminResearches.collection.map((adminResearch) => (
                <CsbTable.TableRow
                  key={adminResearch.uuid}
                  onClick={() =>
                    navigate(`/admin-researches/${adminResearch.uuid}/edit`)
                  }
                >
                  <CsbTable.TableCell w={300}>
                    <Tooltip aria-label="A tooltip" label={adminResearch.name}>
                      <Text isTruncated fontWeight={'bold'} w={300}>
                        {adminResearch.name}
                      </Text>
                    </Tooltip>
                  </CsbTable.TableCell>
                  <CsbTable.TableCell w={300}>
                    <Tooltip
                      aria-label="A tooltip"
                      label={adminResearch.description}
                    >
                      <Text
                        isTruncated
                        color={'gray.500'}
                        fontSize={'sm'}
                        w={300}
                      >
                        {adminResearch.description}
                      </Text>
                    </Tooltip>
                  </CsbTable.TableCell>
                  <CsbTable.TableCell w={200}>
                    <Text
                      color={'gray.500'}
                      fontSize={'sm'}
                      whiteSpace={'nowrap'}
                    >
                      {format(
                        new Date(adminResearch.publishedAt),
                        'yyyy年MM月dd日'
                      )}
                    </Text>
                  </CsbTable.TableCell>
                  <CsbTable.TableCell w={200}>
                    <Text
                      color={'gray.500'}
                      fontSize={'sm'}
                      whiteSpace={'nowrap'}
                    >
                      {format(
                        new Date(adminResearch.closedAt),
                        'yyyy年MM月dd日'
                      )}
                    </Text>
                  </CsbTable.TableCell>
                  <CsbTable.TableCell isNumeric>
                    {isWriteAdminResearch && (
                      <Flex gridGap={5} justifyContent={'end'}>
                        <CsbEditButton
                          onClick={(e) => {
                            e.stopPropagation()
                            updateAdminResearchModal.onOpen(adminResearch)
                          }}
                        />
                        <CsbDeleteButton
                          onClick={(e) => {
                            e.stopPropagation()
                            destroyAdminResearchModal.onOpen(adminResearch)
                          }}
                        />
                      </Flex>
                    )}
                  </CsbTable.TableCell>
                </CsbTable.TableRow>
              ))}
            </CsbTable>
            <Flex mt={5}>
              <CsbPagination
                current={current}
                defaultCurrent={current}
                pageSize={adminResearches.metadata.limitValue}
                total={adminResearches.metadata.totalCount}
                onChange={onChangePage()}
              />
              <HStack ml={'auto'} spacing={3}>
                {isWriteAdminResearch && (
                  <Button w={210} onClick={createAdminResearchModal.onOpen}>
                    共通アンケートの作成
                  </Button>
                )}
              </HStack>
            </Flex>
          </>
        )}
      </AdminResearchesQuery>
      <UpdateAdminResearchModal {...updateAdminResearchModal} />
      <CreateAdminResearchModal {...createAdminResearchModal} />
      <DestroyAdminResearchModal {...destroyAdminResearchModal} />
    </Base>
  )
})
