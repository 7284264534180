import { useBoolean } from '@chakra-ui/react'
import {
  useAnswerQuestionnaire,
  useQuestionnaire,
} from '@src/models/Questionnaire'
import { useResearchQuestionsByResearchUuid } from '@src/models/Research/useResearchQuestionsByResearchUuid'
import { useParams } from 'react-router-dom'

export const useQuestionnaireAnswerHooks = () => {
  const { id } = useParams<{ id: string }>()

  const questionnaire = useQuestionnaire({
    skip: !id,
    variables: {
      uuid: id ?? '',
    },
  })

  const researchQuestions = useResearchQuestionsByResearchUuid({
    variables: {
      researchUuid: questionnaire.data?.questionnaire?.research.uuid ?? '',
    },
  })

  const answerQuestionnaire = useAnswerQuestionnaire()
  const hasResearch =
    researchQuestions.data?.researchQuestionsByResearchUuid.length ?? 0 > 0

  const isResearchLoading = questionnaire.loading || researchQuestions.loading
  const [isSend, { on: setIsSend }] = useBoolean(false)

  // undefinedの場合、空配列を設定する
  researchQuestions.data?.researchQuestionsByResearchUuid.forEach(
    (researchQuestion, index) => {
      if (
        answerQuestionnaire.form.getValues(`answers.${index}`) === undefined
      ) {
        answerQuestionnaire.form.setValue(`answers.${index}`, [])
      }
    }
  )

  const isDisplayLogoInResearch =
    questionnaire.data?.questionnaire?.research.company.setting
      .isDisplayLogoInResearch ?? true

  return {
    answerQuestionnaire,
    hasResearch,
    id,
    isDisplayLogoInResearch,
    isResearchLoading,
    isSend,
    researchQuestions,
    setIsSend,
  }
}

export type useQuestionnaireAnswerHooksResult = ReturnType<
  typeof useQuestionnaireAnswerHooks
>
