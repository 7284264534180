export const PHISHING_MAIL_PLACEHOLDERS = [
  {
    description: '対象者の企業名を表示',
    example: 'サイバーセキュリティーバンク',
    placeholder: '{{company_name}}',
  },
  {
    description: '対象者のフルネームを表示',
    example: '田中 太郎',
    placeholder: '{{full_name}}',
  },
  {
    description: '対象者の名前を表示',
    example: '太郎',
    placeholder: '{{first_name}}',
  },
  {
    description: '対象者の名字を表示',
    example: '田中',
    placeholder: '{{last_name}}',
  },
  {
    description: '対象者の役職を表示',
    example: '部長',
    placeholder: '{{position_name}}',
  },
  {
    description: '対象者のメールアドレスを表示',
    example: 'tanaka@fusegu.cloud',
    placeholder: '{{email}}',
  },
  {
    description: '対象者のメールアドレス(ローカルパート)を表示',
    example: 'tanaka',
    placeholder: '{{local_part}}',
  },
  {
    description: 'メールを送信した日付を表示',
    example: '2022年01月01日',
    placeholder: '{{send_date}}',
  },
  {
    description: 'メールを送信した日付に任意の日数を加算して表示',
    example: '2022年01月02日',
    placeholder: '{{send_date+1}}',
  },
  {
    description: '対象者の所属する組織を表示',
    example: '総務部',
    placeholder: '{{organization}}',
  },
  {
    description: 'フィッシングサイトへのリンク',
    example: '指定した文字列が挿入されます',
    placeholder: '{{link}}',
  },
  {
    description: 'フィッシングサイトへのリンク',
    example: 'フィッシングサイトのURLが挿入されます。',
    placeholder: '{{phishing_url}}',
  },
  {
    description: 'ラーニングページへのリンク',
    example: 'https://fusegu.cloud/learning/***',
    placeholder: '{{learning_url}}',
  },
  {
    description: 'ラーニングページへのリンク',
    example: 'https://fusegu.cloud/learning/***',
    placeholder: '{{url}}',
  },
  {
    description: '添付ファイル・パスワード',
    example: 'password',
    placeholder: '{{archive_password}}',
  },
]

export const REVEALING_MAIL_PLACEHOLDERS = [
  {
    description: '対象者の企業名を表示',
    example: 'サイバーセキュリティーバンク',
    placeholder: '{{company_name}}',
  },
  {
    description: '対象者のフルネームを表示',
    example: '田中 太郎',
    placeholder: '{{full_name}}',
  },
  {
    description: '対象者の名前を表示',
    example: '太郎',
    placeholder: '{{first_name}}',
  },
  {
    description: '対象者の名字を表示',
    example: '田中',
    placeholder: '{{last_name}}',
  },
  {
    description: '対象者の役職を表示',
    example: '部長',
    placeholder: '{{position_name}}',
  },
  {
    description: '対象者のメールアドレスを表示',
    example: 'tanaka@fusegu.cloud',
    placeholder: '{{email}}',
  },
  {
    description: '対象者のメールアドレス(ローカルパート)を表示',
    example: 'tanaka',
    placeholder: '{{local_part}}',
  },
  {
    description: 'メールを送信した日付を表示',
    example: '2022年01月01日',
    placeholder: '{{send_date}}',
  },
  {
    description: 'メールを送信した日付に任意の日数を加算して表示',
    example: '2022年01月02日',
    placeholder: '{{send_date+1}}',
  },
  {
    description: '対象者の所属する組織を表示',
    example: '総務部',
    placeholder: '{{organization}}',
  },
  {
    description: 'フィッシングメールの件名を表示',
    example: 'フィッシングメールタイトル',
    placeholder: '{{phishing_mail_subject}}',
  },
  {
    description: 'ラーニングページへのリンク',
    example: 'https://fusegu.cloud/learning/***',
    placeholder: '{{learning_url}}',
  },
  {
    description: 'ラーニングページへのリンク',
    example: 'https://fusegu.cloud/learning/***',
    placeholder: '{{link}}',
  },
  {
    description: 'ラーニングページへのリンク',
    example: 'https://fusegu.cloud/learning/***',
    placeholder: '{{url}}',
  },
]
