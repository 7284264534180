import { useDisclosure } from '@chakra-ui/react'
import { ResearchesQuery } from '@src/graphql/generated/graphql'
import { useDestroyResearch } from '@src/models/Research'
import { useCallback, useState } from 'react'

type Research = ResearchesQuery['researches']['collection'][number]

export const useDestroyResearchModal = () => {
  const [research, setResearch] = useState<Research>()
  const { isOpen, onClose: _onClose, onOpen: _onOpen } = useDisclosure()
  const { isLoading, onDestroyResearch: _onDestroyResearch } =
    useDestroyResearch({
      onCompleted: () => {
        _onClose()
      },
    })
  const onClose = useCallback(() => {
    _onClose()
  }, [_onClose])

  const onOpen = useCallback(
    (research: Research) => {
      _onOpen()
      setResearch(research)
    },
    [_onOpen, setResearch]
  )

  const onDestroyResearch = useCallback(() => {
    if (!research) return
    _onDestroyResearch(research.uuid)
  }, [research, _onDestroyResearch])

  return {
    isLoading,
    isOpen,
    onClose,
    onDestroyResearch,
    onOpen,
    research,
  }
}

type useDestroyResearchModalType = typeof useDestroyResearchModal
export type useDestroyResearchModalResult =
  ReturnType<useDestroyResearchModalType>
// export type useDestroyResearchModalParameter = Parameters<useDestroyResearchModalType>[0]
