import '@react-pdf-viewer/core/lib/styles/index.css'

import {
  Box,
  Button,
  Center,
  Grid,
  Image,
  RadioGroup,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { LocalizationMap, Viewer } from '@react-pdf-viewer/core'
import jp_JP from '@react-pdf-viewer/locales/lib/jp_JP.json'
import { CsbHeader } from '@src/components'
import { CsbNewLineText } from '@src/components/CsbNewLineText'
import { CsbQuestionRadio } from '@src/components/CsbQuestionRadio'
import { CsbVimeo } from '@src/components/CsbVimeo/CsbVimeo'
import Embed from '@src/components/Embed'
import { usePreviewLearningHooks } from '@src/components/pages/preview/learning/[id]/hooks'
import {
  Correct,
  Question as QuestionType,
} from '@src/graphql/generated/graphql'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type QuestionPropsType = {
  index: number
  question: Pick<
    QuestionType,
    'uuid' | 'name' | 'answerType' | 'explanation'
  > & {
    corrects: Pick<Correct, 'name' | 'uuid'>[]
  }
}

// noinspection DuplicatedCode
const Question = ({ question }: QuestionPropsType) => {
  //現在のラジオボタンの値を管理するstate
  const [value, setValue] = useState<string | null>(null)

  //radioボタンが変わるたびにvalueを更新する
  const onChange = (nextValue: string) => {
    setValue(nextValue)
  }

  return (
    <Box>
      <Text fontWeight={'bold'} mb={3}>
        <CsbNewLineText text={question.name} />
      </Text>
      <RadioGroup
        style={{ display: 'flex', flexDirection: 'column' }}
        value={value ?? ''}
        onChange={onChange}
      >
        <Grid gap={4} gridColumn={'1fr'}>
          {question.corrects.map((correct) => (
            <CsbQuestionRadio
              isChecked={value === correct.uuid}
              key={correct.uuid}
              value={correct.uuid}
            >
              {correct.name}
            </CsbQuestionRadio>
          ))}
        </Grid>
      </RadioGroup>
    </Box>
  )
}

//学習ページのcomponent
export const PreviewLearning = memo(() => {
  const { csbVimeo, data, loading } = usePreviewLearningHooks()

  const { t } = useTranslation()
  useEffect(() => {
    if (data?.phishingEmail.originalLearningUrl) {
      window.location.href = data?.phishingEmail.originalLearningUrl
    }
  }, [data?.phishingEmail.originalLearningUrl])

  return (
    <>
      {data &&
      !loading &&
      !data?.phishingEmail.originalLearningUrl &&
      data.phishingEmail.isDisplayLogoInLearning ? (
        <CsbHeader />
      ) : (
        ''
      )}

      <Box maxW={'650px'} mx={'auto'} px={'15px'} py={20}>
        <Grid rowGap={10}>
          <Box
            dangerouslySetInnerHTML={{
              __html: data?.phishingEmail.learningContentComment ?? '',
            }}
          />

          {data && !loading && !data?.phishingEmail.originalLearningUrl && (
            <>
              {data.phishingEmail.movie && (
                <CsbVimeo video={data.phishingEmail.movie.url} {...csbVimeo} />
              )}
              {data.phishingEmail.comic && (
                <Box>
                  <Grid rowGap={10}>
                    {data.phishingEmail.comic.images.map((image, index) => (
                      <Box key={`${image.uuid}_${index}`}>
                        <Image
                          fallback={
                            <Center bgColor={'gray.100'} h={520}>
                              <Box h={4} w={4}>
                                <Spinner
                                  color="blue.500"
                                  emptyColor="gray.200"
                                  size="xl"
                                  speed="0.65s"
                                  thickness="4px"
                                />
                              </Box>
                            </Center>
                          }
                          src={image.url}
                          w={'full'}
                        />
                      </Box>
                    ))}
                  </Grid>
                </Box>
              )}

              {data.phishingEmail.pdf?.url ? (
                <Box>
                  <Box borderWidth={1} height={600} mb={4}>
                    <Viewer
                      fileUrl={data.phishingEmail.pdf?.url}
                      localization={jp_JP as unknown as LocalizationMap}
                    />
                  </Box>
                  <Center>
                    <Button
                      variant="outline"
                      onClick={() => {
                        window.open(data.phishingEmail.pdf?.url, '_blank')
                      }}
                    >
                      PDFをダウンロード
                    </Button>
                  </Center>
                </Box>
              ) : (
                ''
              )}

              {data.phishingEmail.pdfLink ? (
                <Embed html={data.phishingEmail.pdfLink} />
              ) : (
                ''
              )}

              {data.phishingEmail.learn ? (
                <>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: data?.phishingEmail.learningComment ?? '',
                    }}
                  />

                  <Box>
                    <Grid rowGap={10}>
                      {data?.phishingEmail.learn.questions.map(
                        (question, index) => (
                          <Box key={`${question.uuid}_${index}`}>
                            <Question
                              index={index}
                              key={`${question.uuid}_${index}`}
                              question={question}
                            />
                          </Box>
                        )
                      )}
                    </Grid>
                    <Center>
                      <Button mt={8} size={'lg'}>
                        {t('learn.answer')}
                      </Button>
                    </Center>
                  </Box>
                </>
              ) : (
                <Center>
                  <Button mt={8} size={'lg'}>
                    {t('learn.completeLearning')}
                  </Button>
                </Center>
              )}
            </>
          )}
        </Grid>
      </Box>
    </>
  )
})
