import { ApolloError } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import {
  CsvExportPhishingAccessLogInput,
  CsvExportPhishingAccessLogMutation,
  useCsvExportPhishingAccessLogMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

export const useCsvExportPhishingAccessLog = ({
  onCompleted,
  onError,
}: Partial<BaseOptionsType<CsvExportPhishingAccessLogMutation>> = {}) => {
  const { id } = useParams<{ id: string }>()
  const [csvExportPhishingAccessLogMutation, { loading: isExporting }] =
    useCsvExportPhishingAccessLogMutation()
  const form = useForm<CsvExportPhishingAccessLogInput>({
    defaultValues: {
      phishingEmailUuid: id,
      rows: 1000,
    },
  })
  const toast = useToast()
  const { resetErrors, setErrors } = useGraphQLErrors()

  const onExportPhishingAccessLogs = form.handleSubmit(async (inputData) => {
    try {
      await csvExportPhishingAccessLogMutation({
        variables: {
          input: inputData,
        },
      })
      resetErrors()
      onCompleted?.()
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'success',
        title: 'アクセスログのエクスポート情報をメールに送信しました。',
      })
    } catch (e) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'top',
        status: 'error',
        title: 'アクセスログのエクスポートに失敗しました。',
      })
      if (e instanceof ApolloError) {
        setErrors(e.graphQLErrors)
        onError?.(e)
      }
    }
  })

  return { form, isExporting, onExportPhishingAccessLogs }
}

type useCsvExportPhishingAccessLogType = typeof useCsvExportPhishingAccessLog
export type useCsvExportPhishingAccessLogHooksResult =
  ReturnType<useCsvExportPhishingAccessLogType>
export type useCsvExportPhishingAccessLogParameter =
  Parameters<useCsvExportPhishingAccessLogType>[0]
