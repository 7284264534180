import { paginationInput } from '@src/constants'
import { useMe, usePagination } from '@src/hooks'
import {
  useCreateQuestionnaireModal,
  useDestroyQuestionnaireModal,
  useUpdateQuestionnaireModal,
} from '@src/models/Questionnaire'
import { useQuestionnaires } from '@src/models/Questionnaire/useQuestionnaires'
import { useNavigate } from 'react-router-dom'

export const useQuestionnairesHooks = () => {
  const { role } = useMe()
  const isWriteResearch = !!role?.writeResearch
  //ページネーション
  const { current, onChangePage } = usePagination()
  const questionnaires = useQuestionnaires({
    variables: {
      pagination: paginationInput(current),
    },
  })
  const navigate = useNavigate()
  const createQuestionnaireModal = useCreateQuestionnaireModal()
  const destroyQuestionnaireModal = useDestroyQuestionnaireModal()
  const updateQuestionnaireModal = useUpdateQuestionnaireModal()

  return {
    createQuestionnaireModal,
    current,
    destroyQuestionnaireModal,
    isWriteResearch,
    navigate,
    onChangePage,
    questionnaires,
    updateQuestionnaireModal,
  }
}

export type useQuestionnairesHooksResult = ReturnType<
  typeof useQuestionnairesHooks
>
