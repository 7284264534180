import { ApolloError } from '@apollo/client'
import { snakeCase } from 'change-case'
import { useState } from 'react'

type BaseResponseErrorType = ApolloError['graphQLErrors']

//graphQLErrorを管理するstate
export const useGraphQLErrors = <T extends string>() => {
  const [errors, setErrors] = useState<BaseResponseErrorType>([])

  const resetErrors = () => setErrors([])

  const findError = (attributeName: T) =>
    errors.find(
      ({ extensions }) => extensions?.attribute === snakeCase(attributeName)
    )

  const findErrorMessage = (attributeName: T) =>
    findError(attributeName)?.message

  //詳細なエラーではない場合はこちらを使う
  const hasErrors = errors.length > 0 && errors[0]
  //詳細なエラーではない場合はこちらを使う
  const errorMessage = hasErrors ? errors[0].message : ''

  return {
    errorMessage,
    errors,
    findError,
    findErrorMessage,
    hasErrors,
    resetErrors,
    setErrors,
  }
}

export type useGraphQLErrorsHooksResult = ReturnType<typeof useGraphQLErrors>
