import { ApolloError } from '@apollo/client'
import {
  UpdateQuestionnaireInput,
  UpdateResearchMutationResult,
  useUpdateQuestionnaireMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors, useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { useSelectableResearches } from '@src/models/Research'
import { formReset } from '@src/utils/formReset'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

export const useUpdateQuestionnaire = ({
  onCompleted,
  onError,
}: Partial<BaseOptionsType<UpdateResearchMutationResult['data']>> = {}) => {
  const toast = useToast()
  const [updateQuestionnaire, { loading: isLoading }] =
    useUpdateQuestionnaireMutation({
      refetchQueries: ['questionnaires'],
    })
  const form =
    useForm<Omit<UpdateQuestionnaireInput, 'clientMutationId' | 'uuid'>>()
  const { findErrorMessage, resetErrors, setErrors } =
    useGraphQLErrors<
      keyof Omit<UpdateQuestionnaireInput, 'clientMutationId' | 'uuid'>
    >()
  const resetForm = () => formReset(form, resetErrors)

  const researches = useSelectableResearches()

  const onUpdateQuestionnaire = useCallback(
    (uuid: UpdateQuestionnaireInput['uuid']) =>
      form.handleSubmit(async (inputData) => {
        try {
          const { data } = await updateQuestionnaire({
            variables: {
              input: { ...inputData, uuid },
            },
          })
          formReset(form, resetErrors)
          onCompleted?.(data)
          toast({
            status: 'success',
            title: 'アンケートURL発行の更新に成功しました。',
          })
        } catch (e) {
          toast({
            status: 'error',
            title: 'アンケートURL発行の更新に失敗しました。',
          })
          if (e instanceof ApolloError) {
            setErrors(e.graphQLErrors)
            onError?.(e)
          }
        }
      }),
    [
      form,
      setErrors,
      resetErrors,
      toast,
      onCompleted,
      onError,
      updateQuestionnaire,
    ]
  )

  return {
    findErrorMessage,
    form,
    isLoading,
    isResearchSelectable: false,
    onUpdateQuestionnaire,
    researches,
    resetForm,
  }
}

type useUpdateQuestionnaireType = typeof useUpdateQuestionnaire
export type useUpdateQuestionnaireResult =
  ReturnType<useUpdateQuestionnaireType>
// export type useUpdateQuestionnaireParameter = Parameters<useUpdateQuestionnaireType>[0]
