import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  Heading,
  Input,
  Stack,
  VisuallyHidden,
} from '@chakra-ui/react'
import { CsbFormControl, CsbLogoIcon, CsbPrimaryBox } from '@src/components'
import { useUnlockUserHooksResult } from '@src/components/pages/unlock-user/hooks'
import { EmptyLayout } from '@src/layouts/EmptyLayout'
import { findError } from '@src/utils/findError'
import { memo } from 'react'

// アカウントロック解除
export const UnlockUser = memo(
  ({ errors, form, loading, onSubmit }: useUnlockUserHooksResult) => {
    return (
      <EmptyLayout>
        <Flex align={'center'} justify={'center'} minH={'100vh'}>
          <Flex
            align="center"
            justify="space-between"
            maxW={898}
            mx="auto"
            w="full"
          >
            <Heading
              aria-labelledby="title"
              as="h1"
              maxW={276}
              mt={-16}
              w={'full'}
            >
              <VisuallyHidden id="title">情報漏えい防ぐくん</VisuallyHidden>
              <CsbLogoIcon h={'auto'} w={'full'} />
            </Heading>
            <CsbPrimaryBox
              as="form"
              maxW={402}
              pb={10}
              pt={12}
              px={10}
              rounded={'lg'}
              w="full"
              // pb={4}
              onSubmit={(e) => {
                // preventDefault しないとうまく動作しない
                e.preventDefault()
                onSubmit().then()
              }}
            >
              <Stack spacing={6}>
                <Heading as="h2" fontWeight="bold" size="lg">
                  アカウントロック解除
                </Heading>
                {errors.length > 0 && errors[0] ? (
                  <Alert status="error">
                    <AlertIcon />
                    {errors[0].message}
                  </Alert>
                ) : null}
                <CsbFormControl
                  errorText={findError(errors, 'password')?.message}
                  id="password"
                >
                  <Input
                    {...form.register('password')}
                    placeholder="パスワード"
                    type="password"
                  />
                </CsbFormControl>
                <CsbFormControl
                  errorText={findError(errors, 'passwordConfirmation')?.message}
                  id="passwordConfirmation"
                >
                  <Input
                    {...form.register('passwordConfirmation')}
                    placeholder="パスワード"
                    type="password"
                  />
                </CsbFormControl>
                <Button isLoading={loading} type={'submit'}>
                  パスワードリセット
                </Button>
              </Stack>
            </CsbPrimaryBox>
          </Flex>
        </Flex>
      </EmptyLayout>
    )
  }
)
