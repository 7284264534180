import { useDisclosure } from '@chakra-ui/react'
import { ResearchesQuery } from '@src/graphql/generated/graphql'
import { useCopyResearch } from '@src/models/Research/useCopyResearch'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

type Research = ResearchesQuery['researches']['collection'][number]

export const useCopyResearchModal = () => {
  const [research, setResearch] = useState<Research>()
  const { isOpen, onClose: _onClose, onOpen: _onOpen } = useDisclosure()
  const navigate = useNavigate()

  const {
    findErrorMessage,
    form,
    isLoading,
    onCopyResearch: _onCopyResearch,
    resetForm,
  } = useCopyResearch({
    onCompleted: (data) => {
      navigate(`/researches/${data?.copyResearch?.research.uuid ?? ''}/edit`)
      _onClose()
    },
  })

  // noinspection DuplicatedCode
  const onClose = useCallback(() => {
    resetForm()
    _onClose()
  }, [_onClose, resetForm])

  const onOpen = useCallback(
    (research: Research) => {
      _onOpen()
      setResearch(research)
      form.setValue('name', research.name)
      form.setValue('description', research.description)
      if (research.closedAt) {
        form.setValue('closedAt', research.closedAt)
      }
    },
    [form, _onOpen, setResearch]
  )

  const onCopyResearch = useCallback(() => {
    if (!research) return
    _onCopyResearch(research.name, research.uuid)()
  }, [research, _onCopyResearch])

  return {
    findErrorMessage,
    form,
    isLoading,
    isOpen,
    onClose,
    onCopyResearch,
    onOpen,
  }
}

type useCopyResearchModalType = typeof useCopyResearchModal
export type UseCopyResearchModalResult = ReturnType<useCopyResearchModalType>
