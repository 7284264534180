import {
  Modal as ChakraModal,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react'
import { CsbPrimaryBox } from '@src/components'
import { useEffect, useRef, useState } from 'react'

type ModalPropsType = ModalProps

export const CsbModal = ({ children, ...props }: ModalPropsType) => {
  const modalContentRef = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(true)

  const { isOpen } = props

  useEffect(() => {
    if (isOpen) {
      setIsVisible(false)

      // 10ミリ秒待機してからスクロール位置をトップに設定
      // そうしないとmodalContentRef.currentがnullになり、うまく動作しない。
      const timer = setTimeout(() => {
        if (modalContentRef.current) {
          if (modalContentRef.current.scrollTop !== 0) {
            modalContentRef.current.scrollTop = 0
          }
          setIsVisible(true)
        }
      }, 10)

      return () => clearTimeout(timer)
    }
  }, [isOpen])

  return (
    <ChakraModal {...props} isCentered scrollBehavior={'inside'}>
      <ModalOverlay />
      <ModalContent
        maxH={'90vh'}
        my={'5vh'}
        overflowY={'auto'}
        ref={modalContentRef}
        visibility={isVisible ? 'visible' : 'hidden'}
      >
        <CsbPrimaryBox pb={18} pt={'32px'} px={30}>
          {children}
        </CsbPrimaryBox>
      </ModalContent>
    </ChakraModal>
  )
}
