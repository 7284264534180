import { useDisclosure } from '@chakra-ui/react'
import { useCreateDocument } from '@src/models/Document/useCreateDocument'
import { useCallback } from 'react'

export const useCreateDocumentModal = () => {
  const { isOpen, onClose: _onClose, onOpen: _onOpen } = useDisclosure()
  const {
    findErrorMessage,
    form,
    isLoading,
    onCreateDocument,
    resetErrors,
    resetForm,
  } = useCreateDocument({
    onCompleted: () => {
      _onClose()
    },
  })
  const onClose = useCallback(() => {
    resetForm()
    _onClose()
  }, [_onClose, resetForm])

  const onOpen = useCallback(() => {
    resetErrors()
    _onOpen()
  }, [_onOpen, resetErrors])

  return {
    findErrorMessage,
    form,
    isLoading,
    isOpen,
    onClose,
    onCreateDocument,
    onOpen,
  }
}

type UseCreateDocumentModalType = typeof useCreateDocumentModal
export type UseCreateDocumentModalResult =
  ReturnType<UseCreateDocumentModalType>
