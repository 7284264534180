import { paginationInput } from '@src/constants'
import { useMe, usePagination } from '@src/hooks'
import { useCreateDocumentModal } from '@src/models/Document/components/CreateDocumentModal/useCreateDocumentModal'
import { useDestroyDocumentModal } from '@src/models/Document/components/DestroyDocumentModal/useDestroyDocumentModal'
import { useUpdateDocumentModal } from '@src/models/Document/components/UpdateDocumentModal/useUpdateDocumentModal'
import { useDocuments } from '@src/models/Document/useDocuments'
import { useForm } from 'react-hook-form'

export const useDocumentsHooks = () => {
  const { role } = useMe()
  //TODO バックエンドの実装に合わせて変える
  const isWriteDocument = !!role?.writeAdminResearch
  //ページネーション
  const { current, onChangePage } = usePagination()
  const documents = useDocuments({
    variables: {
      pagination: paginationInput(current),
    },
  })
  const createDocumentModal = useCreateDocumentModal()
  const destroyDocumentModal = useDestroyDocumentModal()
  const updateDocumentModal = useUpdateDocumentModal()

  //search機能
  const searchForm = useForm<{ searchWord: string }>()
  const onSearch = searchForm.handleSubmit(async (inputData) => {
    try {
      await documents.refetch({
        pagination: {
          limit: 30,
          page: current,
        },
        searchWord: inputData.searchWord,
      })
    } catch (e) {
      console.log(e)
    }
  })
  const onResetSearch = () => {
    searchForm.resetField('searchWord')
    onSearch()
  }

  return {
    createDocumentModal,
    current,
    destroyDocumentModal,
    documents,
    isWriteDocument,
    onChangePage,
    onResetSearch,
    onSearch,
    searchForm,
    updateDocumentModal,
  }
}

export type UseDocumentsHooksResult = ReturnType<typeof useDocumentsHooks>
