import { Button, HStack, Stack } from '@chakra-ui/react'
import {
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
} from '@src/components'
import { PdfForm } from '@src/models/Pdf/components/PdfForm/PdfForm'
import { UseUpdatePdfModalResult } from '@src/models/Pdf/components/UpdatePdfModal/useUpdatePdfModal'

export const UpdatePdfModal = ({
  findErrorMessage,
  form,
  isLoading,
  isOpen,
  onClose,
  onUpdatePdf,
}: UseUpdatePdfModalResult) => (
  <CsbModal isOpen={isOpen} onClose={onClose}>
    <CsbModalHeader>ドキュメントを編集する</CsbModalHeader>
    <CsbModalBody mt={10}>
      <Stack spacing={6}>
        <PdfForm findErrorMessage={findErrorMessage} form={form} />
      </Stack>
    </CsbModalBody>
    <CsbModalFooter mt={7}>
      <HStack spacing={6}>
        <Button colorScheme={'gray'} onClick={onClose}>
          キャンセル
        </Button>
        <Button isLoading={isLoading} onClick={onUpdatePdf}>
          編集
        </Button>
      </HStack>
    </CsbModalFooter>
  </CsbModal>
)
