import { useLearningResultQuery } from '@src/graphql/generated/graphql'

export const useLearningResult = (
  baseOptions: Partial<Parameters<typeof useLearningResultQuery>[0]> = {}
) => {
  return useLearningResultQuery(baseOptions)
}

type UseLearningResult = typeof useLearningResult
export type UseResearchResultResult = ReturnType<UseLearningResult>
// export type useResearchParameter = Parameters<useProjectType>[0]
