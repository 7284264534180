import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  TableContainer,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  CsbDeleteButton,
  CsbFormControl,
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
  CsbNextLink,
  CsbPagination,
  CsbTag,
  CsbUserAvatar,
} from '@src/components'
import { CsbTable } from '@src/components/CsbTable'
import { CsbDataModal } from '@src/components/Modal/CsbDataModal'
import { CsbDestroyModal } from '@src/components/Modal/CsbDestroyModal'
import { useCsbDestroyModalHooks } from '@src/components/Modal/CsbDestroyModal/hooks'
import { useUsersHooksResult } from '@src/components/pages/users/hooks'
import { Base } from '@src/layouts'
import { useDestroyUsers } from '@src/models/Users/useDestroyUsers'
import { genderToString } from '@src/utils/genderToString'
import dayjs from 'dayjs'
import { memo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

const UserCount = <
  T extends {
    data: {
      currentCount: number
      currentPage: number
      limitValue: number
      totalCount: number
    }
  }
>({
  data,
}: T) => {
  return (
    <Text color={'gray'} fontSize={'sm'} fontWeight={'bold'} py={2}>
      全 {data.totalCount}件中 {1 + (data.currentPage - 1) * data.limitValue}
      件〜
      {data.currentCount + (data.currentPage - 1) * data.limitValue}件を表示
    </Text>
  )
}

//ユーザー一覧ページのcomponent
export const Users = memo(
  ({
    company,
    csbDestroyModalResult,
    csvExportUserModal,
    current,
    data,
    dataModal,
    destroyUserMutation,
    form,
    groups,
    hoverUser,
    // positions,
    // handleSelect,
    isExporting,
    isWriteUser,
    navigate,
    onChangePage,
    onExportUsers,
    onMouseLeaveUser,
    onMouseOverUser,
    onResetSearch,
    onSearch,
    organizations,
    refetch,
    searchForm,
    user,
  }: useUsersHooksResult) => {
    const { control, getValues, handleSubmit, setValue } =
      // TODO anyを消す
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      useForm<{ uuids: { [key: string]: undefined | any } }>()
    const [isSelectUuid, setIsSelectUuid] = useState(false)
    const destroyUsersModal = useCsbDestroyModalHooks()

    const clearSelectUuids = () => {
      Object.entries(getValues().uuids).forEach(([key]) => {
        setValue(`uuids.${key}`, false)
      })
      setIsSelectUuid(false)
    }

    const { onDestroyUsers } = useDestroyUsers({
      onCompleted: async () => {
        clearSelectUuids()
        setIsSelectUuid(false)
        destroyUsersModal.onClose()
        const {
          data: {
            users: { metadata },
          },
        } = await refetch()
        //refetch後ページャーの中身が0の場合
        const isEmptyPage = metadata.totalCount % metadata.limitValue === 0
        //refetch後ページャーが最初のページの場合
        const isInitialPage = metadata.currentPage === 0
        //ページャーの中身が0かつ2ページ目以降の場合
        if (isEmptyPage && !isInitialPage) {
          //ページャーの値を現在のページ-1ページに更新する
          onChangePage('/users')(metadata.currentPage - 1)
        }
      },
    })

    const noneValue = 'none'

    return (
      <Base
        left={<Heading>ユーザー管理</Heading>}
        right={
          isWriteUser && (
            <HStack spacing={3}>
              <Button colorScheme={'gray'} onClick={csvExportUserModal.onOpen}>
                CSVエクスポート
              </Button>
              <CsbNextLink href={'/users/import'}>
                <Button as={'div'} colorScheme={'gray'}>
                  CSV一括インポート
                </Button>
              </CsbNextLink>
              {company?.maxUserCount === company?.currentUserCount ? (
                <Button isDisabled>ユーザー数上限に達しています</Button>
              ) : (
                <Button as={CsbNextLink} href={'/users/new'}>
                  新しいユーザーの作成
                </Button>
              )}
            </HStack>
          )
        }
      >
        <Flex mt={12}>
          <Box maxW={820} w={'full'}>
            <HStack spacing={2}>
              <Controller
                control={searchForm.control}
                name={'organizationId'}
                render={({ field }) => (
                  <Select
                    bg={'white'}
                    {...field}
                    placeholder="すべての組織"
                    onChange={(e) => {
                      searchForm.setValue('organizationId', e.target.value)
                      onSearch().then()
                    }}
                  >
                    {organizations?.organizations.collection.map(
                      (item, index) => (
                        <option key={`${item.uuid}_${index}`} value={item.uuid}>
                          {item.name}
                        </option>
                      )
                    )}
                    <option key={noneValue} value={noneValue}>
                      設定なし
                    </option>
                  </Select>
                )}
              />
              <Controller
                control={searchForm.control}
                name={'groupId'}
                render={({ field }) => (
                  <Select
                    {...field}
                    bg={'white'}
                    placeholder="すべてのグループ"
                    onChange={(e) => {
                      searchForm.setValue('groupId', e.target.value)
                      onSearch().then()
                    }}
                  >
                    {groups?.groups.collection.map((item, index) => (
                      <option key={`${item.uuid}_${index}`} value={item.uuid}>
                        {item.name}
                      </option>
                    ))}
                    <option key={noneValue} value={noneValue}>
                      設定なし
                    </option>
                  </Select>
                )}
              />
              <Input
                bg={'white'}
                type="text"
                {...searchForm.register('searchWord')}
                placeholder={'氏名を入力'}
                onKeyDown={(e) => {
                  e.stopPropagation()
                  if (e.key.toLowerCase() !== 'enter') return
                  onSearch().then()
                }}
              />
              <ButtonGroup>
                <Button colorScheme={'teal'} onClick={onSearch}>
                  検索
                </Button>
                <Button
                  colorScheme={'gray'}
                  size={'md'}
                  onClick={onResetSearch}
                >
                  リセット
                </Button>
              </ButtonGroup>
            </HStack>
          </Box>

          {/*<HStack ml={'auto'} pl={5} width={550} spacing={4}>*/}
          {/*  <Select*/}
          {/*    placeholder="すべての部署"*/}
          {/*    bg={'white'}*/}
          {/*    onChange={handleSelect('positions')}*/}
          {/*  >*/}
          {/*    {positions?.positions.collection.map((item, index) => (*/}
          {/*      <option value={item.uuid} key={`${item.uuid}_${index}`}>*/}
          {/*        {item.name}*/}
          {/*      </option>*/}
          {/*    ))}*/}
          {/*  </Select>*/}
          {/*  <Select*/}
          {/*    placeholder="すべての年代"*/}
          {/*    bg={'white'}*/}
          {/*    onChange={handleSelect('age')}*/}
          {/*  >*/}
          {/*    {AGES.map(({ name }, index) => (*/}
          {/*      <option value={name} key={`${name}_${index}`}>*/}
          {/*        {name}*/}
          {/*      </option>*/}
          {/*    ))}*/}
          {/*  </Select>*/}
          {/*  <Select*/}
          {/*    placeholder="すべての性別"*/}
          {/*    bg={'white'}*/}
          {/*    onChange={handleSelect('gender')}*/}
          {/*  >*/}
          {/*    {GENDERS.map(({ name }, index) => (*/}
          {/*      <option value={name} key={`${name}_${index}`}>*/}
          {/*        {name}*/}
          {/*      </option>*/}
          {/*    ))}*/}
          {/*  </Select>*/}
          {/*</HStack>*/}
        </Flex>
        <Box mt={35}>
          <Flex>
            {isWriteUser && (
              <HStack>
                <Button
                  onClick={() => {
                    const userUuids =
                      data?.users.collection.map((item) => item.uuid) ?? []
                    Object.entries(getValues().uuids).forEach(([key]) => {
                      setValue(`uuids.${key}`, userUuids.includes(key))
                    })
                    setIsSelectUuid(
                      Object.entries(getValues().uuids)
                        .filter(([, value]) => value)
                        .map(([key]) => key).length > 0
                    )
                  }}
                >
                  すべて選択
                </Button>
                <Button
                  onClick={() => {
                    clearSelectUuids()
                    setIsSelectUuid(
                      Object.entries(getValues().uuids)
                        .filter(([, value]) => value)
                        .map(([key]) => key).length > 0
                    )
                  }}
                >
                  選択解除
                </Button>
                <Button
                  colorScheme={'red'}
                  isDisabled={!isSelectUuid}
                  onClick={() => {
                    destroyUsersModal.setHeader('選択したユーザー')
                    destroyUsersModal.onOpen()
                  }}
                >
                  削除
                </Button>
              </HStack>
            )}
            <Box ml={'auto'} mt={'auto'}>
              {data && (
                <UserCount
                  data={{
                    ...data.users.metadata,
                    currentCount: data.users.collection.length,
                  }}
                />
              )}
            </Box>
          </Flex>
          <TableContainer mt={2}>
            <CsbTable
              labels={[
                { label: '選択' },
                { label: '氏名', width: 400 },
                { label: '社員番号' },
                { label: '権限' },
                { label: '役職' },
                { label: '組織' },
                { label: 'グループ' },
                { label: '性別' },
                { label: '誕生日' },
                { label: '入社日' },
                { label: '電話番号' },
                { label: '携帯電話番号' },
                { label: '内線番号' },
                { label: '' },
              ]}
              onMouseLeave={onMouseLeaveUser}
            >
              {data?.users.collection.map((item) => (
                <CsbTable.TableRow
                  key={item.uuid}
                  {...(isWriteUser && {
                    onClick: () => console.log(),
                  })}
                  onMouseOver={() => onMouseOverUser(item.uuid)}
                >
                  <CsbTable.TableCell textAlign={'center'}>
                    <Controller
                      control={control}
                      name={`uuids.${item.uuid}` as const}
                      render={({ field: { onChange, ...props } }) => (
                        <Checkbox
                          borderColor={'teal.500'}
                          isChecked={props.value}
                          onChange={(e) => {
                            onChange(e)
                            setIsSelectUuid(
                              Object.entries(getValues().uuids)
                                .filter(([, value]) => value)
                                .map(([key]) => key).length > 0
                            )
                          }}
                          {...props}
                        />
                      )}
                    />
                  </CsbTable.TableCell>
                  <CsbTable.TableCell minWidth={'400px'}>
                    {isWriteUser ? (
                      <CsbNextLink href={`/users/${item.uuid}/edit`}>
                        <CsbUserAvatar
                          description={item.email}
                          name={
                            item.lastName &&
                            item.firstName &&
                            `${item.lastName} ${item.firstName}`
                          }
                          src={item.avatarPath || undefined}
                        />
                      </CsbNextLink>
                    ) : (
                      <CsbUserAvatar
                        description={item.email}
                        name={
                          item.lastName &&
                          item.firstName &&
                          `${item.lastName} ${item.firstName}`
                        }
                        src={item.avatarPath || undefined}
                      />
                    )}
                  </CsbTable.TableCell>
                  <CsbTable.TableCell
                    {...(isWriteUser && {
                      onClick: () => navigate(`/users/${item.uuid}/edit`),
                    })}
                  >
                    {item.employeeNumber && <>{item.employeeNumber}</>}
                  </CsbTable.TableCell>
                  <CsbTable.TableCell
                    {...(isWriteUser && {
                      onClick: () => navigate(`/users/${item.uuid}/edit`),
                    })}
                  >
                    {item.role && <CsbTag>{item.role.name}</CsbTag>}
                  </CsbTable.TableCell>
                  <CsbTable.TableCell
                    {...(isWriteUser && {
                      onClick: () => navigate(`/users/${item.uuid}/edit`),
                    })}
                  >
                    {item.position && <CsbTag>{item.position.name}</CsbTag>}
                  </CsbTable.TableCell>
                  <CsbTable.TableCell
                    {...(isWriteUser && {
                      onClick: () => navigate(`/users/${item.uuid}/edit`),
                    })}
                  >
                    <HStack align={'start'} spacing={0.5}>
                      {item.organizations &&
                        item.organizations
                          .slice(0, 2)
                          .map((organization) => (
                            <CsbTag key={organization.uuid}>
                              {organization.name}
                            </CsbTag>
                          ))}
                    </HStack>
                    {item.organizations && item.organizations.length > 2 && (
                      <Text
                        _hover={{ textDecoration: 'underline' }}
                        as={'button'}
                        fontSize={'xs'}
                        type={'button'}
                        onClick={(e) => {
                          e.stopPropagation()
                          dataModal.onOpen()
                          dataModal.setData({
                            items: item.organizations,
                            title: '組織',
                          })
                        }}
                      >
                        …他{item.organizations.length - 2}件
                      </Text>
                    )}
                  </CsbTable.TableCell>
                  <CsbTable.TableCell
                    {...(isWriteUser && {
                      onClick: () => navigate(`/users/${item.uuid}/edit`),
                    })}
                  >
                    <HStack align={'start'} spacing={0.5}>
                      {item.groups &&
                        item.groups
                          .slice(0, 2)
                          .map((groups) => (
                            <CsbTag key={groups.uuid}>{groups.name}</CsbTag>
                          ))}
                    </HStack>
                    {item.groups && item.groups.length > 2 && (
                      <Text
                        _hover={{ textDecoration: 'underline' }}
                        as={'button'}
                        fontSize={'xs'}
                        type={'button'}
                        onClick={(e) => {
                          e.stopPropagation()
                          dataModal.onOpen()
                          dataModal.setData({
                            items: item.groups,
                            title: 'グループ',
                          })
                        }}
                      >
                        …他{item.groups.length - 2}件
                      </Text>
                    )}
                  </CsbTable.TableCell>
                  <CsbTable.TableCell
                    {...(isWriteUser && {
                      onClick: () => navigate(`/users/${item.uuid}/edit`),
                    })}
                  >
                    {item.gender && <>{genderToString(item.gender)}</>}
                  </CsbTable.TableCell>
                  <CsbTable.TableCell
                    {...(isWriteUser && {
                      onClick: () => navigate(`/users/${item.uuid}/edit`),
                    })}
                  >
                    {item.birthdayOn && (
                      <>{dayjs(item.birthdayOn).format('YYYY/MM/DD')}</>
                    )}
                  </CsbTable.TableCell>
                  <CsbTable.TableCell
                    {...(isWriteUser && {
                      onClick: () => navigate(`/users/${item.uuid}/edit`),
                    })}
                  >
                    {item.joinedOn && (
                      <>{dayjs(item.joinedOn).format('YYYY/MM/DD')}</>
                    )}
                  </CsbTable.TableCell>
                  <CsbTable.TableCell
                    w={200}
                    {...(isWriteUser && {
                      onClick: () => navigate(`/users/${item.uuid}/edit`),
                    })}
                  >
                    {item.telephoneNumber && <>{item.telephoneNumber}</>}
                  </CsbTable.TableCell>
                  <CsbTable.TableCell
                    w={200}
                    {...(isWriteUser && {
                      onClick: () => navigate(`/users/${item.uuid}/edit`),
                    })}
                  >
                    {item.mobileNumber && <>{item.mobileNumber}</>}
                  </CsbTable.TableCell>
                  <CsbTable.TableCell
                    w={200}
                    {...(isWriteUser && {
                      onClick: () => navigate(`/users/${item.uuid}/edit`),
                    })}
                  >
                    {item.officeNumber && <>{item.officeNumber}</>}
                  </CsbTable.TableCell>
                  <CsbTable.TableCell
                    isNumeric
                    position={hoverUser === item.uuid ? 'sticky' : undefined}
                    right={0}
                    top={0}
                  >
                    {!isWriteUser ||
                      (user?.uuid !== item.uuid && (
                        <CsbDeleteButton
                          onClick={(e) => {
                            e.stopPropagation()
                            csbDestroyModalResult.onOpen(() => {
                              csbDestroyModalResult.setData(item)
                              csbDestroyModalResult.setHeader(
                                `${item.lastName} ${item.firstName}`
                              )
                            })
                          }}
                        >
                          削除
                        </CsbDeleteButton>
                      ))}
                  </CsbTable.TableCell>
                </CsbTable.TableRow>
              ))}
            </CsbTable>
          </TableContainer>
          <Flex>
            <Box ml={'auto'}>
              {data && (
                <UserCount
                  data={{
                    ...data.users.metadata,
                    currentCount: data.users.collection.length,
                  }}
                />
              )}
            </Box>
          </Flex>
        </Box>
        <Flex mt={5}>
          {data ? (
            <CsbPagination
              current={current}
              defaultCurrent={current}
              pageSize={data.users.metadata.limitValue}
              total={data.users.metadata.totalCount}
              onChange={(page) => {
                clearSelectUuids()
                onChangePage('/users')(page)
              }}
            />
          ) : null}
          <HStack ml={'auto'} spacing={3}>
            {isWriteUser && (
              <HStack spacing={3}>
                <Button
                  colorScheme={'gray'}
                  onClick={csvExportUserModal.onOpen}
                >
                  CSVエクスポート
                </Button>
                <CsbNextLink href={'/users/import'}>
                  <Button as={'div'} colorScheme={'gray'}>
                    CSV一括インポート
                  </Button>
                </CsbNextLink>
                {company?.maxUserCount === company?.currentUserCount ? (
                  <Button isDisabled>ユーザー数上限に達しています</Button>
                ) : (
                  <Button as={CsbNextLink} href={'/users/new'}>
                    新しいユーザーの作成
                  </Button>
                )}
              </HStack>
            )}
          </HStack>
        </Flex>
        <CsbDataModal {...dataModal}>
          <CsbModalHeader>{dataModal.data?.title}</CsbModalHeader>
          <CsbModalBody mt={2}>
            <VStack align={'start'} maxH={60} overflowY={'auto'}>
              {dataModal.data?.items.map(({ name }) => (
                <CsbTag key={`dataModal_${name}`} size={'lg'}>
                  {name}
                </CsbTag>
              ))}
            </VStack>
          </CsbModalBody>
        </CsbDataModal>
        <CsbDestroyModal
          {...csbDestroyModalResult}
          onDestroy={() =>
            csbDestroyModalResult.onDestroy(
              destroyUserMutation({
                variables: {
                  input: { uuid: csbDestroyModalResult.data?.uuid },
                },
              }),
              {
                onCompleted: async () => {
                  const {
                    data: {
                      users: { metadata },
                    },
                  } = await refetch()
                  //refetch後ページャーの中身が0の場合
                  const isEmptyPage =
                    metadata.totalCount % metadata.limitValue === 0
                  //refetch後ページャーが最初のページの場合
                  const isInitialPage = metadata.currentPage === 0
                  //ページャーの中身が0かつ2ページ目以降の場合
                  if (isEmptyPage && !isInitialPage) {
                    //ページャーの値を現在のページ-1ページに更新する
                    onChangePage('/users')(metadata.currentPage - 1)
                  }
                },
              }
            )
          }
        />
        <CsbModal
          isOpen={csvExportUserModal.isOpen}
          onClose={csvExportUserModal.onClose}
        >
          <CsbModalHeader>エクスポート</CsbModalHeader>
          <CsbModalBody mt={4}>
            <Alert status="warning">
              <AlertIcon />
              <Text fontSize={'sm'}>
                ログインしているユーザーのメールアドレスにダウンロードURLを記載したメールが送信されます。
              </Text>
            </Alert>
            <Stack mt={2} spacing={4}>
              <CsbFormControl>
                <Text>全レコード件数: {data?.usersCount ?? 0}</Text>
              </CsbFormControl>
              <CsbFormControl labelText={'1ファイルに表示するレコード件数*'}>
                <Flex align={'center'} justify={'end'}>
                  <NumberInput
                    defaultValue={form.getValues('rows') ?? 0}
                    max={10000}
                    maxW={100}
                    min={0}
                  >
                    <NumberInputField
                      textAlign={'right'}
                      {...form.register('rows', {
                        valueAsNumber: true,
                      })}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text ml={2}>件</Text>
                </Flex>
              </CsbFormControl>
            </Stack>
          </CsbModalBody>
          <CsbModalFooter mt={7}>
            <HStack spacing={6}>
              <Button colorScheme={'gray'} onClick={csvExportUserModal.onClose}>
                キャンセル
              </Button>
              <Button isLoading={isExporting} onClick={onExportUsers}>
                エクスポート
              </Button>
            </HStack>
          </CsbModalFooter>
        </CsbModal>
        <CsbDestroyModal
          {...destroyUsersModal}
          onDestroy={handleSubmit((data) => {
            const uuids = Object.entries(data.uuids)
              .filter(([, value]) => value)
              .map(([key]) => key)
            onDestroyUsers(uuids).then()
          })}
        />
      </Base>
    )
  }
)
