import { ApolloError } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  CreateAdminResearchInput,
  CreateAdminResearchMutationResult,
  useCreateAdminResearchMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors, useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { formReset } from '@src/utils/formReset'
import { valueAsDate } from '@src/utils/input'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

export const useCreateAdminResearch = ({
  onCompleted,
  onError,
}: Partial<
  BaseOptionsType<CreateAdminResearchMutationResult['data']>
> = {}) => {
  const toast = useToast()
  const [createAdminResearch, { loading: isLoading }] =
    useCreateAdminResearchMutation({
      refetchQueries: ['adminResearches'],
    })
  const form = useForm<Omit<CreateAdminResearchInput, 'clientMutationId'>>({
    resolver: zodResolver(
      z.object({
        closedAt: z.preprocess((value) => valueAsDate(value), z.date()),
        description: z.union([z.string(), z.undefined()]),
        name: z.string(),
        publishedAt: z.preprocess((value) => valueAsDate(value), z.date()),
      })
    ),
  })
  const { findErrorMessage, resetErrors, setErrors } =
    useGraphQLErrors<keyof Omit<CreateAdminResearchInput, 'clientMutationId'>>()
  const resetForm = () => formReset(form, resetErrors)

  const onCreateAdminResearch = useCallback(
    () =>
      form.handleSubmit(async (inputData) => {
        try {
          const { data } = await createAdminResearch({
            variables: {
              input: inputData,
            },
          })
          formReset(form, resetErrors)
          onCompleted?.(data)
          toast({
            status: 'success',
            title: 'アンケートを作成しました。',
          })
        } catch (e) {
          toast({
            status: 'error',
            title: 'アンケートの作成に失敗しました。',
          })
          if (e instanceof ApolloError) {
            setErrors(e.graphQLErrors)
            onError?.(e)
          }
        }
      })(),
    [
      resetErrors,
      createAdminResearch,
      setErrors,
      toast,
      form,
      onCompleted,
      onError,
    ]
  )

  return {
    findErrorMessage,
    form,
    isLoading,
    onCreateAdminResearch,
    resetErrors,
    resetForm,
  }
}

type UseCreateAdminResearchType = typeof useCreateAdminResearch
export type UseCreateAdminResearchResult =
  ReturnType<UseCreateAdminResearchType>
// export type UseCreateAdminResearchParameter = Parameters<UseCreateAdminResearchType>[0]
