import { PhishingNew } from '@src/components/pages/phishing/new'
import { usePhishingNewHooks } from '@src/components/pages/phishing/new/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const PhishingNewPage = memo(() => {
  const phishingNewHooks = usePhishingNewHooks()
  return (
    <Authorization>
      <PhishingNew {...phishingNewHooks} />
    </Authorization>
  )
})
