import { Phishing } from '@src/components/pages/phishing'
import { usePhishingHooks } from '@src/components/pages/phishing/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

//フィッシング一覧
export const PhishingPage = memo(() => {
  const phishingHooks = usePhishingHooks()
  return (
    <Authorization>
      <Phishing {...phishingHooks} />
    </Authorization>
  )
})
