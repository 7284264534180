import { useDisclosure } from '@chakra-ui/react'
import { AdminResearchesQuery } from '@src/graphql/generated/graphql'
import { useDestroyAdminResearch } from '@src/models/AdminResearch'
import { useCallback, useState } from 'react'

type AdminResearch =
  AdminResearchesQuery['adminResearches']['collection'][number]

export const useDestroyAdminResearchModal = () => {
  const [adminResearch, setAdminResearch] = useState<AdminResearch>()
  const { isOpen, onClose: _onClose, onOpen: _onOpen } = useDisclosure()
  const { isLoading, onDestroyAdminResearch: _onDestroyAdminResearch } =
    useDestroyAdminResearch({
      onCompleted: () => {
        _onClose()
      },
    })
  const onClose = useCallback(() => {
    _onClose()
  }, [_onClose])

  const onOpen = useCallback(
    (adminResearch: AdminResearch) => {
      _onOpen()
      setAdminResearch(adminResearch)
    },
    [_onOpen, setAdminResearch]
  )

  const onDestroyAdminResearch = useCallback(() => {
    if (!adminResearch) return
    _onDestroyAdminResearch(adminResearch.uuid)
  }, [adminResearch, _onDestroyAdminResearch])

  return {
    adminResearch,
    isLoading,
    isOpen,
    onClose,
    onDestroyAdminResearch,
    onOpen,
  }
}

type UseDestroyAdminResearchModalType = typeof useDestroyAdminResearchModal
export type UseDestroyAdminResearchModalResult =
  ReturnType<UseDestroyAdminResearchModalType>
// export type UseDestroyAdminResearchModalParameter = Parameters<UseDestroyAdminResearchModalType>[0]
