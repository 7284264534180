import { CsbFormControl } from '@src/components'
import { InputControl } from '@src/components/Form/InputControl/InputControl'
import { UseUpdateAttachFormResult } from '@src/models/Attach/components/UpdateAttachForm/useUpdateForm'

export const UpdateAttachForm = ({
  findErrorMessage,
  form: { control },
}: Pick<UseUpdateAttachFormResult, 'form' | 'findErrorMessage'>) => {
  return (
    <>
      <CsbFormControl
        errorText={findErrorMessage('name')}
        labelText={'管理ラベル'}
      >
        <InputControl control={control} name={'name'} />
      </CsbFormControl>
      <CsbFormControl
        errorText={findErrorMessage('fileName')}
        labelText={'ファイル名'}
      >
        <InputControl control={control} name={'fileName'} />
      </CsbFormControl>
    </>
  )
}
