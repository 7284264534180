import { useToast } from '@chakra-ui/react'
import {
  CompanyMailLimitInput,
  CompanyMailLimitKind,
  UpdateCompanyInput,
  useCompanyQuery,
  useUpdateCompanyMutation,
} from '@src/graphql/generated/graphql'
import { useErrors, useMe } from '@src/hooks'
import { companyEditFormDefaultValues } from '@src/pages/companies/formDefaultValues'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
//企業編集で使うhooksのまとめ
export const useCompaniesEditHooks = () => {
  const { role } = useMe()
  const isWriteCompany = !!role?.writeCompany
  const navigate = useNavigate()
  const toast = useToast()
  const [updateCompany, { loading: isUpdating }] = useUpdateCompanyMutation()
  const [errors, setErrors] = useErrors()
  const form = useForm<Omit<UpdateCompanyInput, 'uuid'>>({
    defaultValues: companyEditFormDefaultValues,
  })
  const { id } = useParams<{ id: string }>()

  const { data, loading } = useCompanyQuery({
    onCompleted: ({ company }) => {
      company.name && form.setValue('name', company.name)
      company.domain && form.setValue('domain', company.domain)
      company.logoPath && form.setValue('logoPath', company.logoPath)
      company.postalCode && form.setValue('postalCode', company.postalCode)
      company.state && form.setValue('state', company.state)
      company.address && form.setValue('address', company.address)
      company.street && form.setValue('street', company.street)
      company.building && form.setValue('building', company.building)
      company.maxUserCount &&
        form.setValue('maxUserCount', company.maxUserCount)
      company.maxMonthlyMailCount &&
        form.setValue('maxMonthlyMailCount', company.maxMonthlyMailCount)
      company.expiresAt && form.setValue('expiresAt', company.expiresAt)
      form.setValue('testMailCount', company.testMailCount)
      const companyMailLimits: CompanyMailLimitInput[] =
        company.companyMailLimits.map((companyMailLimit) => ({
          currentCount: companyMailLimit.currentCount,
          endDate: companyMailLimit.endDate,
          id: Number(companyMailLimit.id),
          limitCount: companyMailLimit.limitCount,
          limitType: companyMailLimit.limitType as CompanyMailLimitKind,
          startDate: companyMailLimit.startDate,
        })) ?? []

      form.setValue(
        'companyMailLimits',
        companyMailLimits.length > 0
          ? companyMailLimits
          : [
              {
                currentCount: 0,
                endDate: null,
                id: null,
                limitCount: null,
                limitType: CompanyMailLimitKind.PhishingOnly,
                startDate: dayjs().format('YYYY-MM-DD'),
              },
            ]
      )
    },
    variables: { uuid: id ?? '' },
  })

  const onSubmit = form.handleSubmit(
    async ({ ...inputData }: Omit<UpdateCompanyInput, 'uuid'>) => {
      try {
        await updateCompany({
          variables: {
            input: {
              ...inputData,
              uuid: id ?? '',
            },
          },
        })
        setErrors([])
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'success',
          title: '企業アカウントを更新しました。',
        })
        navigate('/companies')
        /**
         * TODO anyを消す
         * e instanceOf ApolloError
         * setErrorsを変更する
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'error',
          title: '企業アカウントの更新に失敗しました。',
        })
        setErrors(e.graphQLErrors)
      }
    }
  )
  return {
    data,
    errors,
    form,
    isUpdating,
    isWriteCompany,
    loading,
    navigate,
    onSubmit,
  }
}

export type useCompaniesEditHooksResult = ReturnType<
  typeof useCompaniesEditHooks
>
