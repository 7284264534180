import { PhishingResult } from '@src/components/pages/phishing/[id]/result/old'
import { usePhishingResultHooks } from '@src/components/pages/phishing/[id]/result/old/hooks'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const PhishingResultPage = memo(() => {
  const phishingResultHooks = usePhishingResultHooks()
  return (
    <Authorization>
      <PhishingResult {...phishingResultHooks} />
    </Authorization>
  )
})
