import { usePagination } from '@src/hooks'
import { useReplyUsers } from '@src/models/Phishing/ReplyUsers/useReplyUsers'

export const useReplyUsersHooks = (id: string) => {
  //ページネーション
  const { current, onChangePage } = usePagination()
  const useReplyUsersResult = useReplyUsers({
    variables: {
      pagination: {
        limit: 10,
        page: current,
      },
      uuid: id,
    },
  })

  return {
    current,
    onChangePage,
    useReplyUsersResult,
  }
}

export type useReplyUsersHooksResult = ReturnType<typeof useReplyUsersHooks>
