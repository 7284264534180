import 'devextreme/dist/css/dx.light.css'

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { ChakraProvider } from '@chakra-ui/react'
import { Worker } from '@react-pdf-viewer/core'
import { LocalStorage } from '@src/enums/localStorage'
import { useLocalStorage } from '@src/hooks'
import { Router } from '@src/Router'
import { theme } from '@src/thme'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

function App() {
  const navigate = useNavigate()
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
  })

  const [, setAccessToken] = useLocalStorage<string>(
    LocalStorage.ACCESS_TOKEN,
    ''
  )

  const authLink = setContext(async (_, { headers }) => {
    const token = await localStorage.getItem(LocalStorage.ACCESS_TOKEN)
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token?.replace('"', '')}` : '',
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors?.forEach(async (error) => {
        if (error.message === 'Permissions deny, Please login again') {
          setAccessToken(null)
          await navigate('/?session=out')
        }
      })
    }
    if (networkError) {
      if ('statusCode' in networkError && networkError.statusCode === 401) {
        ;(async () => {
          setAccessToken(null)
          await navigate('/?session=out')
        })()
      }
    }
  })

  const cache = new InMemoryCache()
  const client = new ApolloClient({
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
      // query: {
      //   fetchPolicy: 'network-only',
      //   errorPolicy: 'all',
      // },
      // mutate: {
      //   errorPolicy: 'all',
      // },
    },
    link: ApolloLink.from([errorLink, authLink, httpLink]),
  })

  return (
    <RecoilRoot>
      <ApolloProvider client={client}>
        <ChakraProvider resetCSS theme={theme}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/legacy/build/pdf.worker.js">
            <HelmetProvider>
              <Router />
            </HelmetProvider>
          </Worker>
        </ChakraProvider>
      </ApolloProvider>
    </RecoilRoot>
  )
}

export default App
