import { ApolloError } from '@apollo/client'
import {
  CopyResearchInput,
  CopyResearchMutationResult,
  useCopyResearchMutation,
} from '@src/graphql/generated/graphql'
import { useGraphQLErrors, useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { formReset } from '@src/utils/formReset'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

export const useCopyResearch = ({
  onCompleted,
  onError,
}: Partial<BaseOptionsType<CopyResearchMutationResult['data']>> = {}) => {
  const toast = useToast()
  const [copyResearch, { loading: isLoading }] = useCopyResearchMutation({})
  const form =
    useForm<Omit<CopyResearchInput, 'clientMutationId' | 'baseUuid'>>()
  const { findErrorMessage, resetErrors, setErrors } =
    useGraphQLErrors<
      keyof Omit<CopyResearchInput, 'clientMutationId' | 'baseUuid'>
    >()
  const resetForm = () => formReset(form, resetErrors)

  const onCopyResearch = useCallback(
    (baseName: string, baseUuid: CopyResearchInput['baseUuid']) =>
      form.handleSubmit(async (inputData) => {
        try {
          const { data } = await copyResearch({
            variables: {
              input: { ...inputData, baseUuid },
            },
          })
          formReset(form, resetErrors)
          onCompleted?.(data)
          toast({
            status: 'success',
            title: `アンケート（${baseName}）を複製しました。質問内容を編集してください。`,
          })
        } catch (e) {
          toast({
            status: 'error',
            title: 'アンケートの複製に失敗しました。',
          })
          if (e instanceof ApolloError) {
            setErrors(e.graphQLErrors)
            onError?.(e)
          }
        }
      }),
    [form, setErrors, resetErrors, toast, onCompleted, onError, copyResearch]
  )

  return {
    copyResearch,
    findErrorMessage,
    form,
    isLoading,
    onCopyResearch,
    resetForm,
  }
}

type useCopyResearchType = typeof useCopyResearch
export type useCopyResearchResult = ReturnType<useCopyResearchType>
