import { Box, BoxProps } from '@chakra-ui/react'

export const CsbPrimaryBox = ({ ...props }: BoxProps) => {
  return (
    <Box
      bg="white"
      border="1px solid"
      borderColor="gray.200"
      shadow="sm"
      {...props}
    />
  )
}
