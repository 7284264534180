import { Box, Grid, Text } from '@chakra-ui/react'
import { CsbPrimaryBox } from '@src/components'
import {
  AdminAnswerResearchesQuery,
  AdminResearchQuestionsQuery,
} from '@src/graphql/generated/graphql'
import { useTranslation } from 'react-i18next'

type AdminResearchAnswerResultProps = {
  answerResearches: AdminAnswerResearchesQuery['adminAnswerResearches']
  researchQuestions: AdminResearchQuestionsQuery['adminResearchQuestions']
}

export const AdminResearchAnswerResult = ({
  answerResearches,
  researchQuestions,
}: AdminResearchAnswerResultProps) => {
  const { t } = useTranslation()
  const getAnswers = (answers: string[] | null | undefined) => answers ?? []
  const answerMap = new Map(
    answerResearches.map((d) => [d.adminResearchQuestionUuid, d.answers])
  )

  return (
    <Box display={'flex'} flexDirection={'column'} gap={4} mb={2} mt={2}>
      {researchQuestions.map((researchQuestion, index) => (
        <CsbPrimaryBox
          display={'grid'}
          gap={8}
          key={`${researchQuestion.uuid}_${index}`}
          px={4}
          py={6}
          textAlign={'left'}
        >
          <Grid rowGap={2}>
            <Text fontWeight={'bold'}>{t('research.result.question')}</Text>
            <Text
              fontSize={16}
              lineHeight={1.25}
              whiteSpace={'pre-wrap'}
              wordBreak={'break-all'}
            >
              {researchQuestion.name}
            </Text>
          </Grid>
          <Grid rowGap={2}>
            <Text fontWeight={'bold'}>{t('research.result.answer')}</Text>
            {getAnswers(answerMap.get(researchQuestion.uuid)).map(
              (answer, index) => (
                <Text fontSize={16} key={index} lineHeight={1.25}>
                  {answer}
                </Text>
              )
            )}
          </Grid>
        </CsbPrimaryBox>
      ))}
    </Box>
  )
}
