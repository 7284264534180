import { useAdminResearchesQuery } from '@src/graphql/generated/graphql'

export const useAdminResearches = (
  baseOptions: Partial<Parameters<typeof useAdminResearchesQuery>[0]> = {}
) => {
  return useAdminResearchesQuery(baseOptions)
}

type UseAdminResearchesType = typeof useAdminResearches
export type UseAdminResearchesResult = ReturnType<UseAdminResearchesType>
// export type UseAdminResearchesParameter = Parameters<UseProjectType>[0]
