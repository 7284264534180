import { useQuestionnaireQuery } from '@src/graphql/generated/graphql'

export const useQuestionnaire = (
  baseOptions: Partial<Parameters<typeof useQuestionnaireQuery>[0]> = {}
) => {
  return useQuestionnaireQuery(baseOptions)
}

type useQuestionnaireType = typeof useQuestionnaire
export type useQuestionnaireResult = ReturnType<useQuestionnaireType>
// export type useQuestionnaireParameter = Parameters<useProjectType>[0]
