import { Box, Button, Flex, Heading, HStack } from '@chakra-ui/react'
import { CsbPrimaryBox } from '@src/components'
import { TemplateEditForm } from '@src/components/Form/TemplatesEditForm'
import { useTemplatesEditHooksResult } from '@src/components/pages/templates/[id]/edit/hooks'
import { Base } from '@src/layouts'
import { memo } from 'react'

//メールテンプレート更新ページのcomponent
export const TemplatesEdit = memo(
  ({
    data,
    errors,
    form,
    isDraftButton,
    isUpdating,
    isWriteMailTemplate,
    loading,
    navigate,
    onSubmit,
  }: useTemplatesEditHooksResult) => {
    return (
      <Base
        left={
          <Heading>
            {data?.mailTemplate.name || 'テンプレート'}
            の編集
          </Heading>
        }
        right={
          <HStack spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧に戻る
            </Button>
            {data?.mailTemplate.draft && isWriteMailTemplate && (
              <Button
                colorScheme={'blue'}
                isLoading={isDraftButton && isUpdating}
                onClick={onSubmit(true)}
              >
                下書き保存
              </Button>
            )}
            {isWriteMailTemplate && (
              <Button
                isLoading={!isDraftButton && isUpdating}
                onClick={onSubmit(false)}
              >
                公開
              </Button>
            )}
          </HStack>
        }
      >
        <Box mt={45}>
          <CsbPrimaryBox pb={'72px'} pt={35} px={45}>
            {data && !loading ? (
              <TemplateEditForm errors={errors} form={form} />
            ) : (
              <>loading...</>
            )}
          </CsbPrimaryBox>
        </Box>
        <Flex mt={5}>
          <HStack ml={'auto'} spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧に戻る
            </Button>
            {data?.mailTemplate.draft && isWriteMailTemplate && (
              <Button
                colorScheme={'blue'}
                isLoading={isDraftButton && isUpdating}
                onClick={onSubmit(true)}
              >
                下書き保存
              </Button>
            )}
            {isWriteMailTemplate && (
              <Button
                isLoading={!isDraftButton && isUpdating}
                onClick={onSubmit(false)}
              >
                公開
              </Button>
            )}
          </HStack>
        </Flex>
      </Base>
    )
  }
)
