import { ApolloError } from '@apollo/client'
import {
  DestroyQuestionnaireInput,
  DestroyQuestionnaireMutationResult,
  useDestroyQuestionnaireMutation,
} from '@src/graphql/generated/graphql'
import { useToast } from '@src/hooks'
import { BaseOptionsType } from '@src/models/Base'
import { useCallback } from 'react'

export const useDestroyQuestionnaire = ({
  onCompleted,
  onError,
}: Partial<
  BaseOptionsType<DestroyQuestionnaireMutationResult['data']>
> = {}) => {
  const [destroyQuestionnaire, { loading: isLoading }] =
    useDestroyQuestionnaireMutation({
      refetchQueries: ['questionnaires'],
    })
  const toast = useToast()
  const onDestroyQuestionnaire = useCallback(
    async (uuid: DestroyQuestionnaireInput['uuid']) => {
      try {
        const { data } = await destroyQuestionnaire({
          variables: {
            input: { uuid },
          },
        })
        toast({
          status: 'success',
          title: 'アンケートURLを削除しました',
        })
        onCompleted?.(data)
      } catch (e) {
        toast({
          status: 'error',
          title: 'アンケートURLの削除に失敗しました。',
        })
        if (e instanceof ApolloError) {
          onError?.(e)
        }
      }
    },
    [onError, toast, onCompleted, destroyQuestionnaire]
  )

  return { isLoading, onDestroyQuestionnaire }
}

type useDestroyQuestionnaireType = typeof useDestroyQuestionnaire
export type useDestroyQuestionnaireResult =
  ReturnType<useDestroyQuestionnaireType>
// export type useDestroyQuestionnaireParameter = Parameters<useDestroyQuestionnaireType>[0]
