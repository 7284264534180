import { SettingsIcon } from '@chakra-ui/icons'
import { Flex, Heading, Select, Stack, Text, Tooltip } from '@chakra-ui/react'
import { CsbFormControl, CsbFormModal, CsbPagination } from '@src/components'
import { CsbEditButton } from '@src/components/Buttons/CsbEditButton'
import { CsbTable } from '@src/components/CsbTable'
import { useLinkDomainsHooksResult } from '@src/components/pages/link-domains/hooks'
import { Base } from '@src/layouts'
import { findError } from '@src/utils/findError'
import React, { memo } from 'react'

export const LinkDomains = memo(
  ({
    current,
    data,
    formModal,
    isUpdating,
    isWriteLinkDomain,
    onChangePage,
    onSubmit,
    onUpdate,
  }: useLinkDomainsHooksResult) => {
    return (
      <Base left={<Heading>リンクドメイン管理</Heading>}>
        <CsbTable
          labels={['リンクドメイン', 'サイトドメイン', '操作']}
          wrapperProps={{ mt: 35 }}
        >
          {data?.phishingLinkDomains.collection.map((item) => (
            <CsbTable.TableRow key={item.uuid}>
              <CsbTable.TableCell w={300}>
                <Tooltip aria-label="A tooltip" label={item.domain}>
                  <Text isTruncated fontWeight={'bold'} w={300}>
                    {item.domain}
                  </Text>
                </Tooltip>
              </CsbTable.TableCell>
              <CsbTable.TableCell w={300}>
                <Tooltip
                  aria-label="A tooltip"
                  label={item.phishingSiteDomain.domain}
                >
                  <Text isTruncated color={'gray.500'} fontSize={'sm'} w={300}>
                    {item.phishingSiteDomain.domain}
                  </Text>
                </Tooltip>
              </CsbTable.TableCell>

              <CsbTable.TableCell w={200}>
                <Stack direction={'row'} justifyContent={'end'}>
                  {isWriteLinkDomain && (
                    <CsbEditButton
                      icon={<SettingsIcon />}
                      onClick={(e) => {
                        e.stopPropagation()
                        onUpdate({
                          linkDomain: item.domain,
                          phishingSiteDomainUuid: item.phishingSiteDomain.uuid,
                          siteDomain: item.phishingSiteDomain.domain,
                          uuid: item.uuid,
                        })
                      }}
                    />
                  )}
                </Stack>
              </CsbTable.TableCell>
            </CsbTable.TableRow>
          ))}
        </CsbTable>
        <Flex mt={5}>
          {data ? (
            <CsbPagination
              current={current}
              defaultCurrent={current}
              pageSize={data.phishingLinkDomains.metadata.limitValue}
              total={data.phishingLinkDomains.metadata.totalCount}
              onChange={onChangePage('/link-domains')}
            />
          ) : null}
        </Flex>
        <CsbFormModal
          header={'サイトドメイン切替え'}
          isLoading={isUpdating}
          isOpen={formModal.isOpen}
          submit={'切替え'}
          onCancel={formModal.onCancel}
          onClose={formModal.onCancel}
          onSubmit={formModal.form.handleSubmit(onSubmit)}
        >
          <CsbFormControl
            errorText={
              findError(formModal.errors, 'phishingSiteDomainUuid')?.message
            }
          >
            <Select {...formModal.form.register('phishingSiteDomainUuid')}>
              {data?.phishingSiteDomains?.collection.map((domain) => (
                <option key={domain.uuid} value={domain.uuid}>
                  {domain.domain}
                </option>
              ))}
            </Select>
          </CsbFormControl>
        </CsbFormModal>
      </Base>
    )
  }
)
