import { useToast } from '@chakra-ui/react'
import { LocalStorage } from '@src/enums/localStorage'
import { useLocalStorage, useMe, useQueryParams } from '@src/hooks'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

//ログアウトで使うhooksのまとめ
export const useLogoutHooks = () => {
  const { setMe } = useMe()
  const navigate = useNavigate()
  const { session } = useQueryParams()
  const toast = useToast()

  const [, setAccessToken] = useLocalStorage<string>(
    LocalStorage.ACCESS_TOKEN,
    ''
  )

  useEffect(() => {
    if (session) {
      if (!!session && session === 'out') {
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top',
          status: 'error',
          title: 'セッションの有効期限が切れました',
        })
        setMe(undefined)
        setAccessToken(null)
        navigate('/', {
          replace: true,
        })
        return
      }
    }
    toast({
      duration: 3000,
      isClosable: true,
      position: 'top',
      status: 'success',
      title: 'ログアウトしました',
    })
    setMe(undefined)
    setAccessToken(null)
    navigate('/', {
      replace: true,
    })
  }, [session, navigate, setAccessToken, setMe, toast])

  return {}
}

export type useLogoutHooksResult = ReturnType<typeof useLogoutHooks>
