import { Pdfs } from '@src/components/pages/pdfs'
import { Authorization } from '@src/layouts/Authorization'
import { memo } from 'react'

export const PdfsPage = memo(() => {
  return (
    <Authorization>
      <Pdfs />
    </Authorization>
  )
})
