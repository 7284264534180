import {
  ModalFooter as ChakraModalFooter,
  ModalFooterProps,
} from '@chakra-ui/react'

type ModalFooterPropsType = ModalFooterProps

export const CsbModalFooter = ({
  children,
  ...props
}: ModalFooterPropsType) => {
  return (
    <ChakraModalFooter px={0} py={0} {...props}>
      {children}
    </ChakraModalFooter>
  )
}
