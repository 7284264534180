import { DeleteIcon, SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  VStack,
} from '@chakra-ui/react'
import { CsbFormControl, CsbPrefecturesInput } from '@src/components'
import { DropDropUploader } from '@src/components/Form/DropDropUploader'
import { BaseResponseError } from '@src/errors/BaseResponseError'
import {
  CompanyMailLimitInput,
  CompanyMailLimitKind,
  CreateCompanyInput,
  UpdateCompanyInput,
  useFindAddressQuery,
} from '@src/graphql/generated/graphql'
import { companyMailLimitKindTypes } from '@src/utils/companyMailLiimit'
import { findError } from '@src/utils/findError'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types'

type CompanyEditFormType = {
  errors: [BaseResponseError?]
  form: UseFormReturn<
    //TODO anyを消す
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    CreateCompanyInput | Omit<UpdateCompanyInput, 'uuid'> | any
  >
}

export const CompanyEditForm = ({ errors, form }: CompanyEditFormType) => {
  const { loading: findingAddress, refetch } = useFindAddressQuery({
    skip: true,
  })

  //郵便番号から都道府県と市区町村を検索する
  const findAddress = async () => {
    //現在のpostalCodeの値を保存
    const currentPostalCode = form.getValues('postalCode')
    //postalCodeが空文字の場合は早期Return
    if (!currentPostalCode) return
    //postalCodeが7文字未満の場合は早期Return
    if (currentPostalCode.length < 7) return

    //useFindAddressQueryから都道府県と市区町村を取得する
    const { data } = await refetch({
      postalCode: currentPostalCode,
    })

    //postalCodeが無効な場合は元の値をセットする
    form.setValue(
      'postalCode',
      data.findAddress.postalCode || currentPostalCode
    )
    //都道府県を代入する
    form.setValue('state', data.findAddress.state)
    //市区町村を代入する
    form.setValue('address', data.findAddress.address)
  }

  const [companyMailLimits, setCompanyMailLimits] = useState<
    CompanyMailLimitInput[]
  >(form.getValues('companyMailLimits'))

  useEffect(() => {
    setCompanyMailLimits(form.getValues('companyMailLimits'))
  }, [form])

  // eslint-disable-next-line
  const updateCompanyMailLimit = (index: number, data: any) => {
    const companyMailLimit = companyMailLimits[index]
    const newCompanyMailLimit = {
      ...companyMailLimit,
      ...data,
    }
    const heads = companyMailLimits.slice(0, index)
    const rest = companyMailLimits.slice(index + 1)
    const limits = [...heads, newCompanyMailLimit, ...rest]
    setCompanyMailLimits(limits)
    form.setValue('companyMailLimits', limits)
  }

  const onAddCompanyMailLimit = () => {
    const limits = [
      ...companyMailLimits,
      {
        currentCount: 0,
        endDate: null,
        limitCount: null,
        limitType: CompanyMailLimitKind.PhishingOnly,
        startDate: dayjs().format('YYYY-MM-DD'),
      },
    ].map((limit) => {
      // limitCountが自動的に 0 になるので、強制的に null にする
      return {
        ...limit,
        ...{ limitCount: limit.limitCount === 0 ? null : limit.limitCount },
      }
    })
    setCompanyMailLimits(limits)
    form.setValue('companyMailLimits', limits)
  }

  const onDeleteCompanyMailLimit = (index: number) => {
    const heads = companyMailLimits.slice(0, index)
    const rest = companyMailLimits.slice(index + 1)
    const limits = [...heads, ...rest]
    setCompanyMailLimits(limits)
    form.setValue('companyMailLimits', limits)
  }

  return (
    <>
      <VStack align={'start'} mt={6} spacing={5}>
        <CsbFormControl
          errorText={findError(errors, 'name')?.message}
          labelText={'企業名'}
        >
          <Input {...form.register('name')} />
        </CsbFormControl>
        <CsbFormControl
          errorText={findError(errors, 'domain')?.message}
          labelText={'ドメイン'}
        >
          <Input {...form.register('domain')} />
        </CsbFormControl>
        <CsbFormControl
          errorText={findError(errors, 'postalCode')?.message}
          isReadOnly={findingAddress}
          labelText={'郵便番号'}
        >
          <InputGroup size="md">
            <Input {...form.register('postalCode')} />
            <InputRightElement mr={1} width="6rem">
              <Button
                h="1.75rem"
                isLoading={findingAddress}
                size="sm"
                onClick={findAddress}
              >
                <SearchIcon h={3} mr={1} w={3} /> 住所検索
              </Button>
            </InputRightElement>
          </InputGroup>
        </CsbFormControl>
        <CsbFormControl
          errorText={findError(errors, 'state')?.message}
          isReadOnly={findingAddress}
          labelText={'都道府県'}
        >
          <CsbPrefecturesInput {...form.register('state')} />
        </CsbFormControl>
        <CsbFormControl
          errorText={findError(errors, 'address')?.message}
          isReadOnly={findingAddress}
          labelText={'市区町村'}
        >
          <Input {...form.register('address')} />
        </CsbFormControl>
        <CsbFormControl
          errorText={findError(errors, 'street')?.message}
          labelText={'番地'}
        >
          <Input {...form.register('street')} />
        </CsbFormControl>
        <CsbFormControl
          errorText={findError(errors, 'building')?.message}
          labelText={'建物名/部屋番号'}
        >
          <Input {...form.register('building')} />
        </CsbFormControl>
        <CsbFormControl
          errorText={findError(errors, 'logoPath')?.message}
          labelText={'企業ロゴ'}
        >
          <DropDropUploader
            savePath={'company/logo'}
            url={form.getValues('logoPath') ?? ''}
            onChange={(url) => {
              form.setValue('logoPath', url)
            }}
          />
        </CsbFormControl>
      </VStack>
      <Box mt={55}>
        <Heading fontSize={24} mb={3}>
          契約情報
        </Heading>
        <Divider />
        <VStack align={'start'} mt={6} spacing={5}>
          <CsbFormControl
            errorText={findError(errors, 'maxUserCount')?.message}
            labelText={'ユーザー数上限'}
          >
            <Input
              textAlign={'right'}
              w={100}
              {...form.register('maxUserCount', { valueAsNumber: true })}
              type={'number'}
            />
          </CsbFormControl>

          <CsbFormControl
            errorText={findError(errors, 'expiresAt')?.message}
            labelText={'有効期限'}
          >
            <Input
              w={300}
              {...form.register('expiresAt', {
                setValueAs: (value) => {
                  return value === '' || value === null
                    ? null
                    : dayjs(value).format('YYYY-MM-DD')
                },
              })}
              type={'date'}
            />
          </CsbFormControl>
          <CsbFormControl
            errorText={findError(errors, 'testMailCount')?.message}
            labelText={'テストメール配信可能人数'}
          >
            <Input
              textAlign={'right'}
              w={100}
              {...form.register('testMailCount', { valueAsNumber: true })}
              type={'number'}
            />
          </CsbFormControl>

          <VStack align="flex-start" spacing={'20px'}>
            {companyMailLimits &&
              companyMailLimits.map((companyMailLimit, index) => (
                <>
                  <HStack key={index} spacing={'20px'}>
                    <CsbFormControl labelText={index === 0 ? '送信タイプ' : ''}>
                      <Controller
                        control={form.control}
                        name={`companyMailLimits.${index}.limitType`}
                        render={({ ...field }) => (
                          <Select
                            disabled={companyMailLimit.currentCount > 0}
                            value={form.getValues(
                              `companyMailLimits.${index}.limitType`
                            )}
                            w={200}
                            {...field}
                            onChange={(e) => {
                              e.stopPropagation()
                              const companyMailLimitKindType = e.target
                                .value as CompanyMailLimitKind
                              updateCompanyMailLimit(index, {
                                limitType: companyMailLimitKindType,
                              })
                            }}
                          >
                            {companyMailLimitKindTypes.map(([key, value]) => (
                              <option key={key} value={key}>
                                {value}
                              </option>
                            ))}
                          </Select>
                        )}
                      />
                    </CsbFormControl>

                    <CsbFormControl labelText={index === 0 ? '開始日' : ''}>
                      <Input
                        w={200}
                        {...form.register(
                          `companyMailLimits.${index}.startDate`,
                          {
                            setValueAs: (value) => {
                              return value === '' || value === null
                                ? null
                                : dayjs(value).format('YYYY-MM-DD')
                            },
                          }
                        )}
                        type={'date'}
                        onChange={(e) => {
                          e.stopPropagation()
                          updateCompanyMailLimit(index, {
                            startDate: e.target.value,
                          })
                        }}
                      />
                    </CsbFormControl>
                    <CsbFormControl labelText={index === 0 ? '終了日' : ''}>
                      <Input
                        w={200}
                        {...form.register(
                          `companyMailLimits.${index}.endDate`,
                          {
                            setValueAs: (value) => {
                              return value === '' || value === null
                                ? null
                                : dayjs(value).format('YYYY-MM-DD')
                            },
                          }
                        )}
                        type={'date'}
                        onChange={(e) => {
                          e.stopPropagation()
                          updateCompanyMailLimit(index, {
                            endDate: e.target.value,
                          })
                        }}
                      />
                    </CsbFormControl>
                    <CsbFormControl labelText={index === 0 ? '最大送信数' : ''}>
                      <Input
                        textAlign={'right'}
                        w={100}
                        {...form.register(
                          `companyMailLimits.${index}.limitCount`,
                          {
                            valueAsNumber: true,
                          }
                        )}
                        type={'number'}
                        onChange={(e) => {
                          e.stopPropagation()
                          updateCompanyMailLimit(index, {
                            limitCount:
                              e.target.value === '' || e.target.value === null
                                ? null
                                : Number(e.target.value) ?? null,
                          })
                        }}
                      />
                    </CsbFormControl>
                    <CsbFormControl labelText={index === 0 ? '現送信数' : ''}>
                      <Input
                        disabled={true}
                        textAlign={'right'}
                        w={100}
                        {...form.register(
                          `companyMailLimits.${index}.currentCount`
                        )}
                        type={'number'}
                      />
                    </CsbFormControl>
                    <IconButton
                      aria-label="delete-date"
                      colorScheme={'red'}
                      icon={<DeleteIcon />}
                      mt={1}
                      size={'sm'}
                      visibility={
                        index === 0 || companyMailLimit.currentCount > 0
                          ? 'hidden'
                          : 'visible'
                      }
                      onClick={() => {
                        onDeleteCompanyMailLimit(index)
                      }}
                    />
                  </HStack>
                  {findError(errors, `companyMailLimits.${index}`) && (
                    <CsbFormControl
                      errorText={
                        findError(errors, `companyMailLimits.${index}`)?.message
                      }
                      fontSize={'md'}
                    />
                  )}
                </>
              ))}
            <Flex mt={10} w={'full'}>
              <HStack align={'right'} ml={'auto'} spacing={3}>
                <Button onClick={onAddCompanyMailLimit}>追加</Button>
              </HStack>
            </Flex>
          </VStack>
        </VStack>
      </Box>
    </>
  )
}
