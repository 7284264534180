import { useToast } from '@chakra-ui/react'
import { paginationInput } from '@src/constants'
import {
  ForceUnlockUserInput,
  useForceUnlockUserMutation,
  useUserLockLogsQuery,
} from '@src/graphql/generated/graphql'
import { usePagination } from '@src/hooks'
import { useForm } from 'react-hook-form'

//学習一覧で使うhooksのまとめ
export const useUserLockLogsHooks = () => {
  const [forceUnlockUserMutation, { loading: isUnlocking }] =
    useForceUnlockUserMutation()

  const form = useForm<ForceUnlockUserInput>({
    defaultValues: {
      userUuid: '',
    },
  })
  const toast = useToast()
  //ページネーション
  const { current, onChangePage } = usePagination()

  const { data, refetch } = useUserLockLogsQuery({
    variables: {
      pagination: paginationInput(current),
    },
  })

  //新規作成、編集のModalのformを送信する関数
  const onSubmit = form.handleSubmit(async (inputData) => {
    try {
      await forceUnlockUserMutation({
        variables: {
          input: {
            userUuid: inputData.userUuid,
          },
        },
      })

      /**
       * TODO anyを消す
       * e instanceOf ApolloError
       * setErrorsを変更する
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast({
        duration: 3000,
        status: 'error',
        title: 'ロックの解除に失敗しました。',
      })
    }
    await refetch()
  })

  return {
    current,
    data,
    form,
    isUnlocking,
    onChangePage,
    onSubmit,
  }
}

export type useUserLockLogsHooksResult = ReturnType<typeof useUserLockLogsHooks>
