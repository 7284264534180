import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Flex,
  Heading,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
  VStack,
} from '@chakra-ui/react'
import { CsbNextLink, CsbTag } from '@src/components'
import { CsbTable } from '@src/components/CsbTable'
import { CsbDataModal } from '@src/components/Modal/CsbDataModal'
import { useUsersImportHooksResult } from '@src/components/pages/users/import/hooks'
import { Base } from '@src/layouts'
import { genderToString } from '@src/utils/genderToString'
import dayjs from 'dayjs'
import { memo } from 'react'

//ユーザーインポートページのcomponent
export const UsersImport = memo(
  ({
    csv,
    dataModal,
    fileRef,
    getUsers,
    imports,
    loading,
    navigate,
    onChangeFiles,
    onClickFileUpload,
    onFileClear,
    onFileUpload,
    uploading,
  }: useUsersImportHooksResult) => {
    return (
      <Base
        left={<Heading>CSV一括インポート</Heading>}
        right={
          <HStack spacing={3}>
            <Button colorScheme={'gray'} onClick={() => navigate(-1)}>
              一覧に戻る
            </Button>
          </HStack>
        }
      >
        <Box
          alignItems={'start'}
          borderColor={'gray.200'}
          borderWidth={2}
          mb={5}
          p={3}
          rounded={10}
        >
          <input
            hidden
            multiple={false}
            ref={fileRef}
            type="file"
            onChange={onChangeFiles}
          />

          <>
            {csv !== null ? (
              <>
                <Table mb={3}>
                  <Tbody>
                    <Tr>
                      <Th w={200}>ファイル名</Th>
                      <Td>{csv.name}</Td>
                    </Tr>
                    <Tr>
                      <Th>ファイルタイプ</Th>
                      <Td>{csv.type}</Td>
                    </Tr>
                    <Tr>
                      <Th>ファイル容量</Th>
                      <Td>{csv.size}バイト</Td>
                    </Tr>
                  </Tbody>
                </Table>
                <HStack display={'flex'} justifyContent={'end'} spacing={3}>
                  <Button colorScheme={'gray'} onClick={onFileClear}>
                    クリア
                  </Button>
                  <Button
                    isLoading={loading || uploading}
                    onClick={onFileUpload}
                  >
                    インポートを実行する
                  </Button>
                </HStack>
              </>
            ) : (
              <>
                <Box>
                  <Text as={'p'} mb={3}>
                    従業員一覧のCSVファイルを選択してください
                  </Text>
                </Box>
                <VStack align="stretch" spacing={3}>
                  <Box>
                    <Button onClick={onClickFileUpload}>
                      CSVファイルの選択
                    </Button>
                  </Box>
                  <Divider />
                  <Box>
                    <a
                      href={`${process.env.REACT_APP_BACKEND_URL}/default_csv`}
                    >
                      <Button colorScheme={'gray'}>
                        インポート用CSVファイルのダウンロード
                      </Button>
                    </a>
                  </Box>
                </VStack>
              </>
            )}
          </>
        </Box>
        <TableContainer mt={35}>
          <CsbTable
            labels={[
              { label: 'ステータス' },
              { label: '取り込み開始日' },
              { isNumeric: true, label: 'ユーザー数' },
              { isNumeric: true, label: '成功数' },
              { label: '' },
            ]}
          >
            {imports?.csvImportStatus?.map((csvImport, i) => (
              <CsbTable.TableRow key={i}>
                <CsbTable.TableCell w={300}>
                  {' '}
                  {csvImport.completeAt === null ? (
                    <CircularProgress
                      isIndeterminate
                      color="teal"
                      size={'25px'}
                    />
                  ) : (
                    '取込完了'
                  )}
                </CsbTable.TableCell>
                <CsbTable.TableCell>
                  {dayjs(csvImport.startAt).format('YYYY/MM/DD HH:mm')}
                </CsbTable.TableCell>
                <CsbTable.TableCell isNumeric w={200}>
                  {csvImport.recordCount}
                </CsbTable.TableCell>
                <CsbTable.TableCell isNumeric w={200}>
                  {csvImport.successCount}
                </CsbTable.TableCell>
                <CsbTable.TableCell isNumeric w={300}>
                  <Button
                    disabled={csvImport.completeAt === null}
                    onClick={() =>
                      dataModal.onOpen(async () => {
                        getUsers({
                          variables: {
                            uuid: csvImport.uuid,
                          },
                        })
                      })
                    }
                  >
                    結果を確認する
                  </Button>
                  <Button
                    isDisabled={!csvImport.hasError}
                    ml={2}
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_BACKEND_URL}/export/import_user_csv_errors.xlsx?uuid=${csvImport.uuid}`,
                        '_blank'
                      )
                    }}
                  >
                    エラーエクスポート
                  </Button>
                </CsbTable.TableCell>
              </CsbTable.TableRow>
            ))}
          </CsbTable>
        </TableContainer>
        <Flex mt={5}>
          <Text
            color={'gray'}
            fontSize={'sm'}
            fontWeight={'bold'}
            textAlign={'right'}
          >
            最新の取り込み結果5件
          </Text>
          <HStack ml={'auto'} spacing={3}>
            <CsbNextLink href={'/users'}>
              <Button as={'div'} colorScheme={'gray'}>
                一覧に戻る
              </Button>
            </CsbNextLink>
          </HStack>
        </Flex>
        <CsbDataModal {...dataModal} size={'4xl'}>
          <Heading>インポート結果</Heading>
          <TableContainer mt={35}>
            <CsbTable
              labels={[
                '結果',
                '失敗理由',
                '社員番号',
                '氏名',
                'メールアドレス',
                '変更メールアドレス',
                '権限',
                '役職',
                '組織',
                'グループ',
                '追加グループ',
                '性別',
                '誕生日',
                '入社日',
                '電話番号',
                '内線番号',
                '携帯番号',
                'パスワード',
              ]}
              wrapperProps={{
                border: 'none',
                maxH: '300px',
                overflowY: 'auto',
              }}
            >
              {dataModal.data
                ? dataModal.data.map((item) => (
                    <CsbTable.TableRow key={item.uuid}>
                      <CsbTable.TableCell>
                        <CsbTag colorScheme={item.success ? 'teal' : 'red'}>
                          {item.success ? '成功' : '失敗'}
                        </CsbTag>
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        <Text color={'red'} fontSize={'sm'}>
                          {item.errorText}
                        </Text>
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {item.employeeNumber}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {item.lastName &&
                          item.firstName &&
                          `${item.lastName} ${item.firstName}`}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>{item.email}</CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {item.changeEmail}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {item.role && <CsbTag>{item.role}</CsbTag>}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {item.position && <CsbTag>{item.position}</CsbTag>}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        <HStack>
                          {item.organization
                            .split(',')
                            .map(
                              (organization, index) =>
                                organization && (
                                  <CsbTag
                                    key={`organization_${organization}_${index}`}
                                  >
                                    {organization}
                                  </CsbTag>
                                )
                            )}
                        </HStack>
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        <HStack>
                          {item.group
                            .split(',')
                            .map(
                              (group, index) =>
                                group && (
                                  <CsbTag key={`group_${group}_${index}`}>
                                    {group}
                                  </CsbTag>
                                )
                            )}
                        </HStack>
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        <HStack>
                          {item.addGroup
                            .split(',')
                            .map(
                              (group, index) =>
                                group && (
                                  <CsbTag key={`group_${group}_${index}`}>
                                    {group}
                                  </CsbTag>
                                )
                            )}
                        </HStack>
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {item.gender && <>{genderToString(item.gender)}</>}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {item.birthday && (
                          <>{dayjs(item.birthday).format('YYYY/MM/DD')}</>
                        )}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {item.joined && (
                          <>{dayjs(item.joined).format('YYYY/MM/DD')}</>
                        )}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {item.telephoneNumber}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {item.officeNumber}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>
                        {item.mobileNumber}
                      </CsbTable.TableCell>
                      <CsbTable.TableCell>{item.password}</CsbTable.TableCell>
                    </CsbTable.TableRow>
                  ))
                : 'loading...'}
            </CsbTable>
          </TableContainer>
        </CsbDataModal>
      </Base>
    )
  }
)
