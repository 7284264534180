import { usePhishingResultDetailsQuery } from '@src/graphql/generated/graphql'

export const usePhishingResultDetails = (
  baseOptions: Partial<Parameters<typeof usePhishingResultDetailsQuery>[0]> = {}
) => {
  return usePhishingResultDetailsQuery(baseOptions)
}

export type usePhishingResultDetailsHooksResult = ReturnType<
  typeof usePhishingResultDetails
>
export type usePhishingResultDetailsHooksParameter = Parameters<
  typeof usePhishingResultDetails
>[0]
