import { AspectRatio, BoxProps, Grid } from '@chakra-ui/react'
import { CsbLogoIcon } from '@src/components'

type CsbHeaderPropsType = BoxProps

export const CsbHeader = ({ ...props }: CsbHeaderPropsType) => {
  return (
    <Grid
      alignItems={'center'}
      as="header"
      border="solid 1px"
      borderColor="gray.200"
      h={20}
      justifyContent={'space-between'}
      px={31}
      py={3.5}
      templateColumns={'repeat(2,auto)'}
      {...props}
    >
      <AspectRatio ratio={299 / 127} w={92}>
        <CsbLogoIcon />
      </AspectRatio>
    </Grid>
  )
}
