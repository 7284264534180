//フィッシング一覧で使うhooksのまとめ
import { paginationInput } from '@src/constants'
import {
  Comic,
  ComicImage,
  CreateComicInput,
  useComicsQuery,
  useCreateComicMutation,
  useDestroyComicMutation,
  useUpdateComicCompaniesMutation,
  useUpdateComicMutation,
} from '@src/graphql/generated/graphql'
import {
  useDeleteModal,
  useFormModal,
  useMe,
  usePagination,
  useToast,
} from '@src/hooks'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

//comic editでも同じ型を使っている
type PicComic = Pick<Comic, 'name' | 'description' | 'uuid'> & {
  images: Pick<ComicImage, 'uuid' | 'url'>[]
}

export const useComicsHooks = () => {
  const { role } = useMe()
  const isWriteLearningComic = !!role?.writeLearningComic
  const navigate = useNavigate()
  const deleteModal = useDeleteModal()
  const [createComicMutation, { loading: isCreating }] =
    useCreateComicMutation()
  const [updateComicMutation, { loading: isUpdating }] =
    useUpdateComicMutation()
  const [destroyComicMutation] = useDestroyComicMutation()
  const formModal = useFormModal<CreateComicInput>({
    defaultValues: {
      description: '',
      name: '',
    },
  })
  const [currentComic, setComic] = useState<PicComic | null>(null)

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    searchWord: withDefault(StringParam, ''),
  })
  //ページネーション
  const { current, onChangePage } = usePagination()

  const { data, refetch } = useComicsQuery({
    variables: {
      pagination: paginationInput(current),
      searchWord: query.searchWord,
    },
  })

  //編集するModalを開くボタン
  const onUpdate = (comic: PicComic) => {
    formModal.onOpen()
    formModal.form.setValue('name', comic.name)
    formModal.form.setValue('description', comic.description)
    formModal.setUuid(comic.uuid)
    formModal.setState('update')
    setComic(comic)
  }

  //新規作成、編集のModalのformを送信する関数
  const onSubmit = formModal.form.handleSubmit(async (inputData) => {
    try {
      //typeを判定
      switch (formModal.state) {
        case 'create':
          //createの場合はcreateGroupにinputDataを渡す
          await createComicMutation({
            variables: {
              input: inputData,
            },
          })
          break
        case 'update':
          //createの場合はupdateGroupMutationにinputDataとuuidを渡す
          await updateComicMutation({
            variables: {
              input: {
                ...inputData,
                //アップデートの時は選択されているコミックの画像を{uuid,url}[]の形式にして渡す
                images:
                  currentComic?.images.map(({ url, uuid }) => ({
                    url,
                    uuid,
                  })) || [],

                uuid: formModal.uuid,
              },
            },
          })
          break
        //一応エラーを書く
        default:
          new Error(`${formModal.state} does not exist`)
      }

      //新規作成、編集をすればToastを発行する
      formModal.toast({
        status: 'success',
        title: formModal.isCreate
          ? `${inputData.name || 'コミック'}を登録しました。`
          : `${inputData.name || 'コミック'}を編集しました。`,
      })
      formModal.onCancel()
      /**
       * TODO anyを消す
       * e instanceOf ApolloError
       * setErrorsを変更する
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      formModal.setErrors(e.graphQLErrors)
      //新規作成、編集が失敗した場合のToast
      formModal.toast({
        status: 'error',
        title: `${inputData.name || 'コミック'}の登録に失敗しました。`,
      })
    }
    await refetch()
  })

  //search機能
  const searchForm = useForm<{ searchWord: string }>({
    defaultValues: {
      searchWord: query.searchWord,
    },
  })
  const onSearch = searchForm.handleSubmit(async (inputData) => {
    setQuery({
      page: 1,
      searchWord: inputData.searchWord,
    })
  })
  const onResetSearch = () => {
    searchForm.setValue('searchWord', '')

    setQuery({
      page: 1,
      searchWord: '',
    })
  }

  const [updateComicCompaniesMutation, { loading: isUpdatingComicCompanies }] =
    useUpdateComicCompaniesMutation()

  const toast = useToast()

  const onUpdateSelectCompanies = async (
    uuid: string,
    companyUuids: string[]
  ) => {
    try {
      await updateComicCompaniesMutation({
        variables: {
          input: {
            companies: companyUuids,
            uuid: uuid,
          },
        },
      })
      toast({
        status: 'success',
        title: 'コミックの対象企業を設定しました。',
      })
    } catch (e) {
      toast({
        status: 'error',
        title: 'コミックの対象企業の設定に失敗しました。',
      })
    }
    await refetch()
  }

  return {
    current,
    data,
    deleteModal,
    destroyComicMutation,
    formModal,
    isCreating,
    isUpdating,
    isUpdatingComicCompanies,
    isWriteLearningComic,
    navigate,
    onChangePage,
    onResetSearch,
    onSearch,
    onSubmit,
    onUpdate,
    onUpdateSelectCompanies,
    refetch,
    searchForm,
  }
}

export type useComicsHooksResult = ReturnType<typeof useComicsHooks>
